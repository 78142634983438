import React, { useState } from 'react';
import Validators from '../../../utils/Validators/Validators';
import Auth from 'auth/Auth';
import TextField from '../../_ReusableComponents/TextField/TextField';
import Button from '../../_ReusableComponents/Button/Button';
import { useTranslation, Trans } from 'react-i18next';
const styles = require('./Forgot.css');

export default function ForgotPage() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [waiting, setWaiting] = useState(false);
    const [sentTo, setSentTo] = useState<string | null>(null);
    const [invalid, setInvalid] = useState(false);
    return (
        <form
            noValidate
            onSubmit={async (event) => {
                event.preventDefault();
                if (Validators.isValidEmail(email)) {
                    setWaiting(true);
                    try {
                        await Auth.passwordReset(email);
                        setSentTo(email);
                        setEmail('');
                    } catch (error) {
                        setInvalid(true);
                    } finally {
                        setWaiting(false);
                    }
                } else {
                    setInvalid(true);
                }
            }}
        >
            {t('auth.enterEmail')}
            <TextField
                type="email"
                label={t('auth.email')}
                value={email}
                onChange={({ target }) => {
                    setEmail(target.value);
                    setInvalid(false);
                }}
                error={invalid}
                helperText={(() => {
                    if (invalid) {
                        return t('auth.emailInvalid');
                    }
                    return '';
                })()}
                fullWidth
                className={styles.field}
            />
            <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={waiting}
            >
                {t('auth.sendReset')}
            </Button>
            {sentTo && (
                <div className={styles.sent}>
                    <Trans
                        i18nKey="auth.linkSent"
                        components={{
                            span: <span className={styles.sentAddress} />,
                        }}
                        values={{ email: sentTo }}
                    />
                </div>
            )}
        </form>
    );
}
