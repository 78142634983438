// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__1OoCw {\n    border-radius: 7px 7px 0 0;\n    display: flex;\n    margin: 44px auto 0;\n    width: 425px;\n}\n    @media (max-width: 425px) {.root__1OoCw {\n        border-radius: 0;\n        width: 100%\n}\n    }\n\n.link__1Z3Lh {\n    color: #08a;\n    background-color: #f0f0f0;\n    width: 25%;\n    border-radius: 0;\n    box-shadow: none;\n    padding: 10px 15px;\n    min-width: 40px;\n    font-weight: 400 !important;\n}\n\n.link__1Z3Lh:hover {\n        background-color: #f0f0f0;\n        color: #006179;\n        box-shadow: none;\n    }\n\n.active__oLhiw {\n    color: white;\n    background-color: #08a;\n}\n\n.active__oLhiw:hover {\n        color: rgba(255, 255, 255, 0.8);\n        background-color: #08a;\n    }\n", "",{"version":3,"sources":["AdminLinks.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,YAAY;AAKhB;IAJI,2BALJ;QAMQ,gBAAgB;QAChB;AAER;IADI;;AAGJ;IACI,WAAW;IACX,yBAAyB;IACzB,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,2BAA2B;AAO/B;;AALI;QACI,yBAAyB;QACzB,cAAc;QACd,gBAAgB;IACpB;;AAGJ;IACI,YAAY;IACZ,sBAAsB;AAM1B;;AAJI;QACI,+BAA+B;QAC/B,sBAAsB;IAC1B","file":"AdminLinks.css","sourcesContent":[".root {\n    border-radius: 7px 7px 0 0;\n    display: flex;\n    margin: 44px auto 0;\n    width: 425px;\n    @media (max-width: 425px) {\n        border-radius: 0;\n        width: 100%;\n    }\n}\n\n.link {\n    color: #08a;\n    background-color: #f0f0f0;\n    width: 25%;\n    border-radius: 0;\n    box-shadow: none;\n    padding: 10px 15px;\n    min-width: 40px;\n    font-weight: 400 !important;\n\n    &:hover {\n        background-color: #f0f0f0;\n        color: #006179;\n        box-shadow: none;\n    }\n}\n\n.active {\n    color: white;\n    background-color: #08a;\n\n    &:hover {\n        color: rgba(255, 255, 255, 0.8);\n        background-color: #08a;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__1OoCw",
	"link": "link__1Z3Lh",
	"active": "active__oLhiw"
};
module.exports = exports;
