import React, { useContext, useEffect, useRef, useState } from 'react';
import containersDispatchToProps from '../../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../../utils/containersMapStateToProps';
import { useLocation, Redirect } from 'react-router-dom';
import Nav from './Nav';
import useEventListener from '@use-it/event-listener';
import scrollIntoView from 'scroll-into-view';
import { isLoggedIn } from '../../../scripts/helpers';
import { getSubdomain, redirectToSubdomain } from '../../../utils/subdomain';
import { useIsDesktop } from '../../../utils/responsive';
import Plans from './Plans/Plans';
import { Features } from './Features/Features';
import GlobalContext from '../../../GlobalContext';
import { SbSlider } from './SbSlider/SbSlider';
import { ForWhoms } from './ForWhoms/ForWhoms';
import { ContentSlider } from './ContentSlider/ContentSlider';
import Footer from './Footer';
import IActions from 'actions/IActions';
const config = require('CONFIG');
const styles = require('./LandingPage.css');
interface IBizProps {
    actions: IActions;
}

const connect = require('react-redux').connect;
function LandingPage(props: IBizProps) {
    const isDesktop = useIsDesktop();
    const location = useLocation();
    useEffect(() => {
        const el = document.getElementById(location.hash.substring(1) || 'top');
        if (el) {
            scrollIntoView(el, { align: { top: 0, topOffset: 120 } });
        }
    }, [location]);
    const customersRef = useRef<HTMLDivElement | null>(null);
    const [scrollCustomers, setScrollCustomers] = useState(false);
    const checkScroll = () => {
        setTimeout(() => {
            setScrollCustomers(customersRef.current?.offsetWidth > window.innerWidth);
        }, 1);
    };
    useEffect(checkScroll, []);
    useEventListener('resize', checkScroll);
    const { profile } = useContext(GlobalContext);
    if (isLoggedIn()) {
        const { appointments, phone } = profile;
        if (appointments.companyId) {
            if (getSubdomain() === null && window.location.host === config.baseDomain) {
                if (profile.appointments.companySubdomain) {
                    redirectToSubdomain(profile.appointments.companySubdomain, '/sb/dashboard');
                }
                return null;
            }
            return <Redirect to="/sb/dashboard" />;
        }
        if (phone) {
            return <Redirect to={'/sb-customer/my-appointments'} />;
        }
    }
    if (getSubdomain() !== null) {
        return <Redirect to="/sb-customer/find-service" />;
    }
    if (!isDesktop) {
        return <Redirect to="/login" />;
    }
    return (
        <>
            <Nav />
            <SbSlider
                actions={props.actions}
            />
            <div className={styles.body}>
                <div className={styles.content}>
                    <Features />
                    <ForWhoms />
                    <ContentSlider />
                    <Plans />
                </div>
            </div>
            <Footer />
        </>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(LandingPage);
