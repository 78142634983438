import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import Plans from './Plans';
import MobileNav from './MobileNav';
import { Tooltip } from '@material-ui/core';
import Button from '../../components/_ReusableComponents/Button/Button';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { WANTS_TO_BUY_TANBUU } from '../../constants/appConsts';
const styles = require('./MobilePricing.css');

export default function MobilePricing() {
    const { t } = useTranslation();
    return (
        <div>
            <Plans />
            <div className={styles.plan}>
                <div className={styles.planName}>
                    {t('landing.basic')}
                </div>
                <div className={styles.feature}>
                    {t('landing.invitations')}
                    :
                    <Tooltip
                        title={t('landing.invitationsTooltip')}
                        classes={{ tooltip: styles.tooltip }}
                    >
                        <span className={styles.info}>i</span>
                    </Tooltip>
                    <span className={styles.bold}>
                        {`20/${t('landing.mo')}`}
                    </span>
                </div>
                <div className={styles.feature}>
                    {t('landing.connectCalendars')}
                </div>
                <div className={styles.feature}>
                    {t('landing.connectZoom')}
                </div>
                <div className={styles.feature}>
                    {t('landing.emailSupport')}
                    :
                    <span className={styles.bold}>
                        {`1 ${t('landing.day')}`}
                    </span>
                </div>
                <Button
                    variant="text"
                    className={classNames(styles.planButton, styles.planSignUp)}
                    component={Link}
                    to="/register"
                >
                    {t('landing.signUp')}
                </Button>
            </div>
            <div className={styles.plan}>
                <div className={styles.planName}>
                    {t('landing.plus')}
                </div>
                <div className={styles.feature}>
                    {t('landing.invitations')}
                    :
                    <Tooltip
                        title={t('landing.invitationsTooltip')}
                        classes={{ tooltip: styles.tooltip }}
                    >
                        <span className={styles.info}>i</span>
                    </Tooltip>
                    <span className={styles.bold}>
                        {t('landing.unlimited')}
                    </span>
                </div>
                <div className={styles.feature}>
                    {t('landing.connectCalendars')}
                </div>
                <div className={styles.feature}>
                    {t('landing.connectZoom')}
                </div>
                <div className={styles.feature}>
                    {t('landing.emailSupport')}
                    :
                    <span className={styles.bold}>
                        {`4${t('landing.h')}`}
                    </span>
                </div>
                <div className={styles.feature}>
                    {t('landing.outlookAddIn')}
                </div>
                <div className={styles.feature}>
                    {t('landing.customNotifications')}
                </div>
                <Button
                    variant="text"
                    className={classNames(styles.planButton, styles.planBuyNow)}
                    onClick={() => {
                        localStorage.setItem(WANTS_TO_BUY_TANBUU, 'PlusPlan');
                    }}
                    component={Link}
                    to="/register"
                >
                    {t('landing.buyNow')}
                </Button>
            </div>
            <div className={styles.plan}>
                <div className={styles.planName}>
                    {t('landing.enterprise')}
                </div>
                <div className={styles.feature}>
                    {t('landing.invitations')}
                    :
                    <Tooltip
                        title={t('landing.invitationsTooltip')}
                        classes={{ tooltip: styles.tooltip }}
                    >
                        <span className={styles.info}>i</span>
                    </Tooltip>
                    <span className={styles.bold}>
                        {t('landing.unlimited')}
                    </span>
                </div>
                <div className={styles.feature}>
                    {t('landing.connectCalendars')}
                </div>
                <div className={styles.feature}>
                    {t('landing.connectZoom')}
                </div>
                <div className={styles.feature}>
                    {t('landing.emailSupport')}
                    :
                    <span className={styles.bold}>
                        {`1${t('landing.h')}`}
                    </span>
                </div>
                <div className={styles.feature}>
                    {t('landing.outlookAddIn')}
                </div>
                <div className={styles.feature}>
                    {t('landing.customNotifications')}
                </div>
                <div className={styles.feature}>
                    {t('landing.successManager')}
                </div>
                <div className={styles.feature}>
                    {t('landing.tanbuuAPI')}
                </div>
                <div className={styles.feature}>
                    {t('landing.serviceLevel')}
                </div>
                <Button
                    variant="text"
                    className={classNames(styles.planButton, styles.planContactUs)}
                    component={Link}
                    to="/contact"
                >
                    {t('landing.contactUs')}
                </Button>
            </div>
        </div>
    );
}
