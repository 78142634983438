import React, { useContext, useState } from 'react';
import moment from 'moment-timezone';
import IActions from 'actions/IActions';
import { Link } from 'react-router-dom';
import { Dialog, DialogContent, DialogContentText, DialogActions, FormControlLabel, Checkbox } from '@material-ui/core';
import Button from '../../Button/Button';
import BackendMethods from '../../../../api/BackendMethods';
import { useTranslation, Trans } from 'react-i18next';
import GlobalContext from '../../../../GlobalContext';
const styles = require('./AskForTimezoneModal.css');

interface Props {
    actions: IActions;
    data: any;
    open: boolean;
    onClose: () => void;
}

export default function AskForTimezoneModal(props: Props) {
    const { t } = useTranslation();
    const { profile, updateContext } = useContext(GlobalContext);
    const browserTimezone = moment.tz.guess();
    const [neverAsk, setNeverAsk] = useState(false);
    const [loading, setLoading] = useState(false);

    const keep = async () => {
        setLoading(true);
        try {
            await BackendMethods.saveShouldPrompt('timezone', neverAsk ? 'NEVER' : 'NEXT_WEEK');
            props.onClose();
        } catch {}
        setLoading(false);
    };

    const change = async () => {
        setLoading(true);
        try {
            await BackendMethods.updateUserProfile({ timezone: browserTimezone });
            updateContext((x) => ({
                ...x,
                profile: {
                    ...profile,
                    timezone: browserTimezone,
                },
            }));
            props.onClose();
        } catch {}
        setLoading(false);
    };

    return (
        <Dialog
            open={props.open}
            className={styles.root}
        >
            <DialogContent>
                <DialogContentText className={styles.text}>
                    <Trans
                        i18nKey="home.differentTimezone"
                        components={[<strong />, <strong />]}
                        values={{ browserTimezone, timezone: profile.timezone }}
                    />
                </DialogContentText>
                <DialogContentText className={styles.text}>
                    {t('home.timezoneQuestion')}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.actions}>
                <div className={styles.mainButtons}>
                    <Button
                        onClick={keep}
                        disabled={loading}
                        data-testid="keep-button"
                    >
                        {t('home.keep')}&nbsp;
                        <span
                            data-testid="keep-button-timezone"
                        >
                            {profile.timezone}
                        </span>
                    </Button>
                    <Button
                        variant="contained"
                        onClick={change}
                        disabled={loading}
                        data-testid="change-button"
                    >
                        {t('home.changeTo')}&nbsp;
                        <span
                            data-testid="change-button-timezone"
                        >
                            {browserTimezone}
                        </span>
                    </Button>
                </div>
                <Link
                    className={styles.setAnother}
                    to="/preferences"
                    onClick={props.onClose}
                >
                    {t('home.setAnotherTimezone')}
                </Link>
                <FormControlLabel
                    className={styles.formControlLabel}
                    control={(
                        <Checkbox
                            checked={neverAsk}
                            onChange={({ target }) => setNeverAsk(target.checked)}
                            color="primary"
                            data-testid="never-ask"
                        />
                    )}
                    label={t('home.doNotAsk')}
                />
            </DialogActions>
        </Dialog>
    );
}
