import * as React from 'react';

const Styles = require('./YoutubeButton.css');

class YoutubeButton extends React.PureComponent<{}, null> {
  render() {
    return (
      <a
        className={Styles.YoutubeButton}
        href="https://youtu.be/0IJMbYfE4xw"
        target="_blank"
      >
        <div>
          <div className={Styles.Label}>about</div>
          <img
            src="/assets/youtube_icon.svg"
            className={Styles.Image}
          />
        </div>
      </a>
    );
  }
}

export default YoutubeButton;
