import * as R from 'ramda';
import Validators from 'utils/Validators/Validators';
import IProfile from 'interfaces/IProfile';
import moment from 'moment';
import { IWorkHours } from 'interfaces/interfaces';

export default class PreferencesUtils {

    public static AM_OR_PM_REGEX = '/am|pm|a|p/';

    public static getSortedWorkDays(workDays: string[]): string[] {
        const sorter = {
            su: 0,
            mo: 1,
            tu: 2,
            we: 3,
            th: 4,
            fr: 5,
            sa: 6
        };

        return workDays.filter((x) => Boolean(x)).sort((a, b) => {
            const day1 = a.toLowerCase();
            const day2 = b.toLowerCase();
            return sorter[day1] - sorter[day2];
        });
    }

    public static capitalizeFirstLetter(string) {
        if(!string) {
            return string;
        }

        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    public static lowercaseFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    public static getShortWorkDaysArrayFirstLetterUppercase(daysArray: string[]) {
        return daysArray.map(e => {
            let newE: string = this.capitalizeFirstLetter(e);
            newE = newE.slice(0, 2);
            return newE;
        });
    }

    public static getShortWorkDaysArrayFirstLetterLowercase(daysArray: string[]) {
        return daysArray.map(e => {
            let newE = this.lowercaseFirstLetter(e);
            newE = newE.slice(0, 2);
            return newE;
        });
    }

    public static getCleanDaysInputState(state) {
        return state.set('fromAll', '').set('toAll', '')
            .set('fromMo', '').set('toMo', '')
            .set('fromTu', '').set('toTu', '')
            .set('fromWe', '').set('toWe', '')
            .set('fromTh', '').set('toTh', '')
            .set('fromFr', '').set('toFr', '')
            .set('fromSa', '').set('toSa', '')
            .set('fromSu', '').set('toSu', '');
    }

    public static getErrorState(state) {
        let newState = state;
        const fullWorkDays = this.getFullShortWorkDaysArray();

        for (let i = 0; i < fullWorkDays.length; i++) {
            const inputsStateName = this.lowercaseFirstLetter(fullWorkDays[i]) + 'Inputs';
            if (state.get(inputsStateName).disabled === false) {
                newState = newState.set(inputsStateName, {
                    disabled: false,
                    fromError: !Validators.isCorrectAMorPMHour(state.get('from' + fullWorkDays[i])),
                    toError: !Validators.isCorrectAMorPMHour(state.get('to' + fullWorkDays[i]))
                });
            }
        }

        // all inputs
        newState = newState.set('allInputs', {
            disabled: false,
            fromError: state.get('fromAll') && !Validators.isCorrectAMorPMHour(state.get('fromAll')),
            toError: state.get('toAll') && !Validators.isCorrectAMorPMHour(state.get('toAll'))
        });

        return newState;
    }

    public static getHourFromAmPM(stringAMORPM: string): number {
        const hour: number = parseInt(stringAMORPM.replace(this.AM_OR_PM_REGEX, ''), 10);

        if (stringAMORPM.indexOf('pm') !== -1 || stringAMORPM.indexOf('p') !== -1) {
            return hour + 12;
        } else if (hour === 12) {
            return 12;
        } else {
            return hour;
        }
    }

    public static convertFromInputToHour(stringAMORPM = ''): number {
        stringAMORPM = stringAMORPM.replace(/\s/g, '');
        if (!stringAMORPM) {
            return -1;
        }

        const hour: number = parseInt(stringAMORPM.replace(this.AM_OR_PM_REGEX, ''), 10);

        if (stringAMORPM === '12am' || stringAMORPM === '12a') {
            return 0;
        } else {
            return this.getHourFromAmPM(stringAMORPM);
        }
    }

    public static convertToInputToHour(stringAMORPM): number {
        stringAMORPM = stringAMORPM.replace(/\s/g, '');

        const hour: number = parseInt(stringAMORPM.replace(this.AM_OR_PM_REGEX, ''), 10);

        if (stringAMORPM === '12am' || stringAMORPM === '12a') {
            return 24;
        } else {
            return this.getHourFromAmPM(stringAMORPM);
        }
    }

    public static getToggleTabState(state, toggleFlag, disableTab1, disableTab2, disableTab3, disableTab4, updateState?: Function) {
        const currentState: boolean = state.get(toggleFlag);

        if (currentState === true) {
            return state
                .set(toggleFlag, false);
        } else {
            const stateToReturn = state
                .set(toggleFlag, true)
                .set(disableTab1, false)
                .set(disableTab2, false)
                .set(disableTab3, false)
                .set(disableTab4, false);

            if (updateState) {
                return updateState(stateToReturn);
            } else {
                return stateToReturn;
            }
        }
    }

    public static getInitProfileState(state, profile: IProfile) {
        return state.set('profile', profile)
            .set('company', profile.company)
            .set('phone', profile.phone);
    }

    public static cleanIncorrectInputs(state) {
        let newState = state;
        const fromAll = state.get('fromAll');
        const toAll = state.get('toAll');
        const fromAllC = this.convertFromInputToHour(state.get('fromAll'));
        const toAllC = this.convertToInputToHour(state.get('toAll'));
        const fromMoC = this.convertFromInputToHour(state.get('fromMo'));
        const toMoC = this.convertToInputToHour(state.get('toMo'));
        const fromTuC = this.convertFromInputToHour(state.get('fromTu'));
        const toTuC = this.convertToInputToHour(state.get('toTu'));
        const fromWeC = this.convertFromInputToHour(state.get('fromWe'));
        const toWeC = this.convertToInputToHour(state.get('toWe'));
        const fromThC = this.convertFromInputToHour(state.get('fromTh'));
        const toThC = this.convertToInputToHour(state.get('toTh'));
        const fromFrC = this.convertFromInputToHour(state.get('fromFr'));
        const toFrC = this.convertToInputToHour(state.get('toFr'));
        const fromSaC = this.convertFromInputToHour(state.get('fromSa'));
        const toSaC = this.convertToInputToHour(state.get('toSa'));
        const fromSuC = this.convertFromInputToHour(state.get('fromSu'));
        const toSuC = this.convertToInputToHour(state.get('toSu'));

        if (state.get('isHoursInputsVisible') === false) {
            if (fromAllC > toAllC || !fromAll || !toAll) {
                newState = newState.set('allInputs', {
                    disabled: false,
                    fromError: true,
                    toError: true
                });
            }
        } else if (state.get('isHoursInputsVisible') === true) {
            function setIncorrectStateFor(inputString, from, to, fromKey, toKey) {
                if (newState.get(inputString).disabled === false && (from > to || from === to || !from || !to)) {
                    newState = newState.set(inputString, {
                        disabled: false,
                        fromError: true,
                        toError: true
                    });
                }
            }

            setIncorrectStateFor('moInputs', fromMoC, toMoC, 'fromMo', 'toMo');
            setIncorrectStateFor('tuInputs', fromTuC, toTuC, 'fromTu', 'toTu');
            setIncorrectStateFor('weInputs', fromWeC, toWeC, 'fromWe', 'toWe');
            setIncorrectStateFor('thInputs', fromThC, toThC, 'fromTh', 'toTh');
            setIncorrectStateFor('frInputs', fromFrC, toFrC, 'fromFr', 'toFr');
            setIncorrectStateFor('saInputs', fromSaC, toSaC, 'fromSa', 'toSa');
            setIncorrectStateFor('suInputs', fromSuC, toSuC, 'fromSu', 'toSu');
        }

        return newState;
    }

    public static getDisableInputState(state, workDays: string[]) {
        let newState = state;
        const fullWorkDays = this.getFullShortWorkDaysArray();

        for (let i = 0; i < fullWorkDays.length; i++) {
            newState = newState.set(this.lowercaseFirstLetter(fullWorkDays[i]) + 'Inputs', {
                disabled: true,
                fromError: false,
                toError: false
            }).set(`from${this.capitalizeFirstLetter(fullWorkDays[i])}`, '')
                .set(`to${this.capitalizeFirstLetter(fullWorkDays[i])}`, '');
        }

        for (let i = 0; i < workDays.length; i++) {
            newState = newState.set(this.lowercaseFirstLetter(workDays[i]) + 'Inputs', {
                disabled: false,
                fromError: false,
                toError: false
            });
        }

        return newState;
    }

    public static getFullShortWorkDaysArray() {
        return R.clone(['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']);
    }

    public static isBefore(firstItem: IWorkHours, secondItem: IWorkHours) {
        const weekDays = {
            MONDAY      : 1,
            TUESDAY     : 2,
            WEDNESDAY   : 3,
            THURSDAY    : 4,
            FRIDAY      : 5,
            SATURDAY    : 6,
            SUNDAY      : 7
        };

        for (let i = 0; i < firstItem.days.length && i < secondItem.days.length; i++) {
            if (weekDays[firstItem.days[i]] > weekDays[secondItem.days[i]]) {
                return true;
            } else if (weekDays[firstItem.days[i]] < weekDays[secondItem.days[i]]) {
                return false;
            }
        }

        if (firstItem.days.length > secondItem.days.length) {
            return true;
        } else if (firstItem.days.length < secondItem.days.length) {
            return false;
        }

        if (moment(firstItem.begin, 'hh:mm').isAfter(moment(secondItem.begin, 'hh:mm'))) {
            return true;
        } else if (moment(firstItem.begin, 'hh:mm').isBefore(moment(secondItem.begin, 'hh:mm'))) {
            return false;
        } else if (moment(firstItem.end, 'hh:mm').isAfter(moment(secondItem.end, 'hh:mm'))) {
            return true;
        } else if (moment(firstItem.end, 'hh:mm').isBefore(moment(secondItem.end, 'hh:mm'))) {
            return false;
        }

        return false;
    }

    public static sortWorkWeekHours(workDays: IWorkHours[]) {
        for (let i = 0; i < workDays.length - 1; i++) {
            let curNum = i;
            for (let j = i; j < workDays.length; j++) {
                if (this.isBefore(workDays[curNum], workDays[j])) {
                    curNum = j;
                }
            }

            if (curNum !== i) {
                const midItem = workDays[i];
                workDays[i] = workDays[curNum];
                workDays[curNum] = midItem;
            }
        }
        return workDays;
    }
}
