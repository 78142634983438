// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".main__14jw2 {\n    margin-top: 140px;\n    min-height: calc(100vh - 360px);\n}\n\n    @media (max-width: 1250px) {.main__14jw2 {\n        margin-top: 60px;\n        min-height: calc(100vh - 280px)\n}\n    }\n", "",{"version":3,"sources":["Public.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,+BAA+B;AAMnC;;IAJI,4BAJJ;QAKQ,gBAAgB;QAChB;AAER;IADI","file":"Public.css","sourcesContent":[".main {\n    margin-top: 140px;\n    min-height: calc(100vh - 360px);\n\n    @media (max-width: 1250px) {\n        margin-top: 60px;\n        min-height: calc(100vh - 280px);\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"main": "main__14jw2"
};
module.exports = exports;
