import React, { ReactNode } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { useStripe } from '../../../utils/getStripe';
import { useTranslation } from 'react-i18next';

interface IStripeWrapperProps {
    children: ReactNode;
}

const StripeWrapper = (props: IStripeWrapperProps) => {
    const { i18n } = useTranslation();
    const stripe = useStripe(i18n.language);

    return (
        <StripeProvider stripe={stripe}>
            <Elements
                fonts={[
                    {
                        family: 'Source Sans Pro',
                        cssSrc: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300'
                    }
                ]}
            >
                {props.children}
            </Elements>
        </StripeProvider>
    );
};

export default StripeWrapper;
