// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__wSetg {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.main__1DbSt {\n    flex-grow: 1;\n    overflow: hidden;\n}\n", "",{"version":3,"sources":["FindServiceMobile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB","file":"FindServiceMobile.css","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.main {\n    flex-grow: 1;\n    overflow: hidden;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__wSetg",
	"main": "main__1DbSt"
};
module.exports = exports;
