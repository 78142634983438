import * as React from 'react';
import { Radio } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
const Styles = require('./BillingRadio.css');

interface IProps {
    billed: string;
    description: string;
    name: string;
    onChange?: any;
    checked: boolean;
    savedPercent?: number;
    disabled?: boolean;
}

export default function BillingRadio(props: IProps) {
    const { t } = useTranslation();
    const { billed, description, name, onChange, savedPercent, disabled, checked } = props;
    return (
        <div
            className={classNames({
                [Styles.BillingRadio]: true,
                [Styles.disabled]: disabled
            })}
            data-name={billed}
        >
            <div className={Styles.Text}>
                <label htmlFor={billed} className={Styles.Label}>
                    {`${t('plan.billed')} ${billed}`}
                    {savedPercent && ` (${t('plan.planTypeSave')} ${savedPercent}%)`}
                </label>
                <label htmlFor={billed} className={Styles.Label}>
                    {description}
                </label>
            </div>
            <div className={Styles.ButtonContainer}>
                <Radio
                    color="primary"
                    name={name}
                    id={billed}
                    value={billed}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                />
            </div>
        </div>
    );
}
