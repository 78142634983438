import * as React from 'react';
import IActions from '../../../actions/IActions';
import AutoRenew from './BillingSetupComponents/AutoRenew/AutoRenew';
import BillingRadio from './BillingSetupComponents/BillingRadio/BillingRadio';
import BillingSummary from './BillingSetupComponents/BillingSummary/BillingSummary';
import { FormHelperText } from '@material-ui/core';
import TextField from '../../_ReusableComponents/TextField/TextField';
import Button from '../../_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
const Styles = require('./BillingSetUp.css');

interface IBillingSetUpProps {
    plusPlanPayment: any;
    data: any;
    actions: IActions;
    onClick: any;
    touched: boolean;
}

export default function BillingSetUp(props: IBillingSetUpProps) {
    const { t } = useTranslation();
    const { plusPlanPayment, actions } = props;
    const monthlyPriceUSD = props.plusPlanPayment.get('monthlyPrice') / 100;
    const yearlyPriceUSD = props.plusPlanPayment.get('yearlyPrice') / 100;
    const licensesError = plusPlanPayment.get('tooManyLicensesError') || plusPlanPayment.get('invalidNumberError');
    const unitPrice = plusPlanPayment.get('billingType') === 'monthly'
        ? plusPlanPayment.get('monthlyPrice')
        : plusPlanPayment.get('yearlyPrice');
    const totalPrice = unitPrice / 100 * plusPlanPayment.get('amountOfLicenses');
    return (
        <div id="BillingSetup" className={Styles.root}>
            <div className={Styles.Header}>
                {t('plan.buyPlan')}
            </div>
            <BillingRadio
                data-name="monthly"
                billed={t('plan.monthly')}
                description={`$${monthlyPriceUSD} ${t('plan.licensedUser')}*/${t('plan.month')}`}
                name="billed"
                onChange={e => props.actions.setBillingType(e.currentTarget.value)}
                checked={props.plusPlanPayment.get('billingType') === 'monthly'}
            />
            <BillingRadio
                data-name="yearly"
                billed={t('plan.yearly')}
                description={`$${yearlyPriceUSD} ${t('plan.licensedUser')}*/${t('plan.year')}`}
                name="billed"
                onChange={e => props.actions.setBillingType(e.currentTarget.value)}
                checked={props.plusPlanPayment.get('billingType') === 'yearly'}
                savedPercent={Math.round((1 - (yearlyPriceUSD / (monthlyPriceUSD * 12))) * 100)}
            />
            <div className={Styles.TotalLicensesContainer}>
                <label htmlFor="total-licenses">
                    {t('plan.totalLicenses')}:
                </label>
                <TextField
                    variant="outlined"
                    id="total-licenses"
                    type="number"
                    margin="dense"
                    className={Styles.LicensesInput}
                    inputProps={{
                        min: 1,
                        max: 100
                    }}
                    value={plusPlanPayment.get('amountOfLicenses')}
                    onChange={({ target }) => actions.changeAmountOfLicenses(target.value)}
                    error={licensesError}
                />
                {licensesError && (
                    <FormHelperText error className={Styles.Error} data-testid="licenses-error">
                        {(plusPlanPayment.get('tooManyLicensesError') && t('plan.maxLicenses')) ||
                        (plusPlanPayment.get('invalidNumberError') && t('common.invalidNumber'))}
                    </FormHelperText>
                )}
            </div>
            <BillingSummary
                amount={totalPrice.toFixed(2)}
            />
            <AutoRenew
                actions={props.actions}
                plusPlanPayment={props.plusPlanPayment}
            />
            <div className={Styles.BillingSetUp_TaxesInfo}>
                {t('plan.taxesInfo')}
            </div>
            <div className={Styles.Navigation}>
                <Button
                    variant="contained"
                    className={Styles.ContinueButton}
                    onClick={props.onClick}
                    data-testid="continue"
                >
                    {t('plan.continue')}
                </Button>
            </div>
            <div className={Styles.Explanation}>
                * {t('plan.licensedUserFootnote')}
            </div>
        </div>
    );
}
