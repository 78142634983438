import * as React from 'react';
import IActions from '../../../actions/IActions';
import BillingSetUp from '../BillingSetUp/BillingSetUp';
import PlaceOrder from '../PlaceOrder/PlaceOrder';
import LinkToFAQ from './LinkToFAQ/LinkToFAQ';
import containersMapStateToProps from '../../../utils/containersMapStateToProps';
import containersDispatchToProps from '../../../utils/containersMapDispatchToProps';
import { connect } from 'react-redux';
import FallingLeaves from '../../_ReusableComponents/FallingLeaves/FallingLeaves';
import getStripe from '../../../utils/getStripe';
import ICardDetails from '../../../interfaces/backendResponses/ICardDetails';
import BillingCheckout from '../../_ReusableComponents/BillingCheckout/BillingCheckout';
import StripeWrapper from '../../_ReusableComponents/StripeWrapper/StripeWrapper';
import { WithTranslation, withTranslation } from 'react-i18next';
import GlobalContext, { IGlobalContext, withGlobalContext } from '../../../GlobalContext';
const Styles = require('./PlusPlanPayment.css');

interface IPlusPlanPaymentProps extends WithTranslation {
    plusPlanPayment: any;
    data: any;
    actions: IActions;
    history: any;
    context: IGlobalContext;
}

interface IPlusPlanPaymentState {
    touched: boolean;
    paymentState: 'initial' | 'processing' | 'success' | 'failure';
}

class PlusPlanPayment extends React.Component<
    IPlusPlanPaymentProps,
    IPlusPlanPaymentState
> {
    constructor(props) {
        super(props);
        this.state = {
            touched: false,
            paymentState: 'initial'
        };
    }

    componentDidMount(): void {
        this.props.actions.getSubscriptionPlans();
    }

    async componentDidUpdate(prevProps) {
        const { data, plusPlanPayment, history, actions, i18n } = this.props;
        const token = plusPlanPayment.get('3DSecureToken');
        if (token && token !== prevProps.plusPlanPayment.get('3DSecureToken')) {
            const stripe = await getStripe(i18n.language);
            const { error } = await stripe.handleCardPayment(token);
            actions.threeDSecureComplete(!error);
        }
        if (data.get('subscription') && data.get('subscription').status && plusPlanPayment.get('currentPaymentStep') !== 3) {
            history.push('/manage');
            actions.resetPayment();
        }
        if (plusPlanPayment.get('currentPaymentStep') === 3) {
            if (plusPlanPayment.get('paymentStatus') === 'COMPLETE') {
                history.push('/payment-success', { wizard: true });
            } else {
                history.push('/payment-failed');
            }
        }
    }

    proceed = e => {
        e.preventDefault();
        this.setState({ touched: true });
        const { actions, plusPlanPayment } = this.props;
        const licenses = plusPlanPayment.get('amountOfLicenses');
        const licensesNumber = Number(licenses);
        if (licenses === '' || licensesNumber < 1 || licensesNumber % 1 !== 0 || Number.isNaN(licensesNumber)) {
            actions.paymentSetInvalidNumberError(true);
        } else if (licensesNumber > 100) {
            actions.paymentSetTooManyLicensesError(true);
        } else {
            actions.goToPayment();
        }
    };

    createSubscription = (event) => {
        event.preventDefault();
        const { plusPlanPayment } = this.props;
        this.props.actions.createSubscription(
            plusPlanPayment.get('billingType'),
            Number(plusPlanPayment.get('amountOfLicenses')),
            plusPlanPayment.get('autoRenew')
        );
    };

    onCardSaved = (cardDetails: ICardDetails) => {
        const { plusPlanPayment, actions } = this.props;
        actions.cardDetailsReceived(cardDetails);
        const interval = plusPlanPayment.get('billingType') === 'monthly' ? 'MONTH': 'YEAR';
        actions.previewOrder(
            interval,
            plusPlanPayment.get('amountOfLicenses'),
            plusPlanPayment.get('autoRenew')
        );
    };

    render() {
        if (!this.props.plusPlanPayment.get('plansLoaded')) {
            return <FallingLeaves />;
        }
        const currentPaymentStep = this.props.plusPlanPayment.get('currentPaymentStep');
        return (
            <main
                className={Styles.PlusPlanPayment}
                data-testid="plusPlanPayment"
            >
                {currentPaymentStep === 0 && (
                    <BillingSetUp
                        {...this.props}
                        onClick={this.proceed}
                        touched={this.state.touched}
                    />
                )}
                {currentPaymentStep === 1 && (
                    <StripeWrapper>
                        <BillingCheckout
                            onBackClick={this.props.actions.goBack}
                            onSuccess={this.onCardSaved}
                        />
                    </StripeWrapper>
                )}
                {currentPaymentStep === 2 && (
                    <PlaceOrder
                        handleSubmit={this.createSubscription}
                        goBack={this.props.actions.goBack}
                        plusPlanPayment={this.props.plusPlanPayment}
                        timezone={this.props.context.profile.timezone}
                    />
                )}
                <LinkToFAQ />
            </main>
        );
    }
}

export default withTranslation()(withGlobalContext(connect(
    containersMapStateToProps,
    containersDispatchToProps
)(PlusPlanPayment)));
