import React from 'react';
import moment from 'moment-timezone';
import IDateRange from '../interfaces/IDateRange';
import { TFunction } from 'i18next';

export default function getRanges(timezone: string, t: TFunction, language: string, country?: string) {
    const now = () => moment().tz(timezone);
    const todayRange: IDateRange = {
        dateFrom: now().startOf('day').toISOString(),
        dateTo: now().endOf('day').toISOString(),
    };
    const tomorrowRange: IDateRange = {
        dateFrom: now().add(1, 'day').startOf('day').toISOString(),
        dateTo: now().add(1, 'day').endOf('day').toISOString(),
    };
    const thisWeekRange = country ?
        {
            dateFrom: now().startOf('day').toISOString(),
            dateTo: country === 'ca' || country === 'us' ? now().add((13-now().day())%7, 'days').endOf('day').toISOString()
                : now().isoWeekday(7).endOf('day').toISOString()
        }: {
            dateFrom: now().startOf('day').toISOString(),
            dateTo: now().endOf('week').toISOString(),
        };
    const nextWeekRange = country ?
        {
            dateFrom: country === 'ca' || country === 'us'
                ? now().add((13 - now().day()) % 7 + 1, 'days').startOf('day').toISOString()
                : now().add(1, 'week').isoWeekday(1).startOf('day').toISOString(),
            dateTo: country === 'ca' || country === 'us'
                ? now().add((13 - now().day()) % 7 + 7, 'days').endOf('day').toISOString()
                : now().add(1, 'week').isoWeekday(7).endOf('day').toISOString()
        }: {
            dateFrom: now().add(1, 'week').startOf('week').toISOString(),
            dateTo: now().add(1, 'week').endOf('week').toISOString(),
        };
    const formatDate = (date: string) => moment.tz(date, timezone)
        .format(language === 'pl' ? 'D MMM' : 'MMM D');
    const isSameRange = (range1: IDateRange, range2: IDateRange) =>
        moment(range1.dateFrom).isSame(range2.dateFrom) && moment(range1.dateTo).isSame(range2.dateTo);

    const renderRange = (dateRange: IDateRange) => {
        const from = moment.tz(dateRange.dateFrom, timezone);
        const to = moment.tz(dateRange.dateTo, timezone);
        if (dateRange === null) {
            return '';
        } else if (isSameRange(dateRange, todayRange)) {
            return <b>{t('common.today')}</b>;
        } else if (isSameRange(dateRange, tomorrowRange)) {
            return <b>{t('common.tomorrow')}</b>;
        } else if (isSameRange(dateRange, thisWeekRange)) {
            return <b>{t('common.thisWeek')}</b>;
        } else if (isSameRange(dateRange, nextWeekRange)) {
            return <b>{t('common.nextWeek')}</b>;
        } else if (from.isSame(to, 'day')) {
            return <>{t('common.onDate')}<b style={{whiteSpace: 'pre'}}>{' '}{formatDate(dateRange.dateFrom)}</b></>;
        } else {
            const startNow = from.isBefore(now()) && to.isAfter(now());
            return (
                <>
                    <b>
                        {startNow ? t('common.now') : formatDate(dateRange.dateFrom)}
                        {' '}
                        {'-'}
                        {' '}
                        {formatDate(dateRange.dateTo)}
                    </b>
                </>
            );
        }
    };

    const getRangeName = (dateRange: IDateRange) => {
        if (dateRange === null) {
            return '';
        } else if (isSameRange(dateRange, todayRange)) {
            return 'today';
        } else if (isSameRange(dateRange, tomorrowRange)) {
            return 'tomorrow';
        } else if (isSameRange(dateRange, thisWeekRange)) {
            return 'thisWeek';
        } else if (isSameRange(dateRange, nextWeekRange)) {
            return 'nextWeek';
        } else {
            return 'custom';
        }
    };

    return {
        todayRange, tomorrowRange, thisWeekRange, nextWeekRange, renderRange, getRangeName
    };
}
