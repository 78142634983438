import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Rating from '@material-ui/core/Rating';
import { ILocationSearchResult } from '../../../interfaces/interfaces';
import { getSubdomain } from 'utils/subdomain';
import { ICompaniesRating } from 'interfaces/ICompaniesRating';
import GlobalContext from 'GlobalContext';
import { useIsDesktop } from 'utils/responsive';
import { polishPlurals } from 'polish-plurals';
import styles from './CompanyService.css';
import { useTranslation } from 'react-i18next';

type Props = {
    location: ILocationSearchResult | null;
    company: ICompaniesRating | null;
    setCompanyId?: React.Dispatch<React.SetStateAction<number>>;
};

export const CompanyService: React.FC<Props> = ({ location, company, setCompanyId }) => {
    const { t, i18n } = useTranslation();
    const isDesktop = useIsDesktop();
    const history = useHistory();
    const [isRating, setIsRating] = useState<boolean>(false);
    const [ratingValue, setRatingValue] = useState<number | null>(null);
    const [ratingNum, setRatingNum] = useState<number | null>(null);
    const { profile } = useContext(GlobalContext);
    const commentsLabel = polishPlurals.bind(null, 'opinia', 'opinie', 'opinii');
    useEffect(() => {
        if (company) {
            setIsRating(true);
            setRatingNum(company.votes);
            setRatingValue(company.rating);
        }
    }, [company]);
    const getRatingNum = (ratings) => {
        const enRating = ratings === 1 ? 'rating' : 'ratings';
        return i18n.language === 'pl' ? commentsLabel(ratings) : enRating;
    };
    const RatingContent = () => {
        return (
            <div className={styles.ratingBox}>
                <>
                    <Box component="fieldset" p={0} m={0} borderColor="transparent">
                        <Rating
                            name="rating"
                            value={isRating? ratingValue: null}
                            readOnly
                            precision={0.1}
                        />
                    </Box>
                    {isRating && (
                        <h3 className={styles.ratingValue}>{ratingValue.toFixed(1)}</h3>
                    )}
                </>
                <Button
                    className={styles.ratingNum}
                    onClick={(ev) => {
                        ev.preventDefault();
                        if (!!setCompanyId) {
                            setCompanyId(location.companyId);
                        } else {
                            const url = profile && profile.appointments?.companyId ? `/sb/reviews/${location.companyId}` : `/sb-customer/reviews/${location.companyId}`;
                            history.push(url);
                        }
                    }}
                >
                    {isRating? `${ratingNum} ${getRatingNum(ratingNum)}`: t('small.noRatings')}
                </Button>
            </div>
        );
    };

    return (
        <div className={styles.location}>
            {location ? (<>
                <Link
                    onClick={() => {
                        if (!!setCompanyId) {
                            setCompanyId(location.companyId);
                        }
                    }}
                    to={!!setCompanyId ? '/sb/customer'
                        : profile && profile.appointments?.companyId
                            ? `/sb/reviews/${location.companyId}`
                            : `/sb-customer/book/${location.companyId}/${location.locationId}`}
                    className={styles.link}
                >
                    <div className={styles.photoContent}>
                        <div
                            className={styles.photo}
                            style={location.locationPhoto ? { backgroundImage: `url(${location.locationPhoto})` } : {}}
                        />
                        {isRating && (
                            <div className={styles.ratingContent}>
                                <h2 className={styles.rating}>{ratingValue.toFixed(1)}</h2>
                            </div>
                        )}
                    </div>
                    <div className={styles.name}>
                        {getSubdomain() === null && (
                            <span className={styles.companyName}>
                                {location.companyName}
                                {' '}
                            </span>
                        )}
                        {location.locationName}
                    </div>
                    <div className={styles.address}>
                        {location.locationAddress}
                    </div>
                    {isDesktop && (
                        <RatingContent />
                    )}
                </Link>
                {!isDesktop? (
                    <RatingContent />
                ): null}
            </>) : (<>
                <div className={styles.link}>
                    <div className={styles.photoContent}>
                        <div className={styles.photo}>
                            <Skeleton variant="rectangular" width={'100%'} height={170} />
                        </div>
                        <div className={styles.ratingContentSkeleton}>
                            <Skeleton variant="rectangular" width={50} height={50} />
                        </div>
                    </div>
                    <div className={styles.name}>
                        <Skeleton variant="rectangular" width={'100%'} height={16} style={{ marginBottom: 10, marginTop: 10}} />
                        <Skeleton variant="rectangular" width={'100%'} height={16} style={{marginBottom: 10}} />
                        <Skeleton variant="rectangular" width={'100%'} height={16} />
                    </div>
                </div>
            </>)}
        </div>
    );
};
