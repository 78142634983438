import React, { useMemo, useState } from 'react';
import Select from '../Select/Select';
import axios from 'axios';
import debounce from 'debounce-promise';
import { useTranslation } from 'react-i18next';
const config = require('CONFIG');

interface Props {
    onChange: (timezone: string) => void;
    onBlur: () => void;
}

export default function TimeZoneSelect(props: Props) {
    const { t } = useTranslation();
    const [error, setError] = useState(null);

    const formatSubLabel = (x) => {
        if (x.adminName1 && x.countryName && x.adminName1 !== x.name && x.countryName !== x.name) {
            return `${x.adminName1}, ${x.countryName}`;
        }
        if (x.countryName && x.countryName !== x.name) {
            return x.countryName;
        }
    };

    const loadTimeZoneOptions = useMemo(() =>
        debounce(query => axios.get(
            'https://secure.geonames.net/searchJSON',
            {
                params: {
                    username: config.geonamesUsername,
                    q: query,
                    maxRows: 5
                }
            }
        ).then(({ data }) => data.geonames.map(x => ({
            value: x.geonameId,
            label: x.name,
            subLabel: formatSubLabel(x)
        }))), 300),
        []
    );

    return (
        <Select
            async
            label={t('common.location')}
            loadOptions={loadTimeZoneOptions}
            value={null}
            onChange={async (option) => {
                const { data } = await axios.get(
                    'https://secure.geonames.net/getJSON',
                    {
                        params: {
                            username: config.geonamesUsername,
                            geonameId: option.value
                        }
                    }
                );
                if (data.timezone) {
                    props.onChange(data.timezone.timeZoneId);
                } else {
                    setError(t('common.multipleTimezones', { location: option.label }));
                }
            }}
            onBlur={props.onBlur}
            autoFocus
            menuIsOpen
            error={Boolean(error)}
            helperText={error}
            id="timezone-select"
        />
    );
}
