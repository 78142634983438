import React, { useState } from 'react';
import * as dateUtils from '../../../../utils/dateUtils/dateUtils';
import IMeetingFromToDates from 'interfaces/IMeetingFromToDates';
import IProposition from '../../../../interfaces/IProposition';
import moment from 'moment';
import { Popover } from '@material-ui/core';
import CalendarPopover from '../../CalendarPopover/CalendarPopover';
import { useTranslation } from 'react-i18next';
import { useIsDesktop } from '../../../../utils/responsive';
import classNames from 'classnames';
const Styles = require('./ScheduledDate.css');

interface Props {
    proposition: IProposition;
    meeting: any;
    id: number;
    timezone: string;
    timeFormat: string;
    meetingDate: any;
    mainPage?: boolean;
}

const ScheduledDate = (props: Props) => {
    const { t, i18n } = useTranslation();
    const isDesktop = useIsDesktop();
    const { proposition } = props;
    const dateLines: IMeetingFromToDates = dateUtils.getDateLines(
        proposition.earliest,
        moment(proposition.latest).diff(proposition.earliest, 'seconds'),
        props.timezone,
        props.timeFormat,
        t,
        i18n.language,
    );
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    return (
        <>
            <div className={Styles.ScheduledDateSameDay}>
                <div
                    onClick={event => !props.mainPage && setAnchorEl(event.currentTarget)}
                >
                    <span className={classNames(Styles.dateFrom, {
                        [Styles.dateFromDesktop]: isDesktop,
                    })}>
                        {dateLines.dateFrom}
                    </span>
                    <span className={Styles.FullDate}>
                        {dateLines.dateFromSecondLine}
                    </span>
                </div>
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                PaperProps={{ className: Styles.scheduledDate_popover }}
                onClose={() => setAnchorEl(null)}
                disableScrollLock
            >
                <CalendarPopover
                    {...props}
                    timezone={props.timezone}
                />
            </Popover>
        </>
    );
};

export default ScheduledDate;
