import ISupportMessageJson from 'interfaces/ISupportMessageJson';
import BackendMethods from 'api/BackendMethods';
import { IMultipleTypesAction, ISingleTypeAction } from 'interfaces/IAction';

export const HIDE_MOBILE_MENU = 'HIDE_MOBILE_MENU';
export const SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU';
export const DISABLE_CLICK_EVENTS = 'DISABLE_CLICK_EVENTS';
export const ENABLE_CLICK_EVENTS = 'ENABLE_CLICK_EVENTS';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE';
export const TOGGLE_MEETING_NOTES = 'TOGGLE_MEETING_NOTES';
export const TOGGLE_SUPPORT_MODAL = 'TOGGLE_SUPPORT_MODAL';
export const SEND_SUPPORT_MESSAGE_PENDING = 'SEND_SUPPORT_MESSAGE_PENDING';
export const SEND_SUPPORT_MESSAGE_REJECTED = 'SEND_SUPPORT_MESSAGE_REJECTED';
export const SEND_SUPPORT_MESSAGE_FULFILLED = 'SEND_SUPPORT_MESSAGE_FULFILLED';
export const HIDE_SUCCESS_MESSAGE = 'HIDE_SUCCESS_MESSAGE';

export default class AppActions {
    showErrorMessage(message: string): ISingleTypeAction {
        return {
            type: SHOW_ERROR_MESSAGE,
            payload: message
        };
    }

    showSuccessMessage(message: string): ISingleTypeAction {
        return {
            type: SHOW_SUCCESS_MESSAGE,
            payload: message
        };
    }

    hideErrorMessage(): ISingleTypeAction {
        return {
            type: HIDE_ERROR_MESSAGE,
            payload: {}
        };
    }

    hideSuccessMessage(): ISingleTypeAction {
        return {
            type: HIDE_SUCCESS_MESSAGE,
            payload: {}
        };
    }

    showMobileMenu(): ISingleTypeAction {
        return {
            type: SHOW_MOBILE_MENU,
            payload: {}
        };
    }

    hideMobileMenu(): ISingleTypeAction {
        return {
            type: HIDE_MOBILE_MENU,
            payload: {}
        };
    }

    sendSupportMessage(
        supportMessageJson: ISupportMessageJson
    ): IMultipleTypesAction {
        return {
            types: [
                SEND_SUPPORT_MESSAGE_PENDING,
                SEND_SUPPORT_MESSAGE_FULFILLED,
                SEND_SUPPORT_MESSAGE_REJECTED
            ],
            payload: {
                promise: BackendMethods.sendSupportMessage(supportMessageJson)
            }
        };
    }

    toggleSupportModal(): ISingleTypeAction {
        return {
            type: TOGGLE_SUPPORT_MODAL,
            payload: {}
        };
    }
}
