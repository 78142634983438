// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".VerifyEmail__uO4pB {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n}\n\n.VerifyEmail__uO4pB > p {\n    color: #e63e25;\n}\n\n.VerifyEmail__uO4pB > span {\n    display: block;\n}\n\n.VerifyEmail__uO4pB > button {\n    margin-top: 24px;\n}\n\n@media only screen and (max-width: 500px) {\n    .VerifyEmail__uO4pB > button {\n        width: 100%;\n    }\n}\n", "",{"version":3,"sources":["VerifyEmail.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,WAAW;IACf;AACJ","file":"VerifyEmail.css","sourcesContent":[".VerifyEmail {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n}\n\n.VerifyEmail > p {\n    color: #e63e25;\n}\n\n.VerifyEmail > span {\n    display: block;\n}\n\n.VerifyEmail > button {\n    margin-top: 24px;\n}\n\n@media only screen and (max-width: 500px) {\n    .VerifyEmail > button {\n        width: 100%;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"VerifyEmail": "VerifyEmail__uO4pB"
};
module.exports = exports;
