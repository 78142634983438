// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ChromeExtensionInfo__HBlwZ {\n    cursor: pointer;\n    font-size: 13px;\n    margin: 4px -12px 0 16px;\n}\n\n.ChromeExtensionInfo__HBlwZ strong {\n    font-size: 20px;\n}\n\n.badge__AppmK {\n    width: 100%;\n}\n\n.ForCalendar__2fOut {\n    color: #00aad4;\n    float: right;\n    font-size: 13px;\n    font-weight: bold;\n}\n\n@media only screen and (max-width: 1024px) {\n\n    .ChromeExtensionInfo__HBlwZ {\n        display: none;\n        margin: 0;\n    }\n}\n", "",{"version":3,"sources":["ChromeExtensionInfo.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,eAAe;IACf,iBAAiB;AACrB;;AAEA;;IAEI;QACI,aAAa;QACb,SAAS;IACb;AACJ","file":"ChromeExtensionInfo.css","sourcesContent":[".ChromeExtensionInfo {\n    cursor: pointer;\n    font-size: 13px;\n    margin: 4px -12px 0 16px;\n}\n\n.ChromeExtensionInfo strong {\n    font-size: 20px;\n}\n\n.badge {\n    width: 100%;\n}\n\n.ForCalendar {\n    color: #00aad4;\n    float: right;\n    font-size: 13px;\n    font-weight: bold;\n}\n\n@media only screen and (max-width: 1024px) {\n\n    .ChromeExtensionInfo {\n        display: none;\n        margin: 0;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"ChromeExtensionInfo": "ChromeExtensionInfo__HBlwZ",
	"badge": "badge__AppmK",
	"ForCalendar": "ForCalendar__2fOut"
};
module.exports = exports;
