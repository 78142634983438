import React, { useEffect } from 'react';
import { IUnseenMeetings } from '../../../interfaces/interfaces';
import BackendMethods from '../../../api/BackendMethods';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ITab } from '../../../containers/MainPage/MainPage';
import useAsyncEffect from '../../../utils/useAsyncEffect';
import IActions from '../../../actions/IActions';
import { useHistory } from 'react-router-dom';
const styles = require('./FilteringButtons.css');

interface Props {
    actions: IActions;
    data: any;
    isScrolled?: boolean;
    tab: ITab;
    setTab: (tab: ITab) => void;
}

type ICategory = 'scheduled' | 'canceled' | 'inprogress';

export default function FilteringButtons(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const { tab, setTab } = props;

    const unseenMeetings: IUnseenMeetings = props.data.get('unseenMeetings');
    const setUnseenMeetings = (newUnseenMeetings: Partial<IUnseenMeetings>) => (
        props.actions.updateData({
            unseenMeetings: { ...unseenMeetings, ...newUnseenMeetings },
        })
    );
    const discardUnseenMeetings = (
        currentUnseenMeetings: IUnseenMeetings,
        category: ICategory,
    ) => {
        if (currentUnseenMeetings[category] > 0) {
            setUnseenMeetings({ [category]: 0 });
            BackendMethods.discardUnseenMeetings(category);
        }
    };
    useAsyncEffect(async () => {
        const newUnseenMeetings = await BackendMethods.getUnseenMeetings();
        setUnseenMeetings(newUnseenMeetings);
        discardUnseenMeetings(newUnseenMeetings, 'inprogress');
    }, []);
    useEffect(() => {
        const categoryMap: Record<ITab, ICategory> = {
            IN_PROGRESS: 'inprogress',
            SCHEDULED: 'scheduled',
            CANCELED: 'canceled',
        };
        const tabCategory = categoryMap[tab];
        if (unseenMeetings[tabCategory] > 0) {
            discardUnseenMeetings(unseenMeetings, tabCategory);
        }
    }, [unseenMeetings]);
    return (
        <div
            id="FilteringButtons"
            className={classNames(styles.FilteringButtons, {
                [styles.isScrolled]: props.isScrolled,
            })}
        >
            <div
                onClick={() => {
                    discardUnseenMeetings(unseenMeetings, 'inprogress');
                    setTab('IN_PROGRESS');
                    history.push('/main');
                }}
                className={classNames(styles.Button, {
                    [styles.active]: tab === 'IN_PROGRESS',
                })}
                data-testid="filter-in-progress"
            >
                <span className={styles.tabName}>
                    {t('home.inProgress')}
                    {unseenMeetings.inprogress > 0 && (
                        <span className={styles.unseen}>
                            {unseenMeetings.inprogress}
                        </span>
                    )}
                </span>
            </div>
            <div
                onClick={() => {
                    discardUnseenMeetings(unseenMeetings, 'scheduled');
                    setTab('SCHEDULED');
                    history.push('/main');
                }}
                className={classNames(styles.Button, {
                    [styles.active]: tab === 'SCHEDULED',
                })}
                data-testid="filter-scheduled"
            >
                <span className={styles.tabName}>
                    {t('home.scheduled')}
                    {unseenMeetings.scheduled > 0 && (
                        <span className={styles.unseen}>
                            {unseenMeetings.scheduled}
                        </span>
                    )}
                </span>
            </div>
            <div
                onClick={() => {
                    discardUnseenMeetings(unseenMeetings, 'canceled');
                    setTab('CANCELED');
                    history.push('/main');
                }}
                className={classNames(styles.Button, {
                    [styles.active]: tab === 'CANCELED',
                })}
                data-testid="filter-canceled"
            >
                <span className={styles.tabName}>
                    {t('home.canceled')}
                    {unseenMeetings.canceled > 0 && (
                        <span className={styles.unseen}>
                            {unseenMeetings.canceled}
                        </span>
                    )}
                </span>
            </div>
        </div>
    );
}
