import { useEffect, useState } from 'react';
const config = require('CONFIG');

declare global {
    interface Window {
        Stripe: any;
    }
}

export default function getStripe(language: string): any {
    return new Promise(resolve => {
        if (window.Stripe) {
            resolve(window.Stripe(config.stripeKey, { locale: language }));
        } else {
            document
                .querySelector('#stripe-js')
                .addEventListener('load', () => {
                    resolve(window.Stripe(config.stripeKey, { locale: language }));
                });
        }
    });
}

export function useStripe(language: string) {
    const [stripe, setStripe] = useState(null);
    useEffect(() => {
        (async () => {
            const resolvedStripe = await getStripe(language);
            setStripe(resolvedStripe);
        })();
    }, []);
    return stripe;
}
