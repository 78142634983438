import React from 'react';
import Styles from './OtherFeatures.css';
import { useTranslation } from 'react-i18next';
export const OtherFeatures = () => {
    const { t } = useTranslation();
    return (
        <div className={Styles.content}>
            <div className={Styles.sectionHeader}>
                {t('small.additionalFeatures')}
            </div>
            <div className={Styles.body}>
                <ul className={Styles.item}>
                    <li>
                        {t('small.automaticallyTagsAppointments')}
                    </li>
                    <li>
                        {t('small.managesEquipment')}
                    </li>
                    <li>
                        {t('small.allowsYourCustomersToBook')}
                    </li>
                    <li>
                        {t('small.easilyHandlesTheOfferedServices')}
                    </li>
                    <li>
                        {t('small.allowsYouToSpecify')}
                    </li>
                    <li>
                        {t('small.automaticallyAccountsForHolidays')}
                    </li>
                    <li>
                        {t('small.englishAndPolishLanguageVersionsForConsumersAndEmployees')}
                    </li>
                    <li>
                        {t('small.availableOnYourLaptop')}
                    </li>
                    <li>
                        {t('small.easyToIntegrateWithYourWebsite')}
                    </li>
                    <li>
                        {t('small.supportWhichFitsYourNeeds')}
                    </li>
                    <li>
                        {t('small.quickAdoptionOfTheBookingSystem')}
                    </li>
                </ul>
            </div>
        </div>
    );
};
