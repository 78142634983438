import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getAuthToken, getSearchParam } from '../../scripts/helpers';
import PhoneLoginComponent from './PhoneLoginComponent';
const styles = require('./PhoneLogin.css');

export default function PhoneLogin() {
    const location = useLocation();
    const history = useHistory();
    const isMerge = getSearchParam(location.search, 'merge');
    const redirectURI = getSearchParam(location.search, 'redirectURI');
    useEffect(() => {
        if (isMerge) {
            localStorage.setItem('mergeAuthToken', getAuthToken());
        }
        if (redirectURI) {
            localStorage.setItem('mergeRedirectURI', redirectURI);
        }
        if (isMerge || redirectURI) {
            history.replace('/login-phone');
        }
    }, [isMerge, redirectURI]);
    return (
        <div className={styles.root}>
            <PhoneLoginComponent />
        </div>
    );
}
