import React from 'react';
import ENDataProcessingContract from 'components/Data/DataProcessingContract/en.json';
import PLDataProcessingContract from 'components/Data/DataProcessingContract/pl.json';
import { useTranslation } from 'react-i18next';
import {Privacy} from 'components/_ReusableComponents/Privacy/Privacy';
import Styles from './DataProcessingContract.css';

export const DataProcessingContract = () => {
    const { i18n } = useTranslation();
    const data = i18n.language === 'en' ? ENDataProcessingContract : PLDataProcessingContract;

    return (
        <>
            <div className={Styles.root}>
                <Privacy
                    data={data}
                />
            </div>
        </>
    );
};
