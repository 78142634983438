import React, { useEffect, useState } from 'react';
import Select from '../../_ReusableComponents/Select/Select';
import Validators from '../../../utils/Validators/Validators';
import * as R from 'ramda';
import MeLabel from '../../_ReusableComponents/Label/MeLabel';
import { IconButton, Dialog } from '@material-ui/core';
import TimeZoneSelect from '../../_ReusableComponents/TimeZoneSelect/TimeZoneSelect';
import IProfile from '../../../interfaces/IProfile';
import { IConnection, IParticipant } from '../../../interfaces/interfaces';
import BackendMethods from '../../../api/BackendMethods';
import classNames from 'classnames';
import Button from '../../_ReusableComponents/Button/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTimezone } from 'countries-and-timezones';
const styles = require('./Participants.css');

interface Props {
    participants: Record<string, IParticipant>;
    onChange: (updateParticipants: (participants: Record<string, IParticipant>) => Record<string, IParticipant>) => void;
    error: string | null;
    profile: IProfile;
    disabled?: boolean;
    data: any;
    onLinkClick: () => void;
}

export default function Participants(props: Props) {
    const { t } = useTranslation();
    const { participants, onChange, error, profile } = props;
    const myMeetingTimezone = participants[profile.email].timezone;

    const [contacts, setContacts] = useState(null);
    const [showLink, setShowLink] = useState(false);
    useEffect(() => {
        (async () => {
            const newContacts = await BackendMethods.getContacts();
            setContacts(newContacts.map(R.over(
                R.lensProp('timezone'),
                R.defaultTo(myMeetingTimezone),
            )));
            const connections: IConnection[] = props.data.get('connections');
            const accounts = connections.filter((x) => x.scopes.includes('CONTACTS'));
            setShowLink(accounts.length === 0);
        })();
    }, []);

    const contactsOptions = (contacts || []).filter(({ email }) => !participants[email]).map(contact => ({
        label: contact.name || contact.email,
        subLabel: contact.name ? contact.email : null,
        value: contact.email,
        participant: contact
    }));

    const [dialogOpen, setDialogOpen] = useState(false);

    if (!contacts) {
        return null;
    }

    return <>
        <Select
            creatable
            async
            label={t('new.addParticipants')}
            loadOptions={(inputValue) => Promise.resolve(
                contactsOptions.filter((x) => (
                    x.label.toLowerCase().includes(inputValue.toLowerCase())
                    || (x.subLabel || '').toLowerCase().includes(inputValue.toLowerCase())
                )).slice(0, 100)
            )}
            isValidNewOption={query => (
                Validators.isValidEmail(query)
                    && !contactsOptions.find(R.propEq('value', query))
                    && !participants[query]
            )}
            getNewOptionData={email => ({
                label: email,
                value: email,
                participant: {
                    email,
                    adhoc: false,
                    timezone: myMeetingTimezone,
                }
            })}
            value={null}
            onChange={async (option, { action }) => {
                if (!profile.plusPlan.active && R.values(participants).length >= 11) {
                    setDialogOpen(true);
                    return;
                }
                onChange(R.set(
                    R.lensProp(option.value),
                    {
                        ...option.participant,
                        editingTimeZone: false,
                        optional: false,
                    }
                ));
                if (action === 'create-option') {
                    const participantProfile = await BackendMethods.getSimpleProfile(option.value);
                    onChange(R.over(
                        R.lensProp(option.value),
                        R.mergeLeft(participantProfile)
                    ));
                }
            }}
            error={error !== null}
            helperText={error}
            isDisabled={props.disabled}
            inputId="participants"
            dontClearOnBlur
            noOptionsMessage={() => t('new.startTyping')}
        />
        {showLink && (
            <div className={styles.accessContacts}>
                <Button
                    variant="text"
                    component={Link}
                    to="/preferences?openTab=integrations"
                    onClick={props.onLinkClick}
                >
                    {t('new.accessContacts')}
                </Button>
            </div>
        )}
        {R.values(participants).map(participant => (
            <div
                key={participant.email}
                className={classNames({
                    [styles.participant]: true,
                    [styles.disabled]: props.disabled
                })}
            >
                <div className={styles.participantLeft}>
                    <div className={styles.participantNameContainer}>
                        <div className={styles.participantName}>
                            {participant.name || participant.email}
                        </div>
                        <MeLabel
                            isUserOrganizer={participant.email === profile.email}
                            text={t('common.you')}
                            modifierClass={styles.meLabel}
                        />
                        {participant.optional && (
                            <div className={styles.optionalLabel}>
                                {t('new.optional')}
                            </div>
                        )}
                    </div>
                    <div className={styles.participantTimeZone}>
                        {participant.timezone}
                        <IconButton
                            onClick={() => onChange(R.set(
                                R.lensPath([participant.email, 'editingTimeZone']),
                                true,
                            ))}
                            className={styles.timezoneButton}
                            size="large">
                            <img
                                className={styles.penIcon}
                                src="/assets/pen.svg"
                            />
                        </IconButton>
                    </div>
                </div>
                <div className={styles.isOptional}>
                    <IconButton
                        onClick={() => onChange(R.over(
                            R.lensPath([participant.email, 'optional']),
                            R.not,
                        ))}
                        size="large"
                    >
                        <img
                            className={styles.participantIcon}
                            src={participant.optional ? '/assets/participant-optional.svg' : '/assets/participant-required.svg'}
                        />
                    </IconButton>
                </div>
                {participant.email !== profile.email && (
                    <div>
                        <IconButton onClick={() => onChange(R.omit([participant.email]))} size="large">
                            <img
                                className={styles.participantIcon}
                                src="/assets/close_icon.svg"
                            />
                        </IconButton>
                    </div>
                )}
                {participant.editingTimeZone && (
                    <div className={styles.participantTimeZoneSelect}>
                        <TimeZoneSelect
                            onChange={timezone => onChange(R.over(
                                R.lensProp(participant.email),
                                R.mergeLeft({
                                    timezone,
                                    country: getTimezone(timezone).country.toLowerCase(),
                                    editingTimeZone: false
                                })
                            ))}
                            onBlur={() => onChange(R.set(
                                R.lensPath([participant.email, 'editingTimeZone']),
                                false
                            ))}
                        />
                    </div>
                )}
            </div>
        ))}
        <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            classes={{ paper: styles.dialog }}
        >
            <div className={styles.dialogHeader}>
                {t('new.participantsLimitReached')}
            </div>
            <div className={styles.dialogText}>
                {t('new.ifUpgradePlan')}
            </div>
            <div className={styles.dialogActions}>
                <Button
                    onClick={() => setDialogOpen(false)}
                    className={styles.dialogButton}
                >
                    {t('common.cancel')}
                </Button>
                <Button
                    variant="contained"
                    component={Link}
                    to="/upgradePlan"
                    className={styles.dialogButton}
                >
                    {t('new.upgrade')}
                </Button>
            </div>
        </Dialog>
    </>;
}
