import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
const styles = require('./CustomerSuccess.css');

export default function CustomerSuccess() {
    const { t } = useTranslation();
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                {t('landing.customerSuccess')}
            </div>
            <div className={styles.element}>
                <div>
                    <div className={classNames(styles.subheader, styles.subheaderLeft)}>
                        {t('landing.effortlessly')}
                    </div>
                    <div className={styles.description}>
                        Tanbuu makes onboarding smoother and more enjoyable to your customers
                        by instantly proposing meeting times which are most acceptable
                        to the entire group of participants, and guiding them to agree on the common time.
                    </div>
                </div>
                <div className={styles.image1} />
            </div>
            <div className={styles.element}>
                <div className={styles.image2} />
                <div>
                    <div className={classNames(styles.subheader, styles.subheaderRight)}>
                        {t('landing.quicklySchedule')}
                    </div>
                    <div className={styles.description}>
                        Tanbuu helps you find times for one-on-one or larger meetings,
                        and quickly present them to your customer. Yet, it confirms with you
                        which slots will be proposed, so you always stay in control of your time.
                    </div>
                </div>
            </div>
            <div className={styles.element}>
                <div>
                    <div className={classNames(styles.subheader, styles.subheaderLeft2)}>
                        {t('landing.improveRetention')}
                    </div>
                    <div className={styles.description}>
                        Over time, Tanbuu learns your customer’s preferences,
                        so meetings get scheduled even quicker. It works effectively
                        across time zones, and avoids pitfalls due to holidays worldwide
                        (with 200+ countries supported).
                    </div>
                </div>
                <div className={styles.image3} />
            </div>
        </div>
    );
}
