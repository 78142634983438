import React, { useState } from 'react';
import TextField from 'components/_ReusableComponents/TextField/TextField';
import { Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import BackendMethods from 'api/BackendMethods';
import { Pagination } from 'elementsSelectors/Reviews';
import IActions from 'actions/IActions';
import { useIsDesktop } from 'utils/responsive';
import { useTranslation } from 'react-i18next';

interface IReportProps {
    reviewId: number;
    setNowPage: React.Dispatch<React.SetStateAction<Pagination>>;
    actions: IActions;
}
export const Report = ({ reviewId, setNowPage, actions }: IReportProps) => {
    const { t } = useTranslation();
    const isDesktop = useIsDesktop();
    const [reason, setReason] = useState<string>('');
    const [acceptPrivay, setAcceptPrivacy] = useState<boolean>(false);
    const [reportingNow, setReportingNow] = useState<boolean>(false);
    const handleAcceptPrivay = () => {
        acceptPrivay ? setAcceptPrivacy(false) : setAcceptPrivacy(true);
    };
    const submmitReport = async () => {
        if (!reportingNow) {
            setReportingNow(true);
            try {
                await BackendMethods.submitReport({
                    id: reviewId,
                    reason: reason
                });
                setNowPage(Pagination.REVIEWS);
                actions.showSuccessMessage(t('small.yourReportHasBeenSent'));
            } catch (error) {
                actions.showErrorMessage(t('small.failedToSendYourReport'));
            } finally {
                setReportingNow(false);
            }
        }
    };
    return (
        <div className={ isDesktop? 'p-t-18': 'p-18'}>
            <h4 className={classNames('m-t-0', 'm-b-18', 'p-l-10')}>{t('small.reportAbuse')}</h4>
            <TextField
                className="m-t-0"
                value={reason}
                onChange={({ target }) => {
                    target.value.length > 5000 ? setReason(target.value.slice(0, 5000)) : setReason(target.value);
                }}
                multiline
                rows={4}
                label={t('small.reason')}
                fullWidth
            />

            <label className={classNames('inline-between', 'item-start')}>
                <Checkbox
                    className={classNames('p-l-0')}
                    checked={acceptPrivay}
                    onChange={handleAcceptPrivay}
                />
                <div className="p-t-12">
                    <span>
                        {`${t('small.confirmThatTheReportedReviewViolatesThe')} `}
                        <Link target="_blank" to="/terms">{t('small.termsAndConditions')}</Link>
                    </span>
                </div>
            </label>
            <div className={classNames('text-right', 'm-t-20')}>
                <Button
                    className={classNames('blue-btn')}
                    onClick={submmitReport}
                    disabled={reason === '' || !acceptPrivay || reportingNow}
                >
                    {t('small.report')}
                </Button>
            </div>
        </div>
    );
};
