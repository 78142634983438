// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__1JCZr {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    z-index: 1200;\n    width: 100%;\n    background-color: #dee1e1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 16px;\n}\n\n    @media (max-width: 800px) {.root__1JCZr {\n        flex-wrap: wrap\n}\n    }\n.text__1AOX3 {\n    font-size: 16px;\n}\n.link__1qsF_ {\n    color: #013253;\n}\n.button__2j-jv {\n    flex-shrink: 0;\n    margin-left: 40px;\n}\n@media (max-width: 800px) {\n.button__2j-jv {\n        margin-left: auto;\n        margin-top: 8px\n}\n    }\n", "",{"version":3,"sources":["CookieDisclaimer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,aAAa;IACb,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AAKjB;;IAHI,2BAZJ;QAaQ;AAER;IADI;AAEJ;IACI,eAAe;AACnB;AACA;IACI,cAAc;AAClB;AAEA;IACI,cAAc;IACd,iBAAiB;AAKrB;AAJI;AAHJ;QAIQ,iBAAiB;QACjB;AAER;IADI","file":"CookieDisclaimer.css","sourcesContent":[".root {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    z-index: 1200;\n    width: 100%;\n    background-color: #dee1e1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 16px;\n\n    @media (max-width: 800px) {\n        flex-wrap: wrap;\n    }\n}\n.text {\n    font-size: 16px;\n}\n.link {\n    color: #013253;\n}\n\n.button {\n    flex-shrink: 0;\n    margin-left: 40px;\n    @media (max-width: 800px) {\n        margin-left: auto;\n        margin-top: 8px;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__1JCZr",
	"text": "text__1AOX3",
	"link": "link__1qsF_",
	"button": "button__2j-jv"
};
module.exports = exports;
