import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { Moment } from 'moment';
const styles = require('./Slider.css');

interface ISliderProps {
    meetingDuration: number;
    earliest: string;
    latest: string;
    selectedEarliest: string;
    selectedLatest: string;
    onChange: (x: {
        earliest?: string;
        latest?: string;
    }) => void;
    timezone: string;
    timeFormat: string;
    isSetDate: boolean;
}

const MAX_BLOCKS = 20;

const clampMoment = (min: Moment | string, max: Moment | string, x: Moment | string) =>
    moment.max(moment(min), moment.min(moment(max), moment(x)));

export default function Slider(props: ISliderProps) {
    const {
        meetingDuration, earliest, latest, selectedEarliest, selectedLatest, timezone, isSetDate, timeFormat,
    } = props;
    const rangeEnd = moment(latest).add(meetingDuration, 'minutes');
    const selectedRangeEnd = moment(selectedLatest).add(meetingDuration, 'minutes');
    const realBlocksCount =  rangeEnd.diff(moment(earliest), 'minutes') / 15;
    const blocksCount = Math.min(MAX_BLOCKS, realBlocksCount);
    const showBreakLine = blocksCount !== realBlocksCount;
    const totalSpan = Math.min(
        moment.duration(15 * MAX_BLOCKS, 'minutes').asSeconds(),
        rangeEnd.diff(earliest, 'seconds')
    );
    const leftMarginPercent = moment(selectedEarliest).diff(earliest, 'seconds') / totalSpan * 100;
    const rightMarginPercent = moment(latest).diff(selectedLatest, 'seconds') / totalSpan * 100;
    const widthPercent = 100 - leftMarginPercent - rightMarginPercent;

    const leftTime = moment(selectedEarliest).tz(timezone);
    const rightTime = selectedRangeEnd.tz(timezone);
    const leftTimeSwitched = leftMarginPercent >= 25;
    const rightTimeSwitched = rightMarginPercent >= 25;
    const singleTooltip = (!leftTimeSwitched || !rightTimeSwitched) && widthPercent <= 25;
    const noHandles = earliest === latest;

    const [leftMoving, setLeftMoving] = useState(false);
    const [rightMoving, setRightMoving] = useState(false);

    const blocksRef = useRef(null);

    useEffect(() => {
        const mouseMoveListener = event => {
            const { current: blocksEl } = blocksRef;
            const { left, width } = blocksEl? blocksEl.getBoundingClientRect(): {left: 0, width: 0};
            const clientX = event.type === 'mousemove' ? event.clientX : event.touches[0].clientX;
            const relativeLeft = clientX - left;
            const blockWidth = width / blocksCount;
            if (leftMoving) {
                const blockNumber = Math.round(relativeLeft / blockWidth);
                const blockDate = moment(earliest).add(15 * blockNumber, 'minutes');
                const newSelected = clampMoment(earliest, latest, blockDate).toISOString();
                props.onChange({
                    earliest: newSelected,
                    ...((newSelected > selectedLatest || isSetDate) ? { latest: newSelected } : {}),
                });
            } else if (rightMoving) {
                const relativeRight = width - relativeLeft;
                const blockNumber = Math.round(relativeRight / blockWidth);
                const blockDate = moment(latest).subtract(15 * blockNumber, 'minutes');
                const newSelected = clampMoment(earliest, latest, blockDate).toISOString();
                props.onChange({
                    latest: newSelected,
                    ...((newSelected < selectedEarliest || isSetDate) ? { earliest: newSelected } : {}),
                });
            }
        };
        const mouseUpListener = () => {
            setLeftMoving(false);
            setRightMoving(false);
        };
        window.addEventListener('mousemove', mouseMoveListener);
        window.addEventListener('touchmove', mouseMoveListener);
        window.addEventListener('mouseup', mouseUpListener);
        window.addEventListener('touchend', mouseUpListener);
        return () => {
            window.removeEventListener('mousemove', mouseMoveListener);
            window.removeEventListener('touchmove', mouseMoveListener);
            window.removeEventListener('mouseup', mouseUpListener);
            window.removeEventListener('touchend', mouseUpListener);
        };
    }, [leftMoving, rightMoving, blocksCount, blocksRef, selectedEarliest, selectedLatest]);

    const leftMouseDown = (event) => {
        if (event.type === 'mousedown') {
            event.preventDefault();
        }
        event.stopPropagation();
        setLeftMoving(true);
    };
    const rightMouseDown = (event) => {
        if (event.type === 'mousedown') {
            event.preventDefault();
        }
        event.stopPropagation();
        setRightMoving(true);
    };

    const renderTime = (date: Moment) => (
        <>
            {date.format(timeFormat === '12h' ? 'h:mm' : 'H:mm')}
            {timeFormat === '12h' && (
                <span className={styles.ampm}>
                    {date.format('a')}
                </span>
            )}
        </>
    );

    return (
        <div className={classNames({
            [styles.container]: true,
            [styles.containerNoHandles]: noHandles
        })}>
            <div className={styles.barContainer}>
                <div className={styles.blocks} ref={blocksRef}>
                    {R.times(i => (
                        <div key={i} className={styles.block} />
                    ), blocksCount)}
                </div>
                <div
                    className={styles.selected}
                    style={{
                        left: `${leftMarginPercent}%`,
                        width: `${widthPercent}%`
                    }}
                >
                    <div
                        onMouseDown={leftMouseDown}
                        onTouchStart={leftMouseDown}
                        className={classNames(
                            styles.handle,
                            styles.leftHandle,
                            { [styles.handleHidden]: noHandles }
                        )}
                    >
                        <div className={classNames(styles.time, { [styles.timeLeft]: leftTimeSwitched })}>
                            {(!singleTooltip || !leftTimeSwitched) && renderTime(leftTime)}
                            {singleTooltip && !leftTimeSwitched && (
                                <>
                                <span className={styles.timeDash}>
                                    –
                                </span>
                                    {renderTime(rightTime)}
                                </>
                            )}
                        </div>
                    </div>
                    <div
                        className={classNames(
                            styles.handle,
                            styles.rightHandle,
                            { [styles.handleHidden]: noHandles }
                        )}
                        onMouseDown={rightMouseDown}
                        onTouchStart={rightMouseDown}
                    >
                        <div className={classNames(styles.time, { [styles.timeLeft]: !rightTimeSwitched })}>
                            {singleTooltip && !rightTimeSwitched && (
                                <>
                                    {renderTime(leftTime)}
                                    <span className={styles.timeDash}>
                                        –
                                    </span>
                                </>
                            )}
                            {(!singleTooltip || !rightTimeSwitched) && renderTime(rightTime)}
                        </div>
                    </div>
                    {showBreakLine && (
                        <div className={styles.breakLine} />
                    )}
                </div>
            </div>
        </div>
    );
}
