import IMeetingFromToDates from '../../interfaces/IMeetingFromToDates';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import { TFunction } from 'i18next';

function getHourRange(dateFrom: Moment, dateTo: Moment, timeFormat: string, t: TFunction): string {
    const isNextDay: boolean = !moment(dateFrom).isSame(dateTo, 'day');
    const format = timeFormat === '12h' ? 'h:mm a' : 'H:mm';
    return `${dateFrom.format(format)} - ${dateTo.format(format)}${
        isNextDay ? ` (${t('common.nextDay')})` : ''
    } ${dateFrom.format('z')}`;
}

export function getDateLines(
    isoStringDateFrom: string,
    duration: number,
    timezone: string,
    timeFormat: string,
    t: TFunction,
    language: string,
): IMeetingFromToDates {
    const date = moment.tz(isoStringDateFrom, timezone);
    const dateToMoment = moment.tz(isoStringDateFrom, timezone).add(duration, 'seconds');
    const hasPassed = date.isBefore(moment());
    const monthDay = language === 'pl' ? 'D MMM.' : 'MMM. D,';
    const dateFrom = (() => {
        const feminineWeekdays = [0, 3, 6]; // Sunday, Wednesday and Saturday
        if (hasPassed) {
            return date.format(`dddd, ${monthDay} Y`);
        } else if (date.isSame(moment(), 'day')) {
            return t('common.today');
        } else if (date.isSame(moment().add(1, 'day'), 'day')) {
            return t('common.tomorrow');
        } else if (date.isSame(moment(), 'week')) {
            if (feminineWeekdays.includes(date.day())) {
                return `${t('common.thisFeminine')} ${date.format('dddd')}`;
            } else {
                return `${t('common.thisMasculine')} ${date.format('dddd')}`;
            }
        } else if (date.isSame(moment().add(1, 'week'), 'week')) {
            if (feminineWeekdays.includes(date.day())) {
                return `${t('common.nextFeminine')} ${date.format('dddd')}`;
            } else {
                return `${t('common.nextMasculine')} ${date.format('dddd')}`;
            }
        } else {
            return `${date.format('dddd')}`;
        }
    })();
    const dateFromSecondLine = hasPassed ? '' : date.format(`${monthDay} Y`);
    const dateTo = `${getHourRange(date, dateToMoment, timeFormat, t)}${hasPassed ? ` (${t('common.passed')})` : ''}`;
    return { dateFrom, dateFromSecondLine, dateTo };
}
