import React, { useContext, useState } from 'react';
import { useIsDesktop } from '../../../utils/responsive';
import Header, { IHeaderProps } from '../../../components/_ReusableComponents/Header/Header/Header';
import classNames from 'classnames';
import { Redirect, Route, Switch } from 'react-router-dom';
import MobileBooking from '../MobileBooking';
import DesktopBooking from '../DesktopBooking';
import Dashboard from '../Dashboard/Dashboard';
import MobileDashboard from '../Dashboard/Mobile';
import FindServiceMobile from './FindServiceMobile';
import { isAdHoc, isLoggedIn } from '../../../scripts/helpers';
import GlobalContext from '../../../GlobalContext';
import { connect } from 'react-redux';
import IActions from 'actions/IActions';
import { Reviews } from './Review/Reviews';
import containersMapStateToProps from 'utils/containersMapStateToProps';
import containersDispatchToProps from 'utils/containersMapDispatchToProps';
import { DesktopReview } from './Review/DesktopReview/DesktopReview';
const styles = require('./Customer.css');

interface ICustomerProps {
    data: any;
    actions: IActions;
}

function Customer(props: ICustomerProps) {
    const { profile } = useContext(GlobalContext);
    const isDesktop = useIsDesktop();
    const { actions } = props;
    const [headerProps, setHeaderProps] = useState<Partial<IHeaderProps>>({});
    const [noHeader, setNoHeader] = useState(false);
    const childProps = { profile, setHeaderProps, setNoHeader, isCustomer: true, actions: actions };
    const reviewProps = { profile, actions };
    const desktopReviewProps = { profile, actions, setHeaderProps, isCustomer: true  };
    const isCompanyEmployee = profile?.appointments?.companyId != null;
    const loggedIn = isLoggedIn();
    const adHoc = isAdHoc();
    if (isCompanyEmployee) {
        return <Redirect to="/sb/dashboard" />;
    }

    return (
        <div>
            {!noHeader && (
                <Header {...headerProps} />
            )}
            <div
                className={classNames(styles.content, {
                    [styles.contentNoHeader]: noHeader,
                })}
            >
                <Switch>
                    <Route
                        path={[
                            '/sb-customer/book/:company/edit/:id',
                            '/sb-customer/book/:company/rebook/:id',
                            '/sb-customer/book/:company/:location?',
                        ]}
                        render={() => (
                            isDesktop ? <DesktopBooking {...childProps} /> : <MobileBooking {...childProps} />
                        )}
                    />
                    {isDesktop ? (
                        <Redirect
                            from="/sb-customer/find-service"
                            to="/sb-customer/my-appointments"
                        />
                    ) : (
                        <Route
                            path="/sb-customer/find-service"
                            component={FindServiceMobile}
                        />
                    )}
                    <Route
                        path={[
                            '/sb-customer/my-appointments',
                            '/sb-customer/my-appointments/:serviceId/confirm',
                        ]}
                        exact
                        render={() => {
                            if (isDesktop) {
                                return <Dashboard {...childProps} />;
                            }
                            return <MobileDashboard {...childProps} />;
                        }}
                    />
                    <Route
                        path={[
                            '/sb-customer/reviews/:company',
                        ]}
                        render={() => {
                            if (isDesktop) {
                                return <DesktopReview {...desktopReviewProps} />;
                            }
                            if (loggedIn || adHoc) {
                                return <Reviews {...reviewProps} />;
                            }
                            const redirect = encodeURIComponent('/sb-customer/find-service');
                            return <Redirect to={`/login?redirectURI=${redirect}`} />;
                        }}
                    />
                    <Route
                        path={[
                            '/sb-customer/reviews/:company',
                        ]}
                        render={() => {
                            return isDesktop
                                ? <DesktopReview {...desktopReviewProps} />
                                : <Reviews {...reviewProps} />;
                        }}
                    />
                </Switch>
            </div>
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(Customer);
