import React, { ReactNode, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MobileMenuIcon from 'components/_ReusableComponents/MobileMenu/MobileMenuIcon/MobileMenuIcon';
import NotesIcon from 'components/_ReusableComponents/MeetingNotes/NotesIcon';
import { useIsDesktop } from '../../../../utils/responsive';
import { getAuthToken, isAdHoc, isLoggedIn, parseJwt } from '../../../../scripts/helpers';
import Button from '../../Button/Button';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import IActions from '../../../../actions/IActions';
import { getInitialsFromName } from '../../../../utils/userInitials/userInitials';
import auth from 'auth/Auth';
import classNames from 'classnames';
import inIframe from '../../../../utils/inIframe';
import { useRouteMatch } from 'react-router';
import getDisplayName from '../../../../utils/getDisplayName';
import { formatFullPhone } from '../../../../utils/phone';
import { getSubdomain, redirectToBaseDomain, redirectToSubdomain } from '../../../../utils/subdomain';
import AddEmailDialog from '../../AddEmailDialog';
import { connect } from 'react-redux';
import containersMapStateToProps from '../../../../utils/containersMapStateToProps';
import containersDispatchToProps from '../../../../utils/containersMapDispatchToProps';
import GlobalContext from '../../../../GlobalContext';
const styles = require('./Header.css');

export interface IHeaderProps {
    data: any;
    actions: IActions;
    children?: ReactNode;
    onlyBack?: boolean;
    onBack?: () => void;
}

function Header(props: IHeaderProps) {
    const { t } = useTranslation();
    const isDesktop = useIsDesktop();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [switchAnchor, setSwitchAnchor] = useState<HTMLElement | null>(null);
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    const isSb = useRouteMatch('/sb/*');
    const isSbCustomer = useRouteMatch('/sb-customer/*');
    const adHoc = isAdHoc();
    const { profile } = useContext(GlobalContext);
    const subscription = props.data.get('subscription');
    const isPlanAdmin = subscription?.status !== undefined && !profile.plusPlan.organization;
    const isCompanyEmployee = profile?.appointments?.companyId != null;
    const isManager = profile?.appointments.accountPerm;
    const isOutlook = sessionStorage.getItem('outlook') === '1';
    const isExtension = inIframe();
    const loggedIn = isLoggedIn();
    const name = getDisplayName(profile);
    return (
        <div
            id="DashboardHeader"
            className={classNames(styles.header, {
                [styles.outlook]: isOutlook,
            })}
        >
            {!isOutlook && !adHoc && isDesktop && (
                <>
                    <IconButton
                        color="primary"
                        className={styles.switchButton}
                        onClick={({ currentTarget }) => setSwitchAnchor(currentTarget)}
                        size="large">
                        <img src="/assets/dots.svg" alt="" />
                    </IconButton>
                    <Menu
                        open={switchAnchor !== null}
                        anchorEl={switchAnchor}
                        onClose={() => setSwitchAnchor(null)}
                        PaperProps={{ elevation: 4 }}
                    >
                        <MenuItem
                            className={styles.switchItem}
                            {...((!loggedIn || profile.email) ? {
                                component: Link,
                                to: loggedIn ? '/main' : '/',
                            } : {})}
                            onClick={() => {
                                setSwitchAnchor(null);
                                if (!loggedIn || profile.email) {
                                    redirectToBaseDomain(loggedIn ? '/main' : '/');
                                } else {
                                    setEmailDialogOpen(true);
                                }
                            }}
                        >
                            <img src="/assets/app-icon.png" alt="" className={styles.productIcon} />
                            {t('common.meetings')}
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            to={isCompanyEmployee ? '/sb/dashboard' : '/sb-customer/my-appointments'}
                            className={styles.switchItem}
                            onClick={() => {
                                setSwitchAnchor(null);
                                if (isCompanyEmployee && profile.appointments.companySubdomain) {
                                    redirectToSubdomain(profile.appointments.companySubdomain, '/sb/dashboard');
                                }
                            }}
                        >
                            <img src="/assets/app-icon.png" alt="" className={styles.productIcon} />
                            {t('common.appointments')}
                        </MenuItem>
                    </Menu>
                    <AddEmailDialog
                        open={emailDialogOpen}
                        onClose={() => setEmailDialogOpen(false)}
                        context="MEETINGS"
                    />
                </>
            )}
            {isDesktop ? (
                <>
                    {!isOutlook && (
                        <Button
                            variant="text"
                            className={styles.home}
                            component={Link}
                            to={isSb ? '/sb/dashboard' : (isSbCustomer ? '/sb-customer/my-appointments' : '/main')}
                            disabled={adHoc}
                            onClick={() => {
                                if (isSbCustomer) {
                                    redirectToBaseDomain('/sb-customer/my-appointments');
                                }
                            }}
                        >
                            <span className={styles.logo} />
                        </Button>
                    )}
                    {!adHoc && !isOutlook && !isSbCustomer && (
                        <Button
                            variant="contained"
                            className={styles.newMeeting}
                            component={Link}
                            to={isSb ? '/sb/assistant-booking' : '/new'}
                        >
                            {isSb ? t('small.newAppointment') : t('home.newMeeting')}
                        </Button>
                    )}
                </>
            ) : (
                <>
                    {!adHoc && !props.onlyBack && (
                        <MobileMenuIcon
                            name={(() => {
                                if (profile) {
                                    if (profile.firstName) {
                                        return `${profile.firstName} ${profile.lastName}`;
                                    }
                                    if (profile.email) {
                                        return profile.email;
                                    }
                                }
                                if (isAdHoc()) {
                                    return parseJwt(getAuthToken()).email;
                                }
                                return null;
                            })()}
                            actions={props.actions}
                        />
                    )}
                    {props.onlyBack && (
                        <IconButton
                            color="primary"
                            onClick={props.onBack}
                            className={styles.backButton}
                            size="large">
                            <img src="/assets/back-icon.svg" alt="" />
                        </IconButton>
                    )}
                    <span
                        className={classNames(styles.title, {
                            [styles.titleAdHoc]: (adHoc && isSbCustomer),
                        })}
                    >
                        {props.data.get('host')}
                    </span>
                </>
            )}
            <div className={styles.rightIcons}>
                {adHoc && (
                    <Button
                        className={styles.authButton}
                        variant="contained"
                        component={Link}
                        to="/register"
                    >
                        {t('topbar.register')}
                    </Button>
                )}
                {adHoc && isSbCustomer && (
                    <Button
                        className={styles.authButton}
                        variant="contained"
                        component={Link}
                        to="/login"
                    >
                        {t('auth.logIn')}
                    </Button>
                )}
                {props.children}
                {isSb && isDesktop && (
                    <IconButton
                        color="primary"
                        className={styles.calendarButton}
                        component={Link}
                        to="/sb/calendar"
                        size="large"
                    >
                        <img src="/assets/calendar-white.svg" alt="" />
                    </IconButton>
                )}
                {isSb && isDesktop && (
                    <IconButton
                        color="primary"
                        className={styles.calendarButton}
                        component={Link}
                        to="/sb/customer"
                        size="large"
                    >
                        <img src="/assets/customer_icon.svg" alt="" />
                    </IconButton>
                )}
                {!adHoc && (
                    <>
                        {loggedIn && !props.onlyBack && !isSbCustomer && <NotesIcon {...props} />}
                        {isDesktop && (
                            <>
                                {loggedIn && (
                                    <IconButton
                                        color="primary"
                                        className={styles.support}
                                        onClick={props.actions.toggleSupportModal}
                                        size="large">
                                        <img
                                            src={isOutlook ? '/assets/support-dark.svg' : '/assets/support.svg'}
                                            alt=""
                                            className={styles.supportIcon}
                                        />
                                    </IconButton>
                                )}
                                <Button
                                    variant="contained"
                                    className={classNames(styles.userMenu, {
                                        [styles.menuNotLoggedIn]: !loggedIn,
                                    })}
                                    onClick={({ currentTarget }) => (
                                        setAnchorEl(currentTarget)
                                    )}
                                >
                                    {name ? getInitialsFromName(name) : (
                                        <img
                                            src="/assets/menu-white.svg"
                                            alt=""
                                        />
                                    )}
                                </Button>
                                <Menu
                                    open={anchorEl !== null}
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                    PaperProps={{ elevation: 4 }}
                                >
                                    {loggedIn && (
                                        <div className={styles.userInfo}>
                                            {profile.firstName && (
                                                <div>
                                                    {`${profile.firstName} ${profile.lastName}`}
                                                </div>
                                            )}
                                            <div className={styles.email}>
                                                {profile.email || formatFullPhone(profile.phone)}
                                            </div>
                                        </div>
                                    )}
                                    {!isSb && !isSbCustomer && (
                                        <MenuItem
                                            component={Link}
                                            to={isPlanAdmin ? '/manage' : (
                                                profile.plusPlan.active ? '/plan-info' : '/upgradePlan'
                                            )}
                                            onClick={() => setAnchorEl(null)}
                                            className={styles.menuItem}
                                        >
                                            <img
                                                src="/assets/plan-dark.svg"
                                                alt=""
                                                className={styles.menuIcon}
                                            />
                                            {isPlanAdmin ? t('home.managePlan') : (
                                                profile.plusPlan.active
                                                    ? t('plan.plusPlanInfo')
                                                    : t('home.freePlan')
                                            )}
                                        </MenuItem>
                                    )}
                                    {loggedIn && (
                                        <MenuItem
                                            component={Link}
                                            to={isSb && isManager ? '/sb/settings' : '/preferences'}
                                            onClick={() => setAnchorEl(null)}
                                            className={styles.menuItem}
                                        >
                                            <img
                                                src="/assets/settings-dark.svg"
                                                alt=""
                                                className={styles.menuIcon}
                                            />
                                            {t('home.settings')}
                                        </MenuItem>
                                    )}
                                    {!isExtension && loggedIn && (
                                        <MenuItem
                                            onClick={() => auth.logout()}
                                            className={styles.menuItem}
                                        >
                                            <img
                                                src="/assets/logout-dark.svg"
                                                alt=""
                                                className={styles.menuIcon}
                                            />
                                            {t('home.logOut')}
                                        </MenuItem>
                                    )}
                                    {!loggedIn && (
                                        <MenuItem
                                            className={styles.menuItem}
                                            {...(getSubdomain() === null) ? {
                                                component: Link,
                                                to: '/login',
                                            } : {
                                                onClick: () => {
                                                    const { location } = window;
                                                    const url = encodeURIComponent(location.href);
                                                    redirectToBaseDomain(`/login?redirectURI=${url}`);
                                                },
                                            }}
                                        >
                                            <img
                                                src="/assets/log-in-dark.svg"
                                                alt=""
                                                className={styles.menuIcon}
                                            />
                                            {t('common.logIn')}
                                        </MenuItem>
                                    )}
                                </Menu>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(Header);
