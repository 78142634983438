// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MeLabel__1ujXW {\n    border-radius: 3px;\n    box-sizing: content-box;\n    color: white;\n    display: inline-block;\n    font-size: 12px;\n    margin-left: 3px;\n    margin-top: -3px;\n    margin-right: 5px;\n    padding: 4px;\n    background-color: #013253;\n}\n\n.MeLabel--InProgress__14V3C,\n.MeLabel--OrganizerLabel {\n    background-color: #013253;\n}\n\n.MeLabel--CalculationInProgress {\n    background-color: #e84d36;\n}\n\n.MeLabel--InProgressRespond__2JOCe,\n.MeLabel--YouLabel__2d0ZY {\n    background-color: #013253;\n}\n", "",{"version":3,"sources":["MeLabel.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;IAEI,yBAAyB;AAC7B","file":"MeLabel.css","sourcesContent":[".MeLabel {\n    border-radius: 3px;\n    box-sizing: content-box;\n    color: white;\n    display: inline-block;\n    font-size: 12px;\n    margin-left: 3px;\n    margin-top: -3px;\n    margin-right: 5px;\n    padding: 4px;\n    background-color: #013253;\n}\n\n.MeLabel--InProgress,\n:global(.MeLabel--OrganizerLabel) {\n    background-color: #013253;\n}\n\n:global(.MeLabel--CalculationInProgress) {\n    background-color: #e84d36;\n}\n\n.MeLabel--InProgressRespond,\n.MeLabel--YouLabel {\n    background-color: #013253;\n}\n"]}]);
// Exports
exports.locals = {
	"MeLabel": "MeLabel__1ujXW",
	"MeLabel--InProgress": "MeLabel--InProgress__14V3C",
	"MeLabel--InProgressRespond": "MeLabel--InProgressRespond__2JOCe",
	"MeLabel--YouLabel": "MeLabel--YouLabel__2d0ZY"
};
module.exports = exports;
