import React, { useState } from 'react';
import { Dialog, IconButton, Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import * as R from 'ramda';
import copy from 'clipboard-copy';
import IActions from '../../../actions/IActions';
import IMeeting from '../../../interfaces/IMeeting';
import CancelMeetingModal from '../../_ReusableComponents/Modals/CancelMeetingModal/CancelMeetingModal';
import BackendMethods from '../../../api/BackendMethods';
import { useTranslation } from 'react-i18next';
const styles = require('./MeetingOptions.css');

interface Props {
    actions: IActions;
    className?: string;
    history: any;
    currentMeeting: IMeeting;
    email: string;
    onSetDate: () => void;
}

export default function MeetingOptions(props: Props) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [linkDialogOpen, setLinkDialogOpen] = useState(false);
    const { id, isOrganizer, state, participants } = props.currentMeeting;
    const { email } = props;
    const sendReminders = async () => {
        setAnchorEl(null);
        await BackendMethods.sendReminders(id);
        props.actions.showSuccessMessage(t('home.remindersSent'));
    };
    const { declined } = participants.find(R.whereEq({ email }));
    const declineInvite = async () => {
        setAnchorEl(null);
        try {
            const updatedMeeting = await BackendMethods.declineMeeting(id, !declined);
            props.actions.updateMeeting(updatedMeeting, email);
            props.actions.showSuccessMessage(
                declined ? t('home.joinedAgain') : t('home.inviteDeclined'),
            );
        } catch {
            props.actions.showErrorMessage(
                declined ? t('home.failedToJoin') : t('home.failedToDecline'),
            );
        }
    };
    const hasPropositions = props.currentMeeting.propositions.length !== 0;
    const allDeclined = R.all(participant => participant.email === email || participant.declined, participants);
    if (state === 'CANCELED' || state === 'SCHEDULED') {
        return null;
    }
    return (
        <div
            data-testid="meeting-options"
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
            }}
        >
            <IconButton
                onClick={event => setAnchorEl(event.currentTarget)}
                className={styles.button}
                data-testid="options-button"
                size="large">
                <img
                    src="/assets/vertical_dots_light.svg"
                    className={classNames(styles.icon, props.className)}
                />
            </IconButton>
            <Menu
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {isOrganizer ? (
                    [
                        <MenuItem
                            component={Link}
                            to={`/edit/${id}`}
                            data-testid="edit-button"
                            key="1"
                        >
                            {t('home.editMeeting')}
                        </MenuItem>,
                        ...(allDeclined ? [] : [
                            <MenuItem
                                onClick={() => {
                                    setCancelDialogOpen(true);
                                    setAnchorEl(null);
                                }}
                                data-testid="cancel-button"
                                key="2"
                            >
                                {t('home.cancelMeeting')}
                            </MenuItem>,
                            <MenuItem
                                onClick={() => {
                                    props.onSetDate();
                                    setAnchorEl(null);
                                }}
                                data-testid="set-date-button"
                                key="3"
                            >
                                {t('home.setDate')}
                            </MenuItem>,
                        ]),
                        ...(hasPropositions && !allDeclined ? ([
                            <MenuItem
                                onClick={sendReminders}
                                data-testid="send-reminders-button"
                                key="4"
                            >
                                {t('home.sendReminders')}
                            </MenuItem>
                        ]) : []),
                        <MenuItem
                            onClick={async () => {
                                if (participants.length > 2 && participants.some(R.prop('adhoc'))) {
                                    setLinkDialogOpen(true);
                                } else {
                                    const adhoc = participants.find(R.prop('adhoc'));
                                    if (adhoc) {
                                        await copy(await BackendMethods.getMeetingLink(id, adhoc.id));
                                    } else {
                                        await copy(`${location.origin}/meeting/${id}`);
                                    }
                                    props.actions.showSuccessMessage(t('home.linkCopied'));
                                }
                                setAnchorEl(null);
                            }}
                            key="5"
                        >
                            {t('home.copyLink')}
                        </MenuItem>,
                    ]
                ) : (
                    [
                        <MenuItem
                            onClick={declineInvite}
                            data-testid="decline-button"
                            key="5"
                        >
                            {declined ? t('home.joinAgain') : t('home.declineInvite')}
                        </MenuItem>,
                        ...(declined ? [] :
                            ([
                                <MenuItem
                                    data-testid="change-button"
                                    key="6"
                                    component={Link}
                                    to={`/suggestion/${id}`}
                                >
                                    {t('home.suggestChange')}
                                </MenuItem>
                            ])
                        )
                    ]
                )}
            </Menu>
            <CancelMeetingModal
                actions={props.actions}
                meeting={props.currentMeeting}
                history={props.history}
                open={cancelDialogOpen}
                onClose={() => setCancelDialogOpen(false)}
            />
            <Dialog
                open={linkDialogOpen}
                onClose={() => setLinkDialogOpen(false)}
                classes={{ paper: styles.dialog }}
            >
                <div className={styles.dialogHeader}>
                    {t('home.copyLinkFor')}
                </div>
                {participants.filter((participant) => (
                    participant.email !== email
                )).map((participant) => (
                    <div className={styles.dialogRow} key={participant.email}>
                        <div className={styles.dialogName}>
                            {participant.name || participant.email}
                        </div>
                        <IconButton
                            className={styles.dialogButton}
                            onClick={async () => {
                                if (participant.adhoc) {
                                    await copy(await BackendMethods.getMeetingLink(id, participant.id));
                                } else {
                                    await copy(`${location.origin}/meeting/${id}`);
                                }
                                props.actions.showSuccessMessage(t('home.linkCopied'));
                            }}
                            size="large">
                            <img src="/assets/copy.svg" alt="Copy" />
                        </IconButton>
                    </div>
                ))}
            </Dialog>
        </div>
    );
}
