import React, { useEffect, useState } from 'react';
import BackendMethods from '../../../api/BackendMethods';
import Button from '../../_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
import useCloudinary from '../../../utils/useCloudinary';
const Styles = require('./GroupAvatar.css');

export interface IUploadInfo {
    id: string;
    url: string;
    signature: string;
    version: string;
}

const GroupAvatar = () => {
    const { t } = useTranslation();
    const [savedLogo, setSavedLogo] = useState(null);
    useEffect(() => {
        BackendMethods.getLogo().then(logo => {
            setSavedLogo(logo);
        });
    }, []);
    const [uploadInfo, setUploadInfo] = useState(null);
    const widget = useCloudinary((newUploadInfo) => {
        setUploadInfo(newUploadInfo);
        BackendMethods.saveLogo(newUploadInfo);
    }, BackendMethods.getCloudinarySignature);

    const deleteLogo = () => {
        setUploadInfo(null);
        setSavedLogo({});
        BackendMethods.deleteLogo();
    };
    if (!widget || !savedLogo) {
        return null;
    }
    return (
        <section className={Styles.GroupAvatar}>
            <div className={Styles.Description} >
                {t('plan.thisLogo')}
            </div>
            <Button
                variant="contained"
                onClick={() => widget.open()}
                className={Styles.UploadButton}
            >
                {savedLogo.url ? t('plan.changeLogo') : t('plan.upload')}
            </Button>
            {(uploadInfo || savedLogo.url) && (
                <div className={Styles.LogoOuterContainer}>
                    <div className={Styles.LogoContainer}>
                        <img
                            className={Styles.Preview}
                            src={uploadInfo ? uploadInfo.url : savedLogo.url}
                            data-testid="team-logo"
                        />
                        <button className={Styles.DeleteButton} onClick={deleteLogo}>
                        </button>
                    </div>
                </div>
            )}
            <div className={Styles.SizeTips}>
                <p className={Styles.SizeTips__Tip}>{t('plan.sizeInfo')}</p>
            </div>
        </section>
    );
};

export default GroupAvatar;
