// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__1-HIB {\n    padding: 0 32px;\n    margin: 0 auto;\n    max-width: 800px;\n}\n\n    @media (max-width: 1250px) {.root__1-HIB {\n        padding: 0 16px\n}\n    }\n", "",{"version":3,"sources":["Terms.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AAKpB;;IAHI,4BALJ;QAMQ;AAER;IADI","file":"Terms.css","sourcesContent":[".root {\n    padding: 0 32px;\n    margin: 0 auto;\n    max-width: 800px;\n\n    @media (max-width: 1250px) {\n        padding: 0 16px;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__1-HIB"
};
module.exports = exports;
