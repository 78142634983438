// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".WhoContainer__FLidD {\n    align-items: flex-end;\n    display: flex;\n    justify-content: center;\n    opacity: 0;\n}\n\n.Name__1TmXz {\n    font-size: 18px;\n}\n\n.City__3qYSa {\n    font-size: 14px;\n    margin-left: 5px;\n}\n\n.Filled__13tGM {\n    height: 282px;\n    opacity: 0;\n}\n", "",{"version":3,"sources":["Phone.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,aAAa;IACb,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,UAAU;AACd","file":"Phone.css","sourcesContent":[".WhoContainer {\n    align-items: flex-end;\n    display: flex;\n    justify-content: center;\n    opacity: 0;\n}\n\n.Name {\n    font-size: 18px;\n}\n\n.City {\n    font-size: 14px;\n    margin-left: 5px;\n}\n\n.Filled {\n    height: 282px;\n    opacity: 0;\n}\n"]}]);
// Exports
exports.locals = {
	"WhoContainer": "WhoContainer__FLidD",
	"Name": "Name__1TmXz",
	"City": "City__3qYSa",
	"Filled": "Filled__13tGM"
};
module.exports = exports;
