import * as R from 'ramda';
import IConfirmSchedule from '../interfaces/confirmSchedules/IConfirmSchedule';
import INewMeeting from '../interfaces/INewMeeting';
import IGetPreviewMeetingDate from '../interfaces/IGetPreviewMeetingDate';
import IProfile from 'interfaces/IProfile';
import IReview from 'interfaces/IReview';
import ISupportMessageJson from 'interfaces/ISupportMessageJson';
import backendRequest from './backendRequest';
import ISubscriptionPlan, { PLAN_INTERVAL } from '../interfaces/backendResponses/ISubscriptionPlan';
import ICardDetails from '../interfaces/backendResponses/ICardDetails';
import ISubscription from '../interfaces/backendResponses/ISubscription';
import ILicenseAssignment from '../interfaces/backendResponses/ILicenseAssignment';
import ICustomerCredit from '../interfaces/backendResponses/ICustomerCredit';
import IInvoice from '../interfaces/backendResponses/IInvoice';
import { IUploadInfo } from '../components/ManageGroupComponents/GroupAvatar/GroupAvatar';
import { IContact, ISimpleProfile, IQuota, IWorkHours, IUnseenMeetings, IConnectionProvider, IConnectionScope, IMeetingIntegrations, IConnection, ICalendarEventList, ISearchCustomer, ICustomerCare, ICompanyGeneral, ILocationSearchResult, IPreviewChange }
    from '../interfaces/interfaces';
import IMeeting from '../interfaces/IMeeting';
import { getTimezone } from 'countries-and-timezones';
import { IResource } from '../containers/SmallBusiness/Settings/Resources';
import { INewService, IService } from '../containers/SmallBusiness/Settings/Services';
import { ILocation, INewLocation } from '../containers/SmallBusiness/Settings/Sites';
import { INewPerson, IPerson } from '../containers/SmallBusiness/Settings/Personnel';
import { INewWorkHours } from '../containers/SmallBusiness/Settings/PersonnelHours';
import { ICustomer, ILocationDates, INewAppointment, INewCustomer, INewCustomerAppointment } from '../containers/SmallBusiness/DesktopBooking';
import { IAppointment } from '../containers/SmallBusiness/Dashboard/Dashboard';
import IProposition from '../interfaces/IProposition';
import { IHoliday } from '../components/NewMeetingPageComponents/DateRangePicker/DateRangePicker';
import ISuggestionMeeting from 'interfaces/ISuggestionMeeting';
import { ICompaniesRating } from 'interfaces/ICompaniesRating';

export const transformMeeting = email => meeting => ({
    ...meeting,
    isOrganizer: email === meeting.organizer.email,
    hasResponded: meeting.acceptedBy.includes(email)? true: false,
    propositions: meeting.propositions? R.sortBy(R.prop('earliest'), meeting.propositions): []
});

export default class BackendMethods {
    public static saveAnswer({ meeting, data }: IConfirmSchedule): Promise<any> {
        return backendRequest({
            path: `schedule/meeting/${meeting}/answer`,
            type: 'PUT',
            data: data
        });
    }

    public static sendNewMeeting(
        newMeetingJson: INewMeeting
    ): Promise<any> {
        return backendRequest({
            path: 'schedule/meeting',
            type: 'POST',
            data: newMeetingJson
        });
    }

    public static previewPredictions(
        previewJson: IGetPreviewMeetingDate
    ): Promise<any> {
        return backendRequest({
            path: 'schedule/preview-predictions',
            type: 'POST',
            data: previewJson
        });
    }

    public static suggestionMeeting(
        meetingId: number,
        suggestionMweetingJson: ISuggestionMeeting
    ): Promise<any> {
        return backendRequest({
            path: `schedule/meeting/${meetingId}/suggestion`,
            type: 'POST',
            data: suggestionMweetingJson
        });
    }

    public static suggestionMeetings(
        meetingId: number
    ): Promise<ISuggestionMeeting[]> {
        return backendRequest({
            path: `schedule/meeting/${meetingId}/suggestions`,
            type: 'GET'
        });
    }

    public static acceptSuggestion(
        suggestionId: number
    ): Promise<any> {
        return backendRequest({
            path: `schedule/suggestion/${suggestionId}/accept`,
            type: 'POST'
        });
    }

    public static rejectSuggestion(
        suggestionId: number
    ): Promise<any> {
        return backendRequest({
            path: `schedule/suggestion/${suggestionId}/reject`,
            type: 'POST'
        });
    }

    public static editMeeting(
        newMeetingJson: INewMeeting,
        meetingId: number
    ): Promise<any> {
        return backendRequest({
            path: `schedule/meeting/${meetingId}`,
            type: 'PATCH',
            data: newMeetingJson
        });
    }

    public static cancelMeeting(id: number): Promise<IMeeting> {
        return backendRequest({
            path: `schedule/meeting/${id}/cancel`,
            type: 'PUT'
        });
    }

    public static getConnections(): Promise<IConnection[]> {
        return backendRequest({
            path: 'oauth/connections',
            type: 'GET'
        });
    }

    public static sendReminders(id: number): Promise<''> {
        return backendRequest({
            path: `schedule/meeting/${id}/remind`,
            type: 'POST'
        });
    }

    public static initOAuth(
        provider: IConnectionProvider,
        scopes: IConnectionScope[],
        redir: string,
    ): Promise<any> {
        return backendRequest({
            path: 'oauth/init',
            params: { provider, redir, scopes: scopes.join(',') },
            type: 'GET'
        });
    }

    public static deleteConnection(
        provider: IConnectionProvider,
        account: string,
        scopes: IConnectionScope[],
    ): Promise<any> {
        return backendRequest({
            path: 'oauth/connection',
            type: 'DELETE',
            params: { provider, account, scopes: scopes.join(',') }
        });
    }

    public static addThirdPartyApplication(
        provider: IConnectionProvider,
        account: string,
        password: string,
        scopes: IConnectionScope[],
    ): Promise<any> {
        return backendRequest({
            path: 'oauth/access',
            type: 'PUT',
            data: { provider, account, password, scopes }
        });
    }

    public static updateThirdPartyApplication(
        provider: IConnectionProvider,
        account: string,
        scopes: IConnectionScope[],
    ): Promise<any> {
        return backendRequest({
            path: 'oauth/access-scope',
            type: 'PUT',
            data: { provider, account, scopes }
        });
    }

    public static savePreferredAppleCalendar(
        account: string,
        name: string,
    ): Promise<any> {
        return backendRequest({
            path: 'calendar/apple/preferred',
            type: 'PUT',
            data: { account, name }
        });
    }

    public static getAppleCalendar(
        account: string,
    ): Promise<any> {
        return backendRequest({
            path: 'calendar/apple/calendars',
            type: 'GET',
            params: { account }
        });
    }

    public static deleteContacts(id: number): Promise<any> {
        return backendRequest({
            path: `users/contacts/ext/${id}`,
            type: 'DELETE',
        });
    }

    public static updateUserProfile(profile: Partial<IProfile>): Promise<any> {
        return backendRequest({
            path: 'users/profile',
            type: 'PATCH',
            data: profile
        });
    }

    public static getUserProfile(): Promise<IProfile> {
        return backendRequest({
            path: 'users/profile',
            type: 'GET'
        });
    }

    public static putMyWorkWeek(myWorkWeek: IWorkHours[]): Promise<''> {
        return backendRequest({
            path: 'users/workweek',
            type: 'PUT',
            data: myWorkWeek,
        });
    }

    public static getWorkWeek(): Promise<IWorkHours[]> {
        return backendRequest({
            path: 'users/workweek',
            type: 'GET',
        });
    }

    public static getMeetings(email: string): Promise<any> {
        return backendRequest({
            path: `schedule/meetings`,
            type: 'GET'
        }).then(meetings => meetings.map(transformMeeting(email)));
    }

    public static getCalendarEvents(
        start: string,
        end: string,
        timezone: string,
    ): Promise<ICalendarEventList[]> {
        return backendRequest({
            path: 'calendar/events',
            params: { start, end, timezone },
            type: 'GET'
        });
    }

    public static sendSupportMessage(supportMessage: ISupportMessageJson): Promise<''> {
        return backendRequest({
            path: supportMessage.captchaResponse ? 'notification/support' : 'notification/user-support',
            type: 'POST',
            data: supportMessage,
            skipTokenChecking: Boolean(supportMessage.captchaResponse),
        });
    }

    public static declineMeeting(id: number, decline: boolean): Promise<IMeeting> {
        return backendRequest({
            path: `schedule/meeting/${id}/decline`,
            type: 'PUT',
            data: decline,
        });
    }

    public static getSubscriptionPlans(): Promise<Array<ISubscriptionPlan>> {
        return backendRequest({
            path: 'subscription/plans',
            type: 'GET'
        });
    }

    public static storeCard(
        id: string,
        name: string,
        address: string,
        city: string,
        state: string
    ): Promise<ICardDetails> {
        return backendRequest({
            path: 'subscription/card',
            type: 'PUT',
            data: {
                id,
                name,
                line1: address,
                city,
                state: state || null
            }
        });
    }

    public static previewOrder(
        interval: PLAN_INTERVAL,
        quantity: number,
        renew: boolean,
        prorationToken?: string
    ): Promise<any> {
        return backendRequest({
            path: 'subscription/subscription/preview',
            type: 'GET',
            params: {
                interval,
                quantity,
                renew,
                proration: prorationToken
            }
        });
    }

    public static createSubscription(
        billingType: string,
        quantity: number,
        renew: boolean
    ): Promise<any> {
        return backendRequest({
            path: 'subscription/subscription',
            type: 'POST',
            data: {
                interval: billingType === 'monthly' ? 'MONTH' : 'YEAR',
                quantity,
                renew
            }
        });
    }

    public static getSubscription(): Promise<ISubscription> {
        return backendRequest({
            path: 'subscription/subscription',
            type: 'GET'
        });
    }

    public static getAssignedLicenses(): Promise<Array<ILicenseAssignment>> {
        return backendRequest({
            path: 'subscription/licenses/assignments',
            type: 'GET'
        });
    }

    public static assignLicense(license: ILicenseAssignment): Promise<ILicenseAssignment> {
        return backendRequest({
            path: 'subscription/license/assignment',
            type: 'POST',
            data: license
        });
    }

    public static removeLicense(licenseeId: number): Promise<any> {
        return backendRequest({
            path: `subscription/license/assignment/${licenseeId}`,
            type: 'DELETE'
        });
    }

    public static setLicenseExpiring(id: number, expiring: boolean): Promise<any> {
        return backendRequest({
            path: 'subscription/license/assignment/expiring',
            type: 'PUT',
            data: { id, expiring }
        });
    }

    public static getCard(): Promise<ICardDetails> {
        return backendRequest({
            path: 'subscription/card',
            type: 'GET'
        });
    }

    public static getCredit(): Promise<ICustomerCredit> {
        return backendRequest({
            path: 'subscription/credit',
            type: 'GET'
        });
    }

    public static getInvoices(): Promise<Array<IInvoice>> {
        return backendRequest({
            path: 'subscription/invoices',
            type: 'GET'
        });
    }

    public static getInvoice(id: string): Promise<Blob> {
        return backendRequest({
            path: `subscription/invoices/${id}`,
            type: 'GET',
            responseType: 'blob'
        });
    }

    public static getCloudinarySignature(params: string): Promise<string> {
        return backendRequest({
            path: `subscription/logo/signature?${params}`,
            type: 'GET'
        });
    }

    public static saveLogo(uploadInfo: IUploadInfo): Promise<any> {
        return backendRequest({
            path: 'subscription/logo',
            type: 'PUT',
            data: uploadInfo
        });
    }

    public static getLogo(): Promise<{ url?: string }> {
        return backendRequest({
            path: 'subscription/logo',
            type: 'GET'
        });
    }

    public static deleteLogo(): Promise<any> {
        return backendRequest({
            path: 'subscription/logo',
            type: 'DELETE'
        });
    }

    public static updateSubscription(
        billingType: string,
        quantity: number,
        renew: boolean,
        proration: string
    ): Promise<any> {
        return backendRequest({
            path: 'subscription/subscription',
            type: 'PUT',
            data: {
                interval: billingType === 'monthly' ? 'MONTH' : 'YEAR',
                quantity,
                renew,
                proration
            }
        });
    }

    public static setNumberOfExpiringLicenses(quantity: number) {
        return backendRequest({
            path: 'subscription/licenses/expiring',
            type: 'PUT',
            params: { quantity }
        });
    }

    public static retryPayment() {
        return backendRequest({
            path: 'subscription/subscription/pay',
            type: 'POST'
        });
    }

    public static getContacts(): Promise<IContact[]> {
        return backendRequest({
            path: 'users/contacts',
            type: 'GET'
        });
    }

    public static getSimpleProfile(email: string): Promise<ISimpleProfile> {
        return backendRequest({
            path: `users/profile/simple`,
            type: 'GET',
            params: { email }
        });
    }

    public static shouldPrompt(subject: 'calendar' | 'timezone'): Promise<boolean> {
        return backendRequest({
            path: 'users/should-prompt',
            type: 'GET',
            params: { subject },
        });
    }

    public static saveShouldPrompt(subject: 'calendar' | 'timezone', when: 'NEXT_WEEK' | 'NEVER'): Promise<''> {
        return backendRequest({
            path: 'users/should-prompt',
            type: 'PUT',
            data: { subject, when },
        });
    }

    public static getQuota(): Promise<IQuota> {
        return backendRequest({
            path: 'schedule/quota',
            type: 'GET'
        });
    }

    public static setMeetingTimezone(meetingId: number, timezone: string) {
        return backendRequest({
            path: `schedule/meeting/${meetingId}/timezone`,
            type: 'PUT',
            data: {
                timezone,
                country: getTimezone(timezone).country.toLowerCase(),
            },
        });
    }

    public static getMeetingAnswers(id: number) {
        return backendRequest({
            path: `schedule/meeting/${id}/answers`,
            type: 'GET',
        });
    }

    public static setMeetingDate(id: number, date: string) {
        return backendRequest({
            path: `schedule/meeting/${id}/set`,
            type: 'POST',
            params: { date },
        });
    }

    public static resolveShortenedURL(code: string) {
        return backendRequest({
            path: `shortened/${code}`,
            type: 'GET',
            skipTokenChecking: true,
        });
    }

    public static getMeetingIntegrations(id: number): Promise<IMeetingIntegrations> {
        return backendRequest({
            path: `schedule/meeting/${id}/integrations`,
            type: 'GET',
        });
    }

    public static getUnseenMeetings(): Promise<IUnseenMeetings> {
        return backendRequest({
            path: 'schedule/meetings/unseen',
            type: 'GET',
        });
    }

    public static discardUnseenMeetings(category: 'scheduled' | 'canceled' | 'inprogress') {
        return backendRequest({
            path: 'schedule/meetings/unseen/discard',
            type: 'PUT',
            params: { category },
        });
    }

    public static saveSeenPropositions(
        meetingId: number,
        propositions: { earliest: string; latest: string }[],
    ) {
        return backendRequest({
            path: `schedule/meeting/${meetingId}/seen-propositions`,
            type: 'PUT',
            data: propositions,
        });
    }

    public static withdrawAnswer(meetingId: number): Promise<IMeeting> {
        return backendRequest({
            path: `schedule/meeting/${meetingId}/answer/withdraw`,
            type: 'POST',
        });
    }

    public static getMeetingLink(meetingId: number, participantId: number) {
        return backendRequest({
            path: `schedule/meeting/${meetingId}/adhoc-url`,
            type: 'GET',
            params: { invitee: participantId },
        });
    }

    public static getHolidays(from: string, to: string, country: string, type: 'minor' | 'major' = 'minor'): Promise<IHoliday[]> {
        return backendRequest({
            path: `timedate/holidays`,
            type: 'GET',
            params: { from, to, country, type },
        });
    }

    public static getResources(): Promise<IResource[]> {
        return backendRequest({
            path: 'company/resources',
            type: 'GET',
        });
    }

    public static addResource(name: string): Promise<{ id: number }> {
        return backendRequest({
            path: 'company/resources',
            type: 'POST',
            data: { name },
        });
    }

    public static deleteResource(id: number) {
        return backendRequest({
            path: `company/resources/${id}`,
            type: 'DELETE',
        });
    }

    public static getServices(): Promise<IService[]> {
        return backendRequest({
            path: 'company/services',
            type: 'GET',
        });
    }

    public static addService(service: INewService): Promise<{ id: number }> {
        return backendRequest({
            path: 'company/services',
            type: 'POST',
            data: service,
        });
    }

    public static updateService(id: number, service: INewService) {
        return backendRequest({
            path: `company/services/${id}`,
            type: 'PUT',
            data: service,
        });
    }

    public static deleteService(id: number) {
        return backendRequest({
            path: `company/services/${id}`,
            type: 'DELETE',
        });
    }

    public static getLocations(): Promise<ILocation[]> {
        return backendRequest({
            path: 'company/locations',
            type: 'GET',
        });
    }

    public static addLocation(location: INewLocation): Promise<{ id: number }> {
        return backendRequest({
            path: 'company/locations',
            type: 'POST',
            data: location,
        });
    }

    public static updateLocation(id: number, location: INewLocation) {
        return backendRequest({
            path: `company/locations/${id}`,
            type: 'PUT',
            data: location,
        });
    }

    public static deleteLocation(id: number) {
        return backendRequest({
            path: `company/locations/${id}`,
            type: 'DELETE',
        });
    }

    public static getPeople(): Promise<IPerson[]> {
        return backendRequest({
            path: 'company/staff',
            type: 'GET',
        });
    }

    public static addPerson(person: INewPerson): Promise<{ id: number }> {
        return backendRequest({
            path: 'company/staff',
            type: 'POST',
            data: person,
        });
    }

    public static updatePerson(id: number, person: INewPerson) {
        return backendRequest({
            path: `company/staff/${id}`,
            type: 'PUT',
            data: person,
        });
    }

    public static deletePerson(id: number) {
        return backendRequest({
            path: `company/staff/${id}`,
            type: 'DELETE',
        });
    }

    public static addHours(personId: number, hours: INewWorkHours): Promise<{ id: number }> {
        return backendRequest({
            path: `company/staff/${personId}/locations`,
            type: 'POST',
            data: hours,
        });
    }

    public static updateHours(personId: number, id: number, hours: INewWorkHours) {
        return backendRequest({
            path: `company/staff/${personId}/locations/${id}`,
            type: 'PUT',
            data: hours,
        });
    }

    public static deleteHours(personId: number, id: number) {
        return backendRequest({
            path: `company/staff/${personId}/locations/${id}`,
            type: 'DELETE',
        });
    }

    public static getAppointmentDates(
        company: number,
        service: number,
        from: string,
        to: string,
        ignoredAppointment?: number,
    ): Promise<ILocationDates[]> {
        return backendRequest({
            path: 'appointments/public/available',
            type: 'GET',
            params: { company, service, from, to, ignoredAppointment },
            skipTokenChecking: true,
        });
    }

    public static bookAppointment(appointment: INewAppointment) {
        return backendRequest({
            path: 'appointments/company/appointment',
            type: 'POST',
            data: appointment,
        });
    }

    public static bookAppointmentCustomer(appointment: INewCustomerAppointment) {
        return backendRequest({
            path: 'appointments/me/appointment',
            type: 'POST',
            data: appointment,
        });
    }

    public static editAppointment(appointment: INewAppointment) {
        return backendRequest({
            path: 'appointments/company/appointment',
            type: 'PUT',
            data: appointment,
        });
    }

    public static editAppointmentCustomer(appointment: INewCustomerAppointment) {
        return backendRequest({
            path: 'appointments/me/appointment',
            type: 'PUT',
            data: appointment,
        });
    }

    public static getAppointments(from: string, to: string, employee?: number): Promise<IAppointment[]> {
        return backendRequest({
            path: 'appointments/company/appointments',
            type: 'GET',
            params: { from, to, employee },
        });
    }

    public static searchAppointments(query: string): Promise<IAppointment[]> {
        return backendRequest({
            path: 'appointments/company/appointments',
            type: 'GET',
            params: { query },
        });
    }

    public static getCustomerAppointments(from: string, to: string): Promise<IAppointment[]> {
        return backendRequest({
            path: 'appointments/me/appointments',
            type: 'GET',
            params: { from, to },
        });
    }

    public static cancelAppointment(id: number) {
        return backendRequest({
            path: `appointments/company/appointment/${id}/cancel`,
            type: 'POST',
        });
    }

    public static changeAppointmentConfirm(id: number, confirmed: boolean) {
        return backendRequest({
            path: `appointments/company/appointment/${id}/confirm`,
            type: 'PUT',
            params: { confirmed },
        });
    }

    public static confirmAppointment(id: number) {
        return backendRequest({
            path: 'appointments/me/appointment/confirm',
            type: 'PUT',
            params: { id },
        });
    }

    public static customerCancelAppointment(id: number, company: number) {
        return backendRequest({
            path: `appointments/me/appointment/cancel`,
            type: 'POST',
            params: { id, company },
        });
    }

    public static submitReview(review: IReview) {
        return backendRequest({
            path: `appointments/me/review`,
            type: 'PUT',
            data: review,
        });
    }

    public static deleteReview(reviewId: number) {
        return backendRequest({
            path: `appointments/me/review/${reviewId}`,
            type: 'DELETE'
        });
    }

    public static companiesRating(companies_ids: number[], isLoggedIn: boolean = false): Promise<ICompaniesRating[]> {
        const companies: string = companies_ids.join(',').toString();
        return backendRequest({
            path: `appointments/public/company-rating`,
            type: 'GET',
            params: { companies },
            skipTokenChecking: !isLoggedIn,
        });
    }

    public static submitAnswer(answer: { id: number, answer: string }) {
        return backendRequest({
            path: 'appointments/company/review-answer',
            type: 'PUT',
            data: answer,
        });
    }

    public static deleteAnswer(id: number) {
        return backendRequest({
            path: `appointments/company/review-answer/${id}`,
            type: 'DELETE',
        });
    }

    public static submitReport(report: {id: number, reason: string}) {
        return backendRequest({
            path: `appointments/company/report-abuse`,
            type: 'POST',
            data: report,
        });
    }

    public static getSbCloudinarySignature(params: string): Promise<string> {
        return backendRequest({
            path: `company/cloudinary-signature?${params}`,
            type: 'GET'
        });
    }

    public static searchCustomers(query: string): Promise<ISearchCustomer[]> {
        return backendRequest({
            path: 'company/customer-care',
            type: 'GET',
            params: { query },
        });
    }

    public static getCustomer(href: string): Promise<ICustomerCare> {
        return backendRequest({
            path: `company${href}`,
            type: 'GET',
        });
    }

    public static updateCustomer(customer: ICustomer) {
        return backendRequest({
            path: `company/customer/${customer.id}`,
            type: 'PUT',
            data: customer,
        });
    }

    public static updateCare(customerId: number, care: ICustomer) {
        return backendRequest({
            path: `company/customer/${customerId}/care/${care.id}`,
            type: 'PUT',
            data: care,
        });
    }

    public static getBookingCompany(id: number) {
        return backendRequest({
            path: `company/company/${id}`,
            type: 'GET',
            skipTokenChecking: true,
        });
    }

    public static getCompanyGeneral(): Promise<ICompanyGeneral> {
        return backendRequest({
            path: 'company/company-settings',
            type: 'GET',
        });
    }

    public static saveCompanyGeneral(data: ICompanyGeneral) {
        return backendRequest({
            path: 'company/company-settings',
            type: 'PUT',
            data: data,
        });
    }

    public static searchForLocations(query: string, subdomain?: string): Promise<ILocationSearchResult[]> {
        return backendRequest({
            path: 'company/company',
            type: 'GET',
            params: { query, limit: 30, subdomain },
            skipTokenChecking: true,
        });
    }

    public static getMyCare(companyId: number): Promise<ICustomer[]> {
        return backendRequest({
            path: `company/my-cares`,
            type: 'GET',
            params: { companyId },
        });
    }

    public static updateMyCare(companyId: number, person: ICustomer) {
        return backendRequest({
            path: `company/my-cares/${companyId}/care/${person.id}`,
            type: 'PUT',
            data: person,
        });
    }

    public static saveServiceOrder(order: number[]) {
        return backendRequest({
            path: 'company/services-order',
            type: 'PUT',
            data: order,
        });
    }

    public static addCare(customerId: number, person: INewCustomer): Promise<{ id: number }> {
        return backendRequest({
            path: `company/customer/${customerId}/care`,
            type: 'POST',
            data: person,
        });
    }

    public static deleteCare(customerId: number, id: number) {
        return backendRequest({
            path: `company/customer/${customerId}/care/${id}`,
            type: 'DELETE',
        });
    }

    public static deleteCustomer(id: number) {
        return backendRequest({
            path: `company/customer/${id}`,
            type: 'DELETE',
        });
    }

    public static addCustomer(customer: INewCustomer): Promise<{ id: number }> {
        return backendRequest({
            path: 'company/customer',
            type: 'POST',
            data: customer,
        });
    }

    public static getMorePropositions(meetingId: number, exclude: number[]): Promise<IProposition[]> {
        return backendRequest({
            path: `schedule/meeting/${meetingId}/more-propositions`,
            type: 'GET',
            params: { exclude: exclude.join(',') },
        });
    }

    public static getPropositions(meetingId: number): Promise<IProposition[]> {
        return backendRequest({
            path: `schedule/meeting/${meetingId}/propositions`,
            type: 'GET',
        });
    }

    public static getChangePreview(change: IPreviewChange): Promise<IAppointment[]> {
        return backendRequest({
            path: 'appointments/company/change-preview',
            type: 'POST',
            data: change,
        });
    }

    public static flagAffectedAppointments(change: IPreviewChange) {
        return backendRequest({
            path: 'appointments/company/change-apply',
            type: 'POST',
            data: change,
        });
    }

    public static changeNeedsAttention(appointmentId: number, value: boolean) {
        return backendRequest({
            path: `appointments/company/appointment/${appointmentId}/needs-attention`,
            type: 'PUT',
            data: value,
        });
    }

    public static mergeProfiles(authToken: string) {
        return backendRequest({
            path: 'profiles/merge',
            type: 'POST',
            data: JSON.stringify(authToken),
        });
    }

    public static updateMyCustomer(companyId: number, customer: INewCustomer) {
        return backendRequest({
            path: `company/my-customer/${companyId}`,
            type: 'PUT',
            data: customer,
        });
    }

    public static sendInvites(meetingId: number) {
        return backendRequest({
            path: `schedule/meeting/${meetingId}/invite`,
            type: 'PUT',
        });
    }

    public static saveWorkWeekHours(data: { days: string[], begin: string, end: string }) {
        return backendRequest({
            path: `users/workweek`,
            type: 'POST',
            data: data
        });
    }

    public static deleteWeekHours(id: number) {
        return backendRequest({
            path: `users/workweek/${id}`,
            type: 'DELETE',
        });
    }

    public static getConsents(types: string): Promise<any> {
        return backendRequest({
            path: `users/required-consent?types=${types}`,
            type: 'GET'
        });
    }

    public static acceptConsent(contents: string[]) {
        return backendRequest({
            path: `users/accept-consent`,
            type: 'PUT',
            data: contents,
        });
    }

    public static checkSubdomain(subdomain: string) {
        return backendRequest({
            path: 'company/company',
            type: 'HEAD',
            params: { subdomain },
            skipTokenChecking: true,
        });
    }

    public static createCompany(domain: string) {
        return backendRequest({
            path: 'company/create',
            type: 'POST',
            data: {
                domain: domain
            }
        });
    }
}
