// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".opnionBox__3dfSW {\n    margin-top: 26px;\n}\n\n.reviewHeader__McXfG {\n    margin-bottom: 10px;\n    margin-top: 0;\n    font-size: 16px;\n    line-height: 20px;\n}\n\n.btnGroup__2-fNd {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 23px;\n}\n\n.btnRow__1t6a2 {\n    margin-top: 23px;\n    text-align: right;\n}\n\n.submitButton__cFHAO {\n    background-color: #1488a8;\n    color: white;\n    border-radius: 5px;\n}\n\n.submitButton__cFHAO:hover {\n    background-color: #1488a8;\n}\n\n.submitButton__cFHAO:disabled {\n    background-color: #dee1e1;\n    color: #9e9e9e;\n    border: none;\n}\n\n.removeButton__1Zlqe {\n    background-color: transparent !important;\n    color: #1488a8;\n    border: none;\n}\n", "",{"version":3,"sources":["Review.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,wCAAwC;IACxC,cAAc;IACd,YAAY;AAChB","file":"Review.css","sourcesContent":[".opnionBox {\n    margin-top: 26px;\n}\n\n.reviewHeader {\n    margin-bottom: 10px;\n    margin-top: 0;\n    font-size: 16px;\n    line-height: 20px;\n}\n\n.btnGroup {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 23px;\n}\n\n.btnRow {\n    margin-top: 23px;\n    text-align: right;\n}\n\n.submitButton {\n    background-color: #1488a8;\n    color: white;\n    border-radius: 5px;\n}\n\n.submitButton:hover {\n    background-color: #1488a8;\n}\n\n.submitButton:disabled {\n    background-color: #dee1e1;\n    color: #9e9e9e;\n    border: none;\n}\n\n.removeButton {\n    background-color: transparent !important;\n    color: #1488a8;\n    border: none;\n}\n"]}]);
// Exports
exports.locals = {
	"opnionBox": "opnionBox__3dfSW",
	"reviewHeader": "reviewHeader__McXfG",
	"btnGroup": "btnGroup__2-fNd",
	"btnRow": "btnRow__1t6a2",
	"submitButton": "submitButton__cFHAO",
	"removeButton": "removeButton__1Zlqe"
};
module.exports = exports;
