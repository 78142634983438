// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".radioContent__16RZA {\n    margin: 0;\n    position: relative;\n    display: flex;\n    align-items: center;\n    width: -webkit-fit-content;\n    width: -moz-fit-content;\n    width: fit-content;\n}\n\n.radioContent__16RZA:hover {\n    cursor: pointer;\n}\n\n.outsideCircle__2T1kB {\n    position: absolute;\n    left: 0;\n    top: 0;\n    border-radius: 50%;\n    border-style: solid;\n    border-width: 1px;\n}\n\n.insideCircle__3EWhp {\n    border-radius: 50%;\n    width: 100%;\n    height: 100%;\n}\n\n.radioTitle__1LpyC {\n    margin: 0;\n}\n", "",{"version":3,"sources":["RadioButton.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,0BAAkB;IAAlB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,SAAS;AACb","file":"RadioButton.css","sourcesContent":[".radioContent {\n    margin: 0;\n    position: relative;\n    display: flex;\n    align-items: center;\n    width: fit-content;\n}\n\n.radioContent:hover {\n    cursor: pointer;\n}\n\n.outsideCircle {\n    position: absolute;\n    left: 0;\n    top: 0;\n    border-radius: 50%;\n    border-style: solid;\n    border-width: 1px;\n}\n\n.insideCircle {\n    border-radius: 50%;\n    width: 100%;\n    height: 100%;\n}\n\n.radioTitle {\n    margin: 0;\n}\n"]}]);
// Exports
exports.locals = {
	"radioContent": "radioContent__16RZA",
	"outsideCircle": "outsideCircle__2T1kB",
	"insideCircle": "insideCircle__3EWhp",
	"radioTitle": "radioTitle__1LpyC"
};
module.exports = exports;
