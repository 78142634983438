// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".wrapper__2Y7fT {\n    margin-top: 117px;\n    padding: 0;\n}\n\n.sliderItem__2W6ns {\n    height: -webkit-fit-content;\n    height: -moz-fit-content;\n    height: fit-content;\n    position: relative;\n    height: 800px;\n}\n\n.content__1NvN- {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: left;\n    padding: 120px 100px;\n    z-index: 9999;\n}\n\n.title__1z8SK {\n    text-align: left;\n    font-weight: 400;\n    font-size: 80px;\n    color: white;\n}\n\n.title__1z8SK b {\n    font-weight: 700;\n}\n\n.itemImg__3riDp {\n    width: 100%;\n    filter: brightness(50%);\n    -o-object-fit: cover;\n       object-fit: cover;\n    height: 100%;\n}\n", "",{"version":3,"sources":["SbSlider.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,2BAAmB;IAAnB,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,oBAAoB;IACpB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IAEX,uBAAuB;IACvB,oBAAiB;OAAjB,iBAAiB;IACjB,YAAY;AAChB","file":"SbSlider.css","sourcesContent":[".wrapper {\n    margin-top: 117px;\n    padding: 0;\n}\n\n.sliderItem {\n    height: fit-content;\n    position: relative;\n    height: 800px;\n}\n\n.content {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: left;\n    padding: 120px 100px;\n    z-index: 9999;\n}\n\n.title {\n    text-align: left;\n    font-weight: 400;\n    font-size: 80px;\n    color: white;\n}\n\n.title b {\n    font-weight: 700;\n}\n\n.itemImg {\n    width: 100%;\n    -webkit-filter: brightness(50%);\n    filter: brightness(50%);\n    object-fit: cover;\n    height: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "wrapper__2Y7fT",
	"sliderItem": "sliderItem__2W6ns",
	"content": "content__1NvN-",
	"title": "title__1z8SK",
	"itemImg": "itemImg__3riDp"
};
module.exports = exports;
