export default class Validators {
    private static readonly EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // tslint:disable-line
    private static readonly PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\[\[\]\\\^\$\.\|\?\*\+\(\)"`!@#%&_}{:><~,/:;'=\-]{8,}$/;
    private static readonly HOUR_AM_PM_VALIDATOR = /^((([1-9]|[1-1][[0-2])(\s+)?)(am|pm|a|p)(\s+)?)$/;

    public static isValidEmail(email: string): boolean {
        return this.EMAIL_REGEX.test(email);
    }

    public static isEmailsValid(emails: string[]): boolean {
        return (
            emails.filter(e => {
                return !this.isValidEmail(e);
            }).length === 0
        );
    }

    public static isValidPassword(password: string): boolean {
        return this.PASSWORD_REGEX.test(password);
    }

    public static isCorrectAMorPMHour(hour: string): boolean {
        return this.HOUR_AM_PM_VALIDATOR.test(hour);
    }
}
