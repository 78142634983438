// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__16x-Q {\n    margin: 8px 8px 0;\n    display: flex;\n    justify-content: space-between;\n}\n\n.root__16x-Q button {\n    width: 100px;\n    padding: 3px 8px;\n    font-size: 16px;\n}\n\n.setDate__2DEoA {\n    display: flex;\n    margin: 8px auto 0;\n    padding: 3px 12px;\n}\n", "",{"version":3,"sources":["PropositionButtons.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,iBAAiB;AACrB","file":"PropositionButtons.css","sourcesContent":[".root {\n    margin: 8px 8px 0;\n    display: flex;\n    justify-content: space-between;\n}\n\n.root button {\n    width: 100px;\n    padding: 3px 8px;\n    font-size: 16px;\n}\n\n.setDate {\n    display: flex;\n    margin: 8px auto 0;\n    padding: 3px 12px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__16x-Q",
	"setDate": "setDate__2DEoA"
};
module.exports = exports;
