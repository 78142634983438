import React from 'react';
import * as R from 'ramda';
import Select from '../../_ReusableComponents/Select/Select';
import { useIsDesktop } from '../../../utils/responsive';
import { FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
const styles = require('./DurationPicker.css');

interface Props {
    value: number;
    onChange: (value: number) => void;
    error: string | null;
    disabled?: boolean;
}

export default function DurationPicker(props: Props) {
    const { t } = useTranslation();
    const isDesktop = useIsDesktop();
    const hours = Math.floor(props.value / 60);
    const minutes = props.value % 60;
    return (
        <div className={styles.root}>
            <div className={styles.label}>
                {t('new.duration')}
            </div>
            <div className={styles.selects}>
                <Select
                    label=""
                    value={{ value: hours, label: String(hours) }}
                    onChange={option => props.onChange((option ? option.value : 0) * 60 + minutes)}
                    options={R.times(n => ({ value: n, label: String(n) }), 25)}
                    isSearchable={isDesktop}
                    id="duration-hours"
                    className={styles.select}
                    isClearable
                />
                <label className={styles.hoursLabel}>
                    {t('new.hours')}
                </label>
                <Select
                    label=""
                    value={{ value: minutes, label: String(minutes) }}
                    onChange={option => props.onChange(hours * 60 + (option ? option.value: 0))}
                    options={R.times(n => ({ value: n * 15, label: String(n * 15) }), 4)}
                    isSearchable={isDesktop}
                    className={styles.select}
                    isClearable
                />
                <label className={styles.minutesLabel}>
                    {t('new.minutes')}
                </label>
            </div>
            {props.error !== null && (
                <FormHelperText error className={styles.error}>
                    {props.error}
                </FormHelperText>
            )}
        </div>
    );
}
