import * as React from 'react';
import NewMeetingPage from '../NewMeetingPage/NewMeetingPage';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import { connect } from 'react-redux';
import FallingLeaves from '../../components/_ReusableComponents/FallingLeaves/FallingLeaves';

const EditPage = props => {
    const id = Number(props.match.params.id);
    const meetings = props.data.get('meetings');
    if (meetings === null) {
        return <FallingLeaves />;
    }
    const meeting = meetings.find(m => m.id === id);
    if (meeting && meeting.after) {
        return <NewMeetingPage {...props} meetingToEdit={meeting} />;
    }
    return null;
};

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(EditPage);
