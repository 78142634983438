import React from 'react';
import { Skeleton } from '@material-ui/core';
import classNames from 'classnames';

const ConfirmScheduledDateBoxSkeleton = () => {
    return (
        <div className={classNames('lightgrey-border', 'm-b-12', 'm-r-8', 'p-18')} style={{ position: 'relative' }}>
            <Skeleton variant="text" width={60} height={12} />
            <Skeleton variant="text" width={'80%'} height={30} />

            <div className={classNames('inline-between')}>
                <Skeleton variant="text" width={48} height={20} />
                <Skeleton variant="text" width={48} height={20} />
            </div>
            <Skeleton variant="rectangular" width={'100%'} height={30} />
            <div className={classNames('inline-between')}>
                <Skeleton variant="circular" width={26} height={26} />
                <Skeleton variant="circular" width={26} height={26} />
            </div>
            <div className={classNames('inline-between', 'm-h-6')}>
                <Skeleton variant="rectangular" width={100} height={36} />
                <Skeleton variant="rectangular" width={100} height={36} />
            </div>
            <div className={classNames('item-center', 'w-center')}>
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton className="m-l-6" variant="text" width={50} height={13} />
            </div>
        </div>
    );
};

export default ConfirmScheduledDateBoxSkeleton;
