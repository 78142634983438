import React from 'react';
// import ENTermsAndCondition from 'components/Data/TermsAndConditions/en.json';
import PLTermsAndCondition from 'components/Data/TermsAndConditions/pl.json';
import { useTranslation } from 'react-i18next';
import {Privacy} from 'components/_ReusableComponents/Privacy/Privacy';
import Styles from './Terms.css';
import TermsContent from 'components/_ReusableComponents/TermsContent/TermsContent';

export default function Terms() {
    const { i18n } = useTranslation();
    // const data = i18n.language === 'en' ? ENTermsAndCondition : PLTermsAndCondition;

    return i18n.language === 'en'?
        (<div className={Styles.root}>
            <TermsContent />
        </div>):
        (<div className={Styles.root}>
            <Privacy
                data={PLTermsAndCondition}
            />
        </div>);
}
