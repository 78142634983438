import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import * as R from 'ramda';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import DateRangePicker from '../../components/NewMeetingPageComponents/DateRangePicker/DateRangePicker';
import DurationPicker from '../../components/NewMeetingPageComponents/DurationPicker/DurationPicker';
import ToggleSwitch from 'components/_ReusableComponents/ToggleSwitch/ToggleSwitch';
import IActions from 'actions/IActions';
import IDateRange from '../../interfaces/IDateRange';
import IMeeting from '../../interfaces/IMeeting';
import TextField from '../../components/_ReusableComponents/TextField/TextField';
import { IConnection, IMeetingIntegrations, IParticipant, IQuota } from '../../interfaces/interfaces';
import Participants from '../../components/NewMeetingPageComponents/Participants/Participants';
import Header from '../../components/_ReusableComponents/Header/Header/Header';
import { useIsDesktop } from '../../utils/responsive';
import BackendMethods from '../../api/BackendMethods';
import Button from '../../components/_ReusableComponents/Button/Button';
import CalendarPicker from '../../components/NewMeetingPageComponents/CalendarPicker/CalendarPicker';
import VideoConfPicker from '../../components/NewMeetingPageComponents/VideoConfPicker/VideoConfPicker';
import INewMeeting from '../../interfaces/INewMeeting';
import { getTimezone } from 'countries-and-timezones';
import useAsyncEffect from '../../utils/useAsyncEffect';
import AskForTimezoneModal from '../../components/_ReusableComponents/Modals/AskForTimezoneModal/AskForTimezoneModal';
import useInterval from '../../utils/useInterval';
import GlobalContext from '../../GlobalContext';
import IGetPreviewMeetingDate from 'interfaces/IGetPreviewMeetingDate';
import IPreviewMeetingDate from 'interfaces/IPreviewMeetingDate';
const styles = require('./NewMeetingPage.css');

interface Props {
    actions: IActions;
    data: any;
    meetingToEdit?: IMeeting;
}

export interface State {
    isBusiness: boolean;
    title: string;
    desc: string;
    participants: Record<string, IParticipant>;
    duration: number;
    dateRange: IDateRange;
    integrations: IMeetingIntegrations | null;
}

const getCurrentDate = (timezone: string, timeFormat: string, language: string) => {
    const dayMonth = language === 'pl' ? 'D MMMM' : 'MMMM D,';
    const hourMinute = timeFormat === '12h' ? 'h:mm a' : 'H:mm';
    return moment().tz(timezone).format(`dddd, ${dayMonth} Y, ${hourMinute} z`);
};

function NewMeetingPage(props: Props) {
    const { t, i18n: { language } } = useTranslation();
    const history = useHistory();
    const isDesktop = useIsDesktop();
    const { profile } = useContext(GlobalContext);
    const { meetingToEdit, actions } = props;
    const [isBusiness, setIsBusiness] = useState(true);
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [participants, setParticipants] = useState<Record<string, IParticipant>>({
        [profile.email]: {
            id: profile.id,
            email: profile.email,
            name: profile.firstName ? `${profile.firstName} ${profile.lastName}` : null,
            adhoc: false,
            timezone: profile.timezone,
            country: profile.country,
            editingTimeZone: false,
            reviewRequired: false,
        }
    });
    const [initPrePrediction, setInitPrePrediction] = useState<boolean[]>([]);
    const [duration, setDuration] = useState<number>(0);
    const [dateRange, setDateRange] = useState<IDateRange>({
        dateFrom: moment().tz(participants[profile.email].timezone).startOf('day').toISOString(),
        dateTo: participants[profile.email].country === 'ca' || participants[profile.email].country === 'us'
            ? moment().tz(participants[profile.email].timezone).add((13 - moment().tz(participants[profile.email].timezone).day()) % 7, 'days').endOf('day').toISOString()
            : moment().tz(participants[profile.email].timezone).isoWeekday(7).endOf('day').toISOString()
    });
    const [titleError, setTitleError] = useState<string | null>(null);
    const [participantsError, setParticipantsError] = useState<string | null>(null);
    const [durationError, setDurationError] = useState<string | null>(null);
    const [currentTime, setCurrentTime] = useState(getCurrentDate(profile.timezone, profile.timeFormat, language));
    const connections: IConnection[] = props.data.get('connections');
    const calendars = connections.filter((x) => x.scopes.includes('CALENDAR'));
    const confs = connections.filter((x) => (
        x.scopes.includes('VIDEO_CONF') || (x.provider === 'google' && x.scopes.includes('CALENDAR'))
    ));
    const [integrations, setIntegrations] = useState<IMeetingIntegrations>({
        calendar: calendars.find((x) => x.account === profile.email) || calendars[0],
    });
    const [quota, setQuota] = useState<IQuota | null>(null);
    const [sending, setSending] = useState(false);
    const previewMeetingDate: IPreviewMeetingDate = props.data.get('previewMeetingDate');
    const [isDispalyPreviewMeetingDate, setIsDispalyPreviewMeetingDate] = useState<boolean>(false);
    useAsyncEffect(async () => {
        if (meetingToEdit) {
            setIsBusiness(meetingToEdit.type === 'BUSINESS');
            setTitle(meetingToEdit.title);
            setDesc(meetingToEdit.description);
            setParticipants(R.fromPairs(meetingToEdit.participants.map(participant => [participant.email, {
                timezone: profile.timezone,
                country: profile.country,
                ...participant,
                editingTimeZone: false
            }])));
            setDuration(meetingToEdit.duration);
            setDateRange({ dateFrom: meetingToEdit.after, dateTo: meetingToEdit.before });
            setIntegrations(await BackendMethods.getMeetingIntegrations(meetingToEdit.id));
        } else {
            const meetingDataRaw = sessionStorage.getItem('new-meeting');
            if (meetingDataRaw) {
                const newMeetingData = JSON.parse(meetingDataRaw);
                setIsBusiness(newMeetingData.isBusiness);
                setTitle(newMeetingData.title);
                setDesc(newMeetingData.desc);
                setParticipants(newMeetingData.participants);
                setDuration(newMeetingData.duration);
                setDateRange(newMeetingData.dateRange);
                setIntegrations(newMeetingData.integrations);
                sessionStorage.removeItem('new-meeting');
            }
        }
    }, [meetingToEdit]);
    const [timezoneDialogOpen, setTimezoneDialogOpen] = useState(false);
    useAsyncEffect(async () => {
        if (profile.timezone !== moment.tz.guess()) {
            setTimezoneDialogOpen(await BackendMethods.shouldPrompt('timezone'));
        }
    }, [profile.timezone]);
    useEffect(() => {
        setParticipants((currentParticipants) => ({
            ...currentParticipants,
            [profile.email]: {
                ...currentParticipants[profile.email],
                timezone: profile.timezone,
            },
        }));
    }, [profile.timezone]);
    useInterval(() => {
        setCurrentTime(getCurrentDate(participants[profile.email].timezone, profile.timeFormat, language));
    }, 1000, [participants[profile.email].timezone]);
    useAsyncEffect(async () => {
        if (profile.plusPlan.active) {
            setQuota(null);
        } else {
            setQuota(await BackendMethods.getQuota());
        }
    }, [profile.plusPlan.active]);
    const scheduled = Boolean(meetingToEdit) && meetingToEdit.state === 'SCHEDULED';
    const quotaExceeded = !meetingToEdit && quota && quota.usage >= quota.limit;
    const meetingData = {
        isBusiness,
        title,
        desc,
        participants,
        duration,
        dateRange,
        integrations,
    };
    const validate = () => {
        const isEmptyTitleFlag = title === '';
        const isTooLongTitleFlag = title.length >= 500;
        const isIncorrectParticipants = R.values(participants).filter((x) => !x.optional).length < 2;
        const isIncorrectDurationFlag = duration === 0;
        if (isEmptyTitleFlag) {
            setTitleError(t('new.titleEmpty'));
        }
        if (isTooLongTitleFlag) {
            setTitleError(t('new.titleLong'));
        }
        if (isIncorrectParticipants) {
            setParticipantsError(t('new.atLeastTwoRequired'));
        }
        if (isIncorrectDurationFlag) {
            setDurationError(t('new.pleaseSelectDuration'));
        }
        return !(
            isIncorrectParticipants ||
            isEmptyTitleFlag ||
            isTooLongTitleFlag ||
            isIncorrectDurationFlag
        );
    };
    const sendMeeting = async () => {
        if (sending || !validate()) {
            return;
        }
        setSending(true);
        const { dayEventBusy, holidayBusy, email } = profile;
        const { dateFrom, dateTo } = dateRange;
        const fromYear = moment(dateFrom).year();
        const toYear = moment(dateTo).year();
        const { timezone } = participants[email];
        const newMeeting: INewMeeting = {
            title,
            description: desc,
            type: isBusiness ? 'BUSINESS' : 'SOCIAL',
            duration,
            after: dateFrom,
            before: dateTo,
            participants: R.values(participants).map((x) => ({
                email: x.email,
                timezone: x.timezone,
                country: getTimezone(x.timezone).country.toLowerCase(),
                optional: x.optional,
            })),
            integrations,
            organizerDayEvents: dayEventBusy.filter((x) => moment.tz(x, timezone).isBetween(
                dateFrom,
                dateTo,
                undefined,
                '[]',
            )),
            organizerBusyHolidays: holidayBusy.filter(({ date }) => (
                moment.tz(date, 'MM-DD', timezone).year(fromYear)
                    .isBetween(dateFrom, dateTo, undefined, '[]')
                || moment.tz(date, 'MM-DD', timezone).year(toYear)
                    .isBetween(dateFrom, dateTo, undefined, '[]')
            )).map(({ date }) => {
                if (moment.tz(date, 'MM-DD', timezone).year(fromYear).isBefore(dateFrom)) {
                    return moment.tz(date, 'MM-DD', timezone).year(toYear).format('YYYY-MM-DD');
                } else {
                    return moment.tz(date, 'MM-DD', timezone).year(fromYear).format('YYYY-MM-DD');
                }
            }),
        };
        try {
            const newMeetingResponse = await (meetingToEdit
                ? BackendMethods.editMeeting(newMeeting, meetingToEdit.id)
                : BackendMethods.sendNewMeeting(newMeeting));
            actions.updateMeeting(newMeetingResponse, profile.email);
            isDesktop? history.push(`/main/${newMeetingResponse.id}`): history.push(`/meeting/${newMeetingResponse.id}`);
        } catch {
            actions.showErrorMessage(t('new.failedToCreate'));
        } finally {
            setSending(false);
        }
    };
    useEffect(() => {
        if (initPrePrediction.length > 0) {
            if (previewMeetingDate) {
                getPreviewMeetingDate();
                setIsDispalyPreviewMeetingDate(false);
            }
        } else {
            initPrePrediction.push(true);
        }
    }, [duration, participants]);
    useEffect(()=> {
        if(!previewMeetingDate) {
            setIsDispalyPreviewMeetingDate(false);
            return ;
        }
        setIsDispalyPreviewMeetingDate(true);
    }, [previewMeetingDate]);
    const getPreviewMeetingDate = async () => {
        const { dayEventBusy, holidayBusy, email } = profile;
        const { dateFrom, dateTo } = dateRange;
        const fromYear = moment(dateFrom).year();
        const toYear = moment(dateTo).year();
        const { timezone } = participants[email];
        let previewJson: IGetPreviewMeetingDate = {
            type: isBusiness ? 'BUSINESS' : 'SOCIAL',
            participants: R.values(participants).map((x) => ({
                email: x.email,
                timezone: x.timezone,
                country: getTimezone(x.timezone).country.toLowerCase(),
                optional: x.optional,
            })),
            organizerDayEvents: dayEventBusy.filter((x) => moment.tz(x, timezone).isBetween(
                dateFrom,
                dateTo,
                undefined,
                '[]',
            )),
            organizerBusyHolidays: holidayBusy.filter(({ date }) => (
                moment.tz(date, 'MM-DD', timezone).year(fromYear)
                    .isBetween(dateFrom, dateTo, undefined, '[]')
                || moment.tz(date, 'MM-DD', timezone).year(toYear)
                    .isBetween(dateFrom, dateTo, undefined, '[]')
            )).map(({ date }) => {
                if (moment.tz(date, 'MM-DD', timezone).year(fromYear).isBefore(dateFrom)) {
                    return moment.tz(date, 'MM-DD', timezone).year(toYear).format('YYYY-MM-DD');
                } else {
                    return moment.tz(date, 'MM-DD', timezone).year(fromYear).format('YYYY-MM-DD');
                }
            }),
        };
        if (duration !== 0) {
            previewJson.duration = duration;
        }
        try {
            await BackendMethods.previewPredictions(previewJson);
        } catch {
        } finally {
        }
    };
    const sendButton = (
        <Button
            variant="contained"
            color="primary"
            id="NewMeetingSendButton"
            onClick={sendMeeting}
            className={styles.sendButton}
            disabled={quotaExceeded}
        >
            {sending ? <i /> : t('new.findDates')}
        </Button>
    );
    return (
        <div>
            <Header>
                {!isDesktop && sendButton}
            </Header>
            {(!profile.plusPlan.active && !quota) ? null : (
                <div id="NewMeetingPage" className={styles.root}>
                    {quota && !meetingToEdit && (
                        <div className={styles.quota} data-testid="quota">
                            {quota.limit > quota.usage ? (
                                <div className={styles.remaining}>
                                    {t('new.remainingInvites', { count: quota.limit - quota.usage })}
                                </div>
                            ) : (
                                <div className={styles.exceeded}>
                                    {t('new.limitUsed')}
                                </div>
                            )}
                            <Button
                                variant="contained"
                                component={Link}
                                to="/upgradePlan"
                                className={styles.upgrade}
                            >
                                {t('home.upgrade')}
                            </Button>
                        </div>
                    )}
                    <div
                        className={classNames(styles.CurrentTime, {
                            [styles.disabled]: quotaExceeded
                        })}
                    >
                        <strong className={styles.Timezone} data-testid="current-time">
                            {currentTime}
                        </strong>
                    </div>
                    <ToggleSwitch
                        toggleBusiness={() => setIsBusiness(!isBusiness)}
                        currentState={isBusiness}
                        labelForState1={t('new.business')}
                        labelForState2={t('new.social')}
                        state1={true}
                        state2={false}
                        disabled={scheduled || quotaExceeded}
                    />
                    <TextField
                        id="NewMeetingTitleInput"
                        label={t('new.title')}
                        value={title}
                        onChange={({ target }) => {
                            setTitle(target.value);
                            setTitleError(null);
                        }}
                        fullWidth
                        margin="dense"
                        error={Boolean(titleError)}
                        helperText={titleError}
                        disabled={quotaExceeded}
                    />
                    <TextField
                        id="NewMeetingDescription"
                        label={t('new.description')}
                        value={desc}
                        onChange={({ target }) => setDesc(target.value)}
                        fullWidth
                        margin="dense"
                        multiline
                        disabled={quotaExceeded}
                    />
                    <Participants
                        participants={participants}
                        onChange={(newParticipants) => {
                            setParticipants(newParticipants);
                            setParticipantsError(null);
                        }}
                        error={participantsError}
                        profile={profile}
                        disabled={quotaExceeded}
                        data={props.data}
                        onLinkClick={() => {
                            sessionStorage.setItem('new-meeting', JSON.stringify(meetingData));
                        }}
                    />
                    <DurationPicker
                        value={duration}
                        onChange={(newDuration) => {
                            setDuration(newDuration);
                            setDurationError(null);
                        }}
                        error={durationError}
                        disabled={scheduled || quotaExceeded}
                    />
                    <DateRangePicker
                        title={t('new.dateRange')}
                        timezone={participants[profile.email].timezone}
                        country={participants[profile.email].country}
                        profile={profile}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        disabled={scheduled || quotaExceeded}
                        actions={actions}
                        dayEvents={meetingToEdit?.organizerDayEvents}
                        meetingHolidays={meetingToEdit?.organizerBusyHolidays}
                        previewMeetingDate={previewMeetingDate}
                        isDispalyPreviewMeetingDate={isDispalyPreviewMeetingDate}
                    />
                    <VideoConfPicker
                        confs={confs}
                        value={integrations.videoConference}
                        onChange={videoConference => setIntegrations({
                            ...integrations,
                            videoConference,
                            ...(videoConference?.provider === 'google' ? {
                                calendar: calendars.find(
                                    R.propEq('account', videoConference.account),
                                ),
                            } : {}),
                        })}
                    />
                    {calendars.length > 1 && (
                        <CalendarPicker
                            calendars={calendars}
                            value={integrations.calendar}
                            onChange={(calendar) => setIntegrations({
                                ...integrations,
                                calendar,
                            })}
                            isGoogleMeet={integrations.videoConference?.provider === 'google'}
                        />
                    )}
                    {isDesktop && sendButton}
                </div>
            )}
            <AskForTimezoneModal
                open={timezoneDialogOpen}
                onClose={() => setTimezoneDialogOpen(false)}
                data={props.data}
                actions={actions}
            />
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(NewMeetingPage);
