import {ISingleTypeAction, IMultipleTypesAction} from 'interfaces/IAction';
import BackendMethods from '../api/BackendMethods';
import { UPDATE_DATA } from './DataActions';
import { PLAN_INTERVAL } from '../interfaces/backendResponses/ISubscriptionPlan';
import ICardDetails from '../interfaces/backendResponses/ICardDetails';
import ISubscription from '../interfaces/backendResponses/ISubscription';

export const CHANGE_ACTIVE_PANEL = 'CHANGE_ACTIVE_PANEL';
export const SET_NEW_AUTORENEW = 'SET_NEW_AUTORENEW';
export const SET_NEW_BILLING_TYPE = 'SET_NEW_BILLING_TYPE';
export const SET_NEW_NUMBER_OF_LICENSES = 'SET_NEW_NUMBER_OF_LICENSES';
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const PLANS_RECEIVED = 'PLANS_RECEIVED';
export const TOGGLE_RENEW_ADVANCED = 'TOGGLE_RENEW_ADVANCED';
export const CHANGE_RENEW_NUMBER = 'CHANGE_RENEW_NUMBER';
export const SET_TOO_FEW_LICENSES_ERROR = 'SET_TOO_FEW_LICENSES_ERROR';
export const SET_NEGATIVE_CHARGES_ERROR = 'SET_NEGATIVE_CHARGES_ERROR';
export const SET_TOO_MANY_LICENSES_ERROR = 'SET_TOO_MANY_LICENSES_ERROR';
export const SET_INVALID_NUMBER_ERROR = 'SET_INVALID_NUMBER_ERROR';
export const SET_INVALID_RENEW_ERROR = 'SET_INVALID_RENEW_ERROR';
export const SET_LICENSES_BUY_AUTO_RENEW_ERROR = 'SET_LICENSES_BUY_AUTO_RENEW_ERROR';
export const SET_YEARLY_BILLING_AUTO_RENEW_ERROR = 'SET_YEARLY_BILLING_AUTO_RENEW_ERROR';
export const CREDIT_RECEIVED = 'CREDIT_RECEIVED';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_PENDING = 'PAYMENT_PENDING';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const EXPIRING_LICENSES_CHANGED = 'EXPIRING_LICENSES_CHANGED';
export const RESET = 'RESET';
export const NO_CHANGES_ERROR = 'NO_CHANGES_ERROR';
export const UPDATE_CARD_FROM_PLACE_ORDER = 'UPDATE_CARD_FROM_PLACE_ORDER';

const handlePaymentStatus = (dispatch, subscription) => {
    const { status } = subscription.payment;
    if (status === 'COMPLETE') {
        dispatch({ type: PAYMENT_SUCCESS });
    } else if (status === 'PENDING') {
        dispatch({
            type: PAYMENT_PENDING,
            payload: subscription.payment.three_d_secure
        });
    } else if (status === 'FAILED') {
        dispatch({ type: PAYMENT_FAILED });
    }
    dispatch({
        type: UPDATE_DATA,
        payload: { subscription }
    });
};

export default class PlusPlanAdminActions {
    changeActivePanel(activePanel: string): ISingleTypeAction {
        return {
            type: CHANGE_ACTIVE_PANEL,
            payload: {
                activePanel: activePanel
            }
        };
    }

    setNewAutoRenew(newAutoRenew: boolean): ISingleTypeAction {
        return {
            type: SET_NEW_AUTORENEW,
            payload: {
                newAutoRenew: newAutoRenew
            }
        };
    }

    setNewBillingType(newBillingType: string): ISingleTypeAction {
        return {
            type: SET_NEW_BILLING_TYPE,
            payload: {
                newBillingType: newBillingType
            }
        };
    }

    setNewAmountOfLicenses(newAmountOfLicenses: string): ISingleTypeAction {
        return {
            type: SET_NEW_NUMBER_OF_LICENSES,
            payload: {
                newAmountOfLicenses: newAmountOfLicenses
            }
        };
    }

    adminGetCard(): any {
        return async dispatch => {
            const card = await BackendMethods.getCard();
            dispatch({
                type: GET_CARD_SUCCESS,
                payload: card,
            });
        };
    }

    getInvoices(): any {
        return async dispatch => {
            const invoices = await BackendMethods.getInvoices();
            dispatch({
                type: GET_INVOICES_SUCCESS,
                payload: invoices
            });
        };
    }

    getInvoice(id: string): any {
        return async dispatch => {
            const invoice = await BackendMethods.getInvoice(id);
            dispatch({
                type: GET_INVOICE_SUCCESS,
                payload: invoice
            });
        };
    }

    adminGetPlans(): any {
        return async dispatch => {
            const plans = await BackendMethods.getSubscriptionPlans();
            dispatch({
                type: PLANS_RECEIVED,
                payload: plans
            });
        };
    }

    toggleRenewAdvanced(isOpen: boolean): ISingleTypeAction {
        return {
            type: TOGGLE_RENEW_ADVANCED,
            payload: isOpen,
        };
    }

    changeRenewNumber(renewing: string): ISingleTypeAction {
        return {
            type: CHANGE_RENEW_NUMBER,
            payload: renewing,
        };
    }

    setTooFewLicensesError(error: boolean): ISingleTypeAction {
        return {
            type: SET_TOO_FEW_LICENSES_ERROR,
            payload: error
        };
    }

    setTooManyLicensesError(error: boolean): ISingleTypeAction {
        return {
            type: SET_TOO_MANY_LICENSES_ERROR,
            payload: error
        };
    }

    setInvalidNumberError(error: boolean): ISingleTypeAction {
        return {
            type: SET_INVALID_NUMBER_ERROR,
            payload: error
        };
    }

    setInvalidRenewError(error: boolean): ISingleTypeAction {
        return {
            type: SET_INVALID_RENEW_ERROR,
            payload: error
        };
    }

    setLicensesBuyAutoRenewError(error: boolean): ISingleTypeAction {
        return {
            type: SET_LICENSES_BUY_AUTO_RENEW_ERROR,
            payload: error
        };
    }

    setYearlyBillingAutoRenewError(error: boolean): ISingleTypeAction {
        return {
            type: SET_YEARLY_BILLING_AUTO_RENEW_ERROR,
            payload: error
        };
    }

    goToPlaceOrder(newTotalCharge: number) {
        return async (dispatch, getState) => {
            const { data } = getState();
            if (data.get('subscription').status === 'ACTIVE') {
                const {credit: creditCents, token} = await BackendMethods.getCredit();
                const credit = creditCents / 100;
                if (credit > newTotalCharge) {
                    dispatch({
                        type: SET_NEGATIVE_CHARGES_ERROR,
                        payload: true
                    });
                    return;
                }
                dispatch({
                    type: CREDIT_RECEIVED,
                    payload: { credit, token }
                });
            }
            dispatch({
                type: CHANGE_ACTIVE_PANEL,
                payload: {
                    activePanel: 'placeOrder'
                }
            });
        };
    }

    updateSubscription(
        billingType: string,
        quantity: number,
        renew: boolean,
        proration: string
    ) {
        return async (dispatch, getState) => {
            const { data } = getState();
            try {
                let subscription;
                if (data.get('subscription').status === 'ACTIVE') {
                    subscription = await BackendMethods.updateSubscription(billingType, quantity, renew, proration);
                } else {
                    subscription = await BackendMethods.createSubscription(billingType, quantity, renew);
                }
                handlePaymentStatus(dispatch, subscription);
            } catch {
                dispatch({ type: PAYMENT_FAILED });
            }
        };
    }

    setNumberOfExpiringLicenses(quantity: number) {
        return async dispatch => {
            await BackendMethods.setNumberOfExpiringLicenses(quantity);
            dispatch({ type: EXPIRING_LICENSES_CHANGED });
        };
    }

    resetAdminPanel(subscription: ISubscription): ISingleTypeAction {
        return { type: RESET, payload: subscription };
    }

    setNoChangesError(error: boolean): ISingleTypeAction {
        return {
            type: NO_CHANGES_ERROR,
            payload: error
        };
    }

    adminThreeDSecureComplete(success: boolean) {
        return async dispatch => {
            const subscription = await BackendMethods.getSubscription();
            if (success) {
                dispatch({ type: PAYMENT_SUCCESS });
            } else {
                dispatch({ type: PAYMENT_FAILED });
            }
            dispatch({
                type: UPDATE_DATA,
                payload: { subscription }
            });
        };
    }

    retryPayment() {
        return async dispatch => {
            const result = await BackendMethods.retryPayment();
            if (!result.error) {
                handlePaymentStatus(dispatch, result);
            }
        };
    }

    updateCardFromPlaceOrder(): ISingleTypeAction {
        return { type: UPDATE_CARD_FROM_PLACE_ORDER };
    }

    cardSaved(card: ICardDetails): ISingleTypeAction {
        return {
            type: GET_CARD_SUCCESS,
            payload: card,
        };
    }
}
