import React from 'react';
import cs from 'classnames';
import IActions from 'actions/IActions';
import { WithTranslation, withTranslation } from 'react-i18next';
import TimeInputField from 'components/_ReusableComponents/TimeInpustField';
import MyWorkWeekHoursView from './MyWorkWeekHoursView/MyWorkWeekHoursView';
import moment, { Moment } from 'moment';
import { WORK_DAYS } from 'constants/appConsts';
import BackendMethods from 'api/BackendMethods';
import { LoadingButton } from '@material-ui/lab';
import { IGlobalContext, withGlobalContext } from 'GlobalContext';

const Styles = require('./MyWorkWeekHours.css');

interface IProps extends WithTranslation {
    preferences: any;
    actions: IActions;
    data: any;
    selectedWorkDays: number[];
    context: IGlobalContext;
}

interface IState {
    timeFrom?: Moment;
    timeTo?: Moment;
    isLoading: boolean;
}

// @TODO this component is not test so you need to test also on view after change the code.

export default withTranslation()(
    withGlobalContext(
        class MyWorkWeekHours extends React.PureComponent<IProps, IState> {
            constructor(props) {
                super(props);

                this.state = {
                    timeFrom: null,
                    timeTo: null,
                    isLoading: false,
                };
            }

            addWorkTImeHour = async () => {
                const { t } = this.props;
                const workDaysArray = [...this.props.selectedWorkDays];
                const workDays = workDaysArray.sort((a, b) => a - b);
                const days: string[] = workDays.map((workDayIndex) =>
                    moment(WORK_DAYS[workDayIndex], 'dd', 'en')
                        .format('dddd')
                        .toUpperCase()
                );

                if (
                    this.state.timeFrom === null ||
                    this.state.timeTo === null ||
                    !days ||
                    days.length === 0
                ) {
                    this.props.actions.showErrorMessage(
                        t('settings.selectDays')
                    );
                    return;
                }

                if (
                    this.state.timeFrom.format('HH:mm') === 'Invalid date' ||
                    this.state.timeTo.format('HH:mm') === 'Invalid date'
                ) {
                    this.props.actions.showErrorMessage(
                        t('settings.workWeekError')
                    );
                    return;
                }
                if ((this.state.timeFrom.isBefore(this.state.timeTo)) || (this.state.timeTo.format('HH:mm') === '00:00')) {
                    this.setState({ isLoading: true });
                    try {
                        const response = await BackendMethods.saveWorkWeekHours(
                            {
                                days,
                                begin: this.state.timeFrom.format('HH:mm'),
                                end: this.state.timeTo.format('HH:mm'),
                            }
                        );
                        this.setState({ isLoading: false });
                        this.props.actions.saveWorkWeekHours(response);
                        this.props.actions.showSuccessMessage(
                            t('settings.workWeekSaved')
                        );
                    } catch (error) {
                        this.setState({ isLoading: false });
                    }
                } else {
                    this.setState({ isLoading: false });
                    this.props.actions.showErrorMessage(
                        t('settings.workWeekHoursError')
                    );
                }
            };

            closeWorkHours = async (id: number) => {
                try {
                    await BackendMethods.deleteWeekHours(id);
                    this.props.actions.deleteWorkWeekHours(id);
                } catch (error) {}
            };

            render() {
                const { t } = this.props;

                return (
                    <div className={cs(Styles.MyWorkWeekHours)}>
                        <div className={Styles.timeInputs}>
                            <TimeInputField
                                timeFormat={this.props.context.profile.timeFormat}
                                value={this.state.timeFrom}
                                onChange={(value: Moment) => this.setState({ timeFrom:value })}
                            />
                            <span className={Styles.space}>-</span>
                            <TimeInputField
                                timeFormat={this.props.context.profile.timeFormat}
                                value={this.state.timeTo}
                                onChange={(value: Moment) => this.setState({ timeTo:value })}
                            />
                        </div>
                        <div className={Styles.timeAdd}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick={this.addWorkTImeHour}
                                loading={this.state.isLoading}
                            >
                                {t('settings.add')}
                            </LoadingButton>
                        </div>
                        <div className={cs(Styles.EachDayHoursInputs)}>
                            <MyWorkWeekHoursView
                                workHours={this.props.preferences.get('workDays')}
                                timeFormat={this.props.context.profile.timeFormat}
                                closeWorkHours={this.closeWorkHours}
                            />
                        </div>
                    </div>
                );
            }
        }
    )
);
