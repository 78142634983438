import React, { useContext, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import CalculationInProgress from '../../components/MeetingPageComponents/CalculationInProgress/CalculationInProgress';
import MeetingAnswers from '../../components/MeetingPageComponents/MeetingAnswers/MeetingAnswers';
import MeetingSet from '../../components/MeetingPageComponents/MeetingSet/MeetingSet';
import MeetingCancel from '../../components/MeetingPageComponents/MeetingCancel/MeetingCancel';
import IActions from 'actions/IActions';
import BackendMethods from 'api/BackendMethods';
import IPropositionAnswer from '../../interfaces/IPropositionAnswer';
import { getAuthToken, isAdHoc, parseJwt } from 'scripts/helpers';
import IMeeting from '../../interfaces/IMeeting';
import { match, Redirect } from 'react-router';
import { useIsDesktop } from '../../utils/responsive';
import getWorkWeekEvents from '../../utils/getWorkWeekEvents';
import RejectMeetingModal from '../../components/_ReusableComponents/Modals/RejectMeetingModal/RejectMeetingModal';
import IProposition from '../../interfaces/IProposition';
import { Moment } from 'moment';
import SetThisDateModal from '../../components/_ReusableComponents/Modals/SetThisDateModal/SetThisDateModal';
import RegisterModal, { DialogState } from '../../components/_ReusableComponents/Modals/RegisterModal/RegisterModal';
import UndoSnackbar from '../../components/_ReusableComponents/UndoSnackbar/UndoSnackbar';
import AdhocTimezoneModal from '../../components/_ReusableComponents/Modals/AdhocTimezoneModal/AdhocTimezoneModal';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../GlobalContext';

interface Props {
    data: any;
    app: any;
    meeting: any;
    actions: IActions;
    location: any;
    history: any;
    match: match<{ id: string }>;
}

interface IViewProposition extends IProposition {
    removed?: boolean;
}

function MeetingPage(props: Props) {
    const { i18n, t } = useTranslation();
    const [propositionAnswers, setPropositionAnswers] = useState<IPropositionAnswer[] | null>([]);
    const [activePropositionId, setActivePropositionId] = useState(null);
    const [dialogState, setDialogState] = useState<DialogState>('closed');
    const [undoOpen, setUndoOpen] = useState(false);
    const undoTimeoutId = useRef<NodeJS.Timeout>();
    const setCandidateState = (x: IPropositionAnswer) => {
        setPropositionAnswers(
            R.uniqBy(R.prop('id'), [x, ...propositionAnswers])
        );
        if (x.accepted || x.rejected) {
            if (x.rejected) {
                setPropositions(propositions.map((y) => (
                    y.id === x.id ? {
                        ...y,
                        removed: true,
                    } : y
                )));
            }
            setUndoOpen(true);
            clearTimeout(undoTimeoutId.current);
            undoTimeoutId.current = setTimeout(
                () => setUndoOpen(false),
                5000,
            );
            if (isAdHoc()) {
                if (dialogState !== 'already-shown') {
                    setDialogState('open');
                }
            }
        }
    };

    const meetings = props.data.get('meetings');
    const id = Number(props.match.params.id);
    const meeting: IMeeting = meetings.find(m => m.id === id);
    const { profile } = useContext(GlobalContext);
    const adHoc = isAdHoc();
    const email = adHoc ? parseJwt(getAuthToken()).email : profile.email;
    const { timezone, adhoc: participantAdHoc } = meeting
        ? meeting.participants.find(R.whereEq({ email }))
        : { timezone: '', adhoc: false };
    const [adhocTimezonePopup, setAdhocTimezonePopup] = useState<DialogState>('closed');
    useEffect(() => {
        if (typeof meeting !== 'object' || !adHoc) {
            return;
        }
        if (adhocTimezonePopup === 'closed'  && timezone) {
            if (timezone !== moment.tz.guess()) {
                setAdhocTimezonePopup('open');
            } else {
                setAdhocTimezonePopup('already-shown');
                if (dialogState !== 'already-shown') {
                    setDialogState('open');
                }
            }
        } else if (dialogState !== 'already-shown' && adhocTimezonePopup !== 'open') {
            setDialogState('open');
        }
    }, [adHoc, timezone, adhocTimezonePopup, meeting]);
    const [allAnswers, setAllAnswers] = useState<IProposition[]>(null);
    const [setModalDate, setSetModalDate] = useState<string>(null);
    const [startDate, setStartDate] = useState<Moment>(null);
    const [propositions, setPropositions] = useState<IViewProposition[] | null>(null);
    useEffect(() => {
        (async () => {
            const { setCalendarEvents, setMeeting } = props.actions;
            setMeeting(meeting, profile);
            if (!adHoc) {
                const currentMeeting = meetings.find(R.whereEq({ id }));
                if (currentMeeting) {
                    const { after, before } = currentMeeting;
                    const start = moment.tz(after, timezone).add(1, 'ms')
                        .startOf('day').toISOString();
                    const end = moment.tz(before, timezone).subtract(1, 'ms')
                        .endOf('day').toISOString();
                    setCalendarEvents([
                        {
                            events: getWorkWeekEvents(timezone, props.data.get('workWeek'), start, end),
                        },
                        ...(await BackendMethods.getCalendarEvents(after, before, timezone)),
                    ], timezone, profile.timeFormat, i18n.language);
                }
            } else {
                setCalendarEvents({ items: [] }, timezone, profile.timeFormat, i18n.language);
            }
        })();
    }, []);
    const [prevMeeting, setPrevMeeting] = useState<IMeeting>(null);
    const isDesktop = useIsDesktop();
    const declineOpen = R.last(props.location.pathname.split('/')) === 'decline';
    const initPropositions = async () => {
        const nowPropositions =await BackendMethods.getPropositions(meeting.id);
        setPropositions(nowPropositions);
        if (meeting.state === 'WAITING_FOR_ANSWERS' && nowPropositions.length === 0 && meeting.isOrganizer) {
            onSetDate();
        }
    };
    useEffect(() => {
        if (!meeting) {
            props.actions.showErrorMessage(t('home.notFound'));
        } else {
            initPropositions();
        }
    }, [meeting, allAnswers]);
    if (!meeting) {
        return <Redirect to="/main" />;
    }
    if (adHoc && !participantAdHoc) {
        sessionStorage.removeItem('tanbuuAdHoc');
        return <Redirect to="/login" />;
    }
    if (isDesktop) {
        return <Redirect to={`/main/${id}${declineOpen ? '/decline' : ''}`} />;
    }
    const onSetDate = async () => {
        let i = 0;
        const answers = (await BackendMethods.getMeetingAnswers(id)).map(x => ({ ...x, id: i++ }));
        setAllAnswers(answers);
        setPropositionAnswers(answers.map(proposition => ({
            id: proposition.id,
            accepted: false,
            rejected: false,
            earliest: proposition.earliest,
            latest: proposition.earliest,
        })));
        if(!activePropositionId && answers[0]) {
            setActivePropositionId(answers[0].id);
        }
    };
    const setDate = async () => {
        props.actions.updateMeeting(
            await BackendMethods.setMeetingDate(meeting.id, setModalDate),
            profile.email,
        );
        setAllAnswers(null);
        setStartDate(null);
        setSetModalDate(null);
        setPropositionAnswers(null);
        setActivePropositionId(null);
    };
    const goBack = () => {
        setAllAnswers(null);
        setStartDate(null);
        setPropositions([]);
        setPropositionAnswers([]);
        setPrevMeeting(null);
    };
    let children;
    if (meeting.state === 'CANCELED') {
        children = (
            <MeetingCancel
                {...props}
                yesNoButtonsStateArray={propositionAnswers}
                currentMeeting={meeting}
                email={email}
            />
        );
    } else if (meeting.state === 'SCHEDULED') {
        children = (
            <MeetingSet
                {...props}
                yesNoButtonsStateArray={propositionAnswers}
                currentMeeting={meeting}
                timezone={timezone}
            />
        );
    } else if (meeting.state === 'CALCULATING' || meeting.state === 'RECALCULATING') {
        children = (
            <CalculationInProgress
                {...props}
                currentMeeting={meeting}
                email={email}
            />
        );
    } else if (meeting.state === 'WAITING_FOR_ANSWERS') {
        children = (
            <MeetingAnswers
                setPropositionAnswers={setPropositionAnswers}
                {...props}
                propositionAnswers={propositionAnswers}
                currentMeeting={meeting}
                setAnswer={setCandidateState}
                setActivePropositionId={setActivePropositionId}
                activePropositionId={activePropositionId}
                email={email}
                timezone={timezone}
                allAnswers={allAnswers}
                startDate={startDate}
                setStartDate={setStartDate}
                setSetModalDate={setSetModalDate}
                onSetDate={onSetDate}
                goBack={goBack}
                propositions={propositions}
            />
        );
    }
    return (
        <div>
            <div id="MeetingPage">{children}</div>
            <RejectMeetingModal
                meeting={meeting}
                open={declineOpen}
                onClose={() => props.history.push(`/meeting/${meeting ? meeting.id : ''}`)}
                actions={props.actions}
                history={props.history}
            />
            <SetThisDateModal
                open={setModalDate !== null}
                onClose={() => setSetModalDate(null)}
                onConfirm={setDate}
                meeting={meeting}
                startDate={setModalDate}
                timezone={timezone}
                timeFormat={profile.timeFormat}
            />
            <RegisterModal
                dialogState={dialogState}
                setDialogState={setDialogState}
            />
            <UndoSnackbar
                open={undoOpen}
                onClose={() => setUndoOpen(false)}
                meetingId={meeting.id}
                updateMeeting={(data) => props.actions.updateMeeting(data, profile.email)}
            />
            <AdhocTimezoneModal
                open={adhocTimezonePopup === 'open'}
                meeting={meeting}
                email={email}
                onClose={() => setAdhocTimezonePopup('already-shown')}
            />
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(MeetingPage);
