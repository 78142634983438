import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@material-ui/core';
import Button from './Button/Button';
import { redirectToBaseIfNeeded } from '../../utils/subdomain';
import { useHistory } from 'react-router-dom';
const styles = require('./AddEmailDialog.css');

interface Props {
    open: boolean;
    onClose: () => void;
    context?: 'MEETINGS';
}

export default function AddEmailDialog({ open, onClose, context }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <Dialog
            open={open}
            PaperProps={{ className: styles.paper }}
            onClose={onClose}
        >
            <div className={styles.dialogText}>
                {context === 'MEETINGS' ? t('small.meetingsAddEmail') : t('small.addEmailInfo')}
            </div>
            <div className={styles.dialogButtons}>
                <Button
                    className={styles.dialogCancelButton}
                    onClick={onClose}
                >
                    {t('common.cancel')}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        redirectToBaseIfNeeded(history, '/register?merge=1');
                    }}
                >
                    {t('small.continue')}
                </Button>
            </div>
        </Dialog>
    );
}
