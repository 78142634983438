import React, { createContext } from 'react';
import IProfile from './interfaces/IProfile';
import GlobalContext from './GlobalContext';

export interface IGlobalContextData {
    profile: IProfile | null;
}

export interface IGlobalContext extends IGlobalContextData {
    updateContext: (updateFn: (data: IGlobalContextData) => IGlobalContextData) => void;
}

export default createContext<IGlobalContext>({
    profile: null,
    updateContext: () => {},
});

export function withGlobalContext(Component: React.ComponentClass) {
    return function contextWrapper(props) {
        return (
            <GlobalContext.Consumer>
                {(context) => <Component {...props} context={context} />}
            </GlobalContext.Consumer>
        );
    };
}
