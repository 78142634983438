import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment-timezone';
import SocialIcon from '../../SocialIcon/SocialIcon';
import AppUtils from '../../../../utils/AppUtils/AppUtils';
import ChromeExtensionInfo from '../../../ChromeExtensionInfo/ChromeExtensionInfo';
import { useTranslation } from 'react-i18next';
import { useIsDesktop } from '../../../../utils/responsive';
const styles = require('./LoginFooter.css');

const linkedInLink = (() => {
    const baseUrl = 'https://www.linkedin.com/shareArticle?mini=true';
    const url = 'http://tanbuu.com';
    const title = 'Tanbuu';
    const summary =
        'Simplify your life, orchestrating one meeting at a time with Tanbuu!';
    const source = 'Tanbuu';
   return `${baseUrl}&url=${url}&title=${title}&summary=${summary}&source=${source}`;
})();

const twitterLink = (() => {
    const baseUrl = 'https://twitter.com/intent/tweet';
    const text =
        'Simplify your life, orchestrating one meeting at a time with Tanbuu!';
    const hashtags =
        'productivity,meetings,calendar,time_saved,management,office,schedule,assistant,ai,smart,scheduler,meet,tanbuu';
    return `${baseUrl}?text=${text}&hashtags=${hashtags}`;
})();

export default function LoginFooter() {
    const { t } = useTranslation();
    const isDesktop = useIsDesktop();
    return (
        <footer>
            <div className={styles.LoginFooter}>
                <nav>
                    <div className={styles.Navigation}>
                        <Link to="/" className={styles.Navigation__Link}>
                            {t('landing.home')}
                        </Link>
                        <Link
                            to={isDesktop ? '/#features' : '/features'}
                            className={styles.Navigation__Link}
                        >
                            {t('landing.features')}
                        </Link>
                        <Link
                            to={isDesktop ? '/#pricing' : '/pricing'}
                            className={styles.Navigation__Link}
                        >
                            {t('landing.pricing')}
                        </Link>
                        <Link
                            to="/about"
                            className={styles.Navigation__Link}
                        >
                            {t('landing.aboutUs')}
                        </Link>
                        <Link
                            to="/contact"
                            className={styles.Navigation__Link}
                        >
                            {t('landing.contact')}
                        </Link>
                        <Link
                            to="/faq"
                            className={styles.Navigation__Link}
                        >
                            {t('landing.faq')}
                        </Link>
                    </div>
                </nav>
                <ChromeExtensionInfo />
                <ul
                    className={classNames(
                        styles.Navigation__Socials,
                        AppUtils.hasExtensionInstalled() &&
                        styles.ConditionalSocials
                    )}
                >
                    <li className={styles.Share}>Share</li>
                    <div className={styles.icons}>
                        <SocialIcon
                            classes={{
                                list: styles.IconContainer,
                                image: styles.SocialIcon
                            }}
                            iconSrc="/assets/linkedin_icon_footer.svg"
                            link={linkedInLink}
                            iconAlt="Share on LinkedIn"
                            onClick={() => {
                                window.open(
                                    linkedInLink,
                                    'Share on LinkedIn',
                                    'width=1024, height=700'
                                );
                            }}
                        />
                        <SocialIcon
                            classes={{
                                list: styles.IconContainer,
                                image: styles.SocialIcon
                            }}
                            iconSrc="/assets/twitter_icon_footer.svg"
                            link={twitterLink}
                            iconAlt="Share on Twitter"
                            onClick={() => {
                                window.open(
                                    twitterLink,
                                    'Share on Twitter',
                                    'width=1024, height=700'
                                );
                            }}
                        />
                    </div>
                </ul>
            </div>
            <div className={styles.Legals}>
                <div className={styles.Legals__Copyright}>
                    Copyright © {moment().format('Y')} Toolla Inc.
                    <br />
                    {t('common.allRights')}
                </div>
                <Link to="/policy" className={styles.Legals__LegalLink}>
                    {t('common.privacyPolicy')}
                </Link>
                <Link to="/terms" className={styles.Legals__LegalLink}>
                    {t('common.termsAndConditions')}
                </Link>
            </div>
        </footer>
    );
}
