import * as React from 'react';
import { useTranslation } from 'react-i18next';
const Styles = require('./BillingSummary.css');

interface IBillingSummaryProps {
    amount: string;
}

export default function BillingSummary(props: IBillingSummaryProps) {
    const { t } = useTranslation();
    return (
        <div className={Styles.BillingSummary}>
            <div className={Styles.Total}>
                <span>{t('plan.total')}:</span>
                <div className={Styles.Total__Price} id="TotalPrice">${props.amount}</div>
            </div>
        </div>
    );
}
