import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS } from 'immutable';
import * as meetingActions from '../../actions/MeetingActions';
import ICalendarEvent from 'interfaces/calendarEvents/ICalendarEvent';
import IMeeting from 'interfaces/IMeeting';
import moment from 'moment-timezone';
import * as R from 'ramda';

const calendarEventsHourWidth = 120;
const minimumCalendarEventHourWidth = 5;

const INITIAL_STATE = fromJS({
    currentSetDateButtonLoadingIndex: -1,
    calendarEvents: null,
    isLoadingCalendarEvents: true,
    currentWatchingSchedule: '',
    isSetThisDateModalVisible: false,
    scheduleForSetThisDateModal: null,
    isSetThisDateModalVisibleLoading: false,
    isMeetingNotesPopoverVisible: false,
    candidateScheduleIndex: 0
})
    .set('currentMeeting', {
        participants: []
    });

const UNAVAILABLE_TEXT: string = 'Unavailable (from settings)';

const getStateForCalendarEventsList = (state, timezone, timeFormat, language: string) => {
    const calendarEvents = state.get('calendarEvents') || {};
    const calendarEventsList: ICalendarEvent[] = R.chain(R.prop('events'), calendarEvents);
    const currentMeeting: IMeeting = state.get('currentMeeting') || {};

    const startDate = moment.tz(currentMeeting.after, timezone).startOf('hour');
    const startDateInMillis: number = startDate.valueOf();
    const endDateInMillis: number = moment.tz(currentMeeting.before, timezone)
        .add(1, 'hour')
        .startOf('hour')
        .valueOf();

    const hoursArray: any[] = [];

    const rangeInHours =
        (endDateInMillis - startDateInMillis) / (60 * 60 * 1000);

    for (let i = 0; i <= rangeInHours; i++) {
        let hourAsString = moment(startDate).tz(timezone).format(timeFormat === '12h' ? 'ha' : 'H');
        if (hourAsString === '12am' || hourAsString === '0') {
            hourAsString = moment.tz(startDate, timezone)
                .format(language === 'pl' ? 'DD.MM.YY' : 'MM/DD/YY');
        }
        hoursArray.push(hourAsString);
        startDate.add(1, 'hour');
    }

    let eventsListToDisplay: ICalendarEvent[];

    eventsListToDisplay = calendarEventsList.map((e: ICalendarEvent) => {
        let eventWidth = calculateElementWidth(
            state,
            getDifferentBetweenDatesInMillis(e)
        );
        e.boxWidthNumber = eventWidth;
        e.boxWidth = `${eventWidth}px`;
        e.leftAsNumber = getLeftPositionFromDate(
            state,
            startDateInMillis,
            e.start
        );
        e.left = `${e.leftAsNumber}px`;

        if (!e.subject) {
            e.subject = UNAVAILABLE_TEXT;
        }

        return e;
    });

    eventsListToDisplay = R.sortBy(R.prop('leftAsNumber'), eventsListToDisplay);

    const mergeBoxes = () => {
        for (let i = 1; i < eventsListToDisplay.length; i++) {
            const previousElementLeftPosition: number =
                eventsListToDisplay[i - 1].leftAsNumber;
            const previousElementWidth =
                eventsListToDisplay[i - 1].boxWidthNumber;
            const currentElementLeftPosition =
                eventsListToDisplay[i].leftAsNumber;
            const currentElementWidth = eventsListToDisplay[i].boxWidthNumber;

            if (
                previousElementLeftPosition + previousElementWidth >
                currentElementLeftPosition
            ) {
                if (
                    previousElementLeftPosition + previousElementWidth <
                    currentElementLeftPosition + currentElementWidth
                ) {
                    const previousElementNewWidth =
                        previousElementWidth +
                        (currentElementLeftPosition +
                            currentElementWidth -
                            (previousElementLeftPosition +
                                previousElementWidth));
                    eventsListToDisplay[
                        i - 1
                    ].boxWidthNumber = previousElementNewWidth;
                    eventsListToDisplay[
                        i - 1
                    ].boxWidth = `${previousElementNewWidth}px`;
                }

                const previousName: string =
                    eventsListToDisplay[i - 1].subject || UNAVAILABLE_TEXT;
                const currentName: string =
                    eventsListToDisplay[i].subject || UNAVAILABLE_TEXT;

                eventsListToDisplay[
                    i - 1
                ].subject = `${previousName}, ${currentName}`;
                eventsListToDisplay.splice(i, 1);

                mergeBoxes();
                break;
            }
        }
    };

    mergeBoxes();

    return state
        .set('startDateInMillis', moment(state.get('currentMeeting').after).valueOf())
        .set('hoursArray', hoursArray)
        .set('eventsListToDisplay', eventsListToDisplay);
};

const getDifferentBetweenDatesInMillis = (e: ICalendarEvent): number => {
    if (!e) {
        return 0;
    }

    return new Date(e.end).getTime() - new Date(e.start).getTime();
};

const getLeftPositionFromDate = (
    state,
    startDateInMillis: number,
    startDateInISO: string
): number => {
    const hoursFromStart: number =
        (new Date(startDateInISO).getTime() - startDateInMillis) /
        (1000 * 60 * 60);
    return hoursFromStart * calendarEventsHourWidth;
};

const calculateElementWidth = (
    state,
    differentBetweenDateInMillis: number
): number => {
    let eventWidth =
        (differentBetweenDateInMillis / (1000 * 60 * 60)) *
        calendarEventsHourWidth;

    if (eventWidth < minimumCalendarEventHourWidth) {
        eventWidth = minimumCalendarEventHourWidth;
    }

    return eventWidth;
};

export default function meetingReducer(
    state = INITIAL_STATE,
    action: any = { type: '', payload: {} }
) {
    switch (action.type) {
        case LOCATION_CHANGE: {
            state = INITIAL_STATE;
            return state;
        }

        case meetingActions.SET_LOADING_SET_THIS_DATE_BUTTON: {
            return state.set(
                'currentSetDateButtonLoadingIndex',
                action.payload
            );
        }

        case meetingActions.SET_CALENDAR_EVENTS: {
            const { calendarEvents, timezone, timeFormat, language } = action.payload;
            return getStateForCalendarEventsList(
                state
                    .set('isLoadingCalendarEvents', false)
                    .set('calendarEvents', calendarEvents),
                timezone,
                timeFormat,
                language,
            );
        }

        case meetingActions.SET_MEETING: {
            return state.set('currentMeeting', action.payload.meeting);
        }

        default: {
            return state;
        }
    }
}
