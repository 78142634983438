// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".menuIcon__1Nsar {\n    width: 16px;\n    height: 8px;\n    margin-left: 10px;\n}\n\n.menuButton__37vOu {\n    padding: 0;\n    padding-bottom: 18px;\n}\n\n.menuItem__2NBXA {\n    background-color: white !important;\n    padding: 8px 12px;\n    line-height: 20px;\n    min-height: 0 !important;\n}\n\n.menuItem__2NBXA:hover {\n    background-color: #eff3f4 !important;\n}\n\n.selectedItem__14jUt {\n    background-color: #eff3f4 !important;\n    padding: 8px 12px;\n    line-height: 20px;\n    min-height: 0 !important;\n}\n\n.midLine__1oHSJ {\n    margin: 0;\n}\n", "",{"version":3,"sources":["SortMenu.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,oBAAoB;AACxB;;AAEA;IACI,kCAAkC;IAClC,iBAAiB;IACjB,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;IACpC,iBAAiB;IACjB,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,SAAS;AACb","file":"SortMenu.css","sourcesContent":[".menuIcon {\n    width: 16px;\n    height: 8px;\n    margin-left: 10px;\n}\n\n.menuButton {\n    padding: 0;\n    padding-bottom: 18px;\n}\n\n.menuItem {\n    background-color: white !important;\n    padding: 8px 12px;\n    line-height: 20px;\n    min-height: 0 !important;\n}\n\n.menuItem:hover {\n    background-color: #eff3f4 !important;\n}\n\n.selectedItem {\n    background-color: #eff3f4 !important;\n    padding: 8px 12px;\n    line-height: 20px;\n    min-height: 0 !important;\n}\n\n.midLine {\n    margin: 0;\n}\n"]}]);
// Exports
exports.locals = {
	"menuIcon": "menuIcon__1Nsar",
	"menuButton": "menuButton__37vOu",
	"menuItem": "menuItem__2NBXA",
	"selectedItem": "selectedItem__14jUt",
	"midLine": "midLine__1oHSJ"
};
module.exports = exports;
