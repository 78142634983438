// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".paper__1oGmE {\n    margin: 16px;\n    padding: 24px;\n    max-width: 400px;\n}\n", "",{"version":3,"sources":["SupportModal.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;AACpB","file":"SupportModal.css","sourcesContent":[".paper {\n    margin: 16px;\n    padding: 24px;\n    max-width: 400px;\n}\n"]}]);
// Exports
exports.locals = {
	"paper": "paper__1oGmE"
};
module.exports = exports;
