import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '../../components/_ReusableComponents/Button/Button';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { Dialog, Tooltip } from '@material-ui/core';
import Animations from '../../components/LandingPage/Animations/Animations';
import useEventListener from '@use-it/event-listener';
import classNames from 'classnames';
import { WANTS_TO_BUY_TANBUU } from '../../constants/appConsts';
import scrollIntoView from 'scroll-into-view';
import { isLoggedIn } from '../../scripts/helpers';
import { getSubdomain, redirectToSubdomain } from '../../utils/subdomain';
import BackendMethods from '../../api/BackendMethods';
import { useIsDesktop } from '../../utils/responsive';
import Plans from './Plans';
import Features from './Features';
import GlobalContext from '../../GlobalContext';
const config = require('CONFIG');
const styles = require('./LandingPage.css');

export default function LandingPage() {
    const isDesktop = useIsDesktop();
    const { t } = useTranslation();
    const location = useLocation();
    useEffect(() => {
        const el = document.getElementById(location.hash.substring(1) || 'top');
        if (el) {
            scrollIntoView(el, { align: { top: 0, topOffset: 120 } });
        }
    }, [location]);
    const [videoOpen, setVideoOpen] = useState(false);
    const customersRef = useRef<HTMLDivElement | null>(null);
    const [scrollCustomers, setScrollCustomers] = useState(false);
    const checkScroll = () => {
        setTimeout(() => {
            setScrollCustomers(customersRef.current?.offsetWidth > window.innerWidth);
        }, 1);
    };
    useEffect(checkScroll, []);
    useEventListener('resize', checkScroll);
    const { profile } = useContext(GlobalContext);
    if (isLoggedIn()) {
        const { appointments, phone } = profile;
        if (appointments.companyId) {
            if (getSubdomain() === null && window.location.host === config.baseDomain) {
                if (appointments.companySubdomain) {
                    redirectToSubdomain(appointments.companySubdomain, '/sb/dashboard');
                }
                return null;
            }
            return <Redirect to="/sb/dashboard" />;
        }
        return <Redirect to={phone ? '/sb-customer/my-appointments' : '/main'} />;
    }
    if (getSubdomain() !== null) {
        return <Redirect to="/sb-customer/find-service" />;
    }
    if (!isDesktop) {
        return <Redirect to="/login" />;
    }
    const customers = (
        <>
            <img src="/assets/innobaltica.svg" alt="" className={styles.innobaltica} />
            <img src="/assets/sprinklr.svg" alt="" className={styles.sprinklr} />
            <img src="/assets/avnet.svg" alt="" className={styles.avnet} />
        </>
    );
    return (
        <div>
            <div className={styles.top} id="top">
                <div className={styles.topMain}>
                    <div className={styles.headline}>
                        <Trans
                            i18nKey="landing.headline"
                            components={{ br: <br /> }}
                        />
                    </div>
                    <Button
                        variant="text"
                        className={styles.bigSignUp}
                        component={Link}
                        to="/register"
                    >
                        {t('landing.signUp')}
                    </Button>
                    <div className={styles.noCredit}>
                        {t('landing.noCredit')}
                    </div>
                </div>
                <div className={styles.video}>
                    <div className={styles.watchHow}>
                        {t('landing.watchHow')}
                    </div>
                    <Button
                        variant="contained"
                        className={styles.playButton}
                        onClick={() => setVideoOpen(true)}
                    >
                        <div className={styles.playIcon} />
                    </Button>
                    <Dialog
                        open={videoOpen}
                        onClose={() => setVideoOpen(false)}
                        classes={{ paper: styles.dialogPaper }}
                    >
                        <iframe
                            width="854"
                            height="480"
                            src="https://www.youtube.com/embed/0IJMbYfE4xw"
                            allowFullScreen={true}
                            frameBorder={0}
                        />
                    </Dialog>
                </div>
            </div>
            <div className={styles.animations}>
                <div className={styles.animationHeaders}>
                    <div className={styles.typicalDay}>
                        {t('landing.typicalDay')}
                    </div>
                    <div className={styles.solutionTanbuu}>
                        {t('landing.solutionTanbuu')}
                    </div>
                </div>
                <Animations />
            </div>
            <div className={styles.sectionHeader} id="features">
                {t('landing.features')}
            </div>
            <Features />
            <div className={styles.sectionHeader} id="our-customers">
                {t('landing.ourCustomers')}
            </div>
            <div className={styles.customersWrapper}>
                <div
                    className={classNames(styles.customers, {
                        [styles.customersAnimated]: scrollCustomers,
                    })}
                    ref={customersRef}
                >
                    {customers}
                </div>
                {scrollCustomers && (
                    <div className={classNames(styles.customers, styles.secondCustomers)}>
                        {customers}
                    </div>
                )}
            </div>
            <div className={styles.sectionHeader} id="pricing">
                {t('landing.pricing')}
            </div>
            <Plans />
            <table className={styles.featureTable}>
                <thead>
                    <tr className={styles.headerRow}>
                        <th>
                            {t('landing.features')}
                        </th>
                        <th className={styles.tablePlan}>
                            {t('landing.basic')}
                        </th>
                        <th className={styles.tablePlan}>
                            {t('landing.plus')}
                        </th>
                        <th className={styles.tablePlan}>
                            {t('landing.enterprise')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('landing.invitations')}
                            <Tooltip
                                title={t('landing.invitationsTooltip')}
                                classes={{ tooltip: styles.tooltip }}
                            >
                                <span className={styles.info}>i</span>
                            </Tooltip>
                        </td>
                        <td>
                            {`20/${t('landing.mo')}`}
                        </td>
                        <td>
                            {t('landing.unlimited')}
                        </td>
                        <td>
                            {t('landing.unlimited')}
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('landing.connectCalendars')}
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('landing.connectZoom')}
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('landing.emailSupport')}
                        </td>
                        <td>
                            {`1 ${t('landing.day')}`}
                        </td>
                        <td>
                            {`4${t('landing.h')}`}
                        </td>
                        <td>
                            {`1${t('landing.h')}`}
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('landing.outlookAddIn')}
                        </td>
                        <td>
                            <img src="/assets/dash.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('landing.customNotifications')}
                        </td>
                        <td>
                            <img src="/assets/dash.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('landing.successManager')}
                        </td>
                        <td>
                            <img src="/assets/dash.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/dash.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('landing.tanbuuAPI')}
                        </td>
                        <td>
                            <img src="/assets/dash.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/dash.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('landing.serviceLevel')}
                        </td>
                        <td>
                            <img src="/assets/dash.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/dash.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr className={styles.footerRow}>
                        <td />
                        <td>
                            <Button
                                variant="text"
                                className={classNames(styles.planButton, styles.tableButton, styles.planSignUp)}
                                component={Link}
                                to="/register"
                            >
                                {t('landing.signUp')}
                            </Button>
                        </td>
                        <td>
                            <Button
                                variant="text"
                                className={classNames(styles.planButton, styles.tableButton, styles.planBuyNow)}
                                onClick={() => {
                                    localStorage.setItem(WANTS_TO_BUY_TANBUU, 'PlusPlan');
                                }}
                                component={Link}
                                to="/register"
                            >
                                {t('landing.buyNow')}
                            </Button>
                        </td>
                        <td>
                            <Button
                                variant="text"
                                className={classNames(styles.planButton, styles.tableButton, styles.planContactUs)}
                                component={Link}
                                to="/contact"
                            >
                                {t('landing.contactUs')}
                            </Button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}
