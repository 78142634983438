import React from 'react';
import IActions from '../../../../actions/IActions';
import Button from '../../../_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
const styles = require('./AdminNavigation.css');

interface IAdminNavigationProps {
    acceptButton: string;
    onContinueClick: any;
    onReturnClick;
    actions: IActions;
}

export default function AdminNavigation(props: IAdminNavigationProps) {
    const { t } = useTranslation();
    return (
        <section className={styles.root}>
            <Button
                variant="text"
                onClick={props.onReturnClick}
                className={styles.cancel}
            >
                {t('common.cancel')}
            </Button>
            <Button
                variant="contained"
                onClick={props.onContinueClick}
            >
                {props.acceptButton}
            </Button>
        </section>
    );
}

