import { fromJS, merge } from 'immutable';
import * as R from 'ramda';
import * as actions from '../../actions/DataActions';
import IMeeting from '../../interfaces/IMeeting';

const INITIAL_STATE = fromJS({})
    .set('meetings', null)
    .set('calendars', {})
    .set('subscription', null)
    .set('unseenMeetings', {
        scheduled: 0,
        canceled: 0,
        inprogress: 0,
    })
    .set('previewMeetingDate', true);

export default function dataReducer(
    state = INITIAL_STATE,
    action: any = {type: '', payload: {}}
) {
    switch (action.type) {
        case actions.UPDATE_DATA: {
            return merge(state, action.payload);
        }

        case actions.UPDATE_MEETING: {
            const meeting: IMeeting = action.payload;
            const currentMeetings: IMeeting[] = state.get('meetings') || [];
            return state.set('meetings', R.uniqBy(R.prop('id'), [meeting, ...currentMeetings]));
        }
        case actions.UPDATE_MEETINGS: {
            return state.set(
                'meetings',
                R.uniqBy(R.prop('id'), [...action.payload, ...(state.get('meetings') || [])]),
            );
        }
        case actions.PREDICTIONS_PREVIEW: {
            return state.set(
                'previewMeetingDate',
                {...action.payload},
            );
        }
        case actions.REMOVE_PREDICTIONS_PREVIEW: {
            return state.set('previewMeetingDate', true);
        }
        case actions.REMOVE_MEETING: {
            return state.set(
                'meetings',
                state
                    .get('meetings')
                    .filter(m => m.id !== action.payload)
            );
        }

        default: {
            return state;
        }
    }
}
