// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PlanInfo__iP5JN {\n    background-color: #f5fbff;\n    border-radius: 22px;\n    padding: 8px 16px 16px;\n    margin: 16px 0;\n    display: flex;\n    flex-direction: column;\n}\n\n    .PlanInfo__iP5JN p {\n        margin: 8px 0;\n    }\n\n.totalContainer__3VZhE {\n    display: flex;\n    align-items: flex-end;\n}\n\n.Button__3uV5A {\n    font-size: 16px;\n    width: 80px;\n    margin-left: auto;\n}\n\n.Danger__38RS0 {\n    color: #e50200;\n}\n\n.DangerButton__1T9fe {\n    color: white;\n    background-color: #e63e25;\n    font-size: 16px;\n    text-transform: none;\n    font-weight: 400 !important;\n}\n\n.DangerButton__1T9fe:hover {\n        background-color: #b5230e;\n    }\n", "",{"version":3,"sources":["PlanInfo.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,sBAAsB;IACtB,cAAc;IACd,aAAa;IACb,sBAAsB;AAK1B;;IAHI;QACI,aAAa;IACjB;;AAGJ;IACI,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,oBAAoB;IACpB,2BAA2B;AAI/B;;AAHI;QACI,yBAAyB;IAC7B","file":"PlanInfo.css","sourcesContent":[".PlanInfo {\n    background-color: #f5fbff;\n    border-radius: 22px;\n    padding: 8px 16px 16px;\n    margin: 16px 0;\n    display: flex;\n    flex-direction: column;\n\n    & p {\n        margin: 8px 0;\n    }\n}\n\n.totalContainer {\n    display: flex;\n    align-items: flex-end;\n}\n\n.Button {\n    font-size: 16px;\n    width: 80px;\n    margin-left: auto;\n}\n\n.Danger {\n    color: #e50200;\n}\n\n.DangerButton {\n    color: white;\n    background-color: #e63e25;\n    font-size: 16px;\n    text-transform: none;\n    font-weight: 400 !important;\n    &:hover {\n        background-color: #b5230e;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"PlanInfo": "PlanInfo__iP5JN",
	"totalContainer": "totalContainer__3VZhE",
	"Button": "Button__3uV5A",
	"Danger": "Danger__38RS0",
	"DangerButton": "DangerButton__1T9fe"
};
module.exports = exports;
