// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__3OV1v {\n    padding: 0;\n    margin: 55px 0 36px;\n    width: 100%;\n}\n", "",{"version":3,"sources":["ICloudCalendarHelper.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,mBAAmB;IACnB,WAAW;AACf","file":"ICloudCalendarHelper.css","sourcesContent":[".root {\n    padding: 0;\n    margin: 55px 0 36px;\n    width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__3OV1v"
};
module.exports = exports;
