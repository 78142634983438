// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".timeField__1tVT6 {\n    border-radius: 3px;\n    font-size: 16px;\n    height: 40px;\n    width: 160px;\n}\n", "",{"version":3,"sources":["Styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,YAAY;AAChB","file":"Styles.css","sourcesContent":[".timeField {\n    border-radius: 3px;\n    font-size: 16px;\n    height: 40px;\n    width: 160px;\n}\n"]}]);
// Exports
exports.locals = {
	"timeField": "timeField__1tVT6"
};
module.exports = exports;
