import React, { useEffect } from 'react';
import IActions from '../../../actions/IActions';
import EditPlan from './EditPlan/EditPlan';
import PlanSummary from './PlanSummary/PlanSummary';
import AdminLinks from '../../_ReusableComponents/AdminLinks/AdminLinks';
import FallingLeaves from '../../_ReusableComponents/FallingLeaves/FallingLeaves';
import PlaceOrder from './PlaceOrder/PlaceOrder';
import StripeWrapper from '../../_ReusableComponents/StripeWrapper/StripeWrapper';
import BillingCheckout from '../../_ReusableComponents/BillingCheckout/BillingCheckout';
import getStripe from '../../../utils/getStripe';
import ICardDetails from '../../../interfaces/backendResponses/ICardDetails';
import { useTranslation } from 'react-i18next';
const Styles = require('./AdminPanel.css');

interface IAdminPanelProps {
    plusPlanPayment: any;
    plusPlanAdmin: any;
    data: any;
    actions: IActions;
    history: any;
}

const AdminPanel = (props: IAdminPanelProps) => {
    const { i18n } = useTranslation();
    useEffect(() => {
        const fetchData = async () => {
            try {
                await props.actions.adminGetPlans();
                await props.actions.adminGetCard();
            } catch (error) {
            }
        };
        fetchData();
    }, []);
    const subscription = props.data.get('subscription');
    const token = subscription ? subscription.payment.three_d_secure : null;
    useEffect(() => {
        if (token) {
            getStripe(i18n.language).then(stripe => {
                stripe.handleCardPayment(token);
            });
        }
    }, [token]);
    useEffect(() => {
        if (subscription && !subscription.status) {
            props.history.push('/upgradePlan');
        }
    }, [subscription]);

    if (!props.plusPlanAdmin.get('plansLoaded') || !props.plusPlanAdmin.get('card')) {
        return <FallingLeaves />;
    }

    const cardRedirect = () => {
        props.actions.changeActivePanel(props.plusPlanAdmin.get('cardSaveNextPanel') || 'summary');
    };
    const onCardSave = (card: ICardDetails) => {
        props.actions.cardSaved(card);
        cardRedirect();
    };

    const selectPanelPart = () => {
        const panelPart = props.plusPlanAdmin.get('activePanel');
        switch (panelPart) {
            case 'edit':
                return <EditPlan {...props} />;
            case 'updatePaymentInfo':
                return (
                    <StripeWrapper>
                        <BillingCheckout
                            onBackClick={cardRedirect}
                            onSuccess={onCardSave}
                            card={props.plusPlanAdmin.get('card').toJS()}
                            saveButton="Save"
                            noHeader
                            margin
                        />
                    </StripeWrapper>
                );
            case 'placeOrder':
                return <PlaceOrder {...props} />;
            case 'summary':
            default:
                return <PlanSummary {...props} />;
        }
    };
    return (
        <main className={Styles.AdminPanel} data-testid="adminPanel">
            {selectPanelPart()}
        </main>
    );
};

export default AdminPanel;
