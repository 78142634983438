import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import Integrations from 'components/PreferencesPageComponents/Integrations/Integrations';
import MyWorkWeekTab from 'components/PreferencesPageComponents/MyWorkWeek/MyWorkWeekTab/MyWorkWeekTab';
import MyInfo from 'components/PreferencesPageComponents/MyInfo/MyInfo';
import IActions from 'actions/IActions';
import Header from '../../components/_ReusableComponents/Header/Header/Header';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import inIframe from '../../utils/inIframe';
import { useHistory, useLocation } from 'react-router-dom';
import GlobalContext from '../../GlobalContext';
import { IConnection, IConnectionScope } from 'interfaces/interfaces';
import { ICloudCalendar } from 'components/PreferencesPageComponents/ICloudCalendar/ICloudCalendar';
const config = require('CONFIG');
const styles = require('./PreferencesPage.css');

interface Props {
    data: any;
    actions: IActions;
    preferences: any;
}

function PreferencesPage(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { profile } = useContext(GlobalContext);
    const [isICloudCalendar, setIsICloudCalendar] = useState<boolean>(false);
    const [addScopes, setAddScopes] = useState<IConnectionScope[]>([]);
    const { data, actions } = props;
    const connections: IConnection[] = props.data.get('connections');
    const [isAppleCalendarEvents, setIsAppleCalendarEvents] = useState<boolean>(false);
    const [nowProvider, setNowProvider] = useState<string>('');
    useEffect(() => {
        actions.setPreferences(R.clone(profile), data.get('workWeek'));
        if (location.search) {
            const params = new URLSearchParams(location.search);
            const openTab = params.get('openTab');
            if (openTab === 'myWorkWeek' && !props.preferences.get('isMyWorkWeekVisible')) {
                actions.toggleMyWorkWeek();
            }
            if (openTab === 'integrations' && !props.preferences.get('isIntegrationsVisible')) {
                actions.toggleIntegrations();
            }
            const oauth = params.get('oauth');
            if (oauth) {
                const isOutlook = sessionStorage.getItem('outlook') === '1';
                if (isOutlook && !inIframe()) {
                    const { outlookOrigin } = config;
                    window.location.assign(
                        `${outlookOrigin}/callback.html?oauth=${oauth}`,
                    );
                } else {
                    if (oauth === 'success') {
                        actions.showSuccessMessage(t('settings.accountAdded'));
                    } else {
                        actions.showErrorMessage(t('settings.failedToAddAccount'));
                    }
                    actions.toggleIntegrations();
                    history.push('/preferences');
                }
            }
        }
    }, []);
    return (
        <div className={styles.root}>
            <Header />
            {isICloudCalendar ? (
                <ICloudCalendar
                    setIsICloudCalendar={setIsICloudCalendar}
                    scope={addScopes}
                    connections={connections}
                    isAppleCalendarEvents={isAppleCalendarEvents}
                    setIsAppleCalendarEvents={setIsAppleCalendarEvents}
                    email={nowProvider}
                    setEmail={setNowProvider}
                />
            ) : (<>
                <MyInfo {...props} />
                <Integrations
                    actions={props.actions}
                    preferences={props.preferences}
                    data={props.data}
                    setIsICloudCalendar={setIsICloudCalendar}
                    addScopes={addScopes}
                    setAddScopes={setAddScopes}
                    connections={connections}
                    setIsAppleCalendarEvents={setIsAppleCalendarEvents}
                    setNowProvider={setNowProvider}
                    nowProvider={nowProvider}
                />
                <MyWorkWeekTab {...props} />
            </>)}
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(PreferencesPage);
