import {ISingleTypeAction, IMultipleTypesAction} from 'interfaces/IAction';
import BackendMethods from '../api/BackendMethods';
import ILicenseAssignment from '../interfaces/backendResponses/ILicenseAssignment';

export const CHANGE_LICENSE_NAME = 'SET_LICENSE_NAME';
export const CHANGE_LICENSE_EMAIL = 'SET_LICENSE_EMAIL';
export const SET_LICENSE_EMAIL_ERROR = 'SET_LICENSE_EMAIL_ERROR';
export const SET_INSUFFICIENT_LICENSE_AMOUNT = 'SET_INSUFFICIENT_LICENSE_AMOUNT';
export const GET_ASSIGNED_LICENSES_PENDING = 'GET_ASSIGNED_LICENSES_PENDING';
export const GET_ASSIGNED_LICENSES_FULFILLED = 'GET_ASSIGNED_LICENSES_FULFILLED';
export const GET_ASSIGNED_LICENSES_REJECTED = 'GET_ASSIGNED_LICENSES_REJECTED';
export const ASSIGN_PENDING = 'ASSIGN_PENDING';
export const LICENSE_ASSIGNED = 'LICENSE_ASSIGNED';
export const ASSIGN_LICENSE_ERROR = 'ASSIGN_LICENSE_ERROR';
export const REMOVAL_PENDING = 'REMOVAL_PENDING';
export const LICENSE_REMOVED = 'LICENSE_REMOVED';
export const SET_LICENSE_EXPIRING_PENDING = 'SET_LICENSE_EXPIRING_PENDING';
export const SET_LICENSE_EXPIRING = 'SET_LICENSE_EXPIRING';

export default class ManageLicensesActions {
    changeLicenseName(licenseName: string): ISingleTypeAction {
        return {
            type: CHANGE_LICENSE_NAME,
            payload: {
                licenseName: licenseName
            }
        };
    }

    changeLicenseEmail(licenseEmail: string): ISingleTypeAction {
        return {
            type: CHANGE_LICENSE_EMAIL,
            payload: {
                licenseEmail: licenseEmail
            }
        };
    }

    setLicenseEmailError(): ISingleTypeAction {
        return {
            type: SET_LICENSE_EMAIL_ERROR,
            payload: {}
        };
    }

    setInsufficientLicenseAmount(): ISingleTypeAction {
        return {
            type: SET_INSUFFICIENT_LICENSE_AMOUNT,
            payload: {}
        };
    }

    getAssignedLicenses(): IMultipleTypesAction {
        return {
            types: [GET_ASSIGNED_LICENSES_PENDING, GET_ASSIGNED_LICENSES_FULFILLED, GET_ASSIGNED_LICENSES_REJECTED],
            payload: {
                promise: BackendMethods.getAssignedLicenses()
            }
        };
    }

    assignLicense(license: ILicenseAssignment) {
        return async dispatch => {
            dispatch({ type: ASSIGN_PENDING });
            try {
                const newLicense = await BackendMethods.assignLicense(license);
                dispatch({
                    type: LICENSE_ASSIGNED,
                    payload: newLicense
                });
            } catch (error) {
                dispatch({
                    type: ASSIGN_LICENSE_ERROR,
                    payload: error
                });
            }
        };
    }

    removeLicense(licenseeId: number) {
        return async dispatch => {
            dispatch({ type: REMOVAL_PENDING });
            await BackendMethods.removeLicense(licenseeId);
            dispatch({
                type: LICENSE_REMOVED,
                payload: licenseeId
            });
        };
    }

    setLicenseExpiring(id: number, expiring: boolean) {
        return async dispatch => {
            dispatch({
                type: SET_LICENSE_EXPIRING_PENDING,
                payload: { id, expiring }
            });
            await BackendMethods.setLicenseExpiring(id, expiring);
            dispatch({
                type: SET_LICENSE_EXPIRING,
                payload: { id }
            });
        };
    }
}
