import React from 'react';
import { IconButton } from '@material-ui/core';
import { IWorkHours } from 'interfaces/interfaces';
import moment from 'moment';
import PreferencesUtils from 'utils/PreferencesUtils/PreferencesUtils';
import IActions from 'actions/IActions';
import { useTranslation } from 'react-i18next';

const Styles = require('./MyWorkWeekHoursView.css');

type Props = {
    workHours: IWorkHours[];
    closeWorkHours: Function;
    data?: any;
    actions?: IActions;
    preferences?: any;
    timeFormat: string;
};

const MyWorkWeekHoursView: React.FC<Props> = ({
    workHours,
    closeWorkHours,
    timeFormat
}) => {
    const { i18n } = useTranslation();
    const renderWorkSlot = (workSlot: IWorkHours, index: number, timeType: string) => {
        return (
            <div key={index} className={Styles.slotWrapper}>
                <span>
                    {
                        workSlot.days.map((workDay, idx) => {
                            const day = i18n.language === 'en' ?
                                PreferencesUtils.capitalizeFirstLetter(moment(workDay, 'dddd', 'en').locale(i18n.language).format('dd')) :
                                moment(workDay, 'dddd', 'en').locale(i18n.language).format('dd').toLowerCase();
                            return idx > 0 ? `, ${day}` : day;
                        })
                    }
                </span>
                <span className={Styles.time}>
                    { timeType === '12h' ? moment(workSlot.begin, 'hh:mm').format('hh:mm a') : workSlot.begin }
                    {' - '}
                    { timeType === '12h' ? moment(workSlot.end, 'hh:mm').format('hh:mm a') : workSlot.end }
                </span>
                <IconButton
                    color="primary"
                    style={{ height: 20, width: 20, border: 'solid 1px' }}
                    onClick={() => closeWorkHours(workSlot.id)}
                >
                    <img
                        src="/assets/close_icon.svg"
                        alt=""
                        width={'12'}
                        height={'12'}
                    />
                </IconButton>
            </div>
        );
    };

    return (
        <div className={Styles.container}>
            {PreferencesUtils.sortWorkWeekHours([...workHours]).map((workDay, index) => renderWorkSlot(workDay, index, timeFormat))}
        </div>
    );
};

export default MyWorkWeekHoursView;
