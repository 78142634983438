import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link, useRouteMatch } from 'react-router-dom';
import { Collapse, Drawer, IconButton, List, ListItem } from '@material-ui/core';
import Button from '../../components/_ReusableComponents/Button/Button';
const styles = require('./MobileNav.css');

export default function MobileNav() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const isCustomerSuccess = useRouteMatch('/customer-success') !== null;
    const isForSales = useRouteMatch('/for-sales') !== null;
    const [useCasesOpen, setUseCasesOpen] = useState(isCustomerSuccess || isForSales);
    return (
        <div>
            <div className={styles.bar}>
                <Button
                    variant="text"
                    component={Link}
                    to="/"
                    className={styles.logoLink}
                >
                    <div className={styles.logo} />
                </Button>
                <IconButton onClick={() => setOpen(true)}>
                    <div className={styles.menuIcon} />
                </IconButton>
            </div>
            <Drawer
                anchor="left"
                open={open}
                onClose={() => setOpen(false)}
            >
                <List className={styles.list}>
                    <ListItem
                        button
                        component={NavLink}
                        to="/"
                        className={styles.link}
                        activeClassName={styles.active}
                        exact
                        onClick={() => setOpen(false)}
                    >
                        {t('landing.home')}
                    </ListItem>
                    <ListItem
                        button
                        component={NavLink}
                        to="/features"
                        className={styles.link}
                        activeClassName={styles.active}
                        onClick={() => setOpen(false)}
                    >
                        {t('landing.features')}
                    </ListItem>
                    <ListItem
                        button
                        component={NavLink}
                        to="/pricing"
                        className={styles.link}
                        activeClassName={styles.active}
                        onClick={() => setOpen(false)}
                    >
                        {t('landing.pricing')}
                    </ListItem>
                    <ListItem
                        button
                        className={styles.link}
                        onClick={() => setUseCasesOpen(!useCasesOpen)}
                    >
                        {t('landing.useCases')}
                    </ListItem>
                    <Collapse
                        in={useCasesOpen}
                    >
                        <List disablePadding className={styles.nestedList}>
                            <ListItem
                                button
                                component={NavLink}
                                to="/customer-success"
                                className={styles.link}
                                activeClassName={styles.active}
                                onClick={() => setOpen(false)}
                            >
                                {t('landing.customerSuccess')}
                            </ListItem>
                            <ListItem
                                button
                                component={NavLink}
                                to="/for-sales"
                                className={styles.link}
                                activeClassName={styles.active}
                                onClick={() => setOpen(false)}
                            >
                                {t('landing.forSales')}
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem
                        button
                        component={NavLink}
                        to="/faq"
                        className={styles.link}
                        activeClassName={styles.active}
                        onClick={() => setOpen(false)}
                    >
                        {t('landing.faq')}
                    </ListItem>
                    <ListItem
                        button
                        component={NavLink}
                        to="/about"
                        className={styles.link}
                        activeClassName={styles.active}
                        onClick={() => setOpen(false)}
                    >
                        {t('landing.aboutUs')}
                    </ListItem>
                    <ListItem
                        button
                        component={NavLink}
                        to="/contact"
                        className={styles.link}
                        activeClassName={styles.active}
                        onClick={() => setOpen(false)}
                    >
                        {t('landing.contact')}
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
}
