// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".sendButton__32C0r {\n    display: flex;\n    margin: 16px 0 0 auto;\n}\n\n.captchaContainer__37CCI {\n    margin: 12px 0;\n}\n\n.captchaError__3fs7C {\n    margin-left: 16px;\n}\n\n.header__dRct9 {\n    color: #013253;\n    font-size: 22px;\n    letter-spacing: 1px;\n    margin-bottom: 16px;\n}\n\n.landingSend__2gFnd {\n    margin-top: 30px;\n    min-width: 140px;\n    padding: 15px 30px;\n    background-image: url('/assets/sign-up.svg');\n    background-size: 100% 100%;\n    color: white;\n    font-size: 18px;\n    font-weight: 300 !important;\n    display: flex;\n    margin-left: auto;\n}\n\n@media (max-width: 340px) {\n    .captchaContainer__37CCI {\n        display: flex;\n        justify-content: center;\n    }\n}\n", "",{"version":3,"sources":["SupportForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,4CAA4C;IAC5C,0BAA0B;IAC1B,YAAY;IACZ,eAAe;IACf,2BAA2B;IAC3B,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI;QACI,aAAa;QACb,uBAAuB;IAC3B;AACJ","file":"SupportForm.css","sourcesContent":[".sendButton {\n    display: flex;\n    margin: 16px 0 0 auto;\n}\n\n.captchaContainer {\n    margin: 12px 0;\n}\n\n.captchaError {\n    margin-left: 16px;\n}\n\n.header {\n    color: #013253;\n    font-size: 22px;\n    letter-spacing: 1px;\n    margin-bottom: 16px;\n}\n\n.landingSend {\n    margin-top: 30px;\n    min-width: 140px;\n    padding: 15px 30px;\n    background-image: url('/assets/sign-up.svg');\n    background-size: 100% 100%;\n    color: white;\n    font-size: 18px;\n    font-weight: 300 !important;\n    display: flex;\n    margin-left: auto;\n}\n\n@media (max-width: 340px) {\n    .captchaContainer {\n        display: flex;\n        justify-content: center;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"sendButton": "sendButton__32C0r",
	"captchaContainer": "captchaContainer__37CCI",
	"captchaError": "captchaError__3fs7C",
	"header": "header__dRct9",
	"landingSend": "landingSend__2gFnd"
};
module.exports = exports;
