// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".content__28AyV {\n    max-width: 1400px;\n    margin-left: auto;\n    margin-right: auto;\n    width: 100%;\n}\n", "",{"version":3,"sources":["Feature.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;AACf","file":"Feature.css","sourcesContent":[".content {\n    max-width: 1400px;\n    margin-left: auto;\n    margin-right: auto;\n    width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "content__28AyV"
};
module.exports = exports;
