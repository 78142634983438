import IMeeting from '../interfaces/IMeeting';
import Auth from 'auth/Auth';
import cookies from 'js-cookie';
const jwtDecode = require('jwt-decode');
const config = require('CONFIG');

const { hostname } = window.location;
const domain = hostname.endsWith(config.baseDomain) ? config.baseDomain : hostname;

export const parseJwt = (token: string) => {
    return jwtDecode(token);
};

export const deleteAuthCookies = function() {
    cookies.remove('authToken', { domain });
    cookies.remove('expiresAt', { domain });
    cookies.remove('bookingAddPhoneToken', { domain });
    // Old no-subdomain cookies
    cookies.remove('authToken');
    cookies.remove('expiresAt');
    localStorage.removeItem('isAdHoc');
    localStorage.removeItem('isEnterprise');
};

export const getSearchParam = (search: string, paramName: string) =>
    new URLSearchParams(search).get(paramName);

export const isLoggedIn = () => Auth.isAuthenticated();

export const getAdHocAuthToken = () => {
    const authToken = getSearchParam(location.search, 'authToken');
    if (authToken) {
        const fullToken =
            'AdHoc ' + getSearchParam(location.search, 'authToken');
        sessionStorage.setItem('tanbuuAdHoc', fullToken);
        return fullToken;
    }
    if (sessionStorage.getItem('tanbuuAdHoc')) {
        return sessionStorage.getItem('tanbuuAdHoc');
    }
    return null;
};

export const getAuthToken = () => {
    const adHoc = getAdHocAuthToken();
    const auth = cookies.get('authToken');
    if (!adHoc) {
        return auth;
    }
    if (!auth) {
        return adHoc;
    }
    const sameUser = parseJwt(adHoc).email === parseJwt(auth).email;
    return sameUser ? auth : adHoc;
};

export const isAdHoc = () => getAuthTokenType() === 'AdHoc';

export const isOrganizer = (meeting: IMeeting) => meeting.isOrganizer;

// this does not consider AdHoc token
export const getAuthTokenType = () => {
    const token = getAuthToken();
    if (token) {
        return token.split(' ')[0];
    }
    return null;
};
