import React from 'react';
import Footer from '../Footer';
import FindService from './FindService';
const styles = require('./FindServiceMobile.css');

export default function FindServiceMobile() {
    return (
        <div className={styles.root}>
            <div className={styles.main}>
                <FindService />
            </div>
            <Footer />
        </div>
    );
}
