// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Label__2-hG5 {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-top: 16px;\n    width: 100%;\n}\n\n.checkbox__2VDTW {\n    margin-right: -12px;\n}\n", "",{"version":3,"sources":["AutoRenew.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB","file":"AutoRenew.css","sourcesContent":[".Label {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-top: 16px;\n    width: 100%;\n}\n\n.checkbox {\n    margin-right: -12px;\n}\n"]}]);
// Exports
exports.locals = {
	"Label": "Label__2-hG5",
	"checkbox": "checkbox__2VDTW"
};
module.exports = exports;
