// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__3xSgo {\n    min-width: 92px;\n}\n\n.outlined__3b8Bw {\n    padding: 3px 15px;\n}\n\n.contained__TjgD0 {\n    padding: 4px 15px;\n}\n\n.outlinedPrimary__2iVhu {\n    border-color: #08a;\n}\n\n.text__3EVLF {\n    font-weight: 400 !important;\n}\n", "",{"version":3,"sources":["Button.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;AAC/B","file":"Button.css","sourcesContent":[".root {\n    min-width: 92px;\n}\n\n.outlined {\n    padding: 3px 15px;\n}\n\n.contained {\n    padding: 4px 15px;\n}\n\n.outlinedPrimary {\n    border-color: #08a;\n}\n\n.text {\n    font-weight: 400 !important;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__3xSgo",
	"outlined": "outlined__3b8Bw",
	"contained": "contained__TjgD0",
	"outlinedPrimary": "outlinedPrimary__2iVhu",
	"text": "text__3EVLF"
};
module.exports = exports;
