// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".TermsContent__1jqYf {\n    text-align: justify;\n}\n\n.MainHeader__1L0zC {\n    font-weight: 400 !important;\n    font-size: 24px;\n}\n\n.Header__1QjS3 {\n    font-size: 16px;\n    font-weight: 400 !important;\n}\n", "",{"version":3,"sources":["TermsContent.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,2BAA2B;AAC/B","file":"TermsContent.css","sourcesContent":[".TermsContent {\n    text-align: justify;\n}\n\n.MainHeader {\n    font-weight: 400 !important;\n    font-size: 24px;\n}\n\n.Header {\n    font-size: 16px;\n    font-weight: 400 !important;\n}\n"]}]);
// Exports
exports.locals = {
	"TermsContent": "TermsContent__1jqYf",
	"MainHeader": "MainHeader__1L0zC",
	"Header": "Header__1QjS3"
};
module.exports = exports;
