import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAppointment } from './Dashboard';
import { Moment } from 'moment';
import { allCountries, Country } from 'country-telephone-data';
import PhoneNumber from 'awesome-phonenumber';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import { DatePicker } from '@material-ui/lab';
import Autocomplete from '../../../components/_ReusableComponents/Autocomplete/Autocomplete';
import Button from '../../../components/_ReusableComponents/Button/Button';
import { useIsDesktop } from '../../../utils/responsive';
import moment from 'moment';
import BackendMethods from '../../../api/BackendMethods';
const styles = require('./CustomerData.css');

interface IProps {
    appointment: IAppointment;
    onSave: (appointment: IAppointment) => void;
}

export default function CustomerData({ appointment, onSave }: IProps) {
    const { t, i18n: { language } } = useTranslation();
    const isDesktop = useIsDesktop();
    const { customer, bookingFor } = appointment;
    const [name, setName] = useState(customer.firstName);
    const [surname, setSurname] = useState(customer.lastName);
    const [birthdate, setBirthdate] = useState<Moment | null>(
        customer.birthDate ? moment(customer.birthDate) : null,
    );
    const country2 = allCountries.find((x) => (
        x.dialCode === customer.countryCode.substring(1)
    ));
    const [country, setCountry] = useState<Country>(country2);
    const [phone, setPhone] = useState(new PhoneNumber(customer.phone, country2.iso2).getNumber('national'));
    const getCodeLabel = (country3: Country) => `${country3.iso2.toUpperCase()} +${country3.dialCode}`;
    const [email, setEmail] = useState(customer.email);
    const [extraName, setExtraName] = useState(bookingFor?.firstName);
    const [extraSurname, setExtraSurname] = useState(bookingFor?.lastName);
    const [extraBirthdate, setExtraBirthdate] = useState<Moment | null>(
        bookingFor?.birthDate ? moment(bookingFor?.birthDate) : null,
    );
    const country4 = allCountries.find((x) => (
        x.dialCode === (bookingFor?.countryCode || '').substring(1)
    ));
    const [extraCountry, setExtraCountry] = useState<Country>(country4);
    const [extraPhone, setExtraPhone] = useState(
        bookingFor?.phone ? new PhoneNumber(bookingFor.phone, country4.iso2).getNumber('national') : '',
    );
    const [extraEmail, setExtraEmail] = useState(bookingFor?.email);
    return (
        <div>
            {bookingFor && (
                <div className={styles.bookingFor}>
                    <TextField
                        label={t('small.customerName')}
                        value={extraName}
                        onChange={({ target }) => (
                            setExtraName(target.value)
                        )}
                        fullWidth
                    />
                    <TextField
                        label={t('small.surname')}
                        value={extraSurname}
                        onChange={({ target }) => (
                            setExtraSurname(target.value)
                        )}
                        fullWidth
                    />
                    <DatePicker
                        value={extraBirthdate}
                        onChange={setExtraBirthdate}
                        label={t('small.birthdate')}
                        renderInput={(props2) => (
                            <TextField {...props2} fullWidth helperText="" inputProps={{ ...props2.inputProps, placeholder: language === 'pl' ? 'dd.mm.rrrr' : 'mm/dd/yyyy' }} />
                        )}
                    />
                    <div className={styles.phoneRow}>
                        <Autocomplete
                            value={extraCountry}
                            getOptionLabel={getCodeLabel}
                            onChange={(event, newValue) => (
                                setExtraCountry(newValue)
                            )}
                            options={allCountries}
                            className={styles.country}
                            disableClearable
                            label={t('small.countryCode')}
                        />
                        <TextField
                            value={extraPhone}
                            onChange={({ target }) => (
                                setExtraPhone(target.value)
                            )}
                            label={t('small.phoneNumber')}
                            className={styles.phone}
                        />
                    </div>
                    <TextField
                        label={t('small.email')}
                        value={extraEmail}
                        onChange={({ target }) => (
                            setExtraEmail(target.value)
                        )}
                        fullWidth
                    />
                </div>
            )}
            <div className={styles.bookedBy}>
                {t('small.bookedBy')}
                :
            </div>
            <TextField
                label={t('small.customerName')}
                value={name}
                onChange={({ target }) => (
                    setName(target.value)
                )}
                fullWidth
            />
            <TextField
                label={t('small.surname')}
                value={surname}
                onChange={({ target }) => (
                    setSurname(target.value)
                )}
                fullWidth
            />
            <DatePicker
                value={birthdate}
                onChange={setBirthdate}
                label={t('small.birthdate')}
                renderInput={(props2) => (
                    <TextField {...props2} fullWidth helperText="" inputProps={{ ...props2.inputProps, placeholder: language === 'pl' ? 'dd.mm.rrrr' : 'mm/dd/yyyy' }} />
                )}
            />
            <div className={styles.phoneRow}>
                <Autocomplete
                    value={country}
                    getOptionLabel={getCodeLabel}
                    onChange={(event, newValue) => (
                        setCountry(newValue)
                    )}
                    options={allCountries}
                    className={styles.country}
                    disableClearable
                    label={t('small.countryCode')}
                />
                <TextField
                    value={phone}
                    onChange={({ target }) => (
                        setPhone(target.value)
                    )}
                    label={t('small.phoneNumber')}
                    className={styles.phone}
                />
            </div>
            <TextField
                label={t('small.email')}
                value={email}
                onChange={({ target }) => (
                    setEmail(target.value)
                )}
                fullWidth
            />
            <div className={styles.saveRow}>
                <Button
                    onClick={() => onSave(appointment)}
                    className={styles.cancel}
                    variant={isDesktop ? 'outlined' : 'text'}
                >
                    {t('common.cancel')}
                </Button>
                <Button
                    variant="contained"
                    disabled={(
                        !name || !surname || !phone
                        || (bookingFor && (!extraName || !extraSurname || !extraPhone))
                    )}
                    onClick={async () => {
                        const newCustomer = {
                            id: customer.id,
                            firstName: name,
                            lastName: surname,
                            birthDate: birthdate ? birthdate.format('YYYY-MM-DD') : null,
                            countryCode: `+${country.dialCode}`,
                            phone: new PhoneNumber(phone, country.iso2).getNumber('significant'),
                            email: email || null,
                        };
                        const newBookingFor = bookingFor ? {
                            id: bookingFor.id,
                            firstName: extraName,
                            lastName: extraSurname,
                            birthDate: extraBirthdate ? extraBirthdate.format('YYYY-MM-DD') : null,
                            ...(extraPhone ? {
                                countryCode: `+${extraCountry.dialCode}`,
                                phone: new PhoneNumber(extraPhone, extraCountry.iso2).getNumber('significant'),
                            } : {}),
                            email: extraEmail || null,
                        } : null;
                        await BackendMethods.updateCustomer(newCustomer);
                        if (newBookingFor) {
                            await BackendMethods.updateCare(newCustomer.id, newBookingFor);
                        }
                        onSave({
                            ...appointment,
                            customer: newCustomer,
                            bookingFor: newBookingFor,
                        });
                    }}
                >
                    {t('small.save')}
                </Button>
            </div>
        </div>
    );
}
