// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".NewMeetingButton__1kHjW {\n    background: #e9523e;\n    border: 0;\n    border-radius: 40px;\n    bottom: 16px;\n    box-shadow: 0 3px 1px 1px rgba(0, 0, 0, 0.25);\n    color: white;\n    cursor: pointer;\n    font-size: 18px;\n    height: 60px;\n    outline: none;\n    position: fixed;\n    right: 15px;\n    transition: all 0.3s ease-out;\n    width: 60px;\n    z-index: 99;\n}\n\n.PlusIcon__1CPYC {\n    height: 25px;\n    margin-bottom: -3px;\n    width: 25px;\n}\n\n.NewMeetingButton__1kHjW:hover {\n    background: #e91617;\n}\n", "",{"version":3,"sources":["NewMeetingButton.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,SAAS;IACT,mBAAmB;IACnB,YAAY;IACZ,6CAA6C;IAC7C,YAAY;IACZ,eAAe;IACf,eAAe;IACf,YAAY;IACZ,aAAa;IACb,eAAe;IACf,WAAW;IACX,6BAA6B;IAC7B,WAAW;IACX,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB","file":"NewMeetingButton.css","sourcesContent":[".NewMeetingButton {\n    background: #e9523e;\n    border: 0;\n    border-radius: 40px;\n    bottom: 16px;\n    box-shadow: 0 3px 1px 1px rgba(0, 0, 0, 0.25);\n    color: white;\n    cursor: pointer;\n    font-size: 18px;\n    height: 60px;\n    outline: none;\n    position: fixed;\n    right: 15px;\n    transition: all 0.3s ease-out;\n    width: 60px;\n    z-index: 99;\n}\n\n.PlusIcon {\n    height: 25px;\n    margin-bottom: -3px;\n    width: 25px;\n}\n\n.NewMeetingButton:hover {\n    background: #e91617;\n}\n"]}]);
// Exports
exports.locals = {
	"NewMeetingButton": "NewMeetingButton__1kHjW",
	"PlusIcon": "PlusIcon__1CPYC"
};
module.exports = exports;
