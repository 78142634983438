import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
const styles = require('./AdminLinks.css');

interface IAdminLinksProps {
    active: string;
}

export default function AdminLinks({ active }: IAdminLinksProps) {
    const { t } = useTranslation();
    return (
        <nav className={styles.root} data-testid="adminNavigation">
            <Button
                className={classNames(styles.link, {
                    [styles.active]: active === 'plan',
                })}
                variant="contained"
                component={Link}
                to="/manage"
                data-testid="planLink"
            >
                {t('plan.plan')}
            </Button>
            <Button
                className={classNames(styles.link, {
                    [styles.active]: active === 'users',
                })}
                variant="contained"
                component={Link}
                to="/licenses"
                data-testid="usersLink"
            >
                {t('plan.users')}
            </Button>
            <Button
                className={classNames(styles.link, {
                    [styles.active]: active === 'billing',
                })}
                variant="contained"
                component={Link}
                to="/billing"
                data-testid="billingLink"
            >
                {t('plan.billing')}
            </Button>
            <Button
                className={classNames(styles.link, {
                    [styles.active]: active === 'team',
                })}
                variant="contained"
                component={Link}
                to="/team"
                data-testid="teamLink"
            >
                {t('plan.team')}
            </Button>
        </nav>
    );
}
