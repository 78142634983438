// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".slotWrapper__7nA-B {\n    background-color: #f0f0f0;\n    padding: 6px 12px;\n    border-radius: 16px;\n    text-align: left;\n    letter-spacing: 0;\n    color: #043352;\n    opacity: 1;\n    width: -webkit-max-content;\n    width: -moz-max-content;\n    width: max-content;\n    margin-bottom: 8px;\n}\n\n.slotWrapper__7nA-B:hover {\n    background-color: #f7f7f7;\n}\n\n.time__3SZsU {\n    margin-left: 12px;\n    margin-right: 22px;\n}\n", "",{"version":3,"sources":["MyWorkWeekHoursView.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,UAAU;IACV,0BAAkB;IAAlB,uBAAkB;IAAlB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","file":"MyWorkWeekHoursView.css","sourcesContent":[".slotWrapper {\n    background-color: #f0f0f0;\n    padding: 6px 12px;\n    border-radius: 16px;\n    text-align: left;\n    letter-spacing: 0;\n    color: #043352;\n    opacity: 1;\n    width: max-content;\n    margin-bottom: 8px;\n}\n\n.slotWrapper:hover {\n    background-color: #f7f7f7;\n}\n\n.time {\n    margin-left: 12px;\n    margin-right: 22px;\n}\n"]}]);
// Exports
exports.locals = {
	"slotWrapper": "slotWrapper__7nA-B",
	"time": "time__3SZsU"
};
module.exports = exports;
