// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".label__33fpi {\n    color: #797979;\n}\n\n.Input__3Gkps {\n    background-color: white;\n    color: #013253;\n    box-sizing: border-box !important;\n}\n\n.input__2TKDb {\n    box-sizing: content-box !important;\n}\n\n.outline__1alNn {\n    border-color: #dee1e1;\n}\n\n.indicator__1dFT2 {\n    transform: rotate(90deg);\n    height: 34px;\n    width: 24px;\n    background-image: url('/assets/chevron.svg');\n    background-size: 16px 16px;\n    background-position: 50% 50%;\n    background-repeat: no-repeat;\n    border-bottom: 1px solid #ccc;\n    right: 5px;\n    top: calc(50% - 17px);\n}\n", "",{"version":3,"sources":["TextField.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,uBAAuB;IACvB,cAAc;IACd,iCAAiC;AACrC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,WAAW;IACX,4CAA4C;IAC5C,0BAA0B;IAC1B,4BAA4B;IAC5B,4BAA4B;IAC5B,6BAA6B;IAC7B,UAAU;IACV,qBAAqB;AACzB","file":"TextField.css","sourcesContent":[".label {\n    color: #797979;\n}\n\n.Input {\n    background-color: white;\n    color: #013253;\n    box-sizing: border-box !important;\n}\n\n.input {\n    box-sizing: content-box !important;\n}\n\n.outline {\n    border-color: #dee1e1;\n}\n\n.indicator {\n    transform: rotate(90deg);\n    height: 34px;\n    width: 24px;\n    background-image: url('/assets/chevron.svg');\n    background-size: 16px 16px;\n    background-position: 50% 50%;\n    background-repeat: no-repeat;\n    border-bottom: 1px solid #ccc;\n    right: 5px;\n    top: calc(50% - 17px);\n}\n"]}]);
// Exports
exports.locals = {
	"label": "label__33fpi",
	"Input": "Input__3Gkps",
	"input": "input__2TKDb",
	"outline": "outline__1alNn",
	"indicator": "indicator__1dFT2"
};
module.exports = exports;
