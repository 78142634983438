import React, { useContext, useState } from 'react';
import Header from '../../components/_ReusableComponents/Header/Header/Header';
import { Route } from 'react-router';
import SmallBusinessSettings from './Settings/Settings';
import { useIsDesktop } from '../../utils/responsive';
import AssistantBooking from './DesktopBooking';
import MobileAssistantBooking from './MobileBooking';
import { IHeaderProps } from '../../components/_ReusableComponents/Header/Header/Header';
import Calendar from './Calendar';
import Dashboard from './Dashboard/Dashboard';
import MobileDashboard from './Dashboard/Mobile';
import classNames from 'classnames';
import GlobalContext from '../../GlobalContext';
import Registered from './Registered';
import { Redirect } from 'react-router-dom';
import FindServiceMobile from './Customer/FindServiceMobile';
import styles from './SmallBusiness.css';
import { Reviews } from './Customer/Review/Reviews';
import containersMapStateToProps from 'utils/containersMapStateToProps';
import containersDispatchToProps from 'utils/containersMapDispatchToProps';
import IActions from 'actions/IActions';
import { connect } from 'react-redux';
import { DesktopReview } from './Dashboard/DesktopReview';

interface ISmallBusinessProps {
    actions: IActions;
}
function SmallBusiness({actions}:ISmallBusinessProps) {
    const { profile } = useContext(GlobalContext);
    const isDesktop = useIsDesktop();
    const [headerProps, setHeaderProps] = useState<Partial<IHeaderProps>>({});
    const [noHeader, setNoHeader] = useState(false);
    const childProps = { profile, setHeaderProps, setNoHeader };
    const desktopReviewProps = { profile, actions, setHeaderProps, isCustomer: true  };
    const reviewProps = { profile, actions };
    return (
        <div>
            {!noHeader && (
                <Header {...headerProps} />
            )}
            <div
                className={classNames(styles.content, {
                    [styles.contentNoHeader]: noHeader,
                })}
            >
                <Route
                    path="/sb/settings/:tab?"
                    render={() => <SmallBusinessSettings {...childProps} />}
                />
                <Route
                    path={[
                        '/sb/assistant-booking/edit/:id',
                        '/sb/assistant-booking/rebook/:id',
                        '/sb/assistant-booking',
                    ]}
                    render={() => {
                        return isDesktop
                            ? <AssistantBooking {...childProps} />
                            : <MobileAssistantBooking {...childProps} />;
                    }}
                />
                <Route
                    path="/sb/calendar"
                    render={() => <Calendar {...childProps} />}
                />
                <Route
                    path="/sb/dashboard"
                    render={() => {
                        return isDesktop
                            ? <Dashboard {...childProps} />
                            : <MobileDashboard {...childProps} />;
                    }}
                />
                <Route
                    path={[
                        '/sb/customer',
                    ]}
                    render={() => {
                        if (isDesktop) {
                            return <DesktopReview {...desktopReviewProps} />;
                        }
                        return <FindServiceMobile />;
                    }}
                />
                <Route
                    path={[
                        '/sb/reviews/:company',
                    ]}
                    render={() => {
                        if (isDesktop) {
                            return <Redirect to={'/sb/customer'} />;
                        }
                        return <Reviews {...reviewProps} />;
                    }}
                />
                <Route
                    path="/sb/registered"
                    render={() => <Registered />}
                />
            </div>
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(SmallBusiness);
