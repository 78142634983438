import React from 'react';
import IActions from '../../../../../actions/IActions';
import Button from '../../../../_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
const Styles = require('./PaymentMethod.css');

interface IPaymentMethodProps {
    data: any;
    actions: IActions;
    plusPlanAdmin: any;
}

const PaymentMethod = (props: IPaymentMethodProps) => {
    const { t } = useTranslation();
    const goToUpdateDetails = () => {
        window.scrollTo(0,0);
        props.actions.changeActivePanel('updatePaymentInfo');
    };
    const card = props.plusPlanAdmin.get('card');
    return (
        <section className={Styles.PaymentMethod}>
            <div>
                <p>
                    {t('plan.paymentMethod')}:
                    <br />
                    {card.get('brand')}, {t('plan.endingIn')} {card.get('last4')}
                </p>
            </div>
            <Button
                variant="contained"
                onClick={goToUpdateDetails}
                className={Styles.Button}
            >
                {t('plan.edit')}
            </Button>
        </section>
    );
};

export default PaymentMethod;
