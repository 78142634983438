import React from 'react';
import * as dateUtils from '../../../../utils/dateUtils/dateUtils';
import { Dialog } from '@material-ui/core';
import IMeeting from '../../../../interfaces/IMeeting';
import MeetingDateLines from '../../../MeetingPageComponents/MeetingDateLines/MeetingDateLines';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';
const styles = require('./SetThisDateModal.css');

interface Props {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    meeting: IMeeting;
    timezone: string;
    timeFormat: string;
    startDate?: string;
}

export default function SetThisDateModal(props: Props) {
    const { t, i18n } = useTranslation();
    const { meeting, startDate } = props;
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{ className: styles.paper }}
            id="ShowSetThisDateModal"
        >
            <div className={styles.title}>
                {meeting.title}
            </div>
            <div>
                {t('home.setDateAndTime')}
                {startDate && (
                    <div className={styles.date}>
                        <MeetingDateLines
                            dateLines={dateUtils.getDateLines(
                                startDate,
                                meeting.duration * 60,
                                props.timezone,
                                props.timeFormat,
                                t,
                                i18n.language,
                            )}
                        />
                    </div>
                )}
            </div>
            <div className={styles.buttons}>
                <Button
                    id="SetThisDateModalYesButton"
                    onClick={props.onConfirm}
                    className={styles.yesButton}
                >
                    {t('home.yes')}
                </Button>
                <Button
                    variant="contained"
                    id="SetThisDateModalNoButton"
                    onClick={props.onClose}
                >
                    {t('home.no')}
                </Button>
            </div>
        </Dialog>
    );
}
