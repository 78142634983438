import isPromise from '../utils/isPromise/isPromise';
import * as appActions from '../actions/AppActions';

function disableClickEvents(dispatch) {
  dispatch({
    type: appActions.DISABLE_CLICK_EVENTS,
    payload: {},
    meta: {}
  });
}

function enableClickEvents(dispatch) {
  dispatch({
    type: appActions.ENABLE_CLICK_EVENTS,
    payload: {},
    meta: {}
  });
}

export default function promiseMiddleware({ dispatch }) {
  return next => action => {
    if (!isPromise(action.payload)) {
      return next(action);
    }

    const { types, payload } = action;
    let { meta } = action;
    const { promise, data } = payload;
    const [PENDING, FULFILLED, REJECTED] = types;

    meta = meta || {};

    if (meta.doNotDisableClickEvents !== true) {
      disableClickEvents(dispatch);
    }

    /**
     * Dispatch the pending action
     */
    dispatch(
      Object.assign(
        {},
        { type: PENDING },
        data ? { payload: data } : {},
        meta ? { meta } : {}
      )
    );

    /**
     * If successful, dispatch the fulfilled action, otherwise dispatch
     * rejected action.
     */
    return promise.then(
      result => {
        dispatch({
          type: FULFILLED,
          payload: result || {},
          meta
        });
        enableClickEvents(dispatch);
      },
      error => {
        const errorObject = error || {};
          if (
            errorObject.status === 401 ||
            errorObject.status === 403 ||
            errorObject.status === 404
          ) {
            dispatch({
              type: REJECTED,
              payload: error,
              meta
            });
          } else if (errorObject.status === 409) {
            errorObject.responseJSON = errorObject.responseJSON || {};
            errorObject.responseJSON.error =
              errorObject.responseJSON.error || {};
            errorObject.responseJSON.error.errors =
              errorObject.responseJSON.error.errors || [];
            errorObject.responseJSON.error.errors[0] =
              errorObject.responseJSON.error.errors[0] || {};
            errorObject.responseJSON.error.errors[0].reason =
              errorObject.responseJSON.error.errors[0].reason || '';
            dispatch({
              type: REJECTED,
              payload: error,
              meta
            });
          } else if (errorObject.status === 412) {
            dispatch({
              type: REJECTED,
              payload: error,
              meta
            });
          } else {
            console.error(errorObject);
            dispatch({
              type: REJECTED,
              payload: error,
              meta
            });
            dispatch({
              type: appActions.SHOW_ERROR_MESSAGE,
              payload:
                errorObject.responseJSON.error.errors[0].message ||
                'Unexpected error. Please refresh the page.'
            });
          }

        enableClickEvents(dispatch);
      }
    );
  };
}
