import * as plusPlanAdmin from '../../actions/PlusPlanAdminActions';
import {LOCATION_CHANGE} from 'react-router-redux';
import {fromJS} from 'immutable';
import * as R from 'ramda';
import { UPDATE_DATA } from '../../actions/DataActions';

const licenseErrors = {
    tooFewLicensesError: false,
    negativeChargesError: false,
    tooManyLicensesError: false,
    licensesBuyAutoRenewError: false,
    invalidNumberError: false
};

const INITIAL_STATE = fromJS({
    activePanel: 'summary',
    newAutoRenew: false,
    newBillingType: 'monthly',
    newAmountOfLicenses: '0',
    renewAdvanced: false,
    newRenewing: '',
    ...licenseErrors,
    invalidRenewError: false,
    yearlyBillingAutoRenewError: false,
    credit: null,
    prorationToken: null,
    paymentSuccess: false,
    paymentFailed: false,
    expiringLicensesChanged: false,
    noChangesError: false,
    threeDSecureToken: null
});

const onSubscriptionChange = (subscription, state) => {
    const { interval, renewing, licenses } = subscription;
    return state.merge({
        newBillingType: interval === 'MONTH' ? 'monthly' : 'yearly',
        newAmountOfLicenses: String(licenses),
        newAutoRenew: renewing === licenses,
        renewAdvanced: renewing > 0 && renewing !== licenses,
        newRenewing: String(renewing),
        ...licenseErrors,
        yearlyBillingAutoRenewError: false,
        noChangesError: false,
        paymentSuccess: false,
        paymentFailed: false,
        expiringLicensesChanged: false,
        activePanel: 'summary'
    });
};

export default function plusPlanAdminReducer(state: any = INITIAL_STATE, action: any = {type: '', payload: {}}) {

    switch (action.type) {
        case LOCATION_CHANGE: {
            return state;
        }

        case plusPlanAdmin.CHANGE_ACTIVE_PANEL: {
            return state.set('activePanel', action.payload.activePanel);
        }

        case plusPlanAdmin.SET_NEW_AUTORENEW: {
            return state.merge({
                newAutoRenew: action.payload.newAutoRenew,
                invalidRenewError: false,
                noChangesError: false
            });
        }

        case plusPlanAdmin.SET_NEW_BILLING_TYPE: {
            return state.merge({
                newBillingType: action.payload.newBillingType,
                yearlyBillingAutoRenewError: false,
                noChangesError: false
            });
        }

        case plusPlanAdmin.SET_NEW_NUMBER_OF_LICENSES: {
            return state.merge({
                newAmountOfLicenses: action.payload.newAmountOfLicenses,
                noChangesError: false,
                ...licenseErrors
            });
        }

        case UPDATE_DATA: {
            const { subscription } = action.payload;
            if (subscription) {
                return onSubscriptionChange(subscription, state);
            }
            return state;
        }

        case plusPlanAdmin.GET_CARD_SUCCESS:
            return state.set('card', fromJS(action.payload));

        case plusPlanAdmin.GET_INVOICES_SUCCESS:
            return state.set('invoices', fromJS(action.payload));

        case plusPlanAdmin.GET_INVOICE_SUCCESS:
            return state.set('invoice', action.payload);

        case plusPlanAdmin.PLANS_RECEIVED: {
            const [monthlyPrice, yearlyPrice] = R.map(
                interval => action.payload.find(R.propEq('interval', interval)).price
            )(['MONTH', 'YEAR']);
            return state.merge({ monthlyPrice, yearlyPrice, plansLoaded: true });
        }

        case plusPlanAdmin.TOGGLE_RENEW_ADVANCED: {
            return state.merge({
                renewAdvanced: action.payload,
                invalidRenewError: false
            });
        }

        case plusPlanAdmin.CHANGE_RENEW_NUMBER: {
            return state.merge({
                newRenewing: action.payload,
                invalidRenewError: false,
                noChangesError: false
            });
        }

        case plusPlanAdmin.SET_TOO_FEW_LICENSES_ERROR: {
            return state.set('tooFewLicensesError', action.payload);
        }

        case plusPlanAdmin.SET_NEGATIVE_CHARGES_ERROR: {
            return state.set('negativeChargesError', action.payload);
        }

        case plusPlanAdmin.SET_TOO_MANY_LICENSES_ERROR: {
            return state.set('tooManyLicensesError', action.payload);
        }

        case plusPlanAdmin.SET_INVALID_NUMBER_ERROR: {
            return state.set('invalidNumberError', action.payload);
        }

        case plusPlanAdmin.SET_INVALID_RENEW_ERROR: {
            return state.set('invalidRenewError', action.payload);
        }

        case plusPlanAdmin.SET_LICENSES_BUY_AUTO_RENEW_ERROR: {
            return state.set('licensesBuyAutoRenewError', action.payload);
        }

        case plusPlanAdmin.SET_YEARLY_BILLING_AUTO_RENEW_ERROR: {
            return state.set('yearlyBillingAutoRenewError', action.payload);
        }

        case plusPlanAdmin.CREDIT_RECEIVED: {
            return state.merge({
                credit: action.payload.credit,
                prorationToken: action.payload.token
            });
        }

        case plusPlanAdmin.PAYMENT_SUCCESS: {
            if (state.get('activePanel') === 'placeOrder') {
                return state.set('paymentSuccess', true);
            }
            return state;
        }

        case plusPlanAdmin.PAYMENT_FAILED: {
            if (state.get('activePanel') === 'placeOrder') {
                return state.set('paymentFailed', true);
            }
            return state;
        }

        case plusPlanAdmin.EXPIRING_LICENSES_CHANGED: {
            return state.set('expiringLicensesChanged', true);
        }

        case plusPlanAdmin.RESET: {
            return onSubscriptionChange(action.payload, state);
        }

        case plusPlanAdmin.NO_CHANGES_ERROR: {
            return state.set('noChangesError', true);
        }

        case plusPlanAdmin.PAYMENT_PENDING: {
            return state.set('threeDSecureToken', action.payload);
        }

        case plusPlanAdmin.UPDATE_CARD_FROM_PLACE_ORDER: {
            return state.merge({
                activePanel: 'updatePaymentInfo',
                cardSaveNextPanel: 'placeOrder'
            });
        }

        default: {
            return state;
        }
    }
}
