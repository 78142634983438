import React, { useEffect, useState } from 'react';
import { Collapse } from '@material-ui/core';
import IProfile from '../../../interfaces/IProfile';
import { IHeaderProps } from '../../../components/_ReusableComponents/Header/Header/Header';
import FindService from '../Customer/FindService';
import Footer from '../Footer';
import { Reviews } from '../Customer/Review/Reviews';
import IActions from 'actions/IActions';
import { ILocationSearchResult } from 'interfaces/interfaces';
import Button from 'components/_ReusableComponents/Button/Button';
import BackendMethods from 'api/BackendMethods';
import { getSubdomain } from 'utils/subdomain';
import Styles from './DesktopReview.css';

interface IProps {
    profile: IProfile;
    actions: IActions;
    setHeaderProps: (props: Partial<IHeaderProps>) => void;
    isCustomer?: boolean;
}

export const DesktopReview: React.FC<IProps> = ({ profile, actions, setHeaderProps, isCustomer }) => {
    const [companyId, setCompanyId] = useState<number>(0);
    const [location, setLocation] = useState<ILocationSearchResult | null>(null);

    useEffect(() => {
        setLocation(null);
        (async () => {
            const locations: ILocationSearchResult[] = await BackendMethods.searchForLocations('', getSubdomain());
            setLocation(locations.find(item => item.companyId === companyId));
        })();
    }, [companyId]);
    return (
        <div className={Styles.root}>
            <div className={Styles.main}>
                <Collapse orientation="horizontal" in={true}>
                    <div className={Styles.findServiceColumn}>
                        <FindService
                            setCompanyId={setCompanyId}
                        />
                    </div>
                </Collapse>
                <Collapse orientation="horizontal" in={true}>
                    <div className={Styles.findServiceColumn}>
                        {location && (
                            <div className={Styles.reviewContent}>
                                <Button
                                    className={Styles.multiplyContent}
                                    onClick={() => {
                                        setLocation(null);
                                        setCompanyId(0);
                                    }}
                                >
                                    <img className={Styles.multiplyImage} src="/assets/multiply-icon.png" />
                                </Button>
                                <Reviews
                                    profile={profile}
                                    actions={actions}
                                    parentsLocation={location}
                                />
                            </div>
                        )}
                    </div>
                </Collapse>
            </div>
            <Footer />
        </div>
    );
};
