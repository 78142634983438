import * as React from 'react';
import * as anime from 'animejs';
import Phone from './Phone/Phone';
import Playback from '../Playback/Playback';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';

const Circles = require('./Circles.css');
const Styles = require('./RightAnimation.css');

interface IRightAnimationStates {
  animating?: boolean;
  completed?: boolean;
}

export default withTranslation()(class RightAnimation extends React.Component<
  WithTranslation,
  IRightAnimationStates
> {
  animation: any;
  ref: any;

  constructor(props) {
    super(props);
    this.state = {
      animating: false,
      completed: false
    };
    this.animation = null;
    this.ref = null;
  }

  componentDidMount() {
    this.animation = this.createAnimation();
  }

  componentWillUnmount() {
    this.animation = null;
    this.ref = null;
  }

  setRightAnimationRef = rightAnimation => {
    this.ref = { ...this.ref, rightAnimation };
  };
  setUpperDandelionMaskRef = upDandelionMask => {
    this.ref = { ...this.ref, upDandelionMask };
  };
  setUpperDandelionDetailRef = upDandelionDetail => {
    this.ref = { ...this.ref, upDandelionDetail };
  };
  setUpperDandelionDetailWideRef = upDandelionDetailWide => {
    this.ref = { ...this.ref, upDandelionDetailWide };
  };
  setIWantToMeetMaskRef = IWantToMeetMask => {
    this.ref = { ...this.ref, IWantToMeetMask };
  };
  setSusanRef = susan => {
    this.ref = { ...this.ref, susan };
  };
  setSuzukiSanRef = suzukiSan => {
    this.ref = { ...this.ref, suzukiSan };
  };
  setRajeshRef = rajesh => {
    this.ref = { ...this.ref, rajesh };
  };
  setNextWeekMaskRef = nextWeekMask => {
    this.ref = { ...this.ref, nextWeekMask };
  };
  setForOneHourMaskRef = forOneHourMask => {
    this.ref = { ...this.ref, forOneHourMask };
  };
  setNextWeekRef = nextWeek => {
    this.ref = { ...this.ref, nextWeek };
  };
  setForOneHourRef = forOneHour => {
    this.ref = { ...this.ref, forOneHour };
  };
  setCanYouMeetRef = canYouMeet => {
    this.ref = { ...this.ref, canYouMeet };
  };
  setCanYouMeetMaskRef = canYouMeetMask => {
    this.ref = { ...this.ref, canYouMeetMask };
  };
  setLowerDandelionMaskRef = lowerDandelionMask => {
    this.ref = { ...this.ref, lowerDandelionMask };
  };
  setLowerDandelionDetailRef = lowerDandelionDetail => {
    this.ref = { ...this.ref, lowerDandelionDetail };
  };
  setMeetingIsSetMaskRef = meetingIsSetMask => {
    this.ref = { ...this.ref, meetingIsSetMask };
  };
  setMeetingIsSetCircleRef = meetingIsSetCircle => {
    this.ref = { ...this.ref, meetingIsSetCircle };
  };
  createAnimation = () => {
    const showSettings = {
      opacity: 1,
      translateZ: 0,
      duration: 500,
      easing: 'easeInOutSine'
    };
    const circleSettings = {
      easing: 'easeInOutSine',
      rotate: 0.01,
      translateZ: 0,
      duration: 760
    };
    const white = '#ffffff';
    const blue = '#013253';
    const buttonSettings = {
      fill: blue,
      delay: function(el, i) {
        return i * 711.66;
      }
    };
    const buttonTextSettings = {
      fill: white,
      delay: function(el, i) {
        return i * 711.6;
      }
    };
    const timeline = anime
      .timeline({
        loop: false,
        autoplay: false
      })
      .add({
        targets: this.ref.rightAnimation,
        translateY: '-=940',
        translateZ: 0,
        rotate: 0.01,
        easing: 'linear',
        complete: () => {
          this.setState({
            animating: false,
            completed: true
          });
        },
        duration: 30000
      })
      .add({
        targets: this.ref.upDandelionDetail,
        translateY: '+1650',
        translateX: '+165',
        translateZ: 0,
        rotate: {
          value: '-=20',
          duration: 300,
          offset: '-=29520'
        },
        opacity: { value: 0, delay: 8050, duration: 100 },
        easing: 'linear',
        offset: '-=29520',
        duration: 29520
      })
      .add({
        targets: this.ref.upDandelionDetailWide,
        rotate: { value: '-=5', duration: 1, offset: '-=21528' },
        translateZ: 0,
        translateY: '+1650',
        opacity: [
          { value: 1, duration: 1, delay: 8040 },
          { value: 0, duration: 1, delay: 3996 }
        ],
        easing: 'linear',
        offset: '-=29520',
        duration: 29520
      })
      .add({
        targets: this.ref.upDandelionMask,
        translateY: '+=1650',
        translateZ: 0,
        rotate: 0.01,
        opacity: { value: 0, delay: 10980, duration: 1 },
        easing: 'linear',
        offset: '-=29520',
        duration: 29520
      })
      .add({
        targets: this.ref.IWantToMeetMask,
        ...circleSettings,
        rotate: [90, 262],
        offset: '-=29250'
      })
      .add({
        targets: this.ref.susan,
        ...showSettings,
        offset: '-=27960'
      })
      .add({
        targets: this.ref.suzukiSan,
        ...showSettings,
        offset: '-=26520'
      })
      .add({
        targets: this.ref.rajesh,
        ...showSettings,
        offset: '-=24480'
      })
      .add({
        targets: this.ref.nextWeek,
        ...showSettings,
        offset: '-=22740'
      })
      .add({
        targets: this.ref.nextWeekMask,
        ...circleSettings,
        rotate: [272, 95],
        offset: '-=22300'
      })
      .add({
        targets: this.ref.forOneHour,
        ...showSettings,
        offset: '-=21240'
      })
      .add({
        targets: this.ref.forOneHourMask,
        ...circleSettings,
        rotate: [244, 95],
        duration: 450,
        offset: '-=20700'
      })
      .add({
        targets: this.ref.canYouMeet,
        ...showSettings,
        offset: '-=19980'
      })
      .add({
        targets: this.ref.canYouMeetMask,
        ...circleSettings,
        rotate: [250, 82],
        duration: 450,
        offset: '-=19741'
      })
      .add({
        targets: '.WhoContainer',
        ...showSettings,
        offset: '-=17520'
      })
      .add({
        targets: '.PhoneImage',
        ...showSettings,
        rotate: [
          { value: '0.01', delay: 500 },
          { value: '0', delay: 500 },
          { value: '0.01', delay: 500 },
          { value: '0', delay: 500 },
          { value: '0.01', delay: 500 },
          { value: '0', delay: 500 },
          { value: '0.01', delay: 500 }
        ],
        offset: '-=16200'
      })
      .add({
        targets: '#Susan__FirstButton',
        fill: blue,
        offset: '-=12210'
      })
      .add({
        targets: '#Susan__FirstButton__Text',
        ...buttonTextSettings,
        offset: '-=12210'
      })
      .add({
        targets: [
          '#SuzukiSan__FirstButton',
          '#SuzukiSan__SecondButton',
          '#SuzukiSan__ThirdButton'
        ],
        ...buttonSettings,
        offset: '-=11973'
      })
      .add({
        targets: [
          '#SuzukiSan__FirstButton__Text',
          '#SuzukiSan__SecondButton__Text',
          '#SuzukiSan__ThirdButton__Text'
        ],
        ...buttonTextSettings,
        offset: '-=11973'
      })
      .add({
        targets: [
          '#Rajesh__FirstButton',
          '#Rajesh__SecondButton',
          '#Rajesh__ThirdButton'
        ],
        ...buttonSettings,
        offset: '-=11736'
      })
      .add({
        targets: [
          '#Rajesh__FirstButton__Text',
          '#Rajesh__SecondButton__Text',
          '#Rajesh__ThirdButton__Text'
        ],
        ...buttonTextSettings,
        offset: '-=11736'
      })
      .add({
        targets: [
          '#Dave__FirstButton',
          '#Dave__SecondButton',
          '#Dave__ThirdButton'
        ],
        ...buttonSettings,
        offset: '-=11499'
      })
      .add({
        targets: [
          '#Dave__FirstButton__Text',
          '#Dave__SecondButton__Text',
          '#Dave__ThirdButton__Text'
        ],
        ...buttonTextSettings,
        offset: '-=11499'
      })
      .add({
        targets: '#Susan__SecondButton',
        ...buttonSettings,
        offset: '-=11507'
      })
      .add({
        targets: '#Susan__SecondButton__Text',
        ...buttonTextSettings,
        offset: '-=11507'
      })
      .add({
        targets: '#Susan__ThirdButton',
        ...buttonSettings,
        offset: '-=9838'
      })
      .add({
        targets: '#Susan__ThirdButton__Text',
        ...buttonTextSettings,
        offset: '-=9838'
      })
      .add({
        targets: [this.ref.lowerDandelionDetail, this.ref.lowerDandelionMask],
        translateY: '+=550',
        translateZ: 0,
        rotate: [-10],
        easing: 'linear',
        offset: '-=9259',
        duration: 9259
      })
      .add({
        targets: '#voila',
        ...showSettings,
        offset: '-=5400'
      })
      .add({
        targets: [
          '#Summary__Dave',
          '#Summary__Susan',
          '#Summary__SuzukiSan',
          '#Summary__Rajesh'
        ],
        ...showSettings,
        delay: function(el, i) {
          return i * 350;
        },
        offset: '-=3703'
      })
      .add({
        targets: '#Summary__Meeting',
        ...showSettings,
        offset: '-=2222'
      })
      .add({
        targets: this.ref.meetingIsSetMask,
        ...circleSettings,
        rotate: [270, 95],
        offset: '-=1722'
      })
      .add({
        targets: this.ref.meetingIsSetCircle,
        ...showSettings,
        offset: '-=1722'
      })
      .add({
        targets: '#Summary__Tanbuu',
        ...showSettings,
        offset: '-=963'
      });
    return timeline;
  };

  pauseAnimation = () => {
    this.setState({ animating: false }, () => {
      this.animation.pause();
    });
  };

  playAnimation = () => {
    this.setState({ animating: true }, () => {
      this.animation.play();
    });
  };

  restartAnimation = () => {
    this.setState({ animating: true, completed: false }, () => {
      this.animation.restart();
    });
  };

  render(): JSX.Element {
    const { t, i18n: { language } } = this.props;
    return (
      <div
          className={classNames(Styles.Container, {
              [Circles.pl]: language === 'pl',
              [Styles.pl]: language === 'pl',
          })}
      >
        <div className={Styles.OuterContainer}>
          <div
            className={Styles.InnerContainer}
            onClick={this.pauseAnimation}
          >
            <div
              ref={this.setRightAnimationRef}
              className={Styles.RightAnimation}
            >
              <div className={Styles.Dave}>
                <img
                  className={Styles.characterDave}
                  src="/assets/landingAnimation/figure_3.svg"
                  alt=""
                />
                <div className={Styles.Name}>Dave:</div>
                <img
                  className={Circles.wantToMeetCircle}
                  src="/assets/landingAnimation/circle1.svg"
                  alt=""
                />
                <div className={Circles.wantToMeetWrapper}>
                  <div
                    ref={this.setIWantToMeetMaskRef}
                    className={Circles.wantToMeetMask}
                  />
                </div>
                <div className={Styles.IWantToMeet}>
                    {t('landing.wantToMeet')}
                </div>
              </div>
              <img
                className={Styles.DandelionUp}
                src="/assets/landingAnimation/dandelion1.svg"
                alt=""
              />
              <div className={Styles.topCover}>
                <div
                  ref={this.setUpperDandelionDetailRef}
                  className={Styles.topCoverDetail}
                />
                <div
                  ref={this.setUpperDandelionDetailWideRef}
                  className={Styles.topCoverDetailWide}
                />
                <div
                  ref={this.setUpperDandelionMaskRef}
                  className={Styles.topCoverMask}
                />
              </div>
              <div className={Styles.Susan}>
                <div ref={this.setSusanRef} style={{ opacity: 0 }}>Susan</div>
                <img
                  className={Styles.characterSusan}
                  src="/assets/landingAnimation/figure_4.svg"
                  alt=""
                />
              </div>
              <div className={Styles.SuzukiSan}>
                <div ref={this.setSuzukiSanRef} style={{ opacity: 0 }}>Suzuki-san</div>
                <img
                  className={Styles.characterSuzukiSan}
                  src="/assets/landingAnimation/figure_5.svg"
                  alt=""
                />
              </div>
              <div className={Styles.Rajesh}>
                <div ref={this.setRajeshRef} style={{ opacity: 0 }}>Rajesh</div>
                <img
                  className={Styles.characterRajesh}
                  src="/assets/landingAnimation/figure_2.svg"
                  alt=""
                />
              </div>
              <div className={Styles.DetailsContainer}>
                <div ref={this.setNextWeekRef} className={Styles.NextWeek}>
                    {t('landing.nextWeek')}
                </div>
                <img
                  className={Circles.nextWeekCircle}
                  src="/assets/landingAnimation/circle2.svg"
                  alt=""
                />
                <div className={Circles.nextWeekWrapper}>
                  <div
                    ref={this.setNextWeekMaskRef}
                    className={Circles.nextWeekMask}
                  />
                </div>
                <div ref={this.setForOneHourRef} className={Styles.ForOneHour}>
                    {t('landing.for1Hour')}
                </div>
                <img
                  className={Circles.forOneHourCircle}
                  src="/assets/landingAnimation/circle3.svg"
                  alt=""
                />
                <div className={Circles.forOneHourWrapper}>
                  <div
                    ref={this.setForOneHourMaskRef}
                    className={Circles.forOneHourMask}
                  />
                </div>
                <div ref={this.setCanYouMeetRef} className={Styles.CanYouMeet}>
                    <Trans
                        i18nKey="landing.canYouMeet"
                        components={{ br: <br /> }}
                    />
                </div>
                <img
                  className={Circles.canYouMeetCircle}
                  src="/assets/landingAnimation/circle4.svg"
                  alt=""
                />
                <div className={Circles.canYouMeetWrapper}>
                  <div
                    ref={this.setCanYouMeetMaskRef}
                    className={Circles.canYouMeetMask}
                  />
                </div>
              </div>
              <div className={Styles.PhoneContainer}>
                <Phone name="Susan" city="San Francisco" />
                <Phone name="Suzuki-san" city="Tokyo" />
                <Phone name="Rajesh" city="Atlanta" />
                <Phone name="Dave" city="San Francisco" />
              </div>
              <img
                className={Styles.DandelionDown}
                src="/assets/landingAnimation/dandelion2.svg"
                alt=""
              />
              <div className={Styles.bottomCover}>
                <div
                  ref={this.setLowerDandelionDetailRef}
                  className={Styles.bottomCoverDetail}
                />
                <div
                  ref={this.setLowerDandelionMaskRef}
                  className={Styles.bottomCoverMask}
                />
              </div>
              <div className={Styles.SummaryContainer}>
                <div className={Styles.Summary}>
                  <img
                    id="voila"
                    className={Styles.Voila}
                    src="/assets/landingAnimation/voila.svg"
                    alt="voila"
                    style={{ opacity: 0 }}
                  />
                  <div className={Styles.Names}>
                    <span id="Summary__Dave" style={{ opacity: 0 }}>Dave, </span>
                    <span id="Summary__Susan" style={{ opacity: 0 }}>Susan</span>
                  </div>
                  <div className={Styles.Names}>
                    <span id="Summary__SuzukiSan" style={{ opacity: 0 }}>Suzuki-san, </span>
                    <span id="Summary__Rajesh" style={{ opacity: 0 }}>Rajesh -</span>
                  </div>
                  <div className={Styles.MeetingIsSet}>
                    <span
                      id="Summary__Meeting"
                      className={Styles.MeetingIsSet}
                      style={{ opacity: 0 }}
                    >
                        <Trans
                            i18nKey="landing.meetingSet"
                            components={{ br: <br /> }}
                        />
                    </span>
                    <img
                      ref={this.setMeetingIsSetCircleRef}
                      className={Circles.setCircle}
                      src="/assets/landingAnimation/circle5.svg"
                      alt=""
                    />
                    <div className={Circles.setWrapper}>
                      <div
                        ref={this.setMeetingIsSetMaskRef}
                        className={Circles.setMask}
                      />
                    </div>
                  </div>
                  <img
                    id="Summary__Tanbuu"
                    className={Styles.Tanbuu}
                    src="/assets/landingAnimation/logo_text.png"
                    alt="tanbuu"
                    style={{ opacity: 0 }}
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.animating !== true && this.state.completed === false && (
            <Playback onClick={this.playAnimation} iconType="play" />
          )}
          {this.state.animating !== true && this.state.completed === true && (
            <Playback onClick={this.restartAnimation} iconType="restart" />
          )}
        </div>
      </div>
    );
  }
});
