import { useEffect, useState } from 'react';
import BackendMethods from '../api/BackendMethods';
import { IUploadInfo } from '../components/ManageGroupComponents/GroupAvatar/GroupAvatar';
const config = require('CONFIG');

declare global {
    interface Window {
        cloudinary: any;
    }
}

export default function useCloudinary(
    uploadCallback: (info: IUploadInfo) => void,
    getSignature: (params: string) => Promise<string>,
) {
    const [widget, setWidget] = useState(null);

    useEffect(() => {
        setWidget(window.cloudinary.createUploadWidget({
            cloudName: config.cloudinaryCloudName,
            apiKey: config.cloudinaryAPIKey,
            sources: ['local', 'url', 'dropbox', 'facebook', 'instagram'],
            multiple: false,
            resourceType: 'image',
            maxFileSize: 200 * 1024,
            uploadSignature: async (callback, params) => {
                const paramsQueryString = new URLSearchParams(params).toString();
                const signature = await getSignature(paramsQueryString);
                callback(signature);
            }
        }, (error, result) => {
            if (!error && result.event === 'success') {
                const newUploadInfo = {
                    id: result.info.public_id,
                    url: result.info.secure_url,
                    signature: result.info.signature,
                    version: result.info.version
                };
                uploadCallback(newUploadInfo);
            }
        }));
    }, []);

    return widget;
}
