import * as React from 'react';
import IActions from 'actions/IActions';
import PaymentResult from '../../../components/PaymentResultComponents/PaymentResult';
import containersDispatchToProps from '../../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../../utils/containersMapStateToProps';
import ILocation from '../../../interfaces/location/ILocation';
import Header from '../../../components/_ReusableComponents/Header/Header/Header';
const connect = require('react-redux').connect;

interface IPaymentFailedPageProps {
    plusPlanPayment: any;
    data: any;
    actions: IActions;
    location: ILocation;
    plusPlanAdmin: any;
    history: any;
}

class PaymentFailedPage extends React.Component<IPaymentFailedPageProps, void> {
    render() {
        return (
            <div>
                <Header {...this.props} />
                <PaymentResult isSuccess={false} {...this.props} />
            </div>
        );
    }
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(PaymentFailedPage);
