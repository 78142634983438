// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".paper__uKZqv {\n    padding: 24px;\n    margin: 16px;\n}\n\n.title__10P6d {\n    font-size: 20px;\n    text-align: center;\n    margin-bottom: 16px;\n}\n\n@media (min-width: 1250px) {\n\n.title__10P6d {\n        text-align: left\n}\n    }\n\n.buttons__4LbYJ {\n    margin-top: 32px;\n    display: flex;\n    justify-content: center;\n}\n\n@media (min-width: 1250px) {\n\n.buttons__4LbYJ {\n        justify-content: flex-end\n}\n    }\n\n.yesButton__LVZsq {\n    margin-right: 38px;\n}\n", "",{"version":3,"sources":["CancelMeetingModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AAIvB;;AAHI;;AAJJ;QAKQ;AAER;IADI;;AAGJ;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;AAI3B;;AAHI;;AAJJ;QAKQ;AAER;IADI;;AAGJ;IACI,kBAAkB;AACtB","file":"CancelMeetingModal.css","sourcesContent":[".paper {\n    padding: 24px;\n    margin: 16px;\n}\n\n.title {\n    font-size: 20px;\n    text-align: center;\n    margin-bottom: 16px;\n    @media (min-width: 1250px) {\n        text-align: left;\n    }\n}\n\n.buttons {\n    margin-top: 32px;\n    display: flex;\n    justify-content: center;\n    @media (min-width: 1250px) {\n        justify-content: flex-end;\n    }\n}\n\n.yesButton {\n    margin-right: 38px;\n}\n"]}]);
// Exports
exports.locals = {
	"paper": "paper__uKZqv",
	"title": "title__10P6d",
	"buttons": "buttons__4LbYJ",
	"yesButton": "yesButton__LVZsq"
};
module.exports = exports;
