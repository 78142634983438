import React from 'react';
import classNames from 'classnames';
import IDateRangeString from 'interfaces/IDateRangeString';
import IDateRange from 'interfaces/IDateRange';
import moment from 'moment';
import Button from '../Button/Button';
import { WithTranslation, withTranslation } from 'react-i18next';
import i18next from 'i18next';
import IPreviewMeetingDate from 'interfaces/IPreviewMeetingDate';
const $ = require('jquery');
declare let window: any;
window.jQuery = $;
require('jquery-ui-bundle');
const Styles = require('./DatePicker.css');

interface Props extends WithTranslation {
    timezone: string;
    dateRange: IDateRange;
    country: string;
    setDateRange: (dateRange: IDateRange) => void;
    closeSelect: () => void;
    previewMeetingDate: IPreviewMeetingDate|boolean;
    isDispalyPreviewMeetingDate: boolean;
}

interface State {}

interface $ {
    datepicker(): $;
    datepicker(settings: Object): $;
}

$.datepicker.regional.pl = {
    closeText: 'Zamknij',
    prevText: '&#x3C;Poprzedni',
    nextText: 'Następny&#x3E;',
    currentText: 'Dziś',
    monthNames: [ 'Styczeń','Luty','Marzec','Kwiecień','Maj','Czerwiec',
        'Lipiec','Sierpień','Wrzesień','Październik','Listopad','Grudzień' ],
    monthNamesShort: [ 'Sty','Lu','Mar','Kw','Maj','Cze',
        'Lip','Sie','Wrz','Pa','Lis','Gru' ],
    dayNames: [ 'Niedziela','Poniedziałek','Wtorek','Środa','Czwartek','Piątek','Sobota' ],
    dayNamesShort: [ 'Nie','Pn','Wt','Śr','Czw','Pt','So' ],
    dayNamesMin: [ 'N','Pn','Wt','Śr','Cz','Pt','So' ],
    weekHeader: 'Tydz',
    dateFormat: 'dd.mm.yy',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: '',
};

i18next.on('languageChanged', (lng) => {
    $.datepicker.setDefaults($.datepicker.regional[lng]);
});

export default withTranslation()(class DatePicker extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            //states
        };
    }
    dateRange: IDateRangeString;
    cur: number;
    prv: number;
    firstSelect: boolean = false;

    componentDidMount() {
        const { i18n, country } = this.props;
        $.datepicker.regional[i18n.language].firstDay = country === 'us' || country === 'ca'? 0: 1;
        $.datepicker.setDefaults($.datepicker.regional[i18n.language]);
        const containerRef = this.refs["container"]; // tslint:disable-line
        const setDateRange = this.props.setDateRange;
        $.datepicker._defaults.onAfterUpdate = null;
        const datepicker__updateDatepicker = $.datepicker._updateDatepicker;
        $.datepicker._updateDatepicker = function(inst) {
            datepicker__updateDatepicker.call(this, inst);
            const onAfterUpdate = this._get(inst, 'onAfterUpdate');
            if (onAfterUpdate) {
                onAfterUpdate.apply(inst.input ? inst.input[0] : null, [
                    inst.input ? inst.input.val() : '',
                    inst
                ]);
            }
        };

        const dateRange: IDateRange = this.props.dateRange;
        const { timezone } = this.props;
        this.cur = moment(dateRange.dateTo).valueOf();
        this.prv = moment(dateRange.dateFrom).valueOf();
        const that = this;

        const defaultDate = moment(moment().tz(timezone).format('YYYY-MM-DD')).toDate();

        $(containerRef).datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            minDate: defaultDate,
            beforeShowDay: date => {
                const additonalClass = moment.tz(moment(date).format('YYYY-MM-DD'), timezone).isSame(moment().tz(timezone), 'day')
                    ? 'UIDatePickerTodayDate'
                    : '';
                let dotStyle = '';
                if(
                    typeof this.props.previewMeetingDate === 'object' &&
                    moment.tz(moment(date).format('YYYY-MM-DD'), timezone).isSameOrAfter(moment().tz(timezone), 'day')
                ) {
                    if(moment(date).tz(timezone).isSameOrBefore(moment().add(3, 'months'), 'day')) {
                        const dateRating = this.props.isDispalyPreviewMeetingDate ?
                            this.props.previewMeetingDate.calendar.find(preDate => moment(preDate.date, 'YYYY-MM-DD').isSame(moment(date, 'YYYY-MM-DD'))):
                            null;
                        dotStyle = dateRating ? dateRating.score : 'UIDatePickerCalculatingDate';
                    } else {
                        dotStyle = 'UIDatePickerCalculatingDate';
                    }
                }
                const relativeItem = 'relativeItem';
                const timestamp = moment.tz({
                    year: date.getFullYear(),
                    month: date.getMonth(),
                    day: date.getDate()
                }, timezone).valueOf();
                return [
                    true,
                    `${
                        timestamp >= Math.min(that.prv, that.cur) &&
                        timestamp <= Math.max(that.prv, that.cur)
                            ? 'DateRangeSelected'
                            : ''
                    } ${additonalClass} ${dotStyle} ${relativeItem}`
                ];
            },
            onSelect: function(dateText, inst) {
                that.firstSelect = !that.firstSelect;
                that.prv = that.cur;
                that.cur = moment.tz({
                    year: inst.selectedYear,
                    month: inst.selectedMonth,
                    day: inst.selectedDay,
                }, timezone).valueOf();

                if (that.firstSelect === true) {
                    const rangeObject = {
                        dateFrom: moment.tz(that.cur, timezone).startOf('day').toISOString(),
                        dateTo: moment.tz(that.cur, timezone).endOf('day').toISOString(),
                    };
                    setDateRange(rangeObject);
                } else {
                    const rangeObject = {
                        dateFrom: moment.tz(Math.min(that.prv, that.cur), timezone)
                            .startOf('day').toISOString(),
                        dateTo: moment.tz(Math.max(that.prv, that.cur), timezone)
                            .endOf('day').toISOString(),
                    };
                    setDateRange(rangeObject);
                }
            }
        });

        $(containerRef).datepicker('setDate', moment(moment(dateRange.dateFrom).tz(timezone).format('YYYY-MM-DD')).toDate());
    }

    refreshDatePicker() {
        const containerRef = this.refs["container"]; // tslint:disable-line
        const dateRange: IDateRange = this.props.dateRange;
        this.cur = moment(dateRange.dateTo).valueOf();
        this.prv = moment(dateRange.dateFrom).valueOf();
        $(containerRef).datepicker('refresh');
    }

    componentDidUpdate() {
        this.refreshDatePicker();
    }

    render() {
        const classDef = classNames(Styles.DatePicker);
        const classDefFromToDates = classNames(Styles.FromToDates);
        const dateRange: IDateRange = this.props.dateRange;
        const { t, i18n, timezone } = this.props;
        const format = i18n.language === 'pl' ? 'DD.MM.Y' : 'MM/DD/Y';
        return (
            <div id="DatePicker" className={classDef}>
                <div>
                    <div className={classDefFromToDates}>
                        <span>
                            {t('new.from')}:{' '}
                            <span data-testid="date-from">
                                {moment.tz(dateRange.dateFrom, timezone).format(format)}
                            </span>
                        </span>
                        <span>
                            {t('new.to')}:{' '}
                            <span data-testid="date-to">
                                {moment.tz(dateRange.dateTo, timezone).format(format)}
                            </span>
                        </span>
                    </div>
                    <div ref="container" />
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={this.props.closeSelect}
                        data-testid="done-button"
                    >
                        {t('new.done')}
                    </Button>
                </div>
            </div>
        );
    }
});
