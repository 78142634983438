// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PlanSummary__3eTPI {\n    padding: 0 16px;\n}\n", "",{"version":3,"sources":["PlanSummary.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB","file":"PlanSummary.css","sourcesContent":[".PlanSummary {\n    padding: 0 16px;\n}\n"]}]);
// Exports
exports.locals = {
	"PlanSummary": "PlanSummary__3eTPI"
};
module.exports = exports;
