import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, IconButton } from '@material-ui/core';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import Button from '../../../components/_ReusableComponents/Button/Button';
import Select from '../../../components/_ReusableComponents/Select/Select';
import * as R from 'ramda';
import { IResource } from './Resources';
import classNames from 'classnames';
import { allCountries, Country } from 'country-telephone-data';
import { getTimezone } from 'countries-and-timezones';
import moment from 'moment';
import PhoneNumber from 'awesome-phonenumber';
import Autocomplete from '../../../components/_ReusableComponents/Autocomplete/Autocomplete';
import TimeZoneSelect from '../../../components/_ReusableComponents/TimeZoneSelect/TimeZoneSelect';
import BackendMethods from '../../../api/BackendMethods';
import useCloudinary from '../../../utils/useCloudinary';
const styles = require('./Sites.css');

interface ILocationResource {
    id: number;
    quantity: number;
}

export interface INewLocation {
    name: string;
    address: string;
    countryCode: string;
    phone: string;
    timezone: string;
    resources: ILocationResource[];
    photo: string | null;
    country: string;
}

export interface ILocation extends INewLocation {
    id: number;
}

const defaultCountry = allCountries.find(R.propEq(
    'iso2',
    getTimezone(moment.tz.guess()).country.toLowerCase(),
));

export default function Sites() {
    const { t } = useTranslation();
    const [sites, setSites] = useState<ILocation[] | null>(null);
    const [allResources, setAllResources] = useState<IResource[] | null>(null);
    useEffect(() => {
        (async () => {
            const [newSites, newResources] = await Promise.all([
                BackendMethods.getLocations(),
                BackendMethods.getResources(),
            ]);
            setSites(newSites);
            setAllResources(newResources);
        })();
    }, []);
    const [selectedSite, setSelectedSite] = useState<number | 'new' | null>(null);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [photoUrl, setPhotoUrl] = useState<string | null>(null);
    const widget = useCloudinary((uploadInfo) => {
        setPhotoUrl(uploadInfo.url);
    }, BackendMethods.getSbCloudinarySignature);
    const [country, setCountry] = useState<Country>(defaultCountry);
    const [phone, setPhone] = useState('');
    const getCodeLabel = (country2: Country) => `${country2.iso2.toUpperCase()} +${country2.dialCode}`;
    const [timezone, setTimezone] = useState(moment.tz.guess());
    const [editingTimezone, setEditingTimezone] = useState(false);
    const [resources, setResources] = useState<ILocationResource[]>([]);
    const [newResource, setNewResource] = useState<number | null>(null);
    const [quantity, setQuantity] = useState('');
    const [deleteOpen, setDeleteOpen] = useState(false);
    const loadData = (site?: ILocation) => {
        if (site) {
            setSelectedSite(site.id);
            setName(site.name);
            setAddress(site.address);
            setPhotoUrl(site.photo || null);
            const country2 = allCountries.find((x) => x.dialCode === site.countryCode.substring(1));
            setCountry(country2);
            setPhone(new PhoneNumber(site.phone, country2.iso2).getNumber('national'));
            setTimezone(site.timezone);
            setResources(site.resources);
        } else {
            setSelectedSite('new');
            setName('');
            setAddress('');
            setPhotoUrl(null);
            setCountry(defaultCountry);
            setPhone('');
            setTimezone(moment.tz.guess());
            setResources([]);
        }
        setNewResource(null);
        setQuantity('');
    };
    if (!sites || !allResources) {
        return null;
    }
    return (
        <div className={styles.root}>
            <div className={styles.left}>
                <div className={styles.addHeader}>
                    {t('small.sites')}
                    <Button
                        variant="contained"
                        className={styles.addButton}
                        onClick={() => loadData()}
                    >
                        <img src="/assets/plus-white.svg" alt="" className={styles.addIcon} />
                        {t('small.addSite')}
                    </Button>
                </div>
                <div className={styles.elements}>
                    {sites.map((site) => (
                        <Button
                            key={site.id}
                            variant="contained"
                            className={classNames(styles.element, styles.elementButton, {
                                [styles.elementActive]: site.id === selectedSite,
                            })}
                            onClick={() => loadData(site)}
                        >
                            {site.name}
                        </Button>
                    ))}
                </div>
            </div>
            <div className={styles.right}>
                {selectedSite !== null && (
                    <>
                        <div className={styles.formColumns}>
                            <div className={styles.formLeft}>
                                <TextField
                                    label={t('small.name')}
                                    value={name}
                                    onChange={({ target }) => setName(target.value)}
                                    className={styles.textField}
                                />
                                <TextField
                                    label={t('small.address')}
                                    value={address}
                                    onChange={({ target }) => setAddress(target.value)}
                                    className={styles.textField}
                                />
                                <div className={styles.phoneRow}>
                                    <Autocomplete
                                        value={country}
                                        getOptionLabel={getCodeLabel}
                                        onChange={(event, newValue) => (
                                            setCountry(newValue)
                                        )}
                                        options={allCountries}
                                        className={styles.country}
                                        disableClearable
                                    />
                                    <TextField
                                        value={phone}
                                        onChange={({ target }) => (
                                            setPhone(target.value)
                                        )}
                                        label={t('small.registrationPhoneNumber')}
                                        className={styles.phone}
                                    />
                                </div>
                            </div>
                            <div className={styles.formRight}>
                                <div
                                    className={styles.photoContainer}
                                    onClick={() => widget.open()}
                                >
                                    {photoUrl ? (
                                        <>
                                            <img src={photoUrl} alt="" className={styles.photo} />
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setPhotoUrl(null);
                                                }}
                                                className={styles.deleteButton}
                                                size="large">
                                                <img src="/assets/message-close.svg" alt="" />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <div className={styles.addPhoto}>
                                            {t('small.addPhoto')}
                                        </div>
                                    )}
                                </div>
                                <div className={styles.photoDesc}>
                                    {t('small.sizeTip')}
                                    <br />
                                    {t('small.photoVisible')}
                                </div>
                            </div>
                        </div>
                        <div className={styles.timezone}>
                            <div className={styles.timezoneTop}>
                                <div>
                                    {t('settings.timezone')}
                                    {': '}
                                    <span data-testid="default-timezone">
                                        {timezone}
                                    </span>
                                    <IconButton
                                        className={styles.editButton}
                                        onClick={() => setEditingTimezone(true)}
                                        data-testid="edit-timezone"
                                        size="large">
                                        <img
                                            className={styles.penIcon}
                                            src="/assets/pen.svg"
                                        />
                                    </IconButton>
                                </div>
                                <Button
                                    className={styles.findButton}
                                    onClick={() => setTimezone(moment.tz.guess())}
                                >
                                    {t('settings.findTimezone')}
                                </Button>
                            </div>
                            {editingTimezone && (
                                <TimeZoneSelect
                                    onChange={(newTimezone) => {
                                        setTimezone(newTimezone);
                                        setEditingTimezone(false);
                                    }}
                                    onBlur={() => setEditingTimezone(false)}
                                />
                            )}
                        </div>
                        <div className={styles.resourcesHeader}>
                            {t('small.availableResources')}
                        </div>
                        <div className={styles.selectRow}>
                            <Select
                                label={t('small.resourceName')}
                                value={newResource ? {
                                    value: newResource,
                                    label: allResources.find(R.propEq('id', newResource)).name,
                                } : null}
                                onChange={option => setNewResource(option ? option.value : null)}
                                options={allResources.filter((resource) => (
                                    !resources.find(R.propEq('id', resource.id))
                                )).map((resource) => ({
                                    value: resource.id,
                                    label: resource.name,
                                }))}
                                className={styles.select}
                                isClearable
                            />
                            <TextField
                                label={t('small.quantity')}
                                value={quantity}
                                onChange={({ target }) => setQuantity(target.value)}
                                className={styles.quantity}
                            />
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setResources([
                                        ...resources,
                                        {
                                            id: newResource,
                                            quantity: Number(quantity),
                                        },
                                    ]);
                                    setNewResource(null);
                                    setQuantity('');
                                }}
                                disabled={newResource === null || !(Number(quantity) > 0)}
                            >
                                {t('small.add')}
                            </Button>
                        </div>
                        <div className={styles.elements}>
                            {resources.map((siteResource) => {
                                const resource = allResources.find(R.propEq('id', siteResource.id));
                                return (
                                    <div className={styles.element} key={resource.id}>
                                        {`${resource.name}, ${siteResource.quantity} ${t('small.pcs')}`}
                                        <IconButton
                                            size="small"
                                            className={styles.deleteButton}
                                            onClick={() => setResources(resources.filter((x) => (
                                                x.id !== resource.id
                                            )))}
                                        >
                                            <img
                                                src="/assets/delete-blue.svg"
                                                alt=""
                                                className={styles.deleteIcon}
                                            />
                                        </IconButton>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.saveRow}>
                            {selectedSite !== 'new' && (
                                <Button
                                    variant="text"
                                    onClick={() => setDeleteOpen(true)}
                                >
                                    {t('small.deleteSite')}
                                </Button>
                            )}
                            <Button
                                variant="text"
                                onClick={() => setSelectedSite(null)}
                                className={styles.discardChanges}
                            >
                                {t('small.discardChanges')}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    const newSite: INewLocation = {
                                        name,
                                        address,
                                        countryCode: `+${country.dialCode}`,
                                        phone: new PhoneNumber(phone, country.iso2).getNumber('significant'),
                                        timezone,
                                        resources,
                                        photo: photoUrl,
                                        country: getTimezone(timezone).country.toLowerCase(),
                                    };
                                    if (selectedSite === 'new') {
                                        const { id } = await BackendMethods.addLocation(newSite);
                                        setSites([
                                            ...sites,
                                            { ...newSite, id },
                                        ]);
                                    } else {
                                        await BackendMethods.updateLocation(selectedSite, newSite);
                                        setSites(sites.map((x) => (
                                            x.id === selectedSite ? {
                                                ...newSite,
                                                id: selectedSite,
                                            } : x
                                        )));
                                    }
                                    setSelectedSite(null);
                                }}
                                disabled={!name || !address || !phone}
                            >
                                {t('small.save')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                classes={{ paper: styles.dialog }}
            >
                <div className={styles.dialogText}>
                    {t('small.confirmSiteDelete')}
                </div>
                <div className={styles.dialogActions}>
                    <Button
                        className={styles.dialogButton}
                        onClick={async () => {
                            if (selectedSite !== 'new') {
                                await BackendMethods.deleteLocation(selectedSite);
                                setSites(sites.filter((x) => (
                                    x.id !== selectedSite
                                )));
                                setSelectedSite(null);
                                setDeleteOpen(false);
                            }
                        }}
                    >
                        {t('small.delete')}
                    </Button>
                    <Button
                        variant="contained"
                        className={styles.dialogButton}
                        onClick={() => setDeleteOpen(false)}
                    >
                        {t('common.cancel')}
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}
