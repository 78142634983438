import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
const styles = require('./ForSales.css');

export default function ForSales() {
    const { t } = useTranslation();
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                {t('landing.forSales')}
            </div>
            <div className={styles.element}>
                <div>
                    <div className={classNames(styles.subheader, styles.subheaderLeft)}>
                        {t('landing.convertFaster')}
                    </div>
                    <div className={styles.description}>
                        Tanbuu invites include an actionable link your leads can click on
                        to select a time to meet. Hence, they can convert right away.
                        Just to be clear: your prospects do not need to register to
                        Tanbuu to accept your invitation.
                    </div>
                </div>
                <div className={styles.image1} />
            </div>
            <div className={styles.element}>
                <div className={styles.image2} />
                <div>
                    <div className={classNames(styles.subheader, styles.subheaderRight)}>
                        {t('landing.sendMany')}
                    </div>
                    <div className={styles.description}>
                        Available meeting slots visible to any of your prospects are
                        up to date at every instant. You can send as many invites
                        as you need for the same day or week. Whenever a meeting
                        gets scheduled, the slots proposed for other meetings get
                        re-evaluated, and only good slots will remain.
                    </div>
                </div>
            </div>
            <div className={styles.element}>
                <div>
                    <div className={classNames(styles.subheader, styles.subheaderLeft2)}>
                        {t('landing.connectMore')}
                    </div>
                    <div className={styles.description}>
                        If your prospect does not respond for some time,
                        Tanbuu will gently remind them to reply to your invite.
                        Rest assured: Tanbuu will never send reminders at night.
                    </div>
                </div>
                <div className={styles.image3} />
            </div>
        </div>
    );
}
