// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".normalText__3LzM- {\n    margin: 0;\n}\n\n.boldText__1BGV0 {\n    font-weight: 400 !important;\n}\n\n.inputField__3Rz1u {\n    margin: 8px 0 10px;\n}\n\n.helperText__38MDb {\n    font-size: 14px;\n    margin: 23px 0 0 0;\n}\n\n.linkText__2SRpi {\n    text-decoration: none;\n    font-size: 14px;\n}\n\n.errorMessageContent__2Mnzr {\n    margin: 20px 0 36px;\n    height: 20px;\n}\n\n.errorFont__1Fj1b {\n    margin: 0;\n    font-size: 14px;\n    color: #f0161a;\n}\n\n.btnGroup__1wXlG {\n    margin: 0 6px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.continueButton__2ba3S {\n    background-color: #1488a8;\n    color: white;\n}\n\n.continueButton__2ba3S:hover {\n    background-color: #29b;\n    color: white;\n}\n", "",{"version":3,"sources":["ICloudCalendarLogin.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB","file":"ICloudCalendarLogin.css","sourcesContent":[".normalText {\n    margin: 0;\n}\n\n.boldText {\n    font-weight: 400 !important;\n}\n\n.inputField {\n    margin: 8px 0 10px;\n}\n\n.helperText {\n    font-size: 14px;\n    margin: 23px 0 0 0;\n}\n\n.linkText {\n    text-decoration: none;\n    font-size: 14px;\n}\n\n.errorMessageContent {\n    margin: 20px 0 36px;\n    height: 20px;\n}\n\n.errorFont {\n    margin: 0;\n    font-size: 14px;\n    color: #f0161a;\n}\n\n.btnGroup {\n    margin: 0 6px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.continueButton {\n    background-color: #1488a8;\n    color: white;\n}\n\n.continueButton:hover {\n    background-color: #29b;\n    color: white;\n}\n"]}]);
// Exports
exports.locals = {
	"normalText": "normalText__3LzM-",
	"boldText": "boldText__1BGV0",
	"inputField": "inputField__3Rz1u",
	"helperText": "helperText__38MDb",
	"linkText": "linkText__2SRpi",
	"errorMessageContent": "errorMessageContent__2Mnzr",
	"errorFont": "errorFont__1Fj1b",
	"btnGroup": "btnGroup__1wXlG",
	"continueButton": "continueButton__2ba3S"
};
module.exports = exports;
