import React from 'react';
import Styles from './MyCheckBox.css';

interface MyCheckBoxProps {
    isChecked: boolean;
    label: string;
}
export const MyCheckBox = ({ label, isChecked }: MyCheckBoxProps) => {
    return (
        <div
            className={Styles.checkBox}
        >
            {isChecked && (
                <img src="/assets/check_icon_thin.svg" className={Styles.checkIcon} alt="maintenance" />
            )}
            <div className={Styles.label}>
                {label}
            </div>
        </div>
    );
};
