import React, {useContext, useRef, useState, useEffect} from 'react';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import * as R from 'ramda';
import IMeeting from '../../../interfaces/IMeeting';
import MeetingTitle from '../SingleMeeting/MeetingTitle/MeetingTitle';
import IPropositionAnswer from '../../../interfaces/IPropositionAnswer';
import MeetingParticipants from 'components/MeetingPageComponents/SingleMeeting/MeetingParticipants/MeetingParticipants';
import CalendarEventsHorizontalScrollBar from 'components/MeetingPageComponents/CalendarEventsHorizontalScrollBar/CalendarEventsHorizontalScrollBar';
import ConfirmScheduledDateBox from 'components/MeetingPageComponents/ConfirmScheduledDateBox/ConfirmScheduledDateBox';
import ILocation from '../../../interfaces/location/ILocation';
import IActions from '../../../actions/IActions';
import Header from '../../_ReusableComponents/Header/Header/Header';
import MeetingOptions from '../MeetingOptions/MeetingOptions';
import getMeetingMessage from '../../../utils/getMeetingMessage';
import IProposition from '../../../interfaces/IProposition';
import Button from '../../_ReusableComponents/Button/Button';
import TextField from '../../_ReusableComponents/TextField/TextField';
import {Collapse, Tooltip} from '@material-ui/core';
import BackendMethods from '../../../api/BackendMethods';
import { useTranslation } from 'react-i18next';
import MobileDateTimePicker from '@material-ui/lab/MobileDateTimePicker';
import GlobalContext from '../../../GlobalContext';
import TimeZoneSelect from '../../_ReusableComponents/TimeZoneSelect/TimeZoneSelect';
import { SortBy } from 'elementsSelectors/_reusable/Meetings';
import { SortMenu } from 'containers/MainPage/SortMenu/SortMenu';
import { FilterMenu } from 'containers/MainPage/FilterMenu/FilterMenu';
import ConfirmScheduledDateBoxSkeleton from '../ConfirmScheduledDateBox/ConfirmScheduledDateBoxSkeleton';
import RenderIfVisible from 'react-render-if-visible';
const styles = require('./MeetingAnswers.css');

interface IViewProposition extends IProposition {
    removed?: boolean;
}

interface Props {
    meeting: any;
    currentMeeting: IMeeting;
    actions: IActions;
    propositionAnswers: IPropositionAnswer[];
    setPropositionAnswers: React.Dispatch<React.SetStateAction<IPropositionAnswer[] | null>>;
    location: ILocation;
    setAnswer: (answer: IPropositionAnswer) => void;
    setActivePropositionId: (id: number) => void;
    activePropositionId: number;
    history: any;
    email: string;
    timezone: string;
    allAnswers: IProposition[] | null;
    startDate: Moment | null;
    setStartDate: (date: Moment) => void;
    setSetModalDate: (date: string) => void;
    onSetDate: () => void;
    goBack: () => void;
    propositions: IViewProposition[];
}

export default function MeetingAnswers(props: Props) {
    const { t } = useTranslation();
    const {
        currentMeeting: meeting,
        email,
        timezone,
        propositionAnswers,
        allAnswers,
        propositions,
        setPropositionAnswers
    } = props;
    const [sortBy, setSortBy] = useState<SortBy>(SortBy.ByRating);
    const [acceptedOnTop, setAcceptedOnTop] = useState<boolean>(true);
    const [isWorkHoursOnly, setIsWorkHoursOnly] = useState<boolean>(false);
    const[isNonConflictingOnly, setIsNonConflictingOnly] = useState<boolean>(false);
    const [propositionsToShow, setPropositionsToShow] = useState<IViewProposition[]>([]);

    const { participants } = meeting;
    const { declined, timezone: meetingTimezone } = participants.find(R.whereEq({ email }));
    const allDeclined = R.all(
        participant => participant.email === email || participant.declined,
        meeting.participants
    );
    const showPropositions = !declined && !allDeclined && (meeting.hasPropositions || allAnswers !== null);
    const propositionRefs = useRef<Record<number, HTMLDivElement>>({});
    const filterPropositions = (midProposittions: IViewProposition[]) => {
        if (isWorkHoursOnly === isNonConflictingOnly) {
            return [...midProposittions.filter((proposition) => proposition.conflicts.length === 0)];
        }
        if (isWorkHoursOnly) {
            return [...midProposittions.filter((proposition) => !proposition.conflicts.includes('WORKWEEK'))];
        }
        return [...midProposittions.filter((proposition) => !proposition.conflicts.includes('CALENDAR'))];
    };

    useEffect(() => {
        if(!(propositions && propositions.length>0)) {
            return ;
        }
        const allUnseen = propositions.every((x) => !x.seen || x.removed);
        let midProposittions: IViewProposition[];
        midProposittions = [...propositions];
        if (isWorkHoursOnly || isNonConflictingOnly) {
            midProposittions = filterPropositions(midProposittions);
        }
        sortBy === SortBy.ByRating ?
            midProposittions.sort(function(a, b) {
                if(a.score > b.score) {
                    return -1;
                } else if(a.score < b.score) {
                    return 1;
                }
                if(moment(a.earliest).isBefore(moment(b.earliest))) {
                    return -1;
                }
                return 1;
            }) :
            midProposittions.sort((a, b) => (moment(a.earliest).isBefore(moment(b.earliest))) ? -1 : 1);
        if (acceptedOnTop) {
            const acceptedPropositions = [...midProposittions.filter((proposition) => proposition.acceptedBy.length>0),
                ...midProposittions.filter((proposition) => proposition.acceptedBy.length===0)];
            midProposittions = allAnswers || acceptedPropositions.filter((x) => (
                x.seen || allUnseen || x.removed
            ));
        } else {
            midProposittions = allAnswers || midProposittions.filter((x) => (
                x.seen || allUnseen || x.removed
            ));
        }
        if (!propositions || propositions.length === 0) {
            setPropositionAnswers([]);
        } else {
            setPropositionAnswers(midProposittions.map(proposition => {
                return {
                    ...R.pick(['id', 'earliest', 'latest'], proposition),
                    accepted: false,
                    rejected: false,
                };
            }));
        }
        setPropositionsToShow(midProposittions);
        if(!props.activePropositionId && midProposittions.length > 0) {
            props.setActivePropositionId(midProposittions[0].id);
        }
    }, [propositions, acceptedOnTop, sortBy, isWorkHoursOnly, isNonConflictingOnly]);
    const { profile } = useContext(GlobalContext);
    const [timezoneSelectOpen, setTimezoneSelectOpen] = useState(false);
    const onTimezoneChange = async (newTimezone) => {
        setTimezoneSelectOpen(false);
        const newMeeting = await BackendMethods.setMeetingTimezone(meeting.id, newTimezone);
        props.actions.updateMeeting(newMeeting, profile.email);
    };
    const renderMeetingAnswers = () => {
        if(propositionsToShow.length>0 || allAnswers) {
            return propositionsToShow.map((proposition, index) => {
                const propositionAnswer = propositionAnswers.find(s => s.id === proposition.id) || {
                    ...R.pick(['id', 'earliest', 'latest'], proposition),
                    accepted: false,
                    rejected: false,
                };
                const nextScheduleId = propositionsToShow.find((x, i) => (
                    i > index && !x.removed
                ))?.id;
                const setNextCandidateActive = () => {
                    if (nextScheduleId !== undefined) {
                        props.setActivePropositionId(nextScheduleId);
                        const fixedElementsHeight = 165;
                    }
                };
                const stayRendered = index>=0 && index<6 ?true: false;
                return (
                    <RenderIfVisible key={(proposition.acceptedBy.includes(email) ? 'accepted_' : 'unaccepted_') + proposition.id} defaultHeight={248} stayRendered={stayRendered}>
                        <Collapse
                            in={!proposition.removed}
                            className={styles.propositionCollapse}
                        >
                            <div>
                                <ConfirmScheduledDateBox
                                    isUserOrganizer={meeting.isOrganizer}
                                    yesNoButtonsIndex={index}
                                    propositionAnswer={propositionAnswer}
                                    key={proposition.id}
                                    newLabel={!proposition.acceptedBy.includes(email)}
                                    proposition={proposition}
                                    setNextCandidateActive={setNextCandidateActive}
                                    activeCandidateId={props.activePropositionId}
                                    email={email}
                                    timezone={timezone}
                                    timeFormat={profile.timeFormat}
                                    meeting={meeting}
                                    setAnswer={(value: IPropositionAnswer) => {
                                        props.setAnswer(value);
                                        if (nextScheduleId) {
                                            props.setActivePropositionId(nextScheduleId);
                                        }
                                    }}
                                    setActiveCandidateId={props.setActivePropositionId}
                                    isSetDate={allAnswers !== null}
                                    setDate={props.setSetModalDate}
                                    ref={el => (propositionRefs.current[proposition.id] = el)}
                                    updateMeeting={props.actions.updateMeeting}
                                />
                            </div>
                        </Collapse>
                    </RenderIfVisible>
                );
            });
        } else {
            return propositions && propositions.length > 0 && propositionsToShow.length === 0
                ? <div style={{ textAlign: 'center', marginTop: '20px' }}>{t('home.noSlot')}</div>
                : Array.from(new Array(5)).map((item, index) => <ConfirmScheduledDateBoxSkeleton key={index} />);
        }
    };
    return (
        <div id="MeetingAnswers" className={styles.MeetingAnswers}>
            <Header />
            <div className={styles.Body__ElementsOnTopContainer}>
                <div className={styles.message} data-testid="meeting-message">
                    {getMeetingMessage(meeting, email, t, propositions, () => props.actions.showSuccessMessage(t('home.inviteSentMessage')))}
                </div>
                <MeetingTitle
                    title={meeting.title}
                    description={meeting.description}
                >
                    <div className={styles.optionsContainer}>
                        <MeetingOptions
                            actions={props.actions}
                            history={props.history}
                            email={props.email}
                            currentMeeting={props.currentMeeting}
                            onSetDate={props.onSetDate}
                        />
                    </div>
                </MeetingTitle>
                <MeetingParticipants
                    email={props.email}
                    canEditTimezone={showPropositions}
                    actions={props.actions}
                    defaultVisible={!showPropositions}
                    meeting={meeting}
                />
            </div>
            {showPropositions && (
                <>
                    <CalendarEventsHorizontalScrollBar {...props} />
                    <section className={styles.Body}>
                        {allAnswers !== null && propositions.length > 0 && (
                            <Button
                                variant="text"
                                onClick={props.goBack}
                                className={styles.backButton}
                            >
                                <div className={styles.backIcon} />
                                {t('home.backToCurrent')}
                            </Button>
                        )}
                        {allAnswers && (
                            <div className={styles.setDateContainer}>
                                <MobileDateTimePicker
                                    label={t('home.anotherStart')}
                                    disablePast
                                    value={props.startDate}
                                    onChange={(date) => {
                                        props.setStartDate(date);
                                        if (date) {
                                            const dateISO = date.toISOString();
                                            props.setAnswer({
                                                id: -1,
                                                accepted: false,
                                                rejected: false,
                                                earliest: dateISO,
                                                latest: dateISO,
                                            });
                                            props.setActivePropositionId(-1);
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    ampm={profile.timeFormat === '12h'}
                                />
                                <Button
                                    variant="contained"
                                    disabled={!props.startDate}
                                    className={styles.setDateButton}
                                    onClick={() => props.setSetModalDate(
                                        propositionAnswers.find(x => x.id === -1).earliest
                                    )}
                                >
                                    {t('home.setMeeting')}
                                </Button>
                            </div>
                        )}
                        <div className={styles.TimeZoneInfo}>
                            {t('home.allTimesShown')}
                            {' '}
                            <Button
                                variant="text"
                                onClick={() => setTimezoneSelectOpen(true)}
                                className={styles.timeZoneButton}
                            >
                                {moment().tz(timezone).zoneName()}
                            </Button>
                            {meetingTimezone !== moment.tz.guess() && (
                                <Tooltip
                                    title={t('home.timezoneMismatch')}
                                    placement="right"
                                    classes={{ tooltip: styles.tooltip }}
                                    enterTouchDelay={0}
                                >
                                    <img
                                        src="/assets/exclamation.svg"
                                        className={styles.warningIcon}
                                    />
                                </Tooltip>
                            )}
                        </div>
                        {timezoneSelectOpen && (
                            <div
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                className={styles.timezoneSelect}
                            >
                                <TimeZoneSelect
                                    onChange={onTimezoneChange}
                                    onBlur={() => setTimezoneSelectOpen(false)}
                                />
                            </div>
                        )}

                        <div className="inline-between">
                            <SortMenu
                                setSortBy={setSortBy}
                                sortBy={sortBy}
                                acceptedOnTop={acceptedOnTop}
                                setAcceptedOnTop={setAcceptedOnTop}
                            />
                            <FilterMenu
                                isWorkHoursOnly={isWorkHoursOnly}
                                setIsWorkHoursOnly={setIsWorkHoursOnly}
                                isNonConflictingOnly={isNonConflictingOnly}
                                setIsNonConflictingOnly={setIsNonConflictingOnly}
                            />
                        </div>
                        {renderMeetingAnswers()}
                    </section>
                </>
            )}
        </div>
    );
}
