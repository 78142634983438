// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".header__1004d {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 117px;\n    display: flex;\n    align-items: center;\n    padding: 10px 32px 20px 32px;\n    background-color: rgba(255,255,255,0.84706);\n    z-index: 100;\n}\n\n.logo__lslSN {\n    width: 107px;\n    height: 76px;\n    background-image: url('/assets/sbLandingPage/sb_logo.svg');\n    background-size: contain;\n    background-repeat: no-repeat;\n    margin-right: auto;\n}\n\n.menuButton__3HpAR {\n    margin-left: 50px;\n    font-size: 18px;\n    letter-spacing: 0;\n    padding: 5px 20px;\n}\n\n@media (max-width: 1400px) {\n    .menuButton__3HpAR {\n        margin-left: 30px;\n    }\n}\n", "",{"version":3,"sources":["Nav.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,4BAA4B;IAC5B,2CAA2B;IAC3B,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,0DAA0D;IAC1D,wBAAwB;IACxB,4BAA4B;IAC5B,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","file":"Nav.css","sourcesContent":[".header {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 117px;\n    display: flex;\n    align-items: center;\n    padding: 10px 32px 20px 32px;\n    background-color: #ffffffd8;\n    z-index: 100;\n}\n\n.logo {\n    width: 107px;\n    height: 76px;\n    background-image: url('/assets/sbLandingPage/sb_logo.svg');\n    background-size: contain;\n    background-repeat: no-repeat;\n    margin-right: auto;\n}\n\n.menuButton {\n    margin-left: 50px;\n    font-size: 18px;\n    letter-spacing: 0;\n    padding: 5px 20px;\n}\n\n@media (max-width: 1400px) {\n    .menuButton {\n        margin-left: 30px;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"header": "header__1004d",
	"logo": "logo__lslSN",
	"menuButton": "menuButton__3HpAR"
};
module.exports = exports;
