import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/_ReusableComponents/Button/Button';
import classNames from 'classnames';
import { WANTS_TO_BUY_TANBUU } from '../../constants/appConsts';
import { Link } from 'react-router-dom';
const styles = require('./Plans.css');

export default function Plans() {
    const { t } = useTranslation();
    return (
        <div className={styles.root}>
            <div className={styles.plan}>
                <div className={styles.planName}>
                    {t('landing.basic')}
                </div>
                <div className={styles.planPrice}>
                    {t('landing.freeForever')}
                </div>
                <div className={styles.planDescription}>
                    {t('landing.forIndividuals')}
                </div>
                <Button
                    variant="text"
                    className={classNames(styles.planButton, styles.planSignUp)}
                    component={Link}
                    to="/register"
                >
                    {t('landing.signUp')}
                </Button>
            </div>
            <div className={styles.plan}>
                <div className={styles.planName}>
                    {t('landing.plus')}
                </div>
                <div className={styles.planPrice}>
                    $10
                    <span className={styles.mo}>
                            {`/${t('landing.mo')}`}
                        </span>
                    <div className={styles.priceAnnually}>
                        {`$8/${t('landing.mo')} ${t('landing.ifBilled')}`}
                    </div>
                </div>
                <div className={styles.planDescription}>
                    {t('landing.forTeams')}
                </div>
                <Button
                    variant="text"
                    className={classNames(styles.planButton, styles.planBuyNow)}
                    onClick={() => {
                        localStorage.setItem(WANTS_TO_BUY_TANBUU, 'PlusPlan');
                    }}
                    component={Link}
                    to="/register"
                >
                    {t('landing.buyNow')}
                </Button>
            </div>
            <div className={styles.plan}>
                <div className={styles.planName}>
                    {t('landing.enterprise')}
                </div>
                <div className={styles.planPrice}>
                    {t('landing.contactUs')}
                </div>
                <div className={styles.planDescription}>
                    {t('landing.forLarger')}
                </div>
                <Button
                    variant="text"
                    className={classNames(styles.planButton, styles.planContactUs)}
                    component={Link}
                    to="/contact"
                >
                    {t('landing.contactUs')}
                </Button>
            </div>
        </div>
    );
}
