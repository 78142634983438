import * as React from 'react';
import IActions from 'actions/IActions';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useContext, useState } from 'react';
import UnassignLicenseModal from '../../_ReusableComponents/Modals/UnassignLicenseModal/UnassignLicenseModal';
import GlobalContext from '../../../GlobalContext';
const styles = require('./CurrentlyAssignedList.css');

interface ICurrentlyAssignedListProps {
    assignedLicenses: any;
    expiringLicenses: number;
    subscriptionExpires: string;
    manageLicenses: any;
    actions: IActions;
}

const CurrentlyAssignedList = (props: ICurrentlyAssignedListProps) => {
    const { t, i18n } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogEmail, setDialogEmail] = useState<string | null>(null);
    const { profile: { timezone } } = useContext(GlobalContext);
    const selectedExpiring = props.assignedLicenses.filter(x => x.get('expiring')).size;
    return (
        <section>
            {props.expiringLicenses > 0 && (
                <div>
                    <div className={styles.expiringHeader}>
                        {props.expiringLicenses}
                        {' '}
                        {t('plan.licenseExpiringOn', { count: props.expiringLicenses })}
                        {' '}
                        {moment.tz(props.subscriptionExpires, timezone)
                            .format(i18n.language === 'pl' ? 'D MMMM Y' : 'MMMM D, Y')}
                    </div>
                    <div className={classNames({
                        [styles.selectedExpiring]: true,
                        [styles.selectedIncorrect]: selectedExpiring !== props.expiringLicenses
                    })}>
                        {t('plan.selected')} {selectedExpiring}/{props.expiringLicenses}
                        {' '}
                        {t('plan.license', { count: props.expiringLicenses })}
                    </div>
                </div>
            )}
            <ul className={styles.currentlyAssignedList}>
                {props.assignedLicenses.toArray().map((license) => (
                    <li className={styles.ListElement} key={license.get('email')}>
                        <div className={styles.top}>
                            <div className={styles.Label}>
                                <div>{license.get('name')}</div>
                                <div>{license.get('email')}</div>
                            </div>
                            <button
                                className={styles.Button}
                                onClick={() => {
                                    setDialogEmail(license.get('email'));
                                    setDialogOpen(true);
                                }}
                            >
                                <img src="/assets/message-close.svg" className={styles.Icon} />
                            </button>
                        </div>
                        {props.expiringLicenses > 0 && (
                            <div className={styles.expiringContainer}>
                                <FormControlLabel
                                    className={styles.checkboxLabel}
                                    control={(
                                        <Checkbox
                                            checked={license.get('expiring')}
                                            onChange={() => props.actions.setLicenseExpiring(
                                                license.get('id'),
                                                !license.get('expiring'),
                                            )}
                                            classes={{
                                                root: styles.checkbox,
                                                checked: styles.checked
                                            }}
                                        />
                                    )}
                                    label={t('plan.toExpire')}
                                />
                                {license.get('expiringPending') && (
                                    <img src="/assets/spinner.gif" className={styles.spinner} />
                                )}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            <UnassignLicenseModal
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                email={dialogEmail}
                manageLicenses={props.manageLicenses}
                actions={props.actions}
            />
        </section>
    );
};

export default CurrentlyAssignedList;

