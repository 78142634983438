import * as React from 'react';
import { IDS } from 'elementsSelectors/_reusable/GlobalSelectors';
import IActions from 'actions/IActions';
import { isAdHoc } from '../../../scripts/helpers';

declare const __TEST__;

const Styles = require('./NotesIcon.css');

interface Props {
    actions: IActions;
    data: any;
}

interface State {}

export default class NotesIcon extends React.Component<Props, State> {
    render() {
        if (!__TEST__ && isAdHoc()) {
            return null;
        }
        const isOutlook = sessionStorage.getItem('outlook') === '1';
        return (
            <div id={IDS.NOTES_ICON_COUNT} className={Styles.NotesIcon}>
                <img
                    className={Styles.Icon}
                    src={isOutlook ? '/assets/notifications-dark.svg' : '/assets/notification_icon.svg'}
                />
            </div>
        );
    }
}
