import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const styles = require('./ZoomDocs.css');

export default function ZoomDocs() {
    const { t } = useTranslation();
    const transComponents = {
        ul: <ul className={styles.list} />,
        li: <li className={styles.listElement} />,
        b: <b className={styles.bold} />,
    };
    return (
        <div className={styles.content}>
            <div className={styles.header}>
                {t('zoom.header')}
            </div>
            <div className={styles.subheader}>
                {t('zoom.installation')}
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="zoom.installationContent"
                    components={transComponents}
                />
            </div>
            <div className={styles.subheader}>
                {t('zoom.usage')}
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="zoom.usageContent"
                    components={transComponents}
                />
            </div>
            <div className={styles.block}>
                {t('zoom.usageContent2')}
            </div>
            <div className={styles.subheader}>
                {t('zoom.uninstallation')}
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="zoom.uninstallationContent"
                    components={transComponents}
                />
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="zoom.uninstallationContent2"
                    components={transComponents}
                />
            </div>
            <div className={styles.subheader}>
                {t('zoom.troubleshooting')}
            </div>
            <div className={styles.block}>
                {t('zoom.troubleshootingContent')}
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="zoom.troubleshootingContent2"
                    components={transComponents}
                />
            </div>
            <div className={styles.block}>
                <div className={styles.note}>
                    <Trans
                        i18nKey="zoom.troubleshootingContent3"
                        components={transComponents}
                    />
                </div>
            </div>
            <div className={styles.block}>
                {t('zoom.troubleshootingContent4')}
            </div>
            <div className={styles.subheader}>
                {t('zoom.support')}
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="zoom.supportContent"
                    components={{
                        ...transComponents,
                        contact: (
                            <Link to="/contact" className={styles.link} />
                        ),
                        email: <a href="mailto:support@tanbuu.com" className={styles.link} />,
                    }}
                    values={{
                        contactUrl: `${location.origin}/contact`,
                    }}
                />
            </div>
        </div>
    );
}
