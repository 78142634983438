// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".paper__3ozrG {\n    padding: 24px;\n    margin: 16px;\n}\n\n.title__2xwPJ {\n    color: #0badd6;\n    font-size: 22px;\n    font-weight: 400 !important;\n    margin-bottom: 8px;\n}\n\n.text__3plFG {\n    font-size: 18px;\n}\n\n.buttons__3HMuq {\n    margin-top: 16px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.yesButton__fV-Jo {\n    color: #e63e25;\n    border-color: #e63e25;\n    margin-right: 16px;\n}\n\n.yesButton__fV-Jo:hover {\n        border-color: #e63e25;\n    }\n", "",{"version":3,"sources":["RejectMeetingModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,kBAAkB;AAKtB;;AAHI;QACI,qBAAqB;IACzB","file":"RejectMeetingModal.css","sourcesContent":[".paper {\n    padding: 24px;\n    margin: 16px;\n}\n\n.title {\n    color: #0badd6;\n    font-size: 22px;\n    font-weight: 400 !important;\n    margin-bottom: 8px;\n}\n\n.text {\n    font-size: 18px;\n}\n\n.buttons {\n    margin-top: 16px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.yesButton {\n    color: #e63e25;\n    border-color: #e63e25;\n    margin-right: 16px;\n\n    &:hover {\n        border-color: #e63e25;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"paper": "paper__3ozrG",
	"title": "title__2xwPJ",
	"text": "text__3plFG",
	"buttons": "buttons__3HMuq",
	"yesButton": "yesButton__fV-Jo"
};
module.exports = exports;
