// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".tooltip__1jeW1 {\n    font-size: 12px;\n    background-color: #013253;\n}\n\n    .tooltip__1jeW1 div {\n        font-weight: 400 !important;\n    }\n\n.info__1p-qO {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    margin-right: 14px;\n    margin-left: -10px;\n    color: #e9523e;\n    border: 1px solid;\n    border-radius: 50%;\n    width: 14px;\n    height: 14px;\n    font-size: 12px;\n    font-weight: 400 !important;\n    flex-shrink: 0;\n}\n", "",{"version":3,"sources":["AffectedAppointments.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yBAAyB;AAK7B;;IAHI;QACI,2BAA2B;IAC/B;;AAGJ;IACI,oBAAoB;IACpB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,2BAA2B;IAC3B,cAAc;AAClB","file":"AffectedAppointments.css","sourcesContent":[".tooltip {\n    font-size: 12px;\n    background-color: #013253;\n\n    & div {\n        font-weight: 400 !important;\n    }\n}\n\n.info {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    margin-right: 14px;\n    margin-left: -10px;\n    color: #e9523e;\n    border: 1px solid;\n    border-radius: 50%;\n    width: 14px;\n    height: 14px;\n    font-size: 12px;\n    font-weight: 400 !important;\n    flex-shrink: 0;\n}\n"]}]);
// Exports
exports.locals = {
	"tooltip": "tooltip__1jeW1",
	"info": "info__1p-qO"
};
module.exports = exports;
