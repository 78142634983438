// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".paper__3N_zJ {\n    padding: 24px;\n    margin: 16px;\n}\n\n.dialogTitle__s4FWQ {\n    font-size: 20px;\n    text-align: center;\n    margin-bottom: 16px;\n}\n\n@media (min-width: 1250px) {\n\n.dialogTitle__s4FWQ {\n        text-align: left\n}\n    }\n\n.dialogBody__1WmvP {\n    margin-bottom: 32px;\n}\n\n.buttons__2-bbj {\n    display: flex;\n    justify-content: center;\n}\n\n@media (min-width: 1250px) {\n\n.buttons__2-bbj {\n        justify-content: flex-end\n}\n    }\n\n.yesButton__15e8U {\n    margin-right: 38px;\n}\n", "",{"version":3,"sources":["UnassignLicenseModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AAIvB;;AAHI;;AAJJ;QAKQ;AAER;IADI;;AAGJ;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAI3B;;AAHI;;AAHJ;QAIQ;AAER;IADI;;AAGJ;IACI,kBAAkB;AACtB","file":"UnassignLicenseModal.css","sourcesContent":[".paper {\n    padding: 24px;\n    margin: 16px;\n}\n\n.dialogTitle {\n    font-size: 20px;\n    text-align: center;\n    margin-bottom: 16px;\n    @media (min-width: 1250px) {\n        text-align: left;\n    }\n}\n\n.dialogBody {\n    margin-bottom: 32px;\n}\n\n.buttons {\n    display: flex;\n    justify-content: center;\n    @media (min-width: 1250px) {\n        justify-content: flex-end;\n    }\n}\n\n.yesButton {\n    margin-right: 38px;\n}\n"]}]);
// Exports
exports.locals = {
	"paper": "paper__3N_zJ",
	"dialogTitle": "dialogTitle__s4FWQ",
	"dialogBody": "dialogBody__1WmvP",
	"buttons": "buttons__2-bbj",
	"yesButton": "yesButton__15e8U"
};
module.exports = exports;
