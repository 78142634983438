import React, { useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import MeetingRow from '../MeetingRow/MeetingRow';
import IActions from 'actions/IActions';
import IMeeting from '../../../interfaces/IMeeting';
import FallingLeaves from '../../_ReusableComponents/FallingLeaves/FallingLeaves';
import FilteringButtons from '../../MeetingPageComponents/FilteringButtons/FilteringButtons';
import { isAdHoc } from '../../../scripts/helpers';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ITab } from '../../../containers/MainPage/MainPage';
const styles = require('./MeetingRows.css');

interface Props {
    meetings: IMeeting[];
    history: any;
    actions: IActions;
    openMeeting?: number;
    expanded: boolean;
    email: string;
    isDesktop: boolean;
    onSetDate: (id: number) => void;
    data: any;
    tab: ITab;
    setTab: (tab: ITab) => void;
}

export default function MeetingRows(props: Props) {
    const { t } = useTranslation();
    const scrollableRef = useRef<HTMLElement | null>(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const { meetings } = props;
    useEffect(() => {
        const handler = () => {
            setIsScrolled(scrollableRef.current.scrollTop > 20);
        };
        if (scrollableRef.current) {
            scrollableRef.current.addEventListener('scroll', handler);
        }
        return () => {
            if (scrollableRef.current) {
                scrollableRef.current.removeEventListener('scroll', handler);
            }
        };
    }, [meetings.length]);
    if (!meetings) {
        return <FallingLeaves />;
    }
    const { tab, setTab } = props;
    return (
        <div id="MeetingRows" className={styles.MeetingRows}>
            {!isAdHoc() &&
                <FilteringButtons {...props} isScrolled={isScrolled} tab={tab} setTab={setTab} />
            }
            {meetings.length === 0 ? (
                <div
                    className={classNames(styles.noMeetings, {
                        [styles.noMeetingsAdHoc]: isAdHoc(),
                    })}
                    data-testid="no-meetings"
                >
                    {t('home.noMeetings')}
                </div>
            ) : (
                <SimpleBar
                    className={classNames(styles.content, {
                        [styles.contentAdHoc]: isAdHoc(),
                    })}
                    scrollableNodeProps={{ ref: scrollableRef }}
                >
                    {meetings.filter(
                        (x) => !props.openMeeting || x.id === props.openMeeting,
                    ).map(item => (
                        <MeetingRow
                            {...props}
                            key={item.id}
                            item={item}
                        />
                    ))}
                </SimpleBar>
            )}
        </div>
    );
}
