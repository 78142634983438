import React, { useState } from 'react';
import IActions from 'actions/IActions';
import Button from '../../../_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { WORK_DAYS } from 'constants/appConsts';
import PreferencesUtils from 'utils/PreferencesUtils/PreferencesUtils';
import MyWorkWeekHours from './MyWorkWeekHours/MyWorkWeekHours';
const styles = require('./MyWorkWeekContainer.css');

interface Props {
    actions: IActions;
    preferences: any;
    data: any;
}

export default function MyWorkWeekContainer(props: Props) {
    const { t, i18n } = useTranslation();
    const [selectedWorkDays, setSelectedWorkDays] = useState<number[]>([]);

    const selectWorkDay = (dayIndex: number) => {
        const index = selectedWorkDays.findIndex((workDay) => workDay === dayIndex);
        const newWorkDays = [...selectedWorkDays];
        if (index >= 0) {
            newWorkDays.splice(index, 1);
            setSelectedWorkDays([...newWorkDays]);
        } else {
            setSelectedWorkDays(prev => [...prev, dayIndex]);
        }
    };

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                {t('settings.workDays')}:
            </div>
            <div className={styles.days}>
                {WORK_DAYS.map((day, index) => (
                    <Button
                        key={day}
                        variant={selectedWorkDays.includes(index) ? 'contained' : 'text'}
                        color={selectedWorkDays.includes(index) ? 'secondary' : 'primary'}
                        onClick={() => selectWorkDay(index)}
                        className={styles.day}
                    >
                        {i18n.language === 'en' ?
                            PreferencesUtils.capitalizeFirstLetter(moment(day, 'dd', 'en').locale(i18n.language).format('dd')) :
                            moment(day, 'dd', 'en').locale(i18n.language).format('dd').toLowerCase()}
                    </Button>
                ))}
            </div>
            <MyWorkWeekHours {...props} selectedWorkDays={selectedWorkDays} />
        </div>
    );
}
