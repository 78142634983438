import BackendMethods from 'api/BackendMethods';
import { ICompaniesRating, IReviews } from 'interfaces/ICompaniesRating';
import { ILocationSearchResult } from 'interfaces/interfaces';
import IProfile from 'interfaces/IProfile';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSubdomain } from 'utils/subdomain';
import { SortDirection, SortItemType, sortByItem } from 'utils/sort';
import { Review } from './Review/Review';
import { Header } from './Header/Header';
import { Response } from './Response/Response';
import { EditReview } from './EditReview/EditReview';
import { Report } from './Report/Report';
import { Pagination, SortBy } from 'elementsSelectors/Reviews';
import IActions from 'actions/IActions';

interface IReviewProps {
    profile: IProfile;
    actions: IActions;
    parentsLocation?: ILocationSearchResult;
}
export const Reviews = ({ profile, actions, parentsLocation }: IReviewProps) => {
    const [location, setLocation] = useState<ILocationSearchResult | null>(null);
    const [rating, setRating] = useState<ICompaniesRating[] | null>(null);
    const [sortedReviews, setSortedReviews] = useState<IReviews[] | null>(null);
    const [votes, setVotes] = useState<number[]>([0, 0, 0, 0, 0]);
    const [voteNum, setVoteNum] = useState<number>(0);
    const params = useParams<{ company: string }>();
    const [nowPage, setNowPage] = useState<Pagination>(Pagination.REVIEWS);
    const [sortBy, setSortBy] = useState<SortBy>(SortBy.Latest);
    const [nowReview, setNowReview] = useState<IReviews | null>(null);
    const [isShow, setIsShow] = useState<boolean>(false);
    const subDomain = getSubdomain();
    const isMyCompany: boolean = profile && profile.appointments?.companyId
        ? profile.appointments.companyId === (parentsLocation ? parentsLocation.companyId : Number(params.company))
        : false;

    useEffect(() => {
        getRating();
        if (parentsLocation) {
            setLocation(parentsLocation);
        } else {
            (async () => {
                try {
                    const locations: ILocationSearchResult[] = await BackendMethods.searchForLocations('', subDomain);
                    setLocation(locations.find((item) => (item.companyId === Number(params.company))));
                } catch (error) {
                }
            })();
        }
    }, []);
    useEffect(() => {
        if (rating && rating.length > 0) {
            let votesNum = 0;
            const midVotes = [0, 0, 0, 0, 0];
            rating[0].histogram.map((item) => {
                midVotes[5 - item.rating] = item.votes;
                votesNum += item.votes;
            });
            setVotes(midVotes);
            if (votesNum > 0) {
                setVoteNum(votesNum);
            }
        }
    }, [rating]);
    useEffect(() => {
        if (!rating || rating.length === 0) {
            return;
        }
        let midSortedReviews: IReviews[] = [];
        switch (sortBy) {
            case SortBy.Latest:
                midSortedReviews = sortByItem(rating[0].reviews, 'submitted', SortItemType.byDate, SortDirection.DES);
                break;
            case SortBy.Highest:
                midSortedReviews = sortByItem(rating[0].reviews, 'rating', SortItemType.normal, SortDirection.DES);
                break;
            case SortBy.Lowest:
                midSortedReviews = sortByItem(rating[0].reviews, 'rating', SortItemType.normal, SortDirection.ASC);
                break;
        }
        midSortedReviews.sort((a, b) => !a.self && b.self ? 1 : -1);
        setSortedReviews(midSortedReviews.sort((a, b) => !a.self && b.self ? 1 : -1));
    }, [sortBy, rating]);

    const getRating = async () => {
        setIsShow(false);
        const companyIds: number[] = [];
        if (parentsLocation) {
            companyIds.push(parentsLocation.companyId);
        } else {
            companyIds.push(Number(params.company));
        }
        try {
            setRating(await BackendMethods.companiesRating(companyIds, profile? true: false));
            setNowPage(Pagination.REVIEWS);
            setIsShow(true);
        } catch (error) {
        }
    };

    return location ? (
        <>
            {nowPage === Pagination.REVIEWS && (<>
                <Header
                    subDomain={subDomain}
                    location={location}
                    votes={votes}
                    voteNum={voteNum}
                    rating={rating && rating.length>0 ? rating[0].rating : 0}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                />
                {isShow && rating && rating.length>0 && rating[0].reviews ? sortedReviews.map((item, index) => (
                    <Review
                        key={index}
                        review={item}
                        companyName={location.companyName && location.companyName !== '' ? location.companyName : location.companySubdomain}
                        isMyCompany={isMyCompany}
                        setNowReview={setNowReview}
                        setNowPage={setNowPage}
                        getRating={getRating}
                    />
                )) : null}
            </>)}
            {nowPage === Pagination.RESPONSIVE_EDIT && (
                <Response
                    nowReview={nowReview}
                    getRating={getRating}
                />
            )}
            {nowPage === Pagination.REVIEW_EDIT && (
                <EditReview
                    myReview={nowReview}
                    companyId={location.companyId}
                    companyName={location.companyName}
                    setNowPage={setNowPage}
                    getRating={getRating}
                />
            )}
            {nowPage === Pagination.REPORT_EDIT && (
                <Report
                    reviewId={nowReview.id}
                    setNowPage={setNowPage}
                    actions={actions}
                />
            )}
        </>
    ): null;
};
