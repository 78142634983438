import * as React from 'react';
import PlusPlanAdminPage from './containers/PlusPlanAdminPage/PlusPlanAdminPage';
import PlusPlanPaymentPage from './containers/PlusPlanPaymentPage/PlusPlanPaymentPage';
import BillingPage from './containers/BillingPage/BillingPage';
import PaymentFailedPage from './containers/PaymentResultPage/PaymentFailedPage/PaymentFailedPage';
import PaymentSuccessPage from './containers/PaymentResultPage/PaymentSuccessPage/PaymentSuccessPage';
import MainPage from './containers/MainPage/MainPage';
import ManageGroupPage from './containers/ManageGroupPage/ManageGroupPage';
import ManageLicensesPage from './containers/ManageLicensesPage/ManageLicensesPage';
import MeetingPage from './containers/MeetingPage/MeetingPage';
import RegisterPage from './containers/RegisterPage/RegisterPage';
import EmailHandlerPage from './containers/EmailHandlerPage/EmailHandlerPage';
import ForgotPage from './containers/ForgotPage/ForgotPage';
import NewMeetingPage from './containers/NewMeetingPage/NewMeetingPage';
import PreferencesPage from 'containers/PreferencesPage/PreferencesPage';
import EditPage from 'containers/EditPage/EditPage';
import { Route, Switch, Redirect } from 'react-router-dom';
import { isLoggedIn, isAdHoc } from 'scripts/helpers';
import Shortened from './containers/Shortened';
import PhoneLogin from './containers/PhoneLoginPage/PhoneLogin';
import PlusPlanInfo from './containers/PlusPlanInfo/PlusPlanInfo';
import SmallBusiness from './containers/SmallBusiness/SmallBusiness';
import Customer from './containers/SmallBusiness/Customer/Customer';
import Public from './containers/LandingPage/Public';
import LoginPartial from './components/LoginPageComponents/LoginPartial/LoginPartial';
import SuggestionPage from './containers/SuggestionPage/SuggestionPage';
import LandingPage from 'containers/SmallBusiness/LandingPage/LandingPage';

const getRedirect = ({ pathname, search }) => (
    `/login?redirectURI=${encodeURIComponent(pathname + search)}`
);

const PrivateRoute = ({ component: Component, path }) => (
    <Route
        path={path}
        render={props =>
            isLoggedIn()
                ? <Component {...props} />
                : <Redirect to={getRedirect(props.location)} />
        }
    />
);
const AdHocAllowRoute = ({ component: Component, path }) => (
    <Route
        path={path}
        render={props =>
            isLoggedIn() || isAdHoc() ? (
                <Component {...props} />
            ) : (
                <Redirect to={getRedirect(props.location)} />
            )
        }
    />
);

export default (
    <Switch>
        <AdHocAllowRoute path="/main/:id?" component={MainPage} />
        <Route path="/register" component={RegisterPage} />
        <Route path="/forgot" component={ForgotPage} />
        <AdHocAllowRoute path="/meeting/:id" component={MeetingPage} />
        <PrivateRoute path="/history" component={MeetingPage} />
        <PrivateRoute path="/new" component={NewMeetingPage} />
        <PrivateRoute path="/edit/:id" component={EditPage} />
        <PrivateRoute path="/suggestion/:id" component={SuggestionPage} />
        <PrivateRoute path="/preferences" component={PreferencesPage} />
        <Route path="/emailhandler" component={EmailHandlerPage} />
        <Route path="/login" component={LoginPartial} />
        <Route path="/login-phone" component={PhoneLogin} />
        <PrivateRoute path="/upgradePlan" component={PlusPlanPaymentPage} />
        <PrivateRoute path="/payment-failed" component={PaymentFailedPage} />
        <PrivateRoute path="/payment-success" component={PaymentSuccessPage} />
        <PrivateRoute path="/licenses" component={ManageLicensesPage} />
        <PrivateRoute path="/team" component={ManageGroupPage} />
        <PrivateRoute path="/billing" component={BillingPage} />
        <PrivateRoute path="/manage" component={PlusPlanAdminPage} />
        <PrivateRoute path="/plan-info" component={PlusPlanInfo} />
        <Route path="/s/:code" component={Shortened} />
        <Route path="/empty" render={() => null} />
        <PrivateRoute path="/sb/*" component={SmallBusiness} />
        <Route path="/sb-customer/*" component={Customer} />
        <Route path="/biz" component={LandingPage} />
        <Redirect from="/ma" to="/sb-customer/my-appointments" />
        <Route
            path={[
                '/pricing',
                '/features',
                '/customer-success',
                '/for-sales',
                '/faq',
                '/zoom-docs',
                '/contact',
                '/about',
                '/policy',
                '/terms',
                '/professional-terms',
                '/data-processing-contract',
                '/icloud-docs',
                '/',
            ]}
            component={Public}
        />
    </Switch>
);
