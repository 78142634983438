enum Pagination {
    REVIEWS,
    REVIEW_EDIT,
    RESPONSIVE_EDIT,
    REPORT_EDIT,
}

enum SortBy {
    Latest,
    Highest,
    Lowest,
}

export { Pagination, SortBy };
