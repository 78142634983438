import * as React from 'react';
import classNames from 'classnames';
import { Collapse } from '@material-ui/core';
const styles = require('./TogglePreferencesElement.css');

interface Props {
    text: string;
    toggleState: boolean;
    callback: () => void;
    id?: string;
    'data-tesid'?: string;
    children: React.ReactNode;
}

export default function TogglePreferencesElement(props: Props) {
    return (
        <div>
            <div
                id={props.id}
                data-testid={props['data-testid']}
                onClick={props.callback}
                className={styles.root}
            >
                <div className={styles.title}>
                    {props.text || ''}
                </div>
                <img
                    src="/assets/chevron-blue.svg"
                    alt=""
                    className={classNames(styles.arrowUp, {
                        [styles.rotate]: props.toggleState,
                    })}
                />
            </div>
            <Collapse
                in={props.toggleState}
            >
                <div className={styles.container}>
                    {props.children}
                </div>
            </Collapse>
        </div>
    );
}
