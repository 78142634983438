import React, { forwardRef } from 'react';
import { InputAdornment } from '@material-ui/core';
const styles = require('./SearchAdornment.css');

const SearchAdornment = forwardRef<HTMLDivElement>((props, ref) => (
    <InputAdornment position="start" ref={ref}>
        <img
            src="/assets/search-gray.svg"
            alt=""
            className={styles.icon}
        />
    </InputAdornment>
));

export default SearchAdornment;
