import * as React from 'react';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import IActions from 'actions/IActions';
import ManageLicenses from '../../components/ManageLicensesComponents/ManageLicenses/ManageLicenses';
import ILocation from 'interfaces/location/ILocation';
import Header from '../../components/_ReusableComponents/Header/Header/Header';

const connect = require('react-redux').connect;

interface IProps {
    manageLicenses: any;
    data: any;
    actions: IActions;
    location: ILocation;
    history: any;
}

function ManageLicensesPage(props: IProps) {
    return (
        <div>
            <Header />
            <ManageLicenses {...props} />
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(ManageLicensesPage);
