import React from 'react';
import IMeeting from '../../../interfaces/IMeeting';
import { IconButton, Snackbar } from '@material-ui/core';
import Button from '../Button/Button';
import BackendMethods from '../../../api/BackendMethods';
import { useTranslation } from 'react-i18next';
const styles = require('./UndoSnackbar.css');

interface Props {
    open: boolean;
    onClose: () => void;
    meetingId: number;
    updateMeeting: (meeting: IMeeting) => void;
}

export default function UndoSnackbar(props: Props) {
    const { t } = useTranslation();
    return (
        <Snackbar
            open={props.open}
            onClose={(event, reason) => {
                if (reason !== 'clickaway') {
                    props.onClose();
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            ContentProps={{
                className: styles.root,
            }}
            message={(
                <span className={styles.text}>
                    {t('home.answerSent')}
                </span>
            )}
            action={(
                <Button
                    variant="text"
                    onClick={async () => {
                        props.updateMeeting(await BackendMethods.withdrawAnswer(props.meetingId));
                        props.onClose();
                    }}
                    className={styles.undo}
                >
                    {t('home.undo')}
                </Button>
            )}
        />
    );
}
