// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".phoneRow__2W-D- {\n    display: flex;\n}\n\n.country__Wa9K4 {\n    width: 120px;\n    margin-right: 16px;\n    flex-shrink: 0;\n}\n\n.phone__2ZMCx {\n    flex-grow: 1;\n}\n\n.bookingFor__FHida {\n    margin-bottom: 16px;\n}\n\n.bookedBy__2WLuI {\n    font-size: 20px;\n    margin-bottom: 8px;\n}\n\n.saveRow__1pWmv {\n    margin-top: 16px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.cancel__1Xjgu {\n    margin-right: auto;\n}\n\n@media (min-width: 1250px) {\n\n.cancel__1Xjgu {\n        margin-right: 38px\n}\n    }\n", "",{"version":3,"sources":["CustomerData.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;AAKtB;;AAHI;;AAHJ;QAIQ;AAER;IADI","file":"CustomerData.css","sourcesContent":[".phoneRow {\n    display: flex;\n}\n\n.country {\n    width: 120px;\n    margin-right: 16px;\n    flex-shrink: 0;\n}\n\n.phone {\n    flex-grow: 1;\n}\n\n.bookingFor {\n    margin-bottom: 16px;\n}\n\n.bookedBy {\n    font-size: 20px;\n    margin-bottom: 8px;\n}\n\n.saveRow {\n    margin-top: 16px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.cancel {\n    margin-right: auto;\n\n    @media (min-width: 1250px) {\n        margin-right: 38px;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"phoneRow": "phoneRow__2W-D-",
	"country": "country__Wa9K4",
	"phone": "phone__2ZMCx",
	"bookingFor": "bookingFor__FHida",
	"bookedBy": "bookedBy__2WLuI",
	"saveRow": "saveRow__1pWmv",
	"cancel": "cancel__1Xjgu"
};
module.exports = exports;
