import moment from 'moment';
import { IWeek, IWorkHours } from '../../interfaces/interfaces';
import PreferencesUtils from '../PreferencesUtils/PreferencesUtils';

function SortWorkWeekHour(workWeek: IWorkHours[]): IWorkHours[] {
    const splitWorkWeek: IWorkHours[] = [];
    const weekDay = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    if (!workWeek || workWeek.length === 0) {
        weekDay.map((day: IWeek) => {
            const newWeek: IWorkHours = {
                days: [day],
                begin: 'none',
                end: '00:00'
            };
            splitWorkWeek.push(newWeek);
        });
        return splitWorkWeek;
    }
    workWeek.map((item: IWorkHours) => {
        if (item.days.length === 1) {
            splitWorkWeek.push(item);
        } else {
            const splitDay: IWorkHours = {...item};
            item.days.map((day: IWeek) => {
                splitDay.days = [day];
                splitWorkWeek.push({ ...splitDay });
            });
        }
    });

    weekDay.map((day: IWeek) => {
        if (!splitWorkWeek.some(({ days }) => days.includes(day))) {
            const newWeek: IWorkHours = {
                days: [day],
                begin: 'none',
                end: '00:00'
            };
            splitWorkWeek.push(newWeek);
        }
    });

    return mergeWorkWeekHours(splitWorkWeek);
}

function mergeWorkWeekHours(workWeek: IWorkHours[]): IWorkHours[] {
    const sortedHours: IWorkHours[] = PreferencesUtils.sortWorkWeekHours(workWeek);
    const mergedHours: IWorkHours[] = [];
    sortedHours.reduce((previousValue: any, currentValue: IWorkHours, currentIndex: Number) => {
        if (currentIndex === 0 || (!previousValue.days.includes(currentValue.days[0]))) {
            mergedHours.push({...currentValue});
        } else {
            const [isMerge, endTime] = mergeWorkWeekHour(previousValue, currentValue);
            if (isMerge) {
                mergedHours[mergedHours.length-1].end = endTime;
            } else {
                mergedHours.push({...currentValue});
            }
        }
        return mergedHours[mergedHours.length - 1];
    }, []);
    return mergedHours;
}

function mergeWorkWeekHour(preWorkHour: IWorkHours, curWorkHour: IWorkHours): [boolean, string] {
    if (preWorkHour.end === '00:00') {
        return [true, '00:00'];
    } else if (moment(preWorkHour.end, 'hh:mm').isBefore(moment(curWorkHour.begin, 'hh:mm'))) {
        return [false, ''];
    } else if (curWorkHour.end === '00:00') {
        return [true, '00:00'];
    } else {
        return [true, moment.max(moment(preWorkHour.end, 'hh:mm'), moment(curWorkHour.end, 'hh:mm')).format('HH:mm')];
    }
}

export { SortWorkWeekHour };
