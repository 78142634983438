import React from 'react';
import Styles from './Helper.css';

export const Helper = ({ data }) => {

    const card = (cardData: Object, deep: number, index: number) => {
        return (
            <div className={Styles.cardBox}>
                {
                    (cardData['header'] !== '') && <div>
                        {
                            deep === 0 ?
                                (
                                    <div className={Styles.headerBox}>
                                        <h2 className={Styles.header}>{cardData['header']}</h2>
                                    </div>
                                ) :
                                (<li className={Styles.description}>{cardData['header']}</li>)
                        }
                    </div>
                }
                <div className={Styles.contentBox}>
                    {
                        (cardData['description']) && cardData['description'].map((item, ind) => {
                            return (
                                <div key={ind}>
                                    <p className={Styles.description}>{item}</p>
                                </div>
                            );
                        })
                    }
                    <ul className={Styles.ulContent}>
                        {
                            cardData['body'].map((cd, ind) => (
                                typeof (cd) === 'string' ?
                                    (<li
                                        key={ind}
                                        className={Styles.description}
                                        dangerouslySetInnerHTML={{
                                            __html: cd
                                        }} />) :
                                    (<div key={ind}>
                                        {card(cd, deep + 1, ind)}
                                    </div>)
                            ))
                        }
                    </ul>
                    {
                        (cardData['footer']) && cardData['footer'].map((item, ind) => {
                            return (
                                <div key={ind}>
                                    <p className={Styles.description}>{item}</p>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    };

    return (
        <div className={Styles.root}>
            <h1 className={Styles.mainHeader}>{data['title']}</h1>
            {data['header'] && (
                <div className={Styles.footer}>
                    {data['header'].map((text) => (
                        <p className={Styles.description} dangerouslySetInnerHTML={{ __html: text }}></p>
                    ))}
                </div>
            )}
            {data['body'].map((d, index) => (
                <div key={index}>
                    {card(d, 0, index)}
                </div>
            ))}
            {data['footer'] && (
                <div className={Styles.footer}>
                    {data['footer'].map((text) => (
                        <p className={Styles.description}>{text}</p>
                    ))}
                </div>
            )}
        </div>
    );
};
