import React, { useContext, useState } from 'react';
import { Collapse, IconButton, MenuItem } from '@material-ui/core';
import moment from 'moment-timezone';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import TogglePreferencesElement from
        'components/_ReusableComponents/Toggle/TogglePreferencesElement/TogglePreferencesElement';
import IActions from 'actions/IActions';
import Button from '../../_ReusableComponents/Button/Button';
import TextField from '../../_ReusableComponents/TextField/TextField';
import TimeZoneSelect from '../../_ReusableComponents/TimeZoneSelect/TimeZoneSelect';
import BackendMethods from '../../../api/BackendMethods';
import ToggleSwitch from '../../_ReusableComponents/ToggleSwitch/ToggleSwitch';
import i18next from 'i18next';
import { getTimezone } from 'countries-and-timezones';
import { getSubdomain, redirectToBaseDomain } from '../../../utils/subdomain';
import { useHistory } from 'react-router-dom';
import GlobalContext from '../../../GlobalContext';
const styles = require('./MyInfo.css');

interface Props {
    actions: IActions;
    preferences: any;
}

export default function MyInfo(props: Props) {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { profile, updateContext } = useContext(GlobalContext);
    const [firstName, setFirstName] = useState(profile.firstName);
    const [lastName, setLastName] = useState(profile.lastName);
    const [company, setCompany] = useState(profile.company);
    const [timezone, setTimezone] = useState(profile.timezone);
    const [editingTimezone, setEditingTimezone] = useState(false);
    const [timeFormat, setTimeFormat] = useState(profile.timeFormat);
    const [loading, setLoading] = useState(false);
    const newProfile = {
        firstName,
        lastName,
        company,
        timezone,
        country: getTimezone(timezone).country.toLowerCase(),
        timeFormat,
    };
    const showSubmitButton = !R.equals(
        newProfile,
        R.pick(['firstName', 'lastName', 'company', 'timezone', 'country', 'timeFormat'], profile),
    );
    const submitChanges = async () => {
        setLoading(true);
        try {
            await BackendMethods.updateUserProfile(newProfile);
            props.actions.showSuccessMessage(t('settings.infoSaved'));
            updateContext((x) => ({
                ...x,
                profile: { ...profile, ...newProfile },
            }));
        } catch {
            props.actions.showErrorMessage(t('settings.infoFailed'));
        }
        setLoading(false);
    };
    return (
        <div className={styles.root}>
            <TogglePreferencesElement
                callback={props.actions.toggleMyPersonalInformation}
                text={t('settings.myInfo')}
                toggleState={props.preferences.get('isMyPersonalInformationVisible')}
                data-testid="my-info-tab"
            >
                <div className={styles.content} data-testid="my-info-content">
                    <Collapse in={showSubmitButton}>
                        <div>
                            <div className={styles.submitInfo}>
                                {t('settings.clickSubmit')}
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submitChanges}
                                className={styles.submitButton}
                                disabled={loading}
                                data-testid="submit-button"
                            >
                                {t('settings.submit')}
                            </Button>
                        </div>
                    </Collapse>
                    <TextField
                        label={t('settings.firstName')}
                        value={firstName}
                        onChange={
                            ({ target }) => setFirstName(target.value)
                        }
                        fullWidth
                        data-testid="settings-name"
                    />
                    <TextField
                        label={t('settings.lastName')}
                        value={lastName}
                        onChange={
                            ({ target }) => setLastName(target.value)
                        }
                        fullWidth
                        data-testid="settings-last-name"
                    />
                    <TextField
                        label={t('settings.company')}
                        value={company}
                        onChange={
                            ({ target }) => setCompany(target.value)
                        }
                        fullWidth
                        data-testid="settings-company"
                    />
                    {profile.phone ? (
                        <div className={styles.phone}>
                            {t('settings.phone')}
                            {': '}
                            {profile.phone}
                        </div>
                    ) : (
                        <Button
                            className={styles.phone}
                            onClick={() => {
                                const path = `/login-phone?merge=1&redirectURI=/preferences`;
                                if (getSubdomain() === null) {
                                    history.push(path);
                                } else {
                                    redirectToBaseDomain(path);
                                }
                            }}
                        >
                            {t('settings.addPhoneNumber')}
                        </Button>
                    )}
                    <div className={styles.language}>
                        <div>
                            {t('settings.language')}:
                        </div>
                        <TextField
                            select
                            value={i18n.language}
                            onChange={({ target }) => {
                                i18next.changeLanguage(target.value);
                                updateContext((x) => ({
                                    ...x,
                                    profile: {
                                        ...profile,
                                        language: target.value,
                                    },
                                }));
                                BackendMethods.updateUserProfile({
                                    language: target.value,
                                });
                            }}
                            className={styles.languageSelect}
                        >
                            <MenuItem value="en">
                                {t('settings.english')}
                            </MenuItem>
                            <MenuItem value="pl">
                                {t('settings.polish')}
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className={styles.timezone}>
                        <div className={styles.timezoneTop}>
                            <div>
                                {t('settings.timezone')}
                                {': '}
                                <span data-testid="default-timezone">
                                    {timezone}
                                </span>
                                <IconButton
                                    className={styles.editButton}
                                    onClick={() => setEditingTimezone(true)}
                                    data-testid="edit-timezone"
                                    size="large">
                                    <img
                                        className={styles.penIcon}
                                        src="/assets/pen.svg"
                                    />
                                </IconButton>
                            </div>
                            <Button
                                className={styles.findButton}
                                onClick={() => setTimezone(moment.tz.guess())}
                            >
                                {t('settings.findTimezone')}
                            </Button>
                        </div>
                        {editingTimezone && (
                            <TimeZoneSelect
                                onChange={(newTimezone) => {
                                    setTimezone(newTimezone);
                                    setEditingTimezone(false);
                                }}
                                onBlur={() => setEditingTimezone(false)}
                            />
                        )}
                    </div>
                    <div>
                        {t('settings.timeFormat')}:
                    </div>
                    <ToggleSwitch
                        toggleBusiness={() => setTimeFormat(timeFormat === '12h' ? '24h' : '12h')}
                        currentState={timeFormat}
                        labelForState1={t('settings.12hour')}
                        labelForState2={t('settings.24hour')}
                        state1="12h"
                        state2="24h"
                        disabled={false}
                    />
                </div>
            </TogglePreferencesElement>
        </div>
    );
}
