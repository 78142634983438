// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__2S-OU {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 80px 30px 0 30px;\n}\n", "",{"version":3,"sources":["PreferencesPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,yBAAyB;AAC7B","file":"PreferencesPage.css","sourcesContent":[".root {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 80px 30px 0 30px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__2S-OU"
};
module.exports = exports;
