// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".icon__2kPOh {\n    width: 19px;\n    height: 19px;\n}\n", "",{"version":3,"sources":["SearchAdornment.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB","file":"SearchAdornment.css","sourcesContent":[".icon {\n    width: 19px;\n    height: 19px;\n}\n"]}]);
// Exports
exports.locals = {
	"icon": "icon__2kPOh"
};
module.exports = exports;
