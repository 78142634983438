import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { Dialog, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { ILocation } from './Settings/Sites';
import { IService } from './Settings/Services';
import moment, { Moment } from 'moment';
import Button from '../../components/_ReusableComponents/Button/Button';
import { IBookedSlot, IBookingStaff, ICustomer, ILocationDates, ISlot } from './DesktopBooking';
import Autocomplete from '../../components/_ReusableComponents/Autocomplete/Autocomplete';
import TextField from '../../components/_ReusableComponents/TextField/TextField';
import classNames from 'classnames';
import MobileDateTimePicker from '@material-ui/lab/MobileDateTimePicker';
import BackendMethods from '../../api/BackendMethods';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import IProfile from '../../interfaces/IProfile';
import MobileCalendar from './MobileCalendar';
import { renderPrice } from './common';
import { Link } from 'react-router-dom';
import BookingCustomer from './AssistantBooking/BookingCustomer';
import { getSubdomain, redirectToBaseDomain, redirectToBaseIfNeeded } from '../../utils/subdomain';
import FallingLeaves from '../../components/_ReusableComponents/FallingLeaves/FallingLeaves';
import { cancelRequest } from '../../api/backendRequest';
import AddEmailDialog from '../../components/_ReusableComponents/AddEmailDialog';
import { IAppointment } from './Dashboard/Dashboard';
import { GDPRConsentModal } from 'components/_ReusableComponents/Modals/GDPRConsentModal/GDPRConsentModal';
const styles = require('./MobileBooking.css');

interface IProps {
    profile: IProfile;
    setNoHeader: (value: boolean) => void;
    isCustomer?: boolean;
}

const SERVICE_STEP = 0;
const DATE_STEP = 1;
const CUSTOMER_STEP = 2;
const BOOKED_STEP = 3;

export default function MobileBooking({ profile, setNoHeader, isCustomer }: IProps) {
    useEffect(() => {
        setNoHeader(true);
        return () => setNoHeader(false);
    }, []);
    const userTimezone = profile?.timezone || moment.tz.guess();
    const timeFormat = profile?.timeFormat || '24h';
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const params = useParams<{ id: string, company: string, location: string }>();
    const companyId = params.company ? Number(params.company) : profile.appointments.companyId;
    const assistantRebook = useRouteMatch('/sb/assistant-booking/rebook/:id');
    const customerRebook = useRouteMatch('/sb-customer/book/:company/rebook/:id');
    const assistantEdit = useRouteMatch('/sb/assistant-booking/edit/:id');
    const customerEdit = useRouteMatch('/sb-customer/book/:company/edit/:id');
    const isRebook = Boolean(assistantRebook || customerRebook);
    const isEdit = Boolean(assistantEdit || customerEdit);
    const [step, setStep] = useState(0);
    const [useLocalStorage, isUseLocalStorage] = useState<boolean[]>([]);
    const [useLocalStorage1, isUseLocalStorage1] = useState<boolean[]>([]);
    const [sites, setSites] = useState<ILocation[] | null>(null);
    const [services, setServices] = useState<IService[] | null>(null);
    const [people, setPeople] = useState<IBookingStaff[] | null>(null);
    const [companyName, setCompanyName] = useState<string | null>(null);
    const [birthDateRequired, setBirthDateRequired] = useState<boolean>(false);
    const [showGDPRConsentModal, setShowGDPRConsentModal] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            const companyData = await BackendMethods.getBookingCompany(companyId);
            setSites(companyData.locations);
            setServices(companyData.services);
            setPeople(companyData.staff);
            setCompanyName(companyData.name);
            setBirthDateRequired(companyData.birthDateRequired);
        })();
    }, [companyId]);
    const [site, setSite] = useState<ILocation | 'ANY'>('ANY');
    useEffect(() => {
        if (sites && params.location) {
            setSite(sites.find(R.propEq('id', Number(params.location))));
        }
    }, [sites, params.location]);
    const [search, setSearch] = useState('');
    const [selectedPerson, setSelectedPerson] = useState<IBookingStaff | 'ANY'>('ANY');
    const [selectedService, setSelectedService] = useState<IService | null>(null);
    const [bookedService, setBookedService] = useState<IService | null>(null);
    const prevService = useRef(null);
    const [locationDates, setLocationDates] = useState<ILocationDates[]>([]);
    const [selectedWeek, setSelectedWeek] = useState(moment().tz(userTimezone).startOf('week'));
    const [isWeekView, setIsWeekView] = useState(true);
    const prevWeek = useRef(null);
    useEffect(() => {
        if (selectedService === null) {
            setSelectedWeek(moment().tz(userTimezone).startOf('week'));
            setLocationDates([]);
        }
    }, [selectedService]);
    const [selectedSlot, setSelectedSlot] = useState<ISlot | null>(null);
    const [bookedSlot, setBookedSlot] = useState<IBookedSlot | null>(null);
    const getSlots = useCallback((currentLocationDates: ILocationDates[]) => (
        R.sortBy<ISlot>((x) => x.date.valueOf(), R.uniqBy(
            (x) => x.date.valueOf(),
            R.concat(
                R.chain(
                    (x) => x.dates.map((y) => ({
                        date: moment(y).tz(userTimezone),
                        employee: x.id,
                        location: x.location,
                    })),
                    R.chain((x) => x.employees.map((y) => ({
                        ...y,
                        location: x.location,
                    })), currentLocationDates.filter((x) => (
                        site === 'ANY' || x.location === site.id
                    ))).filter((x) => (
                        selectedPerson === 'ANY' || x.id === selectedPerson.id
                    )),
                ),
                ((bookedSlot
                    && (site === 'ANY' || bookedSlot.location === site.id)
                    && (selectedPerson === 'ANY' || bookedSlot.employee === selectedPerson.id)
                    && (bookedSlot.service === selectedService.id)) ? [bookedSlot] : []),
            ),
        ))
    ), [site, selectedPerson, selectedService, userTimezone, bookedSlot]);
    const slots = useMemo(() => (
        selectedService ? getSlots(locationDates) : []
    ), [selectedService, locationDates, getSlots]);
    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const promiseRef = useRef(null);
    useEffect(() => {
        (async () => {
            let currentWeek = selectedWeek;
            const prevRangeStart = moment(prevWeek.current).startOf('month');
            const prevRangeEnd = moment(prevWeek.current).endOf('week').endOf('month');
            const rangeStart = moment(currentWeek).startOf('month');
            const rangeEnd = moment(currentWeek).endOf('week').endOf('month');
            let dates = [];
            const sameService = prevService.current === selectedService;
            prevService.current = selectedService;
            prevWeek.current = currentWeek;
            if (selectedService && (!sameService
                || !prevRangeStart.isSame(rangeStart) || !prevRangeEnd.isSame(rangeEnd)
            )) {
                const getDates = async () => {
                    if (promiseRef.current) {
                        promiseRef.current[cancelRequest]();
                    }
                    promiseRef.current = BackendMethods.getAppointmentDates(
                        companyId,
                        selectedService.id,
                        moment(currentWeek).startOf('month').startOf('week').toISOString(),
                        moment(currentWeek).endOf('week').endOf('month').endOf('week').toISOString(),
                        isEdit ? Number(params.id) : null,
                    );
                    dates = (await promiseRef.current).filter((x) => x.employees.some((y) => y.dates.length !== 0));
                    currentWeek = moment(currentWeek).add(1, 'month');
                };
                if (sameService) {
                    await getDates();
                } else {
                    while (dates.length === 0 && currentWeek.diff(moment(), 'month') <= 12) {
                        await getDates();
                    }
                }
                currentWeek = moment(currentWeek).subtract(1, 'month');
                setLocationDates(dates);
                const newSlots = getSlots(dates);
                if (useLocalStorage1.length === 0) {
                    if (!sameService && newSlots.length > 0) {
                        setSelectedWeek(moment(newSlots[0].date).tz(userTimezone).startOf('week'));
                        setSelectedDate(moment(newSlots[0].date).tz(userTimezone).startOf('day'));
                    // } else if (!currentWeek.isSame(selectedWeek)) {
                    //     setSelectedWeek(currentWeek);
                    }
                } else {
                    useLocalStorage.pop();
                }
            }
        })();
    }, [companyId, selectedService, selectedWeek, params.id]);
    const daySlots = slots.filter((slot) => slot.date.isSame(selectedDate, 'day'));
    const morningSlots = daySlots.filter((slot) => (
        slot.date.isBefore(moment(slot.date).hour(12).startOf('hour'))
    ));
    const afternoonSlots = daySlots.filter((slot) => (slot.date.isBetween(
        moment(slot.date).hour(12).startOf('hour'),
        moment(slot.date).hour(18).startOf('hour'),
        'minute',
        '[)',
    )));
    const eveningSlots = daySlots.filter((slot) => (
        slot.date.isSameOrAfter(moment(slot.date).hour(18).startOf('hour'))
    ));
    const [showCustom, setShowCustom] = useState(false);
    const [customOpen, setCustomOpen] = useState(false);
    const [customDate, setCustomDate] = useState<Moment | null>(null);
    const [customError, setCustomError] = useState(false);
    const selectedSlotDate = selectedSlot?.date || customDate;
    useEffect(() => {
        const bookingDataRaw = localStorage.getItem('booking-data');
        if (sites && services && people && bookingDataRaw) {
            const bookingData = JSON.parse(bookingDataRaw);
            setSite(sites.find(R.propEq('id', bookingData.site)));
            setSelectedService(services.find(R.propEq('id', bookingData.service)));
            setSelectedPerson(people.find(R.propEq('id', bookingData.person)));
            if (bookingData.isCustomDate) {
                setCustomDate(moment(bookingData.date).tz(userTimezone));
            } else {
                setSelectedSlot({
                    date: moment(bookingData.date).tz(userTimezone),
                    location: bookingData.site,
                    employee: bookingData.person,
                });
            }
            if (bookingData.customer) {
                setInitialCustomer(bookingData.customer);
            }
            if (bookingData.bookingFor) {
                setInitialBookingFor(bookingData.bookingFor);
            }
            useLocalStorage.push(true);
            useLocalStorage1.push(true);
            setStep(CUSTOMER_STEP);
            gotoBooking();
            localStorage.removeItem('booking-data');
            setSelectedWeek(moment(bookingData.date).tz(userTimezone).startOf('week'));
            setSelectedDate(moment(bookingData.date).tz(userTimezone).startOf('day'));
        }
    }, [sites, services, people, userTimezone]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [initialNotes, setInitialNotes] = useState('');
    const [initialCustomer, setInitialCustomer] = useState<ICustomer | null>(null);
    const [initialBookingFor, setInitialBookingFor] = useState<ICustomer | null>(null);
    const [originalAppointment, setOriginalAppointment] = useState<IAppointment | null>(null);
    const loadAppointment = async (id: number) => {
        const from = '2000-01-01T00:00:00.000Z';
        const to = '2100-01-01T00:00:00.000Z';
        const allAppointments = await (isCustomer
            ? BackendMethods.getCustomerAppointments(from, to)
            : BackendMethods.getAppointments(from, to));
        const appointment = allAppointments.find(R.propEq('id', id));
        setSite(sites.find(R.propEq('id', appointment.location.id)));
        setSelectedPerson(people.find(R.propEq('id', appointment.employee.id)));
        setSelectedService(services.find(R.propEq('id', appointment.service.id)));
        setInitialNotes(appointment.notes);
        setInitialCustomer(appointment.customer);
        setInitialBookingFor(appointment.bookingFor);
        setOriginalAppointment(appointment);
        return appointment;
    };
    const gotoBooking = async () => {
        let contents: any;
        if (useLocalStorage.length === 0) {
            setStep(step + 1);
        } else {
            useLocalStorage.pop();
        }
        try {
            const types: string = 'PRIVACY_POLICY,TAC_CONSUMER,GDPR_CONSUMER';
            contents = await BackendMethods.getConsents(types);
            if (contents.length > 0) {
                setShowGDPRConsentModal(true);
            }
        } catch (error) {
            setStep(step-1);
        }
    };
    useEffect(() => {
        (async () => {
            if (sites && services && people && isRebook && !dataLoaded) {
                await loadAppointment(Number(params.id));
                setStep(DATE_STEP);
                setDataLoaded(true);
            }
        })();
    }, [isRebook, params.id, sites, services, people]);
    useEffect(() => {
        (async () => {
            if (sites && services && people && isEdit && !dataLoaded) {
                const appointment = await loadAppointment(Number(params.id));
                setBookedService(appointment.service);
                setStep(DATE_STEP);
                setDataLoaded(true);
            }
        })();
    }, [isEdit, params.id, sites, services, people]);
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    useEffect(() => {
        if (site !== 'ANY') {
            moment.tz.setDefault(site.timezone);
        }
        return () => {
            moment.tz.setDefault();
        };
    }, [site]);
    const selectSlot = (slot: ISlot) => {
        setSelectedSlot(slot);
        setSelectedPerson(people.find(R.propEq('id', slot.employee)));
        setSite(sites.find(R.propEq('id', slot.location)));
        setCustomDate(null);
    };
    const renderSlot = (slot: ISlot) => {
        const selected = slot.date.isSame(selectedSlot?.date);
        const { timezone } = sites.find((x) => x.id === slot.location);
        const isInWeek = ((selectedDate && selectedDate.isSameOrBefore(moment(selectedWeek).endOf('week')) && selectedDate.isSameOrAfter(moment(selectedWeek).startOf('week'))) || !isWeekView);
        return (
            <Button
                key={slot.date.toISOString()}
                color={selected && isInWeek ? 'secondary' : 'primary'}
                variant={selected && isInWeek ? 'contained' : 'text'}
                className={styles.slot}
                onClick={() => selectSlot(slot)}
            >
                {slot.date.tz(timezone).format('HH:mm')}
            </Button>
        );
    };
    const calendarSetSelectedDate = useCallback((newDate) => {
        setSelectedDate(newDate);
        setSelectedSlot(null);
    }, []);
    const shouldDisableDate = useCallback((date) => !slots.some((slot) => {
        const { timezone } = sites.find((x) => x.id === slot.location);
        return slot.date.tz(timezone).isSameOrAfter(date.startOf('day')) && slot.date.tz(timezone).isSameOrBefore(date.endOf('day'));
    }), [slots, sites]);
    if (!sites || !services || !people || ((isEdit || isRebook) && !dataLoaded)) {
        return <FallingLeaves />;
    }
    const filteredPeople = people.filter((person) => (
        person.services.length > 0
        && (site === 'ANY' || person.locations.includes(site.id))
        && person.name.toLowerCase().includes(search.toLowerCase())
        && (selectedService === null || person.services.includes(selectedService.id))
    ));
    const peopleDiv = (
        <div className={styles.people}>
            {search === '' && filteredPeople.length > 1 && (
                <div
                    className={classNames(styles.person, {
                        [styles.selectedPerson]: selectedPerson === 'ANY',
                    })}
                    onClick={() => setSelectedPerson('ANY')}
                >
                    <div className={styles.avatarContainer}>
                        <img
                            src="/assets/people.svg"
                            alt=""
                            className={styles.avatarAny}
                        />
                    </div>
                    <div className={styles.personName}>
                        {t('small.everyone')}
                    </div>
                </div>
            )}
            {filteredPeople.map((person) => (
                <div
                    key={person.id}
                    className={classNames(styles.person, {
                        [styles.selectedPerson]: selectedPerson === person,
                    })}
                    onClick={() => {
                        setSelectedPerson(person);
                        setSearch('');
                        setSelectedDate(null);
                        setSelectedSlot(null);
                    }}
                >
                    <div className={styles.avatarContainer}>
                        <img
                            src={person.picture || '/assets/human.svg'}
                            alt=""
                            className={person.picture ? styles.avatar : styles.defaultAvatar}
                        />
                    </div>
                    <Tooltip title={person.name} enterDelay={500}>
                        <div className={styles.personName}>
                            {person.name}
                        </div>
                    </Tooltip>
                </div>
            ))}
        </div>
    );
    const origin = getSubdomain() === null ? '' : window.location.origin;
    const redirect = encodeURIComponent(`${origin}/sb-customer/book/${companyId}/${site !== 'ANY' ? site.id : ''}`);
    const loginUrl = `/login?redirectURI=${redirect}`;
    const registerMergeUrl = `/register?merge=1&redirectURI=${redirect}`;
    const backUrl = isCustomer ? '/sb-customer/find-service' : '/sb/dashboard';
    const dashboardUrl = isCustomer ? '/sb-customer/my-appointments' : '/sb/dashboard';
    const timezoneMismatch = site !== 'ANY' && site.timezone !== userTimezone;
    const summary = (
        <>
            {isEdit && selectedService && originalAppointment.service.id !== selectedService.id && (
                <div className={styles.changed}>
                    {originalAppointment.service.name}
                </div>
            )}
            {selectedService && (
                <div>
                    {selectedService.name}
                </div>
            )}
            {isEdit && ((selectedPerson === 'ANY' || originalAppointment.employee.id !== selectedPerson.id)
                || (originalAppointment.service.price && originalAppointment.service.price !== selectedService?.price)) && (
                <div className={styles.spaceBetween}>
                    {(selectedPerson === 'ANY' || originalAppointment.employee.id !== selectedPerson.id) && (
                        <div className={styles.changed}>
                            {originalAppointment.employee.name}
                        </div>
                    )}
                    {originalAppointment.service.price && !R.equals(originalAppointment.service.price, selectedService?.price) && (
                        <div className={styles.changed}>
                            {renderPrice(originalAppointment.service.price)}
                        </div>
                    )}
                </div>
            )}
            <div className={styles.spaceBetween}>
                <div>
                    {selectedPerson !== 'ANY' && selectedPerson.name}
                </div>
                {selectedService?.price && (
                    <div>
                        {renderPrice(selectedService.price)}
                    </div>
                )}
            </div>
            {isEdit && (!selectedSlotDate || !selectedSlotDate.isSame(originalAppointment.begin)) && (
                <div className={styles.spaceBetween}>
                    <div className={styles.changed}>
                        {(!selectedSlotDate || !selectedSlotDate.isSame(originalAppointment.begin, 'day'))
                        && moment(originalAppointment.begin).format(
                            i18n.language === 'pl' ? 'dddd, D MMMM Y' : 'dddd, MMMM D, Y',
                        )}
                    </div>
                    <div className={styles.changed}>
                        {moment(originalAppointment.begin).format(
                            timeFormat === '12h' ? 'h:mm a' : 'H:mm',
                        )}
                        -
                        {moment(originalAppointment.end)
                            .format(timeFormat === '12h' ? 'h:mm a' : 'H:mm')}
                    </div>
                </div>
            )}
            {selectedSlotDate && selectedService && (
                <div className={styles.spaceBetween}>
                    <div>
                        {selectedSlotDate.format(
                            i18n.language === 'pl' ? 'dddd, D MMMM Y' : 'dddd, MMMM D, Y',
                        )}
                    </div>
                    <div>
                        {selectedSlotDate.format(
                            timeFormat === '12h' ? 'h:mm a' : 'H:mm',
                        )}
                        -
                        {moment(selectedSlotDate).add(selectedService.duration, 'minutes')
                            .format(timeFormat === '12h' ? 'h:mm a' : 'H:mm')}
                    </div>
                </div>
            )}
            {isEdit && (site === 'ANY' || originalAppointment.location.id !== site.id) && (
                <>
                    <div className={styles.changed}>
                        {originalAppointment.location.name}
                    </div>
                    <div className={styles.changed}>
                        {originalAppointment.location.address}
                    </div>
                </>
            )}
            <div>
                {site !== 'ANY' && site.name}
            </div>
            <div>
                {site !== 'ANY' && site.address}
            </div>
        </>
    );
    return (
        <div>
            {step !== BOOKED_STEP && (
                <div className={styles.nav}>
                    <IconButton
                        onClick={() => {
                            if (step === SERVICE_STEP) {
                                history.push(backUrl);
                            }
                            if (step === DATE_STEP) {
                                setSelectedService(null);
                            }
                            if (step !== SERVICE_STEP) {
                                setStep(step - 1);
                            }
                        }}
                        color="primary"
                        size="large">
                        <img src="/assets/back-icon.svg" alt="" className={styles.backChevron} />
                    </IconButton>
                    <div className={styles.navTitle}>
                        {step === SERVICE_STEP ? companyName : selectedService.name}
                    </div>
                    <IconButton color="primary" onClick={() => history.push(backUrl)} size="large">
                        <img src="/assets/close-icon.svg" alt="" className={styles.closeIcon} />
                    </IconButton>
                </div>
            )}
            {step === SERVICE_STEP && (
                <>
                    <div className={styles.selects}>
                        <Autocomplete<ILocation | 'ANY', false, true, false>
                            label={t('small.site')}
                            options={['ANY', ...sites]}
                            getOptionLabel={(x) => x === 'ANY' ? t('small.siteAny') : x.name}
                            value={site}
                            onChange={(event, newValue) => {
                                if (!isRebook && !isEdit && isCustomer) {
                                    const basePath = `/sb-customer/book/${companyId}`;
                                    history.push(`${basePath}${newValue === 'ANY' ? '' : `/${newValue.id}`}`);
                                }
                                setSite(newValue);
                                setSelectedPerson('ANY');
                                setSelectedService(null);
                                setSelectedDate(null);
                                setSelectedSlot(null);
                                setCustomDate(null);
                            }}
                            disableClearable
                        />
                        <TextField
                            label={t('small.searchServicePerson')}
                            value={search}
                            onChange={({ target }) => (
                                setSearch(target.value)
                            )}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img
                                            src="/assets/search-gray.svg"
                                            alt=""
                                            className={styles.searchIcon}
                                        />
                                    </InputAdornment>
                                ),
                                endAdornment: search ? (
                                    <InputAdornment position="end" className={styles.endAdornment}>
                                        <IconButton onClick={() => setSearch('')} size="large">
                                            <img
                                                src="/assets/cancel_icon_dark_blue.svg"
                                                alt=""
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                    </div>
                    {peopleDiv}
                    <div className={styles.services}>
                        {services.filter((service) => (
                            (site === 'ANY' || R.chain(
                                R.prop('services'),
                                people.filter((person) => (
                                    person.locations.includes(site.id)
                                )),
                            ).includes(service.id))
                            && service.name.toLowerCase().includes(search.toLowerCase())
                            && (selectedPerson === 'ANY' || selectedPerson.services.includes(service.id))
                            && (!isCustomer || !service.custHidden)
                        )).map((service) => {
                            const canBook = !(isCustomer && service.noSelf && service.id !== bookedService?.id);
                            const serviceSite = site === 'ANY'
                                ? sites.find(R.propEq(
                                    'id',
                                    (
                                        selectedPerson === 'ANY'
                                            ? people.find((person) => (
                                                person.services.includes(service.id)
                                                && person.locations.length > 0
                                            ))
                                            : selectedPerson
                                    )?.locations[0],
                                ))
                                : site;
                            if (!serviceSite) {
                                return null;
                            }
                            return (
                                <div key={service.id} className={styles.serviceContainer}>
                                    <div className={styles.service}>
                                        <div>
                                            {service.name}
                                        </div>
                                        <div className={styles.serviceDetails}>
                                            <div>
                                                {renderPrice(service.price)}
                                            </div>
                                            <div>
                                                {service.duration} min
                                            </div>
                                        </div>
                                    </div>
                                    {canBook ? (
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                setSelectedService(service);
                                                setSearch('');
                                                setSelectedDate(null);
                                                setSelectedSlot(null);
                                                setStep(step + 1);
                                            }}
                                            className={styles.bookButton}
                                        >
                                            {t('small.bookService')}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            component="a"
                                            href={`tel:${serviceSite.countryCode}${serviceSite.phone}`}
                                            className={styles.bookButton}
                                        >
                                            {t('small.call')}
                                        </Button>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
            {step === DATE_STEP && (
                <>
                    {(isEdit || isRebook) && (
                        <Button
                            variant="text"
                            onClick={() => setStep(SERVICE_STEP)}
                            className={styles.changeServiceLocation}
                        >
                            {t('small.changeServiceLocation')}
                        </Button>
                    )}
                    {peopleDiv}
                    <MobileCalendar
                        selectedWeek={selectedWeek}
                        setSelectedWeek={setSelectedWeek}
                        selectedDate={selectedDate}
                        setSelectedDate={calendarSetSelectedDate}
                        shouldDisableDate={shouldDisableDate}
                        isWeekView={isWeekView}
                        setIsWeekView={setIsWeekView}
                    />
                    {timezoneMismatch && (
                        <div className={styles.timezoneMismatch}>
                            {t('small.hoursTimezone', { timezone: moment().tz((site as ILocation).timezone).zoneAbbr() })}
                        </div>
                    )}
                    <div className={styles.slotContainer}>
                        {morningSlots.length > 0 && (
                            <>
                                <div className={styles.timeGroupHeader}>
                                    {t('small.morning')}
                                </div>
                                <div className={styles.slots}>
                                    {morningSlots.map(renderSlot)}
                                </div>
                            </>
                        )}
                        {afternoonSlots.length > 0 && (
                            <>
                                <div className={styles.timeGroupHeader}>
                                    {t('small.afternoon')}
                                </div>
                                <div className={styles.slots}>
                                    {afternoonSlots.map(renderSlot)}
                                </div>
                            </>
                        )}
                        {eveningSlots.length > 0 && (
                            <>
                                <div className={styles.timeGroupHeader}>
                                    {t('small.evening')}
                                </div>
                                <div className={styles.slots}>
                                    {eveningSlots.map(renderSlot)}
                                </div>
                            </>
                        )}
                        {!isCustomer && (
                            <>
                                {!showCustom && (
                                    <Button
                                        onClick={() => {
                                            if (site !== 'ANY' && selectedPerson !== 'ANY') {
                                                setShowCustom(true);
                                                setCustomOpen(true);
                                            } else {
                                                setCustomError(true);
                                            }
                                        }}
                                    >
                                        {t('small.anotherDate')}
                                    </Button>
                                )}
                                {showCustom && (
                                    <MobileDateTimePicker
                                        label={t('small.anotherDate')}
                                        disablePast
                                        value={customDate}
                                        onChange={(newDate) => {
                                            setCustomDate(moment(newDate).set({ seconds: 0 }));
                                            if (newDate) {
                                                setSelectedDate(moment(newDate).startOf('day'));
                                                setSelectedSlot(null);
                                            }
                                        }}
                                        renderInput={(params2) => <TextField {...params2} />}
                                        ampm={timeFormat === '12h'}
                                        open={customOpen}
                                        onOpen={() => setCustomOpen(true)}
                                        onClose={() => setCustomOpen(false)}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    <div className={styles.summary}>
                        {summary}
                        <div className={styles.summaryButtons}>
                            {isEdit && (
                                <Button
                                    variant="outlined"
                                    component={Link}
                                    to={backUrl}
                                    className={styles.discardChanges}
                                >
                                    {t('small.discardChanges')}
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                disabled={(
                                    !selectedService || !selectedSlotDate || selectedPerson === 'ANY'
                                )}
                                className={styles.continue}
                                {...profile ? {
                                    onClick: () => gotoBooking()
                                } : {
                                    component: Link,
                                    to: loginUrl,
                                        onClick: () => {
                                            if (site === 'ANY' || selectedPerson === 'ANY') {
                                                return;
                                            }
                                            localStorage.setItem('booking-data', JSON.stringify({
                                                site: site.id,
                                                service: selectedService.id,
                                                person: selectedPerson.id,
                                                date: selectedSlotDate.toISOString(),
                                                isCustomDate: customDate !== null,
                                        }));
                                        redirectToBaseDomain(loginUrl);
                                    },
                                }}
                            >
                                {isCustomer ? (isEdit ? t('small.reschedule') :  t('small.book')) : t('small.continue')}
                            </Button>
                        </div>
                    </div>
                </>
            )}
            {step === CUSTOMER_STEP && (
                <div className={styles.customerForm}>
                    {isEdit && (
                        <div className={styles.rescheduleHeader}>
                            {t('small.confirmReschedule')}
                            :
                        </div>
                    )}
                    <div className={styles.customerSummary}>
                        {summary}
                    </div>
                    <BookingCustomer
                        renderSummary={(data) => {
                            const { customer, bookingFor, isExtra, notes, customerEdited, bookingForEdited } = data;
                            return (
                                <div className={styles.bookRow}>
                                    <Button
                                        variant="text"
                                        component={Link}
                                        to={backUrl}
                                    >
                                        {isEdit ? t('small.discardChanges') : t('common.cancel')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={!customer || (isExtra && !bookingFor)}
                                        onClick={async () => {
                                            if (selectedPerson === 'ANY' || site === 'ANY') {
                                                return;
                                            }
                                            if (customerEdited) {
                                                if (isCustomer) {
                                                    await BackendMethods.updateUserProfile(customer);
                                                    await BackendMethods.updateMyCustomer(companyId, customer);
                                                } else if ('id' in customer) {
                                                    await BackendMethods.updateCustomer(customer);
                                                }
                                            }
                                            if (bookingForEdited && 'id' in bookingFor) {
                                                if (isCustomer) {
                                                    await BackendMethods.updateMyCare(companyId, {
                                                        countryCode: null,
                                                        phone: null,
                                                        ...bookingFor,
                                                    });
                                                } else if ('id' in customer) {
                                                    await BackendMethods.updateCare(customer.id, bookingFor);
                                                }
                                            }
                                            const newAppointment = {
                                                employee: selectedPerson.id,
                                                location: site.id,
                                                service: selectedService.id,
                                                date: selectedSlotDate.toISOString(),
                                                customDate: customDate !== null,
                                                notes,
                                                customer,
                                                bookingFor,
                                                ...(isEdit ? { id: Number(params.id) } : {}),
                                            };
                                            if (isEdit) {
                                                if (isCustomer) {
                                                    await BackendMethods.editAppointmentCustomer({
                                                        company: companyId,
                                                        ...newAppointment,
                                                    });
                                                } else {
                                                    await BackendMethods.editAppointment(newAppointment);
                                                }
                                            } else {
                                                if (isCustomer) {
                                                    await BackendMethods.bookAppointmentCustomer({
                                                        company: companyId,
                                                        ...newAppointment,
                                                    });
                                                } else {
                                                    await BackendMethods.bookAppointment(newAppointment);
                                                }
                                            }
                                            setStep(step + 1);
                                        }}
                                    >
                                        {isCustomer
                                            ? (isEdit ? t('small.confirmReschedule') : t('small.confirmBooking'))
                                            : (isEdit ? t('small.reschedule') : t('small.book'))}
                                    </Button>
                                </div>
                            );
                        }}
                        saveData={({ customer, bookingFor }) => {
                            if (site === 'ANY' || selectedPerson === 'ANY') {
                                return;
                            }
                            localStorage.setItem('booking-data', JSON.stringify({
                                site: site.id,
                                service: selectedService.id,
                                person: selectedPerson.id,
                                date: selectedSlotDate.toISOString(),
                                isCustomDate: customDate !== null,
                                customer,
                                bookingFor,
                            }));
                        }}
                        onAddEmail={() => redirectToBaseIfNeeded(history, registerMergeUrl)}
                        timezone={userTimezone}
                        initialNotes={initialNotes}
                        initialCustomer={initialCustomer}
                        initialBookingFor={initialBookingFor}
                        isEdit={isEdit}
                        isCustomer={isCustomer}
                        profile={profile}
                        companyId={companyId}
                        birthDateRequired={birthDateRequired}
                    />
                </div>
            )}
            {step === BOOKED_STEP && (
                <div className={styles.bookedContainer}>
                    <div className={styles.bookedHeader}>
                        {isEdit ? t('small.appointmentRescheduled') : t('small.bookingConfirmed')}
                    </div>
                    <img src="/assets/checkmark_icon.svg" alt="" className={styles.checkmark} />
                    <div className={styles.bookedText}>
                        {t('small.dayBeforeConfirmation')}
                    </div>
                    {(profile && !profile.email) && (
                        <>
                            <div className={styles.dontType}>
                                {t('small.dontTypeCodes')}
                            </div>
                            <Button
                                fullWidth
                                onClick={() => setEmailDialogOpen(true)}
                                className={styles.registerEmail}
                            >
                                {t('small.registerYourEmail')}
                            </Button>
                            <AddEmailDialog
                                open={emailDialogOpen}
                                onClose={() => setEmailDialogOpen(false)}
                            />
                        </>
                    )}
                    <Button
                        fullWidth
                        variant="contained"
                        component={Link}
                        to={dashboardUrl}
                        onClick={() => {
                            if (isCustomer) {
                                redirectToBaseDomain(dashboardUrl);
                            }
                        }}
                    >
                        {t('small.done')}
                    </Button>
                </div>
            )}
            <Dialog
                open={customError}
                PaperProps={{ className: styles.paper }}
                onClose={() => setCustomError(false)}
            >
                <div className={styles.errorDialogText}>
                    {t('small.selectLocationPerson')}
                </div>
                <Button
                    className={styles.dialogButton}
                    variant="contained"
                    onClick={() => setCustomError(false)}
                >
                    {t('small.ok')}
                </Button>
            </Dialog>
            {showGDPRConsentModal && profile && (
                <GDPRConsentModal
                    isDesktop={false}
                    setShowGDPRConsentModal={setShowGDPRConsentModal}
                    onClose={() => {
                        setShowGDPRConsentModal(false);
                        setStep(step - 1);
                    }}
                />
            )}
        </div>
    );
}
