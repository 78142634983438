import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Button from '../../components/_ReusableComponents/Button/Button';
import i18next from 'i18next';
import BackendMethods from '../../api/BackendMethods';
import { isAdHoc, isLoggedIn } from '../../scripts/helpers';
import GlobalContext from '../../GlobalContext';
const styles = require('./Footer.css');

export default function Footer() {
    const { t, i18n: { language } } = useTranslation();
    const loggedIn = isLoggedIn();
    const adHoc = isAdHoc();
    const { profile, updateContext } = useContext(GlobalContext);
    return (
        <div className={styles.root}>
            <div className={styles.copyright}>
                <span className={styles.line}>
                    Copyright © {moment().format('Y')} Toolla Inc.
                </span>
                {' '}
                <span className={styles.line}>
                    {t('common.allRights')}
                </span>
            </div>
            <Link to="/policy" className={styles.link}>
                {t('common.privacyPolicy')}
            </Link>
            <Link to="/terms" className={styles.link}>
                {t('common.termsAndConditions')}
            </Link>
            <Button
                variant="text"
                className={styles.switch}
                onClick={() => {
                    const newLanguage = language === 'pl' ? 'en' : 'pl';
                    i18next.changeLanguage(newLanguage);
                    if (loggedIn && !adHoc) {
                        updateContext((x) => ({
                            ...x,
                            profile: {
                                ...profile,
                                language: newLanguage,
                            },
                        }));
                        BackendMethods.updateUserProfile({
                            language: newLanguage,
                        });
                    }
                }}
            >
                {language === 'pl' ? 'Switch to English' : 'Przełącz na polski'}
            </Button>
        </div>
    );
}
