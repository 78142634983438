import * as React from 'react';
import { Link } from 'react-router-dom';
const Styles = require('./LinkToFAQ.css');

export default class LinkToFAQ extends React.PureComponent<{}, null> {
    render() {
        return (
            <Link to="/faq" className={Styles.LinkToFAQ}>
                <div>FAQ</div>
            </Link>
        );
    }
}
