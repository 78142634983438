import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebouncedEffect from 'use-debounced-effect';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import useCloudinary from '../../../utils/useCloudinary';
import Button from '../../../components/_ReusableComponents/Button/Button';
import { Checkbox, FormControlLabel, IconButton, MenuItem, Tooltip } from '@material-ui/core';
import BackendMethods from '../../../api/BackendMethods';
import containersMapStateToProps from '../../../utils/containersMapStateToProps';
import containersDispatchToProps from '../../../utils/containersMapDispatchToProps';
import IActions from '../../../actions/IActions';
import { useIsDesktop } from 'utils/responsive';
const connect = require('react-redux').connect;
const styles = require('./General.css');

function General({ actions }: { actions: IActions }) {
    const isDesktop = useIsDesktop();
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const subdomainInvalid = subdomain.includes('_');
    const [available, setAvailable] = useState<boolean | null>(null);
    useDebouncedEffect(() => {
        if (subdomain) {
            setAvailable(true);
        }
    }, 300, [subdomain]);
    const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const widget = useCloudinary((uploadInfo) => {
        setLogoUrl(uploadInfo.url);
    }, BackendMethods.getSbCloudinarySignature);
    const [inAdvance, setInAdvance] = useState<string>('without-limits');
    const [birthdateRequired, setBirthdateRequired] = useState(false);
    const [confirmationsRequired, setConfirmationsRequired] = useState(false);
    const [ready, setReady] = useState(false);
    useEffect(() => {
        (async () => {
            const data = await BackendMethods.getCompanyGeneral();
            setName(data.name);
            setSubdomain(data.subdomain || '');
            setLogoUrl(data.logo || null);
            setInAdvance(data.appointmentDateLimit || 'without-limits');
            setBirthdateRequired(data.birthDateRequired);
            setConfirmationsRequired(data.sendConfirmations);
            setReady(true);
        })();
    }, []);
    if (!widget || !ready) {
        return null;
    }
    const renderOption = (unit: string, count: number) => {
        return (
            <>
                {t('small.upTo')}
                {' '}
                {count}
                {' '}
                {t(`small.${unit}`, { count })}
                {' '}
                {t('small.inAdvance')}
            </>
        );
    };
    return (
        <div>
            <div className={styles.header}>
                {t('small.company')}
            </div>
            <TextField
                value={name}
                onChange={({ target }) => setName(target.value)}
                label={t('small.name')}
                className={styles.name}
            />
            <div className={styles.subdomain}>
                <TextField
                    value={subdomain}
                    onChange={({ target }) => {
                        setSubdomain(target.value);
                        setAvailable(null);
                    }}
                    label={t('small.subdomainName')}
                    className={styles.subdomainField}
                    error={available === false || subdomainInvalid}
                    helperText={(() => {
                        if (subdomainInvalid) {
                            return t('small.subdomainInvalid');
                        }
                        if (available) {
                            return t('small.nameAvailable');
                        }
                        if (available === false) {
                            return t('small.nameTaken');
                        }
                        return '';
                    })()}
                />
                <div className={styles.tanbuuDomain}>
                    .tanbuu.com
                </div>
            </div>
            <div className={styles.upload}>
                <Button
                    variant="contained"
                    onClick={() => widget.open()}
                    className={styles.uploadButton}
                >
                    <img src="/assets/plus-white.svg" alt="" className={styles.addIcon} />
                    {t('small.companyLogo')}
                </Button>
                {logoUrl && (
                    <div className={styles.previewOuter}>
                        <div className={styles.previewContainer}>
                            <img src={logoUrl} alt="" className={styles.preview} />
                            <IconButton
                                onClick={() => {
                                    setLogoUrl(null);
                                }}
                                className={styles.deleteButton}
                                size="large">
                                <img src="/assets/message-close.svg" alt="" />
                            </IconButton>
                        </div>
                    </div>
                )}
                <div className={styles.uploadTip}>
                    {t('small.sizeTip')}
                </div>
                <div className={styles.uploadTip}>
                    {t('small.companyLogoInfo')}
                </div>
            </div>
            <div className={styles.header}>
                {t('small.booking')}
            </div>
            <div className={styles.bookingContainer}>
                {t('small.allowBooking')}
                <TextField
                    value={inAdvance}
                    onChange={({ target }) => setInAdvance(target.value)}
                    select
                    className={styles.bookingField}
                >
                    <MenuItem value="P1W">
                        {renderOption('week', 1)}
                    </MenuItem>
                    <MenuItem value="P2W">
                        {renderOption('week', 2)}
                    </MenuItem>
                    <MenuItem value="P3W">
                        {renderOption('week', 3)}
                    </MenuItem>
                    <MenuItem value="P1M">
                        {renderOption('month', 1)}
                    </MenuItem>
                    <MenuItem value="P2M">
                        {renderOption('month', 2)}
                    </MenuItem>
                    <MenuItem value="P3M">
                        {renderOption('month', 3)}
                    </MenuItem>
                    <MenuItem value="P6M">
                        {renderOption('month', 6)}
                    </MenuItem>
                    <MenuItem value="P1Y">
                        {renderOption('year', 1)}
                    </MenuItem>
                    <MenuItem value="P2Y">
                        {renderOption('year', 2)}
                    </MenuItem>
                    <MenuItem value="without-limits">
                        {t('small.withoutLimits')}
                    </MenuItem>
                </TextField>
            </div>
            <div className={styles.checkboxes}>
                <FormControlLabel
                    checked={birthdateRequired}
                    onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setBirthdateRequired(target.checked)}
                    control={<Checkbox color="primary" />}
                    label={t('small.requireBirthdate')}
                />
                <div className={styles.inlineContent}>
                    <FormControlLabel
                        checked={confirmationsRequired}
                        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setConfirmationsRequired(target.checked)}
                        control={<Checkbox color="primary" />}
                        label={t('small.requireConfirmations')}
                        sx={{
                            marginRight: '3px',
                        }}
                    />
                    {isDesktop && (
                        <Tooltip
                            title={t('small.requireConfirmationsTooltip')}
                            placement="right"
                            classes={{ tooltip: styles.tooltip }}
                            PopperProps={{
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        }
                                    }
                                ]
                            }}
                        >
                            <span className={styles.info}>i</span>
                        </Tooltip>
                    )}
                </div>
            </div>
            <Button
                variant="contained"
                className={styles.saveButton}
                disabled={subdomainInvalid || available === false}
                onClick={async () => {
                    await BackendMethods.saveCompanyGeneral({
                        name,
                        subdomain: subdomain || null,
                        logo: logoUrl,
                        appointmentDateLimit: inAdvance === 'without-limits' ? null : inAdvance,
                        birthDateRequired: birthdateRequired,
                        sendConfirmations: confirmationsRequired,
                    });
                    actions.showSuccessMessage(t('small.settingsSaved'));
                }}
            >
                {t('small.save')}
            </Button>
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(General);
