// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".EnterpriseLogo__DiTkj {\n    align-items: center;\n    display: flex;\n    justify-content: center;\n    margin: 32px 0;\n}\n\n.EnterpriseLogo__DiTkj img {\n    height: 140px;\n    max-width: 100%;\n    width: auto;\n}\n", "",{"version":3,"sources":["EnterpriseLogo.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,WAAW;AACf","file":"EnterpriseLogo.css","sourcesContent":[".EnterpriseLogo {\n    align-items: center;\n    display: flex;\n    justify-content: center;\n    margin: 32px 0;\n}\n\n.EnterpriseLogo img {\n    height: 140px;\n    max-width: 100%;\n    width: auto;\n}\n"]}]);
// Exports
exports.locals = {
	"EnterpriseLogo": "EnterpriseLogo__DiTkj"
};
module.exports = exports;
