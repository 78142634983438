import React from 'react';
import Styles from './ForWhom.css';

interface Props {
    title: string;
    image: string;
    text: string[];
}
export const ForWhom = ({ title, image, text }: Props) => {
    return (
        <div className={Styles.content}>
            <div className={Styles.overlay}>
                <div className={Styles.itemBackground}>
                </div>
                <div className={Styles.itemText}>
                    <div className={Styles.sectionHeader}>
                        {title}
                    </div>
                    {text.map((item, index) => (
                        <p key={index} className={Styles.sectionText}>
                            {item}
                        </p>
                    ))}
                </div>
            </div>
            <img className={Styles.itemImage} src={image} alt="For whom?" />
        </div>
    );
};
