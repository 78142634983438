import React from 'react';
const styles = require('./BlinkingDots.css');

export default function BlinkingDots() {
    return (
        <div className={styles.root}>
            <div className={styles.dot} />
            <div className={styles.dot} />
            <div className={styles.dot} />
        </div>
    );
}
