import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useIsDesktop } from '../../utils/responsive';
import Button from '../../components/_ReusableComponents/Button/Button';
import scrollIntoView from 'scroll-into-view';
const styles = require('./FAQ.css');

export default function FAQ() {
    const { t } = useTranslation();
    const isDesktop = useIsDesktop();
    const scrollTo = (id: string) => () => scrollIntoView(
        document.getElementById(id),
        { align: { top: 0, topOffset: 60 } },
    );
    return (
        <div className={styles.root}>
            {!isDesktop && (
                <div className={styles.sectionLinks}>
                    <Button
                        variant="text"
                        className={styles.sectionLink}
                        onClick={scrollTo('faq-pricing')}
                    >
                        {t('landing.pricing')}
                    </Button>
                    <Button
                        variant="text"
                        className={styles.sectionLink}
                        onClick={scrollTo('faq-usage')}
                    >
                        {t('faq.usage')}
                    </Button>
                    <Button
                        variant="text"
                        className={styles.sectionLink}
                        onClick={scrollTo('faq-privacy')}
                    >
                        {t('faq.privacySecurity')}
                    </Button>
                    <Button
                        variant="text"
                        className={styles.sectionLink}
                        onClick={scrollTo('faq-misc')}
                    >
                        {t('faq.misc')}
                    </Button>
                </div>
            )}
            <div className={styles.header}>
                {t('landing.faq')}
            </div>
            <div className={styles.header2} id="faq-pricing">
                {t('landing.pricing')}
            </div>
            <div className={styles.subheader}>
                {t('faq.howMuch')}
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="faq.howMuchAnswer"
                    components={{
                        a: <Link to="/pricing" />,
                    }}
                />
            </div>
            <div className={styles.subheader}>
                {t('faq.enterpriseVersion')}
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="faq.enterpriseVersionAnswer"
                    components={{
                        a: <Link to="/contact" />,
                    }}
                />
            </div>
            <div className={styles.subheader}>
                {t('faq.whyPay')}
            </div>
            <div className={styles.block}>
                {t('faq.whyPayAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.paymentForms')}
            </div>
            <div className={styles.block}>
                {t('faq.paymentFormsAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.salesTax')}
            </div>
            <div className={styles.block}>
                {t('faq.salesTaxAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.tanbuuPricing')}
            </div>
            <div className={styles.block}>
                {t('faq.tanbuuPricingAnswer')}
            </div>
            <div className={styles.header2} id="faq-usage">
                {t('faq.usage')}
            </div>
            <div className={styles.subheader}>
                {t('faq.timeDateMeetings')}
            </div>
            <div className={styles.block}>
                {t('faq.timeDateMeetingsAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.meetingLength')}
            </div>
            <div className={styles.block}>
                {t('faq.meetingLengthAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.timeDateTimezones')}
            </div>
            <div className={styles.block}>
                {t('faq.timeDateTimezonesAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.efficientFind')}
            </div>
            <div className={styles.block}>
                {t('faq.efficientFindAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.howNotify')}
            </div>
            <div className={styles.block}>
                {t('faq.howNotifyAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.slotAccept')}
            </div>
            <div className={styles.block}>
                {t('faq.slotAcceptAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.extraPerson')}
            </div>
            <div className={styles.block}>
                {t('faq.extraPersonAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.diffTimeZone')}
            </div>
            <div className={styles.block}>
                {t('faq.diffTimeZoneAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.notReceivedInvite')}
            </div>
            <div className={styles.block}>
                {t('faq.notReceivedInviteAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.dontWantAttend')}
            </div>
            <div className={styles.block}>
                {t('faq.dontWantAttendAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.cancelMeeting')}
            </div>
            <div className={styles.block}>
                {t('faq.cancelMeetingAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.changeMind')}
            </div>
            <div className={styles.block}>
                {t('faq.changeMindAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.combineEvents')}
            </div>
            <div className={styles.block}>
                {t('faq.combineEventsAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.correctTimezone')}
            </div>
            <div className={styles.block}>
                {t('faq.correctTimezoneAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.guaranteed')}
            </div>
            <div className={styles.block}>
                {t('faq.guaranteedAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.yahoo')}
            </div>
            <div className={styles.block}>
                {t('faq.yahooAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.installExtension')}
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="faq.installExtensionAnswer"
                    components={{
                        a: <a href="https://youtu.be/GMDebQnG5_s" rel="noreferrer noopener" />,
                    }}
                />
            </div>
            <div className={styles.subheader}>
                {t('faq.useExtension')}
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="faq.useExtensionAnswer"
                    components={{
                        a: <a href="https://youtu.be/VIwRvZTnK5c" rel="noreferrer noopener" />,
                    }}
                />
            </div>
            <div className={styles.subheader}>
                {t('faq.zoom')}
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="faq.zoomAnswer"
                    components={{
                        a: <Link to="/zoom-docs" />,
                    }}
                />
            </div>
            <div className={styles.subheader}>
                {t('faq.mobile')}
            </div>
            <div className={styles.block}>
                <Trans
                    i18nKey="faq.mobileAnswer"
                    components={{
                        a: <Link to="/" />,
                    }}
                />
            </div>
            <div className={styles.subheader}>
                {t('faq.contacts')}
            </div>
            <div className={styles.block}>
                {t('faq.contactsAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.alexa')}
            </div>
            <div className={styles.block}>
                {t('faq.alexaAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.spam')}
            </div>
            <div className={styles.block}>
                {t('faq.spamAnswer')}
            </div>
            <div className={styles.header2} id="faq-privacy">
                {t('faq.privacySecurity')}
            </div>
            <div className={styles.subheader}>
                {t('faq.personalInformation')}
            </div>
            <Trans
                i18nKey="faq.personalInformationAnswer"
                components={{
                    p: <div className={styles.block} />,
                }}
            />
            <div className={styles.subheader}>
                {t('faq.collectData')}
            </div>
            <div className={styles.block}>
                <ol className={styles.list}>
                    <Trans
                        i18nKey="faq.collectDataAnswer"
                        components={{
                            li: <li className={styles.listElement} />,
                        }}
                    />
                </ol>
            </div>
            <div className={styles.subheader}>
                {t('faq.dataType')}
            </div>
            <div className={styles.block}>
                {t('faq.dataTypeAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.sellData')}
            </div>
            <div className={styles.block}>
                {t('faq.sellDataAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.shareCalendar')}
            </div>
            <div className={styles.block}>
                {t('faq.shareCalendarAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.revokeCalendar')}
            </div>
            <Trans
                i18nKey="faq.revokeCalendarAnswer"
                components={{
                    p: <div className={styles.block} />,
                    a: <a href="https://myaccount.google.com/permissions" rel="noreferrer noopener" />,
                }}
            />
            <div className={styles.subheader}>
                {t('faq.secure')}
            </div>
            <div className={styles.block}>
                {t('faq.secureAnswer')}
            </div>
            <div className={styles.header2} id="faq-misc">
                {t('faq.misc')}
            </div>
            <div className={styles.subheader}>
                {t('faq.tanbuuMean')}
            </div>
            <Trans
                i18nKey="faq.tanbuuMeanAnswer"
                components={{
                    p: <div className={styles.block} />,
                    ol: <ol className={styles.list} />,
                    li: <li className={styles.listElement} />,
                }}
            />
            <div className={styles.subheader}>
                {t('faq.differentiates')}
            </div>
            <div className={styles.block}>
                {t('faq.differentiatesAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.dandelion')}
            </div>
            <div className={styles.block}>
                {t('faq.dandelionAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.whatIs')}
            </div>
            <div className={styles.block}>
                {t('faq.whatIsAnswer')}
            </div>
            <div className={styles.subheader}>
                {t('faq.logo')}
            </div>
            <div className={styles.block}>
                {t('faq.logoAnswer')}
            </div>
            <div className={styles.block}>
                <Link
                    to="/zoom-docs"
                    className={styles.link}
                >
                    Zoom documentation
                </Link>
            </div>
        </div>
    );
}
