import React, { useContext } from 'react';
import moment from 'moment-timezone';
import IActions from '../../../../../actions/IActions';
import getStripe from '../../../../../utils/getStripe';
import Button from '../../../../_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../../../GlobalContext';
const styles = require('./PlanInfo.css');

interface IPlanInfoProps {
    data: any;
    actions: IActions;
    plusPlanAdmin: any;
}

const PlanInfo = (props: IPlanInfoProps) => {
    const { t, i18n } = useTranslation();
    const subscription = props.data.get('subscription');
    const { profile: { timezone } } = useContext(GlobalContext);
    const period = subscription.interval === 'YEAR' ? 'yearly' : 'monthly';
    const periodShort = subscription.interval === 'YEAR' ? 'yr' : 'mo';
    const isRenewing = subscription.renewing > 0;
    const expiresDate = moment.tz(subscription.expires, timezone)
        .format(i18n.language === 'pl' ? 'D MMMM Y' : 'MMMM D, Y');
    const expiring = subscription.licenses - subscription.renewing;
    const status = subscription.status;
    const paymentStatus = subscription.payment.status;

    const confirmPayment = async () => {
        const stripe = await getStripe(i18n.language);
        const token = subscription.payment.three_d_secure;
        const { error } = await stripe.handleCardPayment(token);
        if (!error) {
            props.actions.getSubscription();
        }
    };

    return (
        <section className={styles.PlanInfo}>
            <div>
                {status !== 'ACTIVE' && (
                    <p className={styles.Danger}>
                        {t('plan.inactive')}
                    </p>
                )}
                {paymentStatus === 'FAILED' && (
                    <p className={styles.Danger}>
                        {t('plan.paymentFailed')}.
                    </p>
                )}
                {paymentStatus === 'PENDING' && (
                     <Button
                        variant="contained"
                        fullWidth
                        className={styles.DangerButton}
                        onClick={confirmPayment}
                     >
                         {t('plan.confirmPayment')}
                     </Button>
                )}
                {paymentStatus === 'FAILED' && status !== 'INCOMPLETE_EXPIRED' && (
                    <Button
                        variant="contained"
                        fullWidth
                        className={styles.DangerButton}
                        onClick={() => props.actions.retryPayment()}
                    >
                        {t('plan.retryPayment')}
                    </Button>
                )}
                {isRenewing && (
                    <p>
                        {t('plan.billed')}
                        {' '}
                        {t(`plan.${period}`)}
                    </p>
                )}
                {status !== 'INCOMPLETE_EXPIRED' && (
                    <p>
                        {isRenewing
                            ? t('plan.nextBill')
                            : (status === 'EXPIRED'
                                ? t('plan.planExpired')
                                : t('plan.planExpires'))}
                        {': '}
                        {expiresDate}
                    </p>
                )}
                <p>{t('plan.autoRenewal')} {isRenewing ? t('plan.on') : t('plan.off')}.</p>
                <p>{t('plan.totalLicenses')}: {subscription.licenses}</p>
                {isRenewing && expiring > 0 && (
                    <p className={styles.Danger}>
                        {expiring}
                        {' '}
                        {t('plan.licenseExpire', { count: expiring })}
                        {' '}
                        {expiresDate}
                    </p>
                )}
            </div>
            <div className={styles.totalContainer}>
                {isRenewing && (
                    <div>
                        <p>
                            {t('plan.currentTotal', { type: t(`plan.${period}`) })}
                            :
                        </p>
                        <p>
                            ${(subscription.charge / 100).toFixed(2)}
                            {' / '}
                            {t(`plan.${periodShort}`)}
                            <small> + {t('plan.applicableTax')}</small>
                        </p>
                    </div>
                )}
                <Button
                    variant="contained"
                    onClick={() => props.actions.changeActivePanel('edit')}
                    className={styles.Button}
                    data-testid="plan-edit-button"
                >
                    {t('plan.edit')}
                </Button>
            </div>
        </section>
    );
};

export default PlanInfo;
