import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Auth from 'auth/Auth';
import Validators from '../../../utils/Validators/Validators';
import { getAuthToken, getSearchParam, parseJwt } from '../../../scripts/helpers';
import TextField from '../../_ReusableComponents/TextField/TextField';
import Button from '../../_ReusableComponents/Button/Button';
import PasswordField from '../../_ReusableComponents/PasswordField/PasswordField';
import { useTranslation, Trans } from 'react-i18next';
const Styles = require('./Register.css');

interface Props {
    actions: any;
    showError: (error: string) => void;
    showSuccess: (email: string) => void;
}

export default function Register(props: Props) {
    const { i18n, t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const isMerge = getSearchParam(location.search, 'merge');
    const redirectURI = getSearchParam(location.search, 'redirectURI');
    useEffect(() => {
        if (isMerge) {
            localStorage.setItem('mergeAuthToken', getAuthToken());
        }
        if (redirectURI) {
            localStorage.setItem('mergeRedirectURI', redirectURI);
        }
        if (isMerge || redirectURI) {
            history.replace('/register');
        }
    }, [isMerge, redirectURI]);
    const token = getSearchParam(location.hash, 'id_token');
    const defaultEmail = token
        ? (parseJwt(token)?.name || '')
        : getSearchParam(location.search, 'email') || '';
    const [email, setEmail] = useState<string>(defaultEmail);
    const [emailError, setEmailError] = useState<string>(null);
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>(null);
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [repeatPasswordError, setRepeatPasswordError] = useState<string>(null);
    const submit = async (event) => {
        event.preventDefault();
        const emailValid = Validators.isValidEmail(email);
        setEmailError(emailValid ? null : t('auth.emailInvalid'));
        const passwordValid = Validators.isValidPassword(password);
        setPasswordError(passwordValid ? null : t('auth.passwordFormat'));
        const repeatPasswordValid = repeatPassword === password;
        setRepeatPasswordError(repeatPasswordValid ? null : t('auth.passwordsMatch'));
        if (emailValid && passwordValid && repeatPasswordValid) {
            const response = await Auth.signUp(email, password, i18n.language);
            if (response.status === 200) {
                props.showSuccess(email);
            } else {
                const { code, description } = await response.json();
                if (code === 'user_exists') {
                    props.showError(t('auth.userExists'));
                } else {
                    props.showError(description);
                }
            }
        }
    };

    return (
        <form noValidate onSubmit={submit}>
            <div className={Styles.Register}>
                <TextField
                    value={email}
                    type="email"
                    label={t('auth.email')}
                    onChange={({ target }) => {
                        setEmail(target.value);
                        setEmailError(null);
                    }}
                    autoComplete="email"
                    error={emailError !== null}
                    helperText={emailError}
                    fullWidth
                    margin="dense"
                    data-testid="register-email"
                />
                <PasswordField
                    value={password}
                    onChange={({ target }) => {
                        setPassword(target.value);
                        setPasswordError(null);
                    }}
                    autoComplete="new-password"
                    label={t('auth.password')}
                    error={passwordError !== null}
                    helperText={passwordError}
                    fullWidth
                    margin="dense"
                    data-testid="register-password"
                />
                <PasswordField
                    value={repeatPassword}
                    label={t('auth.repeatPassword')}
                    onChange={({ target }) => {
                        setRepeatPassword(target.value);
                        setRepeatPasswordError(null);
                    }}
                    autoComplete="new-password"
                    error={repeatPasswordError !== null}
                    helperText={repeatPasswordError}
                    fullWidth
                    margin="dense"
                    data-testid="register-repeat-password"
                />
                <div>
                    <div className={Styles.AdditionalInfo}>
                        <Trans
                            i18nKey="auth.byRegistering"
                            components={{
                                span: <span />,
                                a: <Link to="/terms" className={Styles.AdditionalInfo__Link} />,
                            }}
                        />
                    </div>
                    <Button
                        variant="contained"
                        id="RegisterButton"
                        type="submit"
                        fullWidth
                        data-testid="register-submit-button"
                    >
                        {t('auth.register')}
                    </Button>
                </div>
            </div>
        </form>
    );
}
