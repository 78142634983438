import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
const styles = require('./FallingLeaves.css');

// From https://stackoverflow.com/a/2450976/3853934
function shuffle(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_integer_between_two_values
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

const FallingLeaves = () => {
    const [animationType, setAnimationType] = useState(null);
    const [leaves, setLeaves] = useState(null);
    useEffect(() => {
        setAnimationType(['left', 'right'][getRandomInt(0, 2)]);
        setLeaves(R.compose(
            shuffle,
            R.map(x => R.set(R.lensProp('size'), getRandomInt(35, 95), x)),
            R.chain(x => R.repeat(x, 6)),
            R.map(x => ({ type: x })),
        )(R.range(1, 4)));
    }, []);
    if (animationType === null) {
        return null;
    }
    return (
        <div className={styles.root}>
            <div className={classNames(styles.leaves, styles[`animation-${animationType}`])}>
                {leaves.map((leaf, i) => (
                    <span
                        key={i}
                        className={styles[`leaf${animationType === 'left' ? '1' : '2'}-${leaf.type}`]}
                        style={{
                            width: leaf.size,
                            height: leaf.size
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default FallingLeaves;
