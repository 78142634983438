import React, { useState } from 'react';
import classNames from 'classnames';
import * as R from 'ramda';
import { Popover } from '@material-ui/core';
import IProposition from '../../../../interfaces/IProposition';
import { IParticipant } from '../../../../interfaces/interfaces';
import { useTranslation } from 'react-i18next';
const styles = require('./PropositionResponses.css');

interface Props {
    userEmail: string;
    participants: IParticipant[];
    proposition: IProposition;
    isSetDate: boolean;
}

export default function PropositionResponses(props: Props) {
    const { t, i18n } = useTranslation();
    const { proposition, participants } = props;
    const total = participants.length;
    const confirmed = proposition.acceptedBy.length;
    const rejected = (proposition.rejectedBy || []).length;
    const otherParticipantConfirmed = total === 2 && confirmed === 1 && proposition.acceptedBy[0] !== props.userEmail;
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    return (
        <div className={styles.root}>
            <div
                onClick={event => setAnchorEl(event.currentTarget)}
                className={styles.content}
            >
                {i18n.language === 'pl' && (
                    <div className={styles.confirmed}>
                        {props.isSetDate ? t('home.responded') : t('home.confirmed')}
                        :
                    </div>
                )}
                <div className={styles.participants}>
                    {R.reverse(R.times(i => (
                        <div
                            key={i}
                            className={classNames(styles.participant, {
                                [styles.participantConfirmed]: i < confirmed,
                                [styles.participantRejected]: i >= confirmed && i < confirmed + rejected,
                            })}
                        />
                    ), total))}
                </div>
                <div className={styles.confirmed}>
                    {otherParticipantConfirmed && (
                        props.participants.find(R.whereEq({ email: proposition.acceptedBy[0] }))?.name
                        || proposition.acceptedBy[0]
                    )}
                    {' '}
                    {i18n.language === 'en' && (
                        props.isSetDate ? t('home.responded') : t('home.confirmed')
                    )}
                </div>
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                PaperProps={{ className: styles.popover }}
                onClose={() => setAnchorEl(null)}
            >
                <div className={styles.popoverBody}>
                    <div className={styles.popoverTitle}>{t('home.participants')}:</div>
                    {props.participants.map(participant => (
                        <div className={styles.answer} key={participant.email}>
                            <div className={styles.answerName}>
                                {participant.name || participant.email}
                            </div>
                            {(() => {
                                if (proposition.acceptedBy.includes(participant.email)) {
                                    return <div className={styles.answerYes}>{t('home.yes')}</div>;
                                } else if ((proposition.rejectedBy || []).includes(participant.email)) {
                                    return <div className={styles.answerNo}>{t('home.no')}</div>;
                                } else {
                                    return <div>?</div>;
                                }
                            })()}
                        </div>
                    ))}
                </div>
            </Popover>
        </div>
    );
}
