// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".subHeader__3TkRg {\n    font-size: 20px;\n    margin: 0;\n}\n\n.radioGroup__3nfnK {\n    margin-left: 32px;\n    margin-top: 10px;\n    margin-bottom: 40px;\n}\n\n.radionContent__3wapR {\n    margin-top: 18px;\n    display: flex;\n    align-items: center;\n}\n\n.radioSkeleton__1571n {\n    margin-left: 26px;\n}\n\n.btnGroup__1pqb5 {\n    margin: 0 6px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.setButton__16_9X {\n    background-color: #1488a8;\n    color: white;\n}\n\n.setButton__16_9X:hover {\n    background-color: #29b;\n    color: white;\n}\n", "",{"version":3,"sources":["SetICloudEvent.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB","file":"SetICloudEvent.css","sourcesContent":[".subHeader {\n    font-size: 20px;\n    margin: 0;\n}\n\n.radioGroup {\n    margin-left: 32px;\n    margin-top: 10px;\n    margin-bottom: 40px;\n}\n\n.radionContent {\n    margin-top: 18px;\n    display: flex;\n    align-items: center;\n}\n\n.radioSkeleton {\n    margin-left: 26px;\n}\n\n.btnGroup {\n    margin: 0 6px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.setButton {\n    background-color: #1488a8;\n    color: white;\n}\n\n.setButton:hover {\n    background-color: #29b;\n    color: white;\n}\n"]}]);
// Exports
exports.locals = {
	"subHeader": "subHeader__3TkRg",
	"radioGroup": "radioGroup__3nfnK",
	"radionContent": "radionContent__3wapR",
	"radioSkeleton": "radioSkeleton__1571n",
	"btnGroup": "btnGroup__1pqb5",
	"setButton": "setButton__16_9X"
};
module.exports = exports;
