import React from 'react';
import { IAppointment } from '../Dashboard/Dashboard';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import classNames from 'classnames';
const styles = require('./AffectedAppointments.css');

interface Props {
    appointments: IAppointment[];
    isDelete?: boolean;
    className?: string;
}

export default function AffectedAppointments({ appointments, isDelete, className }: Props) {
    const { t } = useTranslation();
    if (appointments.length === 0) {
        return null;
    }
    return (
        <Tooltip
            title={(
                <div>
                    <div>{isDelete ? t('small.changeDelete') : t('small.changeAffect')}</div>
                    {appointments.map((appointment, i) => {
                        const customer = appointment.bookingFor || appointment.customer;
                        return (
                            <div key={appointment.id}>
                                {i + 1}
                                {'. '}
                                {customer.firstName}
                                {' '}
                                {customer.lastName}
                                {', '}
                                {moment(appointment.begin).format('HH:mm')}
                                -
                                {moment(appointment.end).format('HH:mm')}
                                {', '}
                                {appointment.location.name}
                                {' '}
                                {appointment.location.address}
                            </div>
                        );
                    })}
                </div>
            )}
            classes={{ tooltip: styles.tooltip }}
        >
            <span className={classNames(styles.info, className)}>i</span>
        </Tooltip>
    );
}
