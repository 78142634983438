import * as React from 'react';
import IActions from 'actions/IActions';
import Button from '../../_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
const Styles = require('./GroupNavigation.css');

interface Props {
    actions: IActions;
    history: any;
}

const GroupNavigation = (props: Props) => {
    const { t } = useTranslation();
    return (
        <section className={Styles.GroupNavigation}>
            <Button
                variant="text"
                onClick={() => props.history.goBack()}
                data-testid="continue"
                className={Styles.backButton}
            >
                {t('plan.back')}
            </Button>
            <Button
                variant="contained"
                onClick={() => props.history.push('/main')}
                data-testid="continue"
                className={Styles.Button}
            >
                {t('plan.done')}
            </Button>
        </section>
    );
};

export default GroupNavigation;
