// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".sent__3VI6m {\n    margin-top: 16px;\n}\n\n.sentAddress__2Eo0E {\n    color: #013253;\n    font-weight: 400 !important;\n}\n\n.field__325vV {\n    margin: 16px 0;\n}\n", "",{"version":3,"sources":["Forgot.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB","file":"Forgot.css","sourcesContent":[".sent {\n    margin-top: 16px;\n}\n\n.sentAddress {\n    color: #013253;\n    font-weight: 400 !important;\n}\n\n.field {\n    margin: 16px 0;\n}\n"]}]);
// Exports
exports.locals = {
	"sent": "sent__3VI6m",
	"sentAddress": "sentAddress__2Eo0E",
	"field": "field__325vV"
};
module.exports = exports;
