import React from 'react';
import ENICloudHelper from 'components/Data/ICloudCalendarHelper/en.json';
import PLICloudHelper from 'components/Data/ICloudCalendarHelper/pl.json';
import { useTranslation } from 'react-i18next';
import Styles from './ICloudCalendarHelper.css';
import { Helper } from 'components/_ReusableComponents/MyHelper/Helper';

export const ICloudCalendarHelper = () => {
    const { i18n } = useTranslation();

    return (
        <div className={Styles.root}>
            <Helper
                data={i18n.language === 'en' ? ENICloudHelper : PLICloudHelper}
            />
        </div>
    );
};
