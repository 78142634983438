import axios from 'axios';
import IBackendRequest from 'interfaces/IBackendRequest';
import { deleteAuthCookies, getAuthToken, parseJwt } from '../scripts/helpers';
import Auth from '../auth/Auth';
import moment from 'moment';

export const cancelRequest = Symbol('cancelRequest');

const backendRequest = (req: IBackendRequest): Promise<any> => {
    const source = axios.CancelToken.source();
    let promiseResolved = false;
    async function sendRequest() {
        try {
            const { data } = await axios.request({
                url: `/api/${req.path}`,
                method: req.type,
                headers: {
                    ...(req.skipTokenChecking ? {} : {
                        Authorization: getAuthToken(),
                    }),
                    'Content-Type': 'application/json',
                },
                data: req.data,
                params: req.params,
                responseType: req.responseType,
                cancelToken: source?.token,
            });
            promiseResolved = true;
            return data;
        } catch (error) {
            promiseResolved = true;
            if (error?.response?.status === 401) {
                Auth.logout();
            }
            if (axios.isCancel(error)) {
                return new Promise(() => {});
            }
            throw error;
        }
    }
    const promise = (() => {
        if (req.skipTokenChecking === true) {
            return sendRequest();
        } else {
            const jwt = parseJwt(getAuthToken());
            if (moment.unix(jwt.exp).isBefore(moment())) {
                deleteAuthCookies();
                location.reload();
                return Promise.reject();
            } else {
                return sendRequest();
            }
        }
    })();
    promise[cancelRequest] = () => {
        if (!promiseResolved) {
            source.cancel();
        }
    };
    return promise;
};

export default backendRequest;
