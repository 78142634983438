// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PaymentMethod__1_CYK {\n    background-color: #f5fbff;\n    border-radius: 22px;\n    padding: 8px 16px 16px;\n    margin: 16px 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-end;\n}\n\n    .PaymentMethod__1_CYK p {\n        margin: 8px 0;\n    }\n\n.Button__1b4XL {\n    font-size: 16px;\n    width: 80px;\n}\n", "",{"version":3,"sources":["PaymentMethod.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,sBAAsB;IACtB,cAAc;IACd,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;AAKzB;;IAHI;QACI,aAAa;IACjB;;AAGJ;IACI,eAAe;IACf,WAAW;AACf","file":"PaymentMethod.css","sourcesContent":[".PaymentMethod {\n    background-color: #f5fbff;\n    border-radius: 22px;\n    padding: 8px 16px 16px;\n    margin: 16px 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-end;\n\n    & p {\n        margin: 8px 0;\n    }\n}\n\n.Button {\n    font-size: 16px;\n    width: 80px;\n}\n"]}]);
// Exports
exports.locals = {
	"PaymentMethod": "PaymentMethod__1_CYK",
	"Button": "Button__1b4XL"
};
module.exports = exports;
