import * as React from 'react';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import IActions from 'actions/IActions';
import Billing from '../../components/BillingComponents/Billing/Billing';
import PlusPlanHeader from '../../components/_ReusableComponents/Header/PlusPlanHeader/PlusPlanHeader';
import Header from '../../components/_ReusableComponents/Header/Header/Header';
import AdminLinks from '../../components/_ReusableComponents/AdminLinks/AdminLinks';
import { useTranslation } from 'react-i18next';
const connect = require('react-redux').connect;

interface IBillingProps {
    plusPlanPayment: any;
    data: any;
    actions: IActions;
    plusPlanAdmin: any;
}

function BillingPage(props: IBillingProps) {
    const { t } = useTranslation();
    return (
        <div>
            <Header />
            <AdminLinks active="billing" />
            <PlusPlanHeader text={t('plan.billingHistory')} />
            <Billing {...props} />
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(BillingPage);
