import React, { useEffect, useRef } from 'react';
import { useIsDesktop } from '../../utils/responsive';
import MobileNav from './MobileNav';
import Nav from './Nav';
import Footer from './Footer';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import LandingPage from './LandingPage';
import MobilePricing from './MobilePricing';
import CustomerSuccess from './CustomerSuccess';
import Features from './Features';
import ForSales from './ForSales';
import FAQ from './FAQ';
import ZoomDocs from './ZoomDocs';
import Contact from './Contact';
import About from './About';
import Policy from './Policy';
import Terms from './Terms';
import { ProfessionalTerms } from './ProfessionalTerms';
import { DataProcessingContract } from './DataProcessingContract';
import { ICloudCalendarHelper } from './ICloudCalendarHelper';
const styles = require('./Public.css');

export default function Public() {
    const isDesktop = useIsDesktop();
    const location = useLocation();
    const prevPathname = useRef<string | null>(null);
    const nowUrl = window.location.href;
    useEffect(() => {
        if (prevPathname.current && location.pathname !== prevPathname.current) {
            window.scrollTo(0, 0);
        }
        prevPathname.current = location.pathname;
    }, [location.pathname]);
    return (
        <div>
            {isDesktop ? <Nav /> : <MobileNav />}
            <div className={styles.main}>
                <Switch>
                    <Route path="/customer-success" component={CustomerSuccess} />
                    <Route path="/for-sales" component={ForSales} />
                    <Route path="/faq" component={FAQ} />
                    <Route path="/zoom-docs" component={ZoomDocs} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/about" component={About} />
                    <Route path="/policy" component={Policy} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/professional-terms" component={ProfessionalTerms} />
                    <Route path="/icloud-docs" component={ICloudCalendarHelper} />
                    <Route path="/data-processing-contract" component={DataProcessingContract} />
                    {isDesktop ? (
                        <Redirect from="/features" to="/#features" />
                    ) : (
                        <Route path="/features" component={Features} />
                    )}
                    {isDesktop ? (
                        <Redirect from="/pricing" to="/#pricing" />
                    ) : (
                        <Route path="/pricing" component={MobilePricing} />
                    )}
                    <Route path="/" component={LandingPage} />
                </Switch>
            </div>
            {nowUrl.includes('icloud-docs') ? <Footer isBackgroundColor={true} /> : <Footer />}
        </div>
    );
}
