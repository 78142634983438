import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHelperText } from '@material-ui/core';
import Validators from 'utils/Validators/Validators';
import IActions from 'actions/IActions';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
const config = require('CONFIG');
const styles = require('./SupportForm.css');

interface Props {
    app: any;
    actions: IActions;
    isLandingContactForm?: any;
    email?: string;
}

export default function SupportForm(props: Props) {
    const { t } = useTranslation();
    const [email, setEmail] = useState(props.email || '');
    const [emailError, setEmailError] = useState<string>(null);
    const [title, setTitle] = useState('');
    const [titleError, setSubjectError] = useState<string>(null);
    const [text, setText] = useState('');
    const [textError, setBodyError] = useState<string>(null);
    const captcha = useRef(null);
    const [captchaError, setCaptchaError] = useState<string>(null);
    const sendSupportMessage = (event) => {
        event.preventDefault();
        const emailValid = Validators.isValidEmail(email);
        setEmailError(emailValid ? null : t('home.emptyEmail'));
        const titleValid = title !== '';
        setSubjectError(titleValid ? null : t('home.emptyTitle'));
        const textValid = text !== '';
        setBodyError(textValid ? null : t('home.emptyMessage'));
        const captchaResponse = props.isLandingContactForm ? captcha.current.getValue() : null;
        const captchaValid = captchaResponse !== '' || !props.isLandingContactForm;
        setCaptchaError(captchaValid ? null : t('home.fillCaptcha'));
        if (emailValid && titleValid && textValid && captchaValid) {
            props.actions.sendSupportMessage({
                email,
                title,
                text,
                ...(captchaResponse ? { captchaResponse } : {}),
            });
        }
    };
    const sent = props.app.get('supportMessageSent');
    useEffect(() => {
        if (sent) {
            setEmail('');
            setTitle('');
            setText('');
            if (captcha.current) {
                captcha.current.reset();
            }
        }
    }, [sent]);
    return (
        <form
            onSubmit={sendSupportMessage}
            className={styles.Body}
        >
            <TextField
                type="email"
                autoComplete="email"
                label={t('home.yourEmail')}
                onChange={({ target }) => {
                    setEmail(target.value);
                    setEmailError(null);
                }}
                error={emailError !== null}
                helperText={emailError}
                value={email}
                fullWidth
                margin="dense"
            />
            <TextField
                autoComplete="off"
                label={t('home.subject')}
                onChange={({ target }) => {
                    setTitle(target.value);
                    setSubjectError(null);
                }}
                error={titleError !== null}
                helperText={titleError}
                value={title}
                fullWidth
                margin="dense"
            />
            <TextField
                value={text}
                onChange={({ target }) => {
                    setText(target.value);
                    setBodyError(null);
                }}
                error={textError !== null}
                helperText={textError}
                label={t('home.pleaseLeave')}
                fullWidth
                margin="dense"
                multiline
                rows={4}
            />
            {props.isLandingContactForm ? (
                <>
                    <div className={styles.captchaContainer}>
                        <ReCAPTCHA
                            ref={captcha}
                            sitekey={config.recaptchaKey}
                        />
                        {captchaError && (
                            <FormHelperText error className={styles.captchaError}>
                                {captchaError}
                            </FormHelperText>
                        )}
                    </div>
                    <Button
                        variant="text"
                        className={styles.landingSend}
                        onClick={sendSupportMessage}
                        disabled={props.app.get('isSupportSendButtonLoading')}
                    >
                        {t('home.send')}
                    </Button>
                </>
            ) : (
                <Button
                    className={styles.sendButton}
                    variant="contained"
                    onClick={sendSupportMessage}
                    disabled={props.app.get('isSupportSendButtonLoading')}
                >
                    {t('home.send')}
                </Button>
            )}
        </form>
    );
}
