import React, { useState } from 'react';
import { Button, MenuItem } from '@material-ui/core';
import { IConnection, IMeetingIntegration } from '../../../interfaces/interfaces';
import TextField from '../../_ReusableComponents/TextField/TextField';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useHistory } from 'react-router';
const styles = require('./VideoConfPicker.css');

interface Props {
    confs: IConnection[];
    value: IMeetingIntegration | undefined;
    onChange: (value: IMeetingIntegration | undefined) => void;
}

export default function VideoConfPicker(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    if (props.confs.length === 0) {
        return (
            <div className={styles.container}>
                <Button
                    variant="text"
                    className={styles.add}
                    onClick={() => history.push('/preferences?openTab=integrations')}
                >
                    {t('new.addVideoConf')}
                </Button>
            </div>
        );
    }
    return (
        <TextField
            select
            label={t('new.videoConf')}
            value={props.value?.account ? props.confs.find(R.whereEq(props.value)) : ''}
            SelectProps={{
                onChange: ({ target }) => {
                    if (target.value === 'add-another') {
                        history.push('/preferences?openTab=integrations');
                    } else if (!target.value) {
                        props.onChange(undefined);
                    } else {
                        props.onChange(target.value as IConnection);
                    }
                }
            }}
            fullWidth
        >
            {props.confs.map(conf => (
                <MenuItem
                    key={`${conf.provider}${conf.account}`}
                    value={conf as any}
                >
                    <div className={styles.videoConf}>
                        {conf.provider === 'zoom' && (
                            <img src="/assets/zoom-color.svg" className={styles.providerIcon} />
                        )}
                        {conf.provider === 'microsoft' && (
                            <img src="/assets/teams-color.svg" className={styles.providerIcon} />
                        )}
                        {conf.provider === 'google' && (
                            <img src="/assets/google-meet.svg" className={styles.providerIcon} />
                        )}
                        <span className={styles.account}>
                            {conf.account}
                        </span>
                    </div>
                </MenuItem>
            ))}
            <MenuItem value="">
                <div className={styles.providerIcon} />
                {t('new.confNone')}
            </MenuItem>
            <MenuItem value="add-another">
                <div className={styles.providerIcon} />
                {t('new.addAnother')}
            </MenuItem>
        </TextField>
    );
}
