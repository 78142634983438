import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { MyCheckBox } from 'components/_ReusableComponents/MyCheckBox/MyCheckBox';
import { useTranslation } from 'react-i18next';
import Styles from './FilterMenu.css';

interface ISortMenuProps {
    isWorkHoursOnly: boolean;
    setIsWorkHoursOnly: React.Dispatch<React.SetStateAction<boolean>>;
    isNonConflictingOnly: boolean;
    setIsNonConflictingOnly: React.Dispatch<React.SetStateAction<boolean>>;
}
export const FilterMenu = ({ isWorkHoursOnly, setIsWorkHoursOnly, isNonConflictingOnly, setIsNonConflictingOnly }: ISortMenuProps) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [label, setLabel] = useState<string>('Sort by rating');
    return (
        <div>
            <div
                className={Styles.menuButton}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                <img src="/assets/menu_icon.svg" alt="" className={Styles.menuIcon} />
            </div>
            <Menu
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                className={Styles.menuItems}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <MenuItem
                    className={Styles.menuItem}
                    onClick={() => {
                        isWorkHoursOnly ? setIsWorkHoursOnly(false) : setIsWorkHoursOnly(true);
                        setAnchorEl(null);
                    }}
                >
                    <MyCheckBox
                        label={t('home.workHoursOnly')}
                        isChecked={isWorkHoursOnly}
                    />
                </MenuItem>
                <MenuItem
                    className={Styles.menuItem}
                    onClick={() => {
                        isNonConflictingOnly ? setIsNonConflictingOnly(false) : setIsNonConflictingOnly(true);
                        setAnchorEl(null);
                    }}
                >
                    <MyCheckBox
                        label={t('home.nonConflictingOnly')}
                        isChecked={isNonConflictingOnly}
                    />
                </MenuItem>
            </Menu>
        </div>
    );
};
