import React, { useRef } from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import Styles from './ContentSlider.css';
import classNames from 'classnames';

export const ContentSlider = () => {
    const sliderRef = useRef(null);
    const { t } = useTranslation();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true
    };

    const nextSlide = () => {
        if (sliderRef) {
            sliderRef.current.slickNext();
        }
    };
    const prevSlide = () => {
        if (sliderRef) {
            sliderRef.current.slickPrev();
        }
    };

    return (
        <div className={Styles.wrapper} id="How_it_works">
            <Slider {...settings} ref={sliderRef}>
                <img className={Styles.itemImg} src={'/assets/sbLandingPage/contentSlider_1@2x.png'} />
                <img className={Styles.itemImg} src={'/assets/sbLandingPage/contentSlider_2@2x.png'} />
            </Slider>
            <div className={Styles.buttonGroup}>
                <button onClick={prevSlide} className={classNames(Styles.dirButton, Styles.rightRound)}>
                    <div className={classNames(Styles.buttonArrow, Styles.leftArrow)}/>
                </button>
                <button onClick={nextSlide} className={classNames(Styles.dirButton, Styles.leftRound)}>
                    <div className={classNames(Styles.buttonArrow, Styles.rightArrow)}/>
                </button>
            </div>
        </div>
    );
};
