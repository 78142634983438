import React, { useState } from 'react';
import { Checkbox, Dialog, FormControlLabel, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import IActions from 'actions/IActions';
import { IConnection, IConnectionProvider, IConnectionScope } from '../../../interfaces/interfaces';
import Button from '../../_ReusableComponents/Button/Button';
import TogglePreferencesElement from '../../_ReusableComponents/Toggle/TogglePreferencesElement/TogglePreferencesElement';
import BackendMethods from '../../../api/BackendMethods';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
const config = require('CONFIG');
const styles = require('./Integrations.css');

interface Props {
    actions: IActions;
    preferences: any;
    data: any;
    setIsICloudCalendar: React.Dispatch<React.SetStateAction<boolean>>;
    addScopes: IConnectionScope[];
    setAddScopes: React.Dispatch<React.SetStateAction<IConnectionScope[]>>;
    connections: IConnection[];
    setIsAppleCalendarEvents: React.Dispatch<React.SetStateAction<boolean>>;
    setNowProvider: React.Dispatch<React.SetStateAction<string>>;
    nowProvider: string;
}

export default function Integrations(props: Props) {
    const {setIsICloudCalendar, addScopes, setAddScopes, connections, setIsAppleCalendarEvents, setNowProvider, nowProvider} = props;
    const { t } = useTranslation();
    const history = useHistory();
    const [addProvider, setAddProvider] = useState<IConnectionProvider | null>(null);
    const [addOpen, setAddOpen] = useState(false);
    const [removeOpen, setRemoveOpen] = useState(false);
    const [accountToRemove, setAccountToRemove] = useState<IConnection | null>(null);
    const [removePending, setRemovePending] = useState(false);
    const [editAccount, setEditAccount] = useState<IConnection | null>(null);
    const removeAccount = async () => {
        setRemovePending(true);
        try {
            await BackendMethods.deleteConnection(
                accountToRemove.provider,
                accountToRemove.account,
                accountToRemove.scopes,
            );
            props.actions.updateData({
                connections: connections.filter((x) => !(
                    x.provider === accountToRemove.provider
                    && x.account === accountToRemove.account
                )),
            });
            setRemoveOpen(false);
        } finally {
            setRemovePending(false);
        }
    };
    const addAccount = async (
        provider: IConnectionProvider,
        email?: string,
        currentScopes?: IConnectionScope[],
    ) => {
        const scopes = ((): IConnectionScope[] => {
            if (currentScopes) {
                return currentScopes;
            }
            return provider === 'zoom' ? ['VIDEO_CONF'] : addScopes;
        })();
        const isOutlook = sessionStorage.getItem('outlook') === '1';
        const redirect = `${location.origin}${location.pathname}${isOutlook ? '?outlook=1' : ''}`;
        const state = await BackendMethods.initOAuth(provider, scopes, redirect);
        const baseUrl = location.host === 'localhost:8080' ? 'https://dev.tanbuu.com' : location.origin;
        const common = {
            response_type: 'code',
            prompt: email ? 'consent' : 'select_account',
            state,
            redirect_uri: `${baseUrl}/api/oauth/response`,
            ...(email ? {
                login_hint: email,
            } : {}),
        };
        let url;
        if (provider === 'google') {
            const params = new URLSearchParams({
                client_id: config.googleClientID,
                scope: [
                    'https://www.googleapis.com/auth/userinfo.email',
                    ...(scopes.includes('CALENDAR') ? [
                        'https://www.googleapis.com/auth/calendar.readonly',
                        'https://www.googleapis.com/auth/calendar.events',
                    ] : []),
                    ...(scopes.includes('CONTACTS') ? [
                        'https://www.googleapis.com/auth/contacts.other.readonly',
                        'https://www.googleapis.com/auth/contacts.readonly',
                    ] : []),
                ].join(' '),
                access_type: 'offline',
                include_granted_scopes: 'true',
                ...common
            }).toString();
            url = `https://accounts.google.com/o/oauth2/v2/auth?${params}`;
        } else if (provider === 'microsoft') {
            const params = new URLSearchParams({
                client_id: config.microsoftClientID,
                scope: [
                    'offline_access',
                    'User.Read',
                    ...(scopes.includes('CALENDAR') ? ['Calendars.ReadWrite'] : []),
                    ...(scopes.includes('CONTACTS') ? ['Contacts.Read', 'People.Read'] : []),
                    ...(scopes.includes('VIDEO_CONF') ? ['OnlineMeetings.ReadWrite'] : []),
                ].join(' '),
                response_mode: 'query',
                ...common
            }).toString();
            url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${params}`;
        } else {
            const params = new URLSearchParams({
                client_id: config.zoomClientId,
                ...common
            }).toString();
            url = `https://zoom.us/oauth/authorize?${params}`;
        }
        if (isOutlook) {
            window.parent.postMessage(JSON.stringify({
                popupUrl: url,
            }), config.outlookOrigin);
            setAddOpen(false);
        } else {
            location.assign(url);
        }
    };
    const checkboxOnChange = (scope: IConnectionScope) => () => {
        if (addScopes.includes(scope)) {
            setAddScopes(addScopes.filter((x) => x !== scope));
        } else {
            setAddScopes([...addScopes, scope]);
        }
    };
    const addCalendar = async () => {
        if (editAccount) {
            const removedScopes = editAccount.scopes.filter((x) => (
                !addScopes.includes(x)
            ));
            const addedScopes = addScopes.filter((x) => (
                !editAccount.scopes.includes(x)
            ));
            if (removedScopes.length > 0) {
                await BackendMethods.deleteConnection(
                    editAccount.provider,
                    editAccount.account,
                    removedScopes,
                );
                props.actions.updateData({
                    connections: connections.map((x) => (
                        x.provider === editAccount.provider
                        && x.account === editAccount.account
                    ) ? {
                        ...x,
                        scopes: addScopes,
                    } : x),
                });
            }
            if (addedScopes.length > 0) {
                await addAccount(
                    editAccount.provider,
                    editAccount.account,
                    addedScopes,
                );
            }
            setAddOpen(false);
        } else {
            await addAccount(addProvider);
        }
    };

    const updateICloudCalendar = async () => {
        const provider = 'apple';
        const account = nowProvider;
        const scopes: IConnectionScope[] = addScopes;
            try {
                await BackendMethods.updateThirdPartyApplication(provider, account, scopes);
                const connection: IConnection = connections.find(item => item.account === account);
                while (connection.scopes.length > 0) {
                    connection.scopes.pop();
                }
                scopes.map(item => connection.scopes.push(item));
                setAddOpen(false);
            } catch (error) {
        }
    };
    return (
        <TogglePreferencesElement
            data-testid="integrations-tab"
            callback={props.actions.toggleIntegrations}
            text={t('settings.integrations')}
            toggleState={props.preferences.get('isIntegrationsVisible')}
        >
            <div
                data-testid="calendars-root"
                className={styles.root}
            >
                <div className={styles.addButtons}>
                    <Button
                        className={styles.addButton}
                        onClick={() => {
                            setEditAccount(null);
                            setAddScopes([]);
                            setAddProvider('google');
                            setAddOpen(true);
                        }}
                        fullWidth
                    >
                        <img
                            className={styles.plusIcon}
                            src="/assets/plus-icon.svg"
                        />
                        <span className={styles.addProvider}>
                            Google
                        </span>
                        <img
                            className={styles.scopeIcon}
                            src="/assets/google-calendar.svg"
                        />
                        <img
                            className={styles.scopeIcon}
                            src="/assets/google-meet.svg"
                        />
                        <img
                            className={styles.scopeIcon}
                            src="/assets/google-contacts.svg"
                        />
                    </Button>
                    <Button
                        className={styles.addButton}
                        onClick={() => {
                            setEditAccount(null);
                            setAddScopes([]);
                            setAddProvider('microsoft');
                            setAddOpen(true);
                        }}
                        fullWidth
                    >
                        <img
                            className={styles.plusIcon}
                            src="/assets/plus-icon.svg"
                        />
                        <span className={styles.addProvider}>
                            Microsoft
                        </span>
                        <img
                            className={styles.scopeIcon}
                            src="/assets/microsoft-calendar.svg"
                        />
                        <img
                            className={styles.scopeIcon}
                            src="/assets/microsoft-contacts.svg"
                        />
                        <img
                            className={styles.scopeIcon}
                            src="/assets/microsoft-teams.svg"
                        />
                    </Button>
                    <Button
                        className={styles.addButton}
                        onClick={() => addAccount('zoom')}
                        fullWidth
                    >
                        <img
                            className={styles.plusIcon}
                            src="/assets/plus-icon.svg"
                        />
                        <span className={styles.addProvider}>
                            Zoom
                        </span>
                        <img
                            className={styles.scopeIcon}
                            src="/assets/zoom-color.svg"
                        />
                    </Button>
                    <Button
                        className={styles.addButton}
                        onClick={() => {
                            setEditAccount(null);
                            setAddScopes([]);
                            setNowProvider('');
                            setAddProvider('apple');
                            setAddOpen(true);
                        }}
                        fullWidth
                    >
                        <img
                            className={styles.plusIcon}
                            src="/assets/plus-icon.svg"
                        />
                        <span className={styles.addProvider}>
                            iCloud
                        </span>
                        <img
                            className={styles.scopeIcon}
                            src="/assets/icloud_icon.png"
                        />
                        <img
                            className={styles.scopeIcon}
                            src="/assets/icloud_contacts.png"
                        />
                    </Button>
                </div>
                {connections.length === 0 ? (
                    <div className={styles.emptyList} data-testid="no-accounts">
                        {t('settings.noAccounts')}
                    </div>
                ) : connections.map((connection) => (
                    <div
                        key={connection.provider + connection.account}
                        className={classNames(styles.listElement, {
                            [styles.expired]: !connection.active,
                        })}
                        data-testid="account-list-item"
                    >
                        <div
                            className={classNames(styles.editAccountButton, {
                                [styles.editActive]: connection.provider !== 'zoom',
                            })}
                            onClick={() => {
                                if (connection.provider !== 'zoom') {
                                    if (connection.provider !== 'apple' || connection.active === true) {
                                        setEditAccount(connection);
                                        setAddScopes(connection.scopes);
                                        setAddProvider(connection.provider);
                                        setNowProvider(connection.account);
                                        setAddOpen(true);
                                    }
                                }
                            }}
                        >
                            <div className={styles.email} data-testid="account-email">
                                {connection.account}
                            </div>
                            {connection.provider === 'google' && (
                                <>
                                    {connection.scopes.includes('CALENDAR') && (
                                        <>
                                            <img
                                                className={styles.scopeIcon}
                                                src="/assets/google-calendar.svg"
                                            />
                                            <img
                                                className={styles.scopeIcon}
                                                src="/assets/google-meet.svg"
                                            />
                                        </>
                                    )}
                                    {connection.scopes.includes('CONTACTS') && (
                                        <img
                                            className={styles.scopeIcon}
                                            src="/assets/google-contacts.svg"
                                        />
                                    )}
                                </>
                            )}
                            {connection.provider === 'microsoft' && (
                                <>
                                    {connection.scopes.includes('CALENDAR') && (
                                        <img
                                            className={styles.scopeIcon}
                                            src="/assets/microsoft-calendar.svg"
                                        />
                                    )}
                                    {connection.scopes.includes('CONTACTS') && (
                                        <img
                                            className={styles.scopeIcon}
                                            src="/assets/microsoft-contacts.svg"
                                        />
                                    )}
                                    {connection.scopes.includes('VIDEO_CONF') && (
                                        <img
                                            className={styles.scopeIcon}
                                            src="/assets/microsoft-teams.svg"
                                        />
                                    )}
                                </>
                            )}
                            {connection.provider === 'zoom' && (
                                <img
                                    className={styles.scopeIcon}
                                    src="/assets/zoom-color.svg"
                                />
                            )}
                            {connection.provider === 'apple' && (
                                <>
                                    {connection.scopes.includes('CALENDAR') && (
                                        <img
                                            className={styles.scopeIcon}
                                            src="/assets/icloud_icon.png"
                                        />
                                    )}
                                    {connection.scopes.includes('CONTACTS') && (
                                        <img
                                            className={styles.scopeIcon}
                                            src="/assets/icloud_contacts.png"
                                        />
                                    )}
                                    {connection.active && connection.scopes.includes('CALENDAR')? (
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsAppleCalendarEvents(true);
                                                setNowProvider(connection.account);
                                                setAddScopes(connection.scopes);
                                                setIsICloudCalendar(true);
                                            }}
                                        >
                                            <img
                                                className={styles.editIcon}
                                                src="/assets/pencil-icon.png"
                                            />
                                        </div>
                                    ): null}
                                </>
                            )}
                        </div>
                        {!connection.active && (
                            <>
                                {connection.provider === 'apple'? (
                                    <Button
                                        variant="contained"
                                        className={styles.renew}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsAppleCalendarEvents(false);
                                            setNowProvider(connection.account);
                                            setAddScopes(connection.scopes);
                                            setIsICloudCalendar(true);
                                        }}
                                    >
                                        {t('settings.renew')}
                                    </Button>
                                ): (
                                    <Button
                                        variant="contained"
                                        className={styles.renew}
                                        onClick={() => addAccount(
                                            connection.provider,
                                            connection.account,
                                            connection.scopes,
                                        )}
                                    >
                                        {t('settings.renew')}
                                    </Button>
                                )}
                            </>
                        )}
                        <IconButton
                            onClick={() => {
                                setRemoveOpen(true);
                                setAccountToRemove(connection);
                            }}
                            data-testid={`remove-account-${connection.provider}-${connection.account}`}
                            size="large">
                            <img
                                src="/assets/plus-icon.svg"
                                className={styles.removeIcon}
                            />
                        </IconButton>
                    </div>
                ))}
            </div>
            <Dialog
                open={addOpen}
                onClose={() => {
                    setAddOpen(false);
                    setNowProvider('');
                }}
                PaperProps={{ className: styles.dialogPaper }}
            >
                <div className={styles.dialogTitle}>
                    {t('settings.allowAccess')}
                </div>
                <div className={styles.dialogBody}>
                    {addProvider === 'google' && (
                        <>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        color="primary"
                                        checked={addScopes.includes('CALENDAR')}
                                        onChange={checkboxOnChange('CALENDAR')}
                                    />
                                )}
                                label={(
                                    <div className={styles.checkboxLabel}>
                                        <div className={styles.scopeContainer}>
                                            <img
                                                className={styles.scopeIcon}
                                                src="/assets/google-calendar.svg"
                                            />
                                            <span className={styles.scopeName}>
                                                {t('settings.googleCalendar')}
                                            </span>
                                        </div>
                                        <div className={styles.scopeContainer}>
                                            <img
                                                className={styles.scopeIcon}
                                                src="/assets/google-meet.svg"
                                            />
                                            <span className={styles.scopeName}>
                                                Google Meet
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        color="primary"
                                        checked={addScopes.includes('CONTACTS')}
                                        onChange={checkboxOnChange('CONTACTS')}
                                    />
                                )}
                                label={(
                                    <div className={styles.checkboxLabel}>
                                        <div className={styles.scopeContainer}>
                                            <img
                                                className={styles.scopeIcon}
                                                src="/assets/google-contacts.svg"
                                            />
                                            <span className={styles.scopeName}>
                                                {t('settings.googleContacts')}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </>
                    )}
                    {addProvider === 'microsoft' && (
                        <>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        color="primary"
                                        checked={addScopes.includes('CALENDAR')}
                                        onChange={checkboxOnChange('CALENDAR')}
                                    />
                                )}
                                label={(
                                    <div className={styles.checkboxLabel}>
                                        <div className={styles.scopeContainer}>
                                            <img
                                                className={styles.scopeIcon}
                                                src="/assets/microsoft-calendar.svg"
                                            />
                                            <span className={styles.scopeName}>
                                                {t('settings.outlookCalendar')}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        color="primary"
                                        checked={addScopes.includes('CONTACTS')}
                                        onChange={checkboxOnChange('CONTACTS')}
                                    />
                                )}
                                label={(
                                    <div className={styles.checkboxLabel}>
                                        <div className={styles.scopeContainer}>
                                            <img
                                                className={styles.scopeIcon}
                                                src="/assets/microsoft-contacts.svg"
                                            />
                                            <span className={styles.scopeName}>
                                                {t('settings.outlookContacts')}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        color="primary"
                                        checked={addScopes.includes('VIDEO_CONF')}
                                        onChange={checkboxOnChange('VIDEO_CONF')}
                                    />
                                )}
                                label={(
                                    <div className={styles.checkboxLabel}>
                                        <div className={styles.scopeContainer}>
                                            <img
                                                className={styles.scopeIcon}
                                                src="/assets/microsoft-teams.svg"
                                            />
                                            <span className={styles.scopeName}>
                                                Microsoft Teams
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </>
                    )}
                    {addProvider === 'apple' && (
                        <>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        color="primary"
                                        checked={addScopes.includes('CALENDAR')}
                                        onChange={checkboxOnChange('CALENDAR')}
                                    />
                                )}
                                label={(
                                    <div className={styles.checkboxLabel}>
                                        <div className={styles.scopeContainer}>
                                            <img
                                                className={styles.scopeIcon}
                                                src="/assets/icloud_icon.png"
                                            />
                                            <span className={styles.scopeName}>
                                                {t('settings.calendar')}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        color="primary"
                                        checked={addScopes.includes('CONTACTS')}
                                        onChange={checkboxOnChange('CONTACTS')}
                                    />
                                )}
                                label={(
                                    <div className={styles.checkboxLabel}>
                                        <div className={styles.scopeContainer}>
                                            <img
                                                className={styles.scopeIcon}
                                                src="/assets/icloud_contacts.png"
                                            />
                                            <span className={styles.scopeName}>
                                                {t('settings.contacts')}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </>
                    )}
                </div>
                <div className={styles.dialogButtons}>
                    <Button
                        className={styles.dialogRemoveButton}
                        onClick={() => {
                            setAddOpen(false);
                            setNowProvider('');
                        }}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        variant={(editAccount?.provider || addProvider) === 'google' ? 'outlined' : 'contained'}
                        onClick={() => {
                            if (nowProvider === '') {
                                (editAccount?.provider || addProvider) === 'apple' ? setIsICloudCalendar(true) : addCalendar();
                            } else {
                                updateICloudCalendar();
                            }
                        }}
                        disabled={addScopes.length === 0}
                    >
                        {(editAccount?.provider || addProvider) === 'google' ? (
                            <span className={styles.signInWithGoogle}>
                                <img src="/assets/google_icon.png" className={styles.googleIcon} />
                                {t('auth.signInWithGoogle')}
                            </span>
                        ) : t('settings.continue')}
                    </Button>
                </div>
            </Dialog>
            <Dialog
                open={removeOpen}
                onClose={() => setRemoveOpen(false)}
                PaperProps={{ className: styles.dialogPaper }}
                data-testid="account-remove-modal"
            >
                <div className={styles.dialogTitle}>
                    {t('settings.removeAccount')}
                </div>
                <section className={styles.dialogBody}>
                    {t(
                        'settings.removeAccountConfirm',
                        { account: accountToRemove?.account },
                    )}
                </section>
                <div className={styles.dialogButtons}>
                    <Button
                        className={styles.dialogRemoveButton}
                        onClick={removeAccount}
                        disabled={removePending}
                        data-testid="remove-button"
                    >
                        {t('settings.remove')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setRemoveOpen(false)}
                        data-testid="keep-button"
                    >
                        {t('settings.keep')}
                    </Button>
                </div>
            </Dialog>
        </TogglePreferencesElement>
    );
}
