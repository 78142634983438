import React, { useEffect, useMemo, useState } from 'react';
import { ICustomer, INewCustomer } from '../DesktopBooking';
import moment, { Moment } from 'moment';
import { allCountries, Country } from 'country-telephone-data';
import PhoneNumber from 'awesome-phonenumber';
import * as R from 'ramda';
import { getTimezone } from 'countries-and-timezones';
import Autocomplete from '../../../components/_ReusableComponents/Autocomplete/Autocomplete';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import { DatePicker } from '@material-ui/lab';
import { Dialog, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ISearchCustomer } from '../../../interfaces/interfaces';
import BackendMethods from '../../../api/BackendMethods';
import classNames from 'classnames';
import Button from '../../../components/_ReusableComponents/Button/Button';
import IProfile from '../../../interfaces/IProfile';
import { getCountryAndPhone } from '../../../utils/phone';
import PhoneLoginComponent from '../../PhoneLoginPage/PhoneLoginComponent';
import cookies, { CookieAttributes } from 'js-cookie';
import { MinorConsentModal } from 'components/_ReusableComponents/Modals/MinorConsentModal/MinorConsentModal';
import { useIsDesktop } from '../../../utils/responsive';
const config = require('CONFIG');
const styles = require('./BookingCustomer.css');

interface ICallbackData {
    customer: ICustomer | INewCustomer;
    bookingFor?: ICustomer | INewCustomer;
    isExtra: boolean;
    notes: string;
    customerEdited: boolean;
    bookingForEdited: boolean;
}

interface IProps {
    renderSummary: (data: ICallbackData) => React.ReactNode;
    onAddEmail: () => void;
    saveData: (data: ICallbackData) => void;
    timezone: string;
    initialNotes?: string;
    initialCustomer?: ICustomer;
    initialBookingFor?: ICustomer;
    isEdit?: boolean;
    isCustomer?: boolean;
    profile: IProfile;
    companyId: number;
    birthDateRequired?: boolean;
}

const { hostname } = window.location;
const domain = hostname.endsWith(config.baseDomain) ? config.baseDomain : hostname;

const cookieOptions: CookieAttributes = {
    secure: true,
    sameSite: 'none',
    domain,
};

export default function BookingCustomer(props: IProps) {
    const { renderSummary, onAddEmail, saveData, timezone, isEdit, isCustomer, profile, companyId, birthDateRequired } = props;
    const { t, i18n: { language } } = useTranslation();
    const isDesktop = useIsDesktop();
    const defaultCountry = allCountries.find(R.propEq(
        'iso2',
        getTimezone(timezone).country.toLowerCase(),
    ));
    const [customers, setCustomers] = useState<ISearchCustomer[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<ISearchCustomer | null>(null);
    const [editingCustomer, setEditingCustomer] = useState(isCustomer === true && !isEdit);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [birthdate, setBirthdate] = useState<Moment | null>(null);
    const [country, setCountry] = useState<Country>(defaultCountry);
    const [phone, setPhone] = useState('');
    const getCodeLabel = (country2: Country) => `${country2.iso2.toUpperCase()} +${country2.dialCode}`;
    const [email, setEmail] = useState('');
    const [notes, setNotes] = useState('');
    const [isExtra, setIsExtra] = useState(false);
    const [care, setCare] = useState<ICustomer[]>([]);
    const [selectedExtra, setSelectedExtra] = useState<ICustomer | null>(null);
    const [editingExtra, setEditingExtra] = useState(false);
    const [extraName, setExtraName] = useState('');
    const [extraSurname, setExtraSurname] = useState('');
    const [extraBirthdate, setExtraBirthdate] = useState<Moment | null>(null);
    const [extraCountry, setExtraCountry] = useState<Country>(defaultCountry);
    const [extraPhone, setExtraPhone] = useState('');
    const [extraEmail, setExtraEmail] = useState('');
    const [addEmailOpen, setAddEmailOpen] = useState(false);
    const [showMinorConsentModal, setShowMinorConsentModal] = useState<boolean>(false);
    const customer = (() => {
        if (name && surname && (!isCustomer || isExtra || !birthDateRequired || birthdate) && phone) {
            const id = (() => {
                if (selectedCustomer) {
                    if (selectedCustomer.hrefCarer) {
                        return Number(selectedCustomer.hrefCarer.match(/(\d+)/)[1]);
                    }
                    return selectedCustomer.id;
                }
                return null;
            })();
            return {
                firstName: name,
                lastName: surname,
                birthDate: birthdate ? birthdate.format('YYYY-MM-DD') : null,
                countryCode: `+${country.dialCode}`,
                phone: new PhoneNumber(phone, country.iso2).getNumber('significant'),
                email: email || null,
                ...(id ? { id } : {}),
            };
        }
        return null;
    })();
    const bookingFor = (() => {
        if (isExtra && extraName && extraSurname && (!isCustomer || !birthDateRequired || extraBirthdate)) {
            const newBookingFor: ICustomer | INewCustomer = {
                firstName: extraName,
                lastName: extraSurname,
                birthDate: extraBirthdate
                    ? extraBirthdate.format('YYYY-MM-DD')
                    : null,
                ...(extraPhone ? {
                    countryCode: `+${extraCountry.dialCode}`,
                    phone: new PhoneNumber(extraPhone, extraCountry.iso2).getNumber('significant'),
                } : {}),
                email: extraEmail || null,
                ...(selectedExtra ? { id: selectedExtra.id } : {}),
            };
            return newBookingFor;
        }
    })();
    useEffect(() => {
        if (props.initialNotes) {
            setNotes(props.initialNotes);
        }
    }, [props.initialNotes]);
    useEffect(() => {
        (async () => {
            const { initialCustomer } = props;
            if (initialCustomer) {
                setSelectedCustomer(initialCustomer);
                setName(initialCustomer.firstName);
                setSurname(initialCustomer.lastName);
                setBirthdate(initialCustomer.birthDate ? moment(initialCustomer.birthDate) : null);
                const country2 = allCountries.find((x) => (
                    x.dialCode === initialCustomer.countryCode.substring(1)
                ));
                setCountry(country2);
                if (!isCustomer) {
                    setPhone(new PhoneNumber(initialCustomer.phone, country2.iso2).getNumber('national'));
                    setEmail(initialCustomer.email || '');
                    const customerCare = await BackendMethods.getCustomer(`/customer/${initialCustomer.id}`);
                    setCare(customerCare.care);
                }
            } else if (isCustomer) {
                setName(profile.firstName || '');
                setSurname(profile.lastName || '');
                setBirthdate(profile.birthDate ? moment(profile.birthDate) : null);
            }
            if (isCustomer) {
                if (profile.phone) {
                    const [newCountry, newPhone] = getCountryAndPhone(profile.phone);
                    setCountry(newCountry);
                    setPhone(newPhone);
                }
                setEmail(profile.email || '');
                const newCare = await BackendMethods.getMyCare(companyId);
                setCare(newCare);
            }
        })();
    }, [props.initialCustomer, isCustomer]);
    useEffect(() => {
        const { initialBookingFor } = props;
        if (initialBookingFor) {
            setIsExtra(true);
            setSelectedExtra(initialBookingFor);
            setExtraName(initialBookingFor.firstName);
            setExtraSurname(initialBookingFor.lastName);
            setExtraBirthdate(initialBookingFor.birthDate ? moment(initialBookingFor.birthDate) : null);
            if (initialBookingFor.phone) {
                const country2 = allCountries.find((x) => (
                    x.dialCode === initialBookingFor.countryCode.substring(1)
                ));
                setExtraCountry(country2);
                setExtraPhone(new PhoneNumber(initialBookingFor.phone, country2.iso2).getNumber('national'));
            }
            setExtraEmail(initialBookingFor.email || '');
        }
    }, [props.initialBookingFor]);
    const autocompleteOptions = useMemo(() => (
        R.uniqBy(R.prop('id'), [
            ...customers,
            ...(selectedCustomer ? [selectedCustomer] : []),
        ])
    ), [customers, selectedCustomer]);
    const callbackData = {
        customer,
        bookingFor,
        isExtra,
        notes,
        customerEdited: selectedCustomer !== null && editingCustomer,
        bookingForEdited: selectedExtra !== null && editingExtra,
    };
    const setExtra = async (value: string) => {
        if (value === 'other') {
            let contents: any;
            setIsExtra(true);
            try {
                const types: string = 'GDPR_CARE';
                contents = await BackendMethods.getConsents(types);
                if (contents.length > 0) {
                    setShowMinorConsentModal(true);
                }
            } catch (error) {
                setIsExtra(false);
            }
        } else {
            setIsExtra(false);
        }
    };
    const clearExtra = () => {
        setSelectedExtra(null);
        setExtraName('');
        setExtraSurname('');
        setExtraBirthdate(null);
        setExtraCountry(defaultCountry);
        setExtraPhone('');
        setExtraEmail('');
    };
    const clearCustomer = () => {
        setName('');
        setSurname('');
        setBirthdate(null);
        setCountry(defaultCountry);
        setPhone('');
        setEmail('');
        setIsExtra(false);
        clearExtra();
        setCare([]);
    };
    return (
        <div>
            {!isCustomer && (
                <Autocomplete
                    options={autocompleteOptions}
                    getOptionLabel={(x) => `${x.lastName}, ${x.firstName}`}
                    renderOption={(props2, x) => (
                        <MenuItem
                            {...props2}
                            className={classNames(props2.className, styles.menuItem)}
                            key={`${x.id}${x.hrefCarer}`}
                        >
                            {x.lastName}
                            {', '}
                            {x.firstName}
                            <div className={styles.optionSmall}>
                                {x.hrefCarer
                                    ? `${t('small.bookedBy')} ${x.carerFirstName} ${x.carerLastName}`
                                    : new PhoneNumber(x.countryCode + x.phone).getNumber('international')}
                            </div>
                        </MenuItem>
                    )}
                    isOptionEqualToValue={(a, b) => a.id === b.id && a.hrefCarer === b.hrefCarer}
                    filterOptions={(options) => options}
                    value={selectedCustomer}
                    onChange={async (event, value) => {
                        setSelectedCustomer(value);
                        if (value) {
                            const customerCare = await BackendMethods.getCustomer(value.href || value.hrefCarer);
                            setName(customerCare.firstName);
                            setSurname(customerCare.lastName);
                            setBirthdate(customerCare.birthDate ? moment(customerCare.birthDate) : null);
                            if (customerCare.phone) {
                                const country2 = allCountries.find((x) => (
                                    x.dialCode === customerCare.countryCode.substring(1)
                                ));
                                setCountry(country2);
                                setPhone(new PhoneNumber(customerCare.phone, country2.iso2).getNumber('national'));
                            } else {
                                setPhone('');
                            }
                            setEmail(customerCare.email || '');
                            setCare(customerCare.care);
                            if (value.hrefCarer) {
                                const bookingFor2 = customerCare.care.find(R.propEq('id', value.id));
                                setIsExtra(true);
                                setSelectedExtra(bookingFor2);
                                setExtraName(bookingFor2.firstName);
                                setExtraSurname(bookingFor2.lastName);
                                setExtraBirthdate(bookingFor2.birthDate ? moment(bookingFor2.birthDate) : null);
                                if (bookingFor2.phone) {
                                    const country2 = allCountries.find((x) => (
                                        x.dialCode === bookingFor2.countryCode.substring(1)
                                    ));
                                    setExtraCountry(country2);
                                    setExtraPhone(
                                        new PhoneNumber(bookingFor2.phone, country2.iso2).getNumber('national'),
                                    );
                                } else {
                                    setExtraPhone('');
                                }
                                setExtraEmail(bookingFor2.email || '');
                            } else {
                                setIsExtra(false);
                                setSelectedExtra(null);
                                setExtraName('');
                                setExtraSurname('');
                                setExtraBirthdate(null);
                                setExtraCountry(defaultCountry);
                                setExtraPhone('');
                                setExtraEmail('');
                            }
                        } else {
                            clearCustomer();
                        }
                        setEditingCustomer(false);
                        setEditingExtra(false);
                    }}
                    onInputChange={async (event, value) => {
                        if (value.length >= 3) {
                            const newCustomers = await BackendMethods.searchCustomers(value);
                            setCustomers(newCustomers);
                        } else {
                            setCustomers([]);
                        }
                    }}
                    disabled={isEdit}
                    renderInput={(props2) => (
                        <TextField
                            {...props2}
                            autoComplete="off"
                            label={t('small.nameEmailPhone')}
                        />
                    )}
                />
            )}
            {!isCustomer && (
                <Button
                    variant="text"
                    onClick={() => {
                        clearCustomer();
                        setEditingCustomer(true);
                    }}
                    className={styles.newButton}
                >
                    <img src="/assets/plus-icon.svg" alt="" className={styles.plusIcon} />
                    {t('small.newCustomer')}
                </Button>
            )}
            {(selectedCustomer || editingCustomer || isCustomer) && (
                <>
                    <div className={styles.forWhom}>
                        {t('small.forWhom')}
                    </div>
                    <RadioGroup
                        value={isExtra ? 'other' : 'myself'}
                        onChange={({ target }) => setExtra(target.value)}
                        className={styles.radioGroup}
                    >
                        <FormControlLabel
                            control={<Radio color="secondary" />}
                            label={t('small.forMyself')}
                            value="myself"
                        />
                        <FormControlLabel
                            control={<Radio color="secondary" />}
                            label={t('small.forOther')}
                            value="other"
                        />
                    </RadioGroup>
                </>
            )}
            <div
                className={classNames({
                    [styles.columns]: isCustomer,
                })}
            >
                {(selectedCustomer || editingCustomer || isCustomer) && (
                    <div className={styles.customerColumn}>
                        <div className={styles.headerRow}>
                            <div className={styles.header}>
                                {t('small.yourDetails')}
                            </div>
                            {(selectedCustomer !== null || isCustomer) && !editingCustomer && (
                                <IconButton
                                    size="small"
                                    onClick={() => setEditingCustomer(true)}
                                >
                                    <img src="/assets/pen.svg" alt="" />
                                </IconButton>
                            )}
                        </div>
                        <TextField
                            label={t('small.customerName')}
                            value={name}
                            onChange={({ target }) => (
                                setName(target.value)
                            )}
                            fullWidth
                            disabled={!editingCustomer}
                            required={isCustomer}
                        />
                        <TextField
                            label={t('small.surname')}
                            value={surname}
                            onChange={({ target }) => (
                                setSurname(target.value)
                            )}
                            fullWidth
                            disabled={!editingCustomer}
                            required={isCustomer}
                        />
                        {!isExtra && birthDateRequired && (
                            <DatePicker
                                value={birthdate}
                                onChange={setBirthdate}
                                label={`${t('small.birthdate')}${isCustomer ? ' *' : ''}`}
                                renderInput={(props2) => (
                                    <TextField
                                        {...props2}
                                        fullWidth
                                        helperText=""
                                        inputProps={{ ...props2.inputProps, placeholder: language === 'pl' ? 'dd.mm.rrrr' : 'mm/dd/yyyy' }}
                                    />
                                )}
                                disabled={!editingCustomer}
                            />
                        )}
                        {(isCustomer && !profile?.phone) ? (
                            <div className={styles.phoneLoginContainer}>
                                <PhoneLoginComponent
                                    phoneStepHeader={t('small.addAndConfirmPhone')}
                                    noBack
                                    onSubmit={() => {
                                        saveData(callbackData);
                                        cookies.set('bookingAddPhoneToken', cookies.get('authToken'), cookieOptions);
                                        cookies.set('bookingAddPhoneRedirect', location.href, cookieOptions);
                                    }}
                                />
                            </div>
                        ) : (
                            <div className={styles.phoneRow}>
                                <Autocomplete
                                    value={country}
                                    getOptionLabel={getCodeLabel}
                                    onChange={(event, newValue) => (
                                        setCountry(newValue)
                                    )}
                                    options={allCountries}
                                    className={styles.country}
                                    disableClearable
                                    label={`${t('small.countryCode')}${isCustomer ? ' *' : ''}`}
                                    disabled={!editingCustomer || isCustomer}
                                />
                                <TextField
                                    value={phone}
                                    onChange={({ target }) => (
                                        setPhone(target.value)
                                    )}
                                    label={t('small.phoneNumber')}
                                    className={styles.phone}
                                    disabled={!editingCustomer || isCustomer}
                                    required={isCustomer}
                                />
                            </div>
                        )}
                        {(!isCustomer || profile.email) ? (
                            <TextField
                                label={t('small.email')}
                                value={email}
                                onChange={({ target }) => (
                                    setEmail(target.value)
                                )}
                                fullWidth
                                disabled={!editingCustomer || isCustomer}
                            />
                        ) : (
                            <div className={styles.addEmail}>
                                <div className={styles.addEmailText}>
                                    {t('small.dontTypeCodes')}
                                </div>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => setAddEmailOpen(true)}
                                >
                                    {t('small.registerYourEmail')}
                                </Button>
                                <Dialog
                                    open={addEmailOpen}
                                    PaperProps={{ className: styles.paper }}
                                    onClose={() => setAddEmailOpen(false)}
                                >
                                    <div className={styles.dialogText}>
                                        {t('small.addEmailInfo')}
                                    </div>
                                    <div className={styles.dialogButtons}>
                                        <Button
                                            className={styles.dialogCancelButton}
                                            onClick={() => setAddEmailOpen(false)}
                                        >
                                            {t('common.cancel')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                saveData(callbackData);
                                                onAddEmail();
                                            }}
                                        >
                                            {t('small.continue')}
                                        </Button>
                                    </div>
                                </Dialog>
                            </div>
                        )}
                        {!isCustomer && (
                            <TextField
                                label={t('small.notes')}
                                value={notes}
                                onChange={({ target }) => (
                                    setNotes(target.value)
                                )}
                                fullWidth
                                multiline
                                minRows={2}
                            />
                        )}
                    </div>
                )}
                {isExtra && (
                    <div className={styles.extraColumn}>
                        <div className={styles.headerRow}>
                            <div className={styles.header}>
                                {t('small.amBookingFor')}
                            </div>
                            {selectedExtra !== null && !editingExtra && (
                                <IconButton
                                    size="small"
                                    onClick={() => setEditingExtra(true)}
                                >
                                    <img src="/assets/pen.svg" alt="" />
                                </IconButton>
                            )}
                        </div>
                        <Autocomplete
                            options={care}
                            getOptionLabel={(x) => `${x.lastName}, ${x.firstName}`}
                            renderOption={(props2, x) => (
                                <MenuItem {...props2} key={x.id}>
                                    {`${x.lastName}, ${x.firstName}`}
                                </MenuItem>
                            )}
                            value={selectedExtra}
                            isOptionEqualToValue={(a, b) => a.id === b.id}
                            onChange={(event, value) => {
                                if (value) {
                                    setSelectedExtra(value);
                                    setExtraName(value.firstName);
                                    setExtraSurname(value.lastName);
                                    setExtraBirthdate(value.birthDate ? moment(value.birthDate) : null);
                                    if (value.phone) {
                                        const country2 = allCountries.find((x) => (
                                            x.dialCode === value.countryCode.substring(1)
                                        ));
                                        setExtraCountry(country2);
                                        setExtraPhone(
                                            new PhoneNumber(value.phone, country2.iso2).getNumber('national'),
                                        );
                                    } else {
                                        setExtraPhone('');
                                    }
                                    setExtraEmail(value.email || '');
                                } else {
                                    clearExtra();
                                }
                                setEditingExtra(false);
                            }}
                            renderInput={(props2) => (
                                <TextField
                                    {...props2}
                                    autoComplete="off"
                                    label={t('small.nameEmailPhone')}
                                />
                            )}
                        />
                        <Button
                            variant="text"
                            onClick={() => {
                                clearExtra();
                                setEditingExtra(true);
                            }}
                            className={styles.newButton}
                        >
                            <img src="/assets/plus-icon.svg" alt="" className={styles.plusIcon} />
                            {t('small.newCustomer')}
                        </Button>
                        {(selectedExtra !== null || editingExtra) && (
                            <>
                                <TextField
                                    label={t('small.customerName')}
                                    value={extraName}
                                    onChange={({ target }) => (
                                        setExtraName(target.value)
                                    )}
                                    fullWidth
                                    disabled={!editingExtra}
                                    required={isCustomer}
                                />
                                <TextField
                                    label={t('small.surname')}
                                    value={extraSurname}
                                    onChange={({ target }) => (
                                        setExtraSurname(target.value)
                                    )}
                                    fullWidth
                                    disabled={!editingExtra}
                                    required={isCustomer}
                                />
                                {birthDateRequired && (
                                    <DatePicker
                                        value={extraBirthdate}
                                        onChange={setExtraBirthdate}
                                        label={`${t('small.birthdate')}${isCustomer ? ' *' : ''}`}
                                        renderInput={(props2) => (
                                            <TextField
                                                {...props2}
                                                fullWidth
                                                helperText=""
                                                inputProps={{ ...props2.inputProps, placeholder: language === 'pl' ? 'dd.mm.rrrr' : 'mm/dd/yyyy' }}
                                            />
                                        )}
                                        disabled={!editingExtra}
                                    />
                                )}
                                <div className={styles.phoneRow}>
                                    <Autocomplete
                                        value={extraCountry}
                                        getOptionLabel={getCodeLabel}
                                        onChange={(event, newValue) => (
                                            setExtraCountry(newValue)
                                        )}
                                        options={allCountries}
                                        className={styles.country}
                                        disableClearable
                                        label={t('small.countryCode')}
                                        disabled={!editingExtra}
                                    />
                                    <TextField
                                        value={extraPhone}
                                        onChange={({ target }) => (
                                            setExtraPhone(target.value)
                                        )}
                                        label={t('small.phoneNumber')}
                                        className={styles.phone}
                                        disabled={!editingExtra}
                                    />
                                </div>
                                <TextField
                                    label={t('small.email')}
                                    value={extraEmail}
                                    onChange={({ target }) => (
                                        setExtraEmail(target.value)
                                    )}
                                    fullWidth
                                    disabled={!editingExtra}
                                />
                            </>
                        )}
                    </div>
                )}
            </div>
            {(selectedCustomer || editingCustomer || isCustomer) && (
                <div
                    className={classNames(styles.bottom, {
                        [styles.bottomTwoColumns]: isCustomer && isExtra,
                    })}
                >
                    {renderSummary(callbackData)}
                    {isCustomer && (
                        <div className={styles.requiredField}>
                            * {t('small.requiredField')}
                        </div>
                    )}
                </div>
            )}
            {showMinorConsentModal && (
                <MinorConsentModal
                    isDesktop={isDesktop}
                    setShowMinorConsentModal={setShowMinorConsentModal}
                    onClose={() => {
                        setShowMinorConsentModal(false);
                        setIsExtra(false);
                    }}
                />
            )}
        </div>
    );
}
