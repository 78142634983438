import React, { useEffect, useState } from 'react';
import Button from 'components/_ReusableComponents/Button/Button';
import IReview from 'interfaces/IReview';
import { ICompaniesRating, IReviews } from 'interfaces/ICompaniesRating';
import BackendMethods from 'api/BackendMethods';
import Styles from './Review.css';
import { ReviewContent } from 'components/_ReusableComponents/ReviewContent/ReviewContent';
import { useTranslation } from 'react-i18next';

interface ReviewProps {
  company: {id: number, name: string};
  disabled: boolean;
  reviews?: IReviews | null;
  setIsChangeRating?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Review: React.FC<ReviewProps> = ({ company, disabled, reviews, setIsChangeRating }) => {
  const { t } = useTranslation();
  const companyId = company.id;
  const companyName = company.name;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [ratingValue, setRatingValue] = useState<number | null>(null);
  const [opinion, setOpinion] = useState<string>('');
  const [nickName, setNickName] = useState<string>('');
  const [oldRatingValue, setOldRatingValue] = useState<number | null>(null);
  const [oldOpinion, setOldOpinion] = useState<string>('');
  const [oldNickName, setOldNickName] = useState<string>('');
  const [isMyReview, setIsMyReview] = useState<boolean>(false);
  const [myReview, setMyReview] = useState<IReviews | null>(null);
  const [disableChange, setDisableChange] = useState<boolean>(true);

  useEffect(() => {
    (async function initReview() {
      setIsShow(false);
      if (!disabled) {
        if (reviews) {
          setMyReview(reviews);
          setIsShow(true);
        } else {
          await getReview();
        }
      } else {
        setIsShow(true);
      }
    })();
  }, [company]);
  const getReview = async () => {
    const companies_ids: number[] = [];
    companies_ids.push(companyId);
    try {
      const isLoggedIn = true;
      const companiesRating: ICompaniesRating[] = await BackendMethods.companiesRating(companies_ids, isLoggedIn);
      if (!companiesRating || companiesRating.length === 0) {
        return;
      }
      setMyReview(companiesRating[0].reviews.find((item) => item.self === true));
    } catch (error) {
    } finally {
      setIsShow(true);
    }
  };
  useEffect(() => {
    initValues();
  }, [myReview]);
  const initValues = () => {
    if (myReview) {
      setRatingValue(myReview.rating);
      setOpinion(myReview.review);
      setNickName(myReview.author);
      setOldNickName(myReview.author);
      setOldRatingValue(myReview.rating);
      setOldOpinion(myReview.review);
      setIsMyReview(true);
    } else {
      setRatingValue(null);
      setOpinion('');
      setNickName('');
      setOldNickName('');
      setOldRatingValue(null);
      setOldOpinion('');
      setIsMyReview(false);
    }
  };

  useEffect(() => {
    if (isMyReview && nickName && ratingValue) {
      setDisableChange(nickName === oldNickName && ratingValue === oldRatingValue && opinion===oldOpinion);
    } else {
      setDisableChange(true);
    }
  }, [nickName, ratingValue, opinion]);

  const submitOpinion = async () => {
    const review: IReview = {
      company: companyId,
      nick: nickName,
      rating: ratingValue,
      review: opinion
    };
    try {
      await BackendMethods.submitReview(review);
      setIsChangeRating(true);
      getReview();
    } catch (error) {
    }
  };
  const deleteOpinion = async () => {
    try {
      await BackendMethods.deleteReview(myReview.id);
      setMyReview(null);
    } catch (error) {
    }
  };

  return isShow?(
    <div className={Styles.opnionBox}>
      {disabled && (
        <p className={Styles.reviewHeader}>{t('small.youWillBeAbleToRateAfterYourAppointment')}</p>
      )}
      <ReviewContent
        disabled={disabled}
        ratingValue={ratingValue}
        setRatingValue={setRatingValue}
        opinion={opinion}
        setOpinion={setOpinion}
        nickName={nickName}
        setNickName={setNickName}
        companyName={companyName}
      />
      {isMyReview ? (
        <div className={Styles.btnGroup}>
          <Button
            variant="outlined"
            onClick={() => {
              deleteOpinion();
            }}
            className={Styles.removeButton}
          >
            {t('small.deleteReview')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              submitOpinion();
              setDisableChange(true);
            }}
            className={Styles.submitButton}
            disabled={disableChange}
          >
            {t('small.updateReview')}
          </Button>
        </div>
      ) : (
        <div className={Styles.btnRow}>
          <Button
            variant="outlined"
            onClick={() => {
              submitOpinion();
            }}
            className={Styles.submitButton}
            disabled={nickName === '' || !ratingValue || disabled}
          >
            {t('small.post')}
          </Button>
        </div>
      )}
    </div>
  ): null;
};
