import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
const styles = require('./Features.css');

export default function Features() {
    const { t } = useTranslation();
    return (
        <div className={styles.features}>
            <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                    <img src="/assets/reduced.svg" alt="" />
                </div>
                <div className={styles.featureName}>
                    {t('landing.reducedInteractions')}
                </div>
                <div className={styles.featureDescription}>
                    {t('landing.reducedInteractionsDesc')}
                </div>
            </div>
            <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                    <img src="/assets/instant.svg" alt="" />
                </div>
                <div className={styles.featureName}>
                    {t('landing.instantScheduling')}
                </div>
                <div className={styles.featureDescription}>
                    {t('landing.instantSchedulingDesc')}
                </div>
            </div>
            <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                    <img src="/assets/integrations.svg" alt="" />
                </div>
                <div className={styles.featureName}>
                    {t('landing.integrations')}
                </div>
                <div className={styles.featureDescription}>
                    {t('landing.integrationsDesc')}
                </div>
            </div>
            <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                    <img src="/assets/external.svg" alt="" />
                </div>
                <div className={styles.featureName}>
                    {t('landing.externalCustomers')}
                </div>
                <div className={styles.featureDescription}>
                    {t('landing.externalCustomersDesc')}
                </div>
            </div>
            <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                    <img src="/assets/privacy.svg" alt="" />
                </div>
                <div className={styles.featureName}>
                    {t('landing.completePrivacy')}
                </div>
                <div className={styles.featureDescription}>
                    {t('landing.completePrivacyDesc')}
                </div>
            </div>
            <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                    <img src="/assets/ai-based.svg" alt="" />
                </div>
                <div className={styles.featureName}>
                    {t('landing.aiBased')}
                </div>
                <div className={styles.featureDescription}>
                    {t('landing.aiBasedDesc')}
                </div>
            </div>
            <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                    <img src="/assets/time-zones.svg" alt="" />
                </div>
                <div className={styles.featureName}>
                    {t('landing.timeZones')}
                </div>
                <div className={styles.featureDescription}>
                    {t('landing.timeZonesDesc')}
                </div>
            </div>
            <div className={styles.feature}>
                <div className={styles.featureImageContainer}>
                    <img src="/assets/holidays.svg" alt="" />
                </div>
                <div className={styles.featureName}>
                    <Trans
                        i18nKey="landing.holidays"
                        components={{ span: <span className={styles.featureLine} /> }}
                    />
                </div>
                <div className={styles.featureDescription}>
                    {t('landing.holidaysDesc')}
                </div>
            </div>
        </div>
    );
}
