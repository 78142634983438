// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__3EwC_ {\n    padding: 0 32px;\n    margin: 0 auto;\n    max-width: 800px;\n    margin-bottom: 40px;\n}\n\n    @media (max-width: 1250px) {.root__3EwC_ {\n        padding: 0 16px\n}\n    }\n", "",{"version":3,"sources":["DataProcessingContract.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,mBAAmB;AAKvB;;IAHI,4BANJ;QAOQ;AAER;IADI","file":"DataProcessingContract.css","sourcesContent":[".root {\n    padding: 0 32px;\n    margin: 0 auto;\n    max-width: 800px;\n    margin-bottom: 40px;\n\n    @media (max-width: 1250px) {\n        padding: 0 16px;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__3EwC_"
};
module.exports = exports;
