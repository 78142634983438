import * as React from 'react';
import AppUtils from '../../utils/AppUtils/AppUtils';
import { CHROME_WEB_STORE_EXTENSION_URL } from '../../constants/appConsts';
import { useTranslation } from 'react-i18next';

const Styles = require('./ChromeExtensionInfo.css');

export default function ChromeExtensionInfo() {
    const { t } = useTranslation();
    return (
        <div>
            <div
                className={Styles.ChromeExtensionInfo}
                onClick={() => window.open(CHROME_WEB_STORE_EXTENSION_URL)}
                style={{
                    visibility: AppUtils.shouldRemindOfChromeExtension()
                        ? 'visible'
                        : 'hidden'
                }}
            >
                <div>{t('landing.usingCalendar')}</div>
                <div>
                    <span>{t('landing.getExtension')}</span>
                    <strong> tanbuu </strong>
                    <span>
                        <b>{t('landing.chromeExtension')}</b>
                    </span>
                </div>
                <div>
                    <span className={Styles.ForCalendar}>
                        {t('landing.forCalendar')}
                    </span>
                </div>
                <img
                    className={Styles.badge}
                    src="/assets/chrome-web-store.png"
                />
            </div>
        </div>
    );
}
