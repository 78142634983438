// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__3jCea,\n.root__3jCea *:not(.fa) {\n    box-sizing: content-box;\n}\n\n.popupIcon__2fluc {\n    width: 20px;\n    height: 20px;\n    background-image: url('/assets/chevron-down.svg');\n    background-size: contain;\n    background-repeat: no-repeat;\n}\n", "",{"version":3,"sources":["Autocomplete.css"],"names":[],"mappings":"AAAA;;IAEI,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iDAAiD;IACjD,wBAAwB;IACxB,4BAA4B;AAChC","file":"Autocomplete.css","sourcesContent":[".root,\n.root *:not(:global(.fa)) {\n    box-sizing: content-box;\n}\n\n.popupIcon {\n    width: 20px;\n    height: 20px;\n    background-image: url('/assets/chevron-down.svg');\n    background-size: contain;\n    background-repeat: no-repeat;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__3jCea",
	"popupIcon": "popupIcon__2fluc"
};
module.exports = exports;
