// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__ccqgr {\n    padding: 8px 16px;\n    height: 100%;\n    overflow: auto;\n}\n\n.locations__aR2kJ {\n    margin: 16px 0;\n}\n", "",{"version":3,"sources":["FindService.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","file":"FindService.css","sourcesContent":[".root {\n    padding: 8px 16px;\n    height: 100%;\n    overflow: auto;\n}\n\n.locations {\n    margin: 16px 0;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__ccqgr",
	"locations": "locations__aR2kJ"
};
module.exports = exports;
