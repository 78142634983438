import React, { useContext, useState } from 'react';
import { Dialog } from '@material-ui/core';
import IMeeting from 'interfaces/IMeeting';
import IActions from 'actions/IActions';
import BackendMethods from '../../../../api/BackendMethods';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../../GlobalContext';
const styles = require('./CancelMeetingModal.css');

interface Props {
    actions: IActions;
    meeting: IMeeting;
    history: any;
    open: boolean;
    onClose: () => void;
}

function CancelMeetingModal(props: Props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { profile } = useContext(GlobalContext);
    const confirmCancelMeeting = async () => {
        setLoading(true);
        try {
            const data = await BackendMethods.cancelMeeting(props.meeting.id);
            props.actions.updateMeeting(data, profile.email);
            props.history.push('/main');
            props.onClose();
        } finally {
            setLoading(false);
        }
    };
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{ className: styles.paper }}
            id="CancelMeetingModal"
        >
            <div className={styles.title}>
                {props.meeting.title}
            </div>
            <div>
                {t('home.confirmCancel')}
            </div>
            <div className={styles.buttons}>
                <Button
                    id="CancelMeetingYesButton"
                    onClick={confirmCancelMeeting}
                    className={styles.yesButton}
                    disabled={loading}
                >
                    {t('home.yes')}
                </Button>
                <Button
                    variant="contained"
                    id="CancelMeetingNoButton"
                    onClick={props.onClose}
                    disabled={loading}
                >
                    {t('home.no')}
                </Button>
            </div>
        </Dialog>
    );
}

export default CancelMeetingModal;
