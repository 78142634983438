// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__f1rL_ {\n    padding: 0 40px;\n}\n\n.logoContent__2a23h {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    margin: 55px 0 27px;\n}\n\n.logo__2_Eca {\n    height: 93px;\n    width: 93px;\n}\n\n.laodingContent__177_H {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    margin-top: 200px;\n}\n", "",{"version":3,"sources":["ICloudCalendar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,iBAAiB;AACrB","file":"ICloudCalendar.css","sourcesContent":[".root {\n    padding: 0 40px;\n}\n\n.logoContent {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    margin: 55px 0 27px;\n}\n\n.logo {\n    height: 93px;\n    width: 93px;\n}\n\n.laodingContent {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    margin-top: 200px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__f1rL_",
	"logoContent": "logoContent__2a23h",
	"logo": "logo__2_Eca",
	"laodingContent": "laodingContent__177_H"
};
module.exports = exports;
