import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import BackendMethods from '../api/BackendMethods';

interface Props extends RouteComponentProps<{ code: string }> {}

export default function Shortened(props: Props) {
    useEffect(() => {
        (async () => {
            location.replace(
                await BackendMethods.resolveShortenedURL(props.match.params.code)
            );
        })();
    }, []);
    return null;
}
