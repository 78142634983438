import * as React from 'react';
import classNames from 'classnames';

const Styles = require('./MeLabel.css');

interface Props {
    modifierClass: any;
    text: string;
    isUserOrganizer: boolean;
}

export default function MeLabel(props: Props) {
    if (props.isUserOrganizer) {
        return (
            <div className={classNames(Styles.MeLabel, props.modifierClass)}>
                {props.text}
            </div>
        );
    } else {
        return null;
    }
}
