import * as React from 'react';
import cs from 'classnames';
const $ = require('jquery');
import moment from 'moment';

const Styles = require('./CalendarPopover.css');

interface Props {
    meetingDate: string;
    meeting: any;
    timezone: string;
}

interface State {}

interface $ {
    datepicker(): $;
    datepicker(settings: Object): $;
}

const removeBrowserOffsetFromDate = (millis: number): Date => {
    const date = new Date(millis);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
};

export default class CalendarPopover extends React.Component<Props, State> {
    componentDidMount() {
        const { timezone } = this.props;
        const currentDateFromOffset: Date = moment().tz(timezone).toDate();

        const containerRef = this.refs["container"]; // tslint:disable-line

        $.datepicker._defaults.onAfterUpdate = null;
        const datepicker__updateDatepicker = $.datepicker._updateDatepicker;
        $.datepicker._updateDatepicker = function(inst) {
            datepicker__updateDatepicker.call(this, inst);
            const onAfterUpdate = this._get(inst, 'onAfterUpdate');
            if (onAfterUpdate) {
                onAfterUpdate.apply(inst.input ? inst.input[0] : null, [
                    inst.input ? inst.input.val() : '',
                    inst
                ]);
            }
        };

        $(containerRef).datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            minDate: currentDateFromOffset || 0,
            // focus datepicker on month of meeting date.
            defaultDate: moment.tz(this.props.meetingDate, timezone).toDate(),
            beforeShowDay: date => {
                const dateWithBrowserOffset = removeBrowserOffsetFromDate(date.getTime());
                const meetingDateIncludingOffset = moment.tz(this.props.meetingDate, timezone).toISOString();
                const isSameDayAsMeeting: boolean = moment(
                    dateWithBrowserOffset.toISOString().substring(0, 10),
                    'YYYY-MM-DD'
                ).isSame(meetingDateIncludingOffset.substring(0, 10), 'day');
                const additionalClass = moment(date).tz(timezone).isSame(moment(), 'day')
                    ? 'UIDatePickerTodayDate'
                    : '';
                return [
                    true,
                    isSameDayAsMeeting
                        ? `DateRangeSelected DisableHover ${additionalClass}`
                        : `DisableHover ${additionalClass}`
                ];
            }
        });
    }

    render() {
        return (
            <div className={cs(Styles.CalendarPopover)} ref="container" />
        );
    }
}
