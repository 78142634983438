import Button from 'components/_ReusableComponents/Button/Button';
import React, { useEffect } from 'react';
import BackendMethods from 'api/BackendMethods';
import { useState } from 'react';
import Styles from './SuggestionModal.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import getRanges from 'utils/dateRange';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent } from '@material-ui/core';

type SuggestionModalProps = {
    meetingId: number;
    timezone: string;
    nowDate: any;
    suggestions: number[];
    actions: any;
    open: boolean;
    onClose: any;
};

export const SuggestionModal: React.FC<SuggestionModalProps> = ({ open, meetingId, timezone, nowDate, suggestions, actions, onClose }) => {
    const { t, i18n } = useTranslation();
    const [isModalLoading, setIsModalLoading] = useState(true);
    const [isDisableBtn, setIsDisableBtn] = useState(false);
    const [hint, setHint] = useState<any>(<></>);
    const [name, setName] = useState<string>('');
    const [note, setNote] = useState<string>('');
    const [suggestionId, setSuggestionId] = useState<number>();
    const { renderRange } = getRanges(timezone, t, i18n.language);
    const getSuggestionMeeting = async () => {
        setIsModalLoading(true);
        try {
            const suggestionMeeting = (await BackendMethods.suggestionMeetings(meetingId))[0];
            setSuggestionId(suggestionMeeting.id);
            setName(suggestionMeeting.user.name !== '' ? suggestionMeeting.user.name : suggestionMeeting.user.email);
            setNote(suggestionMeeting.note);
            setHint(renderRange({
                dateFrom: suggestionMeeting.after,
                dateTo: suggestionMeeting.before
            }));
        } catch {
            onClose();
            actions.showErrorMessage(t('home.normalFetchingError'));
        }
        setIsModalLoading(false);
    };
    const acceptSuggestion = async () => {
        if (!suggestionId) {
            return;
        }
        setIsDisableBtn(true);
        try {
            await BackendMethods.acceptSuggestion(suggestionId);
            suggestions.shift();
        } catch {
            actions.showErrorMessage(t('home.responseFailure'));
        } finally {
            setIsDisableBtn(false);
            onClose();
        }
    };
    const rejectSuggestion = async () => {
        setIsDisableBtn(true);
        try {
            await BackendMethods.rejectSuggestion(suggestionId);
            suggestions.shift();
        } catch {
            actions.showErrorMessage(t('home.responseFailure'));
        } finally {
            setIsDisableBtn(false);
            onClose();
        }
    };
    useEffect(() => {
        if (open === true) {
            getSuggestionMeeting();
        }
    }, [open]);

    if (isModalLoading && open) {
        return (
            <Dialog open PaperProps={{
                className: Styles.loadModalProps
            }}>
                <DialogContent>
                    <div className={Styles.loadingModalContent}>
                        <CircularProgress />
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                id="CancelMeetingModal"
            >
                <div className={Styles.suggestionModalContent}>
                    {name + ' ' + t('home.wrote') + ':'}
                    <p className={Styles.description}>
                        {note}
                    </p>
                    <p className={Styles.hint}>
                        {name + ' ' + t('home.suggestToChange')+ ':'}
                    </p>
                    <del className={Styles.delItem}>
                        {nowDate}
                    </del>
                    {hint}
                    <div className={Styles.btnBlock}>
                        <Button
                            className={Styles.noBorderButton}
                            onClick={async (e) => {
                                acceptSuggestion();
                            }}
                            variant="outlined"
                            disabled={isDisableBtn}
                        >
                            {t('home.accept')}
                        </Button>
                        <Button
                            className={Styles.noBorderButton}
                            onClick={(e) => {
                                rejectSuggestion();
                            }}
                            variant="outlined"
                            disabled={isDisableBtn}
                        >
                            {t('home.reject')}
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
};
