import React, { useEffect, useState } from 'react';
import { IConnection, IConnectionScope } from 'interfaces/interfaces';
import { ICloudCalendarLogin } from './ICloudCalendarLogin/ICloudCalendarLogin';
import BackendMethods from 'api/BackendMethods';
import { SetICloudEvent } from './SetICloudEvent/SetICloudEvent';
import Styles from './ICloudCalendar.css';

interface ICloudCalendarProps {
    setIsICloudCalendar: React.Dispatch<React.SetStateAction<boolean>>;
    scope: IConnectionScope[];
    connections: IConnection[];
    isAppleCalendarEvents: boolean;
    setIsAppleCalendarEvents: React.Dispatch<React.SetStateAction<boolean>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
}

export const ICloudCalendar = (props: ICloudCalendarProps) => {
    const { scope, setIsICloudCalendar, connections, isAppleCalendarEvents, setIsAppleCalendarEvents, email, setEmail } = props;
    const [password, setPassword] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [iCloudCalendarEvent, setICloudCalendarEvent] = useState<string>('Work');
    const [controllerable, setControllerable] = useState<boolean>(true);
    const [events, setEvnets] = useState<string[]>([]);
    const updateConnections = async () => {
        const newConnections = await BackendMethods.getConnections();
        while (connections.length > 0) {
            connections.pop();
        }
        newConnections.map(connection => connections.push(connection));
        if(!scope.includes('CALENDAR')) {
            setIsICloudCalendar(false);
        }
    };
    const addICloudCalendar = async () => {
        if (controllerable) {
            if (email.replace(/\s+/g, '').length > 0 && password.length > 0) {
                const provider = 'apple';
                const account = email;
                setControllerable(false);
                try {
                    await BackendMethods.addThirdPartyApplication(provider, account, password, scope);
                    updateConnections();
                    if (scope.includes('CALENDAR')) {
                        setIsAppleCalendarEvents(true);
                    }
                } catch (error) {
                    setIsError(true);
                }
                setControllerable(true);
            } else {
                setIsError(true);
            }
        }
    };

    return (
        <div className={Styles.root}>
            <div className={Styles.logoContent}>
                <img
                    className={Styles.logo}
                    src="/assets/icloud_icon.png"
                />
            </div>
            {isAppleCalendarEvents ? (
                <SetICloudEvent
                    iCloudCalendarEvent={iCloudCalendarEvent}
                    setICloudCalendarEvent={setICloudCalendarEvent}
                    setIsICloudCalendar={setIsICloudCalendar}
                    events={events}
                    setIsAppleCalendarEvents={setIsAppleCalendarEvents}
                    provider={email}
                />
            ) : (
                <ICloudCalendarLogin
                    setIsICloudCalendar={setIsICloudCalendar}
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    isError={isError}
                    setIsError={setIsError}
                    addICloudCalendar={addICloudCalendar}
                    setIsAppleCalendarEvents={setIsAppleCalendarEvents}
                />
            )}
        </div>
    );
};
