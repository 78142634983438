import { getSearchParam } from '../scripts/helpers';

declare const __TEST__: boolean;

const constructPathToRedirect = (path):string => {
    let pathToRedirect = path;

    const enterpriseToken: string = getSearchParam(location.search, 'enterpriseToken') || '';
    const showNotesAfterSignIn: string = getSearchParam(location.search, 'showNotesAfterSignIn') || '';

    if(enterpriseToken) {
        pathToRedirect = `${pathToRedirect}&enterpriseToken=${enterpriseToken}`;
    }

    if(showNotesAfterSignIn === 'true') {
        pathToRedirect = `${pathToRedirect}&showNotesAfterSignIn=true`;
    }

    if(path !== '/' && path.indexOf('login') === -1) {
        return pathToRedirect;
    } else {
        return `${path}`;
    }
};

// if user come from external site to route '/' and user is log in then application will redirect to main route.
// The problem is when user click back button -> browser redirect to '/' and application redirect back to main page.
// window.location.replace won't save redirection in browser history so if user will click back button then browser
// will back to external site
export function locationChangeWithoutSaveHistory(path) {
    // Test do not accept window.location.replace which cause full page reload
    if (!__TEST__) {
        window.location.replace(constructPathToRedirect(path));
    }
}

export function locationChange(path) {
    // Test do not accept window.location.replace which cause full page reload
    if (!__TEST__) {
        window.location.href = constructPathToRedirect(path);
    }
}
