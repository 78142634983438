import * as React from 'react';
import UserInitials from 'components/_ReusableComponents/MobileMenu/UserInitials/UserInitials';
import IActions from 'actions/IActions';
import { IDS } from 'elementsSelectors/_reusable/GlobalSelectors';
import IProfile from 'interfaces/IProfile';
import ILocation from '../../../../interfaces/location/ILocation';
import classNames from 'classnames';
import Button from '../../Button/Button';
const styles = require('./MobileMenuIcon.css');

interface Props {
    actions: IActions;
    name: string;
}

export default class MobileMenuIcon extends React.PureComponent<Props, null> {
    showMobileMenu = () => {
        const {
            actions: { showMobileMenu }
        } = this.props;
        showMobileMenu();
    };

    render() {
        const { name } = this.props;
        const isOutlook = sessionStorage.getItem('outlook') === '1';
        return (
            <div
                id={IDS.MOBILE_MENU_ICON}
                className={styles.MobileMenuIcon}
                onClick={this.showMobileMenu}
            >
                {name ? (
                    <>
                        <UserInitials
                            changeColorOnHover={false}
                            name={name}
                        />
                        <img
                            className={classNames(styles.ChevronDown, {
                                [styles.chevronOutlook]: isOutlook,
                            })}
                            src={isOutlook ? '/assets/chevron.svg' : '/assets/chevron_icon.svg'}
                        />
                    </>
                ) : (
                    <Button
                        variant="contained"
                        className={styles.menuIcon}
                    >
                        <img src="/assets/menu-white.svg" alt="" />
                    </Button>
                )}
            </div>
        );
    }
}
