import * as React from 'react';
import { getSearchParam } from 'scripts/helpers';
import Button from '../../_ReusableComponents/Button/Button';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Styles = require('./VerifyEmail.css');

export default function VerifyEmailPage() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const isVerify = getSearchParam(location.search, 'success') === 'true';
    const message = getSearchParam(location.search, 'message');
    const verifyMessage = (() => {
        if (!message) {
            return '';
        }
        if (message.includes('only once')) {
            return t('auth.linkOnce');
        }
        if (message.includes('email was verified')) {
            return t('auth.emailWasVerified');
        }
        return message;
    })();
    if (isVerify) {
        return (
            <div id="VerifyEmail" className={Styles.VerifyEmail}>
                <span>{verifyMessage}</span>
                <Button
                    variant="contained"
                    onClick={() => history.push('/login')}
                    data-testid="go-to-login"
                >
                    {t('auth.goToLogin')}
                </Button>
            </div>
        );
    } else {
        return (
            <div id="VerifyEmail" className={Styles.VerifyEmail}>
                <p>{verifyMessage}</p>
                <Button variant="contained" onClick={() => history.push('/login')}>
                    {t('auth.goToLogin')}
                </Button>
            </div>
        );
    }
}
