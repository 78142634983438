import React from 'react';
import IPropositionAnswer from 'interfaces/IPropositionAnswer';
import Button from '../../_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
const styles = require('./PropositionButtons.css');

interface Props {
    propositionAnswer: IPropositionAnswer;
    setPropositionAnswer: (answer: IPropositionAnswer) => void;
    acceptedByUser: boolean;
    setNextPropositionActive: () => void;
    rangeNarrowed: boolean;
    isSetDate: boolean;
    setDate: () => void;
}

export default function PropositionButtons(props: Props) {
    const { t } = useTranslation();
    const { accepted, rejected } = props.propositionAnswer;
    const onClick = newAccepted => (event) => {
        event.stopPropagation();
        props.setPropositionAnswer({
            ...props.propositionAnswer,
            accepted: newAccepted,
            rejected: !newAccepted,
        });
        props.setNextPropositionActive();
    };
    if (props.isSetDate) {
        return (
            <Button
                onClick={props.setDate}
                className={styles.setDate}
            >
                {t('home.setThisDate')}
            </Button>
        );
    }
    return (
        <div data-answers-buttons="" className={styles.root}>
            <Button
                variant={(
                    (accepted || props.acceptedByUser) && !props.rangeNarrowed
                ) ? 'contained' : 'outlined'}
                onClick={onClick(true)}
                disabled={props.acceptedByUser && !props.rangeNarrowed}
                data-type="yes"
            >
                {t('home.accept')}
            </Button>
            <Button
                variant={rejected ? 'contained' : 'outlined'}
                onClick={onClick(false)}
                disabled={props.rangeNarrowed}
                data-type="no"
            >
                {t('home.reject')}
            </Button>
        </div>
    );
}
