import * as React from 'react';
import IActions from '../../../../actions/IActions';
import PlanInfo from './PlanInfo/PlanInfo';
import PaymentMethod from './PaymentMethod/PaymentMethod';
const Styles = require('./PlanSummary.css');

interface Props {
    data: any;
    actions: IActions;
    plusPlanAdmin: any;
    history: any;
}

const PlanSummary = (props: Props) => {
    return (
        <section className={Styles.PlanSummary}>
            <PlanInfo {...props} />
            <PaymentMethod {...props} />
        </section>
    );
};

export default PlanSummary;
