// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__ivsFI {\n    padding: 0 32px;\n    margin: 0 auto;\n    max-width: 800px;\n}\n\n    @media (max-width: 1250px) {.root__ivsFI {\n        padding: 0 16px\n}\n    }\n\n.bigText__HK0C6 {\n    font-size: 26px;\n    margin-bottom: 16px;\n}\n\n.text__1j3MO {\n    font-size: 20px;\n    margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["About.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AAKpB;;IAHI,4BALJ;QAMQ;AAER;IADI;;AAGJ;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","file":"About.css","sourcesContent":[".root {\n    padding: 0 32px;\n    margin: 0 auto;\n    max-width: 800px;\n\n    @media (max-width: 1250px) {\n        padding: 0 16px;\n    }\n}\n\n.bigText {\n    font-size: 26px;\n    margin-bottom: 16px;\n}\n\n.text {\n    font-size: 20px;\n    margin-bottom: 16px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__ivsFI",
	"bigText": "bigText__HK0C6",
	"text": "text__1j3MO"
};
module.exports = exports;
