// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MobileMenuIcon__1OCL5 {\n    cursor: pointer;\n    height: 30px;\n    width: 70px;\n    display: flex;\n    align-items: center;\n    margin-left: 5px;\n    margin-right: auto;\n}\n\n.ChevronDown__U1lEp {\n    height: 18px;\n    width: 18px;\n    margin-left: 8px;\n}\n\n.chevronOutlook__2dldb {\n    transform: rotate(90deg);\n}\n\n.menuIcon__PSlu6 {\n    min-width: 0;\n    min-width: initial;\n    padding: 8.5px 7px;\n}\n", "",{"version":3,"sources":["MobileMenuIcon.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,YAAkB;IAAlB,kBAAkB;IAClB,kBAAkB;AACtB","file":"MobileMenuIcon.css","sourcesContent":[".MobileMenuIcon {\n    cursor: pointer;\n    height: 30px;\n    width: 70px;\n    display: flex;\n    align-items: center;\n    margin-left: 5px;\n    margin-right: auto;\n}\n\n.ChevronDown {\n    height: 18px;\n    width: 18px;\n    margin-left: 8px;\n}\n\n.chevronOutlook {\n    transform: rotate(90deg);\n}\n\n.menuIcon {\n    min-width: initial;\n    padding: 8.5px 7px;\n}\n"]}]);
// Exports
exports.locals = {
	"MobileMenuIcon": "MobileMenuIcon__1OCL5",
	"ChevronDown": "ChevronDown__U1lEp",
	"chevronOutlook": "chevronOutlook__2dldb",
	"menuIcon": "menuIcon__PSlu6"
};
module.exports = exports;
