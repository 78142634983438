import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/_ReusableComponents/Button/Button';
import classNames from 'classnames';
import { Redirect, useParams, Link, Route } from 'react-router-dom';
import General from './General';
import Resources from './Resources';
import Services from './Services';
import Sites from './Sites';
import Personnel from './Personnel';
import IProfile from '../../../interfaces/IProfile';
import Customers from './Customers';
import { useHistory } from 'react-router';
const styles = require('./Settings.css');

interface IProps {
    profile: IProfile;
}

type Tab = 'general' | 'resources' | 'services' | 'sites' | 'staff';

export default function Settings({ profile }: IProps) {
    const { t } = useTranslation();
    const params = useParams<{ tab: Tab }>();
    const history = useHistory();
    if (!params.tab) {
        history.push('/sb/settings/general');
    }
    return (
        <div className={styles.root}>
            <div className={styles.tabs}>
                {['general', 'resources', 'services', 'sites', 'personnel', 'customers'].map((tab) => (
                    <Button
                        key={tab}
                        variant="contained"
                        className={classNames(styles.tab, {
                            [styles.tabActive]: params.tab === tab,
                        })}
                        component={Link}
                        to={`/sb/settings/${tab}`}
                    >
                        {t(`small.${tab}`)}
                    </Button>
                ))}
            </div>
            <div className={styles.tabContent}>
                <Route path="/sb/settings/general" component={General} />
                <Route path="/sb/settings/resources" component={Resources} />
                <Route path="/sb/settings/services" component={Services} />
                <Route path="/sb/settings/sites" component={Sites} />
                <Route
                    path="/sb/settings/personnel"
                    render={() => (
                        <Personnel timeFormat={profile.timeFormat} />
                    )}
                />
                <Route
                    path="/sb/settings/customers"
                    render={() => (
                        <Customers profile={profile} />
                    )}
                />
            </div>
        </div>
    );
}
