import { History } from 'history';
const config = require('CONFIG');

export function redirectToBaseDomain(path?: string) {
    const { location } = window;
    if (location.host !== config.baseDomain && location.host.endsWith(config.baseDomain)) {
        const newPath = path || `${location.pathname}${location.search}`;
        location.assign(`https://${config.baseDomain}${newPath}`);
    }
}

export function redirectToSubdomain(subdomain: string, path?: string) {
    const { location } = window;
    if (location.host === config.baseDomain) {
        const newPath = path || `${location.pathname}${location.search}`;
        location.assign(`https://${subdomain}.${config.baseDomain}${newPath}`);
    }
}

export function getSubdomain() {
    const { hostname } = window.location;
    if (hostname === config.baseDomain) {
        return null;
    }
    if (hostname.endsWith(config.baseDomain)) {
        return hostname.split('.')[0];
    }
    return null;
}

export function redirectToBaseIfNeeded(history: History, path: string) {
    if (getSubdomain() === null) {
        history.push(path);
    } else {
        redirectToBaseDomain(path);
    }
}
