import * as React from 'react';
import * as ReactDOM from 'react-dom';
const modalRoot = document.getElementById('modal-root');

interface Props {
    position?: string;
    onClose?: Function;
}
export default class Modal extends React.Component<Props, null> {
    el: any;
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.onClickBackground = this.onClickBackground.bind(this);
    }

    componentDidMount() {
        // The portal element is inserted in the DOM tree after
        // the Modal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Modal and only render the children when Modal
        // is inserted in the DOM tree.
        if (modalRoot) {
            modalRoot.appendChild(this.el);
        }
    }

    componentWillUnmount() {
        if (modalRoot) {
            modalRoot.removeChild(this.el);
        }
    }

    onClickBackground(e) {
        e.stopPropagation();
        e.preventDefault();
    }
    render() {
        return ReactDOM.createPortal(
            <div className="tanbuu__modal" onClick={this.onClickBackground}>
                <div>{this.props.children}</div>
                <style>{`
                    .tanbuu__modal{
                        position:fixed;
                        animation-duration: 1s;
                        animation-fill-mode: both;
                        animation-iteration-count: 1;
                        animation-name: showElement;
                        animation-timing-function: ease-in-out;
                        background: rgba(0, 0, 0, 0.3);
                        min-height: 100vh;
                        left: 0;
                        overflow-y: hidden;
                        position: fixed;
                        top: 0;
                        min-width: 100vw;
                        z-index: 101;
                    }
                    @keyframes showElement {

                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }
                    .tanbuu__modal > div {
                        animation-duration: 0.6s;
                        animation-fill-mode: both;
                        animation-iteration-count: 1;
                        animation-name: ShowFromTop;
                        animation-timing-function: ease-in-out;
                        margin: 0 auto;
                        position: relative;
                        top:${
                            this.props.position === 'top'
                                ? '44px'
                                : 'calc(50vh - 200px)'
                        };
                        transition: 0.3s all linear;
                        width: 100%;
                    }
                    @keyframes ShowFromTop {

                        0% {
                            opacity: 0;
                            transform: translate(0, -100px);
                        }

                        100% {
                            opacity: 1;
                            transform: translate(0, 0);
                        }
                    }
                `}</style>
            </div>,
            this.el
        );
    }
}
