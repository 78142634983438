import React from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from './Autocomplete/Autocomplete';
import { getCodeLabel } from '../../utils/phone';
import { allCountries, Country } from 'country-telephone-data';
import TextField from './TextField/TextField';
import classNames from 'classnames';
import { TextFieldProps } from '@material-ui/core';
const styles = require('./PhoneInput.css');

interface IProps {
    country: Country;
    setCountry: (country: Country) => void;
    phone: string;
    setPhone: (phone: string) => void;
    className?: string;
    phoneProps?: TextFieldProps;
}

export default function PhoneInput({ country, setCountry, phone, setPhone, className, phoneProps }: IProps) {
    const { t } = useTranslation();
    return (
        <div className={classNames(styles.root, className)}>
            <Autocomplete
                value={country}
                getOptionLabel={getCodeLabel}
                onChange={(event, newValue) => (
                    setCountry(newValue)
                )}
                options={allCountries}
                className={styles.country}
                disableClearable
                label={t('small.countryCode')}
            />
            <TextField
                value={phone}
                onChange={({ target }) => (
                    setPhone(target.value)
                )}
                label={t('small.phoneNumber')}
                className={styles.phone}
                {...phoneProps}
            />
        </div>
    );
}
