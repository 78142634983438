import React, { useEffect, useState } from 'react';
import Button from 'components/_ReusableComponents/Button/Button';
import { RadioButton } from 'components/_ReusableComponents/RadioButton/RadioButton';
import Styles from './SetICloudEvent.css';
import { useTranslation } from 'react-i18next';
import BackendMethods from 'api/BackendMethods';
import Skeleton from '@material-ui/lab/Skeleton';

interface ICloudCalendarEventProps {
    iCloudCalendarEvent: string;
    setICloudCalendarEvent: React.Dispatch<React.SetStateAction<string>>;
    setIsICloudCalendar: React.Dispatch<React.SetStateAction<boolean>>;
    events: string[];
    setIsAppleCalendarEvents: React.Dispatch<React.SetStateAction<boolean>>;
    provider: string;
}

interface ICloudCalendarEvent {
    name: string;
    preferred: boolean;
}

export const SetICloudEvent = (props: ICloudCalendarEventProps) => {
    const { t } = useTranslation();
    const { iCloudCalendarEvent, setICloudCalendarEvent, setIsICloudCalendar, setIsAppleCalendarEvents, provider } = props;
    const [events, setEvnets] = useState<string[]>([]);
    const [controllerable, setControllerable] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        getEvents();
    }, []);
    const getEvents = async () => {
        setIsLoading(true);
        const iEvents: ICloudCalendarEvent[] = await BackendMethods.getAppleCalendar(provider);
        iEvents.map(event => {
            events.push(event.name);
            if (event.preferred) {
                setICloudCalendarEvent(event.name);
            }
        });
        setIsLoading(false);
    };
    const setPreferredAppleCalendar = async () => {
        if (controllerable) {
            const account = provider;
            const name = iCloudCalendarEvent;
            setControllerable(false);
            try {
                await BackendMethods.savePreferredAppleCalendar(account, name);
                setIsICloudCalendar(false);
            } catch (error) {
            }
            setControllerable(true);
        }
    };
    return (
        <div>
            <h3 className={Styles.subHeader}>{t('settings.setTheICloudCalendarYouWouldLikeToAddNewEventsTo')}:</h3>
            <div className={Styles.radioGroup}>
                {isLoading ? (
                    <div>
                        {Array.from(new Array(3)).map((item, key) => (
                            <div className={Styles.radionContent} key={key}>
                                <Skeleton variant="circular" width={19} height={18} />
                                <div className={Styles.radioSkeleton}>
                                    <Skeleton variant="text" width={100} height={25} />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <>
                        {events.map((event, key) => (
                            <div className={Styles.radionContent} key={key}>
                                <RadioButton
                                    checked={event === iCloudCalendarEvent}
                                    label={event}
                                    value={event}
                                    onChange={setICloudCalendarEvent}
                                    style={{
                                        color: '#1488a8',
                                        size: 18,
                                        padding: 1,
                                        titlePosTop: 0,
                                        titlePosLeft: 27,
                                        fontColor: '#013253',
                                        fontSize: 16
                                    }}
                                />
                            </div>
                        ))}
                    </>
                )}
            </div>
            <div className={Styles.btnGroup}>
                <Button
                    className={Styles.cancelButton}
                    onClick={() => {
                        setIsICloudCalendar(false);
                        setIsAppleCalendarEvents(false);
                    }}
                >
                    {t('settings.cancel')}
                </Button>
                <Button
                    className={Styles.setButton}
                    onClick={() => setPreferredAppleCalendar()}
                >
                    {t('settings.set')}
                </Button>
            </div>
        </div>
    );
};
