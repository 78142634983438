import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';

export function useIsDesktop() {
    return useMediaQuery('(min-width: 1250px)', { noSsr: true });
}

export function withIsDesktop(Component: React.ElementType) {
    return (props) => {
        const isDesktop = useIsDesktop();
        return <Component{...props} isDesktop={isDesktop} />;
    };
}
