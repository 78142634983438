// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".EmailHandlerPage__2-AyU {\n    margin: 30px auto;\n    width: 320px;\n    display: flex;\n    flex-direction: column;\n}\n\n.EmailHandlerPage__2-AyU > div:first-child {\n    margin-bottom: 30px;\n    text-align: center;\n}\n\n.EmailHandlerPage__2-AyU > .WrongQueryParams__1hrCc {\n    text-align: center;\n}\n\n.EmailHandlerPage__2-AyU > div:first-child > img {\n    max-width: 100%;\n    width: 200px;\n}\n\n@media only screen and (max-width: 500px) {\n    .EmailHandlerPage__2-AyU {\n        margin: 0;\n        padding: 30px;\n        width: 100%;\n        min-height: 100vh;\n    }\n\n    .EmailHandlerPage__2-AyU > .WrongQueryParams__1hrCc > button {\n        width: 100%;\n    }\n}\n", "",{"version":3,"sources":["EmailHandlerPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI;QACI,SAAS;QACT,aAAa;QACb,WAAW;QACX,iBAAiB;IACrB;;IAEA;QACI,WAAW;IACf;AACJ","file":"EmailHandlerPage.css","sourcesContent":[".EmailHandlerPage {\n    margin: 30px auto;\n    width: 320px;\n    display: flex;\n    flex-direction: column;\n}\n\n.EmailHandlerPage > div:first-child {\n    margin-bottom: 30px;\n    text-align: center;\n}\n\n.EmailHandlerPage > .WrongQueryParams {\n    text-align: center;\n}\n\n.EmailHandlerPage > div:first-child > img {\n    max-width: 100%;\n    width: 200px;\n}\n\n@media only screen and (max-width: 500px) {\n    .EmailHandlerPage {\n        margin: 0;\n        padding: 30px;\n        width: 100%;\n        min-height: 100vh;\n    }\n\n    .EmailHandlerPage > .WrongQueryParams > button {\n        width: 100%;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"EmailHandlerPage": "EmailHandlerPage__2-AyU",
	"WrongQueryParams": "WrongQueryParams__1hrCc"
};
module.exports = exports;
