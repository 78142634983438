import React, { useState } from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import BackendMethods from '../../../api/BackendMethods';
import ICardDetails from '../../../interfaces/backendResponses/ICardDetails';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
const styles = require('./BillingCheckout.css');

interface IBillingCheckoutProps {
    stripe: any;
    onBackClick: () => void;
    onSuccess: (cardDetails: ICardDetails) => void;
    card?: ICardDetails;
    saveButton?: string;
    noHeader?: boolean;
    margin?: boolean;
}

const BillingCheckout = (props: IBillingCheckoutProps) => {
    const { t } = useTranslation();
    const { card } = props;
    const [name, setName] = useState(card ? card.name : '');
    const [address, setAddress] = useState(card ? card.line1 : '');
    const [city, setCity] = useState(card ? card.city : '');
    const [state, setState] = useState(card ? (card.state || '') : '');

    const [nameError, setNameError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);

    const [editingCard, setEditingCard] = useState(false);
    const newCard = !card || editingCard;

    const [cardDeclined, setCardDeclined] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let error = false;
        if (name.trim() === '') {
            setNameError(true);
            error = true;
        }
        if (address.trim() === '') {
            setAddressError(true);
            error = true;
        }
        if (city.trim() === '') {
            setCityError(true);
            error = true;
        }
        if (error) {
            return;
        }
        setLoading(true);
        let sourceId;
        if (newCard) {
            const { source, error: stripeError } = await props.stripe.createSource({ type: 'card' });
            if (stripeError) {
                setCardDeclined(true);
            } else {
                sourceId = source.id;
            }
        } else {
            sourceId = card.id;
        }
        try {
            const cardDetails = await BackendMethods.storeCard(sourceId, name, address, city, state);
            props.onSuccess(cardDetails);
        } catch {
            setCardDeclined(true);
        }
        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit} className={props.margin ? styles.BillingCheckout : ''}>
            {!props.noHeader && (
                <div className={styles.Header}>
                    {t('plan.paymentInformation')}
                </div>
            )}
            <div className={styles.BillingName}>
                <TextField
                    label={t('plan.name')}
                    placeholder={t('plan.namePlaceholder')}
                    autoComplete="name"
                    fullWidth
                    margin="dense"
                    value={name}
                    onChange={({ target }) => {
                        setName(target.value);
                        setNameError(false);
                    }}
                    error={nameError}
                />
            </div>
            <div className={styles.BillingAddress}>
                <TextField
                    label={t('plan.address')}
                    placeholder={t('plan.addressPlaceholder')}
                    autoComplete="street-address"
                    fullWidth
                    margin="dense"
                    value={address}
                    onChange={({ target }) => {
                        setAddress(target.value);
                        setAddressError(false);
                    }}
                    error={addressError}
                />
                <div className={styles.Details}>
                    <TextField
                        label={t('plan.city')}
                        placeholder={t('plan.cityPlaceholder')}
                        autoComplete="address-level2"
                        margin="dense"
                        className={styles.City}
                        value={city}
                        onChange={({ target }) => {
                            setCity(target.value);
                            setCityError(false);
                        }}
                        error={cityError}
                    />
                    <TextField
                        label={t('plan.state')}
                        placeholder={t('plan.statePlaceholder')}
                        autoComplete="address-level1"
                        margin="dense"
                        className={styles.State}
                        value={state}
                        onChange={({ target }) => setState(target.value)}
                    />
                </div>
            </div>
            <div className={styles.CardDetailsHeader}>
                {t('plan.cardDetails')}
            </div>
            {newCard ? (
                <CardElement
                    id="CardElement"
                    style={{
                        base: {
                            color: '#013253',
                            fontFamily: 'Source Sans Pro',
                            fontSize: '16px',
                            '::placeholder': {
                                color: '#08a'
                            }
                        }
                    }}
                />
            ) : (
                <div className={styles.CardDetailsContainer}>
                    <div className={styles.CardDetails}>
                        <div>
                            {card.brand}, {t('plan.endingIn')} {card.last4}
                        </div>
                        <div>
                            {t('plan.expires')}: {card.expires}
                        </div>
                    </div>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={styles.UpdateButton}
                        onClick={() => setEditingCard(true)}
                    >
                        {t('plan.update')}
                    </Button>
                </div>
            )}
            <span className={styles.StripeLogo}>
                {t('plan.poweredBy')}
                <img src="../../../assets/stripe.svg" alt="Stripe" />
            </span>
            {cardDeclined && (
                <div className={styles.CardDeclined}>
                    {t('plan.cardDeclined')}
                </div>
            )}
            <div className={styles.Navigation}>
                <Button
                    variant="text"
                    className={styles.backButton}
                    onClick={props.onBackClick}
                >
                    {t('plan.back')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={styles.Button}
                    type="submit"
                    disabled={loading}
                >
                    {props.saveButton || t('plan.continue')}
                </Button>
            </div>
        </form>
    );
};

export default injectStripe(BillingCheckout);
