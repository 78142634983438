import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import IMeeting from '../../../interfaces/IMeeting';
import ILocation from '../../../interfaces/location/ILocation';
import IActions from '../../../actions/IActions';
import MeetingOptions from '../../MeetingPageComponents/MeetingOptions/MeetingOptions';
import BlinkingDots from '../../_ReusableComponents/BlinkingDots/BlinkingDots';
import { Collapse, IconButton, Tooltip } from '@material-ui/core';
import {useContext, useEffect, useState} from 'react';
import TimeZoneSelect from '../../_ReusableComponents/TimeZoneSelect/TimeZoneSelect';
import BackendMethods from '../../../api/BackendMethods';
import getMeetingMessage from '../../../utils/getMeetingMessage';
import getRanges from '../../../utils/dateRange';
import formatDuration from '../../../utils/duration';
import { isAdHoc } from '../../../scripts/helpers';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../GlobalContext';
import Button from 'components/_ReusableComponents/Button/Button';
import { IParticipant } from 'interfaces/interfaces';
import { SuggestionModal } from 'components/_ReusableComponents/Modals/SuggestionModal/SuggestionModal';
import styles from './MeetingRow.css';

interface Props {
    item: IMeeting;
    history: any;
    actions: IActions;
    openMeeting?: number;
    expanded: boolean;
    email: string;
    isDesktop: boolean;
    onSetDate: (id: number) => void;
}

export default function MeetingRow({ item, openMeeting, email, isDesktop, actions, expanded, history, onSetDate }: Props) {
    const { t, i18n } = useTranslation();
    const { timezone, declined, optional } = item.participants.find(R.whereEq({ email }));
    const { profile } = useContext(GlobalContext);
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);
    const [participantsExpanded, setParticipantsExpanded] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const participantsNotResponded = R.filter(
        participant => participant.email !== email && !participant.declined && !item.acceptedBy.includes(participant.email),
        item.participants
    );
    const allResponded = participantsNotResponded.length === 0;
    const allDeclined = R.all(
        participant => participant.email === email || participant.declined,
        item.participants
    ) && !declined;
    const inProgress = item.state === 'WAITING_FOR_ANSWERS' && (item.hasResponded || optional) && !allResponded && !declined &&
        item.hasPropositions && !allDeclined;
    const inProgressRespond = item.state === 'WAITING_FOR_ANSWERS' && (!item.hasResponded || allResponded) &&
        item.hasPropositions && !declined && !allDeclined && !optional;
    const canceled = item.state === 'CANCELED' && !declined;
    const scheduled = item.state === 'SCHEDULED' && !declined && !allDeclined;
    const calculating = item.state === 'CALCULATING' && !declined && !allDeclined;
    const recalculating = item.state === 'RECALCULATING' && !declined && !allDeclined;
    const noSlots = item.state === 'WAITING_FOR_ANSWERS' && !item.hasPropositions && !declined && !allDeclined;
    const to = (() => {
        if (isDesktop) {
            if (item.state === 'SCHEDULED' || item.state === 'CANCELED' || isAdHoc()) {
                return null;
            }
            return item.id === openMeeting ? '/main' : `/main/${item.id}`;
        } else {
            return `/meeting/${item.id}`;
        }
    })();
    const message = getMeetingMessage(item, email, t);
    const [timezoneSelectOpen, setTimezoneSelectOpen] = useState(false);
    const onTimezoneChange = async (newTimezone) => {
        setTimezoneSelectOpen(false);
        const meeting = await BackendMethods.setMeetingTimezone(item.id, newTimezone);
        actions.updateMeeting(meeting, profile.email);
    };
    const { renderRange } = getRanges(timezone, t, i18n.language);
    const otherParticipant = item.participants.find((x) => x.email !== email);
    useEffect(() => {
        setParticipantsExpanded(item.id === openMeeting);
    }, [item.id, openMeeting]);
    const GetAttendee = () => {
        const attendeeId: number = item.inviteeSuggestions[0];
        const attendee: IParticipant = item.participants.find((participant) => participant.id === attendeeId);
        if (!attendee) {
            return;
        }
        const attendeeName: string = attendee?.name.length > 0
            ? attendee.name
            : attendee.email;
        return (
            <div className={styles.suggestionLabel}>
                {attendeeName + ' ' + t('home.suggestsChange')}
                {
                    isDesktop &&
                    <Tooltip
                        title={t('home.reviewSuggestion')}
                        placement="right"
                        classes={{ tooltip: styles.tooltip }}
                    >
                        <span className={styles.info}>i</span>
                    </Tooltip>
                }
            </div>
        );
    };

    function formatDate(startDate: string, duration: number) {
        const start = moment.tz(startDate, timezone);
        let relativeDate;
        if (start.isSame(moment(), 'day')) {
            relativeDate = t('common.today');
        } else if (start.isSame(moment().add(1, 'day'), 'day')) {
            relativeDate = t('common.tomorrow');
        } else if (start.isBetween(
            moment(),
            moment().add(7, 'days').startOf('day'))
        ) {
            relativeDate = start.format('dddd');
        }
        const dayMonth = i18n.language === 'pl' ? 'D MMM.' : 'MMM. D,';
        const absoluteDate = start.format(`${dayMonth} Y ${profile.timeFormat === '12h' ? 'h:mm a' : 'H:mm'}`);
        const end = moment(start).add(duration, 'minutes');
        const endTime = end.format(profile.timeFormat === '12h' ? 'h:mm a' : 'H:mm');
        const nextDay = start.isSame(end, 'day') ? '' : ` (${t('common.nextDay')})`;
        const zoneName = end.zoneName();
        return `${relativeDate ? `${relativeDate} ` : ''}${absoluteDate} - ${endTime}${nextDay} ${zoneName}`;
    }
    const content = (
        <div className={styles.root}>
            <div
                className={classNames(styles.content, {
                    [styles.contentInProgress]: inProgress || calculating || recalculating || noSlots,
                    [styles.contentRespond]: inProgressRespond,
                    [styles.contentTimezoneSelect]: timezoneSelectOpen,
                    [styles.contentLink]: to !== null,
                })}
            >
                <div className={styles.spaceBetween}>
                    {
                        item.inviteeSuggestions?.length > 0 &&
                        item.organizer.email === profile.email ?
                        (
                            <Button
                                className={styles.suggestionAlert}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setModalOpen(true);
                                }}
                            >
                                <GetAttendee/>
                            </Button>
                        ) :
                        (
                            <div className={styles.status}>
                                {inProgressRespond && (
                                    <>
                                        {t(
                                            'home.waitingOnYou',
                                            {
                                                count: item.participants.filter((x) => !x.declined).length - 1,
                                            },
                                        )}
                                    </>
                                )}
                                {inProgress && t('home.waitingOnOthers')}
                                {canceled && t('home.canceled')}
                                {declined && t('home.youDeclined')}
                                {allDeclined && t('home.declinedByParticipants')}
                                {scheduled && t('home.scheduled')}
                                {(calculating || recalculating) && (
                                    <div className={styles.calculatingContainer}>
                                        {t('home.searchingForDates')}
                                        <BlinkingDots />
                                    </div>
                                )}
                                {noSlots && t('home.noSlots')}
                                {isDesktop && !inProgressRespond && message && (
                                    <Tooltip
                                        title={message}
                                        placement="right"
                                        classes={{ tooltip: styles.tooltip }}
                                    >
                                        <span className={styles.info}>i</span>
                                    </Tooltip>
                                )}
                            </div>
                        )
                    }

                    {item.state === 'WAITING_FOR_ANSWERS' && item.isOrganizer && (!item.invitesSent || !inProgressRespond) && (
                        <Tooltip
                            title={item.invitesSent ? t('home.inviteSent') : t('home.acceptToSend')}
                            placement={isDesktop ? 'left' : 'bottom'}
                            classes={{
                                tooltip: styles.tooltip,
                                tooltipPlacementBottom: styles.tooltipBottom,
                            }}
                        >
                            <div
                                className={classNames(styles.meetingLabel, {
                                    [styles.meetingLabelSent]: item.invitesSent
                                })}
                                onClick={async (event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    if (!item.invitesSent) {
                                        await BackendMethods.sendInvites(item.id);
                                        actions.showSuccessMessage(t('home.inviteSentMessage'));
                                    }
                                }}
                            >
                                {item.invitesSent ? t('home.invitesSent') : t('home.invitesNotSent')}
                            </div>
                        </Tooltip>
                    )}
                    {(!item.isOrganizer || item.invitesSent) && inProgressRespond && (
                        <Tooltip
                            title={message}
                            placement={isDesktop ? 'left' : 'bottom'}
                            classes={{
                                tooltip: styles.tooltip,
                                tooltipPlacementBottom: styles.tooltipBottom,
                            }}
                        >
                            <div className={styles.meetingLabel}>
                                {t('home.respond')}
                            </div>
                        </Tooltip>
                    )}
                </div>
                <div
                    className={styles.titleRow}
                >
                    <div
                        className={styles.title}
                        data-testid="title"
                    >
                        {item.title}
                    </div>
                    {isDesktop && (
                        <>
                            {item.description && (
                                <img
                                    src="/assets/chevron-blue.svg"
                                    className={classNames(styles.chevron, {
                                        [styles.chevronFlipped]: descriptionExpanded,
                                    })}
                                    onClick={(event) => {
                                        if (isDesktop) {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            setDescriptionExpanded(R.not);
                                        }
                                    }}
                                />
                            )}
                            <div className={styles.optionsContainer}>
                                <MeetingOptions
                                    actions={actions}
                                    history={history}
                                    email={email}
                                    currentMeeting={item}
                                    className={styles.options}
                                    onSetDate={() => onSetDate(item.id)}
                                />
                            </div>
                        </>
                    )}
                </div>
                <Collapse
                    in={isDesktop && descriptionExpanded && item.description !== ''}
                >
                    <div className={styles.description}>
                        {item.description}
                    </div>
                </Collapse>
                {scheduled && (
                    <div className={styles.startDate}>
                        {formatDate(item.startDate, item.duration)}
                    </div>
                )}
                <div
                    className={styles.wantsToMeet}
                    onClick={(event) => {
                        if (isDesktop) {
                            event.preventDefault();
                            event.stopPropagation();
                            setParticipantsExpanded(R.not);
                        }
                    }}
                >
                    <b>
                        {item.isOrganizer
                            ? t('home.you')
                            : (item.organizer.name || item.organizer.email)}
                    </b>
                    {' '}
                    {item.isOrganizer ? t('home.wantToMeet') : t('home.wantsToMeet')}
                    {' '}
                    {item.isOrganizer
                        ? (otherParticipant.name || otherParticipant.email)
                        : t('home.withYou')
                    }
                    {item.participants.length > 2 && ` +${item.participants.length - 2}`}
                    {isDesktop && (
                        <img
                            src="/assets/chevron-blue.svg"
                            className={classNames(styles.chevron, {
                                [styles.chevronFlipped]: participantsExpanded,
                            })}
                        />
                    )}
                </div>
                <Collapse in={isDesktop && participantsExpanded}>
                    <div className={styles.participants}>
                        {item.participants.map((participant, i) => (
                            <div
                                className={styles.participant}
                                key={participant.email}
                            >
                                {participant.name || participant.email}
                                {participant.email === email && (
                                    <span className={styles.label}>
                                        {t('common.you')}
                                    </span>
                                )}
                                {participant.email === item.organizer.email && (
                                    <span className={styles.label}>
                                        {t('home.organizer')}
                                    </span>
                                )}
                                {participant.optional && (
                                    <span className={styles.optionalLabel}>
                                        {t('new.optional')}
                                    </span>
                                )}
                                {participant.declined && (
                                    <span className={styles.label}>
                                        {t('home.declined')}
                                    </span>
                                )}
                                <div className={styles.participantTimezone}>
                                    {participant.timezone}
                                    {participant.email === email && (inProgress || inProgressRespond) && (
                                        <>
                                            <IconButton
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    setTimezoneSelectOpen(true);
                                                }}
                                                className={styles.timezoneEditButton}
                                                size="large">
                                                <img src="/assets/pen.svg" />
                                            </IconButton>
                                            {participant.timezone !== moment.tz.guess() && (
                                                <Tooltip
                                                    title={t('home.timezoneMismatch')}
                                                    placement="right"
                                                    classes={{ tooltip: styles.tooltip }}
                                                >
                                                    <img
                                                        src="/assets/exclamation.svg"
                                                        className={styles.warningIcon}
                                                    />
                                                </Tooltip>
                                            )}
                                            {timezoneSelectOpen && (
                                                <div
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                    }}
                                                    className={styles.timezoneSelect}
                                                >
                                                    <TimeZoneSelect
                                                        onChange={onTimezoneChange}
                                                        onBlur={() => setTimezoneSelectOpen(false)}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </Collapse>
                {!scheduled && (
                    <div className={styles.duration}>
                        {t('home.forDuration')}
                        {' '}
                        <b>
                            {formatDuration(moment.duration(Number(item.duration), 'minutes'), t)}
                        </b>
                        {', '}
                        {renderRange({
                            dateFrom: item.after,
                            dateTo: item.before,
                        })}
                    </div>
                )}
            </div>
            {item.id === openMeeting && expanded && (
                <div className={styles.openMeetingIndicator} />
            )}
        </div>
    );
    if (to) {
        return (
            <>
                <Link
                    to={to}
                    className={styles.wrapper}
                >
                    {content}
                </Link>
                <SuggestionModal
                    open={modalOpen}
                    meetingId={item.id}
                    timezone={timezone}
                    nowDate={
                        renderRange({
                            dateFrom: item.after,
                            dateTo: item.before,
                        })
                    }
                    suggestions={item.inviteeSuggestions}
                    actions={actions}
                    onClose={() => {
                        setModalOpen(false);
                    }}
                />
            </>
        );
    }
    return content;
}
