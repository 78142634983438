// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".rating__1BAAp {\n    margin: 3px 0 6px -2px;\n}\n\n.b_border__2tqxp {\n    border-bottom: solid 2px #f0f0f0;\n}\n\n.reviewText__1ODeb {\n    white-space: pre;\n}\n\n.isReportComponent__r4qBP {\n    color: #1488a8 !important;\n}\n\n.isReportComponent__r4qBP:hover {\n    cursor: pointer;\n}\n\n.isNonReportComponent__pXaoj {\n    color: lightgrey;\n}\n", "",{"version":3,"sources":["Review.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB","file":"Review.css","sourcesContent":[".rating {\n    margin: 3px 0 6px -2px;\n}\n\n.b_border {\n    border-bottom: solid 2px #f0f0f0;\n}\n\n.reviewText {\n    white-space: pre;\n}\n\n.isReportComponent {\n    color: #1488a8 !important;\n}\n\n.isReportComponent:hover {\n    cursor: pointer;\n}\n\n.isNonReportComponent {\n    color: lightgrey;\n}\n"]}]);
// Exports
exports.locals = {
	"rating": "rating__1BAAp",
	"b_border": "b_border__2tqxp",
	"reviewText": "reviewText__1ODeb",
	"isReportComponent": "isReportComponent__r4qBP",
	"isNonReportComponent": "isNonReportComponent__pXaoj"
};
module.exports = exports;
