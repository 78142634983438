import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import { InputAdornment } from '@material-ui/core';
import BackendMethods from '../../../api/BackendMethods';
import { ILocationSearchResult } from '../../../interfaces/interfaces';
import { getSubdomain } from '../../../utils/subdomain';
import { CompanyService } from './CompanyService';
import { makeStyles } from '@material-ui/styles';
import { ICompaniesRating } from 'interfaces/ICompaniesRating';
const styles = require('./FindService.css');

interface IProps {
    setCompanyId?: React.Dispatch<React.SetStateAction<number>>;
    isChangeRating?: boolean;
    setIsChangeRating?: React.Dispatch<React.SetStateAction<boolean>>;
}
const useStyle = makeStyles(() => ({
    textField: {
        '& label.MuiInputLabel-root': {
            transform: 'translate(41px, 9px) scale(1)',
        },
    }
}));
interface IProps {
    setCompanyId?: React.Dispatch<React.SetStateAction<number>>;
    isChangeRating?: boolean;
    setIsChangeRating?: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function FindService({ setCompanyId, isChangeRating, setIsChangeRating }: IProps) {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [isFirst, setIsFirst] = useState(true);
    const [locations, setLocations] = useState<ILocationSearchResult[]>([]);
    const [companiesRating, setCompaniesRating] = useState<ICompaniesRating[]>([]);
    const [isRating, setIsRating] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            setLocations(await BackendMethods.searchForLocations(search, getSubdomain()));
        })();
    }, [search]);
    useEffect(() => {
        if (locations && locations.length > 0) {
            const companyIds: number[] = [];
            locations.map((item) => {
                let index = companyIds.indexOf(item.companyId);
                if (index < 0) {
                    companyIds.push(item.companyId);
                }
            });
            (async () => {
                try {
                    setCompaniesRating(await BackendMethods.companiesRating(companyIds));
                } catch (error) {
                } finally {
                    setIsRating(true);
                }
            })();
        }
    }, [locations]);
    useEffect(() => {
        if (isChangeRating) {
            if (locations && locations.length > 0) {
                const companyIds: number[] = [];
                locations.map((item) => {
                    let index = companyIds.indexOf(item.companyId);
                    if (index < 0) {
                        companyIds.push(item.companyId);
                    }
                });
                (async () => {
                    try {
                        setCompaniesRating(await BackendMethods.companiesRating(companyIds));
                    } catch (error) {
                    } finally {
                        setIsRating(true);
                        setIsChangeRating(false);
                    }
                })();
            }
        }
    }, [isChangeRating]);

    const classes = useStyle();

    return (
        <div className={styles.root}>
            <TextField
                className={isFirst ? classes.textField : null}
                label={t('small.findServiceSearch')}
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img
                                src="/assets/search-gray.svg"
                                alt=""
                                className={styles.searchIcon}
                            />
                        </InputAdornment>
                    ),
                }}
                onClick={() => setIsFirst(false)}
            />
            <div className={styles.locations}>
                {isRating? locations.map((location, index) => (
                    <CompanyService
                        key={index}
                        location={location}
                        company={companiesRating.find((item) => item.company === location.companyId)}
                        setCompanyId={setCompanyId}
                    />
                )) : (<>
                    {Array(5).fill(0).map((_, index) => (
                        <CompanyService
                            key={index}
                            location={null}
                            company={null}
                        />
                    ))}
                </>)}
            </div>
        </div>
    );
}
