import React from 'react';
import Button from 'components/_ReusableComponents/Button/Button';
import BackendMethods from 'api/BackendMethods';
import { useState } from 'react';
import Styles from './MinorConsentModal.css';
import { useTranslation } from 'react-i18next';
import { Dialog, Checkbox } from '@material-ui/core';
import PLMinorConsent from 'components/Data/MinorConsent/pl.json';
import ENMinorConsent from 'components/Data/MinorConsent/en.json';

interface MinorConsen {
  isDesktop: boolean;
  setShowMinorConsentModal: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: any;
}

export const MinorConsentModal: React.FC<MinorConsen> = ({ isDesktop, setShowMinorConsentModal, onClose }) => {
  const { i18n, t } = useTranslation();
  const data = i18n.language === 'en' ? ENMinorConsent : PLMinorConsent;
  const [consentProcessing, setConsentProcessing] = useState<boolean>(false);
  const [consentTransfer, setConsentTransfer] = useState<boolean>(false);
  const [acceptAllPrivacy, setAcceptAllPrivacy] = useState(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [contentButtonLabel, setContentButtonLabel] = useState<string>(t('consent.learnMore'));

  const handleAllAccept = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptAllPrivacy(event.target.checked);
    if (event.target.checked) {
      setConsentProcessing(true);
      setConsentTransfer(true);
    } else {
      setConsentProcessing(false);
      setConsentTransfer(false);
    }
  };
  const handleConsentProcessing = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentProcessing(event.target.checked);
    if (event.target.checked && consentTransfer) {
      setAcceptAllPrivacy(true);
    } else {
      setAcceptAllPrivacy(false);
    }
  };
  const handleConsentTransfer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentTransfer(event.target.checked);
    if (consentProcessing && event.target.checked) {
      setAcceptAllPrivacy(true);
    } else {
      setAcceptAllPrivacy(false);
    }
  };
  const handleShowContent = () => {
    if (showContent) {
      setContentButtonLabel(t('consent.learnMore'));
      setShowContent(false);
    } else {
      setContentButtonLabel(t('consent.collapse'));
      setShowContent(true);
    }
  };
  const acceptConsent = async () => {
    try {
      await BackendMethods.acceptConsent(
        [
          'GDPR_CARE'
        ]
      );
      setShowMinorConsentModal(false);
    } catch (error) {
      onClose();
    }
  };
  const backToBook = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={onClose}
        PaperProps={{
          className: Styles.dlgPaper
        }}
        id="GDPRConsentModal"
        maxWidth={false}
      >
        <div className={Styles.root}>
          <label className={Styles.checkList}>
            <Checkbox
              checked={acceptAllPrivacy}
              onChange={handleAllAccept}
            />
            <span className={Styles.checkListItem}>
              {t('consent.selectAll')}
            </span>
          </label>
          <label className={Styles.checkList}>
            <Checkbox
              checked={consentProcessing}
              onChange={handleConsentProcessing}
            />
            <span className={Styles.checkListItem}>
              {t('consent.agreeProcessingDataForMinor')}
            </span>
          </label>
          <label className={Styles.checkList}>
            <Checkbox
              checked={consentTransfer}
              onChange={handleConsentTransfer}
            />
            <span className={Styles.checkListItem}>
              {t('consent.agreeTransferDataForMinor')}
            </span>
          </label>
          <div className={Styles.content}>
            <p className={Styles.midText}>*{t('consent.required')}</p>
            <div className={Styles.contentBtnGroup}>
              <Button
                className={Styles.showContentBtn}
                onClick={handleShowContent}
              >
                {contentButtonLabel}
              </Button>
              <div className={isDesktop? Styles.secondBtnGroup: Styles.contentBtnGroup}>
                <Button
                  className={Styles.backBtn}
                  onClick={backToBook}
                >
                  {t('consent.back')}
                </Button>
                <Button
                  className={Styles.saveBtn}
                  disabled={!acceptAllPrivacy}
                  onClick={acceptConsent}
                >
                  {t('small.save')}
                </Button>
              </div>
            </div>
            {showContent && (
              <ul className={Styles.contentList}>
                {data.map((cd, ind) => (
                  (<li key={ind}>
                    {cd}
                  </li>)
                ))}
              </ul>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};
