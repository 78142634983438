// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".content__349Y8 {\n    margin-top: 44px;\n    height: calc(100vh - 44px);\n}\n\n.contentNoHeader__k6pWb {\n    margin-top: 0;\n    height: 100vh;\n}\n", "",{"version":3,"sources":["Customer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,aAAa;AACjB","file":"Customer.css","sourcesContent":[".content {\n    margin-top: 44px;\n    height: calc(100vh - 44px);\n}\n\n.contentNoHeader {\n    margin-top: 0;\n    height: 100vh;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "content__349Y8",
	"contentNoHeader": "contentNoHeader__k6pWb"
};
module.exports = exports;
