import * as React from 'react';
import IActions from 'actions/IActions';
const Styles = require('./AvailableAmount.css');

interface IAvailableAmountProps {
    data: any;
    actions: IActions;
    label: string;
    amount: number;
}

export default class AvailableAmount extends React.Component<IAvailableAmountProps, null> {

    render() {
        return (
            <div className={Styles.AvailableAmount}>
                <span>{this.props.label}</span>
                <span>{this.props.amount}</span>
            </div>
        );
    }
}
