// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".checkBox__1076w {\n    position: relative;\n    border: none;\n    padding: 0;\n}\n\n.checkBox__1076w:hover {\n    border: none;\n    background-color: transparent;\n}\n\n.checkIcon__kFu81 {\n    position: absolute;\n    left: 0;\n    top: 5px;\n    color: #013253;\n}\n\n.label__kQAp2 {\n    margin-left: 24px;\n    color: #013253;\n}\n", "",{"version":3,"sources":["MyCheckBox.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB","file":"MyCheckBox.css","sourcesContent":[".checkBox {\n    position: relative;\n    border: none;\n    padding: 0;\n}\n\n.checkBox:hover {\n    border: none;\n    background-color: transparent;\n}\n\n.checkIcon {\n    position: absolute;\n    left: 0;\n    top: 5px;\n    color: #013253;\n}\n\n.label {\n    margin-left: 24px;\n    color: #013253;\n}\n"]}]);
// Exports
exports.locals = {
	"checkBox": "checkBox__1076w",
	"checkIcon": "checkIcon__kFu81",
	"label": "label__kQAp2"
};
module.exports = exports;
