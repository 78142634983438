import moment from 'moment-timezone';

export default function formatDuration(duration: moment.Duration, t) {
    const hours = duration.hours() > 0
        ? `${duration.hours()} ${t('common.forHour', { count: duration.hours() })}`
        : '';
    const minutes = duration.minutes() > 0
        ? `${duration.minutes()} ${t('common.minute', { count: duration.minutes() })}`
        : '';
    return `${hours} ${minutes}`.trim();
}
