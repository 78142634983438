import * as plusPlanPayment from '../../actions/PlusPlanPaymentActions';
import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS } from 'immutable';
import * as R from 'ramda';

const INITIAL_STATE = fromJS({
    currentPaymentStep: 0,
    totalPrice: 0,
    amountOfLicenses: 0,
    billingType: 'monthly',
    isPaymentButtonDisabled: false,
    autoRenew: false,
    invalidNumberError: false,
    tooManyLicensesError: false
});

export default function plusPlanPaymentReducer(
    state: any = INITIAL_STATE,
    action: any = { type: '', payload: {} }
) {
    switch (action.type) {
        case LOCATION_CHANGE: {
            return state;
        }

        case plusPlanPayment.CHANGE_AMOUNT_OF_LICENSES: {
            return state.merge({
                amountOfLicenses: action.payload.amountOfLicenses,
                invalidNumberError: false,
                tooManyLicensesError: false
            });
        }

        case plusPlanPayment.SET_INVALID_NUMBER_ERROR: {
            return state.set('invalidNumberError', action.payload);
        }

        case plusPlanPayment.SET_TOO_MANY_LICENSES_ERROR: {
            return state.set('tooManyLicensesError', action.payload);
        }

        case plusPlanPayment.SET_BILLING_TYPE: {
            return state.set('billingType', action.payload.billingType);
        }

        case plusPlanPayment.SET_AUTO_RENEW: {
            return state.set('autoRenew', action.payload.autoRenew);
        }

        case plusPlanPayment.GO_TO_PAYMENT: {
            return state.set('currentPaymentStep', 1);
        }

        case plusPlanPayment.GET_PLANS_FULFILLED: {
            const [monthlyPrice, yearlyPrice] = R.map(
                interval => action.payload.find(R.propEq('interval', interval)).price
            )(['MONTH', 'YEAR']);
            return state.merge({ monthlyPrice, yearlyPrice, plansLoaded: true });
        }

        case plusPlanPayment.STORE_CARD_FULFILLED: {
            return state.set('cardDetails', action.payload);
        }

        case plusPlanPayment.PREVIEW_ORDER_FULFILLED:
            return state.merge({
                orderPreview: action.payload,
                currentPaymentStep: 2,
            });

        case plusPlanPayment.CREATE_SUBSCRIPTION_FULFILLED:
            if (action.payload.status === 'PENDING') {
                return state.set('3DSecureToken', action.payload.three_d_secure);
            }
            return state.merge({
                currentPaymentStep: 3,
                paymentStatus: action.payload.status
            });

        case plusPlanPayment.THREE_D_SECURE_COMPLETE:
            return state.merge({
                currentPaymentStep: 3,
                paymentStatus: action.payload.success ? 'COMPLETE' : 'FAILED'
            });

        case plusPlanPayment.RESET_PAYMENT:
            return INITIAL_STATE;

        case plusPlanPayment.GO_BACK:
            return state.update('currentPaymentStep', x => x - 1);

        default: {
            return state;
        }
    }
}
