import React from 'react';
import { LinearProgress, Rating } from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { ILocationSearchResult } from 'interfaces/interfaces';
import { SortBy } from 'elementsSelectors/Reviews';
import { SortMenu } from './SortMenu/SortMenu';
import { polishPlurals } from 'polish-plurals';
import { useTranslation } from 'react-i18next';
import Styles from './Header.css';

interface IReviewHeaderProps {
    subDomain: string;
    location: ILocationSearchResult;
    votes: number[];
    voteNum: number;
    rating: number;
    sortBy: SortBy;
    setSortBy: React.Dispatch<React.SetStateAction<SortBy>>;
}
export const Header = ({ subDomain, location, votes, voteNum, rating, sortBy, setSortBy }: IReviewHeaderProps) => {
    const commentsLabel = polishPlurals.bind(null, 'opinia', 'opinie', 'opinii');
    const { t, i18n } = useTranslation();
    const getRatingNum = (ratingNum) => {
        const enRating = ratingNum === 1 ? 'rating' : 'ratings';
        return i18n.language === 'pl' ? commentsLabel(ratingNum) : enRating;
    };

    return (
        <div className={Styles.subHeader}>
            <div className={Styles.name}>
                <span className={Styles.companyName}>
                    {location.companyName && location.companyName !== '' ?
                        location.companyName :
                        location.companySubdomain
                    }
                </span>
            </div>
            <div className={Styles.headerContent}>
                <div className={Styles.histograms}>
                    {votes.map((item, index) => (
                        <div className={Styles.histogram} key={index}>
                            <h6 className={Styles.rating}>{5 - index}</h6>
                            <LinearProgress
                                className={Styles.vote}
                                variant="determinate"
                                value={voteNum === 0? 0: item * 100 / voteNum}
                            />
                        </div>
                    ))}
                </div>
                <div className={Styles.reviewContent}>
                    <div className={Styles.review}>
                        <h3 className={Styles.reviewRating}>{rating ? rating : 0}</h3>
                        <Rating
                            name="rating"
                            value={rating ? rating : 0}
                            readOnly
                            precision={0.1}
                            emptyIcon={
                                <StarBorderIcon fontSize="inherit" className={'yellow'} />
                            }
                        />
                        <p className={Styles.reviewVotes}>
                            {voteNum ? `${voteNum} ${getRatingNum(voteNum)}` : t('small.noRatings')}
                        </p>
                    </div>
                    <div className={Styles.menu}>
                        <SortMenu
                            setSortBy={setSortBy}
                            sortBy={sortBy}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
