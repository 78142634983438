import React from 'react';
import { Autocomplete as MuiAutocomplete, AutocompleteProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '../TextField/TextField';
import classNames from 'classnames';
const styles = require('./Autocomplete.css');

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

interface Props<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
    > extends WithOptional<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
    label?: string;
}

export default function Autocomplete<
    T,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
    >({ label, classes, className, ...rest }: Props<T, Multiple, DisableClearable, FreeSolo>) {
    const { t } = useTranslation();
    return (
        <MuiAutocomplete
            size="small"
            className={classNames(styles.root, className)}
            popupIcon={(
                <div className={styles.popupIcon} />
            )}
            renderInput={(tfProps) => (
                <TextField {...tfProps} label={label} />
            )}
            noOptionsText={t('common.noOptions')}
            autoHighlight
            {...rest}
        />
    );
}
