import React from 'react';
import { Dialog } from '@material-ui/core';
import Button from '../../Button/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const styles = require('./RegisterModal.css');

export type DialogState = 'closed' | 'open' | 'already-shown';

interface Props {
    dialogState: DialogState;
    setDialogState: (state: DialogState) => void;
}

export default function RegisterModal({ dialogState, setDialogState } : Props) {
    const { t } = useTranslation();
    return (
        <Dialog
            open={dialogState === 'open'}
            onClose={() => setDialogState('already-shown')}
            PaperProps={{ className: styles.paper }}
        >
            <img
                src="/assets/tanbuu_logo.png"
                className={styles.logo}
            />
            <div className={styles.text}>
                {t('home.registerPopup')}
            </div>
            <div className={styles.text}>
                {t('home.noCalendarData')}
            </div>
            <div className={styles.buttons}>
                <Button
                    onClick={() => setDialogState('already-shown')}
                    className={styles.dialogButton}
                >
                    {t('home.noThanks')}
                </Button>
                <Button
                    variant="contained"
                    component={Link}
                    to="/register"
                    className={styles.dialogButton}
                >
                    {t('home.register')}
                </Button>
            </div>
        </Dialog>
    );
}
