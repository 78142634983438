import * as React from 'react';
import * as dateUtils from 'utils/dateUtils/dateUtils';
import MeetingTitleWithImage from '../MeetingTitleWithImage/MeetingTitleWithImage';
import IMeeting from 'interfaces/IMeeting';
import DescriptionTextToggle from '../../_ReusableComponents/Toggle/DescriptionTextToggle/DescriptionTextToggle';
import MeetingDateLines from 'components/MeetingPageComponents/MeetingDateLines/MeetingDateLines';
import ILocation from '../../../interfaces/location/ILocation';
import IPropositionAnswer from '../../../interfaces/IPropositionAnswer';
import Header from '../../_ReusableComponents/Header/Header/Header';
import MeetingParticipants from '../SingleMeeting/MeetingParticipants/MeetingParticipants';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import GlobalContext from '../../../GlobalContext';
const Styles = require('./MeetingSet.css');

interface Props {
    meeting: any;
    actions: any;
    location: ILocation;
    yesNoButtonsStateArray: IPropositionAnswer[];
    history: any;
    currentMeeting: IMeeting;
    timezone: string;
}

export default function MeetingSet(props: Props) {
    const { t, i18n } = useTranslation();
    const { profile } = useContext(GlobalContext);
    const meeting = props.currentMeeting;
    return (
        <div id="MeetingSet" className={Styles.MeetingSet}>
            <Header />
            <section className={Styles.View}>
                <MeetingTitleWithImage title={meeting.title} />
                <MeetingDateLines
                    dateLines={dateUtils.getDateLines(
                        meeting.startDate,
                        meeting.duration * 60,
                        props.timezone,
                        profile.timeFormat,
                        t,
                        i18n.language,
                    )}
                />
                <MeetingParticipants
                    email={profile.email}
                    canEditTimezone={false}
                    actions={props.actions}
                    defaultVisible
                    meeting={meeting}
                />
                <DescriptionTextToggle
                    description={meeting.description}
                />
            </section>
        </div>
    );
}
