import React, { useEffect, useState } from 'react';
import IActions from 'actions/IActions';
import { IHeaderProps } from 'components/_ReusableComponents/Header/Header/Header';
import IProfile from 'interfaces/IProfile';
import { Reviews } from '../Reviews';
import { useHistory, useParams } from 'react-router-dom';
import { CompanyService } from '../../CompanyService';
import { ICompaniesRating } from 'interfaces/ICompaniesRating';
import BackendMethods from 'api/BackendMethods';
import { getSubdomain } from 'utils/subdomain';
import { ILocationSearchResult } from 'interfaces/interfaces';
import Button from 'components/_ReusableComponents/Button/Button';
import { Collapse } from '@material-ui/core';
import Footer from 'containers/SmallBusiness/Footer';
import Styles from './DesktopReview.css';

interface IReviewProps {
    profile: IProfile;
    actions: IActions;
    setHeaderProps: (props: Partial<IHeaderProps>) => void;
    isCustomer?: boolean;
}
export const DesktopReview = ({ profile, actions, setHeaderProps, isCustomer }: IReviewProps) => {
    const history = useHistory();
    const params = useParams<{ company: string }>();
    const [location, setLocation] = useState<ILocationSearchResult | null>(null);
    const [company, setCompany] = useState<ICompaniesRating | null>(null);
    useEffect(() => {
        (async () => {
            const locations: ILocationSearchResult[] = await BackendMethods.searchForLocations('', getSubdomain());
            setLocation(locations.find(item => item.companyId === Number(params.company)));
        })();
        (async () => {
            const companyIds: number[] = [];
            companyIds.push(Number(params.company));
            const companies: ICompaniesRating[] = await BackendMethods.companiesRating(companyIds);
            setCompany(companies.length>0? companies[0]: null);
        })();
    }, []);

    return (
        <div className={Styles.root}>
            <div className={Styles.main}>
                <Collapse orientation="horizontal" in={true}>
                    <div className={Styles.findServiceColumn}>
                        <CompanyService
                            location={location}
                            company={company}
                        />
                    </div>
                </Collapse>
                <Collapse orientation="horizontal" in={true}>
                    <div className={Styles.findServiceColumn}>
                        {location && (
                            <div className={Styles.reviewContent}>
                                <Button
                                    className={Styles.multiplyContent}
                                    onClick={() => history.push('/sb-customer/my-appointments')}
                                >
                                    <img className={Styles.multiplyImage} src="/assets/multiply-icon.png" />
                                </Button>
                                <Reviews
                                    profile={profile}
                                    actions={actions}
                                    parentsLocation={location}
                                />
                            </div>
                        )}
                    </div>
                </Collapse>
            </div>
            <Footer />
        </div>
    );
};
