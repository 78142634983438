// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__2E6j0 {\n    margin-top: 20px;\n    margin-bottom: 8px;\n}\n\n.firstLine__1HESa {\n    font-size: 25px;\n}\n\n.secondLine__3Kq5Z {\n    font-size: 20px;\n}\n", "",{"version":3,"sources":["MeetingDateLines.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","file":"MeetingDateLines.css","sourcesContent":[".root {\n    margin-top: 20px;\n    margin-bottom: 8px;\n}\n\n.firstLine {\n    font-size: 25px;\n}\n\n.secondLine {\n    font-size: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__2E6j0",
	"firstLine": "firstLine__1HESa",
	"secondLine": "secondLine__3Kq5Z"
};
module.exports = exports;
