import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import getStripe from '../../utils/getStripe';
import { useTranslation } from 'react-i18next';
const Styles = require('./PaymentResult.css');

interface IPaymentResultPageProps {
    isSuccess: boolean;
    actions: any;
    plusPlanAdmin: any;
    history: any;
    data: any;
    location: any;
}

export default function PaymentResult(props: IPaymentResultPageProps) {
    const { t, i18n } = useTranslation();
    const { plusPlanAdmin } = props;
    const licenses = plusPlanAdmin.get('newAmountOfLicenses');
    const token = plusPlanAdmin.get('threeDSecureToken');
    useEffect(() => {
        (async () => {
            if (token) {
                const stripe = await getStripe(i18n.language);
                const { error } = await stripe.handleCardPayment(token);
                props.actions.adminThreeDSecureComplete(!error);
            }
        })();
    }, [token]);

    useEffect(() => {
        if (plusPlanAdmin.get('paymentSuccess')) {
            if (licenses !== props.data.get('subscription').licenses) {
                props.history.push('/payment-success');
            } else {
                props.history.push('/payment-success/no-new-licenses');
            }
            props.actions.resetAdminPanel(props.data.get('subscription'));
        }
    }, [plusPlanAdmin.get('paymentSuccess')]);

    const wizard = props.location.state && props.location.state.wizard;

    return (
        <div className={Styles.PaymentResult}>
            {props.isSuccess === true ? (
                <div className={Styles.ResultContainer}>
                    <h1 className={Styles.Result}>
                        {t('plan.transactionComplete')}
                    </h1>
                    <img
                        className={Styles.Icon}
                        src="/assets/checkmark_icon.svg"
                    />
                    <p className={Styles.Info}>
                        {t('plan.paymentSuccessful')}
                    </p>
                    <p className={Styles.Info}>
                        {t('plan.thankYou')}
                    </p>
                    <Route
                        path="/payment-success"
                        exact
                        render={() => (
                            <>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    component={Link}
                                    to={{
                                        pathname: '/licenses',
                                        state: { wizard }
                                    }}
                                    className={Styles.ContinueButton}
                                >
                                    {t('plan.continueAssign')}
                                </Button>
                                <Link
                                    to="/main"
                                    className={
                                        Styles.SecondaryOption__Text
                                    }
                                >
                                    {t('plan.assignLater')}
                                </Link>
                            </>
                        )}
                    />
                    <Route
                        path="/payment-success/no-new-licenses"
                        exact
                        render={() => (
                            <Button
                                variant="outlined"
                                color="primary"
                                component={Link}
                                to="/main"
                                className={Styles.ContinueButton}
                            >
                                {t('plan.goToDashboard')}
                            </Button>
                        )}
                    />
                </div>
            ) : (
                <div className={Styles.ResultContainer}>
                    <h1 className={classNames(Styles.Result, Styles.paymentFailed)}>
                        {t('plan.paymentFailed')}
                    </h1>
                    <img
                        className={Styles.Icon}
                        src="/assets/exclamation_icon.svg"
                    />
                    <p className={Styles.Info}>
                        {t('plan.cardDeclined')}
                    </p>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={Styles.ContinueButton}
                        onClick={() => props.actions.retryPayment()}
                    >
                        {t('plan.retryPayment')}
                    </Button>
                    <Link
                        to="/manage"
                        className={
                            Styles.SecondaryOption__Text
                        }
                    >
                        {t('plan.editCard')}
                    </Link>
                </div>
            )}
        </div>
    );
}
