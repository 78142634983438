// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".disabledHeader__n-H3D {\n    color: rgba(0, 0, 0, 0.38);\n}\n\n.opinionStar__3ePhp {\n    color: #1488a8;\n}\n\n.opinionInput__x-6-z {\n    margin: 20px 0 14px 0;\n}\n", "",{"version":3,"sources":["ReviewContent.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB","file":"ReviewContent.css","sourcesContent":[".disabledHeader {\n    color: rgba(0, 0, 0, 0.38);\n}\n\n.opinionStar {\n    color: #1488a8;\n}\n\n.opinionInput {\n    margin: 20px 0 14px 0;\n}\n"]}]);
// Exports
exports.locals = {
	"disabledHeader": "disabledHeader__n-H3D",
	"opinionStar": "opinionStar__3ePhp",
	"opinionInput": "opinionInput__x-6-z"
};
module.exports = exports;
