// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".calendar__2NtZ3 {\n    display: flex;\n    align-items: center;\n    font-weight: inherit !important;\n    margin-right: 4px;\n}\n\n.account__2J5Tn {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.providerIcon__1TTVL {\n    display: block;\n    fill: #e24b27;\n    height: 18px;\n    width: 18px;\n    margin-right: 12px;\n}\n", "",{"version":3,"sources":["CalendarPicker.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,+BAA+B;IAC/B,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB","file":"CalendarPicker.css","sourcesContent":[".calendar {\n    display: flex;\n    align-items: center;\n    font-weight: inherit !important;\n    margin-right: 4px;\n}\n\n.account {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.providerIcon {\n    display: block;\n    fill: #e24b27;\n    height: 18px;\n    width: 18px;\n    margin-right: 12px;\n}\n"]}]);
// Exports
exports.locals = {
	"calendar": "calendar__2NtZ3",
	"account": "account__2J5Tn",
	"providerIcon": "providerIcon__1TTVL"
};
module.exports = exports;
