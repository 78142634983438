import React, { useContext, useEffect } from 'react';
import IActions from '../../../actions/IActions';
import BillingListHeading from './BillingListHeading/BillingListHeading';
import FallingLeaves from '../../_ReusableComponents/FallingLeaves/FallingLeaves';
import moment from 'moment-timezone';
import Button from '../../_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../GlobalContext';
const Styles = require('./Billing.css');

interface IBillingProps {
    data: any;
    actions: IActions;
    plusPlanAdmin: any;
}

export default function Billing(props: IBillingProps) {
    const { t, i18n } = useTranslation();
    const { profile: { timezone } } = useContext(GlobalContext);
    useEffect(() => {
        const fetchData = async () => {
            try {
                await props.actions.getInvoices();
            } catch (error) {
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        if (props.plusPlanAdmin.get('invoice')) {
            const url = window.URL.createObjectURL(props.plusPlanAdmin.get('invoice'));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'invoice.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }, [props.plusPlanAdmin.get('invoice')]);
    if (!props.plusPlanAdmin.get('invoices')) {
        return <FallingLeaves />;
    }
    return (
        <main className={Styles.Billing} data-testid="billing">
            <div>
                <ul className={Styles.List}>
                    <BillingListHeading />
                    {props.plusPlanAdmin.get('invoices').map(invoice => (
                        <li key={invoice.get('id')} className={Styles.List__ListElement}>
                            <span>
                                {moment.tz(invoice.get('created'), timezone)
                                    .format(i18n.language === 'pl' ? 'D MMMM Y' : 'MMMM D, Y')}
                            </span>
                            <span>
                                ${(invoice.get('amount') / 100).toFixed(2)}
                            </span>
                            <span>
                                <Button
                                    variant="text"
                                    onClick={() => props.actions.getInvoice(invoice.get('id'))}
                                    className={Styles.DownloadButton}
                                >
                                    {t('plan.download')}
                                </Button>
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </main>
    );
}
