// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__2W4sR {\n    background-color: #dee1e1;\n    border-radius: 12px;\n    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);\n}\n\n.text__2h2lL {\n    color: #013253;\n    font-size: 16px;\n    padding: 2px 10px;\n}\n\n.undo__121w2 {\n    margin-right: 10px;\n}\n\n.undo__121w2 > span {\n        font-size: 16px;\n        font-weight: 400 !important;\n        color: #08a;\n    }\n", "",{"version":3,"sources":["UndoSnackbar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,2CAA2C;AAC/C;;AAEA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AAMtB;;AALI;QACI,eAAe;QACf,2BAA2B;QAC3B,WAAW;IACf","file":"UndoSnackbar.css","sourcesContent":[".root {\n    background-color: #dee1e1;\n    border-radius: 12px;\n    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);\n}\n\n.text {\n    color: #013253;\n    font-size: 16px;\n    padding: 2px 10px;\n}\n\n.undo {\n    margin-right: 10px;\n    & > span {\n        font-size: 16px;\n        font-weight: 400 !important;\n        color: #08a;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__2W4sR",
	"text": "text__2h2lL",
	"undo": "undo__121w2"
};
module.exports = exports;
