import * as React from 'react';
import classNames from 'classnames';
const Styles = require('./GoogleSignInButton.css');
import Auth from 'auth/Auth';

interface IGoogleSignInButtonProps {
    text: string;
}
export default class GoogleSignInButton extends React.Component<
    IGoogleSignInButtonProps,
    null
> {
    render(): JSX.Element {
        const classDef = classNames(Styles.GoogleSignInButton);
        const signIn = () => {
            Auth.login();
        };

        return (
            <button
                id="GoogleSignInButton"
                className={classDef}
                onClick={signIn}
            >
                <img src="/assets/google_icon.png" />
                <span>{this.props.text}</span>
            </button>
        );
    }
}
