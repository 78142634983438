import * as R from 'ramda';
import moment from 'moment-timezone';
import React, { ReactNode } from 'react';
import IMeeting from '../interfaces/IMeeting';
import IProposition from '../interfaces/IProposition';
import { IParticipant } from '../interfaces/interfaces';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import Button from '../components/_ReusableComponents/Button/Button';
import BackendMethods from '../api/BackendMethods';
const styles = require('./getMeetingMessage.css');

interface IViewProposition extends IProposition {
    removed?: boolean;
}
export default function getMeetingMessage(meeting: IMeeting, email: string, t: TFunction, propositions?: IViewProposition[], onInviteSent?: () => void): ReactNode {
    const { declined, timezone, optional } = meeting.participants.find(R.whereEq({ email }));
    const nowPropositions = propositions? propositions: meeting.propositions;
    const participantsNotResponded = R.filter(
        participant => participant.email !== email && !participant.declined && !participant.optional && R.all(
            x => !x.acceptedBy.includes(participant.email),
            nowPropositions
        ),
        meeting.participants
    );
    const allDeclined = R.all(participant => participant.email === email || participant.declined, meeting.participants);
    const allResponded = participantsNotResponded.length === 0;
    const inProgress = meeting.state === 'WAITING_FOR_ANSWERS' && (meeting.hasResponded || optional) && !allResponded && !declined &&
        !allDeclined;
    const inProgressRespond = meeting.state === 'WAITING_FOR_ANSWERS' && (!meeting.hasResponded || allResponded) &&
        meeting.hasPropositions && !declined && !allDeclined && !optional;
    const canceled = meeting.state === 'CANCELED';
    const calculating = meeting.state === 'CALCULATING';
    const recalculating = meeting.state === 'RECALCULATING';
    const noSlots = meeting.state === 'WAITING_FOR_ANSWERS' && !meeting.hasPropositions;
    const organizerName = meeting.organizer.name || meeting.organizer.email;
    const notRespondedNames = R.compose(
        R.join(', '),
        R.map((participant: IParticipant) => participant.name || participant.email)
    )(participantsNotResponded);
    const inRange = (x: number, lower: number, upper: number) => (x >= lower && x <= upper);
    const allNightTime = R.all(
        (participant: IParticipant) => !inRange(moment().tz(participant.timezone).hour(), 6, 20),
        participantsNotResponded
    );
    if (declined) {
        return t('home.clickJoinAgain');
    }
    if (allDeclined) {
        return t('home.allDeclined');
    }
    if (calculating || recalculating) {
        return t('home.calculating');
    }
    if (recalculating) {
        return t('home.recalculating');
    }
    if (canceled) {
        return t('home.meetingCanceled', { name: organizerName });
    }
    if (noSlots) {
        if (meeting.isOrganizer) {
            if (moment.tz(meeting.before, timezone).isAfter(moment())) {
                return t('home.allSlotsRejected');
            } else {
                return t('home.allSlotsExpired');
            }
        } else {
            return t('home.noSlotsParticipant', { name: organizerName });
        }
    }
    if (meeting.isOrganizer && !meeting.invitesSent) {
        return (
            <Trans
                i18nKey="home.acceptOrClick"
                components={{
                    button: (
                        <Button
                            className={styles.hereButton}
                            variant="text"
                            onClick={async () => {
                                await BackendMethods.sendInvites(meeting.id);
                                if (onInviteSent) {
                                    onInviteSent();
                                }
                            }}
                        />
                    )
                }}
            />
        );
    }
    if (inProgressRespond) {
        if (meeting.hasResponded && allResponded) {
            return t('home.pleaseRespond');
        } else {
            return t('home.pleasePick');
        }
    }
    if (meeting.newPropositions) {
        return t('home.moreSlots');
    }
    if (inProgress) {
        const stillWaitingOn = meeting.participants.find((x) => x.reviewRequired);
        if (allNightTime) {
            return t('home.nightTime', { names: notRespondedNames });
        } else if (stillWaitingOn) {
            const name = stillWaitingOn.name || stillWaitingOn.email;
            return `${t('home.stillWaiting', { name })}
                ${email === meeting.organizer.email ? t('home.stillWaitingOrganizer') : ''}`;
        } else {
            return t('home.haveNotHeard', { names: notRespondedNames });
        }
    }
    return null;
}
