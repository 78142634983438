import React, { useContext, useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import IActions from 'actions/IActions';
import SupportForm from '../../SupportForm/SupportForm';
import GlobalContext from '../../../../GlobalContext';
import BackendMethods from 'api/BackendMethods';
import { PrivacyModal } from '../PrivacyModal/PrivacyModal';
import { OwnerGDPRConsentModal } from '../OwnerGDPRConsentModal/OwnerGDPRConsentModal';
import { useIsDesktop } from 'utils/responsive';
const styles = require('./SupportModal.css');

interface Props {
    app: any;
    actions: IActions;
    open: boolean;
}

export default function SupportModal(props: Props) {
    const isDesktop = useIsDesktop();
    const { profile } = useContext(GlobalContext);
    const [isShowOwnerModal, setIsShowOwnerModal] = useState<boolean>(false);
    const [isShowPrivacyModal, setIsShowPrivacyModal] = useState<boolean>(false);
    const exceptionUrl = [
        'tanbuu.com/about',
        'tanbuu.com/policy',
        'tanbuu.com/terms',
        'tanbuu.com/professional-terms',
        'tanbuu.com/data-processing-contract',
        'tanbuu.com/login'
    ];
    useEffect(() => {
        const nowUrl = window.location.href;
        if (profile.appointments.companyId) {
            const isException = exceptionUrl.find((item) => nowUrl.includes(item));
            if (!isException) {
                checkConsent();
            }
        }
    }, []);
    const checkConsent = () => {
        if (profile.appointments.accountPerm && profile.appointments.bookingPerm) {
            checkOwner();
        } else {
            checkEmployee();
        }
    };
    const checkOwner = async () => {
        try {
            const contents = await BackendMethods.getConsents('PRIVACY_POLICY,TAC_EMPLOYEE,GDPR_OWNER');
            if (contents.length > 0) {
                setIsShowOwnerModal(true);
            } else {
            }
        } catch (error) {
        }
    };
    const checkEmployee = async () => {
        try {
            const consents = await BackendMethods.getConsents('PRIVACY_POLICY,TAC_EMPLOYEE');
            if (consents.length > 0) {
                setIsShowPrivacyModal(true);
            } else {
            }
        } catch (error) {
        }
    };

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.actions.toggleSupportModal}
                PaperProps={{ className: styles.paper }}
            >
                <SupportForm {...props} email={profile.email} />
            </Dialog>
            {isShowOwnerModal && (
                <OwnerGDPRConsentModal
                    isDesktop={isDesktop}
                    setShowGDPRConsentModal={setIsShowOwnerModal}
                />
            )}
            {isShowPrivacyModal && (
                <PrivacyModal
                    setIsShowPrivacyModal={setIsShowPrivacyModal}
                />
            )}
        </>
    );
}
