import * as React from 'react';
import { Redirect } from 'react-router-dom';
import cs from 'classnames';
const Styles = require('./NewMeetingButton.css');

interface State {
    redirectTriggered: boolean;
}
export default class NewMeetingButton extends React.PureComponent<{}, State> {
    constructor(props) {
        super(props);
        this.state = {
            redirectTriggered: false
        };
    }

    redirect = () => this.setState({ redirectTriggered: true });

    render() {
        if (this.state.redirectTriggered) {
            return <Redirect to="/new" />;
        }

        return (
            <button
                id="newMeeting"
                className={Styles.NewMeetingButton}
                onClick={this.redirect}
            >
                <img
                    src="/assets/plus_icon_for_action_button.svg"
                    className={cs(Styles.PlusIcon)}
                />
            </button>
        );
    }
}
