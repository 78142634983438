// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__1Ae83 {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 12px;\n}\n    .root__1Ae83 > button {\n        min-width: 120px;\n    }\n\n.cancel__-Ymao {\n    font-weight: 400 !important;\n}\n", "",{"version":3,"sources":["AdminNavigation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AAIpB;IAHI;QACI,gBAAgB;IACpB;;AAGJ;IACI,2BAA2B;AAC/B","file":"AdminNavigation.css","sourcesContent":[".root {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 12px;\n    & > button {\n        min-width: 120px;\n    }\n}\n\n.cancel {\n    font-weight: 400 !important;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__1Ae83",
	"cancel": "cancel__-Ymao"
};
module.exports = exports;
