// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Billing__Rx5MW {\n    margin: 0 auto 32px;\n    width: 100%;\n    max-width: 425px;\n    padding-left: 16px;\n}\n\n.List__3p16r {\n    color: #013253;\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n}\n\n.List__ListElement__kBoi9 {\n    display: flex;\n    align-items: center;\n}\n\n.List__ListElement__kBoi9 span {\n    text-align: center;\n}\n\n.List__ListElement__kBoi9 > span:nth-child(1) {\n    width: 40%;\n    text-align: left;\n}\n\n.List__ListElement__kBoi9 > span:nth-child(2) {\n    width: 30%;\n    text-align: right;\n}\n\n.List__ListElement__kBoi9 > span:nth-child(3) {\n    width: 30%;\n}\n\n.DownloadButton__1Brjf {\n    padding: 4px 8px;\n    font-weight: 400 !important;\n}\n", "",{"version":3,"sources":["Billing.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AACA;IACI,UAAU;IACV,iBAAiB;AACrB;;AACA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,2BAA2B;AAC/B","file":"Billing.css","sourcesContent":[".Billing {\n    margin: 0 auto 32px;\n    width: 100%;\n    max-width: 425px;\n    padding-left: 16px;\n}\n\n.List {\n    color: #013253;\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n}\n\n.List__ListElement {\n    display: flex;\n    align-items: center;\n}\n\n.List__ListElement span {\n    text-align: center;\n}\n\n.List__ListElement > span:nth-child(1) {\n    width: 40%;\n    text-align: left;\n}\n.List__ListElement > span:nth-child(2) {\n    width: 30%;\n    text-align: right;\n}\n.List__ListElement > span:nth-child(3) {\n    width: 30%;\n}\n\n.DownloadButton {\n    padding: 4px 8px;\n    font-weight: 400 !important;\n}\n"]}]);
// Exports
exports.locals = {
	"Billing": "Billing__Rx5MW",
	"List": "List__3p16r",
	"List__ListElement": "List__ListElement__kBoi9",
	"DownloadButton": "DownloadButton__1Brjf"
};
module.exports = exports;
