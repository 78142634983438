import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Dialog, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup } from '@material-ui/core';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import Button from '../../../components/_ReusableComponents/Button/Button';
import Select from '../../../components/_ReusableComponents/Select/Select';
import * as R from 'ramda';
import { IResource } from './Resources';
import classNames from 'classnames';
import BackendMethods from '../../../api/BackendMethods';
import { DraggableArea } from 'react-draggable-tags';
import { ChromePicker } from 'react-color';
const styles = require('./Services.css');

export type IPrice = {
    min: number;
    max: number;
    currency: string;
};

export interface INewService {
    name: string;
    color: string;
    price: IPrice;
    duration: number;
    noSelf: boolean;
    custHidden: boolean;
    resources: number[];
}

export interface IService extends INewService {
    id: number;
    key: string;
}

const colors = [
    '#DBDEDE',
    '#C41515',
    '#E06307',
    '#EBB114',
    '#1FA219',
    '#0F8044',
    '#199CE5',
    '#4051B6',
    '#050074',
    '#99319D',
    '#EB6DD3',
];

const defaultColor = colors[0];

const getRandomKey = () => String(Math.round(Math.random() * 1e8));

export default function Services() {
    const { t, i18n } = useTranslation();
    const [services, setServices] = useState<IService[] | null>(null);
    const [allResources, setAllResources] = useState<IResource[] | null>(null);
    useEffect(() => {
        (async () => {
            const [newServices, newResources] = await Promise.all([
                BackendMethods.getServices(),
                BackendMethods.getResources(),
            ]);
            setServices(newServices.map((x) => ({
                ...x,
                key: getRandomKey(),
            })));
            setAllResources(newResources);
        })();
    }, []);
    const [selectedService, setSelectedService] = useState<number | 'new' | null>(null);
    const [name, setName] = useState('');
    const [color, setColor] = useState(defaultColor);
    const [customColor, setCustomColor] = useState(defaultColor);
    const [colorOpen, setColorOpen] = useState(false);
    const [isRange, setIsRange] = useState(false);
    const [currency, setCurrency] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [duration, setDuration] = useState('');
    const [noSelfBooking, setNoSelfBooking] = useState(false);
    const [custHidden, setCustHidden] = useState(false);
    const [resources, setResources] = useState([]);
    const [newResource, setNewResource] = useState<number | null>(null);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const loadData = (service?: IService) => {
        if (service) {
            setSelectedService(service.id);
            setName(service.name);
            setColor(service.color);
            setIsRange(service.price.min !== service.price.max);
            setMinPrice(service.price.min ? String(service.price.min) : '');
            setMaxPrice(service.price.max ? String(service.price.max) : '');
            setCurrency(service.price.currency);
            setDuration(String(service.duration));
            setNoSelfBooking(service.noSelf);
            setCustHidden(service.custHidden);
            setResources(service.resources);
        } else {
            setSelectedService('new');
            setName('');
            setColor(defaultColor);
            setIsRange(false);
            setCurrency(i18n.language === 'pl' ? 'PLN' : 'USD');
            setMinPrice('');
            setMaxPrice('');
            setDuration('');
            setNoSelfBooking(false);
            setCustHidden(false);
            setResources([]);
        }
        setNewResource(null);
    };
    if (!services || !allResources) {
        return null;
    }
    return (
        <div className={styles.root}>
            <div className={styles.left}>
                <div className={styles.addHeader}>
                    {t('small.services')}
                    <Button
                        variant="contained"
                        className={styles.addButton}
                        onClick={() => loadData()}
                    >
                        <img src="/assets/plus-white.svg" alt="" className={styles.addIcon} />
                        {t('small.addService')}
                    </Button>
                </div>
                <div className={styles.elements}>
                    <DraggableArea
                        tags={services.map((x) => ({
                            id: x.key,
                            data: x,
                        }))}
                        render={({ tag: { data: service } }) => (
                            <Button
                                variant="contained"
                                className={classNames(styles.element, styles.elementButton, {
                                    [styles.elementActive]: service.id === selectedService,
                                })}
                                onClick={() => loadData(service)}
                            >
                                <img
                                    src={`/assets/drag-handle${service.id === selectedService ? '-white' : ''}.svg`}
                                    alt=""
                                    className={styles.dragHandle}
                                    onMouseDown={(event) => event.preventDefault()}
                                    onClick={(event) => event.stopPropagation()}
                                />
                                <span
                                    className={styles.listButtonContent}
                                    onMouseDownCapture={(event) => event.stopPropagation()}
                                >
                                    {service.name}
                                    <span
                                        className={styles.listColorDot}
                                        style={{ backgroundColor: service.color }}
                                    />
                                </span>
                            </Button>
                        )}
                        onChange={(newServices) => {
                            setServices(newServices.map(R.prop('data')));
                            BackendMethods.saveServiceOrder(newServices.map((x) => x.data.id));
                        }}
                    />
                </div>
            </div>
            <div className={styles.right}>
                {selectedService !== null && (
                    <>
                        <div className={styles.nameRow}>
                            <TextField
                                label={t('small.name')}
                                value={name}
                                onChange={({ target }) => setName(target.value)}
                                className={styles.name}
                            />
                            <TextField
                                value={color}
                                onChange={({ target }) => {
                                    if (target.value === 'custom') {
                                        setColorOpen(true);
                                    } else {
                                        setColor(target.value);
                                    }
                                }}
                                select
                                className={styles.colorSelect}
                                InputProps={{
                                    className: styles.colorSelectInput,
                                    classes: {
                                        notchedOutline: styles.colorSelectOutline,
                                    },
                                }}
                                SelectProps={{
                                    IconComponent: () => (
                                        <div className={styles.colorSelectIcon} />
                                    ),
                                }}
                            >
                                {colors.map((x) => (
                                    <MenuItem key={x} value={x}>
                                        <span className={styles.colorDot} style={{ backgroundColor: x }} />
                                    </MenuItem>
                                ))}
                                {!colors.includes(color) && (
                                    <MenuItem value={color}>
                                        <span className={styles.colorDot} style={{ backgroundColor: color }} />
                                    </MenuItem>
                                )}
                                <MenuItem value="custom">
                                    {t('small.customColor')}
                                </MenuItem>
                            </TextField>
                        </div>
                        <RadioGroup
                            value={isRange ? 'range' : 'price'}
                            onChange={({ target }) => setIsRange(target.value === 'range')}
                            row
                            className={styles.radioGroup}
                        >
                            <FormControlLabel
                                control={<Radio color="secondary" />}
                                label={t('small.price')}
                                value="price"
                                className={styles.priceRadio}
                            />
                            <FormControlLabel
                                control={<Radio color="secondary" />}
                                label={t('small.priceRange')}
                                value="range"
                            />
                        </RadioGroup>
                        <div className={styles.fieldRow}>
                            {isRange ? (
                                <>
                                    <TextField
                                        label={t('small.minPrice')}
                                        value={minPrice}
                                        onChange={({ target }) => setMinPrice(target.value)}
                                        className={styles.price}
                                        type="number"
                                    />
                                    <div className={styles.hyphen}>
                                        -
                                    </div>
                                    <TextField
                                        label={t('small.maxPrice')}
                                        value={maxPrice}
                                        onChange={({ target }) => setMaxPrice(target.value)}
                                        className={styles.price}
                                        type="number"
                                    />
                                </>
                            ) : (
                                <TextField
                                    label={t('small.price')}
                                    value={minPrice}
                                    onChange={({ target }) => {
                                        setMinPrice(target.value);
                                        setMaxPrice(target.value);
                                    }}
                                    className={styles.price}
                                    type="number"
                                />
                            )}
                            <TextField
                                label={t('small.currency')}
                                value={currency}
                                onChange={({ target }) => setCurrency(target.value)}
                                select
                                className={styles.currency}
                            >
                                <MenuItem value="EUR">
                                    EUR
                                </MenuItem>
                                <MenuItem value="PLN">
                                    PLN
                                </MenuItem>
                                <MenuItem value="USD">
                                    USD
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className={styles.fieldRow}>
                            <TextField
                                label={t('small.duration')}
                                value={duration}
                                onChange={({ target }) => setDuration(target.value)}
                                className={styles.duration}
                                type="number"
                            />
                            <div>
                                {t('common.minute', { count: 15 })}
                            </div>
                        </div>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={t('small.noSelfBooking')}
                            checked={noSelfBooking}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => (
                                setNoSelfBooking(target.checked)
                            )}
                            disabled={custHidden}
                        />
                        <FormControlLabel
                            control={<Checkbox />}
                            label={t('small.serviceHidden')}
                            checked={custHidden}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                                if (target.checked) {
                                    setNoSelfBooking(true);
                                }
                                setCustHidden(target.checked);
                            }}
                        />
                        <div className={styles.resourcesHeader}>
                            {t('small.requiredResources')}
                        </div>
                        <div className={styles.selectRow}>
                            <Select
                                label={t('small.resourceName')}
                                value={newResource ? {
                                    value: newResource,
                                    label: allResources.find(R.propEq('id', newResource)).name,
                                } : null}
                                onChange={option => setNewResource(option ? option.value : null)}
                                options={allResources.filter((resource) => (
                                    !resources.includes(resource.id)
                                )).map((resource) => ({
                                    value: resource.id,
                                    label: resource.name,
                                }))}
                                className={styles.select}
                                isClearable
                            />
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setResources([...resources, newResource]);
                                    setNewResource(null);
                                }}
                                disabled={newResource === null}
                            >
                                {t('small.add')}
                            </Button>
                        </div>
                        <div className={styles.elements}>
                            {resources.map((resourceId) => {
                                const resource = allResources.find(R.propEq('id', resourceId));
                                return (
                                    <div className={styles.element} key={resource.id}>
                                        {resource.name}
                                        <IconButton
                                            size="small"
                                            className={styles.deleteButton}
                                            onClick={() => setResources(resources.filter((x) => (
                                                x !== resource.id
                                            )))}
                                        >
                                            <img
                                                src="/assets/delete-blue.svg"
                                                alt=""
                                                className={styles.deleteIcon}
                                            />
                                        </IconButton>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.saveRow}>
                            {selectedService !== 'new' && (
                                <Button
                                    variant="text"
                                    onClick={() => setDeleteOpen(true)}
                                >
                                    {t('small.deleteService')}
                                </Button>
                            )}
                            <Button
                                variant="text"
                                onClick={() => setSelectedService(null)}
                                className={styles.discardChanges}
                            >
                                {t('small.discardChanges')}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    const newService: INewService = {
                                        name,
                                        color,
                                        price: {
                                            min: minPrice ? Number(minPrice) : null,
                                            max: maxPrice ? Number(maxPrice) : null,
                                            currency,
                                        },
                                        duration: Number(duration),
                                        noSelf: noSelfBooking,
                                        custHidden,
                                        resources,
                                    };
                                    if (selectedService === 'new') {
                                        const { id } = await BackendMethods.addService(newService);
                                        setServices([
                                            ...services,
                                            { ...newService, id, key: getRandomKey() },
                                        ]);
                                    } else {
                                        await BackendMethods.updateService(selectedService, newService);
                                        setServices(services.map((x) => (
                                            x.id === selectedService ? {
                                                ...newService,
                                                id: selectedService,
                                                key: getRandomKey(),
                                            } : x
                                        )));
                                    }
                                    setSelectedService(null);
                                }}
                                disabled={!name || !duration}
                            >
                                {t('small.save')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                classes={{ paper: styles.dialog }}
            >
                <div className={styles.dialogText}>
                    {t('small.confirmServiceDelete')}
                </div>
                <div className={styles.dialogActions}>
                    <Button
                        className={styles.dialogButton}
                        onClick={async () => {
                            if (selectedService !== 'new') {
                                await BackendMethods.deleteService(selectedService);
                                setServices(services.filter((x) => (
                                    x.id !== selectedService
                                )));
                                setSelectedService(null);
                                setDeleteOpen(false);
                            }
                        }}
                    >
                        {t('small.delete')}
                    </Button>
                    <Button
                        variant="contained"
                        className={styles.dialogButton}
                        onClick={() => setDeleteOpen(false)}
                    >
                        {t('common.cancel')}
                    </Button>
                </div>
            </Dialog>
            <Dialog
                open={colorOpen}
                onClose={() => setColorOpen(false)}
                classes={{ paper: styles.dialog }}
            >
                <ChromePicker
                    disableAlpha
                    color={customColor}
                    onChange={({ hex }) => setCustomColor(hex)}
                />
                <div className={styles.colorDialogActions}>
                    <Button
                        className={styles.dialogButton}
                        onClick={() => {
                            setColor(customColor);
                            setColorOpen(false);
                        }}
                    >
                        {t('small.chooseColor')}
                    </Button>
                    <Button
                        variant="contained"
                        className={styles.dialogButton}
                        onClick={() => setColorOpen(false)}
                    >
                        {t('common.cancel')}
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}
