import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import MobileDateTimePicker from '@material-ui/lab/MobileDateTimePicker';
import Header from '../../components/_ReusableComponents/Header/Header/Header';
import MeetingRows from '../../components/MainPageComponents/MeetingRows/MeetingRows';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import IActions from 'actions/IActions';
import BackendMethods from 'api/BackendMethods';
import NewMeetingButton from 'components/MainPageComponents/NewMeetingButton/NewMeetingButton';
import { WANTS_TO_BUY_TANBUU } from '../../constants/appConsts';
import * as Redirect from '../../utils/Redirect';
import WeekCalendar from '../../components/MainPageComponents/WeekCalendar/WeekCalendar';
import ConfirmScheduledDateBox from '../../components/MeetingPageComponents/ConfirmScheduledDateBox/ConfirmScheduledDateBox';
import { useIsDesktop } from '../../utils/responsive';
import HeaderSearch from '../../components/_ReusableComponents/Header/HeaderSearch/HeaderSearch';
import { getAuthToken, isAdHoc, parseJwt } from '../../scripts/helpers';
import IMeeting, { STATES } from '../../interfaces/IMeeting';
import getWorkWeekEvents from '../../utils/getWorkWeekEvents';
import RejectMeetingModal from '../../components/_ReusableComponents/Modals/RejectMeetingModal/RejectMeetingModal';
import Button from '../../components/_ReusableComponents/Button/Button';
import IProposition from '../../interfaces/IProposition';
import IPropositionAnswer from '../../interfaces/IPropositionAnswer';
import TextField from '../../components/_ReusableComponents/TextField/TextField';
import SetThisDateModal from '../../components/_ReusableComponents/Modals/SetThisDateModal/SetThisDateModal';
import RegisterModal, { DialogState } from '../../components/_ReusableComponents/Modals/RegisterModal/RegisterModal';
import {Collapse, Tooltip} from '@material-ui/core';
import UndoSnackbar from '../../components/_ReusableComponents/UndoSnackbar/UndoSnackbar';
import AdhocTimezoneModal from '../../components/_ReusableComponents/Modals/AdhocTimezoneModal/AdhocTimezoneModal';
import { useTranslation } from 'react-i18next';
import { ICalendarEvent, IConnection } from '../../interfaces/interfaces';
import useAsyncEffect from '../../utils/useAsyncEffect';
import AskForCalendarModal from '../../components/_ReusableComponents/Modals/AskForCalendarModal/AskForCalendarModal';
import GlobalContext from '../../GlobalContext';
import TimeZoneSelect from '../../components/_ReusableComponents/TimeZoneSelect/TimeZoneSelect';
import { SortMenu } from './SortMenu/SortMenu';
import { FilterMenu } from './FilterMenu/FilterMenu';
import { SortBy } from 'elementsSelectors/_reusable/Meetings';
import RenderIfVisible from 'react-render-if-visible';
import ConfirmScheduledDateBoxSkeleton from 'components/MeetingPageComponents/ConfirmScheduledDateBox/ConfirmScheduledDateBoxSkeleton';
const styles = require('./MainPage.css');

export type ITab = 'IN_PROGRESS' | 'SCHEDULED' | 'CANCELED';

const tabStates: Record<ITab, STATES[]> = {
    IN_PROGRESS: [
        'CALCULATING',
        'RECALCULATING',
        'WAITING_FOR_ANSWERS',
    ],
    SCHEDULED: ['SCHEDULED'],
    CANCELED: ['CANCELED'],
};

interface IMainPageProps {
    data: any;
    actions: IActions;
}

interface IViewProposition extends IProposition {
    removed?: boolean;
}

function MainPage(props: IMainPageProps) {
    const isDesktop = useIsDesktop();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch<{ id: string }>();
    const adHoc = isAdHoc();
    const [dialogState, setDialogState] = useState<DialogState>('closed');
    const [undoOpen, setUndoOpen] = useState(false);
    const undoTimeoutId = useRef<NodeJS.Timeout>();

    const [sortBy, setSortBy] = useState<SortBy>(SortBy.ByRating);
    const [acceptedOnTop, setAcceptedOnTop] = useState<boolean>(true);
    const [isWorkHoursOnly, setIsWorkHoursOnly] = useState<boolean>(false);
    const [isNonConflictingOnly, setIsNonConflictingOnly] = useState<boolean>(false);
    if (!isDesktop && match.params.id) {
        history.push('/main');
    }
    useEffect(() => {
        if (localStorage.getItem(WANTS_TO_BUY_TANBUU) === 'PlusPlan') {
            Redirect.locationChange(`/upgradePlan`);
        }
    }, []);

    const [tab, setTab] = useState<ITab>('IN_PROGRESS');
    const statesToShow = tabStates[tab];
    const [search, setSearch] = useState('');
    const [isChangeProposition, setIsChangeProposition] = useState<boolean>(false);
    const searchLower = search.toLowerCase();
    const { profile } = useContext(GlobalContext);
    const email = adHoc ? parseJwt(getAuthToken()).email : profile.email;
    const preProcessedMeetings: IMeeting[] = props.data.get('meetings');
    const meetings: IMeeting[] | null = useMemo(() => preProcessedMeetings === null
        ? null
        : R.sortWith(
            [
                R.ascend((x) => x.startDate),
                R.descend((item) => {
                    const { declined: itemDeclined } = item.participants.find(R.whereEq({ email }));
                    const participantsNotResponded = R.filter(
                        participant => participant.email !== email && (participant.declined? !participant.declined: true) && R.all(
                            x => !x.acceptedBy.includes(participant.email),
                            item.propositions
                        ),
                        item.participants
                    );
                    const allResponded = participantsNotResponded.length === 0;
                    const itemAllDeclined = R.all(
                        participant => participant.email === email || participant.declined,
                        item.participants
                    ) && !declined;
                    return (item.state === 'WAITING_FOR_ANSWERS' && (!item.hasResponded || allResponded) &&
                        item.hasPropositions && !itemDeclined && !itemAllDeclined) ? 1 : 0;
                }),
                R.descend((x) => (tab === 'IN_PROGRESS' && x.isOrganizer) ? 1 : 0),
                R.ascend(R.prop('before')),
                R.ascend(R.prop('after')),
                R.descend(R.prop('id')),
            ],
            preProcessedMeetings
                .filter(m => adHoc || statesToShow.includes(m.state))
                .filter(
                    m =>
                        m.title.toLowerCase().includes(searchLower) ||
                        m.organizer.email.toLowerCase().includes(searchLower)
                ),
        ),
        [preProcessedMeetings, email, statesToShow, searchLower],
    );
    const [openMeeting, setOpenMeeting] = useState<number>(null);
    const meeting = meetings ? preProcessedMeetings.find(R.propEq('id', openMeeting)) : null;
    const { id: matchId } = match.params;
    const timezone = meeting ? meeting.participants.find(R.whereEq({ email })).timezone : profile.timezone
        || moment.tz.guess();
    const [adhocTimezonePopup, setAdhocTimezonePopup] = useState<DialogState>('closed');
    useEffect(() => {
        if (typeof meeting !== 'object' || !adHoc) {
            return;
        }
        if (adhocTimezonePopup === 'closed'  && timezone) {
            if (timezone !== moment.tz.guess()) {
                setAdhocTimezonePopup('open');
            } else {
                setAdhocTimezonePopup('already-shown');
                if (dialogState !== 'already-shown') {
                    setDialogState('open');
                }
            }
        } else if (dialogState !== 'already-shown' && adhocTimezonePopup !== 'open') {
            setDialogState('open');
        }
    }, [adHoc, timezone, adhocTimezonePopup, meeting]);
    const [allAnswers, setAllAnswers] = useState<IProposition[]>(null);
    const [allAnswersMeeting, setAllAnswersMeeting] = useState<number>(null);
    const [propositionAnswers, setPropositionAnswers] = useState<IPropositionAnswer[]>([]);
    const [setModalDate, setSetModalDate] = useState<string>(null);
    const [propositions, setPropositions] = useState<IViewProposition[]>([]);
    const [prePropositions, setPrePropositions] = useState<IViewProposition[]>([]);
    const [propositionsToShow, setPropositionsToShow] = useState<IViewProposition[]>([]);
    const calculateAnswers = (newPropositions: IProposition[], newMeeting) => {
        setPropositionAnswers(newPropositions.map(x => {
            const prevProposition = newMeeting.id === openMeeting
                ? propositionAnswers.find(R.whereEq({ id: x.id }))
                : null;
            return prevProposition ? {
                ...prevProposition,
                earliest: moment.max(moment(prevProposition.earliest), moment(x.earliest)).toISOString(),
                latest: moment.min(moment(prevProposition.latest), moment(x.latest)).toISOString(),
                accepted: x.acceptedBy.includes(email),
            } : {
                ...R.pick(['id', 'earliest', 'latest'], x),
                accepted: x.acceptedBy.includes(email),
                rejected: false,
            };
        }));
        setIsChangeProposition(true);
    };
    useEffect(() => {
        if (Number(matchId) !== allAnswersMeeting) {
            setAllAnswers(null);
            setStartDate(null);
            setAllAnswersMeeting(null);
        }
    }, [matchId]);
    const [prevPropositions, setPrevPropositions] = useState(null);
    const initPropositions = async (newMeeting: IMeeting) => {
        const nowPropositons =await BackendMethods.getPropositions(Number(matchId));
        setPropositions(nowPropositons);
        if (!R.equals(nowPropositons, prevPropositions) && Number(matchId) !== allAnswersMeeting) {
            calculateAnswers(nowPropositons, newMeeting);
        }
    };
    useEffect(() => {
        if (matchId) {
            const id = Number(matchId);
            const newMeeting = preProcessedMeetings.find(R.propEq('id', id));
            if (newMeeting) {
                const newTimezone = newMeeting.participants.find(R.whereEq({ email })).timezone;
                const start = moment.tz(newMeeting.after, newTimezone).add(1, 'ms')
                    .startOf('week').toISOString();
                const end = moment.tz(newMeeting.before, newTimezone).subtract(1, 'ms')
                    .endOf('week').toISOString();
                setEventsRangeStart(start);
                setEventsRangeEnd(end);
                setOpenMeeting(id);
                if(newMeeting.hasPropositions) {
                    isDesktop? setExpanded(true): setExpanded(false);
                    initPropositions(newMeeting);
                } else {
                    if (newMeeting.state === 'WAITING_FOR_ANSWERS' && newMeeting.isOrganizer && !allAnswersMeeting) {
                        onSetDate(Number(matchId));
                    }
                }
                if (newMeeting.state === 'SCHEDULED') {
                    setTab('SCHEDULED');
                    setExpanded(false);
                    history.push('/main');
                } else if (newMeeting.state === 'CANCELED') {
                    setTab('CANCELED');
                    history.push('/main');
                } else {
                    setTab('IN_PROGRESS');
                }
            } else {
                setPrevPropositions(null);
            }
        } else {
            setPropositions([]);
            setPropositionsToShow([]);
            setEventsRangeStart(null);
            setEventsRangeEnd(null);
            setOpenMeeting(null);
            setSelectedPropositionId(null);
            setStartDate(null);
            setPrevPropositions(null);
            setExpanded(false);
            setSortBy(SortBy.ByRating);
            setAcceptedOnTop(true);
            setIsWorkHoursOnly(false);
            setIsNonConflictingOnly(false);
        }
    }, [matchId, meetings, allAnswersMeeting]);
    const [selectedPropositionId, setSelectedPropositionId] = useState<number>(null);
    const [startOfWeek, setStartOfWeek] = useState(moment().tz(timezone).startOf('week'));
    const [weekEvents, setWeekEvents] = useState<ICalendarEvent[]>([]);
    const [eventsRangeStart, setEventsRangeStart] = useState(null);
    const [eventsRangeEnd, setEventsRangeEnd] = useState(null);
    useEffect(() => {
        if (eventsRangeStart && eventsRangeEnd && !adHoc) {
            BackendMethods.getCalendarEvents(eventsRangeStart, eventsRangeEnd, timezone).then((calendars) => {
                setWeekEvents(R.chain(R.prop('events'), calendars));
            });
        }
    }, [eventsRangeStart, eventsRangeEnd, timezone]);
    const activePropositions = propositions.filter((x) => !x.removed);
    if (( activePropositions).length > 0
        && selectedPropositionId !== -1 && (selectedPropositionId === null
        || !( activePropositions).find(R.propEq('id', selectedPropositionId))
    )) {
        const newSelected = ( propositions)
            .find((x: IProposition | IViewProposition) => !('removed' in x) || !x.removed);
        if (newSelected) {
            setSelectedPropositionId(newSelected.id);
        }
    }
    const onSetDate = async (id: number) => {
        setAllAnswersMeeting(id);
        setPropositionAnswers([]);
        history.push(`/main/${id}`);
        let i = 0;
        const answers = (await BackendMethods.getMeetingAnswers(id)).map(x => ({ ...x, id: i++ }));
        setAllAnswers(answers);
        setPropositionAnswers(answers.map(proposition => ({
            id: proposition.id,
            accepted: false,
            rejected: false,
            earliest: proposition.earliest,
            latest: proposition.earliest,
        })));
        if (answers[0]) {
            setSelectedPropositionId(answers[0].id);
        }
    };
    const filterPropositions = (midProposittions) => {
        if (isWorkHoursOnly === isNonConflictingOnly) {
            return [...midProposittions.filter((proposition) => proposition.conflicts.length === 0)];
        }
        if (isWorkHoursOnly) {
            return [...midProposittions.filter((proposition) => !proposition.conflicts.includes('WORKWEEK'))];
        }
        return [...propositions.filter((proposition) => !proposition.conflicts.includes('CALENDAR'))];
    };
    useEffect(() => {
        if(propositions === prePropositions) {
            return ;
        } else {
            setPrePropositions([...propositions]);
        }
        const allUnseen = propositions.every((x) => !x.seen || x.removed);
        let midProposittions: IViewProposition[] = [...propositions];
        if (isWorkHoursOnly || isNonConflictingOnly) {
            midProposittions = filterPropositions(midProposittions);
        }
        sortBy === SortBy.ByRating ?
            midProposittions.sort(function(a, b) {
                if(a.score > b.score) {
                    return -1;
                } else if(a.score < b.score) {
                    return 1;
                }
                if(moment(a.earliest).isBefore(moment(b.earliest))) {
                    return -1;
                }
                return 1;
            }) :
            midProposittions.sort((a, b) => (moment(a.earliest).isBefore(moment(b.earliest))) ? -1 : 1);
        if (acceptedOnTop) {
            const acceptedPropositions = [...midProposittions.filter((proposition) => proposition.acceptedBy.length>0),
                ...midProposittions.filter((proposition) => proposition.acceptedBy.length===0)];
            midProposittions = allAnswers || acceptedPropositions.filter((x) => (
                x.seen || allUnseen || x.removed
            ));
        } else {
            midProposittions = allAnswers || midProposittions.filter((x) => (
                x.seen || allUnseen || x.removed
            ));
        }
        if (isChangeProposition) {
            setPropositionAnswers(midProposittions.map(proposition => {
                return {
                    ...R.pick(['id', 'earliest', 'latest'], proposition),
                    accepted: false,
                    rejected: false,
                };
            }));
            setIsChangeProposition(false);
        }
        setPropositionsToShow(midProposittions);
    }, [propositions, acceptedOnTop, sortBy, isWorkHoursOnly, isNonConflictingOnly]);
    const renderMeetingAnswers = () => {
        if (!meeting) {
            return null;
        }
        if (propositionsToShow.length > 0 || allAnswers) {
            return propositionsToShow.map((proposition, index) => {
                const propositionAnswer = propositionAnswers.find(s => s.id === proposition.id) || {
                    ...R.pick(['id', 'earliest', 'latest'], proposition),
                    accepted: false,
                    rejected: false,
                };
                const nextScheduleId = propositionsToShow.find((x, i) => (
                    i > index && !x.removed
                ))?.id;
                const setNextCandidateActive = () =>
                    nextScheduleId === undefined
                        ? null
                        : setSelectedPropositionId(nextScheduleId);
                const stayRendered = index>=0 && index<6 ?true: false;
                return (
                    <RenderIfVisible key={(proposition.acceptedBy.includes(email) ? 'accepted_' : 'unaccepted_') + proposition.id} defaultHeight={248} stayRendered={stayRendered}>
                        <Collapse
                            in={!proposition.removed}
                            className={styles.propositionCollapse}
                        >
                            <div>
                                <ConfirmScheduledDateBox
                                    isUserOrganizer={meeting.organizer.email === email}
                                    yesNoButtonsIndex={index}
                                    propositionAnswer={propositionAnswer}
                                    newLabel={allAnswers === null && !proposition.acceptedBy.includes(email)}
                                    proposition={proposition}
                                    setNextCandidateActive={setNextCandidateActive}
                                    activeCandidateId={selectedPropositionId}
                                    timezone={timezone}
                                    timeFormat={profile.timeFormat}
                                    email={email}
                                    meeting={meeting}
                                    setAnswer={x => {
                                        setPropositionAnswers(
                                            R.uniqBy(R.prop('id'), [x, ...propositionAnswers])
                                        );
                                        if (x.accepted || x.rejected) {
                                            if (x.rejected) {
                                                setPropositions(propositions.map((y) => (
                                                    y.id === x.id ? {
                                                        ...y,
                                                        removed: true,
                                                    } : y
                                                )));
                                            }
                                            if(nextScheduleId) {
                                                setSelectedPropositionId(nextScheduleId);
                                            }
                                            setUndoOpen(true);
                                            clearTimeout(undoTimeoutId.current);
                                            undoTimeoutId.current = setTimeout(
                                                () => setUndoOpen(false),
                                                5000,
                                            );
                                        }
                                    }}
                                    setActiveCandidateId={setSelectedPropositionId}
                                    mainPage
                                    isSetDate={allAnswers !== null}
                                    setDate={setSetModalDate}
                                    updateMeeting={props.actions.updateMeeting}
                                />
                            </div>
                        </Collapse>
                    </RenderIfVisible>
                );
            });
        } else {
            return propositions && propositions.length > 0 && propositionsToShow.length === 0
                ? <div style={{ textAlign: 'center', marginTop: '20px' }}>{t('home.noSlot')}</div>
                : Array.from(new Array(5)).map((item, index) => <ConfirmScheduledDateBoxSkeleton key={index} />);
        }
    };
    const selectedPropositionAnswer = propositionAnswers.find(R.whereEq({ id: selectedPropositionId }));
    const duration = moment.duration(meeting?.duration || 0, 'minutes');
    const declined = meeting ? meeting.participants.find(R.whereEq({ email })).declined : false;
    const [expanded, setExpanded] = useState<boolean>(false);
    const [searchExpanded, setSearchExpanded] = useState(false);
    const [startDate, setStartDate] = useState<Moment>(null);
    const setDate = async () => {
        props.actions.updateMeeting(
            await BackendMethods.setMeetingDate(meeting.id, setModalDate),
            profile.email,
        );
        setPropositions([]);
        setAllAnswers(null);
        setStartDate(null);
        setAllAnswersMeeting(null);
        setSetModalDate(null);
        setSelectedPropositionId(null);
    };
    const isOutlook = sessionStorage.getItem('outlook') === '1';
    const calendarProposition = useMemo(() => selectedPropositionAnswer ? {
        id: 'proposition',
        subject: meeting?.title,
        start: selectedPropositionAnswer.earliest,
        end: moment(selectedPropositionAnswer.latest)
            .add(meeting?.duration || 0, 'minutes').toISOString()
    } : null, [selectedPropositionAnswer, meeting?.title, meeting?.duration]);
    const [calendarDialogOpen, setCalendarDialogOpen] = useState(false);
    const connections: IConnection[] = props.data.get('connections');
    useAsyncEffect(async () => {
        if (connections && connections.length === 0 && await BackendMethods.shouldPrompt('calendar')) {
            setCalendarDialogOpen(true);
        }
    }, [connections]);
    const [timezoneSelectOpen, setTimezoneSelectOpen] = useState(false);
    const onTimezoneChange = async (newTimezone) => {
        setTimezoneSelectOpen(false);
        const newMeeting = await BackendMethods.setMeetingTimezone(meeting.id, newTimezone);
        props.actions.updateMeeting(newMeeting, profile.email);
    };

    return (
        <div id="MainPage" className={styles.root}>
            <Header>
                {!adHoc && (
                    <>
                        <HeaderSearch
                            search={search}
                            setSearch={setSearch}
                            hideSearch={() => {
                                setSearchExpanded(false);
                                setSearch('');
                            }}
                            hidden={!searchExpanded}
                            expanded={expanded}
                        />
                        <i
                            className={classNames(styles.search, {
                                [styles.searchOutlook]: isOutlook,
                                [styles.searchOutlookExpanded]: isOutlook && expanded,
                                [styles.searchHidden]: searchExpanded,
                            })}
                            id="SearchIcon"
                            onClick={() => setSearchExpanded(true)}
                        />
                    </>
                )}
            </Header>
            {!isDesktop && !adHoc && <NewMeetingButton />}
            <div className={styles.content}>
                <MeetingRows
                    meetings={meetings}
                    openMeeting={openMeeting}
                    expanded={expanded}
                    email={email}
                    isDesktop={isDesktop}
                    onSetDate={onSetDate}
                    history={history}
                    tab={tab}
                    setTab={setTab}
                    actions={props.actions}
                    data={props.data}
                />
                {expanded && (
                    <Collapse
                        in={expanded}
                        orientation="horizontal"
                    >
                        <div className={styles.detailsContainer}>
                            <div className={styles.answers}>
                                {allAnswers && meeting && meeting.hasPropositions && (
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            setAllAnswers(null);
                                            setStartDate(null);
                                            setSelectedPropositionId(null);
                                            setPropositionAnswers([]);
                                            setPrevPropositions([]);
                                            setAllAnswersMeeting(null);
                                        }}
                                        className={styles.backButton}
                                    >
                                        <div className={styles.backIcon} />
                                        {t('home.backToCurrent')}
                                    </Button>
                                )}
                                <div className={styles.answersTop}>
                                    <div>
                                        <img className={styles.hourglass} src="/assets/hourglass.svg" />
                                        <span className={styles.duration}>
                                            {duration.hours() > 0 && `${duration.hours()}h `}
                                            {duration.minutes() > 0 && `${duration.minutes()}min`}
                                        </span>
                                    </div>
                                    <div className={styles.timezoneInfo}>
                                        {t('home.allTimesShown')}
                                        {' '}
                                        <Button
                                            variant="text"
                                            onClick={() => setTimezoneSelectOpen(true)}
                                            className={styles.timeZoneButton}
                                        >
                                            {moment().tz(timezone).zoneName()}
                                        </Button>
                                        {timezone !== moment.tz.guess() && (
                                            <Tooltip
                                                title={t('home.timezoneMismatch')}
                                                placement="right"
                                                classes={{ tooltip: styles.tooltip }}
                                            >
                                                <img
                                                    src="/assets/exclamation.svg"
                                                    className={styles.warningIcon}
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                    {timezoneSelectOpen && (
                                        <div
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            }}
                                            className={styles.timezoneSelect}
                                        >
                                            <TimeZoneSelect
                                                onChange={onTimezoneChange}
                                                onBlur={() => setTimezoneSelectOpen(false)}
                                            />
                                        </div>
                                    )}
                                    <div className={styles.sortMenu}>
                                        <SortMenu
                                            setSortBy={setSortBy}
                                            sortBy={sortBy}
                                            acceptedOnTop={acceptedOnTop}
                                            setAcceptedOnTop={setAcceptedOnTop}
                                        />
                                    </div>
                                    <div className={styles.filterMenu}>
                                        <FilterMenu
                                            isWorkHoursOnly={isWorkHoursOnly}
                                            setIsWorkHoursOnly={setIsWorkHoursOnly}
                                            isNonConflictingOnly={isNonConflictingOnly}
                                            setIsNonConflictingOnly={setIsNonConflictingOnly}
                                        />
                                    </div>
                                </div>
                                {allAnswers && (
                                    <div className={styles.setDateContainer}>
                                        <MobileDateTimePicker
                                            label={t('home.anotherStart')}
                                            disablePast
                                            value={startDate}
                                            onChange={(date) => {
                                                setStartDate(date);
                                                if (date) {
                                                    const dateISO = date.toISOString();
                                                    setPropositionAnswers(R.uniqBy(R.prop('id'), [
                                                        {
                                                            id: -1,
                                                            accepted: false,
                                                            rejected: false,
                                                            earliest: dateISO,
                                                            latest: dateISO,
                                                        },
                                                        ...propositionAnswers,
                                                    ]));
                                                    setSelectedPropositionId(-1);
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            ampm={profile.timeFormat === '12h'}
                                        />
                                        <Button
                                            variant="contained"
                                            disabled={!startDate}
                                            className={styles.setDateButton}
                                            onClick={() => setSetModalDate(
                                                propositionAnswers.find(x => x.id === -1).earliest
                                            )}
                                        >
                                            {t('home.setMeeting')}
                                        </Button>
                                    </div>
                                )}
                                {renderMeetingAnswers()}
                            </div>
                            <WeekCalendar
                                rangeStart={eventsRangeStart}
                                rangeEnd={eventsRangeEnd}
                                events={weekEvents}
                                proposition={calendarProposition}
                                timezone={timezone}
                                timeFormat={profile.timeFormat}
                                workWeekEvents={
                                    getWorkWeekEvents(timezone, props.data.get('workWeek'), eventsRangeStart, eventsRangeEnd)
                                }
                                className={styles.weekCalendar}
                                startOfDay={startOfWeek}
                                setStartOfDay={setStartOfWeek}
                                showClose
                            />
                        </div>
                    </Collapse>
                )}
            </div>
            {meeting && (
                <>
                    <RejectMeetingModal
                        meeting={meeting}
                        open={R.last(location.pathname.split('/')) === 'decline'}
                        onClose={() => history.push(`/main/${meeting.id}`)}
                        actions={props.actions}
                        history={history}
                    />
                    <SetThisDateModal
                        open={setModalDate !== null}
                        onClose={() => setSetModalDate(null)}
                        onConfirm={setDate}
                        meeting={meeting}
                        startDate={setModalDate}
                        timezone={timezone}
                        timeFormat={profile.timeFormat}
                    />
                </>
            )}
            <RegisterModal
                dialogState={dialogState}
                setDialogState={setDialogState}
            />
            <UndoSnackbar
                open={undoOpen && meeting !== null}
                onClose={() => setUndoOpen(false)}
                meetingId={meeting?.id}
                updateMeeting={(data) => props.actions.updateMeeting(data, profile.email)}
            />
            <AdhocTimezoneModal
                open={adhocTimezonePopup === 'open'}
                meeting={meeting}
                email={email? email: ''}
                onClose={() => setAdhocTimezonePopup('already-shown')}
            />
            <AskForCalendarModal
                open={calendarDialogOpen}
                onClose={() => setCalendarDialogOpen(false)}
            />
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(MainPage);
