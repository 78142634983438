import React from 'react';
import IMeetingFromToDates from 'interfaces/IMeetingFromToDates';
const styles = require('./MeetingDateLines.css');

interface Props {
    dateLines: IMeetingFromToDates;
}

export default function MeetingDateLines({ dateLines }: Props) {
    return (
        <div className={styles.root}>
            <div className={styles.firstLine}>
                {dateLines.dateFrom}&nbsp;{dateLines.dateFromSecondLine}
            </div>
            <div className={styles.secondLine}>
                {dateLines.dateTo}
            </div>
        </div>
    );
}
