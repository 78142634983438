import * as React from 'react';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import classNames from 'classnames';
import { TextField as MuiTextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useRef, useState } from 'react';
const styles = require('./TextField.css');

interface Props extends Partial<OutlinedTextFieldProps> {
    variant?: any;
}

const useStyles = makeStyles<Theme, { width: number }>({
    labelOffset: {
        transform: ({ width }) => `translate(${14 + (width ? width + 8 : 0)}px, 9px) scale(1)`,
    },
});

const TextField = (props: Props) => {
    const {
        className,
        inputProps,
        InputProps: { classes = {}, className: InputClassName = null, startAdornment, ...InputPropsRest } = {},
        InputLabelProps: { classes: InputLabelClasses = {}, ...InputLabelRest } = {},
        SelectProps,
        variant,
        ...rest
    } = props;
    const value = props.value || inputProps?.value;
    const [shrink, setShrink] = useState(!!value || !!props.placeholder);
    const startAdornmentRef = useRef<HTMLDivElement | null>(null);
    const [startAdornmentWidth, setStartAdornmentWidth] = useState(0);
    useEffect(() => {
        if (startAdornmentRef.current) {
            const { width } = startAdornmentRef.current.getBoundingClientRect();
            setStartAdornmentWidth(width);
        } else {
            setStartAdornmentWidth(0);
        }
    }, [startAdornment]);
    const [focused, setFocused] = useState(false);
    useEffect(() => {
        setShrink(Boolean(value || props.placeholder || focused));
    }, [value, props.placeholder, focused]);
    const classes2 = useStyles({ width: startAdornmentWidth });
    const shrink2 = 'shrink' in InputLabelRest ? InputLabelRest.shrink : shrink;
    return (
        <MuiTextField
            variant={variant || 'outlined'}
            margin="dense"
            size="small"
            className={classNames(styles.root, className)}
            InputLabelProps={{
                ...(shrink2 === false ? { shrink } : {}),
                classes: {
                    ...(shrink2 === false ? { root: classes2.labelOffset } : {}),
                    ...InputLabelClasses,
                },
                className: styles.label,
                ...InputLabelRest
            }}
            InputProps={{
                className: classNames(InputClassName, styles.Input),
                classes: {
                    notchedOutline: styles.outline,
                    ...classes
                },
                ...(startAdornment ? {
                    startAdornment: (typeof startAdornment === 'object' && 'type' in startAdornment)
                        ? React.cloneElement(startAdornment, {
                            ref: startAdornmentRef,
                        })
                        : <div ref={startAdornmentRef}>{startAdornment}</div>,
                } : {}),
                onAnimationStart: ({ animationName }) => {
                    if (animationName !== 'mui-auto-fill-cancel') {
                        setShrink(true);
                    }
                },
                ...InputPropsRest
            }}
            inputProps={{
                className: styles.input,
                ...inputProps
            }}
            SelectProps={{
                IconComponent: (iconProps) => (
                    <div {...iconProps} className={classNames(iconProps.className, styles.indicator)} />
                ),
                ...SelectProps,
            }}
            onFocus={() => setFocused(true)}
            onBlur={({ target }) => (
                setFocused(false)
            )}
            {...rest}
        />
    );
};

export default TextField;
