import { combineReducers } from 'redux';
import appReducer from './appReducer';
import meetingReducer from './app-reducers/meetingReducer';
import preferencesReducer from './app-reducers/preferencesReducer';
import plusPlanAdminReducer from './app-reducers/plusPlanAdminReducer';
import plusPlanPaymentReducer from './app-reducers/plusPlanPaymentReducer';
import manageLicensesReducer from './app-reducers/manageLicensesReducer';
import dataReducer from './app-reducers/dataReducer';

const rootReducer = combineReducers({
    data: dataReducer,
    app: appReducer,
    plusPlanAdmin: plusPlanAdminReducer,
    plusPlanPayment: plusPlanPaymentReducer,
    manageLicenses: manageLicensesReducer,
    meeting: meetingReducer,
    preferences: preferencesReducer,
});

export default rootReducer;
