import IMeeting from '../interfaces/IMeeting';
import IProfile from 'interfaces/IProfile';
import { ISingleTypeAction } from 'interfaces/IAction';

export const SET_MEETING = 'SET_MEETING';
export const SET_LOADING_SET_THIS_DATE_BUTTON = 'SET_LOADING_SET_THIS_DATE_BUTTON';
export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS';

const setMeetingAction = (
    meeting: IMeeting,
    profile: IProfile
): ISingleTypeAction => {
    return {
        type: SET_MEETING,
        payload: {
            meeting: meeting,
            profile: profile
        }
    };
};

export default class MeetingActions {
    setMeeting(meeting: IMeeting, profile: IProfile): ISingleTypeAction {
        return setMeetingAction(meeting, profile);
    }

    setCalendarEvents(calendarEvents, timezone: string, timeFormat: string, language: string): ISingleTypeAction {
        return {
            type: SET_CALENDAR_EVENTS,
            payload: { calendarEvents, timezone, timeFormat, language }
        };
    }
}
