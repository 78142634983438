import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getSubdomain } from '../utils/subdomain';
const config = require('CONFIG');
const styles = require('./NoSubdomain.css');

export default function NoSubdomain() {
    const { t } = useTranslation();
    return (
        <div className={styles.root}>
            <div className={styles.notOnTanbuu}>
                <Trans
                    i18nKey="small.notOnTanbuu"
                    components={{
                        b: <b className={styles.name} />,
                    }}
                    values={{ name: getSubdomain() }}
                />
            </div>
            <div className={styles.text}>
                <Trans
                    i18nKey="small.setUpAccount"
                    components={{
                        a: <a className={styles.link} href={`https://${config.baseDomain}/contact`} />
                    }}
                />
            </div>
            <div className={styles.text}>
                {t('small.or')}
            </div>
            <div className={styles.text}>
                <a className={styles.link} href={`https://${config.baseDomain}/sb-customer/find-service`}>
                    {t('small.seeOtherServices')}
                </a>
            </div>
        </div>
    );
}
