import * as React from 'react';

const Styles = require('./OuterLoginBg.css');

export default class OuterLoginBg extends React.PureComponent<{}, null> {
  render() {
    return (
      <div className={Styles.OuterLoginBg}>
        <div />
        <div />
      </div>
    );
  }
}
