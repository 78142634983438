import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IService } from './Services';
import { ILocation } from './Sites';
import useCloudinary from '../../../utils/useCloudinary';
import Button from '../../../components/_ReusableComponents/Button/Button';
import classNames from 'classnames';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import { Checkbox, Dialog, FormControlLabel, IconButton } from '@material-ui/core';
import PersonnelHours, { IWorkHours } from './PersonnelHours';
import * as R from 'ramda';
import BackendMethods from '../../../api/BackendMethods';
import { Moment } from 'moment';
import { DatePicker, DatePickerProps, DateTimePickerProps, MobileDateTimePicker, MobileDateTimePickerProps } from '@material-ui/lab';
import moment from 'moment';
import { IAppointment } from '../Dashboard/Dashboard';
import AffectedAppointments from './AffectedAppointments';
const styles = require('./Personnel.css');

interface IVacationRange {
    start: string;
    end: string;
}

export interface INewPerson {
    name: string;
    email: string;
    picture: string;
    accountPerm: boolean;
    bookingPerm: boolean;
    services: number[];
    dtimesOff: IVacationRange[];
}

export interface IPerson extends INewPerson {
    id: number;
    locations: IWorkHours[];
}

interface IProps {
    timeFormat: string;
}

interface DefaultZeroPickerProps {
    PickerComponent: React.FC<DatePickerProps | DateTimePickerProps>;
    props: DatePickerProps | DateTimePickerProps;
}

function DefaultZeroPicker({ PickerComponent, props }: DefaultZeroPickerProps) {
    return (
        <PickerComponent
            {...props}
            onChange={(newDate: Moment) => {
                if (newDate.hours() === moment().hours() && newDate.minutes() === moment().minutes()) {
                    props.onChange(newDate.hours(0).minutes(0));
                } else {
                    props.onChange(newDate);
                }
            }}
        />
    );
}

export default function Personnel({ timeFormat }: IProps) {
    const { t, i18n: { language } } = useTranslation();
    const [sites, setSites] = useState<ILocation[] | null>(null);
    const [allServices, setAllServices] = useState<IService[] | null>(null);
    const [people, setPeople] = useState<IPerson[] | null>(null);
    useEffect(() => {
        (async () => {
            const [newSites, newServices, newPeople] = await Promise.all([
                BackendMethods.getLocations(),
                BackendMethods.getServices(),
                BackendMethods.getPeople(),
            ]);
            setSites(newSites);
            setAllServices(newServices);
            setPeople(newPeople);
        })();
    }, []);
    const [selectedPerson, setSelectedPerson] = useState<number | 'new' | null>(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [photo, setPhoto] = useState<string | null>(null);
    const [canEditSettings, setCanEditSettings] = useState(false);
    const [canBookOthers, setCanBookOthers] = useState(false);
    const [services, setServices] = useState<number[]>([]);
    const [dateFrom, setDateFrom] = useState<Moment | null>(null);
    const [dateTo, setDateTo] = useState<Moment | null>(null);
    const [wholeDays, setWholeDays] = useState(true);
    const [vacations, setVacations] = useState<IVacationRange[]>([]);
    const [workHours, setWorkHours] = useState<IWorkHours[]>([]);
    const [affectedAppointments, setAffectedAppointments] = useState<IAppointment[]>([]);
    const timezone = moment.tz.guess();
    useEffect(() => {
        (async () => {
            if (typeof selectedPerson === 'number') {
                const [servicesAppointments, vacationsAppointments] = await Promise.all([
                    BackendMethods.getChangePreview({
                        type: 'EMPL_SERVICE',
                        details: {
                            employee: selectedPerson,
                            services,
                        },
                    }),
                    BackendMethods.getChangePreview({
                        type: 'EMPL_DAYS_OFF',
                        details: {
                            employee: selectedPerson,
                            daysOff: vacations,
                            timezone,
                        },
                    }),
                ]);
                setAffectedAppointments(R.uniqBy(R.prop('id'), [...servicesAppointments, ...vacationsAppointments]));
            } else {
                setAffectedAppointments([]);
            }
        })();
    }, [selectedPerson, services, vacations]);
    const widget = useCloudinary((uploadInfo) => {
        setPhoto(uploadInfo.url);
    }, BackendMethods.getSbCloudinarySignature);
    const [deleteOpen, setDeleteOpen] = useState(false);
    if (!widget || !sites || !allServices || !people) {
        return null;
    }
    const loadData = (person?: IPerson) => {
        if (person) {
            setSelectedPerson(person.id);
            setName(person.name);
            setEmail(person.email);
            setPhoto(person.picture);
            setCanEditSettings(person.accountPerm);
            setCanBookOthers(person.bookingPerm);
            setServices(person.services);
            setWorkHours(person.locations);
            setVacations(person.dtimesOff || []);
        } else {
            setSelectedPerson('new');
            setName('');
            setEmail('');
            setPhoto('');
            setCanEditSettings(false);
            setCanBookOthers(false);
            setServices([]);
            setWorkHours([]);
            setVacations([]);
        }
        setDateFrom(null);
        setDateTo(null);
        setWholeDays(true);
    };
    const PickerComponent: React.FC<DatePickerProps | MobileDateTimePickerProps> = wholeDays ? DatePicker : MobileDateTimePicker;
    return (
        <div className={styles.root}>
            <div className={styles.left}>
                <div className={styles.addHeader}>
                    {t('small.personnel')}
                    <Button
                        variant="contained"
                        className={styles.addButton}
                        onClick={() => loadData()}
                    >
                        <img src="/assets/plus-white.svg" alt="" className={styles.addIcon} />
                        {t('small.addPerson')}
                    </Button>
                </div>
                <div className={styles.elements}>
                    {people.map((person) => (
                        <Button
                            key={person.id}
                            variant="contained"
                            className={classNames(styles.element, styles.elementButton, {
                                [styles.elementActive]: person.id === selectedPerson,
                            })}
                            onClick={() => loadData(person)}
                        >
                            {!person.name || person.name.replace(/\s/g, '') === '' ? person.email : person.name}
                        </Button>
                    ))}
                </div>
            </div>
            <div className={styles.right}>
                {selectedPerson !== null && (
                    <>
                        <div className={styles.top}>
                            <div className={styles.photoColumn}>
                                <div
                                    className={styles.photoContainer}
                                    onClick={() => widget.open()}
                                >
                                    {photo ? (
                                        <img src={photo} alt="" className={styles.photo} />
                                    ) : (
                                        <div className={styles.addPhoto}>
                                            {t('small.addPhoto')}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.infoColumn}>
                                <TextField
                                    value={name}
                                    onChange={({ target }) => setName(target.value)}
                                    label={t('small.personName')}
                                    className={styles.field}
                                />
                                <TextField
                                    value={email}
                                    onChange={({ target }) => setEmail(target.value)}
                                    label={t('small.email')}
                                    className={styles.field}
                                />
                                <FormControlLabel
                                    checked={canEditSettings}
                                    onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setCanEditSettings(target.checked)}
                                    control={<Checkbox color="primary" />}
                                    label={t('small.canEditSettings')}
                                />
                                <FormControlLabel
                                    checked={canBookOthers}
                                    onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setCanBookOthers(target.checked)}
                                    control={<Checkbox color="primary" />}
                                    label={t('small.canBookOthers')}
                                />
                            </div>
                        </div>
                        <div className={styles.header}>
                            {t('small.services')}
                        </div>
                        <FormControlLabel
                            checked={allServices.every((x) => services.includes(x.id))}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => (
                                setServices(target.checked ? allServices.map(R.prop('id')) : [])
                            )}
                            control={<Checkbox color="primary" />}
                            label={t('small.allServices')}
                        />
                        <div className={styles.services}>
                            {allServices.map((service) => (
                                <FormControlLabel
                                    key={service.id}
                                    checked={services.includes(service.id)}
                                    onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                                        setServices(target.checked
                                            ? [...services, service.id]
                                            : services.filter((x) => x !== service.id));
                                    }}
                                    control={<Checkbox color="primary" />}
                                    label={service.name}
                                />
                            ))}
                        </div>
                        <div className={styles.header}>
                            {t('small.daysOff')}
                        </div>
                        <div className={styles.row}>
                            <DefaultZeroPicker
                                PickerComponent={PickerComponent}
                                props={{
                                    value: dateFrom,
                                    onChange: setDateFrom,
                                    label: t('small.from'),
                                    renderInput: (params) => (
                                        <TextField
                                            {...params}
                                            className={classNames(params.className, styles.dateFrom)}
                                            helperText=""
                                            inputProps={{ ...params.inputProps, ...(wholeDays ? { placeholder: language === 'pl' ? 'dd.mm.rrrr' : 'mm/dd/yyyy' } : {}) }}
                                        />
                                    ),
                                    ampm: timeFormat === '12h',
                                }}
                            />
                            <DefaultZeroPicker
                                PickerComponent={PickerComponent}
                                props={{
                                    value: dateTo,
                                    onChange: setDateTo,
                                    label: t('small.to'),
                                    renderInput: (params) => (
                                        <TextField
                                            {...params}
                                            className={classNames(params.className, styles.dateTo)}
                                            helperText=""
                                            inputProps={{ ...params.inputProps, ...(wholeDays ? { placeholder: language === 'pl' ? 'dd.mm.rrrr' : 'mm/dd/yyyy' } : {}) }}
                                        />
                                    ),
                                    ampm: timeFormat === '12h',
                                }}
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" />}
                                label={t('small.wholeDays')}
                                checked={wholeDays}
                                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setWholeDays(target.checked)}
                                className={styles.wholeDays}
                            />
                            <Button
                                variant="contained"
                                disabled={!dateFrom || !dateTo}
                                onClick={() => {
                                    setVacations([
                                        ...vacations,
                                        {
                                            start: (wholeDays ? moment(dateFrom).startOf('day') : dateFrom)
                                                .format('YYYY-MM-DDTHH:mm'),
                                            end: (wholeDays ? moment(dateTo).endOf('day') : dateTo)
                                                .format('YYYY-MM-DDTHH:mm'),
                                        },
                                    ]);
                                    setDateFrom(null);
                                    setDateTo(null);
                                }}
                            >
                                {t('small.add')}
                            </Button>
                        </div>
                        <div className={styles.chips}>
                            {vacations.map((x) => {
                                const wholeDay = moment(x.start).isSame(moment(x.start).startOf('day'), 'minute')
                                    && moment(x.end).isSame(moment(x.end).endOf('day'), 'minute');
                                const format = wholeDay ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm';
                                return (
                                    <div key={x.start + x.end} className={styles.interactiveChip}>
                                        {moment(x.start).format(format)}
                                        {x.start !== x.end && ` - ${moment(x.end).format(format)}`}
                                        <IconButton
                                            onClick={() => {
                                                setVacations(vacations.filter((y) => (
                                                    y !== x
                                                )));
                                            }}
                                            size="small"
                                            className={styles.chipDelete}
                                        >
                                            <img src="/assets/delete-blue.svg" alt="" />
                                        </IconButton>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.saveRow}>
                            {selectedPerson !== 'new' && (
                                <Button
                                    variant="text"
                                    onClick={() => setDeleteOpen(true)}
                                >
                                    {t('small.deletePerson')}
                                </Button>
                            )}
                            <Button
                                variant="text"
                                onClick={() => setSelectedPerson(null)}
                                className={styles.discardChanges}
                            >
                                {t('small.discardChanges')}
                            </Button>
                            <AffectedAppointments appointments={affectedAppointments} />
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    const newPerson: INewPerson = {
                                        name,
                                        email,
                                        picture: photo,
                                        accountPerm: canEditSettings,
                                        bookingPerm: canBookOthers,
                                        services,
                                        dtimesOff: vacations,
                                    };
                                    if (selectedPerson === 'new') {
                                        const { id } = await BackendMethods.addPerson(newPerson);
                                        setPeople([
                                            ...people,
                                            {
                                                ...newPerson,
                                                id,
                                                locations: [],
                                            },
                                        ]);
                                    } else {
                                        await BackendMethods.updatePerson(selectedPerson, newPerson);
                                        await BackendMethods.flagAffectedAppointments({
                                            type: 'EMPL_SERVICE',
                                            details: {
                                                employee: selectedPerson,
                                                services,
                                            },
                                        });
                                        await BackendMethods.flagAffectedAppointments({
                                            type: 'EMPL_DAYS_OFF',
                                            details: {
                                                employee: selectedPerson,
                                                daysOff: vacations,
                                                timezone,
                                            },
                                        });
                                        setPeople(people.map((x) => (
                                            x.id === selectedPerson ? {
                                                ...x,
                                                ...newPerson,
                                                locations: workHours,
                                            } : x
                                        )));
                                    }
                                    setSelectedPerson(null);
                                }}
                                disabled={!name || !email}
                            >
                                {t('small.save')}
                            </Button>
                        </div>
                        <PersonnelHours
                            workHours={workHours}
                            setWorkHours={setWorkHours}
                            sites={sites}
                            people={people.filter((x) => x.id !== selectedPerson)}
                            timeFormat={timeFormat}
                            personId={selectedPerson}
                        />
                    </>
                )}
            </div>
            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                classes={{ paper: styles.dialog }}
            >
                <div className={styles.dialogText}>
                    {t('small.deletePersonConfirm')}
                </div>
                <div className={styles.dialogActions}>
                    <Button
                        className={styles.dialogButton}
                        onClick={async () => {
                            if (selectedPerson !== 'new') {
                                await BackendMethods.deletePerson(selectedPerson);
                                setPeople(people.filter((x) => (
                                    x.id !== selectedPerson
                                )));
                                setSelectedPerson(null);
                                setDeleteOpen(false);
                            }
                        }}
                    >
                        {t('small.delete')}
                    </Button>
                    <Button
                        variant="contained"
                        className={styles.dialogButton}
                        onClick={() => setDeleteOpen(false)}
                    >
                        {t('common.cancel')}
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}
