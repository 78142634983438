import * as React from 'react';
import classNames from 'classnames';
const $ = require('jquery');
declare let window: Window;
interface Window {
    jQuery: any;
}
window.jQuery = $;
require('jquery-ui-bundle');

const Styles = require('./ToggleSwitch.css');

interface Props {
    switchId?: string;
    labelForState1: string;
    labelForState2: string;
    state1: any;
    state2: any;
    currentState: any;
    toggleBusiness: Function;
    disabled: boolean;
}

interface State {}

export default class ToggleSwitch extends React.Component<Props, State> {
    circleRef: string = 'CIRCLE_REF';

    MAX_LEFT_OF_CIRCLE_AFTER_FINISH_DROP: number = 33;

    // if user drag circle then we have to reset left position to default one.
    resetCirclePosition() {
        const $circle: any = $(this.refs[this.circleRef]);
        $circle.css('left', 0);
    }

    callback1 = () => {
        this.resetCirclePosition();
        this.props.toggleBusiness();
    };

    callback2 = () => {
        this.resetCirclePosition();
        this.props.toggleBusiness();
    };

    changeState = () => {
        if (this.props.currentState === this.props.state2) {
            this.callback2();
        } else {
            this.callback1();
        }
    };

    componentDidMount() {
        const $circle: any = $(this.refs[this.circleRef]);

        $circle.draggable({
            containment: 'parent',
            axis: 'x',
            stop: (event, ui) => {
                const pageX: number = event.pageX;
                const elementX: number = ui.offset.left;

                const $element: any = $(ui.helper);
                const left = parseInt(
                    $element.css('left').replace('px', ''),
                    10
                );

                if (this.props.currentState === this.props.state1) {
                    if (
                        left > this.MAX_LEFT_OF_CIRCLE_AFTER_FINISH_DROP / 2 ||
                        pageX > elementX + 20
                    ) {
                        this.callback1();
                    } else {
                        this.callback2();
                    }
                } else {
                    if (
                        Math.abs(left) >
                            this.MAX_LEFT_OF_CIRCLE_AFTER_FINISH_DROP / 2 ||
                        elementX - 20 > pageX
                    ) {
                        this.callback2();
                    } else {
                        this.callback1();
                    }
                }
            }
        });
    }

    componentWillUnmount() {
        const $circle: any = $(this.refs[this.circleRef]);
        $circle.draggable('destroy');
    }

    render() {
        const isSecondState: boolean =
            this.props.currentState === this.props.state2;

        return (
            <div
                className={classNames({
                    [Styles.ToggleSwitch]: true,
                    [Styles.disabled]: this.props.disabled
                })}
            >
                <div
                    onClick={this.callback2}
                    className={Styles.Label1}
                >
                    {this.props.labelForState1}
                </div>
                <div
                    id={this.props.switchId}
                    onClick={this.changeState}
                    className={Styles.BackgroundForCircle}
                >
                    <div
                        ref={this.circleRef}
                        className={classNames(
                            Styles.BackgroundForCircle__Circle,
                            isSecondState === true
                                ? Styles['BackgroundForCircle__Circle--SecondState']
                                : ''
                        )}
                    />
                </div>
                <div
                    onClick={this.callback1}
                    className={Styles.Label2}
                >
                    {this.props.labelForState2}
                </div>
            </div>
        );
    }
}
