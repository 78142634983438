// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".paper__1-cpE {\n    padding: 24px;\n    margin: 16px;\n}\n\n.title__1UL-1 {\n    font-size: 20px;\n    text-align: center;\n    margin-bottom: 16px;\n}\n\n@media (min-width: 1250px) {\n\n.title__1UL-1 {\n        text-align: left\n}\n    }\n\n.date__1AmiC > div {\n    margin: 8px 0;\n}\n\n.date__1AmiC > div > :first-child {\n        font-weight: 400;\n        font-size: 16px;\n    }\n\n.date__1AmiC > div > :last-child {\n        margin-top: 4px;\n        font-weight: 400;\n        font-size: 16px;\n    }\n\n.buttons__ZbSFD {\n    margin-top: 32px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.yesButton__2eoqx {\n    margin-right: 38px;\n}\n", "",{"version":3,"sources":["SetThisDateModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AAIvB;;AAHI;;AAJJ;QAKQ;AAER;IADI;;AAGJ;IACI,aAAa;AAWjB;;AATI;QACI,gBAAgB;QAChB,eAAe;IACnB;;AACA;QACI,eAAe;QACf,gBAAgB;QAChB,eAAe;IACnB;;AAGJ;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;AACtB","file":"SetThisDateModal.css","sourcesContent":[".paper {\n    padding: 24px;\n    margin: 16px;\n}\n\n.title {\n    font-size: 20px;\n    text-align: center;\n    margin-bottom: 16px;\n    @media (min-width: 1250px) {\n        text-align: left;\n    }\n}\n\n.date > div {\n    margin: 8px 0;\n\n    & > :first-child {\n        font-weight: 400;\n        font-size: 16px;\n    }\n    & > :last-child {\n        margin-top: 4px;\n        font-weight: 400;\n        font-size: 16px;\n    }\n}\n\n.buttons {\n    margin-top: 32px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.yesButton {\n    margin-right: 38px;\n}\n"]}]);
// Exports
exports.locals = {
	"paper": "paper__1-cpE",
	"title": "title__1UL-1",
	"date": "date__1AmiC",
	"buttons": "buttons__ZbSFD",
	"yesButton": "yesButton__2eoqx"
};
module.exports = exports;
