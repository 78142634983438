// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".menu__52lIQ {\n    width: 24px;\n}\n\n.menuIcon__2rYTu {\n    width: 24px;\n    height: 24px;\n}\n\n.menuButton__3pVuF {\n    width: 24px;\n    padding: 0;\n}\n\n.menuItem__3gRuI {\n    background-color: white !important;\n    padding: 8px 12px;\n    line-height: 20px;\n    min-height: 0 !important;\n}\n\n.selectedItem__1vyFm {\n    background-color: #eff3f4 !important;\n    padding: 8px 12px;\n    line-height: 20px;\n    min-height: 0 !important;\n}\n", "",{"version":3,"sources":["SortMenu.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,kCAAkC;IAClC,iBAAiB;IACjB,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,oCAAoC;IACpC,iBAAiB;IACjB,iBAAiB;IACjB,wBAAwB;AAC5B","file":"SortMenu.css","sourcesContent":[".menu {\n    width: 24px;\n}\n\n.menuIcon {\n    width: 24px;\n    height: 24px;\n}\n\n.menuButton {\n    width: 24px;\n    padding: 0;\n}\n\n.menuItem {\n    background-color: white !important;\n    padding: 8px 12px;\n    line-height: 20px;\n    min-height: 0 !important;\n}\n\n.selectedItem {\n    background-color: #eff3f4 !important;\n    padding: 8px 12px;\n    line-height: 20px;\n    min-height: 0 !important;\n}\n"]}]);
// Exports
exports.locals = {
	"menu": "menu__52lIQ",
	"menuIcon": "menuIcon__2rYTu",
	"menuButton": "menuButton__3pVuF",
	"menuItem": "menuItem__3gRuI",
	"selectedItem": "selectedItem__1vyFm"
};
module.exports = exports;
