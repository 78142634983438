import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import Button from '../../../components/_ReusableComponents/Button/Button';
import { Dialog, IconButton } from '@material-ui/core';
import BackendMethods from '../../../api/BackendMethods';
const styles = require('./Resources.css');

export interface IResource {
    id: number;
    name: string;
}

export default function Resources() {
    const { t } = useTranslation();
    const [resources, setResources] = useState<IResource[] | null>(null);
    useEffect(() => {
        (async () => {
            setResources(await BackendMethods.getResources());
        })();
    }, []);
    const [name, setName] = useState('');
    const [resourceToDelete, setResourceToDelete] = useState<IResource | null>(null);
    const [deleteOpen, setDeleteOpen] = useState(false);
    if (!resources) {
        return null;
    }
    return (
        <div>
            <form
                className={styles.addForm}
                noValidate
                onSubmit={async (event) => {
                    event.preventDefault();
                    if (name) {
                        const { id } = await BackendMethods.addResource(name);
                        setResources([
                            ...resources,
                            {
                                id,
                                name,
                            },
                        ]);
                        setName('');
                    }
                }}
            >
                <TextField
                    label={t('small.resourceName')}
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                    className={styles.addField}
                />
                <Button
                    variant="contained"
                    type="submit"
                    disabled={!name}
                >
                    {t('small.add')}
                </Button>
            </form>
            <div className={styles.elements}>
                {resources.map((resource) => (
                    <div className={styles.element} key={resource.id}>
                        {resource.name}
                        <IconButton
                            size="small"
                            className={styles.deleteButton}
                            onClick={() => {
                                setResourceToDelete(resource);
                                setDeleteOpen(true);
                            }}
                        >
                            <img src="/assets/delete-blue.svg" alt="" className={styles.deleteIcon} />
                        </IconButton>
                    </div>
                ))}
            </div>
            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                classes={{ paper: styles.dialog }}
            >
                <div className={styles.dialogText}>
                    {t('small.confirmResourceDelete', { name: resourceToDelete?.name })}
                </div>
                <div className={styles.dialogActions}>
                    <Button
                        className={styles.dialogButton}
                        onClick={async () => {
                            await BackendMethods.deleteResource(resourceToDelete.id);
                            setResources(resources.filter((x) => (
                                x.id !== resourceToDelete.id
                            )));
                            setDeleteOpen(false);
                        }}
                    >
                        {t('small.delete')}
                    </Button>
                    <Button
                        variant="contained"
                        className={styles.dialogButton}
                        onClick={() => setDeleteOpen(false)}
                    >
                        {t('common.cancel')}
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}
