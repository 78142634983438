import * as React from 'react';
import Modal from '../../_ReusableComponents/Modals/Modal';
import * as R from 'ramda';
import ISubscriptionPreview from '../../../interfaces/backendResponses/ISubscriptionPreview';
import moment from 'moment-timezone';
import TermsContent from '../../_ReusableComponents/TermsContent/TermsContent';
import classNames from 'classnames';
import Button from 'components/_ReusableComponents/Button/Button';
import { Trans, useTranslation } from 'react-i18next';
const Styles = require('./PlaceOrder.css');

interface Props {
    handleSubmit: (event: any) => void;
    goBack: () => void;
    plusPlanPayment: any;
    timezone: string;
}

interface State {
    showTerms: boolean;
}

const initialState: State = {
    showTerms: false
};

export default (props: Props) => {
    const { t, i18n } = useTranslation();
    const [state, setState] = React.useState(initialState);

    const orderPreview: ISubscriptionPreview = props.plusPlanPayment.get('orderPreview');
    const expires = moment.tz(R.last(orderPreview.items).to, props.timezone)
        .format(i18n.language === 'pl' ? 'D MMMM Y' : 'MMMM D, Y');

    const { plusPlanPayment } = props;
    const billingType = plusPlanPayment.get('billingType');
    const unitPrice = billingType === 'monthly'
        ? plusPlanPayment.get('monthlyPrice')
        : plusPlanPayment.get('yearlyPrice');
    const totalPrice = unitPrice / 100 * plusPlanPayment.get('amountOfLicenses');
    const card = plusPlanPayment.get('cardDetails');
    return (
        <form onSubmit={props.handleSubmit} className={Styles.PlaceOrder}>
            <div className={Styles.Header}>{t('plan.placeOrder')}</div>
            <div className={Styles.Line}>
                <div>
                    {t('plan.totalLicenses')}:
                </div>
                <div>
                    {props.plusPlanPayment.get('amountOfLicenses')}
                </div>
            </div>
            {props.plusPlanPayment.get('autoRenew') ? (
                <>
                    <div className={Styles.Line}>
                        <div>
                            {billingType === 'monthly'
                                ? t('plan.monthlyCharge')
                                : t('plan.yearlyCharge')}
                            :
                        </div>
                        <div>
                            {`$${totalPrice.toFixed(2)} / `}
                            {billingType === 'monthly' ? t('plan.mo') : t('plan.yr')}
                        </div>
                    </div>
                    <p className={Styles.InfoLine}>
                        {t('plan.billed')} {t(`plan.${billingType}`)}
                    </p>
                </>
            ) : (
                <div className={Styles.Line}>
                    <div>
                        {t('plan.planExpires')}:
                    </div>
                    <div>
                        {expires}
                    </div>
                </div>
            )}
            <div className={classNames(Styles.Line, Styles.LineBold)}>
                <div>
                    {t('plan.chargeNow')}:
                </div>
                <div>
                    ${totalPrice.toFixed(2)}
                </div>
            </div>
            <p className={Styles.InfoLine}>
                {t('plan.paymentMethod')}:
                <br />
                {card.brand}, {t('plan.endingIn')} {card.last4}
            </p>
            <div className={Styles.TaxesInfo}>
                {t('plan.taxesInfo')}
            </div>
            <p className={Styles.Terms}>
                <Trans
                    i18nKey="plan.termsAgree"
                    components={[
                        <span
                            className={Styles.TermsBlue}
                            onClick={() => setState({ showTerms: true })}
                        />,
                    ]}
                />
                {state.showTerms && (
                    <Modal position="top">
                        <div className={Styles.Terms__Modal}>
                            <div>
                                <div className={Styles.ButtonContainer}>
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            setState({ showTerms: false })
                                        }
                                    >
                                        {t('plan.back')}
                                    </Button>
                                </div>
                                <TermsContent />
                            </div>
                        </div>
                    </Modal>
                )}
            </p>
            <div className={Styles.Navigation}>
                <Button
                    variant="text"
                    className={Styles.backButton}
                    onClick={props.goBack}
                >
                    {t('plan.back')}
                </Button>
                <Button
                    variant="contained"
                    className={Styles.Button}
                    onClick={props.handleSubmit}
                    type="submit"
                >
                    {t('plan.placeOrder')}
                </Button>
            </div>
        </form>
    );
};
