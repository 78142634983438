// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".paper__17C-I {\n    overflow: visible;\n}\n\n.root__kcA71 strong {\n    font-weight: 400 !important;\n}\n\n.actions__11KkO {\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.mainButtons__2Q5cM {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.setAnother__1wEB- {\n    margin: 10px 0 !important;\n    color: #08a;\n    text-decoration: none;\n    font-weight: 400 !important;\n}\n\n.timeZoneSelect__faP7H {\n    width: 100%;\n    max-width: 300px;\n}\n\n@media (max-device-width: 1223px) {\n    .actions__11KkO button {\n        margin: 8px auto;\n    }\n\n    .mainButtons__2Q5cM {\n        flex-direction: column-reverse;\n    }\n\n    .setAnother__1wEB- {\n        margin: 10px auto 10px !important;\n    }\n}\n", "",{"version":3,"sources":["AdhocTimezoneModal.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,8BAA8B;IAClC;;IAEA;QACI,iCAAiC;IACrC;AACJ","file":"AdhocTimezoneModal.css","sourcesContent":[".paper {\n    overflow: visible;\n}\n\n.root strong {\n    font-weight: 400 !important;\n}\n\n.actions {\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.mainButtons {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.setAnother {\n    margin: 10px 0 !important;\n    color: #08a;\n    text-decoration: none;\n    font-weight: 400 !important;\n}\n\n.timeZoneSelect {\n    width: 100%;\n    max-width: 300px;\n}\n\n@media (max-device-width: 1223px) {\n    .actions button {\n        margin: 8px auto;\n    }\n\n    .mainButtons {\n        flex-direction: column-reverse;\n    }\n\n    .setAnother {\n        margin: 10px auto 10px !important;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"paper": "paper__17C-I",
	"root": "root__kcA71",
	"actions": "actions__11KkO",
	"mainButtons": "mainButtons__2Q5cM",
	"setAnother": "setAnother__1wEB-",
	"timeZoneSelect": "timeZoneSelect__faP7H"
};
module.exports = exports;
