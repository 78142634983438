// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ManageLicenses__1dEDB {\n    margin: 44px auto 32px;\n    width: 100%;\n    max-width: 425px;\n}\n\n.content__2c4iP {\n    padding: 0 20px;\n}\n\n.Button_Continue__1Gh5- {\n    margin: 10px 0;\n    width: 100px;\n}\n\n.Button_Continue__Container__33C-6 {\n    display: flex;\n    justify-content: flex-end;\n}\n", "",{"version":3,"sources":["ManageLicenses.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B","file":"ManageLicenses.css","sourcesContent":[".ManageLicenses {\n    margin: 44px auto 32px;\n    width: 100%;\n    max-width: 425px;\n}\n\n.content {\n    padding: 0 20px;\n}\n\n.Button_Continue {\n    margin: 10px 0;\n    width: 100px;\n}\n\n.Button_Continue__Container {\n    display: flex;\n    justify-content: flex-end;\n}\n"]}]);
// Exports
exports.locals = {
	"ManageLicenses": "ManageLicenses__1dEDB",
	"content": "content__2c4iP",
	"Button_Continue": "Button_Continue__1Gh5-",
	"Button_Continue__Container": "Button_Continue__Container__33C-6"
};
module.exports = exports;
