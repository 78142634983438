import * as React from 'react';
import { useTranslation } from 'react-i18next';
const Styles = require('./BillingListHeading.css');

export default function BillingListHeading() {
    const { t } = useTranslation();
    return (
        <li className={Styles.BillingListHeading}>
            <span>{t('plan.date')}</span>
            <span>{t('plan.payment')}</span>
            <span>{t('plan.invoice')}</span>
        </li>
    );
}
