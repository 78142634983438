// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".videoConf__1ySh8 {\n    display: flex;\n    align-items: center;\n    font-weight: inherit !important;\n    margin-right: 4px;\n}\n\n.account__PgN_O {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.providerIcon__ojHeA {\n    display: block;\n    fill: #e24b27;\n    height: 18px;\n    width: 18px;\n    margin-right: 12px;\n}\n\n.container__1mNzq {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 8px;\n}\n\n.add__1SjCQ {\n    font-weight: 400 !important;\n}\n", "",{"version":3,"sources":["VideoConfPicker.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,+BAA+B;IAC/B,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,2BAA2B;AAC/B","file":"VideoConfPicker.css","sourcesContent":[".videoConf {\n    display: flex;\n    align-items: center;\n    font-weight: inherit !important;\n    margin-right: 4px;\n}\n\n.account {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.providerIcon {\n    display: block;\n    fill: #e24b27;\n    height: 18px;\n    width: 18px;\n    margin-right: 12px;\n}\n\n.container {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 8px;\n}\n\n.add {\n    font-weight: 400 !important;\n}\n"]}]);
// Exports
exports.locals = {
	"videoConf": "videoConf__1ySh8",
	"account": "account__PgN_O",
	"providerIcon": "providerIcon__ojHeA",
	"container": "container__1mNzq",
	"add": "add__1SjCQ"
};
module.exports = exports;
