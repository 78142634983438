import React from 'react';
const styles = require('./About.css');

export default function About() {
    return (
        <div className={styles.root}>
            <div className={styles.bigText}>
                Headquartered in Palo Alto, California,
            </div>
            <div className={styles.text}>
                with offices in Gdansk, Poland, Toolla is a seed-funded company,
                started in 2015.
            </div>
            <div className={styles.text}>
                Tanbuu has been developed by top engineers with PhD's from MIT,
                Michigan, UC Berkeley, and Carnegie Mellon, holding multiple US
                patents (granted and pending) in high-tech.
            </div>
            <div className={styles.text}>
                Apart from engineers and mathematicians, Toolla has a full suite
                of marketing, sales, and cutting-edge UI designers, with full
                portfolio experience.
            </div>
            <div className={styles.bigText}>
                The team has also successfully developed, designed, implemented,
                and launched web and mobile apps for Fortune 500 companies.
            </div>
        </div>
    );
}
