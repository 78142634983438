import * as React from 'react';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import IActions from 'actions/IActions';
import PlusPlanPayment from '../../components/PlusPlanPayment/Payment/PlusPlanPayment';
import { WANTS_TO_BUY_TANBUU } from '../../constants/appConsts';
import ILocation from '../../interfaces/location/ILocation';
import Header from '../../components/_ReusableComponents/Header/Header/Header';

const connect = require('react-redux').connect;

interface IPlusPlanPaymentProps {
    plusPlanPayment: any;
    data: any;
    actions: IActions;
    location: ILocation;
    history: any;
}

class PlusPlanPaymentPage extends React.Component<IPlusPlanPaymentProps, null> {
    componentDidMount() {
        localStorage.removeItem(WANTS_TO_BUY_TANBUU);
    }

    render() {
        return (
            <div>
                <Header {...this.props} />
                <PlusPlanPayment {...this.props} />
            </div>
        );
    }
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(PlusPlanPaymentPage);
