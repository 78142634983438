import actions from '../actions/Actions';
import {Map} from 'immutable';
import {bindActionCreators} from 'redux';

export default function mapDispatchToProps(dispatch): { actions: any; dispatch: any; } {

    const getActionsFunctions = function (actions2: any) {

        let actionsFns = {};
        actions2.forEach((action) => {
            const object = Object.getOwnPropertyNames(action.prototype);
            for (let prop in object) {
                const functionName = object[prop];
                actionsFns[functionName] =  action.prototype[functionName];
            }
        });

        return actionsFns;
    };

    let actionsObject = Map()
        .merge(getActionsFunctions(actions))
        .toObject();



    function getObject(actionsObjectParam: any) {
        return actionsObjectParam;
    }

    return {
        actions: bindActionCreators(getObject(actionsObject), dispatch),
        dispatch
    };
}
