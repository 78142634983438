// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".content__J-buk {\n    margin-top: 44px;\n    height: calc(100vh - 44px);\n}\n\n.contentNoHeader__1o24a {\n    margin-top: 0;\n    height: 100vh;\n}\n", "",{"version":3,"sources":["SmallBusiness.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,aAAa;AACjB","file":"SmallBusiness.css","sourcesContent":[".content {\n    margin-top: 44px;\n    height: calc(100vh - 44px);\n}\n\n.contentNoHeader {\n    margin-top: 0;\n    height: 100vh;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "content__J-buk",
	"contentNoHeader": "contentNoHeader__1o24a"
};
module.exports = exports;
