import * as React from 'react';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import IActions from 'actions/IActions';
import ManageGroup from '../../components/ManageGroupComponents/ManageGroup/ManageGroup';
import ILocation from '../../interfaces/location/ILocation';
import Header from '../../components/_ReusableComponents/Header/Header/Header';
const connect = require('react-redux').connect;

interface IProps {
    data: any;
    actions: IActions;
    location: ILocation;
    history: any;
}

function ManageGroupPage(props: IProps) {
    return (
        <div>
            <Header />
            <ManageGroup {...props} />
        </div>
    );
}


export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(ManageGroupPage);
