// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MyWorkWeekTab__2YEHh {\n    display: block;\n    position: relative;\n    width: 100%;\n    z-index: 1;\n}\n", "",{"version":3,"sources":["MyWorkWeekTab.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd","file":"MyWorkWeekTab.css","sourcesContent":[".MyWorkWeekTab {\n    display: block;\n    position: relative;\n    width: 100%;\n    z-index: 1;\n}\n"]}]);
// Exports
exports.locals = {
	"MyWorkWeekTab": "MyWorkWeekTab__2YEHh"
};
module.exports = exports;
