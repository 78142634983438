// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".NotesIcon__1XQ5n {\n    cursor: pointer;\n    position: absolute;\n    left: 65px;\n    display: flex;\n}\n\n    @media (min-width: 1250px) {.NotesIcon__1XQ5n {\n        position: relative;\n        left: auto;\n        left: initial\n}\n    }\n\n.Icon__2uiEL {\n    font-size: 32px !important;\n    height: 39px;\n    width: 36px;\n    z-index: 1;\n    padding: 8px;\n}\n\n.Icon__Count__2ZrKD {\n    background-color: #e76a5c;\n    border-radius: 21px;\n    color: white;\n    font-size: 12px;\n    font-weight: bold;\n    left: 27px;\n    padding: 0 4px 1px 4px;\n    position: relative;\n    top: -23px;\n    transition: 0.3s all ease-out;\n    z-index: 7;\n}\n", "",{"version":3,"sources":["NotesIcon.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,UAAU;IACV,aAAa;AAMjB;;IAJI,4BANJ;QAOQ,kBAAkB;QAClB,UAAa;QAAb;AAER;IADI;;AAGJ;IACI,0BAA0B;IAC1B,YAAY;IACZ,WAAW;IACX,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,UAAU;IACV,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;IACV,6BAA6B;IAC7B,UAAU;AACd","file":"NotesIcon.css","sourcesContent":[".NotesIcon {\n    cursor: pointer;\n    position: absolute;\n    left: 65px;\n    display: flex;\n\n    @media (min-width: 1250px) {\n        position: relative;\n        left: initial;\n    }\n}\n\n.Icon {\n    font-size: 32px !important;\n    height: 39px;\n    width: 36px;\n    z-index: 1;\n    padding: 8px;\n}\n\n.Icon__Count {\n    background-color: #e76a5c;\n    border-radius: 21px;\n    color: white;\n    font-size: 12px;\n    font-weight: bold;\n    left: 27px;\n    padding: 0 4px 1px 4px;\n    position: relative;\n    top: -23px;\n    transition: 0.3s all ease-out;\n    z-index: 7;\n}\n"]}]);
// Exports
exports.locals = {
	"NotesIcon": "NotesIcon__1XQ5n",
	"Icon": "Icon__2uiEL",
	"Icon__Count": "Icon__Count__2ZrKD"
};
module.exports = exports;
