// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__1u4ub {\n    margin-bottom: 16px;\n}\n\n.label__4jWl6 {\n    margin: 0 0 4px 8px;\n}\n\n.selects__3WYyY {\n    display: flex;\n    align-items: center;\n}\n\n.select__3hjQx {\n    width: 75px;\n}\n\n.hoursLabel__19NpD {\n    margin: 0 16px 0 8px;\n}\n\n.minutesLabel__3iV1D {\n    margin-left: 8px;\n}\n\n.error__20BG- {\n    margin-left: 8px;\n}\n", "",{"version":3,"sources":["DurationPicker.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","file":"DurationPicker.css","sourcesContent":[".root {\n    margin-bottom: 16px;\n}\n\n.label {\n    margin: 0 0 4px 8px;\n}\n\n.selects {\n    display: flex;\n    align-items: center;\n}\n\n.select {\n    width: 75px;\n}\n\n.hoursLabel {\n    margin: 0 16px 0 8px;\n}\n\n.minutesLabel {\n    margin-left: 8px;\n}\n\n.error {\n    margin-left: 8px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__1u4ub",
	"label": "label__4jWl6",
	"selects": "selects__3WYyY",
	"select": "select__3hjQx",
	"hoursLabel": "hoursLabel__19NpD",
	"minutesLabel": "minutesLabel__3iV1D",
	"error": "error__20BG-"
};
module.exports = exports;
