import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/_ReusableComponents/Header/Header/Header';
import GlobalContext from '../../GlobalContext';
const styles = require('./PlusPlanInfo.css');

function PlusPlanInfo() {
    const { t } = useTranslation();
    const { profile: { plusPlan } } = useContext(GlobalContext);
    const name = (() => {
        if ('licensor' in plusPlan) {
            return plusPlan.licensor.name
                ? `${plusPlan.licensor.name} (${plusPlan.licensor.email})`
                : plusPlan.licensor.email;
        } else {
            return plusPlan.organization;
        }
    })();
    return (
        <div>
            <Header />
            <div className={styles.root}>
                <div className={styles.header}>
                    {t('plan.plusPlanInfo')}
                </div>
                {plusPlan.logo && (
                    <img src={plusPlan.logo} alt="" className={styles.logo} />
                )}
                <div>
                    {t('plan.subscriptionManagedBy', { name })}
                </div>
            </div>
        </div>
    );
}

export default PlusPlanInfo;
