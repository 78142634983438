// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".button__3o_Li {\n    margin: 0 -20px -12px 0;\n}\n\n.icon__1nxqf {\n    height: 22px;\n    width: 22px;\n}\n\n.dialog__sOZD8 {\n    margin: 32px;\n    padding: 24px;\n}\n\n.dialogHeader__-JTDr {\n    font-size: 20px;\n    margin-bottom: 16px;\n    text-align: center;\n}\n\n@media (min-width: 1250px) {\n\n.dialogHeader__-JTDr {\n        text-align: left\n}\n    }\n\n.dialogRow__28tjZ {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.dialogName__18ZgT {\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.dialogButton__dvS47 {\n    margin-right: -12px;\n}\n", "",{"version":3,"sources":["MeetingOptions.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AAKtB;;AAHI;;AALJ;QAMQ;AAER;IADI;;AAGJ;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB","file":"MeetingOptions.css","sourcesContent":[".button {\n    margin: 0 -20px -12px 0;\n}\n\n.icon {\n    height: 22px;\n    width: 22px;\n}\n\n.dialog {\n    margin: 32px;\n    padding: 24px;\n}\n\n.dialogHeader {\n    font-size: 20px;\n    margin-bottom: 16px;\n    text-align: center;\n\n    @media (min-width: 1250px) {\n        text-align: left;\n    }\n}\n\n.dialogRow {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.dialogName {\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.dialogButton {\n    margin-right: -12px;\n}\n"]}]);
// Exports
exports.locals = {
	"button": "button__3o_Li",
	"icon": "icon__1nxqf",
	"dialog": "dialog__sOZD8",
	"dialogHeader": "dialogHeader__-JTDr",
	"dialogRow": "dialogRow__28tjZ",
	"dialogName": "dialogName__18ZgT",
	"dialogButton": "dialogButton__dvS47"
};
module.exports = exports;
