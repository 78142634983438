import * as React from 'react';
import * as anime from 'animejs';
import classNames from 'classnames';
import Playback from '../Playback/Playback';
import { Trans } from 'react-i18next';

const Styles = require('./LeftAnimation.css');
const Speeches = require('./Speeches.css');

interface ILeftAnimationStates {
    animating?: boolean;
    completed?: boolean;
}

export default class LeftAnimation extends React.Component<{},
    ILeftAnimationStates> {
    animation: any;

    constructor(props) {
        super(props);
        this.state = {
            animating: false,
            completed: false
        };
        this.animation = null;
    }

    componentDidMount() {
        this.animation = this.createAnimation();
    }

    componentWillUnmount() {
        this.animation = null;
    }

    createAnimation = () => {
        const showSettings = {
            duration: 1500,
            easing: 'easeInOutSine'
        };

        const timeline = anime
            .timeline({
                loop: false,
                autoplay: false
            })
            .add({
                targets: '#imagesContainer img',
                translateY: '-=1400',
                rotate: 0.01,
                elasticity: 0,
                easing: 'linear',
                complete: () => {
                    this.setState({
                        animating: false,
                        completed: true
                    });
                },
                duration: 70000
            })
            .add({
                targets: '#textContainer',
                translateY: '-=1800',
                rotate: 0.01,
                elasticity: 0,
                easing: 'linear',
                offset: '-=70000',
                duration: 70000
            })
            .add({
                targets: '#HiDave',
                opacity: 0,
                ...showSettings,
                offset: '-=65500' // relative to the end of previous animation because it has better performance than absolute offset
            })
            .add({
                targets: '#SureRay',
                opacity: 0,
                ...showSettings,
                offset: '-=61000'
            })
            .add({
                targets: '#ThanksDave',
                opacity: [{ value: 1 }, { delay: 10000, value: 0 }],
                ...showSettings,
                offset: '-=65000'
            })
            .add({
                targets: '#OkWill',
                opacity: [{ value: 1 }, { delay: 9500, value: 0 }],
                ...showSettings,
                offset: '-=61000'
            })
            .add({
                targets: '#HelloSuzuki',
                opacity: [{ value: 1 }, { delay: 10500, value: 0 }],
                ...showSettings,
                offset: '-=58000'
            })
            .add({
                targets: '#HiDaveSan',
                opacity: [{ value: 1 }, { delay: 12000, value: 0 }],
                ...showSettings,
                offset: '-=53000'
            })
            .add({
                targets: '#HeyRajesh',
                opacity: [{ value: 1 }, { delay: 12000, value: 0 }],
                ...showSettings,
                offset: '-=48500'
            })
            .add({
                targets: '#DaveOk',
                opacity: [{ value: 1 }, { delay: 12000, value: 0 }],
                ...showSettings,
                offset: '-=41500'
            })
            .add({
                targets: '#SoundsGood',
                opacity: [{ value: 1 }, { delay: 9000, value: 0 }],
                ...showSettings,
                offset: '-=35500'
            })
            .add({
                targets: '#HelloSuzukiSan',
                opacity: [{ value: 1 }, { delay: 10500, value: 0 }],
                ...showSettings,
                offset: '-=31000'
            })
            .add({
                targets: '#DearDaveSan',
                opacity: [{ value: 1 }, { delay: 9500, value: 0 }],
                ...showSettings,
                offset: '-=25500'
            })
            .add({
                targets: '#DearSuzukiSanThat',
                opacity: [{ value: 1 }, { delay: 10500, value: 0 }],
                ...showSettings,
                offset: '-=22000'
            })
            .add({
                targets: '#DearSuzukiSanWhat',
                opacity: [{ value: 1 }, { delay: 11000, value: 0 }],
                ...showSettings,
                offset: '-=18000'
            })
            .add({
                targets: '#DearDaveSanAfter',
                opacity: [{ value: 1 }, { delay: 11000, value: 0 }],
                ...showSettings,
                offset: '-=14000'
            })
            .add({
                targets: '#HiRajesh',
                opacity: 1,
                ...showSettings,
                offset: '-=9000'
            })
            .add({
                targets: '#DaveI',
                opacity: 1,
                ...showSettings,
                offset: '-=6500'
            })
            .add({
                targets: '#SorryRajesh',
                opacity: 1,
                ...showSettings,
                offset: '-=3000'
            })
            .add({
                targets: '#boom',
                opacity: 1,
                duration: 3000,
                easing: 'easeInOutSine',
                offset: '-=2000'
            });

        return timeline;
    };

    pauseAnimation = () => {
        this.setState({ animating: false }, () => {
            this.animation.pause();
        });
    };

    playAnimation = () => {
        this.setState({ animating: true }, () => {
            this.animation.play();
        });
    };

    restartAnimation = () => {
        this.setState({ animating: true, completed: false }, () => {
            this.animation.restart();
        });
    };

    renderText(key: string) {
        return (
            <Trans
                i18nKey={key}
                components={{ div: <div /> }}
            />
        );
    }

    render(): JSX.Element {
        const { renderText } = this;
        return (
            <div className={Styles.Container}>
                <div className={Styles.OuterContainer}>
                    <div
                        className={Styles.InnerContainer}
                        onClick={this.pauseAnimation}
                    >
                        <div
                            id="imagesContainer"
                            className={Styles.ImageContainer}
                        >
                            <img src="/assets/landingAnimation/figure_1.svg" alt="" />
                            <img src="/assets/landingAnimation/figure_2.svg" alt="" />
                            <img src="/assets/landingAnimation/figure_3.svg" alt="" />
                            <img src="/assets/landingAnimation/figure_4.svg" alt="" />
                            <img src="/assets/landingAnimation/figure_5.svg" alt="" />
                            <img src="/assets/landingAnimation/figure_1.svg" alt="" />
                            <img src="/assets/landingAnimation/figure_2.svg" alt="" />
                            <img src="/assets/landingAnimation/figure_3.svg" alt="" />
                            <img src="/assets/landingAnimation/figure_4.svg" alt="" />
                            <img src="/assets/landingAnimation/figure_5.svg" alt="" />
                            <img src="/assets/landingAnimation/hellop.svg" alt="" id="boom" />
                        </div>
                        <div
                            id="textContainer"
                            className={Styles.TextContainer}
                        >
                            <div className={Speeches.HiDave} id="HiDave">
                                <img src="/assets/landingAnimation/dialog1.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.hiDave')}
                                </div>
                            </div>
                            <div className={Speeches.SureRay} id="SureRay">
                                <img src="/assets/landingAnimation/dialog2.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.sureRay')}
                                </div>
                            </div>
                            <div className={Speeches.ThanksDave} id="ThanksDave" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog3.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.thanksDave')}
                                </div>
                            </div>
                            <div className={Speeches.OkWill} id="OkWill" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog4.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.okWill')}
                                </div>
                            </div>
                            <div className={Speeches.HelloSuzuki} id="HelloSuzuki" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog5.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.helloSuzuki')}
                                </div>
                            </div>
                            <div className={Speeches.HiDaveSan} id="HiDaveSan" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog6.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.hiDaveSan')}
                                </div>
                            </div>
                            <div className={Speeches.HeyRajesh} id="HeyRajesh" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog7.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.heyRajesh')}
                                </div>
                            </div>
                            <div className={Speeches.DaveOk} id="DaveOk" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog8.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.daveOk')}
                                </div>
                            </div>
                            <div className={Speeches.SoundsGood} id="SoundsGood" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog9.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.soundsGood')}
                                </div>
                            </div>
                            <div className={Speeches.HelloSuzukiSan} id="HelloSuzukiSan" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog10.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.helloSuzukiSan')}
                                </div>
                            </div>
                            <div className={Speeches.DearDaveSan} id="DearDaveSan" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog6.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.dearDaveSan')}
                                </div>
                            </div>
                            <div
                                className={Speeches.DearSuzukiSanThat}
                                id="DearSuzukiSanThat"
                                style={{ opacity: 0 }}
                            >
                                <img src="/assets/landingAnimation/dialog11.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.dearSuzukiSanThat')}
                                </div>
                            </div>
                            <div
                                className={Speeches.DearSuzukiSanWhat}
                                id="DearSuzukiSanWhat"
                                style={{ opacity: 0 }}
                            >
                                <img src="/assets/landingAnimation/dialog12.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.dearSuzukiSanWhat')}
                                </div>
                            </div>
                            <div className={Speeches.DearDaveSanAfter} id="DearDaveSanAfter" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog14.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.dearDaveSanAfter')}
                                </div>
                            </div>
                            <div className={Speeches.HiRajesh} id="HiRajesh" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog1.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.hiRajesh')}
                                </div>
                            </div>
                            <div className={Speeches.DaveI} id="DaveI" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog15.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.daveI')}
                                </div>
                            </div>
                            <div className={Speeches.SorryRajesh} id="SorryRajesh" style={{ opacity: 0 }}>
                                <img src="/assets/landingAnimation/dialog16.svg" alt="" />
                                <div
                                    className={classNames(
                                        Styles.SpeechContent,
                                        'text'
                                    )}
                                >
                                    {renderText('landing.sorryRajesh')}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.animating !== true && this.state.completed === false && (
                        <Playback onClick={this.playAnimation} iconType="play" />
                    )}
                    {this.state.animating !== true && this.state.completed === true && (
                        <Playback onClick={this.restartAnimation} iconType="restart" />
                    )}
                </div>
            </div>
        );
    }
}
