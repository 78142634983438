import * as React from 'react';
import Auth from 'auth/Auth';

interface Props {
    text?: string;
}
export default class MicrosoftSignInButton extends React.Component<
    Props,
    null
> {
    render() {
        const text = this.props.text;
        return (
            <React.Fragment>
                <button
                    className="microsoft-button"
                    onClick={Auth.microsoftSignIn}
                >
                    <img
                        className="microsoft-logo"
                        src="/assets/microsoft_logo.png"
                    />
                    <span>{text}</span>
                </button>
                <style>{`
                    .microsoft-button{
                        background-color: #FFFFFF;
                        border: 1px solid #013253;
                        border-radius: 5px;
                        color: #757575;
                        cursor: pointer;
                        display: inline-block;
                        font-weight: 500;
                        font-size:16px;
                        height: 42px;
                        letter-spacing: 0;
                        margin: 5px 0;
                        min-width: 64px;
                        outline: none;
                        line-height:normal;
                        padding: 0 12px;
                        position: relative;
                        text-align: left;
                        text-decoration: none;
                        transition: all 0.3s ease-out;
                        vertical-align: middle;
                        width: 100%;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .microsoft-button:hover{
                        background-color: #013253;
                        border: 1px solid #08a;
                        color: #FFFFFF;
                    }
                    .microsoft-logo{
                        vertical-align: middle;
                        padding-right: 12px;
                    }
                    .microsoft-button span{
                        font-weight: 500;
                    }
                `}</style>
            </React.Fragment>
        );
    }
}
