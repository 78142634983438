// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".checkboxContainer__2eulk {\n    margin: 4px 0;\n    align-items: flex-start;\n    display: flex;\n}\n\n.checkbox__scYNY {\n    margin: -4px 0;\n}\n\n.label__3w61Z {\n    padding: 4px 0;\n}\n\n.customMenuItem__2Xglw {\n    display: none;\n}\n\n.suggestionItem__OaGLT {\n    color: white;\n    background-color: #043352;\n    font-size: 11px;\n    margin-left: 10px;\n    border-radius: 2px;\n    padding: 0 6px 3px;\n}\n", "",{"version":3,"sources":["DateRangePicker.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB","file":"DateRangePicker.css","sourcesContent":[".checkboxContainer {\n    margin: 4px 0;\n    align-items: flex-start;\n    display: flex;\n}\n\n.checkbox {\n    margin: -4px 0;\n}\n\n.label {\n    padding: 4px 0;\n}\n\n.customMenuItem {\n    display: none;\n}\n\n.suggestionItem {\n    color: white;\n    background-color: #043352;\n    font-size: 11px;\n    margin-left: 10px;\n    border-radius: 2px;\n    padding: 0 6px 3px;\n}\n"]}]);
// Exports
exports.locals = {
	"checkboxContainer": "checkboxContainer__2eulk",
	"checkbox": "checkbox__scYNY",
	"label": "label__3w61Z",
	"customMenuItem": "customMenuItem__2Xglw",
	"suggestionItem": "suggestionItem__OaGLT"
};
module.exports = exports;
