// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".BillingListHeading__3W-Mo {\n    display: flex;\n    margin-bottom: 15px;\n}\n\n.BillingListHeading__3W-Mo span {\n    text-align: center;\n    width: 100%;\n    font-weight: 400 !important;\n}\n\n.BillingListHeading__3W-Mo span:nth-child(1) {\n    width: 40%;\n    text-align: left;\n}\n\n.BillingListHeading__3W-Mo span:nth-child(2) {\n    width: 30%;\n    text-align: right;\n}\n\n.BillingListHeading__3W-Mo span:nth-child(3) {\n    width: 30%;\n}\n", "",{"version":3,"sources":["BillingListHeading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AACA;IACI,UAAU;IACV,iBAAiB;AACrB;;AACA;IACI,UAAU;AACd","file":"BillingListHeading.css","sourcesContent":[".BillingListHeading {\n    display: flex;\n    margin-bottom: 15px;\n}\n\n.BillingListHeading span {\n    text-align: center;\n    width: 100%;\n    font-weight: 400 !important;\n}\n\n.BillingListHeading span:nth-child(1) {\n    width: 40%;\n    text-align: left;\n}\n.BillingListHeading span:nth-child(2) {\n    width: 30%;\n    text-align: right;\n}\n.BillingListHeading span:nth-child(3) {\n    width: 30%;\n}\n"]}]);
// Exports
exports.locals = {
	"BillingListHeading": "BillingListHeading__3W-Mo"
};
module.exports = exports;
