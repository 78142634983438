import * as React from 'react';
import IActions from 'actions/IActions';
import AvailableAmount from './AvailableAmount/AvailableAmount';
import { useTranslation } from 'react-i18next';
const Styles = require('./AvailableLicenses.css');

interface IAvailableLicensesProps {
    amountOfLicensesOwned: number;
    amountOfLicensesUnassigned: number;
    expiringLicenses: number;
    manageLicenses: any;
    data: any;
    actions: IActions;
}

export default function AvailableLicenses(props: IAvailableLicensesProps) {
    const { t } = useTranslation();
    const { amountOfLicensesOwned, amountOfLicensesUnassigned } = props;
    return (
        <section className={Styles.AvailableLicenses}>
            <AvailableAmount
                {...props}
                label={t('plan.allLicenses')}
                amount={amountOfLicensesOwned}
            />
            <AvailableAmount
                {...props}
                label={t('plan.unassignedLicenses')}
                amount={amountOfLicensesUnassigned}
            />
            {props.expiringLicenses > 0 && (
                <AvailableAmount
                    {...props}
                    label={t('plan.expiringLicenses')}
                    amount={props.expiringLicenses}
                />
            )}
        </section>
    );
}
