// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".body__3INvr {\n    margin: 0;\n    display: flex;\n    justify-content: center;\n}\n\n.content__34GqR {\n    width: 100%;\n}\n", "",{"version":3,"sources":["LandingPage.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf","file":"LandingPage.css","sourcesContent":[".body {\n    margin: 0;\n    display: flex;\n    justify-content: center;\n}\n\n.content {\n    width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"body": "body__3INvr",
	"content": "content__34GqR"
};
module.exports = exports;
