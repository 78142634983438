import React, { useContext, useEffect, useRef, useState } from 'react';
import containersDispatchToProps from '../../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../../utils/containersMapStateToProps';
import IActions from 'actions/IActions';
import Login from '../Login/Login';
import GoogleSignInButton from '../Login/GoogleButton/GoogleSignInButton';
import MicrosoftSignInButton from '../Login/MicrosoftSignInButton';
import OuterLoginBg from '../../_ReusableComponents/OuterLoginBg/OuterLoginBg';
import EnterpriseLogo from '../../_ReusableComponents/EnterpriseLogo/EnterpriseLogo';
import LoginFooter from '../../_ReusableComponents/Footer/LoginFooter/LoginFooter';
import YoutubeButton from '../../../components/_ReusableComponents/Buttons/YoutubeButton/YoutubeButton';
import Auth from 'auth/Auth';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { getSearchParam } from 'scripts/helpers';
import FallingLeaves from 'components/_ReusableComponents/FallingLeaves/FallingLeaves';
import Button from '../../_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
import BackendMethods from '../../../api/BackendMethods';
import { redirectToSubdomain } from '../../../utils/subdomain';
import GlobalContext from '../../../GlobalContext';
import { fetchProfile } from '../../../actions/DataActions';
import cookies from 'js-cookie';
const config = require('CONFIG');

const connect = require('react-redux').connect;
const styles = require('./LoginPartial.css');

interface ILoginPartialProps {
    actions: IActions;
    data: any;
}

function LoginPartial(props: ILoginPartialProps) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { profile, updateContext } = useContext(GlobalContext);
    const [loading, setLoading] = useState(
        location.hash.includes('access_token') || location.search.includes('id_token'),
    );
    const [resendActivationLink, setResendActivationLink] = useState(false);
    const [loginState, setLoginState] = useState<'initial' | 'success'>('initial');
    useEffect(() => {
        window.scrollTo(0, 0);
        const redirect = getSearchParam(location.search, 'redirectURI');
        if (redirect) {
            sessionStorage.setItem('redirectURI', redirect);
        }
        Auth.handleAuth(
            async () => {
                const mergeAuthToken = cookies.get('bookingAddPhoneToken') || localStorage.getItem('mergeAuthToken');
                if (mergeAuthToken) {
                    try {
                        await BackendMethods.mergeProfiles(mergeAuthToken);
                    } catch {
                        props.actions.showErrorMessage(t('auth.accountMergeFailed'));
                        localStorage.removeItem('mergeRedirectURI');
                        localStorage.removeItem('mergeAuthToken');
                    }
                }
                let profilePromise = fetchProfile(i18n);
                await props.actions.dbConnect(profilePromise);
                const newProfile = await profilePromise;
                updateContext((x) => ({ ...x, profile: newProfile }));
                setLoginState('success');
                setLoading(false);
                props.actions.pushConnect(newProfile.email, updateContext);
            },
            () => setLoading(false),
            () => {
                setResendActivationLink(true);
                setLoading(false);
            },
        );
    }, []);
    const redirected = useRef(false);
    if (loginState === 'success' && !redirected.current) {
        const { appointments, phone } = profile;
        const redirect = cookies.get('bookingAddPhoneRedirect')
            || localStorage.getItem('mergeRedirectURI')
            || sessionStorage.getItem('redirectURI')
            || '';
        sessionStorage.removeItem('redirectURI');
        localStorage.removeItem('mergeRedirectURI');
        localStorage.removeItem('mergeAuthToken');
        const { hostname } = window.location;
        const domain = hostname.endsWith(config.baseDomain) ? config.baseDomain : hostname;
        cookies.remove('bookingAddPhoneRedirect', { domain });
        redirected.current = true;
        if (redirect.startsWith('https://')) {
            window.location.assign(redirect);
            return null;
        }
        if (appointments.companyId && window.location.host === config.baseDomain) {
            if (appointments.companySubdomain) {
                redirectToSubdomain(appointments.companySubdomain, redirect || '/sb/dashboard');
            }
            return null;
        }
        return <Redirect to={redirect || (phone ? '/sb-customer/my-appointments' : '/main')} />;
    }
    if (loading) {
        return <FallingLeaves />;
    }
    return (
        <div className={styles.root}>
            <YoutubeButton />
            <OuterLoginBg />
            <div className={styles.LoginBackground}>
                <div id="LoginPage" className={styles.LoginPage}>
                    <EnterpriseLogo logoSrc={props.data.get('logoSrc')} />
                    <GoogleSignInButton text={t('auth.signInWithGoogle')} />
                    <MicrosoftSignInButton text={t('auth.signInWithMicrosoft')} />
                    <div className={styles.CenterLabel}>{t('auth.or')}</div>
                    <Button
                        className={styles.phoneButton}
                        fullWidth
                        component={Link}
                        to="/login-phone"
                    >
                        <img src="/assets/phone.svg" alt="" className={styles.buttonIcon} />
                        {t('auth.signInWithPhone')}
                    </Button>
                    <div className={styles.CenterLabel}>{t('auth.or')}</div>
                    <Login
                        {...props}
                        resendActivationLink={resendActivationLink}
                        setActivationLinkToSent={() => setResendActivationLink(false)}
                    />
                    <div className={styles.LoginFooter}>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(LoginPartial);
