import IProfile from 'interfaces/IProfile';
import BackendMethods from 'api/BackendMethods';
import { ISingleTypeAction, IMultipleTypesAction } from 'interfaces/IAction';
import { IWorkHours } from '../interfaces/interfaces';

export const TOGGLE_MY_CALENDARS = 'TOGGLE_MY_CALENDARS';
export const TOGGLE_MY_CONTACTS = 'TOGGLE_MY_CONTACTS';
export const TOGGLE_VIDEO_CONFS = 'TOGGLE_VIDEO_CONFS';
export const TOGGLE_MY_WORK_WEEK = 'TOGGLE_MY_WORK_WEEK';
export const SET_PREFERENCES_CALENDARS = 'SET_PREFERENCES_CALENDARS';
export const TOGGLE_WORK_DAY = 'TOGGLE_WORK_DAY';
export const PUT_MY_WORK_WEEK_PENDING = 'PUT_MY_WORK_WEEK_PENDING';
export const PUT_MY_WORK_WEEK_FULFILLED = 'PUT_MY_WORK_WEEK_FULFILLED';
export const PUT_MY_WORK_WEEK_REJECTED = 'PUT_MY_WORK_WEEK_REJECTED';
export const SET_CURRENT_TIMEZONE = 'SET_CURRENT_TIMEZONE';
export const SET_TIME_FORMAT = 'SET_TIME_FORMAT';
export const TOGGLE_HOURS_INPUTS = 'TOGGLE_HOURS_INPUTS';
export const SET_HOUR_INPUTS_VISIBLE_STATE = 'SET_HOUR_INPUTS_VISIBLE_STATE';
export const VALIDATE_PREFERENCES = 'VALIDATE_PREFERENCES';
export const DO_NOT_SHOW_ERROR_MESSAGE = 'DO_NOT_SHOW_ERROR_MESSAGE';
export const TOGGLE_MY_PERSONAL_INFORMATION = 'TOGGLE_MY_PERSONAL_INFORMATION';

export const UPDATE_USER_PROFILE_PENDING = 'UPDATE_USER_PROFILE_PENDING';
export const UPDATE_USER_PROFILE_FULFILLED = 'UPDATE_USER_PROFILE_FULFILLED';
export const UPDATE_USER_PROFILE_REJECTED = 'UPDATE_USER_PROFILE_REJECTED';

export const HIDE_SUBMIT_BUTTON = 'HIDE_SUBMIT_BUTTON';
export const SAVE_WORK_WEEK_HOURS = 'SAVE_WORK_WEEK_HOURS';
export const REMOVE_WORK_WEEK_HOURS = 'REMOVE_WORK_WEEK_HOURS';

export default class PreferencesActions {
    setPreferences(profile: IProfile, workWeek: IWorkHours[]): ISingleTypeAction {
        return {
            type: SET_PREFERENCES_CALENDARS,
            payload: { profile, workWeek },
        };
    }

    toggleIntegrations(): ISingleTypeAction {
        return {
            type: TOGGLE_MY_CALENDARS,
            payload: {}
        };
    }

    toggleMyContacts(): ISingleTypeAction {
        return {
            type: TOGGLE_MY_CONTACTS,
            payload: {}
        };
    }

    toggleVideoConfs(): ISingleTypeAction {
        return {
            type: TOGGLE_VIDEO_CONFS,
            payload: {}
        };
    }

    toggleMyWorkWeek(): ISingleTypeAction {
        return {
            type: TOGGLE_MY_WORK_WEEK,
            payload: {}
        };
    }

    toggleWorkDay(workDay: string): ISingleTypeAction {
        return {
            type: TOGGLE_WORK_DAY,
            payload: workDay
        };
    }

    putMyWorkWeek(myWorkWeek: IWorkHours[]): IMultipleTypesAction {
        return {
            types: [
                PUT_MY_WORK_WEEK_PENDING,
                PUT_MY_WORK_WEEK_FULFILLED,
                PUT_MY_WORK_WEEK_REJECTED
            ],
            payload: {
                promise: BackendMethods.putMyWorkWeek(myWorkWeek)
            }
        };
    }

    setCurrentTimezone(timezone: string): ISingleTypeAction {
        return {
            type: SET_CURRENT_TIMEZONE,
            payload: timezone
        };
    }

    setTimeFormat(timeFormat: string): ISingleTypeAction {
        return {
            type: SET_TIME_FORMAT,
            payload: timeFormat
        };
    }

    updateUserProfile(profile: IProfile): IMultipleTypesAction {
        return {
            types: [
                UPDATE_USER_PROFILE_PENDING,
                UPDATE_USER_PROFILE_FULFILLED,
                UPDATE_USER_PROFILE_REJECTED
            ],
            payload: {
                promise: BackendMethods.updateUserProfile(profile)
            }
        };
    }

    toggleHoursInputs(): ISingleTypeAction {
        return {
            type: TOGGLE_HOURS_INPUTS,
            payload: {}
        };
    }

    setHourInputsVisibleState(flag: boolean): ISingleTypeAction {
        return {
            type: SET_HOUR_INPUTS_VISIBLE_STATE,
            payload: flag
        };
    }

    doNotShowErrorMessage(): ISingleTypeAction {
        return {
            type: DO_NOT_SHOW_ERROR_MESSAGE,
            payload: {}
        };
    }

    toggleMyPersonalInformation(): ISingleTypeAction {
        return {
            type: TOGGLE_MY_PERSONAL_INFORMATION
        };
    }

    hideSubmitButton(): ISingleTypeAction {
        return {
            type: HIDE_SUBMIT_BUTTON,
            payload: {}
        };
    }

    saveWorkWeekHours(slot: IWorkHours): ISingleTypeAction {
        return {
            type: SAVE_WORK_WEEK_HOURS,
            payload: slot
        };
    }

    deleteWorkWeekHours(id: number): ISingleTypeAction {
        return {
            type: REMOVE_WORK_WEEK_HOURS,
            payload: id
        };
    }
}
