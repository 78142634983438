import { IPrice } from './Settings/Services';
import React from 'react';

export const renderPrice = (price: IPrice) => {
    if (price.min || price.max) {
        return (
            <>
                {price.currency === 'EUR' && '€'}
                {price.currency === 'USD' && '$'}
                {price.min === price.max
                    ? price.min
                    : `${price.min}-${price.max}`}
                {price.currency === 'PLN' && ' zł'}
            </>
        );
    }
    return null;
};
