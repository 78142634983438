import React from 'react';
import Styles from './MainFeatures.css';
import { useTranslation } from 'react-i18next';

export default function MainFeatures() {
    const { t } = useTranslation();
    return (
        <>
            <div className={Styles.sectionHeader} id="features">
                {t('small.keyTanbuuFeatures')}
            </div>
            <div className={Styles.features}>
                <div className={Styles.feature}>
                    <div className={Styles.featureImageContainer}>
                        <img src="/assets/sbLandingPage/mainFeature_1.svg" alt="" />
                    </div>
                    <div className={Styles.featureDescription}>
                        {t('small.allowsYourCustomersToSelf')}
                    </div>
                </div>
                <div className={Styles.feature}>
                    <div className={Styles.featureImageContainer}>
                        <img src="/assets/sbLandingPage/mainFeature_2.svg" alt="" />
                    </div>
                    <div className={Styles.featureDescription}>
                        {t('small.automaticallySendsSMS')}
                    </div>
                </div>
                <div className={Styles.feature}>
                    <div className={Styles.featureImageContainer}>
                        <img src="/assets/sbLandingPage/mainFeature_3.svg" alt="" />
                    </div>
                    <div className={Styles.featureDescription}>
                        {t('small.allowsYouToEffortlesslyHandle')}
                    </div>
                </div>
                <div className={Styles.feature}>
                    <div className={Styles.featureImageContainer}>
                        <img src="/assets/sbLandingPage/mainFeature_4.svg" alt="" />
                    </div>
                    <div className={Styles.featureDescription}>
                        {t('small.findsTheNearestAvailableDate')}
                    </div>
                </div>
                <div className={Styles.feature}>
                    <div className={Styles.featureImageContainer}>
                        <img src="/assets/sbLandingPage/mainFeature_5.svg" alt="" />
                    </div>
                    <div className={Styles.featureDescription}>
                        {t('small.keepsYourCalendarUpToDateAndAvailable')}
                    </div>
                </div>
            </div>
        </>
    );
}
