import React, { useEffect } from 'react';
import Slider from 'react-slick';
import Styles from './SbSlider.css';
import { useTranslation } from 'react-i18next';
import { SbSliderContent } from './SliderContent/SbSliderContent';
import IActions from 'actions/IActions';

interface SbSliderProps {
    actions: IActions;
}
export const SbSlider: React.FC<SbSliderProps> = ({ actions }) => {
    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    };
    const { t } = useTranslation();
    return (
        <div className={Styles.wrapper} id="home">
            <Slider {...settings}>
                <div className={Styles.sliderItem}>
                    <div className={Styles.content}>
                        <h1 className={Styles.title}><b>{t('small.stop') + ' '}</b>{t('small.browsingCalendar')}</h1>
                    </div>
                    <img className={Styles.itemImg} src={'/assets/sbLandingPage/slider-1.png'} />
                </div>
                <div className={Styles.sliderItem}>
                    <div className={Styles.content}>
                        <h1 className={Styles.title}><b>{t('small.breakFree') + ' '}</b>{t('small.fromEndlessCalls')}</h1>
                    </div>
                    <img className={Styles.itemImg} src={'/assets/sbLandingPage/slider-2.png'} />
                </div>
                <div className={Styles.sliderItem}>
                    <div className={Styles.content}>
                        <h1 className={Styles.title}><b>{t('small.imagine') + ' '}</b>{t('small.alreadyBooded')}</h1>
                    </div>
                    <img className={Styles.itemImg} src={'/assets/sbLandingPage/slider-3.png'} />
                </div>
                <div className={Styles.sliderItem}>
                    <div className={Styles.content}>
                        <h1 className={Styles.title}><b>{t('small.takeBreath') + ' '}</b>{t('small.allRemindersWereSent')}</h1>
                    </div>
                    <img className={Styles.itemImg} src={'/assets/sbLandingPage/slider-4.png'} />
                </div>
                <div className={Styles.sliderItem}>
                    <div className={Styles.content}>
                        <h1 className={Styles.title}><b>{t('small.start') + ' '}</b>{t('small.takingBookings')}</h1>
                    </div>
                    <img className={Styles.itemImg} src={'/assets/sbLandingPage/slider-5.png'} />
                </div>
            </Slider>
            <SbSliderContent
                actions={actions}
            />
        </div>
    );
};
