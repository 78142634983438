export function getInitialsFromName(
    name: string
): string {
    const array = name.split(' ');

    const firstLetter = ((array[0] || '')[0] || '').toUpperCase();
    const secondLetter = ((array[1] || '')[0] || '').toUpperCase();

    return firstLetter + (secondLetter ? secondLetter : '');
}
