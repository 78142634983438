import React from 'react';
import ENTermsAndCondition from 'components/Data/TermsAndConditions/en.json';
import PLTermsAndCondition from 'components/Data/TermsAndConditions/pl.json';
import ENProfessionalTerms from 'components/Data/ProfessionalTerms/en.json';
import PLProfessionalTerms from 'components/Data/ProfessionalTerms/pl.json';
import { useTranslation } from 'react-i18next';
import {Privacy} from 'components/_ReusableComponents/Privacy/Privacy';
import Styles from './ProfessionalTerms.css';

export const ProfessionalTerms = () => {
    const { i18n } = useTranslation();
    const data1 = i18n.language === 'en' ? ENTermsAndCondition : PLTermsAndCondition;
    const data2 = i18n.language === 'en' ? ENProfessionalTerms : PLProfessionalTerms;

    return (
        <>
            <div className={Styles.root}>
                <Privacy
                    data={data1}
                />
            </div>
            <div className={Styles.root}>
                <Privacy
                    data={data2}
                />
            </div>
        </>
    );
};
