import * as React from 'react';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
const Styles = require('./DescriptionTextToggle.css');

interface Props extends WithTranslation {
    description: string;
}

interface State {
    hideDescription: boolean;
}

export default withTranslation()(class DescriptionTextToggle extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            hideDescription: false
        };
    }

    toggleDescriptionState = () => {
        this.setState({
            hideDescription: !this.state.hideDescription
        });
    };

    render() {
        const { t } = this.props;
        if (!this.props.description) {
            return null;
        }
        return (
            <div>
                <div className={Styles.DescriptionTextToggle}
                     onClick={this.toggleDescriptionState}>
                    <div className={Styles.Title}>
                        {t('home.description')}
                    </div>
                    <IconButton>
                        <img
                            src="/assets/blue_chevron_light.svg"
                            className={classNames(Styles.arrowUp, {
                                [Styles.arrowUpRotate]: this.state.hideDescription,
                            })}
                        />
                    </IconButton>
                </div>
                {!this.state.hideDescription && (
                    <div className={Styles.Content}>
                        {this.props.description}
                    </div>
                )}
            </div>
        );
    }
});
