import React, {useContext, useEffect, useState} from 'react';
import { Checkbox, FormControlLabel, MenuItem, Stack, Typography, Skeleton } from '@material-ui/core';
import IDateRange from '../../../interfaces/IDateRange';
import TextField from '../../_ReusableComponents/TextField/TextField';
import DatePicker from '../../_ReusableComponents/DatePicker/DatePicker';
import getRanges from '../../../utils/dateRange';
import { useTranslation } from 'react-i18next';
import BackendMethods from '../../../api/BackendMethods';
import IProfile from '../../../interfaces/IProfile';
import moment, { Moment } from 'moment';
import IActions from '../../../actions/IActions';
import * as R from 'ramda';
import { ICalendarEvent } from '../../../interfaces/interfaces';
import GlobalContext from '../../../GlobalContext';
import { Box } from '@material-ui/system';
import IPreviewMeetingDate from 'interfaces/IPreviewMeetingDate';
const styles = require('./DateRangePicker.css');

interface Props {
    title: string;
    timezone: string;
    country: string;
    profile: IProfile;
    dateRange: IDateRange;
    setDateRange: (dateRange: IDateRange) => void;
    disabled?: boolean;
    actions: IActions;
    dayEvents?: string[];
    meetingHolidays?: string[];
    previewMeetingDate: IPreviewMeetingDate | boolean;
    isDispalyPreviewMeetingDate: boolean;
}

export interface IHoliday {
    date: string;
    names: {
        en: string;
        pl: string;
    };
}

export default function DateRangePicker(props: Props) {
    const { t, i18n } = useTranslation();
    const { updateContext } = useContext(GlobalContext);
    const { title, timezone, country, profile, dateRange, setDateRange, actions, dayEvents, meetingHolidays } = props;
    const {
        todayRange, tomorrowRange, thisWeekRange, nextWeekRange, renderRange, getRangeName,
    } = getRanges(timezone, t, i18n.language, country);
    const selectValue = renderRange(dateRange);
    const [open, setOpen] = useState(false);
    const [holidays, setHolidays] = useState<IHoliday[]>([]);
    const [events, setEvents] = useState<ICalendarEvent[]>([]);
    const recommendedData = typeof props.previewMeetingDate === 'object' && props.previewMeetingDate.recommended ?
        props.previewMeetingDate.recommended :
        null;
    const suggestioinRange: IDateRange|null = recommendedData
        ? {
            dateFrom: moment.tz(recommendedData.begin, timezone).startOf('day').toISOString(),
            dateTo: moment.tz(recommendedData.end, timezone).endOf('day').toISOString()
        } : null;
    const suggestionValue = recommendedData
        ? renderRange(suggestioinRange)
        : '';
    useEffect(() => {
        (async () => {
            const { dateFrom, dateTo } = dateRange;
            const startDate = moment(dateFrom).add(1, 'second').toISOString();
            if (dateFrom && dateTo) {
                const [newHolidays, newEvents] = await Promise.all([
                    BackendMethods.getHolidays(
                        moment(startDate).tz(timezone).format('YYYY-MM-DD'),
                        moment(dateTo).tz(timezone).format('YYYY-MM-DD'),
                        country,
                    ),
                    BackendMethods.getCalendarEvents(
                        moment(startDate).tz(timezone).toISOString(true),
                        moment(dateTo).tz(timezone).toISOString(true),
                        timezone,
                    ),
                ]);
                setHolidays(newHolidays);
                const eventArray = R.chain(R.prop('events'), newEvents);
                const dayEventBusy: string[] = [];
                eventArray.filter(R.prop('allDay')).map((event) => {
                    const date = moment.max(moment(), moment(event.start)).tz(timezone);
                    const dateEnd = moment(event.end).subtract(1, 'second').tz(timezone);
                    const datesString = (() => {
                        const dates = [];
                        const currentDate = moment(date);
                        while (currentDate.isBefore(dateEnd)) {
                            dates.push(currentDate.format('YYYY-MM-DD'));
                            currentDate.add(1, 'day');
                        }
                        return dates;
                    })();
                    if (profile.dayEventBusy.includes(datesString[0])) {
                        dayEventBusy.push(...datesString);
                    }
                });
                updateContext((x) => ({
                    ...x,
                    profile: {
                        ...profile,
                        dayEventBusy,
                    },
                }));
                BackendMethods.updateUserProfile({ dayEventBusy });
                setEvents(eventArray);
            }
        })();
    }, [dateRange, country, timezone]);
    const [initRange] = useState<IDateRange>(dateRange);
    useEffect(() => {
        let dayEventBusy;
        let holidayBusy;
        if (dayEvents) {
            dayEventBusy = R.uniq([
                ...profile.dayEventBusy.filter((x) => !moment.tz(x, timezone).isBetween(
                    initRange.dateFrom,
                    initRange.dateTo,
                    undefined,
                    '[]',
                )),
                ...dayEvents,
            ]);
        }
        if (meetingHolidays) {
            const fromYear = moment(initRange.dateFrom).year();
            const toYear = moment(initRange.dateTo).year();
            holidayBusy = R.uniq([
                ...profile.holidayBusy.filter(({ date }) => (
                    !moment.tz(date, 'MM-DD', timezone).year(fromYear).isBetween(
                        initRange.dateFrom,
                        initRange.dateTo,
                        undefined,
                        '[]',
                    ) && !moment.tz(date, 'MM-DD', timezone).year(toYear).isBetween(
                        initRange.dateFrom,
                        initRange.dateTo,
                        undefined,
                        '[]',
                    )
                )),
                ...meetingHolidays.map((x) => ({
                    date: moment.tz(x, timezone).format('MM-DD'),
                    name: '',
                })),
            ]);
        }
        if (dayEventBusy || holidayBusy) {
            const newData = {
                ...(dayEventBusy ? { dayEventBusy } : {}),
                ...(holidayBusy ? { holidayBusy } : {}),
            };
            updateContext((x) => ({
                ...x,
                profile: {
                    ...profile,
                    ...newData,
                },
            }));
            BackendMethods.updateUserProfile(newData);
        }
    }, [initRange, dayEvents, meetingHolidays]);
    const formatDate = (date: string | Moment) => moment(date)
        .format(i18n.language === 'pl' ? 'D MMM. Y' : 'MMM. D, Y');
    const renderHoliday = (holiday: IHoliday) => {
        const date = moment(holiday.date);
        return (
            <FormControlLabel
                className={styles.checkbox}
                classes={{
                    label: styles.label,
                }}
                key={holiday.date}
                control={(
                    <Checkbox
                        color="primary"
                        checked={profile.holidayBusy.find((x) => (
                            date.isSame(moment(x.date, 'MM-DD').year(date.year()))
                        )) === undefined}
                        onChange={({ target }) => {
                            const holidayBusy = !target.checked ? [
                                ...profile.holidayBusy,
                                {
                                    date: moment(holiday.date).format('MM-DD'),
                                    name: holiday.names.en,
                                },
                            ] : profile.holidayBusy.filter((x) => (
                                !date.isSame(moment(x.date, 'MM-DD').year(date.year()))
                            ));
                            updateContext((x) => ({
                                ...x,
                                profile: {
                                    ...profile,
                                    holidayBusy,
                                },
                            }));
                            BackendMethods.updateUserProfile({ holidayBusy });
                        }}
                    />
                )}
                label={(
                    <>
                        {t('new.canMeetOn')} {formatDate(date)}
                        {' - '}
                        {holiday.names[i18n.language] || holiday.names.en}
                    </>
                )}
            />
        );
    };
    const getTimeWindowText = (dates: string) => {
        if (dates !== '' && typeof props.previewMeetingDate === 'object') {
            switch (dates) {
                case 'today':
                    return getCustomeTimeWindows({ dateFrom: todayRange.dateFrom, dateTo: todayRange.dateTo });
                case 'tomorrow':
                    return getCustomeTimeWindows({ dateFrom: tomorrowRange.dateFrom, dateTo: tomorrowRange.dateTo });
                case 'thisWeek':
                    return getCustomeTimeWindows({ dateFrom: thisWeekRange.dateFrom, dateTo: thisWeekRange.dateTo });
                case 'nextWeek':
                    return getCustomeTimeWindows({ dateFrom: nextWeekRange.dateFrom, dateTo: nextWeekRange.dateTo });
                default :
                    return getCustomeTimeWindows(dateRange);
            }
        }
        return '';
    };
    const getTimeWindowColor = (dates: string) => {
        return getTimeWindowText(dates) === t('new.noGoodSlots')? '#e9523e': '#0f9f25';
    };
    const getCustomeTimeWindows = (datesRange: IDateRange) => {
        if (typeof props.previewMeetingDate === 'object') {
            if(props.previewMeetingDate.calendar.find(date =>
                moment.tz(date.date, timezone).endOf('day').isSameOrBefore(moment.tz(datesRange.dateTo, timezone).endOf('day')) &&
                moment.tz(date.date, timezone).startOf('day').isSameOrAfter(moment.tz(datesRange.dateFrom, timezone).startOf('day')) &&
                date.score === 'MANY_GOOD_SLOTS')
            ) {
                return t('new.manyGoodSlots');
            }
            if(props.previewMeetingDate.calendar.find(date =>
                moment.tz(date.date, timezone).endOf('day').isSameOrBefore(moment.tz(datesRange.dateTo, timezone).endOf('day')) &&
                moment.tz(date.date, timezone).startOf('day').isSameOrAfter(moment.tz(datesRange.dateFrom, timezone).startOf('day')) &&
                date.score === 'FEW_GOOD_SLOTS')
            ) {
                return t('new.goodSlots');
            }
        }
        return t('new.noGoodSlots');
    };
    const renderDayEvent = (event: ICalendarEvent) => {
        const date = moment.max(moment(), moment(event.start)).tz(timezone);
        const dateEnd = moment(event.end).subtract(1, 'second').tz(timezone);
        const oneDay = dateEnd.isSame(date, 'day');
        const datesString = (() => {
            const dates = [];
            const currentDate = moment(date);
            while (currentDate.isBefore(dateEnd)) {
                dates.push(currentDate.format('YYYY-MM-DD'));
                currentDate.add(1, 'day');
            }
            return dates;
        })();
        return (
            <FormControlLabel
                className={styles.checkboxContainer}
                classes={{
                    label: styles.label,
                }}
                key={event.id}
                control={(
                    <Checkbox
                        color="primary"
                        checked={!profile.dayEventBusy.includes(datesString[0])}
                        onChange={({ target }) => {
                            const dayEventBusy = !target.checked ? R.uniq([
                                ...profile.dayEventBusy,
                                ...datesString,
                            ]) : profile.dayEventBusy.filter((x) => (
                                !datesString.includes(x)
                            ));
                            updateContext((x) => ({
                                ...x,
                                profile: {
                                    ...profile,
                                    dayEventBusy,
                                },
                            }));
                            BackendMethods.updateUserProfile({ dayEventBusy });
                        }}
                        className={styles.checkbox}
                    />
                )}
                label={(
                    <>
                        {oneDay ? (
                            `${t('new.canMeetOn')} ${formatDate(date)}`
                        ) : (
                            t('new.canMeetFromTo', {
                                from: formatDate(date),
                                to: formatDate(dateEnd),
                            })
                        )}
                        {' - '}
                        {event.subject}
                    </>
                )}
            />
        );
    };
    return (
        <>
            <Box position="relative">
                <Stack direction="row" spacing={2}
                    sx={{
                        position: 'absolute',
                        top: '18px',
                        alignItems: 'flex-end',
                        px: 2,
                        zIndex: 1,
                    }}
                >
                    <div style={{visibility: 'hidden'}}>{selectValue}</div>
                    {props.isDispalyPreviewMeetingDate && typeof props.previewMeetingDate !== 'boolean' ? (
                        <Typography variant="caption" sx={{ color: getTimeWindowColor(getRangeName(dateRange)) }}>
                            { getCustomeTimeWindows(dateRange) }
                        </Typography>
                    ) : (<>
                        {props.previewMeetingDate && (<>
                                <Skeleton variant="text" sx={{ ml: '10px', width: '90px', height: '20px' }} />
                        </>)}
                    </>)}
                </Stack>
                <TextField
                    select
                    label={title}
                    value={getRangeName(dateRange)}
                    onChange={({ target: { value } }) => {
                        if (value === 'today') {
                            setDateRange(todayRange);
                        } else if (value === 'tomorrow') {
                            setDateRange(tomorrowRange);
                        } else if (value === 'thisWeek') {
                            setDateRange(thisWeekRange);
                        } else if (value === 'nextWeek') {
                            setDateRange(nextWeekRange);
                        }
                    }}
                    fullWidth
                    SelectProps={{
                        autoWidth: true,
                        renderValue: () => selectValue,
                        open,
                        onOpen: () => setOpen(true),
                        onClose: () => setOpen(false),
                    }}
                    data-testid="date-range-picker"
                >
                    {['today', 'tomorrow', 'thisWeek', 'nextWeek'].map(x => (
                        <MenuItem key={x} value={x} data-testid={x} sx={{ alignItems: 'flex-end' }}>
                            {t(`common.${x}`)}
                            {props.isDispalyPreviewMeetingDate && typeof props.previewMeetingDate !== 'boolean' ? (
                                <>
                                    <Typography variant="caption" sx={{ color: getTimeWindowColor(x), ml: '10px' }}>
                                        { getTimeWindowText(x) }
                                    </Typography>
                                    {getRangeName(suggestioinRange) === x && (
                                        <div className={styles.suggestionItem}>
                                            {t('new.suggested')}
                                        </div>
                                    )}
                                </>
                            ) : (<>
                                {props.previewMeetingDate && (<>
                                    <Skeleton variant="text" sx={{ ml: '10px', width: '90px', height: '20px' }} />
                                </>)}
                            </>)}
                        </MenuItem>
                    ))}
                    {getRangeName(suggestioinRange) === 'custom' && props.isDispalyPreviewMeetingDate? (
                        <MenuItem key={'custom'} value={'suggested'} data-testid={'custom'} sx={{ alignItems: 'flex-end' }} onClick={() => setDateRange(suggestioinRange)}>
                            {suggestionValue}
                            <Typography
                                variant="caption"
                                sx={{
                                    color: getCustomeTimeWindows({ dateFrom: recommendedData.begin, dateTo: recommendedData.end }) === t('new.noGoodSlots') ?
                                        '#e9523e' :
                                        '#0f9f25', ml: '10px'
                                }}
                            >
                                { getCustomeTimeWindows({dateFrom: recommendedData.begin, dateTo: recommendedData.end}) }
                            </Typography>
                            <div className={styles.suggestionItem}>
                                {t('new.suggested')}
                            </div>
                        </MenuItem>
                    ): null}
                    <MenuItem value="custom" className={styles.customMenuItem} />
                    <DatePicker
                        timezone={timezone}
                        dateRange={dateRange}
                        country={country}
                        setDateRange={setDateRange}
                        closeSelect={() => setOpen(false)}
                        previewMeetingDate={props.previewMeetingDate}
                        isDispalyPreviewMeetingDate={props.isDispalyPreviewMeetingDate}
                    />
                </TextField>
            </Box>
            {R.sortBy(
                (x) => {
                    if ('date' in x) {
                        return moment(x.date).valueOf();
                    } else {
                        return moment(x.start).valueOf();
                    }
                },
                [...holidays, ...events.filter(R.prop('allDay'))]
            ).map((x) => {
                if ('date' in x) {
                    return renderHoliday(x);
                } else {
                    return renderDayEvent(x);
                }
            })}
        </>
    );
}
