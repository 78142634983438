import moment from 'moment';

enum SortItemType {
    normal,
    byDate,
}

enum SortDirection {
    ASC,
    DES,
}

function sortByItem(array: any, sortItem: string, sortItemtype?: SortItemType, direction?: SortDirection) {
    if (array.length === 0) {
        return array;
    }
    const newArray = [...array];
    if (sortItemtype === SortItemType.normal) {
        if (direction === SortDirection.ASC) {
            newArray.sort((a, b) => (a[sortItem] < b[sortItem]) ? -1 : 1);
        } else {
            newArray.sort((a, b) => (a[sortItem] > b[sortItem]) ? -1 : 1);
        }
    } else {
        if (direction === SortDirection.ASC) {
            newArray.sort((a, b) => (moment(a[sortItem].earliest).isBefore(moment(b[sortItem].earliest))) ? 1 : -1);
        } else {
            newArray.sort((a, b) => (moment(a[sortItem].earliest).isBefore(moment(b[sortItem].earliest))) ? -1 : 1);
        }
    }
    return newArray;
}

export { SortDirection, SortItemType, sortByItem };
