import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import * as R from 'ramda';
import scrollIntoView from 'scroll-into-view';
import ICalendarEvent from 'interfaces/calendarEvents/ICalendarEvent';
import IMeeting from 'interfaces/IMeeting';
import { isAdHoc } from '../../../scripts/helpers';
import IPropositionAnswer from '../../../interfaces/IPropositionAnswer';
const styles = require('./CalendarEventsHorizontalScrollBar.css');

interface Props {
    meeting?: any;
    app?: any;
    activePropositionId: number;
    currentMeeting: IMeeting;
    email: string;
    timezone: string;
    propositionAnswers: IPropositionAnswer[];
}

const hourWidth = 120;
const minimumWidthToDisplayText = 40;

export default function CalendarEventsHorizontalScrollBar(props: Props) {
    const { meeting, currentMeeting } = props;
    const activeProposition = props.propositionAnswers.find(R.whereEq({ id: props.activePropositionId }));
    const hourElements = useRef([]);
    const hoursArray = meeting.get('hoursArray');
    const scrollContainer = useRef<HTMLDivElement>(null);
    const propositionRef = useRef<HTMLDivElement>(null);
    const [propositionWidth, setPropositionWidth] = useState<number>(0);
    useEffect(() => {
        if (activeProposition && propositionRef.current && scrollContainer.current) {
            scrollIntoView(
                propositionRef.current,
                {
                    validTarget: R.equals(scrollContainer.current),
                    align: {
                        left: propositionWidth > scrollContainer.current.offsetWidth? 0: 0.5
                    }
                }
            );
        }
    });

    if (meeting.get('isLoadingCalendarEvents')) {
        return (
            <div
                id="CalendarEventsHorizontalScrollBar"
                className={styles.root}
            >
                <div className={styles.background}>
                    <span className={styles.loadingIcon} />
                </div>
            </div>
        );
    }

    const adHoc = isAdHoc();

    return (
        <div
            id="CalendarEventsHorizontalScrollBar"
            className={classNames({
                [styles.root]: true,
                [styles.rootAdHoc]: adHoc
            })}
        >
            <div
                className={classNames({
                    [styles.background]: true,
                    [styles.backgroundAdHoc]: adHoc
                })}
            />
            <div
                className={classNames({
                    [styles.scrollContainer]: true,
                    [styles.scrollContainerAdHoc]: adHoc
                })}
                ref={scrollContainer}
            >
                {activeProposition && (() => {
                    const { earliest, latest } = activeProposition;
                    const timezone = props.timezone;
                    const propositionLeft = moment(earliest)
                        .diff(currentMeeting.after, 'hours', true) * hourWidth;
                    const rangeDuration = moment.duration(moment(latest).diff(earliest))
                        .add(currentMeeting.duration, 'minutes')
                        .asHours();
                    const preProposition = Math.max(rangeDuration * hourWidth, 5);
                    if(preProposition !== propositionWidth) {
                        setPropositionWidth(preProposition);
                    }
                    return (
                        <div
                            style={{
                                width: `${propositionWidth}px`,
                                left: `${propositionLeft}px`
                            }}
                            className={classNames({
                                [styles.scrollContainerBox]: true,
                                [styles.scrollContainerBoxCurrentSchedule]: true,
                                [styles.scrollContainerBoxAdHoc]: adHoc
                            })}
                            ref={propositionRef}
                        >
                            <div className={styles.scrollContainerBoxTextContainer}>
                                {propositionWidth >= minimumWidthToDisplayText && (
                                    <span>
                                        <strong>
                                            {moment(earliest).tz(timezone).format('ddd D')}
                                        </strong>
                                        <br />
                                        {currentMeeting.title}
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                })()}
                {meeting.get('eventsListToDisplay').map((e: ICalendarEvent, i) => {
                    const boxStyles = { width: e.boxWidth, left: e.left };
                    return (
                        <div
                            key={i}
                            style={boxStyles}
                            className={classNames(styles.scrollContainerBox, styles.scrollContainerBoxCalendarEvent)}
                        >
                            <div className={styles.scrollContainerBoxTextContainer}>
                                {e.boxWidthNumber >= minimumWidthToDisplayText && (e.subject || '')}
                            </div>
                        </div>
                    );
                })}
                <div className={styles.hoursBar}>
                    {' '}
                    {hoursArray.map((e, i) => (
                        <div
                            style={{
                                width: `${(i === hoursArray.length - 1) ? '0' : hourWidth}px`,
                                // bold for dates
                                fontWeight:
                                    e.indexOf('/') !== -1 ? 'normal' : 'lighter'
                            }}
                            className={classNames({
                                [styles.hourElement]: true,
                                [styles.hourElementAdHoc]: adHoc
                            })}
                            key={i}
                            ref={el => (hourElements.current[i] = el)}
                        >
                            {e}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
