import * as React from 'react';
import classNames from 'classnames';
import { Collapse } from '@material-ui/core';
const Styles = require('./MeetingTitle.css');

interface Props {
    title: string;
    description: string;
    className?: any;
}

interface State {
    descriptionVisible: boolean;
}

export default class MeetingTitle extends React.Component<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            descriptionVisible: false
        };
    }

    toggleDescription = (e) => {
        e.stopPropagation();
        this.setState({
            descriptionVisible: !this.state.descriptionVisible
        });
    };

    render() {
        const classDef = classNames(Styles.MeetingTitle, this.props.className);
        const {title, description} = this.props;
        const {descriptionVisible} = this.state;
        const iconClasses = classNames(Styles.ChevronIcon, (descriptionVisible === true) ? Styles['ChevronIcon--Rotate'] : '');

        return (
            <div
                className={classDef}>
                <div onClick={this.toggleDescription}>
                    <span>
                        {title}
                    </span>
                        <img src="/assets/blue_chevron_light.svg" className={iconClasses}/>
                    {this.props.children}
                </div>
                <Collapse in={descriptionVisible}>
                    <div>
                        {description}
                    </div>
                </Collapse>
            </div>
        );
    }
}
