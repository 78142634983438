// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".LinkToFAQ__vyFzp {\n    align-items: center;\n    background: #08a;\n    border-radius: 12px;\n    color: #fff;\n    display: flex;\n    font-size: 20px;\n    height: 55px;\n    justify-content: center;\n    margin: 48px auto 20px auto;\n    text-decoration: none;\n    width: 100px;\n}\n\n.LinkToFAQ__vyFzp:visited {\n    color: #fff;\n}\n\n@media only screen and (min-height: 660px) {\n\n    .LinkToFAQ__vyFzp {\n        border-radius: 12px 12px 0 0;\n        bottom: 0;\n        left: 0;\n        margin-bottom: 0;\n        position: fixed;\n        right: 0;\n    }\n}\n", "",{"version":3,"sources":["LinkToFAQ.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,eAAe;IACf,YAAY;IACZ,uBAAuB;IACvB,2BAA2B;IAC3B,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;;IAEI;QACI,4BAA4B;QAC5B,SAAS;QACT,OAAO;QACP,gBAAgB;QAChB,eAAe;QACf,QAAQ;IACZ;AACJ","file":"LinkToFAQ.css","sourcesContent":[".LinkToFAQ {\n    align-items: center;\n    background: #08a;\n    border-radius: 12px;\n    color: #fff;\n    display: flex;\n    font-size: 20px;\n    height: 55px;\n    justify-content: center;\n    margin: 48px auto 20px auto;\n    text-decoration: none;\n    width: 100px;\n}\n\n.LinkToFAQ:visited {\n    color: #fff;\n}\n\n@media only screen and (min-height: 660px) {\n\n    .LinkToFAQ {\n        border-radius: 12px 12px 0 0;\n        bottom: 0;\n        left: 0;\n        margin-bottom: 0;\n        position: fixed;\n        right: 0;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"LinkToFAQ": "LinkToFAQ__vyFzp"
};
module.exports = exports;
