// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".DisableClickEvents__2MK_4 {\n    background-color: rgba(0, 0, 0, 0.05);\n    height: 100vh;\n    left: 0;\n    position: fixed;\n    top: 0;\n    width: 100vw;\n    z-index: 9999;\n}\n\n.chevronLeft__2gVm8 {\n    transform: rotate(90deg);\n    height: 16px;\n    width: 16px;\n}\n\n.chevronRight__1Fjzt {\n    transform: rotate(-90deg);\n    height: 16px;\n    width: 16px;\n}\n", "",{"version":3,"sources":["App.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,aAAa;IACb,OAAO;IACP,eAAe;IACf,MAAM;IACN,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;AACf","file":"App.css","sourcesContent":[".DisableClickEvents {\n    background-color: rgba(0, 0, 0, 0.05);\n    height: 100vh;\n    left: 0;\n    position: fixed;\n    top: 0;\n    width: 100vw;\n    z-index: 9999;\n}\n\n.chevronLeft {\n    transform: rotate(90deg);\n    height: 16px;\n    width: 16px;\n}\n\n.chevronRight {\n    transform: rotate(-90deg);\n    height: 16px;\n    width: 16px;\n}\n"]}]);
// Exports
exports.locals = {
	"DisableClickEvents": "DisableClickEvents__2MK_4",
	"chevronLeft": "chevronLeft__2gVm8",
	"chevronRight": "chevronRight__1Fjzt"
};
module.exports = exports;
