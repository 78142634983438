import * as React from 'react';
import MeetingTitle from 'components/MeetingPageComponents/SingleMeeting/MeetingTitle/MeetingTitle';
import IMeeting from 'interfaces/IMeeting';
import ILocation from 'interfaces/location/ILocation';
import Header from '../../_ReusableComponents/Header/Header/Header';
import FallingLeaves from '../../_ReusableComponents/FallingLeaves/FallingLeaves';
import MeetingOptions from '../MeetingOptions/MeetingOptions';
import getMeetingMessage from '../../../utils/getMeetingMessage';
import { useTranslation } from 'react-i18next';
const styles = require('./CalculationInProgress.css');

interface Props {
    app: any;
    data: any;
    meeting: any;
    actions: any;
    location: ILocation;
    history: any;
    currentMeeting: IMeeting;
    email: string;
}

export default function CalculationInProgress(props: Props) {
    const { t } = useTranslation();
    const { currentMeeting } = props;
    return (
        <div id="CalculationInProgress">
            <section className={styles.wrapper}>
                <Header />
                <div className={styles.message}>
                    {getMeetingMessage(currentMeeting, props.email, t)}
                </div>
                <MeetingTitle
                    className={styles.titleContainer}
                    title={currentMeeting.title}
                    description={currentMeeting.description}
                >
                    <MeetingOptions
                        actions={props.actions}
                        history={props.history}
                        email={props.email}
                        currentMeeting={props.currentMeeting}
                        onSetDate={() => {}}
                    />
                </MeetingTitle>
                <div className={styles.searching}>
                    {t('home.searchingForDates')} ...
                </div>
            </section>
            <FallingLeaves />
        </div>
    );
}
