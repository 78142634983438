import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { SortBy } from 'elementsSelectors/_reusable/Meetings';
import { MyCheckBox } from 'components/_ReusableComponents/MyCheckBox/MyCheckBox';
import { useTranslation } from 'react-i18next';
import Styles from './SortMenu.css';

interface ISortMenuProps {
    sortBy: SortBy;
    setSortBy: React.Dispatch<React.SetStateAction<SortBy>>;
    acceptedOnTop: boolean;
    setAcceptedOnTop: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SortMenu = ({ sortBy, setSortBy, acceptedOnTop, setAcceptedOnTop }: ISortMenuProps) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [label, setLabel] = useState<string>(t('home.sortByRating'));
    return (
        <div>
            <div
                className={Styles.menuButton}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                {label}
                <img src="/assets/chevron-blue.svg" alt="" className={Styles.menuIcon} />
            </div>
            <Menu
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                className={Styles.menuItems}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <MenuItem
                    className={sortBy === SortBy.ByRating ? Styles.selectedItem : Styles.menuItem}
                    onClick={() => {
                        setSortBy(SortBy.ByRating);
                        setLabel(t('home.sortByRating'));
                        setAnchorEl(null);
                    }}
                >
                    {t('home.sortByRating')}
                </MenuItem>
                <MenuItem
                    className={sortBy === SortBy.ByTimes ? Styles.selectedItem : Styles.menuItem}
                    onClick={() => {
                        setSortBy(SortBy.ByTimes);
                        setLabel(t('home.sortByTime'));
                        setAnchorEl(null);
                    }}
                >
                    {t('home.sortByTime')}
                </MenuItem>
                <hr className={Styles.midLine} />
                <MenuItem
                    className={Styles.menuItem}
                    onClick={() => {
                        acceptedOnTop ? setAcceptedOnTop(false) : setAcceptedOnTop(true);
                        setAnchorEl(null);
                    }}
                >
                    <MyCheckBox
                        label={t('home.AcceptedOnTop')}
                        isChecked={acceptedOnTop}
                    />
                </MenuItem>
            </Menu>
        </div>
    );
};
