// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".DescriptionTextToggle__2qeKt {\n    cursor: pointer;\n    margin-top: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.Title__ktg9V {\n    color: #00aad4;\n    font-size: 18px;\n}\n\n.Content__3WcLp {\n    font-size: 16px;\n    white-space: pre-line;\n}\n\n.arrowUp__2emcS {\n    width: 24px;\n    height: 24px;\n    transition: 0.3s all linear;\n}\n\n.arrowUpRotate__M8lUI {\n    transform: rotate(180deg);\n}\n", "",{"version":3,"sources":["DescriptionTextToggle.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;AAC7B","file":"DescriptionTextToggle.css","sourcesContent":[".DescriptionTextToggle {\n    cursor: pointer;\n    margin-top: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.Title {\n    color: #00aad4;\n    font-size: 18px;\n}\n\n.Content {\n    font-size: 16px;\n    white-space: pre-line;\n}\n\n.arrowUp {\n    width: 24px;\n    height: 24px;\n    transition: 0.3s all linear;\n}\n\n.arrowUpRotate {\n    transform: rotate(180deg);\n}\n"]}]);
// Exports
exports.locals = {
	"DescriptionTextToggle": "DescriptionTextToggle__2qeKt",
	"Title": "Title__ktg9V",
	"Content": "Content__3WcLp",
	"arrowUp": "arrowUp__2emcS",
	"arrowUpRotate": "arrowUpRotate__M8lUI"
};
module.exports = exports;
