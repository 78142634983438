import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import * as R from 'ramda';
import moment, { Moment } from 'moment';
import { IconButton } from '@material-ui/core';
import Button from '../../components/_ReusableComponents/Button/Button';
import classNames from 'classnames';
const styles = require('./MobileCalendar.css');

interface IProps {
    selectedWeek: Moment;
    setSelectedWeek: (week: Moment) => void;
    selectedDate: Moment | null;
    setSelectedDate: (date: Moment) => void;
    shouldDisableDate?: (date: Moment) => boolean;
    switchIcon?: React.ReactNode;
    isWeekly?: boolean;
    isWeekView: boolean;
    setIsWeekView: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IDateData {
    date: Moment;
    isoDate: string;
    today: boolean;
    selected: boolean;
    disabled: boolean;
    formatted: string;
}

export default function MobileCalendar(props: IProps) {
    const { selectedWeek, setSelectedWeek, selectedDate, setSelectedDate, shouldDisableDate, switchIcon, isWeekly, isWeekView, setIsWeekView } = props;
    const selectedWeekEnd = moment(selectedWeek).endOf('week');
    const goWeekBack = () => setSelectedWeek(moment(selectedWeek).subtract(1, 'week'));
    const goWeekForward = () => setSelectedWeek(moment(selectedWeek).add(1, 'week'));
    const weekRef = useRef<HTMLDivElement | null>(null);
    const weekHandlers = useSwipeable({
        onSwiping: ({ deltaX }) => {
            if (!isWeekView) {
                return;
            }
            weekRef.current.style.transition = '';
            weekRef.current.style.transform = `translateX(${deltaX}px)`;
        },
        onSwiped: ({ dir }) => {
            if (!isWeekView) {
                return;
            }
            weekRef.current.style.transition = 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)';
            if (dir === 'Left' || dir === 'Right') {
                weekRef.current.style.transform = `translateX(${dir === 'Left' ? '-' : ''}33.33%)`;
                setTimeout(() => {
                    weekRef.current.style.transition = '';
                    weekRef.current.style.transform = '';
                    dir === 'Left' ? goWeekForward() : goWeekBack();
                }, 150);
            } else {
                weekRef.current.style.transform = '';
            }
        },
    });
    const base = moment().isSame(moment().startOf('week'), 'month')
        ? moment(selectedWeek).startOf('week')
        : moment(selectedWeek).endOf('week');
    const weeksBefore = Math.ceil(selectedWeek.diff(
        moment(base).startOf('month'),
        'weeks',
        true,
    ));
    const weeksAfter = moment(base).endOf('month').diff(selectedWeek, 'weeks');
    const aboveWeekRef = useRef<HTMLDivElement | null>(null);
    const belowWeekRef = useRef<HTMLDivElement | null>(null);
    const weekBackRef = useRef<HTMLButtonElement | null>(null);
    const weekForwardRef = useRef<HTMLButtonElement | null>(null);
    const bottomRef = useRef<HTMLDivElement | null>(null);
    const switchHandlers = useSwipeable({
        onSwiping: ({ deltaY }) => {
            if (isWeekly) {
                const maxDelta = -bottomRef.current.offsetHeight;
                if (Math.sign(deltaY) === (isWeekView ? 1 : -1)) {
                    bottomRef.current.style.transition = '';
                    const marginBottom = R.clamp(maxDelta, 0, (isWeekView ? maxDelta : 0) + deltaY);
                    bottomRef.current.style.marginBottom = `${marginBottom}px`;
                    const opacity = (maxDelta - marginBottom) / maxDelta;
                    bottomRef.current.style.opacity = `${opacity}`;
                }
            } else {
                const maxBefore = -weeksBefore * 42;
                const maxAfter = -weeksAfter * 42;
                const beforeRatio = weeksBefore / (weeksBefore + weeksAfter);
                const afterRatio = weeksAfter / (weeksBefore + weeksAfter);
                if (Math.sign(deltaY) === (isWeekView ? 1 : -1)) {
                    aboveWeekRef.current.style.transition = '';
                    belowWeekRef.current.style.transition = '';
                    weekBackRef.current.style.transition = '';
                    weekForwardRef.current.style.transition = '';
                    const topBase = deltaY * beforeRatio;
                    const marginTop = R.clamp(
                        maxBefore,
                        0,
                        isWeekView ? (maxBefore + topBase) : topBase,
                    );
                    aboveWeekRef.current.style.marginTop = `${marginTop}px`;
                    const bottomBase = deltaY * afterRatio;
                    const marginBottom = R.clamp(
                        maxAfter,
                        0,
                        isWeekView ? (maxAfter + bottomBase) : bottomBase,
                    );
                    belowWeekRef.current.style.marginBottom = `${marginBottom}px`;
                    const opacityBase = Math.abs(deltaY) / ((weeksBefore + weeksAfter) * 42);
                    const opacity = R.clamp(
                        0,
                        1,
                        isWeekView ? opacityBase : (1 - opacityBase),
                    );
                    aboveWeekRef.current.style.opacity = `${opacity}`;
                    belowWeekRef.current.style.opacity = `${opacity}`;
                    const arrowsOpacity = 1 - opacity;
                    weekBackRef.current.style.opacity = `${arrowsOpacity}`;
                    weekForwardRef.current.style.opacity = `${arrowsOpacity}`;
                }
            }
        },
        onSwiped: ({ dir }) => {
            if (dir === (isWeekView ? 'Down' : 'Up')) {
                const transition = `margin 150ms cubic-bezier(0.4, 0, 0.2, 1),
                        opacity 150ms cubic-bezier(0.4, 0, 0.2, 1)`;
                if (isWeekly) {
                    const maxDelta = -bottomRef.current.offsetHeight;
                    bottomRef.current.style.transition = transition;
                    const marginBottom = isWeekView ? 0 : maxDelta;
                    bottomRef.current.style.marginBottom = `${marginBottom}px`;
                    const opacity = isWeekView ? 1 : 0;
                    bottomRef.current.style.opacity = `${opacity}`;
                    setTimeout(() => {
                        setIsWeekView(R.not);
                        bottomRef.current.style.transition = '';
                    }, 150);
                } else {
                    aboveWeekRef.current.style.transition = transition;
                    belowWeekRef.current.style.transition = transition;
                    weekBackRef.current.style.transition = transition;
                    weekForwardRef.current.style.transition = transition;
                    const marginTop = isWeekView ? 0 : -weeksBefore * 42;
                    const marginBottom = isWeekView ? 0 : -weeksAfter * 42;
                    aboveWeekRef.current.style.marginTop = `${marginTop}px`;
                    belowWeekRef.current.style.marginBottom = `${marginBottom}px`;
                    const opacity = isWeekView ? 1 : 0;
                    aboveWeekRef.current.style.opacity = `${opacity}`;
                    belowWeekRef.current.style.opacity = `${opacity}`;
                    const arrowsOpacity = 1 - opacity;
                    weekBackRef.current.style.opacity = `${arrowsOpacity}`;
                    weekForwardRef.current.style.opacity = `${arrowsOpacity}`;
                    setTimeout(() => {
                        setIsWeekView(R.not);
                        aboveWeekRef.current.style.transition = '';
                        belowWeekRef.current.style.transition = '';
                        weekBackRef.current.style.transition = '';
                        weekForwardRef.current.style.transition = '';
                    }, 150);
                }
            }
        },
    });
    const renderDate = (data) => {
        return (
            <Button
                key={data.isoDate}
                className={classNames(styles.dayButton, {
                    [styles.today]: data.today,
                    [styles.dateSelected]: data.selected,
                })}
                variant="text"
                disabled={data.disabled}
                onClick={() => {
                    setSelectedDate(data.date);
                    setSelectedWeek(
                        moment(data.date)
                            .startOf('week'),
                    );
                }}
            >
                {data.formatted}
            </Button>
        );
    };
    const makeDateData = useCallback((currentDate: Moment): IDateData => ({
        date: currentDate,
        isoDate: currentDate.toISOString(),
        today: currentDate.isSame(moment(), 'day'),
        selected: currentDate.isSame(selectedDate, 'day'),
        disabled: shouldDisableDate ? shouldDisableDate(currentDate) : false,
        formatted: currentDate.format('D'),
    }), [selectedDate, shouldDisableDate]);
    const datesBefore = useMemo(() => {
        const result: IDateData[][] = [];
        let currentDate = moment(selectedWeek).subtract(weeksBefore, 'weeks');
        for (let i = -weeksBefore; i < 0; i++) {
            const week = [];
            for (let j = 0; j < 7; j++) {
                week.push(makeDateData(currentDate));
                currentDate = moment(currentDate).add(1, 'day');
            }
            result.push(week);
        }
        return result;
    }, [selectedWeek, weeksBefore, makeDateData]);
    const datesThisWeek = useMemo(() => {
        const week = [];
        let currentDate = moment(selectedWeek).subtract(7, 'days');
        for (let j = -7; j < 14; j++) {
            week.push(makeDateData(currentDate));
            currentDate = moment(currentDate).add(1, 'day');
        }
        return week;
    }, [selectedWeek, makeDateData]);
    const datesAfter = useMemo(() => {
        const result: IDateData[][] = [];
        let currentDate = moment(selectedWeek).add(1, 'week');
        for (let i = 0; i < weeksAfter; i++) {
            const week = [];
            for (let j = 0; j < 7; j++) {
                week.push(makeDateData(currentDate));
                currentDate = moment(currentDate).add(1, 'day');
            }
            result.push(week);
        }
        return result;
    }, [selectedWeek, weeksAfter, makeDateData]);
    return (
        <div>
            <div className={styles.weekPicker}>
                <div className={styles.pickerTop}>
                    <IconButton
                        onClick={() => {
                            if (selectedWeek.isSame(selectedWeekEnd, 'month')) {
                                setSelectedWeek(
                                    moment(base)
                                        .subtract(1, 'month')
                                        .startOf('month')
                                        .startOf('week'),
                                );
                            } else {
                                setSelectedWeek(
                                    moment(base)
                                        .startOf('month')
                                        .startOf('week'),
                                );
                            }
                        }}
                        size="small"
                    >
                        <img
                            src="/assets/chevron-blue.svg"
                            alt=""
                            className={styles.chevronLeft}
                        />
                    </IconButton>
                    <div className={styles.pickerMonthYear}>
                        {(() => {
                            if (
                                selectedWeek.isSame(selectedWeekEnd, 'month')
                                || !isWeekView
                            ) {
                                return (
                                    <>
                                        <span className={styles.pickerMonth}>
                                            {base.format('MMMM')}
                                        </span>
                                        <span>
                                            {base.format('Y')}
                                        </span>
                                    </>
                                );
                            }
                            if (selectedWeek.isSame(selectedWeekEnd, 'year')) {
                                const from = selectedWeek.format('MMMM');
                                const to = selectedWeekEnd.format('MMMM Y');
                                return `${from} – ${to}`;
                            }
                            {
                                const from = selectedWeek.format('MMMM Y');
                                const to = selectedWeekEnd.format('MMMM Y');
                                return `${from} – ${to}`;
                            }
                        })()}
                    </div>
                    <IconButton
                        onClick={() => {
                            if (selectedWeek.isSame(selectedWeekEnd, 'month')) {
                                setSelectedWeek(
                                    moment(base)
                                        .add(1, 'month')
                                        .startOf('month')
                                        .startOf('week'),
                                );
                            } else {
                                setSelectedWeek(
                                    moment(base)
                                        .add(2, 'month')
                                        .startOf('month')
                                        .startOf('week'),
                                );
                            }
                        }}
                        size="small"
                    >
                        <img
                            src="/assets/chevron-blue.svg"
                            alt=""
                            className={styles.chevronRight}
                        />
                    </IconButton>
                    {switchIcon}
                </div>
                <div className={styles.bottomWrapper}>
                    <div
                        style={(isWeekly && isWeekView) ? {
                            marginBottom: '-100%',
                            opacity: 0,
                        } : {
                            marginBottom: 0,
                            opacity: 1,
                        }}
                        ref={bottomRef}
                    >
                        <div className={styles.pickerDaysHeader}>
                            {[0, 1, 2, 3, 4, 5, 6].map((weekday) => (
                                <div key={weekday} className={styles.pickerDayLabel}>
                                    {moment().weekday(weekday).format('dd')}
                                </div>
                            ))}
                        </div>
                        <div className={styles.daysWrapper}>
                            <div className={styles.daysContainer}>
                                <div
                                    className={styles.aboveWeek}
                                    style={isWeekly ? {} : {
                                        marginTop: isWeekView ? -(42 * weeksBefore) : 0,
                                        opacity: isWeekView ? 0 : 1,
                                    }}
                                    ref={aboveWeekRef}
                                >
                                    {datesBefore.map((week, i) => (
                                        <div key={i} className={styles.daysRow}>
                                            {week.map(renderDate)}
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className={styles.weekRowWrapper}
                                    {...isWeekView ? weekHandlers : {}}
                                >
                                    <IconButton
                                        onClick={goWeekBack}
                                        size="small"
                                        style={{
                                            opacity: isWeekView ? 1 : 0,
                                        }}
                                        disabled={!isWeekView}
                                        ref={weekBackRef}
                                    >
                                        <img
                                            src="/assets/chevron-blue.svg"
                                            alt=""
                                            className={styles.chevronLeft}
                                        />
                                    </IconButton>
                                    <div className={styles.weekRowContainer}>
                                        <div className={classNames(styles.daysRow, styles.weekDaysRow)} ref={weekRef}>
                                            {datesThisWeek.map(renderDate)}
                                        </div>
                                    </div>
                                    <IconButton
                                        onClick={goWeekForward}
                                        size="small"
                                        style={{
                                            opacity: isWeekView ? 1 : 0,
                                        }}
                                        disabled={!isWeekView}
                                        ref={weekForwardRef}
                                    >
                                        <img
                                            src="/assets/chevron-blue.svg"
                                            alt=""
                                            className={styles.chevronRight}
                                        />
                                    </IconButton>
                                </div>
                                <div
                                    className={styles.belowWeek}
                                    style={isWeekly ? {} : {
                                        marginBottom: isWeekView ? -(42 * weeksAfter) : 0,
                                        opacity: isWeekView ? 0 : 1,
                                    }}
                                    ref={belowWeekRef}
                                >
                                    {datesAfter.map((week, i) => (
                                        <div key={i} className={styles.daysRow}>
                                            {week.map(renderDate)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.handleWrapper}>
                <div
                    className={styles.calendarHandle}
                    {...switchHandlers}
                    onClick={() => setIsWeekView(R.not)}
                >
                    <div className={styles.handleIcon} />
                </div>
            </div>
        </div>
    );
}
