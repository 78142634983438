import React from 'react';
import { Dialog } from '@material-ui/core';
import IActions from 'actions/IActions';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';
const styles = require('./UnassignLicenseModal.css');

interface Props {
    actions: IActions;
    manageLicenses: any;
    open: boolean;
    onClose: () => void;
    email: string;
}

export default function UnassignLicenseModal(props: Props) {
    const { t } = useTranslation();
    const unassignLicense = () => {
        const id = props.manageLicenses
            .get('assignedLicenses')
            .find(x => x.get('email') === props.email)
            .get('id');
        props.actions.removeLicense(id);
        props.onClose();
    };
    const pending = props.manageLicenses.get('removalPending');
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{ className: styles.paper }}
        >
            <div className={styles.dialogTitle}>
                {t('plan.removeUser')}
            </div>
            <section className={styles.dialogBody}>
                <div>{t('plan.unassign')} {props.email}?</div>
            </section>
            <div className={styles.buttons}>
                <Button
                    className={styles.yesButton}
                    onClick={unassignLicense}
                    disabled={pending}
                >
                    {t('plan.yes')}
                </Button>
                <Button
                    variant="contained"
                    onClick={props.onClose}
                    disabled={pending}
                >
                    {t('plan.no')}
                </Button>
            </div>
        </Dialog>
    );
}
