// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__1XaPz {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n\n.title__37v7A {\n    color: #013253;\n    font-size: 20px;\n    margin-right: 24px;\n    max-width: 190px;\n}\n\n@media (min-width: 1250px) {\n\n.title__37v7A {\n        max-width: none;\n        max-width: initial\n}\n    }\n\n.container__87XUw {\n    width: 100%;\n}\n\n.arrowUp__V2aF6 {\n    width: 19px;\n    transition: 0.3s all ease-in-out;\n    transform: rotate(0deg);\n    margin-top: 3px;\n}\n\n.rotate__y6dlW {\n    transform: rotate(180deg);\n}\n", "",{"version":3,"sources":["TogglePreferencesElement.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,yBAAiB;OAAjB,sBAAiB;QAAjB,qBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AAIpB;;AAHI;;AALJ;QAMQ,eAAkB;QAAlB;AAER;IADI;;AAGJ;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,gCAAgC;IAChC,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","file":"TogglePreferencesElement.css","sourcesContent":[".root {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n    user-select: none;\n}\n\n.title {\n    color: #013253;\n    font-size: 20px;\n    margin-right: 24px;\n    max-width: 190px;\n    @media (min-width: 1250px) {\n        max-width: initial;\n    }\n}\n\n.container {\n    width: 100%;\n}\n\n.arrowUp {\n    width: 19px;\n    transition: 0.3s all ease-in-out;\n    transform: rotate(0deg);\n    margin-top: 3px;\n}\n\n.rotate {\n    transform: rotate(180deg);\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__1XaPz",
	"title": "title__37v7A",
	"container": "container__87XUw",
	"arrowUp": "arrowUp__V2aF6",
	"rotate": "rotate__y6dlW"
};
module.exports = exports;
