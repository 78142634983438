import * as manageLicenses from '../../actions/ManageLicensesActions';
import {LOCATION_CHANGE} from 'react-router-redux';
import {fromJS} from 'immutable';
import Validators from '../../utils/Validators/Validators';
import {act} from 'react-dom/test-utils';

const INITIAL_STATE = fromJS({
    licenseName: '',
    licenseEmail: '',
    insufficientLicenseAmount: false,
    wrongLicenseEmail: false,
    assignedByOtherError: false,
    alreadyAssignedError: false
});

const resetErrors = {
    wrongLicenseEmail: false,
    insufficientLicenseAmount: false,
    assignedByOtherError: false,
    alreadyAssignedError: false
};

export default function manageLicensesReducer(state: any = INITIAL_STATE, action: any = {type: '', payload: {}}) {

    switch (action.type) {
        case LOCATION_CHANGE: {
            return state;
        }

        case manageLicenses.CHANGE_LICENSE_NAME: {
            return state.merge({
                licenseName: action.payload.licenseName,
                ...resetErrors
            });
        }

        case manageLicenses.CHANGE_LICENSE_EMAIL: {
            return state.merge({
                licenseEmail: action.payload.licenseEmail,
                ...resetErrors
            });
        }

        case manageLicenses.SET_LICENSE_EMAIL_ERROR: {
            return state.set('wrongLicenseEmail', true);
        }

        case manageLicenses.SET_INSUFFICIENT_LICENSE_AMOUNT: {
            return state.set('insufficientLicenseAmount', true);
        }

        case manageLicenses.GET_ASSIGNED_LICENSES_FULFILLED: {
            return state.set('assignedLicenses', fromJS(action.payload));
        }

        case manageLicenses.ASSIGN_PENDING: {
            return state.set('assignPending', true);
        }

        case manageLicenses.LICENSE_ASSIGNED: {
            return state
                .update('assignedLicenses', x => x.push(fromJS(action.payload)))
                .merge({ licenseName: '', licenseEmail: '', assignPending: false });
        }

        case manageLicenses.ASSIGN_LICENSE_ERROR: {
            const newState = state.set('assignPending', false);
            if (action.payload.response) {
                const { code } = action.payload.response.data;
                if (code === 'assigned_by_other') {
                    return newState.set('assignedByOtherError', true);
                }
                if (code === 'already_assigned') {
                    return newState.set('alreadyAssignedError', true);
                }
            }
            return newState;
        }

        case manageLicenses.REMOVAL_PENDING: {
            return state.set('removalPending', true);
        }

        case manageLicenses.LICENSE_REMOVED: {
            return state
                .set('removalPending', false)
                .update('assignedLicenses', x => x.filter(license => license.get('id') !== action.payload));
        }

        case manageLicenses.SET_LICENSE_EXPIRING_PENDING: {
            const { id, expiring } = action.payload;
            return state.update(
                'assignedLicenses',
                x => x.map(license => (
                    license.get('id') === id
                        ? license.merge({ expiring, expiringPending: true })
                        : license
                ))
            );
        }

        case manageLicenses.SET_LICENSE_EXPIRING: {
            const { id } = action.payload;
            return state.update(
                'assignedLicenses',
                x => x.map(license => (
                    license.get('id') === id
                        ? license.merge({ expiringPending: false })
                        : license
                ))
            );
        }

        default: {
            return state;
        }
    }
}
