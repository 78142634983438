import React, { useEffect, useRef, useState } from 'react';
import { IWeekEvent } from '../../../interfaces/interfaces';
import moment from 'moment-timezone';
import * as R from 'ramda';
import classNames from 'classnames';
import { IconButton, Popover, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { isAdHoc, isLoggedIn } from '../../../scripts/helpers';
import { Moment } from 'moment';
import useEventListener from '@use-it/event-listener';
import { useTranslation } from 'react-i18next';
import { IHoliday } from '../../NewMeetingPageComponents/DateRangePicker/DateRangePicker';
import useAsyncEffect from '../../../utils/useAsyncEffect';
import BackendMethods from '../../../api/BackendMethods';
import { getTimezone } from 'countries-and-timezones';
import useInterval from '../../../utils/useInterval';
import pickColorForBackground from '../../../utils/pickColorForBackground';
const styles = require('./WeekCalendar.css');

interface Props<T = null> {
    startOfDay: Moment;
    setStartOfDay: (date: Moment) => void;
    rangeStart: string;
    rangeEnd: string;
    events: IWeekEvent<T>[];
    proposition: IWeekEvent<T> | null;
    timezone: string;
    timeFormat: string;
    workWeekEvents: IWeekEvent<T>[];
    isSmallBusiness?: boolean;
    selectedDate?: Moment;
    setSelectedSlot?: (data: T) => void;
    className?: string;
    showClose?: boolean;
    isDaily?: boolean;
    isMobile?: boolean;
    showHolidays?: boolean;
}

interface CalendarEventProps<T = null> {
    event: IWeekEvent<T>;
    timezone: string;
    cellWidth: number;
    isSmallBusiness: boolean;
    setPopoverEventKey: (key: any) => void;
    setAnchorEl: (anchor: any) => void;
    setSelectedSlot?: (data: T) => void;
    viewStart: Moment;
    hourFormat: string;
    isDaily?: boolean;
}
const cellHeight = 70;
const lineHeight = 13;
const verticalPadding = 4;

function CalendarEvent<T>(props: CalendarEventProps<T>) {
    const {
        event, timezone, cellWidth, isSmallBusiness, setPopoverEventKey, setAnchorEl, setSelectedSlot,
        viewStart, hourFormat, isDaily,
    } = props;
    const start = moment.tz(event.start, timezone);
    const end = moment.tz(event.end, timezone);
    const startHours = start.hour() + start.minute() / 60;
    const hoursDuration = end.hour() - start.hour() + (end.minute() - start.minute()) / 60;
    const key = event.id + event.start;
    const height = Math.ceil(hoursDuration * cellHeight);
    const innerHeight = height - verticalPadding;
    const eventTimezone = event.timezone || timezone;

    if (event.start === event.end) {
        return (
            <></>
        );
    }
    return (
        <div
            className={classNames({
                [styles.event]: true,
                [styles.proposition]: event.isProposition,
                [styles.workWeek]: event.isWorkWeek,
            })}
            style={{
                transform: `translate(
                    ${moment.tz(event.start, timezone).diff(viewStart, 'days') * cellWidth}px,
                    ${startHours * cellHeight}px
                )`,
                height: height,
                width: cellWidth,
                paddingBottom: (innerHeight < lineHeight) ? 0 : (innerHeight % lineHeight),
                ...(event.color ? {
                    backgroundColor: event.color,
                    color: pickColorForBackground(event.color),
                } : {}),
            }}
            onClick={({ target }) => {
                if (!event.isWorkWeek && !isSmallBusiness) {
                    setPopoverEventKey(key);
                    setAnchorEl(target);
                }
                if (isSmallBusiness && setSelectedSlot) {
                    setSelectedSlot(event.data);
                }
            }}
            {...event.isProposition && {
                ref: (el) => {
                    if (el) {
                        el.scrollIntoView({
                            block: 'center',
                            inline: 'center',
                        });
                    }
                }
            }}
        >
            <div
                className={classNames(styles.eventInner, {
                    [styles.eventOneLine]: hoursDuration < 0.43,
                })}
                style={{
                    WebkitLineClamp: Math.max(1, Math.floor((height - verticalPadding) / lineHeight)),
                }}
            >
                {event.subject && (typeof event.subject === 'string'
                    ? event.subject.split('\n').map((line, i) => (
                        <div key={i} className={classNames({ [styles.subjectLine]: event.subject2 })}>
                            {line}
                        </div>
                    )) : (
                        <div className={classNames({ [styles.subjectLine]: event.subject2 })}>
                            {event.subject}
                        </div>
                ))}
                {!event.allDay && !(isDaily && event.events.length > 1) && (
                    <div>
                        {
                            moment.tz(event.start, eventTimezone).isSame(moment.tz(event.start, eventTimezone).endOf('day'))
                                ? moment.tz(event.start, eventTimezone).add(1, 'day').startOf('day').format(hourFormat)
                                : moment.tz(event.start, eventTimezone).format(hourFormat)
                        }
                        {' – '}
                        {
                            moment.tz(event.end, eventTimezone).isSame(moment.tz(event.end, eventTimezone).endOf('day'))
                                ? moment.tz(event.end, eventTimezone).add(1, 'day').startOf('day').format(hourFormat)
                                : moment.tz(event.end, eventTimezone).format(hourFormat)
                        }
                        {eventTimezone !== timezone && (
                            <>
                                {' '}
                                {moment.tz(event.end, eventTimezone).format('z')}
                            </>
                        )}
                    </div>
                )}
                {event.subject2 && (
                    <div>
                        {event.subject2}
                    </div>
                )}
            </div>
        </div>
    );
}

const pickProps = [
    'subject', 'start', 'end', 'isProposition', 'isWorkWeek', 'dontGroup', 'data', 'id',
    'subject2', 'allDay', 'color', 'timezone',
];

export default function WeekCalendar<T>(props: Props<T>) {
    const {
        rangeStart, rangeEnd, events, proposition, timezone, workWeekEvents, timeFormat, isSmallBusiness,
        selectedDate, setSelectedSlot, className, startOfDay, setStartOfDay, showClose, isDaily, isMobile,
        showHolidays
    } = props;
    const { i18n: { language } } = useTranslation();
    const loggedIn = isLoggedIn();
    const [holidays, setHolidays] = useState<IHoliday[]>([]);
    useAsyncEffect(async () => {
        if (showHolidays && loggedIn) {
            const country = getTimezone(timezone).country.toLowerCase();
            setHolidays(await BackendMethods.getHolidays(rangeStart, rangeEnd, country, 'major'));
        }
    }, [showHolidays, rangeStart, rangeEnd]);
    const tableRef = useRef<HTMLTableElement | null>(null);
    const [rootWidth, setRootWidth] = useState(0);
    useEffect(() => {
        setRootWidth(tableRef.current.offsetWidth);
    }, [isDaily]);
    useEventListener('resize', () => {
        setRootWidth(tableRef.current.offsetWidth);
    });
    const endOfDay = moment(startOfDay).endOf('day');
    const startOfWeek = moment(startOfDay).startOf('week');
    const endOfWeek = moment(startOfDay).endOf('week');
    const viewStart = isDaily ? startOfDay : startOfWeek;
    const viewEnd = isDaily ? endOfDay : endOfWeek;
    const midnight = moment().startOf('day');
    const hourFormat = timeFormat === '12h' ? 'h:mm a' : 'H:mm';
    const processEvents = (eventsList: IWeekEvent<T>[]) => R.compose(
        isSmallBusiness ? ((x: IWeekEvent<T>[]): IWeekEvent<T>[] => x) : R.map((event: IWeekEvent<T>) => {
            const subject = (() => {
                if (event.events) {
                    const subjects = event.events.map((x) => (isDaily
                        ? (
                            <>
                                {x.subject}
                                {event.events.length > 1 && (
                                    <>
                                        {' '}
                                        {moment.tz(x.start, timezone).format(hourFormat)}
                                        {' – '}
                                        {moment.tz(x.end, timezone).format(hourFormat)}
                                    </>
                                )}
                            </>
                        )
                        : (x.subjectShort || x.subject)));
                    return subjects.every((x) => typeof x === 'string')
                        ? subjects.join(', ')
                        : (
                            <>
                                {R.intersperse(', ', subjects).map((x, i) => (
                                    <React.Fragment key={i}>
                                        {x}
                                    </React.Fragment>
                                ))}
                            </>
                        );
                }
                return event.subject;
            })();
            return {
                ...event,
                events: event.events || [event],
                subject,
            };
        }),
        R.filter((event: IWeekEvent<T>) => (
            [event.start, event.end].some(x => (
                moment.tz(x, timezone).isBetween(viewStart, viewEnd, null, '[]')
            ))
        )),
        // Merge overlapping events
        isSmallBusiness ? ((x: IWeekEvent<T>[]) => x) : R.reduce((acc: IWeekEvent<T>[], event: IWeekEvent<T>) => {
            const previousEvent = R.findLast(x => (!x.dontGroup && x.id !== event.id), acc);
            if (previousEvent && !event.dontGroup && moment(previousEvent.end).isAfter(event.start)) {
                return [
                    ...acc.filter((x) => x.id !== previousEvent.id),
                    {
                        id: event.id,
                        subject: '',
                        start: previousEvent.start,
                        end: moment.max(
                            moment(previousEvent.end),
                            moment(event.end)
                        ).toISOString(),
                        events: [
                            ...previousEvent.events,
                            ...event.events
                        ],
                    },
                ];
            }
            return [...acc, event];
        }, []),
        R.sortBy(event => moment(event.start).unix()),
        // Split events lasting multiple days into one event per day
        isSmallBusiness ? ((x: IWeekEvent<T>[]) => x) : R.chain(R.unfold((event: IWeekEvent<T> | null) => {
            if (event === null) {
                return false;
            }
            const end = moment.min(
                moment.tz(event.end, timezone),
                moment.tz(event.start, timezone).endOf('day')
            );
            return [
                {
                    ...R.pick(pickProps, event),
                    end: end.toISOString(),
                    events: event.events || [event],
                },
                moment.tz(event.end, timezone).isSameOrBefore(event.start, 'day') || (
                    moment.tz(event.end, timezone).get('hour') === 0 &&
                    moment.tz(event.end, timezone).get('minute') === 0
                ) ? null
                    : {
                        ...R.pick(pickProps, event),
                        start: moment(end).add(1, 'ms').toISOString(),
                        events: event.events || [event],
                    }
            ];
        })),
    )(eventsList);
    const processedEvents = processEvents([
        ...events,
        ...(proposition ? [{
            ...proposition,
            isProposition: true,
            dontGroup: true,
        }] : []),
    ]);
    const processedWorkWeekEvents = processEvents(workWeekEvents);
    const hoursWidth = 40;
    const cellWidth = (rootWidth - hoursWidth) / (isDaily ? 1 : ((rootWidth > 480 || isMobile) ? 7 : 5));
    const [popoverEventKey, setPopoverEventKey] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const popoverEvent = processedEvents.find((x) => (x.id + x.start) === popoverEventKey);
    const canGoBack = viewStart.isAfter(rangeStart);
    const canGoForward = viewEnd.isBefore(rangeEnd);

    const tableContainerRef = useRef<HTMLDivElement | null>(null);
    const sevenRef = useRef<HTMLTableRowElement | null>(null);
    useEffect(() => {
        tableContainerRef.current.scrollTop = sevenRef.current.offsetTop + 40;
        if (language === 'en') {
            tableContainerRef.current.scrollLeft = cellWidth;
        }
    }, [language, cellWidth]);

    useEffect(() => {
        if (proposition) {
            const newStartOfDay = moment.tz(proposition.start, timezone).startOf(isDaily ? 'day' : 'week');
            if (!startOfDay.isSame(newStartOfDay)) {
                setStartOfDay(newStartOfDay);
            }
        } else if (!viewStart.isBetween(rangeStart, rangeEnd, null, '[]')) {
            setStartOfDay(moment.tz(rangeStart, timezone).startOf(isDaily ? 'day' : 'week'));
        }
    }, [proposition, rangeStart]);

    const [currentTime, setCurrentTime] = useState(moment());
    useInterval(() => {
        setCurrentTime(moment());
    }, 3 * 60 * 1000, []);

    return (
        <div
            className={classNames(styles.root, className, {
                [styles.smallBusiness]: isSmallBusiness,
                [styles.mobile]: isMobile,
                [styles.daily]: isDaily,
            })}
        >
            {!(isMobile && !isDaily) && (
                <div className={styles.top}>
                    {!isMobile && (
                        <div className={styles.navigation}>
                            <IconButton
                                onClick={() => (
                                    setStartOfDay(moment(startOfDay)
                                        .subtract(1, isDaily ? 'day' : 'week'))
                                )}
                                disabled={!canGoBack}
                                size="large">
                                <div className={classNames(styles.icon, styles.iconLeft)} />
                            </IconButton>
                            <IconButton
                                onClick={() => (
                                    setStartOfDay(moment(startOfDay)
                                        .add(1, isDaily ? 'day' : 'week'))
                                )}
                                disabled={!canGoForward}
                                size="large">
                                <div className={styles.icon} />
                            </IconButton>
                        </div>
                    )}
                    <div className={styles.month}>
                        {isMobile && viewStart.format('dddd, ')}
                        {(() => {
                            if (isDaily) {
                                return viewStart.format('D MMMM Y');
                            }
                            if (viewStart.isSame(viewEnd, 'month')) {
                                return viewStart.format('MMMM Y');
                            }
                            if (viewStart.isSame(viewEnd, 'year')) {
                                return `${viewStart.format('MMMM')} – ${viewEnd.format('MMMM Y')}`;
                            }
                            return `${viewStart.format('MMMM Y')} – ${viewEnd.format('MMMM Y')}`;
                        })()}
                    </div>
                    {(() => {
                        if (isDaily && isMobile) {
                            const holiday = holidays.find((x) => moment(x.date).isSame(viewStart, 'day'));
                            if (holiday) {
                                return (
                                    <div className={classNames(styles.holiday, styles.mobileDailyHoliday)}>
                                        {holiday.names[language] || holiday.names.en}
                                    </div>
                                );
                            }
                        }
                    })()}
                    {!isAdHoc() && showClose && (
                        <Link to="/main">
                            <img
                                src="/assets/close_icon.svg"
                                className={styles.closeIcon}
                            />
                        </Link>
                    )}
                </div>
            )}
            <div className={styles.tableContainer} ref={tableContainerRef}>
                <table
                    className={styles.table}
                    ref={tableRef}
                >
                    {!(isMobile && isDaily) && (
                        <thead>
                            <tr
                                className={classNames(styles.header, {
                                    [styles.headerHolidays]: showHolidays,
                                })}
                            >
                                <th className={styles.hoursCover} />
                                <th className={styles.hoursCover2} />
                                {R.times(i => {
                                    const date = moment(viewStart).add(i, 'days');
                                    const holiday = holidays.find((x) => moment(x.date).isSame(date, 'day'));
                                    return (
                                        <th
                                            key={i}
                                            className={styles.cell}
                                        >
                                            <div className={classNames(styles.day, {
                                                [styles.today]: date.isSame(
                                                    moment().tz(timezone),
                                                    'day',
                                                ),
                                                [styles.selectedDay]: selectedDate
                                                && date.isSame(selectedDate, 'day'),
                                                [styles.dayLeft]: rootWidth > 600,
                                            })}>
                                                <div className={styles.dayOfWeek}>
                                                    {date.format(isMobile ? 'dd' : 'ddd')}
                                                </div>
                                                <div className={styles.dayOfMonth}>
                                                    {date.format('D')}
                                                </div>
                                                {showHolidays && (
                                                    <div className={styles.holidayContainer}>
                                                        {holiday && (
                                                            <Tooltip
                                                                title={holiday.names[language] || holiday.names.en}
                                                            >
                                                                <div className={styles.holiday}>
                                                                    {holiday.names[language] || holiday.names.en}
                                                                </div>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                    );
                                }, isDaily ? 1 : 7)}
                            </tr>
                        </thead>
                    )}
                    <tbody>
                    {R.times(i => {
                        return (
                            <tr key={i} ref={i === 6 ? sevenRef : null}>
                                <th scope="row" className={styles.hourCell}>
                                    <div className={styles.hour}>
                                        {moment(midnight).add(i + 1, 'hour')
                                            .format(timeFormat === '12h' ? 'h a' : 'H:00')}
                                    </div>
                                </th>
                                <th scope="row" className={styles.hourCell2} />
                                {R.times(j => (
                                    <td key={j} className={classNames(styles.cell, styles.dataCell)} />
                                ), isDaily ? 1 : 7)}
                            </tr>
                        );
                    }, 24)}
                    </tbody>
                </table>
                <div
                    className={classNames(styles.eventsContainer, {
                        [styles.eventsContainerHolidays]: showHolidays,
                    })}
                >
                    <div className={styles.eventsContainerInner}>
                        {R.reverse([...processedEvents, ...processedWorkWeekEvents]).map((event, i) => (
                            <CalendarEvent
                                event={event}
                                timezone={timezone}
                                cellWidth={cellWidth}
                                isSmallBusiness={isSmallBusiness}
                                setPopoverEventKey={setPopoverEventKey}
                                setAnchorEl={setAnchorEl}
                                setSelectedSlot={setSelectedSlot}
                                viewStart={viewStart}
                                hourFormat={hourFormat}
                                key={i}
                                isDaily={isDaily}
                            />
                        ))}
                        <div
                            className={styles.currentTimeIndicator}
                            style={{
                                width: cellWidth,
                                transform: `translate(
                                    ${currentTime.diff(viewStart, 'days') * cellWidth}px,
                                    ${(currentTime.hour() + currentTime.minute() / 60) * cellHeight}px
                                )`,
                            }}
                        />
                    </div>
                </div>
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                onClose={() => setAnchorEl(null)}
            >
                {popoverEvent && popoverEvent.events.map(event => (
                    <div className={styles.popoverEvent} key={event.id + event.start}>
                        <div>
                            {event.subject}
                        </div>
                        {!event.allDay && (
                            <div>
                                {moment.tz(event.start, event.timezone || timezone).format(hourFormat)}
                                {' – '}
                                {moment.tz(event.end, event.timezone || timezone).format(hourFormat)}
                                {event.timezone && event.timezone !== timezone && (
                                    <>
                                        {' '}
                                        {moment.tz(event.end, event.timezone).format('z')}
                                    </>
                                )}
                            </div>
                        )}
                        {event.subject2 && (
                            <div>
                                {event.subject2}
                            </div>
                        )}
                    </div>
                ))}
            </Popover>
        </div>
    );
}
