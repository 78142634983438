import * as React from 'react';
import MeetingTitleWithImage from '../MeetingTitleWithImage/MeetingTitleWithImage';
import IMeeting from 'interfaces/IMeeting';
import DescriptionTextToggle from '../../_ReusableComponents/Toggle/DescriptionTextToggle/DescriptionTextToggle';
import IActions from 'actions/IActions';
import ILocation from '../../../interfaces/location/ILocation';
import IPropositionAnswer from '../../../interfaces/IPropositionAnswer';
import Header from '../../_ReusableComponents/Header/Header/Header';
import MeetingParticipants from '../SingleMeeting/MeetingParticipants/MeetingParticipants';
import getMeetingMessage from '../../../utils/getMeetingMessage';
import { useTranslation } from 'react-i18next';
const Styles = require('./MeetingCancel.css');

interface Props {
    actions: IActions;
    meeting: any;
    data: any;
    location: ILocation;
    yesNoButtonsStateArray: IPropositionAnswer[];
    history: any;
    currentMeeting: IMeeting;
    email: string;
}

export default function MeetingCancel(props: Props) {
    const { t } = useTranslation();
    const { currentMeeting: meeting } = props;
    return (
        <div id="MeetingCancel" className={Styles.MeetingCancel}>
            <Header/>
            <div className={Styles.message}>
                {getMeetingMessage(meeting, props.email, t)}
            </div>
            <section className={Styles.View}>
                <MeetingTitleWithImage title={meeting.title} />
                <MeetingParticipants
                    email={props.email}
                    canEditTimezone={false}
                    actions={props.actions}
                    defaultVisible
                    meeting={meeting}
                />
                <DescriptionTextToggle
                    description={meeting.description}
                />
            </section>
        </div>
    );
}
