// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".paper__1nxnE {\n    padding: 24px;\n    margin: 16px;\n    display: flex;\n    flex-direction: column;\n}\n\n.dialogButtons__2V6t5 {\n    display: flex;\n    justify-content: center;\n}\n\n@media (min-width: 1250px) {\n\n.dialogButtons__2V6t5 {\n        justify-content: flex-end\n}\n    }\n\n.dialogCancelButton__1o1zn {\n    margin-right: 38px;\n}\n\n.dialogText__2zJpy {\n    margin: 0 0 24px 0;\n}\n", "",{"version":3,"sources":["AddEmailDialog.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;AAI3B;;AAHI;;AAHJ;QAIQ;AAER;IADI;;AAGJ;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","file":"AddEmailDialog.css","sourcesContent":[".paper {\n    padding: 24px;\n    margin: 16px;\n    display: flex;\n    flex-direction: column;\n}\n\n.dialogButtons {\n    display: flex;\n    justify-content: center;\n    @media (min-width: 1250px) {\n        justify-content: flex-end;\n    }\n}\n\n.dialogCancelButton {\n    margin-right: 38px;\n}\n\n.dialogText {\n    margin: 0 0 24px 0;\n}\n"]}]);
// Exports
exports.locals = {
	"paper": "paper__1nxnE",
	"dialogButtons": "dialogButtons__2V6t5",
	"dialogCancelButton": "dialogCancelButton__1o1zn",
	"dialogText": "dialogText__2zJpy"
};
module.exports = exports;
