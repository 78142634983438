import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@material-ui/core';
const styles = require('./Nav.css');

export default function Nav() {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    return (
        <div className={styles.header}>
            <Link
                className={styles.logo}
                to="/"
            />
            <Button
                variant="text"
                className={styles.menuButton}
                component={Link}
                to="/#features"
            >
                {t('landing.features')}
            </Button>
            <Button
                variant="text"
                className={styles.menuButton}
                component={Link}
                to="/#our-customers"
            >
                {t('landing.ourCustomers')}
            </Button>
            <Button
                variant="text"
                className={styles.menuButton}
                component={Link}
                to="/#pricing"
            >
                {t('landing.pricing')}
            </Button>
            <Button
                variant="text"
                className={styles.menuButton}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                {t('landing.useCases')}
            </Button>
            <Menu
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem
                    component={Link}
                    to="/customer-success"
                    onClick={() => setAnchorEl(null)}
                >
                    {t('landing.customerSuccess')}
                </MenuItem>
                <MenuItem
                    component={Link}
                    to="/for-sales"
                    onClick={() => setAnchorEl(null)}
                >
                    {t('landing.forSales')}
                </MenuItem>
            </Menu>
            <Button
                variant="contained"
                className={styles.menuButton}
                component={Link}
                to="/register"
            >
                {t('landing.signUp')}
            </Button>
            <Button
                variant="text"
                className={styles.menuButton}
                component={Link}
                to="/login"
            >
                {t('landing.signIn')}
            </Button>
        </div>
    );
}
