import React from 'react';
import SupportForm from '../../components/_ReusableComponents/SupportForm/SupportForm';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
const styles = require('./Contact.css');

interface Props {
    app: any;
    actions: any;
}

function Contact({ app, actions }: Props) {
    const { t } = useTranslation();
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                {t('home.pleaseFillContact')}
            </div>
            <div className={styles.form}>
                <SupportForm app={app} actions={actions} isLandingContactForm />
            </div>
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(Contact);
