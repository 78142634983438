import moment from 'moment-timezone';
import { IWeek, IWeekEvent, IWorkHours } from '../interfaces/interfaces';
import { SortWorkWeekHour } from './MainPageUtils/MainPageUtils';

function getWorkWeekEvents(
    timezone: string,
    workWeek: IWorkHours[],
    start: string,
    end: string
): IWeekEvent[] {
    const weekDay = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const sortedWorkWeek: IWorkHours[] = SortWorkWeekHour(workWeek);

    const getEvents = (currentStart: string) => {
        if (moment(currentStart).isBefore(end)) {
            const weekEvnets: IWeekEvent[] = [];
            let weekEvent: IWeekEvent;
            const currendWeekDay: number = moment.tz(currentStart, timezone).day();
            let currentDay: number = sortedWorkWeek.findIndex((workDay) => (workDay.days.includes(weekDay[currendWeekDay] as IWeek)));
            weekEvent = {
                id: `work-week-${currentStart}-${sortedWorkWeek[currentDay].begin}`,
                subject: '',
                start: currentStart,
                end: sortedWorkWeek[currentDay].begin === 'none'
                    ? moment
                        .tz(currentStart, timezone)
                        .endOf('day')
                        .toISOString()
                    : moment
                        .tz(currentStart, timezone)
                        .add(moment.duration(sortedWorkWeek[currentDay].begin))
                        .toISOString(),
                isWorkWeek: true,
            };
            weekEvnets.push(weekEvent);
            const currentLength: number = sortedWorkWeek.length;
            for (let i: number = 1; i < currentLength; i++) {
                if (sortedWorkWeek[(i + currentDay) % currentLength].days[0] !== sortedWorkWeek[(i + currentDay - 1) % currentLength].days[0]) {
                    if (
                        weekEvent.end !== moment.tz(currentStart, timezone).endOf('day').toISOString()
                        && sortedWorkWeek[(i + currentDay - 1) % currentLength].end !== '00:00'
                    ) {
                        weekEvent = {
                            id: `work-week-${currentStart}-${sortedWorkWeek[(i + currentDay - 1) % currentLength].end}`,
                            subject: '',
                            start: moment
                                .tz(currentStart, timezone)
                                .add(moment.duration(sortedWorkWeek[(i + currentDay - 1) % currentLength].end))
                                .toISOString(),
                            end: moment
                                .tz(currentStart, timezone)
                                .endOf('day')
                                .toISOString(),
                            isWorkWeek: true,
                        };
                        weekEvnets.push(weekEvent);
                    }
                    currentStart = moment.tz(currentStart, timezone).add(1, 'day').toISOString();
                    weekEvent = {
                        id: `work-week-${currentStart}-${sortedWorkWeek[i].begin}`,
                        subject: '',
                        start: currentStart,
                        end: sortedWorkWeek[(i + currentDay) % currentLength].begin === 'none'
                            ? moment
                                .tz(currentStart, timezone)
                                .endOf('day')
                                .toISOString()
                            : moment
                                .tz(currentStart, timezone)
                                .add(moment.duration(sortedWorkWeek[(i + currentDay) % currentLength].begin))
                                .toISOString(),
                        isWorkWeek: true,
                    };
                    weekEvnets.push(weekEvent);
                } else {
                    weekEvent = {
                        id: `work-week-${currentStart}-${sortedWorkWeek[(i + currentDay - 1) % currentLength].begin}`,
                        subject: '',
                        start: moment
                            .tz(currentStart, timezone)
                            .add(moment.duration(sortedWorkWeek[(i + currentDay - 1) % currentLength].end))
                            .toISOString(),
                        end: moment
                            .tz(currentStart, timezone)
                            .add(moment.duration(sortedWorkWeek[(i + currentDay) % currentLength].begin))
                            .toISOString(),
                        isWorkWeek: true,
                    };
                    weekEvnets.push(weekEvent);
                }
            }
            return weekEvnets;
        } else {
            return [];
        }
    };
    return getEvents(start);
}

export default getWorkWeekEvents;
