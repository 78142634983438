import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IActions from 'actions/IActions';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import EnterpriseLogo from '../../components/_ReusableComponents/EnterpriseLogo/EnterpriseLogo';
import GoogleSignInButton from 'components/LoginPageComponents/Login/GoogleButton/GoogleSignInButton';
import OuterLoginBg from '../../components/_ReusableComponents/OuterLoginBg/OuterLoginBg';
import Register from '../../components/RegisterPageComponents/Register/Register';
import LoginFooter from '../../components/_ReusableComponents/Footer/LoginFooter/LoginFooter';
import YoutubeButton from '../../components/_ReusableComponents/Buttons/YoutubeButton/YoutubeButton';
import MicrosoftSignInButton from '../../components/LoginPageComponents/Login/MicrosoftSignInButton';
import { useTranslation, Trans } from 'react-i18next';
const styles = require('./RegisterPage.css');

interface Props {
    actions: IActions;
    data: any;
}

function RegisterPage(props: Props) {
    const { t } = useTranslation();
    const [error, setError] = useState<string>(null);
    const [successEmail, setSuccessEmail] = useState<string>(null);
    return (
        <div className={styles.root}>
            <YoutubeButton />
            <OuterLoginBg />
            <div className={styles.RegisterBackground}>
                <div id="RegisterPage" className={styles.RegisterPage}>
                    <EnterpriseLogo logoSrc={props.data.get('logoSrc')} />
                    {successEmail === null ? (
                        <>
                            {error && (
                                <div className={styles.error}>
                                    {error}
                                </div>
                            )}
                            <GoogleSignInButton text={t('auth.continueWithGoogle')} />
                            <MicrosoftSignInButton text={t('auth.continueWithMicrosoft')} />
                            <div className={styles.CenterLabel}>{t('auth.or')}</div>
                            <Register
                                {...props}
                                showError={setError}
                                showSuccess={email => setSuccessEmail(email)}
                            />
                            <div className={styles.Registered}>
                                <Link
                                    to="/login"
                                    className={styles.Link}
                                >
                                    {t('auth.alreadyRegistered')}
                                    {' '}
                                    <span className={styles.blueLink}>
                                        {t('auth.logIn')}
                                    </span>
                                </Link>
                            </div>
                            <div className={styles.LoginFooter}>
                                <LoginFooter />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.successText}>
                                <Trans
                                    i18nKey="auth.weHaveSent"
                                    values={{ email: successEmail }}
                                />
                            </div>
                            <div className={styles.successText}>
                                {t('auth.pleaseOpen')}
                            </div>
                            <div className={styles.didNotReceive}>
                                <Trans
                                    i18nKey="auth.toResend"
                                    components={{
                                        a: <Link to="/login" className={styles.blueLink} />
                                    }}
                                />
                            </div>
                            <Link to="/login" className={styles.blueLink}>
                                {t('auth.goToLogin')}
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(RegisterPage);
