import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { allCountries } from 'country-telephone-data';
import { getTimezone } from 'countries-and-timezones';
import moment from 'moment';
import * as R from 'ramda';
import Select from '../../components/_ReusableComponents/Select/Select';
import TextField from '../../components/_ReusableComponents/TextField/TextField';
import Button from '../../components/_ReusableComponents/Button/Button';
import { Link } from 'react-router-dom';
import auth from 'auth/Auth';
import { FormHelperText } from '@material-ui/core';
import IActions from '../../actions/IActions';
import PhoneNumber from 'awesome-phonenumber';
import { connect } from 'react-redux';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
const config = require('CONFIG');
const styles = require('./PhoneLoginComponent.css');

const CODE_LENGTH = 6;

interface Props {
    actions: IActions;
    phoneStepHeader?: string;
    noBack?: boolean;
    onSubmit?: () => void;
}

function PhoneLoginComponent(props: Props) {
    const { t, i18n } = useTranslation();
    const [countryCode, setCountryCode] = useState<string>(
        getTimezone(moment.tz.guess()).country.toLowerCase(),
    );
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [codeStep, setCodeStep] = useState(false);
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [codeError, setCodeError] = useState(false);
    const inputRefs = useRef([]);
    useEffect(() => {
        if (codeStep && inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, [codeStep]);
    const getLabel = (countryCode2: string) => {
        const { iso2, dialCode } = allCountries.find(R.propEq('iso2', countryCode2));
        return `${iso2.toUpperCase()} +${dialCode}`;
    };
    const fullPhone = (() => {
        const { dialCode } = allCountries.find(R.propEq('iso2', countryCode));
        return new PhoneNumber(`+${dialCode}${phone}`).getNumber();
    })();
    const sendCode = (callback) => {
        fetch(`https://${config.auth0Domain}/passwordless/start`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'x-request-language': i18n.language,
            },
            body: JSON.stringify({
                client_id: config.auth0ClientID,
                connection: 'sms',
                send: 'code',
                phone_number: fullPhone,
            }),
        }).then((response) => response.json()).then((data) => {
            if ('error' in data) {
                callback(data.error);
            } else {
                callback(null);
            }
        });
    };
    return (
        <div className={styles.root}>
            {!codeStep ? (
                <form
                    noValidate
                    onSubmit={(event) => {
                        event.preventDefault();
                        if (phone) {
                            sendCode((error) => {
                                if (error) {
                                    setPhoneError(true);
                                } else {
                                    setCodeStep(true);
                                }
                            });
                        }
                    }}
                >
                    <div className={styles.header}>
                        {props.phoneStepHeader || t('auth.enterPhoneNumber')}
                    </div>
                    <div className={styles.fieldRow}>
                        <Select
                            label=""
                            value={{ value: countryCode, label: getLabel(countryCode) }}
                            onChange={option => setCountryCode(option.value)}
                            options={allCountries.map(({ iso2 }) => ({
                                value: iso2,
                                label: getLabel(iso2),
                            }))}
                            className={styles.select}
                        />
                        <TextField
                            value={phone}
                            onChange={({ target }) => {
                                setPhone(target.value);
                                setPhoneError(false);
                            }}
                            label={t('auth.phoneNumber')}
                            className={styles.phone}
                            error={phoneError}
                            helperText={phoneError ? t('auth.invalidNumber') : ''}
                            required
                        />
                    </div>
                    <div className={styles.buttonRow}>
                        {!props.noBack && (
                            <Button
                                variant="text"
                                component={Link}
                                to="/login"
                                className={styles.back}
                            >
                                {t('auth.back')}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!phone}
                            className={styles.submitButton}
                        >
                            {t('auth.continue')}
                        </Button>
                    </div>
                </form>
            ) : (
                <form
                    noValidate
                    onSubmit={(event) => {
                        event.preventDefault();
                        if (code.join('').length === CODE_LENGTH) {
                            if (props.onSubmit) {
                                props.onSubmit();
                            }
                            auth.webAuth.passwordlessLogin({
                                connection: 'sms',
                                phoneNumber: fullPhone,
                                verificationCode: code.join(''),
                            }, (error) => {
                                if (error) {
                                    setCodeError(true);
                                }
                            });
                        }
                    }}
                >
                    <div className={styles.header}>
                        {t('auth.enterCode')}
                    </div>
                    <div className={styles.fieldRow}>
                        {R.times((i) => (
                            <TextField
                                key={i}
                                value={code[i]}
                                onChange={({ target }) => {
                                    if (target.value.length === CODE_LENGTH) {
                                        setCode(target.value.split(''));
                                    } else {
                                        setCode(R.update(i, R.take(1, target.value)));
                                        if (target.value !== '' && i !== CODE_LENGTH - 1) {
                                            inputRefs.current[i + 1].focus();
                                        }
                                    }
                                    setCodeError(false);
                                }}
                                inputRef={(el) => inputRefs.current[i] = el}
                                className={styles.codeInput}
                                autoComplete="off"
                                inputProps={{
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                }}
                            />
                        ), CODE_LENGTH)}
                    </div>
                    {codeError && (
                        <FormHelperText error className={styles.codeError}>
                            {t('auth.invalidCode')}
                        </FormHelperText>
                    )}
                    <div className={styles.buttonRow}>
                        <Button
                            variant="text"
                            onClick={() => {
                                setCodeStep(false);
                                setCode(['', '', '', '', '', '']);
                            }}
                            className={styles.back}
                        >
                            {t('auth.back')}
                        </Button>
                        <Button
                            variant="contained"
                            disabled={code.join('').length !== CODE_LENGTH}
                            type="submit"
                        >
                            {t('auth.confirmNumber')}
                        </Button>
                    </div>
                    <div className={styles.resendContainer}>
                        {t('auth.didntReceive')}
                        <Button
                            variant="text"
                            onClick={() => sendCode(() => {
                                props.actions.showSuccessMessage(t('auth.codeSent'));
                            })}
                            className={styles.resendButton}
                        >
                            {t('auth.resend')}
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(PhoneLoginComponent);
