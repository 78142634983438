import * as preferences from '../../actions/PreferencesActions';
import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS } from 'immutable';
import * as Redirect from '../../utils/Redirect';
import PreferencesUtils from 'utils/PreferencesUtils/PreferencesUtils';
import * as R from 'ramda';
import { IWorkHours } from 'interfaces/interfaces';

const INITIAL_STATE = fromJS({
    isMyPersonalInformationVisible: true,
    isIntegrationsVisible: false,
    isMyContactsVisible: false,
    isVideoConfsVisible: false,
    isMyWorkWeekVisible: false,
    isLoadingCalendars: false,
    isLoadingRemoveCalendarYesButton: false,
    isRemoveCalendarModalVisible: false,
    currentCalendarEmailToRemove: '',
    currentCalendarTypeToRemove: '',
    currentCalendars: [],
    shouldReloadCalendars: false,
    showCalendarListElementsAnimation: true,
    isSetTimezoneButtonLoading: false,
    workDays: [],
    isPhoneNumberIncorrect: false,
    isNameIncorrect: false,
    isPreferencesMyWorkWeekSubmitButtonVisible: false,
    isPreferencesMyWorkWeekSubmitButtonLoading: false,
    isPreferencesMyInfoSubmitButtonLoading: false,
    isHoursInputsVisible: false,
    validData: false,
    errorMessagePreferences: false,
    isChooseCalendarTypeVisible: false,
    calendarAccount: '',
    profile: {},
}).set('isPreferencesMyInfoSubmitButtonVisible', false);

export default function preferencesReducer(
    state = INITIAL_STATE,
    action: any = { type: '', payload: {} }
) {
    if (action.type === LOCATION_CHANGE) {
        {
            state = INITIAL_STATE;
            return state;
        }
    } else if (action.type === preferences.SET_CURRENT_TIMEZONE) {
        return state
            .set('isPreferencesMyInfoSubmitButtonVisible', true)
            .update('profile', R.mergeLeft({ timezone: action.payload }));
    } else if (action.type === preferences.SET_TIME_FORMAT) {
        return state
            .set('isPreferencesMyInfoSubmitButtonVisible', true)
            .update('profile', R.mergeLeft({ timeFormat: action.payload }));
    } else if (action.type === preferences.UPDATE_USER_PROFILE_PENDING) {
        return state.set('isPreferencesMyInfoSubmitButtonLoading', true);
    } else if (action.type === preferences.UPDATE_USER_PROFILE_REJECTED) {
        return state.set('isPreferencesMyInfoSubmitButtonLoading', false);
    } else if (action.type === preferences.UPDATE_USER_PROFILE_FULFILLED) {
        Redirect.locationChange('/preferences');
        return state.set('isPreferencesMyInfoSubmitButtonLoading', false);
    } else if (action.type === preferences.DO_NOT_SHOW_ERROR_MESSAGE) {
        return state.set('errorMessagePreferences', false);
    } else if (action.type === preferences.PUT_MY_WORK_WEEK_PENDING) {
        return state
            .set('validData', false)
            .set('isPreferencesMyWorkWeekSubmitButtonLoading', true);
    } else if (action.type === preferences.PUT_MY_WORK_WEEK_REJECTED) {
        return state.set('isPreferencesMyWorkWeekSubmitButtonLoading', false);
    } else if (action.type === preferences.PUT_MY_WORK_WEEK_FULFILLED) {
        return state
            .set('isPreferencesMyWorkWeekSubmitButtonLoading', false)
            .set('isPreferencesMyWorkWeekSubmitButtonVisible', false);
    } else if (action.type === preferences.SET_HOUR_INPUTS_VISIBLE_STATE) {
        return state.set('isHoursInputsVisible', action.payload);
    } else if (action.type === preferences.TOGGLE_WORK_DAY) {
        const workDay: string = (action.payload || '').toLowerCase();
        const workDays: string[] = PreferencesUtils.getShortWorkDaysArrayFirstLetterLowercase(
            state.get('workDays') || []
        );
        let newWorkDays: string[] = R.symmetricDifference(workDays, [workDay]).filter((x) => Boolean(x));
        return PreferencesUtils.getCleanDaysInputState(
            PreferencesUtils.getDisableInputState(state, newWorkDays)
        )
            .set(
                'workDays',
                PreferencesUtils.getShortWorkDaysArrayFirstLetterUppercase(
                    newWorkDays
                )
            )
            .set('isPreferencesMyWorkWeekSubmitButtonVisible', true)
            .set(
                'workDaysInput',
                PreferencesUtils.getShortWorkDaysArrayFirstLetterUppercase(
                    PreferencesUtils.getSortedWorkDays(newWorkDays)
                ).join(', ')
            );
    } else if (action.type === preferences.TOGGLE_HOURS_INPUTS) {
        const isHoursInputsVisible: boolean = state.get('isHoursInputsVisible');
        return state.set('isHoursInputsVisible', !isHoursInputsVisible);
    } else if (action.type === preferences.SET_PREFERENCES_CALENDARS) {
        return state.set('workDays', action.payload.workWeek);
    } else if (action.type === preferences.TOGGLE_MY_CALENDARS) {
        return PreferencesUtils.getToggleTabState(
            state,
            'isIntegrationsVisible',
            'isMyPersonalInformationVisible',
            'isMyWorkWeekVisible',
            'isMyContactsVisible',
            'isVideoConfsVisible',
            returnedState =>
                returnedState.set('showCalendarListElementsAnimation', true)
        );
    } else if (action.type === preferences.TOGGLE_MY_WORK_WEEK) {
        return PreferencesUtils.getToggleTabState(
            state,
            'isMyWorkWeekVisible',
            'isMyContactsVisible',
            'isMyPersonalInformationVisible',
            'isIntegrationsVisible',
            'isVideoConfsVisible',
        );
    } else if (action.type === preferences.TOGGLE_MY_PERSONAL_INFORMATION) {
        return PreferencesUtils.getToggleTabState(
            state,
            'isMyPersonalInformationVisible',
            'isMyWorkWeekVisible',
            'isMyContactsVisible',
            'isIntegrationsVisible',
            'isVideoConfsVisible',
        );
    } else if (action.type === preferences.TOGGLE_MY_CONTACTS) {
        return PreferencesUtils.getToggleTabState(
            state,
            'isMyContactsVisible',
            'isMyPersonalInformationVisible',
            'isMyWorkWeekVisible',
            'isIntegrationsVisible',
            'isVideoConfsVisible',
        );
    } else if (action.type === preferences.TOGGLE_VIDEO_CONFS) {
        return PreferencesUtils.getToggleTabState(
            state,
            'isVideoConfsVisible',
            'isMyContactsVisible',
            'isMyPersonalInformationVisible',
            'isMyWorkWeekVisible',
            'isIntegrationsVisible',
        );
    } else if (action.type === preferences.HIDE_SUBMIT_BUTTON) {
        return state.set('isPreferencesMyInfoSubmitButtonVisible', false);
    } else if (action.type === preferences.SAVE_WORK_WEEK_HOURS) {
        return state.set('workDays', [...state.get('workDays'), action.payload]);
    } else if (action.type === preferences.REMOVE_WORK_WEEK_HOURS) {
        const workDays: IWorkHours[] = [...state.get('workDays')];
        const index = workDays.findIndex((workDay) => workDay.id === action.payload);
        if (index > -1) {
            workDays.splice(index, 1);
        }
        return state.set('workDays', [...workDays]);
    } else {
        return state;
    }
}
