import PlusPlanAdminActions from './PlusPlanAdminActions';
import PlusPlanPaymentActions from './PlusPlanPaymentActions';
import AppActions from './AppActions';
import MeetingActions from './MeetingActions';
import PreferencesActions from './PreferencesActions';
import ManageLicensesActions from './ManageLicensesActions';
import DataActions from './DataActions';

const actions = [
    DataActions,
    AppActions,
    PlusPlanAdminActions,
    PlusPlanPaymentActions,
    ManageLicensesActions,
    MeetingActions,
    PreferencesActions,
];

export default actions;
