// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".GroupNavigation__3_vnB {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 60px;\n}\n\n.backButton__2Md8P {\n    width: 100px;\n    font-weight: 400 !important;\n}\n\n.Button__3IT7r {\n    width: 100px;\n}\n", "",{"version":3,"sources":["GroupNavigation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,YAAY;AAChB","file":"GroupNavigation.css","sourcesContent":[".GroupNavigation {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 60px;\n}\n\n.backButton {\n    width: 100px;\n    font-weight: 400 !important;\n}\n\n.Button {\n    width: 100px;\n}\n"]}]);
// Exports
exports.locals = {
	"GroupNavigation": "GroupNavigation__3_vnB",
	"backButton": "backButton__2Md8P",
	"Button": "Button__3IT7r"
};
module.exports = exports;
