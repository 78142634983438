// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".sectionHeader__2S3ZA {\n    font-size: 35px;\n    font-weight: 700 !important;\n    margin: 120px 0 80px 0;\n    text-align: center;\n    text-transform: capitalize;\n}\n", "",{"version":3,"sources":["ForWhoms.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,2BAA2B;IAC3B,sBAAsB;IACtB,kBAAkB;IAClB,0BAA0B;AAC9B","file":"ForWhoms.css","sourcesContent":[".sectionHeader {\n    font-size: 35px;\n    font-weight: 700 !important;\n    margin: 120px 0 80px 0;\n    text-align: center;\n    text-transform: capitalize;\n}\n"]}]);
// Exports
exports.locals = {
	"sectionHeader": "sectionHeader__2S3ZA"
};
module.exports = exports;
