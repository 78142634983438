import React from 'react';
import { useTranslation } from 'react-i18next';
import { ForWhom } from './ForWhom/ForWhom';
import Styles from './ForWhoms.css';

export const ForWhoms = () => {
    const { t } = useTranslation();
    const data: any = [
        {
            title: t('small.forDoctors'),
            image: 'assets/sbLandingPage/forWhom_1.png',
            text: [
                t('small.tanbuuIncludesPolishAndEnglishLanguageVersions'),
                t('small.tanbuuDisplaysTimeZonesForTheAppointmentTimes')
            ]
        },
        {
            title: t('small.forOrthodontists'),
            image: 'assets/sbLandingPage/forWhom_2.png',
            text: [
                t('small.tanbuuAllowsOneToSplitTheOfferToServicesAvailableForSelfBooking'),
                t('small.tanbuuAllowsCustomersToBookAppointmentsForOtherPersons')
            ]
        },
        {
            title: t('small.forBarbers'),
            image: 'assets/sbLandingPage/forWhom_3.png',
            text: [
                t('small.withTanbuuYouCanEffortlesslyManageSchedules'),
                t('small.tanbuuAllowsYouToEasilyEnterTimeOffForYourEmployees')
            ]
        },
        {
            title: t('small.forAutomotiveServices'),
            image: 'assets/sbLandingPage/forWhom_4.png',
            text: [
                t('small.inTanbuuYouCanEasilyManageTheEquipmentAvailable')
            ]
        },
        {
            title: t('small.forBikeService'),
            image: 'assets/sbLandingPage/forWhom_5.png',
            text: [
                t('small.tanbuuAllowsYouToVeryQuicklyDefineBroadServiceOffer'),
                t('small.tanbuuSeamlesslyIntegratesWithGoogleMaps')
            ]
        }
    ];
    return (
        <div>
            <div className={Styles.sectionHeader} id="useCases">
                {t('small.useCases')}
            </div>
            {data.map((item, index) => (
                <ForWhom
                    key={index}
                    title={item.title}
                    image={item.image}
                    text={item.text}
                />
            ))}
        </div>
    );
};
