// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".AvailableAmount__1HDqB {\n    height: 30px;\n    display: flex;\n    justify-content: space-between;\n}\n", "",{"version":3,"sources":["AvailableAmount.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC","file":"AvailableAmount.css","sourcesContent":[".AvailableAmount {\n    height: 30px;\n    display: flex;\n    justify-content: space-between;\n}\n"]}]);
// Exports
exports.locals = {
	"AvailableAmount": "AvailableAmount__1HDqB"
};
module.exports = exports;
