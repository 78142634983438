// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media only screen and (min-height: 730px) and (min-width: 600px) {\n\n    .OuterLoginBg__1xw2m {\n        background: #013253;\n        height: calc(50vh + 92px);\n        position: absolute;\n        top: 0;\n        width: 100%;\n        z-index: -1;\n    }\n\n    .OuterLoginBg__1xw2m > div {\n        background: #013253;\n        height: 111%;\n        width: calc(50vw - 215px);\n    }\n\n    .OuterLoginBg__1xw2m > div:nth-child(1) {\n        border-radius: 0 0 50px;\n    }\n\n    .OuterLoginBg__1xw2m > div:nth-child(2) {\n        border-radius: 0 0 0 50px;\n        position: absolute;\n        right: 0;\n        top: 0;\n    }\n}\n", "",{"version":3,"sources":["OuterLoginBg.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,mBAAmB;QACnB,yBAAyB;QACzB,kBAAkB;QAClB,MAAM;QACN,WAAW;QACX,WAAW;IACf;;IAEA;QACI,mBAAmB;QACnB,YAAY;QACZ,yBAAyB;IAC7B;;IAEA;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;QACzB,kBAAkB;QAClB,QAAQ;QACR,MAAM;IACV;AACJ","file":"OuterLoginBg.css","sourcesContent":["@media only screen and (min-height: 730px) and (min-width: 600px) {\n\n    .OuterLoginBg {\n        background: #013253;\n        height: calc(50vh + 92px);\n        position: absolute;\n        top: 0;\n        width: 100%;\n        z-index: -1;\n    }\n\n    .OuterLoginBg > div {\n        background: #013253;\n        height: 111%;\n        width: calc(50vw - 215px);\n    }\n\n    .OuterLoginBg > div:nth-child(1) {\n        border-radius: 0 0 50px;\n    }\n\n    .OuterLoginBg > div:nth-child(2) {\n        border-radius: 0 0 0 50px;\n        position: absolute;\n        right: 0;\n        top: 0;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"OuterLoginBg": "OuterLoginBg__1xw2m"
};
module.exports = exports;
