import React from 'react';
import Button from 'components/_ReusableComponents/Button/Button';
import BackendMethods from 'api/BackendMethods';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Checkbox } from '@material-ui/core';
import PLGDPRConsent from 'components/Data/GDPRConsent/pl.json';
import ENGDPRConsent from 'components/Data/GDPRConsent/en.json';
import { Link } from 'react-router-dom';
import Styles from './OwnerGDPRConsentModal.css';

interface GDPRProps {
  isDesktop: boolean;
  setShowGDPRConsentModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OwnerGDPRConsentModal: React.FC<GDPRProps> = ({ isDesktop, setShowGDPRConsentModal }) => {
  const { i18n, t } = useTranslation();
  const data = i18n.language === 'en' ? ENGDPRConsent : PLGDPRConsent;
  const [acceptPrivacy, setAcceptPrivacy] = useState<boolean>(false);
  const [consentProcessing, setConsentProcessing] = useState<boolean>(false);
  const [acceptAllPrivacy, setAcceptAllPrivacy] = useState(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [contentButtonLabel, setContentButtonLabel] = useState<string>(t('consent.learnMore'));

  const handleAllAccept = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptAllPrivacy(event.target.checked);
    if (event.target.checked) {
      setAcceptPrivacy(true);
      setConsentProcessing(true);
    } else {
      setAcceptPrivacy(false);
      setConsentProcessing(false);
    }
  };
  const handleAcceptPrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptPrivacy(event.target.checked);
    if (event.target.checked && consentProcessing) {
      setAcceptAllPrivacy(true);
    } else {
      setAcceptAllPrivacy(false);
    }
  };
  const handleConsentProcessing = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentProcessing(event.target.checked);
    if (acceptPrivacy && event.target.checked) {
      setAcceptAllPrivacy(true);
    } else {
      setAcceptAllPrivacy(false);
    }
  };
  const handleShowContent = () => {
    if (showContent) {
      setContentButtonLabel(t('consent.learnMore'));
      setShowContent(false);
    } else {
      setContentButtonLabel(t('consent.collapse'));
      setShowContent(true);
    }
  };
  const acceptConsent = async () => {
    try {
      await BackendMethods.acceptConsent(
        [
          'PRIVACY_POLICY',
          'TAC_EMPLOYEE',
          'GDPR_OWNER'
        ]
      );
    } catch (error) {
    }
    setShowGDPRConsentModal(false);
  };
  return (
    <>
      <Dialog
        open={true}
        PaperProps={{
          className: Styles.dlgPaper
        }}
        id="GDPRConsentModal"
        maxWidth={false}
      >
        <div className={Styles.root}>
          <label className={Styles.checkList}>
            <Checkbox
              checked={acceptAllPrivacy}
              onChange={handleAllAccept}
            />
            <span className={Styles.checkListItem}>
              {t('consent.selectAll')}
            </span>
          </label>
          <label className={Styles.checkList}>
            <Checkbox
              checked={acceptPrivacy}
              onChange={handleAcceptPrivacy}
            />
            <span className={Styles.checkListItem}>
              {`${t('consent.agreePrivacy')} `}
              <Link target="_blank" to="/professional-terms">{`${t('consent.termsAndConditions')}`}</Link>
              {' ' + `${t('consent.and')} `}
              <Link target="_blank" to="/policy">{`${t('consent.privacyPolicy')}`}</Link>
              {i18n.language === 'pl'? ' ' + `${t('consent.end')}`: '.'}*
            </span>
          </label>
          <label className={Styles.checkList}>
            <Checkbox
              checked={consentProcessing}
              onChange={handleConsentProcessing}
            />
            <span className={Styles.checkListItem}>
              {t('consent.agreeTo') + ' '}
              <Link target="_blank" to="/data-processing-contract">{t('consent.dataProcessorAgreement')}</Link>.*
            </span>
          </label>
          <div className={Styles.content}>
            <p className={Styles.midText}>*{t('consent.required')}</p>
            <div className={Styles.contentBtnGroup}>
              <div className={isDesktop? Styles.secondBtnGroup: Styles.contentBtnGroup}>
                <h5 className={Styles.dataProcessingLabel}>
                  {`${t('consent.processSolely')}:`}
                </h5>
                <Button
                  className={Styles.showContentBtn}
                  onClick={handleShowContent}
                >
                  {contentButtonLabel}
                </Button>
              </div>
              <div className={isDesktop? Styles.secondBtnGroup: Styles.contentBtnGroup}>
                <Button
                  className={Styles.saveBtn}
                  disabled={!acceptAllPrivacy}
                  onClick={acceptConsent}
                >
                  {t('small.save')}
                </Button>
              </div>
            </div>
            {showContent && (
              <ul className={Styles.contentList}>
                {data.map((cd, ind) => (
                  (<li key={ind}>
                    {cd}
                  </li>)
                ))}
              </ul>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};
