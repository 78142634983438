import React, { useContext, useEffect } from 'react';
import IActions from '../../../../actions/IActions';
import AdminNavigation from '../AdminNavigation/AdminNavigation';
import BillingRadio from '../../../PlusPlanPayment/BillingSetUp/BillingSetupComponents/BillingRadio/BillingRadio';
import { Button, Checkbox, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { RouteComponentProps, withRouter } from 'react-router';
import TextField from '../../../_ReusableComponents/TextField/TextField';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../../GlobalContext';
const styles = require('./EditPlan.css');

interface IEditPlanProps extends RouteComponentProps<any> {
    plusPlanAdmin: any;
    data: any;
    actions: IActions;
    history: any;
}

const EditPlan = (props: IEditPlanProps) => {
    const { t, i18n } = useTranslation();
    const { profile } = useContext(GlobalContext);
    const returnToSummary = () => {
        window.scrollTo(0,0);
        props.actions.resetAdminPanel(props.data.get('subscription'));
        props.actions.changeActivePanel('summary');
    };

    const { plusPlanAdmin } = props;
    const monthlyPriceUSD = plusPlanAdmin.get('monthlyPrice') / 100;
    const yearlyPriceUSD = plusPlanAdmin.get('yearlyPrice') / 100;
    const renewAdvanced = plusPlanAdmin.get('renewAdvanced');
    const subscription = props.data.get('subscription');
    const currentLicenses = subscription.licenses;
    const currentBillingType = subscription.interval === 'MONTH' ? 'monthly' : 'yearly';
    const currentBillingTypeShort = subscription.interval === 'MONTH' ? 'mo' : 'yr';
    const currentCharge = subscription.charge / 100;
    let newCharge, newTotalCharge, effective;
    const newLicenses = plusPlanAdmin.get('newAmountOfLicenses');
    const newBillingType = plusPlanAdmin.get('newBillingType');
    const newBillingTypeShort = newBillingType === 'monthly' ? 'mo' : 'yr';
    const newLicensesNumber = Number(newLicenses);
    const newRenewing = plusPlanAdmin.get('newRenewing');
    const newAutoRenew = plusPlanAdmin.get('newAutoRenew');
    const newRenewingNumber = renewAdvanced ? Number(newRenewing) : (newAutoRenew ? newLicensesNumber : 0);
    const currentRenewing = subscription.renewing;

    const invalidNumber = newLicenses === '' || newLicensesNumber < 0 || Number.isNaN(newLicensesNumber);
    const tooFewLicenses = currentBillingType === newBillingType && newLicensesNumber < currentLicenses;
    const tooManyLicenses = newLicensesNumber > 100;
    const invalidRenew = renewAdvanced &&
        (newRenewing === '' || newRenewingNumber < 0 || newRenewingNumber > newLicensesNumber);
    const renewingSome = (currentRenewing > 0 && currentRenewing < currentLicenses) ||
        (renewAdvanced && newRenewingNumber > 0 && newRenewingNumber < newLicensesNumber);
    const licensesBuyAutoRenewError = renewingSome && newLicensesNumber > currentLicenses;
    const yearlyBillingAutoRenewError = renewingSome && currentBillingType !== newBillingType;
    const typeOrTotalChanged = newLicensesNumber !== currentLicenses || newBillingType !== currentBillingType;
    const renewingChanged = newRenewingNumber !== currentRenewing;
    const newSubscription = subscription.status !== 'ACTIVE';

    if (!invalidNumber && !tooFewLicenses && !tooManyLicenses && !invalidRenew &&
        !licensesBuyAutoRenewError && !yearlyBillingAutoRenewError) {
        const licensePrice = newBillingType === 'monthly' ? monthlyPriceUSD : yearlyPriceUSD;
        if (typeOrTotalChanged) {
            newTotalCharge = (newLicensesNumber * licensePrice).toFixed(2);
            if (currentBillingType === newBillingType) {
                newCharge = ((newLicensesNumber - currentLicenses) * licensePrice).toFixed(2);
            } else {
                newCharge = newTotalCharge;
            }
            effective = t('common.today');
        } else if (renewingChanged) {
            newTotalCharge = (newRenewingNumber * licensePrice).toFixed(2);
            newCharge = ((newRenewingNumber - currentRenewing) * licensePrice).toFixed(2);
            const expires = props.data.get('subscription').expires;
            effective = moment.tz(expires, profile.timezone)
                .format(i18n.language === 'pl' ? 'D MMMM Y' : 'MMMM D, Y');
        }
    }
    const licensesError = plusPlanAdmin.get('tooFewLicensesError') || plusPlanAdmin.get('negativeChargesError') ||
        plusPlanAdmin.get('tooManyLicensesError') || plusPlanAdmin.get('invalidNumberError') ||
        plusPlanAdmin.get('licensesBuyAutoRenewError');

    const onContinue = () => {
        if (invalidNumber) {
            props.actions.setInvalidNumberError(true);
        } else if (tooFewLicenses) {
            props.actions.setTooFewLicensesError(true);
        } else if (tooManyLicenses) {
            props.actions.setTooManyLicensesError(true);
        } else if (invalidRenew) {
            props.actions.setInvalidRenewError(true);
        } else if (licensesBuyAutoRenewError) {
            props.actions.setLicensesBuyAutoRenewError(true);
        } else if (yearlyBillingAutoRenewError) {
            props.actions.setYearlyBillingAutoRenewError(true);
        } else if (typeOrTotalChanged || newSubscription) {
            props.actions.goToPlaceOrder(Number(newTotalCharge));
        } else if (renewingChanged) {
            props.actions.setNumberOfExpiringLicenses(currentLicenses - newRenewingNumber);
        } else {
            props.actions.setNoChangesError(true);
        }
    };

    const expiringLicensesChanged = plusPlanAdmin.get('expiringLicensesChanged');
    useEffect(() => {
        if (expiringLicensesChanged) {
            props.history.push('/licenses');
            props.actions.resetAdminPanel(props.data.get('subscription'));
            props.actions.getSubscription();
        }
    }, [expiringLicensesChanged]);

    return (
        <section className={styles.EditPlan}>
            <BillingRadio
                data-name="monthly"
                billed={t('plan.monthly')}
                description={`$${monthlyPriceUSD} ${t('plan.licensedUser')}*/${t('plan.month')}`}
                name="billed"
                onChange={() => props.actions.setNewBillingType('monthly')}
                checked={newBillingType === 'monthly'}
                disabled={currentBillingType === 'yearly'}
            />
            <BillingRadio
                data-name="yearly"
                billed={t('plan.yearly')}
                description={`$${yearlyPriceUSD} ${t('plan.licensedUser')}*/${t('plan.year')}`}
                name="billed"
                onChange={() => props.actions.setNewBillingType('yearly')}
                checked={newBillingType === 'yearly'}
                savedPercent={Math.round((1 - (yearlyPriceUSD / (monthlyPriceUSD * 12))) * 100)}
            />
            {plusPlanAdmin.get('yearlyBillingAutoRenewError') && (
                <FormHelperText error className={styles.error}>
                    {t('plan.toSwitchToYearly')}
                </FormHelperText>
            )}
            <div className={styles.totalLicensesContainer}>
                <label htmlFor="total-licenses" className={styles.totalLicensesLabel}>
                    {t('plan.totalLicenses')}:
                </label>
                <TextField
                    margin="dense"
                    id="total-licenses"
                    type="number"
                    className={styles.licensesInput}
                    inputProps={{
                        min: currentBillingType === newBillingType ? currentLicenses : 1,
                        max: 100
                    }}
                    value={plusPlanAdmin.get('newAmountOfLicenses')}
                    onChange={({ target }) => props.actions.setNewAmountOfLicenses(target.value)}
                    error={licensesError}
                />
                {licensesError && (
                    <FormHelperText error className={styles.error}>
                        {(plusPlanAdmin.get('tooFewLicensesError') && t('plan.toTerminate')) ||
                        (plusPlanAdmin.get('negativeChargesError') && t('plan.negativeCharge')) ||
                        (plusPlanAdmin.get('tooManyLicensesError') && t('plan.maxLicenses')) ||
                        (plusPlanAdmin.get('licensesBuyAutoRenewError') && t('plan.beforeBuying')) ||
                        (plusPlanAdmin.get('invalidNumberError') && t('common.invalidNumber'))}
                    </FormHelperText>
                )}
            </div>
            <div className={styles.autoRenewContainer}>
                <label htmlFor="auto-renew">
                    {t('plan.autoRenew')}
                </label>
                {renewAdvanced ? (
                    <TextField
                        margin="dense"
                        variant="outlined"
                        id="auto-renew"
                        type="number"
                        className={styles.licensesInput}
                        inputProps={{
                            min: 0,
                            max: plusPlanAdmin.get('newAmountOfLicenses')
                        }}
                        value={plusPlanAdmin.get('newRenewing')}
                        onChange={({ target }) => props.actions.changeRenewNumber(target.value)}
                        error={plusPlanAdmin.get('invalidRenewError')}
                    />
                ) : (
                    <Checkbox
                        color="primary"
                        id="auto-renew"
                        checked={plusPlanAdmin.get('newAutoRenew')}
                        onChange={(_, checked) => props.actions.setNewAutoRenew(checked)}
                    />
                )}
                {plusPlanAdmin.get('invalidRenewError') && (
                    <FormHelperText error className={styles.error}>
                        {t('common.invalidNumber')}
                    </FormHelperText>
                )}
            </div>
            <Button
                color="primary"
                className={styles.autoRenewAdvanced}
                onClick={() => props.actions.toggleRenewAdvanced(!renewAdvanced)}
            >
                {renewAdvanced ? t('common.cancel') : t('plan.advanced')}
            </Button>
            <div className={styles.amounts}>
                {currentCharge !== 0 && (
                    <div className={styles.amountContainer}>
                        <div>
                            {t(
                                'plan.currentTotal',
                                { type: t(`plan.${currentBillingType}`) },
                            )}
                            :
                        </div>
                        <div>
                            $
                            {currentCharge.toFixed(2)}
                            {' / '}
                            {t(`plan.${currentBillingTypeShort}`)}
                        </div>
                    </div>
                )}
                {newCharge && (
                    <div className={styles.amountContainer}>
                        <div>
                            {Number(newCharge > 0) ? t('plan.new') : t('plan.expiring')}
                            {` ${t('plan.licenses')}:`}
                        </div>
                        <div>
                            {Number(newCharge > 0) ? `$${newCharge}`
                                : `($${Math.abs(newCharge).toFixed(2)})`}
                            {' / '}
                            {t(`plan.${newBillingTypeShort}`)}
                        </div>
                    </div>
                )}
                {newTotalCharge && (
                    <div className={classNames(styles.amountContainer, styles.newTotalCharge)}>
                        <div>
                            {t(
                                'plan.newTotal',
                                { type: t(`plan.${newBillingType}`) },
                            )}
                            :
                        </div>
                        <div>
                            <span data-testid="total-charge">
                                $
                                {newTotalCharge}
                                {' / '}
                                {t(`plan.${newBillingTypeShort}`)}
                            </span>
                            <div className={styles.effectiveToday}>
                                {t('plan.effective')} {effective}
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.taxesInfo}>
                    {t('plan.taxesInfo')}
                </div>
            </div>
            {plusPlanAdmin.get('noChangesError') && (
                <FormHelperText error className={styles.error}>
                    {t('plan.noChanges')}
                </FormHelperText>
            )}
            <AdminNavigation
                {...props}
                onContinueClick={onContinue}
                onReturnClick={returnToSummary}
                acceptButton={
                    (renewAdvanced && renewingChanged) ? t('plan.save') : t('plan.continue')
                }
            />
            <div className={styles.footnote}>
                {'* '}
                {t('plan.licensedUserFootnote')}
            </div>
        </section>
    );
};

export default withRouter(EditPlan);
