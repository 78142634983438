import * as React from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import UserInitials from 'components/_ReusableComponents/MobileMenu/UserInitials/UserInitials';
import IProfile from 'interfaces/IProfile';
import { getAuthToken, isAdHoc, isLoggedIn, parseJwt } from '../../../scripts/helpers';
import Auth from 'auth/Auth';
import Button from '../Button/Button';
import IActions from '../../../actions/IActions';
import { useTranslation } from 'react-i18next';
import { Ref, useState } from 'react';
import inIframe from '../../../utils/inIframe';
import { useRouteMatch } from 'react-router';
import { formatFullPhone } from '../../../utils/phone';
import { getSubdomain, redirectToBaseDomain, redirectToSubdomain } from '../../../utils/subdomain';
import BackendMethods from '../../../api/BackendMethods';
import AddEmailDialog from '../AddEmailDialog';
const styles = require('./MobileMenu.css');

interface Props {
    actions: IActions;
    profile: IProfile;
    data: any;
    collapsed: boolean;
}

export default React.forwardRef(function MobileMenu(props: Props, ref: Ref<HTMLElement>) {
    const { t } = useTranslation();
    const history = useHistory();
    const isSb = useRouteMatch('/sb/*');
    const isSbCustomer = useRouteMatch('/sb-customer/*');
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    const profile: IProfile = props.profile;
    const email = (() => {
        if (profile?.email) {
            return profile.email;
        }
        if (isAdHoc()) {
            return parseJwt(getAuthToken()).email;
        }
    })();
    const name = (() => {
        if (profile?.firstName) {
            return `${profile.firstName} ${profile.lastName}`;
        }
        if (email) {
            return email;
        }
        return null;
    })();
    let isEnterprise: boolean = inIframe();
    const subscription = props.data.get('subscription');
    const isPlusUserAdmin = subscription?.status !== undefined && !profile.plusPlan.organization;
    const { actions } = props;

    const logout = () => {
        Auth.logout();
    };
    const closeMenu = () => actions.hideMobileMenu();
    const toggleMenu = () => props.collapsed ? actions.showMobileMenu() : actions.hideMobileMenu();
    const isOutlook = sessionStorage.getItem('outlook') === '1';
    const loggedIn = isLoggedIn();
    const isCompanyEmployee = profile?.appointments?.companyId != null;

    return (
        <section
            ref={ref}
            className={classNames(styles.root, {
                [styles.collapsed]: props.collapsed,
                [styles.outlook]: isOutlook,
            })}
        >
            <div
                className={styles.CloseIcon}
                onClick={toggleMenu}
            />
            <div className={styles.InitialsContainer}>
                {name ? (
                    <UserInitials name={name} onClick={toggleMenu} />
                ) : (
                    <Button
                        variant="contained"
                        className={styles.menuIcon}
                    >
                        <img src="/assets/menu-white.svg" alt="" />
                    </Button>
                )}
                {loggedIn && (
                    <>
                        <div
                            className={classNames(
                                styles.InitialsContainer__NameAndSurname
                            )}
                        >
                            {name}
                        </div>
                        <div
                            className={classNames(
                                styles.InitialsContainer__Email
                            )}
                        >
                            {email || formatFullPhone(profile.phone)}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.links}>
                {(isSb || isSbCustomer) ? (
                    <Link
                        className={classNames(styles.MenuElement, styles.appointments, {
                            [styles.disabled]: !loggedIn,
                        })}
                        to={isSb ? '/sb/dashboard' : '/sb-customer/my-appointments'}
                        onClick={closeMenu}
                    >
                        {isSb ? t('small.appointments') : t('small.myAppointments')}
                    </Link>
                ) : (
                    <Link
                        className={classNames(styles.MenuElement, styles.home)}
                        to="/main"
                        onClick={closeMenu}
                    >
                        {t('home.home')}
                    </Link>
                )}
                {isSbCustomer && (
                    <Link
                        className={classNames(styles.MenuElement, styles.findService)}
                        to={'/sb-customer/find-service'}
                        onClick={closeMenu}
                    >
                        {t('small.findService')}
                    </Link>
                )}
                {!isSbCustomer && (
                    <Link
                        className={classNames(styles.MenuElement, styles.newMeeting)}
                        to={isSb ? '/sb/assistant-booking' : '/new'}
                        onClick={closeMenu}
                    >
                        {isSb ? t('small.bookNav') : t('home.newMeeting')}
                    </Link>
                )}
                {!isSb && !isSbCustomer && (
                    <>
                        {isPlusUserAdmin ? (
                            <Link
                                id="admin"
                                className={classNames(
                                    styles.MenuElement,
                                    styles.admin
                                )}
                                to="/manage"
                                onClick={closeMenu}
                            >
                                {t('home.managePlan')}
                            </Link>
                        ) : (profile.plusPlan.active ? (
                            <Link
                                id="admin"
                                className={classNames(
                                    styles.MenuElement,
                                    styles.admin
                                )}
                                to="/plan-info"
                                onClick={closeMenu}
                            >
                                {t('plan.plusPlanInfo')}
                            </Link>
                        ) : (
                            <div className={styles.adminContainer}>
                                <Link
                                    id="admin"
                                    to="/upgradePlan"
                                    onClick={closeMenu}
                                    className={styles.mobileMenu_freePlan}
                                >
                                    {t('home.freePlan')}
                                </Link>
                                <Button
                                    variant="contained"
                                    className={styles.mobileMenu_upgradeButton}
                                    onClick={() => {
                                        history.push('/upgradePlan');
                                        closeMenu();
                                    }}
                                >
                                    {t('home.upgrade')}
                                </Button>
                            </div>
                        ))}
                    </>
                )}
                {!isAdHoc() && (
                    <Link
                        className={classNames(styles.MenuElement, styles.settings, {
                            [styles.disabled]: !loggedIn,
                        })}
                        to="/preferences"
                        onClick={closeMenu}
                    >
                        {t('home.settings')}
                    </Link>
                )}
                {isSb && (
                    <Link
                        className={classNames(styles.MenuElement, styles.customerPage)}
                        to={'/sb/customer'}
                        onClick={closeMenu}
                    >
                        {t('small.customerPage')}
                    </Link>
                )}
                <div
                    className={classNames(styles.MenuElement, styles.support)}
                    onClick={actions.toggleSupportModal}
                >
                    {t('home.support')}
                </div>
            </div>
            {!isEnterprise && !isAdHoc() && (
                <>
                    {loggedIn && (
                        <div
                            id="logout"
                            className={classNames(styles.MenuElement, styles.logout)}
                            onClick={logout}
                        >
                            {t('home.logOut')}
                        </div>
                    )}
                    {!loggedIn && (
                        <Link
                            className={classNames(styles.MenuElement, styles.logIn)}
                            to="/login"
                            onClick={() => {
                                if (getSubdomain() !== null) {
                                    const { location } = window;
                                    const url = encodeURIComponent(location.href);
                                    redirectToBaseDomain(`/login?redirectURI=${url}`);
                                }
                            }}
                        >
                            {t('common.logIn')}
                        </Link>
                    )}
                </>
            )}
            {isOutlook ? (
                <>
                    <div
                        className={classNames(
                            styles.Dandelion,
                            styles['Dandelion--FirstDandelion']
                        )}
                    />
                    <div
                        className={classNames(
                            styles.Dandelion,
                            styles['Dandelion--SecondDandelion']
                        )}
                    />
                    <div
                        className={classNames(
                            styles.Dandelion,
                            styles['Dandelion--ThirdDandelion']
                        )}
                    />
                </>
            ) : (
                <div className={styles.switchContainer}>
                    <div className={styles.switchTo}>
                        {t('common.switchTo')}
                        :
                    </div>
                    <Button
                        variant="text"
                        {...((!loggedIn || profile.email) ? {
                            component: Link,
                            to: (isSb || isSbCustomer)
                                ? (loggedIn ? '/main' : '/')
                                : (isCompanyEmployee ? '/sb/dashboard' : '/sb-customer/my-appointments')
                        } : {})}
                        onClick={() => {
                            closeMenu();
                            if (isSb || isSbCustomer) {
                                if (!loggedIn || profile.email) {
                                    redirectToBaseDomain(loggedIn ? '/main' : '/');
                                } else {
                                    setEmailDialogOpen(true);
                                }
                            } else if (isCompanyEmployee && profile.appointments.companySubdomain) {
                                redirectToSubdomain(profile.appointments.companySubdomain, '/sb/dashboard');
                            }
                        }}
                    >
                        <img src="/assets/tanbuu_icon.svg" alt="" className={styles.switchIcon} />
                        <span className={styles.switchButtonText}>
                            {(isSb || isSbCustomer) ? t('common.meetings') : t('common.appointments')}
                        </span>
                    </Button>
                    <AddEmailDialog
                        open={emailDialogOpen}
                        onClose={() => setEmailDialogOpen(false)}
                        context="MEETINGS"
                    />
                </div>
            )}
        </section>
    );
});
