import React from 'react';
import Button from 'components/_ReusableComponents/Button/Button';
import BackendMethods from 'api/BackendMethods';
import { useState } from 'react';
import Styles from './PrivacyModal.css';
import { useTranslation } from 'react-i18next';
import { Dialog, Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

interface PrivacyProps {
    setIsShowPrivacyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PrivacyModal: React.FC<PrivacyProps> = ({ setIsShowPrivacyModal }) => {
    const { t, i18n } = useTranslation();
    const [acceptAllPrivacy, setAcceptAllPrivacy] = useState(false);
    const history = useHistory();
    const handleAllAccept = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAcceptAllPrivacy(event.target.checked);
    };
    const acceptConsent = async () => {
        try {
            await BackendMethods.acceptConsent(
                [
                    'PRIVACY_POLICY',
                    'TAC_EMPLOYEE'
                ]
            );
            window.dispatchEvent(new Event('acceptedPrivacy'));
        } catch (error) {
        }
        setIsShowPrivacyModal(false);
    };

    return (
        <>
            <Dialog
                open={true}
                PaperProps={{
                    className: Styles.dlgPaper
                }}
                id="GDPRConsentModal"
                maxWidth={false}
            >
                <div className={Styles.root}>
                    <label className={Styles.checkList}>
                        <Checkbox
                            checked={acceptAllPrivacy}
                            onChange={handleAllAccept}
                        />
                        <span className={Styles.checkListItem}>
                            {`${t('consent.agreePrivacy')} `}
                            <Link target="_blank" to="/professional-terms">{`${t('consent.termsAndConditions')}`}</Link>
                            {' ' + `${t('consent.and')} `}
                            <Link target="_blank" to="/policy">{`${t('consent.privacyPolicy')}`}</Link>
                            {i18n.language === 'pl'? ' ' + `${t('consent.end')}`: '.'}*
                        </span>
                    </label>
                    <div className={Styles.content}>
                        <p className={Styles.midText}>*{t('consent.required')}</p>
                        <div className={Styles.contentBtnGroup}>
                            <Button
                                className={Styles.saveBtn}
                                disabled={!acceptAllPrivacy}
                                onClick={acceptConsent}
                            >
                                {t('small.save')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};
