import * as React from 'react';
import IActions from 'actions/IActions';
import GroupAvatar from 'components/ManageGroupComponents/GroupAvatar/GroupAvatar';
import GroupNavigation from 'components/ManageGroupComponents/GroupNavigation/GroupNavigation';
import ILocation from '../../../interfaces/location/ILocation';
import AdminLinks from '../../_ReusableComponents/AdminLinks/AdminLinks';
import PlusPlanHeader from '../../_ReusableComponents/Header/PlusPlanHeader/PlusPlanHeader';
import { useTranslation } from 'react-i18next';
const Styles = require('./ManageGroup.css');

interface Props {
    data: any;
    actions: IActions;
    history: any;
    location: ILocation;
}

const ManageGroup = (props: Props) => {
    const { t } = useTranslation();
    return (
        <main className={Styles.ManageGroup} data-testid="team">
            {!props.location.state?.wizard && (
                <AdminLinks active="team" />
            )}
            <div className={Styles.content}>
                <PlusPlanHeader text={t('plan.teamInfo')} />
                <GroupAvatar />
                {props.location.state?.wizard && (
                    <GroupNavigation
                        actions={props.actions}
                        history={props.history}
                    />
                )}
            </div>
        </main>
    );
};

export default ManageGroup;
