import * as React from 'react';
const styles = require('./PlusPlanHeader.css');

interface IProps {
    text: string;
}

export default function PlusPlanHeader(props: IProps) {
    return (
        <div className={styles.root}>
            {props.text}
        </div>
    );
}
