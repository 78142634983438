// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__1I6La {\n    display: flex;\n    width: 100%;\n}\n\n.country__rFUbf {\n    width: 120px;\n    margin-right: 16px;\n    flex-shrink: 0;\n}\n\n.phone__79Qgc {\n    flex-grow: 1;\n}\n", "",{"version":3,"sources":["PhoneInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB","file":"PhoneInput.css","sourcesContent":[".root {\n    display: flex;\n    width: 100%;\n}\n\n.country {\n    width: 120px;\n    margin-right: 16px;\n    flex-shrink: 0;\n}\n\n.phone {\n    flex-grow: 1;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__1I6La",
	"country": "country__rFUbf",
	"phone": "phone__79Qgc"
};
module.exports = exports;
