import React, { useContext, useState } from 'react';
import { Dialog } from '@material-ui/core';
import IMeeting from 'interfaces/IMeeting';
import IActions from '../../../../actions/IActions';
import BackendMethods from '../../../../api/BackendMethods';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../../GlobalContext';
const styles = require('./RejectMeetingModal.css');

interface Props {
    meeting: IMeeting;
    open: boolean;
    onClose: () => void;
    actions: IActions;
    history: any;
}

export default function RejectMeetingModal(props: Props) {
    const { t } = useTranslation();
    const { profile } = useContext(GlobalContext);
    const item = props.meeting;
    const [loading, setLoading] = useState(false);
    const onConfirm = () => {
        setLoading(true);
        BackendMethods.declineMeeting(item.id, true).then((updatedMeeting) => {
            setLoading(false);
            props.history.push(`/meeting/${item.id}`);
            props.actions.updateMeeting(updatedMeeting, profile.email);
            props.actions.showSuccessMessage(t('home.inviteDeclined'));
        }, () => {
            setLoading(false);
            props.onClose();
            props.actions.showErrorMessage(t('home.failedToDecline'));
        });
    };
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{ className: styles.paper }}
        >
            <div className={styles.title}>{item.title}</div>
            <div className={styles.text}>{t('home.confirmDecline')}</div>
            <div className={styles.buttons}>
                <Button
                    className={styles.yesButton}
                    id="CancelMeetingYesButton"
                    onClick={onConfirm}
                    disabled={loading}
                >
                    {t('home.yes')}
                </Button>
                <Button
                    variant="contained"
                    id="CancelMeetingNoButton"
                    onClick={props.onClose}
                    disabled={loading}
                >
                    {t('home.no')}
                </Button>
            </div>
        </Dialog>
    );
}
