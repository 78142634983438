import React, { useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import Auth from 'auth/Auth';
import Validators from '../../../utils/Validators/Validators';
import { getSearchParam, parseJwt } from 'scripts/helpers';
import PasswordField from '../../_ReusableComponents/PasswordField/PasswordField';
import TextField from '../../_ReusableComponents/TextField/TextField';
import Button from '../../_ReusableComponents/Button/Button';
import IActions from '../../../actions/IActions';
import { useTranslation, Trans } from 'react-i18next';
const styles = require('./Login.css');

interface Props {
    actions: IActions;
    resendActivationLink: boolean;
    setActivationLinkToSent: Function;
}

export default function Login(props: Props) {
    const { t } = useTranslation();
    const location = useLocation();
    const token = getSearchParam(location.hash, 'id_token');
    const defaultEmail = token
        ? (parseJwt(token)?.name || '')
        : getSearchParam(location.search, 'email') || '';
    const [email, setEmail] = useState(defaultEmail);
    const [emailError, setEmailError] = useState<string>(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<string>(null);
    const [loading, setLoading] = useState(false);
    const resendActivationLink = async () => {
        props.setActivationLinkToSent();
        const { status } = await Auth.resendActivationLink();
        if (status >= 200 && status < 300) {
            props.actions.showSuccessMessage(t('auth.activationSent'));
        } else {
            props.actions.showErrorMessage(t('auth.activationError'));
        }
    };
    const signIn = (event) => {
        event.preventDefault();
        const emailValid = Validators.isValidEmail(email);
        setEmailError(emailValid ? null : t('auth.emailInvalid'));
        const passwordValid = password !== '';
        setPasswordError(passwordValid ? null : t('auth.emptyPassword'));
        if (emailValid && passwordValid) {
            setLoading(true);
            Auth.loginWithEmail(email, password, () => {
                setLoading(false);
                setPasswordError(t('auth.wrongEmail'));
            });
        }
    };
    return (
        <form noValidate onSubmit={signIn} className={styles.Login}>
            <div className={styles.RegisterForm}>
                <div>
                    <TextField
                        type="email"
                        autoComplete="email"
                        label={t('auth.email')}
                        value={email}
                        onChange={({ target }) => {
                            setEmail(target.value);
                            setEmailError(null);
                        }}
                        error={emailError !== null}
                        helperText={emailError}
                        fullWidth
                        margin="dense"
                    />
                    <PasswordField
                        autoComplete="current-password"
                        label={t('auth.password')}
                        value={password}
                        onChange={({ target }) => {
                            setPassword(target.value);
                            setPasswordError(null);
                        }}
                        error={passwordError !== null}
                        helperText={passwordError}
                        fullWidth
                        margin="dense"
                    />
                </div>
                {props.resendActivationLink && (
                    <div className={styles.notVerified}>
                        {t('auth.emailNotVerified')}
                        <a
                            className={styles.resend}
                            id="ResendActivationLink"
                            onClick={resendActivationLink}
                        >
                            {t('auth.resendLink')}
                        </a>
                    </div>
                )}
                <div className={styles.FooterContainer}>
                    <Link
                        className={classNames(styles.Link, styles.forgotLink)}
                        to="/forgot"
                    >
                        {t('auth.forgotPassword')}
                    </Link>
                    <span className={styles.SignInInfo}>
                        <Trans
                            i18nKey="auth.bySigningIn"
                            components={{
                                a: <Link className={classNames(styles.Link, styles.termsLink)} to="/terms" />,
                            }}
                        />
                    </span>
                    <Button
                        variant="contained"
                        fullWidth
                        type="submit"
                        id="LoginButton"
                        disabled={loading}
                    >
                        {t('auth.logIn')}
                    </Button>
                </div>
                <Link className={styles.Link} to="/register" data-testid="login-sign-up">
                    {t('auth.dontHaveAccount')}
                    <span>{t('auth.signUp')}</span>
                </Link>
            </div>
        </form>
    );
}
