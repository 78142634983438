// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__2nQYC {\n    width: 100%;\n    max-width: 352px;\n    margin: 60px auto;\n    padding: 0 26px;\n}\n", "",{"version":3,"sources":["PhoneLogin.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;AACnB","file":"PhoneLogin.css","sourcesContent":[".root {\n    width: 100%;\n    max-width: 352px;\n    margin: 60px auto;\n    padding: 0 26px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__2nQYC"
};
module.exports = exports;
