import React from 'react';
import Styles from './Privacy.css';
import { useTranslation } from 'react-i18next';

const Privacy = ({ data }) => {
    const { t } = useTranslation();

    const card = (cardData: Object, deep: number, index: number) => {
        return (
            <>
                {
                    (cardData['header'] !== '') && <div>
                        {
                            deep === 0 ?
                                (
                                    <>
                                        <h2 className={Styles.headerIndex}>{t('common.indexMark') + ' ' + (index + 1) + '.'}</h2>
                                        <h2 className={Styles.header}>{cardData['header']}</h2>
                                    </>
                                ) :
                                (<li className={Styles.description}>{cardData['header']}</li>)
                        }
                    </div>
                }
                {
                    (cardData['description'] !== '') && <div>
                        <p className={Styles.description}>{cardData['description']}</p>
                    </div>
                }
                <ul>
                    {
                        cardData['body'].map((cd, ind) => (
                            typeof (cd) === 'string' ?
                                (<li
                                    key={ind}
                                    className={Styles.description}
                                    dangerouslySetInnerHTML={{
                                        __html: cd
                                    }} />) :
                                (<div key={ind}>
                                    {card(cd, deep + 1, ind)}
                                </div>)
                        ))
                    }
                </ul>
            </>
        );
    };

    return (
        <div className={Styles.root}>
            <h1 className={Styles.mainHeader}>{data['title']}</h1>
            {data['header'] && (
                <div className={Styles.footer}>
                    {data['header'].map((text) => (
                        <p className={Styles.description} dangerouslySetInnerHTML={{__html: text}}></p>
                    ))}
                </div>
            )}
            {data['body'].map((d, index) => (
                <div key={index}>
                    {card(d, 0, index)}
                </div>
            ))}
            {data['footer'] && (
                <div className={Styles.footer}>
                    {data['footer'].map((text) => (
                        <p className={Styles.description}>{text}</p>
                    ))}
                </div>
            )}
        </div>
    );
};

export { Privacy };
