import * as React from 'react';
import cs from 'classnames';

const Styles = require('./MeetingTitleWithImage.css');

interface Props {
    title: string;
}

interface State {
}

export default class MeetingTitleWithImage extends React.Component<Props, State> {

    render() {
        return (
            <div className={cs(Styles.MeetingTitleWithImage)}>
                <div className={cs(Styles.Title)}>{this.props.title}</div>
                <img className={cs(Styles.HorizontalLine)}
                     src="/assets/horizontal_line.svg"
                     alt="Horizontal Line"/>
                {this.props.children}
            </div>
        );
    }
}
