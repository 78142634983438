import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Button from 'components/_ReusableComponents/Button/Button';
import IReview from 'interfaces/IReview';
import { IReviews } from 'interfaces/ICompaniesRating';
import BackendMethods from 'api/BackendMethods';
import { ReviewContent } from 'components/_ReusableComponents/ReviewContent/ReviewContent';
import { useIsDesktop } from 'utils/responsive';
import { useTranslation } from 'react-i18next';

interface ReviewProps {
    companyId: number;
    companyName: string;
    myReview: IReviews;
    setNowPage: React.Dispatch<React.SetStateAction<number>>;
    getRating: any;
}
export const EditReview: React.FC<ReviewProps> = ({ companyId, myReview, setNowPage, getRating, companyName }) => {
    const [clicked, setClicked] = useState<boolean>(false);
    const { t } = useTranslation();
    const isDesktop = useIsDesktop();
    const [ratingValue, setRatingValue] = useState<number | null>(null);
    const [opinion, setOpinion] = useState<string>('');
    const [nickName, setNickName] = useState<string>('');

    useEffect(() => {
        initValues();
    }, [myReview]);
    const initValues = () => {
        if (myReview) {
            setRatingValue(myReview.rating);
            setOpinion(myReview.review);
            setNickName(myReview.author);
        } else {
            setRatingValue(null);
            setOpinion('');
            setNickName('');
        }
    };

    const submitOpinion = async () => {
        if (!clicked) {
            const review: IReview = {
                company: companyId,
                nick: nickName,
                rating: ratingValue,
                review: opinion
            };
            try {
                setClicked(true);
                await BackendMethods.submitReview(review);
                getRating();
                setNowPage(0);
            } catch (error) {
            } finally {
                setClicked(false);
            }
        }
    };

    return (
        <div className={ isDesktop? 'p-t-18': 'p-18'}>
            <ReviewContent
                disabled={false}
                ratingValue={ratingValue}
                setRatingValue={setRatingValue}
                opinion={opinion}
                setOpinion={setOpinion}
                nickName={nickName}
                setNickName={setNickName}
                companyName={companyName}
            />
            <div className={ classNames('text-right', 'm-t-20') }>
                <Button
                    variant="outlined"
                    onClick={() => {
                        submitOpinion();
                    }}
                    className="blue-btn"
                    disabled={nickName === '' || !ratingValue || clicked}
                >
                    {t('small.post')}
                </Button>
            </div>
        </div>
    );
};
