import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILocation } from '../Settings/Sites';
import { IService } from '../Settings/Services';
import BackendMethods from '../../../api/BackendMethods';
import { IPerson } from '../Settings/Personnel';
import Button from '../../../components/_ReusableComponents/Button/Button';
import classNames from 'classnames';
import PhoneNumber from 'awesome-phonenumber';
import * as R from 'ramda';
import moment, { Moment } from 'moment';
import { Checkbox, Collapse, Dialog, Fade, IconButton, Tooltip } from '@material-ui/core';
import CustomerData from './CustomerData';
import WeekCalendar from '../../../components/MainPageComponents/WeekCalendar/WeekCalendar';
import IProfile from '../../../interfaces/IProfile';
import { renderPrice } from '../common';
import { Link, Redirect, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { IHeaderProps } from '../../../components/_ReusableComponents/Header/Header/Header';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import { formatPhone } from '../../../utils/phone';
import FindService from '../Customer/FindService';
import { isAdHoc, isLoggedIn } from '../../../scripts/helpers';
import Footer from '../Footer';
import scrollIntoView from 'scroll-into-view';
import useDebouncedEffect from 'use-debounced-effect';
import pickColorForBackground from '../../../utils/pickColorForBackground';
import IActions from 'actions/IActions';
import { Review } from './Review/Review';
const styles = require('./Dashboard.css');

interface IProps {
    profile: IProfile;
    setHeaderProps: (props: Partial<IHeaderProps>) => void;
    isCustomer?: boolean;
    actions?: IActions;
}

enum appointmentType {
    Past = 0,
    UpComing,
    Other,
}

export interface ICustomer {
    id: number;
    firstName: string;
    lastName: string;
    countryCode?: string;
    phone?: string;
    email?: string;
    birthDate?: string;
}

export interface IAppointment {
    id: number;
    canceled: boolean;
    employee: IPerson;
    location: ILocation;
    service: IService;
    begin: string;
    end: string;
    notes?: string;
    customer: ICustomer;
    bookingFor?: ICustomer;
    needsAttention?: boolean;
    confirmed?: Boolean;
    company: {
        id: number;
        name: string;
    };
    customDate?: boolean;
}

const makeEvent = (appointment: IAppointment, isCustomer: boolean) => {
    const { customer, service, notes } = appointment;
    const phone = new PhoneNumber(customer.countryCode + customer.phone)
        .getNumber('international');
    const { email } = customer;
    const name = `${customer.firstName} ${customer.lastName}`;
    const subjectShort = (
        <>
            <span className={styles.bold}>
                {name}
            </span>
            {notes && ` ${notes}`}
        </>
    );
    return {
        id: String(appointment.id),
        start: appointment.begin,
        end: appointment.end,
        ...(isCustomer ? {
            subject: service.name,
        } : {
            subjectShort,
            subject: (
                <>
                    {subjectShort}
                    {` tel. ${phone}${email ? `, ${email}` : ''}`}
                </>
            ),
            subject2: service.name,
            color: appointment.service.color,
        }),
        timezone: appointment.location.timezone,
    };
};

export default function Dashboard({ profile, setHeaderProps, isCustomer, actions }: IProps) {
    const timezone = profile?.timezone || moment.tz.guess();
    const params: any = useParams();
    const loggedIn = isLoggedIn();
    const adHoc = isAdHoc();
    const [isConfirm, setIsConfirm] = useState<boolean>(params.serviceId? true: false);
    const { t } = useTranslation();
    const [appointments, setAppointments] = useState<IAppointment[] | null>(null);
    const [canceledTab, setCanceledTab] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState<number | null>(params.serviceId? Number(params.serviceId): null);
    const [selectedDate, setSelectedDate] = useState<Moment>(moment().tz(timezone).startOf('week'));
    const [cancelId, setCancelId] = useState<number | null>(null);
    const [editId, setEditId] = useState<number | null>(null);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedAppointmentType, setSelectedAppointmentType] = useState<appointmentType | null>(null);
    const [isChangeRating, setIsChangeRating] = useState<boolean>(false);
    const [sendConfirm, setSendConfirm] = useState<boolean>(false);
    const [isShowPrivacyModal, setIsShowPrivacyModal] = useState<boolean>(false);
    const proposition = useMemo(() => (() => {
        if (!selectedAppointment || !appointments) {
            return null;
        }
        const appointment = appointments.find(R.propEq('id', selectedAppointment));
        if (!appointment) {
            return null;
        }
        return makeEvent(appointment, isCustomer);
    })(), [selectedAppointment, appointments]);
    const [searchOpen, setSearchOpen] = useState(false);
    const [search, setSearch] = useState('');
    const searchRef = useRef<HTMLDivElement | null>(null);
    const getAppointments = useCallback(() => {
        const from = (isCustomer || adHoc)
            ? '2020-01-01T00:00:00Z'
            : moment(selectedDate).startOf('week').toISOString();
        const to = (isCustomer || adHoc)
            ? '2120-01-01T00:00:00Z'
            : moment(selectedDate).endOf('week').toISOString();
        return (isCustomer
            ? BackendMethods.getCustomerAppointments(from, to)
            : BackendMethods.getAppointments(from, to));
    }, [selectedDate, isCustomer]);
    useEffect(() => {
        if (isConfirm) {
            (async () => {
                try {
                    await BackendMethods.confirmAppointment(Number(params.serviceId));
                } catch (error) {
                    setIsConfirm(false);
                    actions.showErrorMessage(t('small.confirmationErrorMsg'));
                }
            })();
        }
    }, [isConfirm]);
    useEffect(() => {
        (async () => {
            if (loggedIn || adHoc) {
                if (profile.appointments.companyId) {
                    getConsents();
                }
                const newAppointments = await getAppointments();
                if (selectedAppointment) {
                    const nowAppointment: IAppointment = newAppointments.find(appointment => appointment.id === selectedAppointment);
                    if (!nowAppointment || moment(nowAppointment.begin).isBefore(moment())) {
                        setIsConfirm(false);
                    }
                }
                setAppointments(R.sortBy(R.prop('begin'), newAppointments));
                if (adHoc && newAppointments.length === 1) {
                    setSelectedAppointment(newAppointments[0].id);
                } else {
                    setSelectedAppointment(null);
                    setSelectedAppointmentType(null);
                }
            }
        })();
    }, [isCustomer, getAppointments]);
    const getConsents = async () => {
        try {
            const consents = await BackendMethods.getConsents('PRIVACY_POLICY,TAC_EMPLOYEE');
            if (consents.length > 0) {
                setIsShowPrivacyModal(true);
            }
        } catch (error) {
            // history.goBack();
        }
    };
    useDebouncedEffect(() => {
        (async () => {
            if (loggedIn && !isCustomer) {
                const newAppointments = await (search ? BackendMethods.searchAppointments(search) : getAppointments());
                setAppointments(R.sortBy(R.prop('begin'), newAppointments));
            }
        })();
    }, 300, [getAppointments, isCustomer, search]);
    useEffect(() => {
        if (loggedIn) {
            setHeaderProps({
                children: (
                    <div className={styles.searchContainer}>
                        <Collapse
                            orientation="horizontal"
                            in={searchOpen}
                        >
                            <TextField
                                value={search}
                                onChange={({ target }) => (
                                    setSearch(target.value)
                                )}
                                className={styles.search}
                                InputProps={{
                                    classes: {
                                        root: styles.searchInputContainer,
                                        input: styles.searchInput,
                                    },
                                }}
                                placeholder={t('home.search')}
                                inputRef={searchRef}
                            />
                        </Collapse>
                        <div className={styles.searchButtonContainer}>
                            <Fade in={searchOpen}>
                                <IconButton
                                    onClick={() => {
                                        setSearchOpen(false);
                                        setSearch('');
                                    }}
                                    className={styles.searchButton}
                                    size="large">
                                    <img src="/assets/close-icon.svg" alt="" className={styles.closeSearchIcon} />
                                </IconButton>
                            </Fade>
                            <Fade in={!searchOpen}>
                                <IconButton
                                    onClick={() => {
                                        setSearchOpen(true);
                                        setTimeout(() => searchRef.current.focus(), 1);
                                    }}
                                    className={styles.searchButton}
                                    size="large">
                                    <img src="/assets/search_icon.svg" alt="" className={styles.searchIcon} />
                                </IconButton>
                            </Fade>
                        </div>
                    </div>
                ),
            });
        }
        return () => setHeaderProps({});
    }, [searchOpen, search]);
    const firstTodayRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (firstTodayRef.current && !isCustomer) {
            scrollIntoView(firstTodayRef.current, {
                time: 0,
                align: {
                    top: 0,
                },
            });
        }
    }, [appointments, isCustomer]);
    if ((loggedIn || adHoc) && !appointments) {
        return null;
    }
    const filteredAppointments = (appointments || []).filter((appointment) => (
        (adHoc || appointment.canceled === canceledTab)
        && (!isCustomer || [
            appointment.service.name, appointment.employee.name,
            `${appointment.customer.firstName} ${appointment.customer.lastName}`,
        ].some((x) => x.toLowerCase().includes(search.toLowerCase())))
    ));
    const [upcoming, pastUnsorted] = R.partition(({ begin }) => moment(begin).isAfter(moment()), filteredAppointments);
    const past = R.reverse(pastUnsorted);
    const attentionCount = (appointments || []).filter((x) => (
        x.needsAttention && moment(x.begin).isAfter(moment())
    )).length;
    const firstTodayId = filteredAppointments
        .find((x) => moment(x.begin).isSameOrAfter(moment(), 'day'))?.id;
    const changeConfirm = async (id: number, confirmed: boolean) => {
        if (!sendConfirm) {
            setSendConfirm(true);
            try {
                await BackendMethods.changeAppointmentConfirm(id, confirmed);
                setAppointments(appointments.map((x) => (
                    x.id === id ? {
                        ...x,
                        confirmed: confirmed
                    } : x
                )));
            } catch (error) {
            } finally {
                setSendConfirm(false);
            }
        }
    };
    const renderAppointment = (appointment: IAppointment, type?: appointmentType) => {
        const { service, employee, location } = appointment;
        const person = appointment.bookingFor || appointment.customer;
        const bookBase = isCustomer
            ? `/sb-customer/book/${appointment.company.id}`
            : '/sb/assistant-booking';
        const isPast = moment(appointment.begin).isBefore(moment());
        const timezoneMismatch = location.timezone !== timezone;
        return (
            <div
                key={appointment.id}
                className={styles.appointment}
                onClick={() => {
                    if (!adHoc) {
                        setSelectedAppointmentType(selectedAppointment === appointment.id
                            ? null
                            : type,
                        );
                        setSelectedAppointment(selectedAppointment === appointment.id
                            ? null
                            : appointment.id,
                        );
                    }
                }}
                ref={appointment.id === firstTodayId ? firstTodayRef : null}
            >
                <div className={styles.appointmentData}>
                    {(!isCustomer || appointment.bookingFor) && (
                        <>
                            <div className={styles.line}>
                                {!isCustomer && !appointment.canceled && !isPast && (
                                    <>
                                        <IconButton
                                            size="small"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                BackendMethods.changeNeedsAttention(
                                                    appointment.id,
                                                    !appointment.needsAttention,
                                                );
                                                setAppointments(appointments.map((x) => (
                                                    x.id === appointment.id ? {
                                                        ...x,
                                                        needsAttention: !x.needsAttention,
                                                    } : x
                                                )));
                                            }}
                                            className={styles.flagButton}
                                        >
                                            <img
                                                src={appointment.needsAttention
                                                    ? '/assets/flag-red.svg'
                                                    : '/assets/flag-white.svg'}
                                                alt=""
                                            />
                                        </IconButton>
                                        {appointment.confirmed !== undefined && (
                                            <div
                                                className={styles.confirmContent}
                                                onClick={() => changeConfirm(appointment.id, !appointment.confirmed)}
                                            >
                                                {appointment.confirmed ? (
                                                    <Tooltip
                                                        title={t('small.confirmSchedule')}
                                                        placement="top"
                                                        classes={{ tooltip: styles.tooltip }}
                                                    >
                                                        <div className={styles.nonConfirm}>
                                                            <img
                                                                src="/assets/check-icon.svg"
                                                                width={'100%'}
                                                                height={'100%'}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip
                                                        title={t('small.nonConfirmScheduleYet')}
                                                        placement="top"
                                                        classes={{ tooltip: styles.tooltip }}
                                                    >
                                                        <div className={styles.nonConfirm}>
                                                            ?
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                                <div className={styles.lineText}>
                                    <span className={styles.bold}>
                                        {person.firstName}
                                        {' '}
                                        {person.lastName}
                                    </span>
                                    {!isCustomer && (
                                        <>
                                            &nbsp;
                                            {person.phone && ` tel. ${formatPhone(person)}`}
                                            {person.email && `, ${person.email}`}
                                        </>
                                    )}
                                </div>
                                {!isCustomer && (
                                    <>
                                        {appointment.bookingFor && (
                                            <Tooltip
                                                title={(
                                                    <>
                                                        {t('small.bookedBy')}
                                                        :
                                                        <br />
                                                        {appointment.customer.firstName}
                                                        {' '}
                                                        {appointment.customer.lastName}
                                                        {', tel. '}
                                                        {formatPhone(appointment.customer)}
                                                        {' '}
                                                        {appointment.customer.email || ''}
                                                    </>
                                                )}
                                                classes={{ tooltip: styles.tooltip }}
                                            >
                                                <span className={styles.info}>i</span>
                                            </Tooltip>
                                        )}
                                        <IconButton
                                            size="small"
                                            className={styles.editIcon}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setEditOpen(true);
                                                setEditId(appointment.id);
                                            }}
                                        >
                                            <img src="/assets/pen.svg" alt="" />
                                        </IconButton>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    <div className={styles.line}>
                        <div
                            className={classNames(styles.lineText, {
                                [styles.serviceNameLine]: !isCustomer,
                            })}
                        >
                            {isCustomer ? service.name : (
                                <span
                                    className={styles.serviceName}
                                    style={{
                                        backgroundColor: service.color,
                                        color: pickColorForBackground(service.color),
                                    }}
                                >
                                    {service.name}
                                </span>
                            )}
                            , {employee.name}
                            <span className={styles.price}>
                                {renderPrice(service.price)}
                            </span>
                        </div>
                        {appointment.notes && (
                            <Tooltip
                                title={appointment.notes}
                                classes={{ tooltip: styles.tooltip }}
                            >
                                <span className={styles.info}>i</span>
                            </Tooltip>
                        )}
                    </div>
                    <div className={styles.line}>
                        <div className={styles.lineText}>
                            {location.name}&nbsp; {location.address}
                        </div>
                    </div>
                    <div className={classNames(styles.line, styles.bold)}>
                        {moment(appointment.begin).tz(location.timezone)
                            .format('dddd, DD MMMM Y, HH:mm')}
                        -
                        {moment(appointment.end).tz(location.timezone)
                            .format('HH:mm')}
                        {timezoneMismatch && (
                            <Tooltip title={t('small.differentTimezone')}>
                                <span className={styles.timezoneMismatch}>
                                    {moment(appointment.end).tz(location.timezone).format('z')}
                                </span>
                            </Tooltip>
                        )}
                    </div>
                    {(isCustomer || adHoc) && (
                        <div className={styles.line}>
                            <h4 className={type === appointmentType.Past ? styles.pastTooltip : styles.upComingTooltip}>{t('small.rate')}</h4>
                            {type !== appointmentType.Past && (
                                <Tooltip title={t('small.youWillBeAbleToRateAfterYourAppointment')} placement="right">
                                    <div className={styles.tooltipIcon}>i</div>
                                </Tooltip>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.appointmentButtons}>
                    {appointment.canceled ? (
                        <Button
                            variant="contained"
                            className={styles.appointmentButton}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            component={Link}
                            to={adHoc
                                ? '/login'
                                : `${bookBase}/rebook/${appointment.id}`}
                        >
                            {t('small.rebook')}
                        </Button>
                    ) : (
                        <>
                            <Button
                                variant="contained"
                                className={styles.appointmentButton}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                component={Link}
                                to={`${bookBase}/edit/${appointment.id}`}
                                disabled={isPast}
                            >
                                {t('small.reschedule')}
                            </Button>
                            <Button
                                variant="contained"
                                className={styles.appointmentButton}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setCancelId(appointment.id);
                                }}
                                disabled={isPast}
                            >
                                {t('small.cancel')}
                            </Button>
                        </>
                    )}
                </div>
                <div
                    className={classNames(styles.joiner, {
                        [styles.joinerVisible]: selectedAppointment === appointment.id,
                    })}
                />
            </div>
        );
    };
    return (
        <div className={styles.root}>
            <div className={styles.main}>
                {isCustomer && (
                    <Collapse orientation="horizontal" in={selectedAppointment === null}>
                        <div className={styles.findServiceColumn}>
                            <FindService
                                isChangeRating={isChangeRating}
                                setIsChangeRating={setIsChangeRating}
                            />
                        </div>
                    </Collapse>
                )}
                {(loggedIn || adHoc) && (
                    <>
                        <div
                            className={classNames(styles.left, {
                                [styles.leftCustomerNotExpanded]: isCustomer && selectedAppointment === null,
                            })}
                        >
                            {!adHoc && (
                                <div className={styles.tabs}>
                                    <Button
                                        variant="contained"
                                        className={classNames(styles.tab, {
                                            [styles.selectedTab]: !canceledTab,
                                        })}
                                        onClick={() => {
                                            setCanceledTab(false);
                                            setSelectedAppointment(null);
                                        }}
                                    >
                                    <span className={styles.bookedText}>
                                        {t('small.booked')}
                                        {attentionCount > 0 && (
                                            <span className={styles.attentionCount}>
                                                {attentionCount}
                                            </span>
                                        )}
                                    </span>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classNames(styles.tab, {
                                            [styles.selectedTab]: canceledTab,
                                        })}
                                        onClick={() => {
                                            setCanceledTab(true);
                                            setSelectedAppointment(null);
                                        }}
                                    >
                                        {t('small.canceled')}
                                    </Button>
                                </div>
                            )}
                            <SimpleBar className={styles.appointments}>
                                <div className={styles.content}>
                                    {(isCustomer && !adHoc && !canceledTab) ? (
                                        <>
                                            <div className={styles.listHeader}>
                                                {t('small.upcomingAppointments')}
                                            </div>
                                            {upcoming.map(item => renderAppointment(item, appointmentType.UpComing))}
                                            {upcoming.length === 0 && (
                                                <div className={styles.noAppointments}>
                                                    {t('small.noAppointments')}
                                                </div>
                                            )}
                                            <div className={styles.listHeader}>
                                                {t('small.pastAppointments')}
                                            </div>
                                            {past.map(item => renderAppointment(item, appointmentType.Past))}
                                            {past.length === 0 && (
                                                <div className={styles.noAppointments}>
                                                    {t('small.noAppointments')}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {filteredAppointments.map(item => renderAppointment(item, appointmentType.Other))}
                                            {filteredAppointments.length === 0 && (
                                                <div className={styles.noAppointments}>
                                                    {t('small.noAppointments')}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </SimpleBar>
                        </div>
                        <Collapse
                            orientation="horizontal"
                            in={!isCustomer || selectedAppointment !== null}
                        >
                            {selectedAppointmentType === appointmentType.Past && appointments.find(item => item.id === selectedAppointment) ? (
                                <div className={styles.reviewContent}>
                                    <div className={styles.reviweBox}>
                                        <Review
                                            company={appointments.find(item => item.id === selectedAppointment).company}
                                            disabled={false}
                                            reviews={null}
                                            setIsChangeRating={setIsChangeRating}
                                        />
                                    </div>
                                </div>
                            ): (
                                <div className={styles.right}>
                                    <WeekCalendar
                                        rangeStart="2000-01-01T00:00:00.000Z"
                                        rangeEnd="2100-01-01T00:00:00.000Z"
                                        events={appointments.filter((x) => (
                                            !x.canceled && x.id !== selectedAppointment
                                        )).map((x) => makeEvent(x, isCustomer))}
                                        proposition={proposition}
                                        timezone={timezone}
                                        timeFormat={profile.timeFormat}
                                        workWeekEvents={[]}
                                        startOfDay={selectedDate}
                                        setStartOfDay={setSelectedDate}
                                        showHolidays
                                    />
                                </div>
                            )}
                        </Collapse>
                        <Dialog
                            open={cancelId !== null}
                            onClose={() => setCancelId(null)}
                            classes={{ paper: styles.dialog }}
                        >
                            <div className={styles.dialogText}>
                                {t('small.cancelConfirm')}
                            </div>
                            <div className={styles.dialogActions}>
                                <Button
                                    className={styles.dialogButton}
                                    onClick={async () => {
                                        if (isCustomer) {
                                            await BackendMethods.customerCancelAppointment(
                                                cancelId,
                                                appointments.find(R.propEq('id', cancelId)).company.id,
                                            );
                                        } else {
                                            await BackendMethods.cancelAppointment(cancelId);
                                        }
                                        if (selectedAppointment === cancelId && !adHoc) {
                                            setSelectedAppointment(null);
                                        }
                                        setAppointments(appointments.map((x) => (
                                            x.id === cancelId ? {
                                                ...x,
                                                canceled: true,
                                            } : x
                                        )));
                                        setCancelId(null);
                                    }}
                                >
                                    {t('small.yes')}
                                </Button>
                                <Button
                                    variant="contained"
                                    className={styles.dialogButton}
                                    onClick={() => setCancelId(null)}
                                >
                                    {t('small.no')}
                                </Button>
                            </div>
                        </Dialog>
                        <Dialog
                            open={isConfirm}
                            onClose={() => setIsConfirm(false)}
                            classes={{ paper: styles.confirmModal }}
                        >
                            <div className={styles.confirmModalText}>
                                {t('small.attendanceConfirmed')}
                            </div>
                            <div className={styles.confirmModalIcon}>
                                <img src="/assets/checkmark_icon.svg" alt="" width={45} height={42} />
                            </div>
                            <Button
                                className={styles.fillColorButton}
                                onClick={() => setIsConfirm(false)}
                            >
                                {t('small.viewDetails')}
                            </Button>
                            <h4 className={styles.confirmModalContectText}>{t('small.cantAttend')}</h4>
                            <div className={styles.confirmModalButtonGroup}>
                                <Button
                                    className={styles.confirmModalButton}
                                    onClick={() => {
                                        setCancelId(Number(selectedAppointment));
                                        setIsConfirm(false);
                                    }}
                                >
                                    {t('small.cancel')}
                                </Button>
                                <Button
                                    className={styles.confirmModalButton}
                                    component={Link}
                                    to={isCustomer
                                        ? `/sb-customer/book/${appointments.find(appointment => appointment.id === selectedAppointment)?.company.id}/edit/${selectedAppointment}`
                                        : `/sb/assistant-booking/edit/${selectedAppointment}`}
                                >
                                    {t('small.reschedule')}
                                </Button>
                            </div>
                        </Dialog>
                        <Dialog
                            open={editOpen}
                            onClose={() => setEditOpen(false)}
                            classes={{ paper: styles.dialog }}
                        >
                            <CustomerData
                                appointment={appointments.find(R.propEq('id', editId))}
                                onSave={(appointment) => {
                                    setAppointments(appointments.map((x) => (
                                        x.id === editId ? appointment : x
                                    )));
                                    setEditOpen(false);
                                }}
                            />
                        </Dialog>
                    </>
                )}
            </div>
            <Footer />
        </div>
    );
}
