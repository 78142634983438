import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { SortBy } from 'elementsSelectors/Reviews';
import Styles from './SortMenu.css';
import { useTranslation } from 'react-i18next';

interface ISortMenuProps {
    sortBy: SortBy;
    setSortBy: React.Dispatch<React.SetStateAction<SortBy>>;
}
export const SortMenu = ({ sortBy, setSortBy }: ISortMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { t } = useTranslation();
    return (
        <div className={Styles.menu}>
            <div
                className={Styles.menuButton}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                <img src="/assets/menu_icon.svg" alt="" className={Styles.menuIcon} />
            </div>
            <Menu
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                className={Styles.menuItems}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem
                    className={sortBy === SortBy.Latest ? Styles.selectedItem : Styles.menuItem}
                    onClick={() => {
                        setSortBy(SortBy.Latest);
                        setAnchorEl(null);
                    }}
                >
                    {t('small.mostRecent')}
                </MenuItem>
                <MenuItem
                    className={sortBy === SortBy.Highest ? Styles.selectedItem : Styles.menuItem}
                    onClick={() => {
                        setSortBy(SortBy.Highest);
                        setAnchorEl(null);
                    }}
                >
                    {t('small.highestRating')}
                </MenuItem>
                <MenuItem
                    className={sortBy === SortBy.Lowest ? Styles.selectedItem : Styles.menuItem}
                    onClick={() => {
                        setSortBy(SortBy.Lowest);
                        setAnchorEl(null);
                    }}
                >
                    {t('small.lowestRating')}
                </MenuItem>
            </Menu>
        </div>
    );
};
