// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".AnimationsContainer__-h1d- {\n    background: transparent;\n    display: flex;\n    height: 700px;\n    justify-content: space-between;\n    margin: 0 auto;\n    max-width: 1800px;\n    padding-left: 40px;\n}\n", "",{"version":3,"sources":["Animations.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB","file":"Animations.css","sourcesContent":[".AnimationsContainer {\n    background: transparent;\n    display: flex;\n    height: 700px;\n    justify-content: space-between;\n    margin: 0 auto;\n    max-width: 1800px;\n    padding-left: 40px;\n}\n"]}]);
// Exports
exports.locals = {
	"AnimationsContainer": "AnimationsContainer__-h1d-"
};
module.exports = exports;
