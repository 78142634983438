import auth0 from 'auth0-js';
import { parseJwt, getAuthTokenType, getSearchParam, deleteAuthCookies } from '../scripts/helpers';
import cookies, { CookieAttributes } from 'js-cookie';
const config = require('CONFIG');

const { hostname } = window.location;
const domain = hostname.endsWith(config.baseDomain) ? config.baseDomain : hostname;

const cookieOptions: CookieAttributes = {
    expires: 365,
    secure: true,
    sameSite: 'none',
    domain,
};

class Auth {
    webAuth = new auth0.WebAuth({
        domain: config.auth0Domain,
        clientID: config.auth0ClientID,
        audience: config.auth0Audience,
        redirectUri: `${location.origin}/login`,
        responseType: 'token id_token',
        scope: 'openid profile email phone',
        prompt: 'none'
    });
    authManage = null;
    login = () => {
        this.webAuth.authorize({ connection: 'google-oauth2' });
    };
    microsoftSignIn = () => {
        this.webAuth.authorize({ connection: 'msoft' });
    };
    loginWithEmail = (email: string, password: string, callback = () => {}) => {
        this.webAuth.login(
            {
                connection: 'Username-Password-Authentication',
                email,
                password
            },
            callback
        );
    };

    handleAuth = (
        onSuccess: Function,
        onFailure: Function,
        onEmailNotVerified?: Function
    ) => {
        if (getSearchParam(location.search, 'id_token')) {
            // Logs in through Enterprise or logs iframe on chrome plugin popup, both using search instead of hash
            if (
                this.isAuthenticated() &&
                getSearchParam(location.search, 'token_type') === 'Enterprise'
            ) {
                deleteAuthCookies();
                return this.webAuth.logout({ returnTo: location.href });
            } else {
                const authToken = getSearchParam(location.search, 'id_token');
                const tokenType = getSearchParam(location.search, 'token_type');
                const expiresAt =
                    new Date().getTime() + parseJwt(authToken).exp * 1000 ||
                    1000 * 60 * 60 * 120;
                cookies.set('authToken', `${tokenType} ${authToken}`, cookieOptions);
                cookies.set('expiresAt', String(expiresAt), cookieOptions);
                sessionStorage.removeItem('tanbuuAdHoc');

                this.webAuth.client.userInfo(
                    authToken,
                    (err, user) => {
                        if (err) {
                            onFailure();
                        } else {
                            onSuccess(user);
                        }
                    },
                );
            }
        } else {
            // Logs in through auth0
            this.webAuth.parseHash((err, authResult) => {
                if (authResult) {
                    this.webAuth.client.userInfo(
                        authResult.accessToken,
                        (err2, user) => {
                            if (user.email && !user.email_verified) {
                                return onEmailNotVerified();
                            }

                            const adHocToken = sessionStorage.getItem('tanbuuAdHoc');
                            if (adHocToken) {
                                const token = parseJwt(adHocToken.split(' ')[1]);
                                if (token.phone && user.email && user.email_verified && !user.phone_number) {
                                    localStorage.setItem('mergeAuthToken', adHocToken);
                                }
                            }
                            this.setSession(authResult);
                            if (sessionStorage.getItem('outlook')) {
                                const { outlookOrigin } = config;
                                const { accessToken } = authResult;
                                location.assign(
                                    `${outlookOrigin}/callback.html?authToken=${accessToken}`,
                                );
                            }
                            if (!localStorage.getItem('mergeRedirectURI') && user[`https://tanbuu.com/newCompanyDomain`]) {
                                localStorage.setItem('mergeRedirectURI', '/sb/registered');
                                localStorage.setItem('subDomain', user[`https://tanbuu.com/newCompanyDomain`]);
                            } else if (localStorage.getItem('mergeRedirectURI') !== '/sb/registered') {
                                localStorage.removeItem('subDomain');
                            }
                            onSuccess(user);
                        }
                    );
                } else {
                    onFailure();
                }
            });
        }
    };

    passwordReset = (email: string) => {
        let options = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                client_id: config.auth0ClientID,
                email: email,
                connection: 'Username-Password-Authentication'
            }),
            json: true
        };
        return fetch(
            `https://${config.auth0Domain}/dbconnections/change_password`,
            options
        );
    };

    resendActivationLink = async () => {
        const token = `Bearer ${getSearchParam(location.hash.substring(1), 'access_token')}`;
        const options = {
            method: 'GET',
            headers: {
                Authorization: token
            }
        };
        return fetch(
            `/api/verification/resend`,
            options
        );
    };

    signUp = (email: string, password: string, language: string): Promise<any> => {
        let user_metadata: any;
        if (localStorage.getItem('mergeRedirectURI') && localStorage.getItem('mergeRedirectURI') === '/sb/registered') {
            user_metadata = {
                lang: language,
                newCompanyDomain: localStorage.getItem('subDomain')
            };
        } else {
            user_metadata = {
                lang: language
            };
        }
        let options = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                client_id: config.auth0ClientID,
                email,
                password,
                connection: 'Username-Password-Authentication',
                user_metadata: user_metadata
            }),
            json: true
        };
        return fetch(`https://${config.auth0Domain}/dbconnections/signup`, options);
    };

    logout = () => {
        deleteAuthCookies();
        const { port } = window.location;
        this.webAuth.logout({ returnTo: `https://${domain}${port ? `:${port}` : ''}/login` });
    };
    isAuthenticated = () => {
        return (
            cookies.get('authToken') &&
            new Date().getTime() < Number(cookies.get('expiresAt'))
        );
    };

    renewSession() {
        if (getAuthTokenType() === 'Bearer') {
            this.webAuth.checkSession({}, (err, authResult) => {
                if (
                    authResult &&
                    authResult.accessToken &&
                    authResult.idToken
                ) {
                    this.setSession(authResult);
                } else if (err) {
                    // this.logout();
                    // alert(
                    //     `Could not get a new token (${err.error}: ${
                    //         err.error_description
                    //     }).`
                    // );
                }
            });
        }
    }

    setSession(authResult, type = 'jwt') {
        let expiresAt = JSON.stringify(
            parseJwt(authResult.accessToken).exp * 1000 + new Date().getTime()
        );
        cookies.set('authToken', `${authResult.tokenType} ${authResult.accessToken}`, cookieOptions);
        cookies.set('expiresAt', expiresAt, cookieOptions);
        sessionStorage.removeItem('tanbuuAdHoc');
    }
}

export default new Auth();
