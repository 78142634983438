import * as React from 'react';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import VerifyEmail from '../../components/EmailHandlerPageComponents/VerifyEmail/VerifyEmail';
const connect = require('react-redux').connect;
const Styles = require('./EmailHandlerPage.css');

interface ILoginPageProps {
    data: any;
    location: any;
    history: any;
}

const EmailHandlerPage = (props: ILoginPageProps) => {
    const logoSrc = props.data.get('logoSrc');
    return (
        <div className={Styles.EmailHandlerPage}>
            <div>
                <img src={logoSrc} width={200} height={144} alt="Tanbuu" />
            </div>
            <VerifyEmail />
        </div>
    );
};

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(EmailHandlerPage);
