import React, { useState } from 'react';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import TextField from '../TextField/TextField';
import { IconButton, InputAdornment } from '@material-ui/core';
import * as R from 'ramda';
import classNames from 'classnames';
const styles = require('./PasswordField.css');

export default function PasswordField(props: Partial<OutlinedTextFieldProps>) {
    const [visible, setVisible] = useState(false);
    const { InputProps: { classes = {}, ...InputPropsRest } = {}, ...rest } = props;
    return (
        <TextField
            type={visible ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => setVisible(R.not)} size="large">
                            <span
                                className={classNames(
                                    styles.icon,
                                    styles[visible ? 'iconVisibility' : 'iconVisibilityOff']
                                )}
                            />
                        </IconButton>
                    </InputAdornment>
                ),
                classes: {
                    adornedEnd: styles.inputAdornedEnd,
                    ...classes
                },
                ...InputPropsRest
            }}
            {...rest}
        />
    );
}
