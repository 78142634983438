import React  from 'react';
import { MenuItem } from '@material-ui/core';
import { IConnection, IMeetingIntegration } from '../../../interfaces/interfaces';
import TextField from '../../_ReusableComponents/TextField/TextField';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
const styles = require('./CalendarPicker.css');

interface Props {
    calendars: IConnection[];
    value: IMeetingIntegration | undefined;
    onChange: (value: IMeetingIntegration | undefined) => void;
    isGoogleMeet: boolean;
}

export default function CalendarPicker(props: Props) {
    const { t } = useTranslation();
    return (
        <TextField
            select
            label={t('new.addToCalendar')}
            value={props.value?.account ? props.calendars.find(R.whereEq(props.value)) : ''}
            SelectProps={{
                onChange: ({ target }) =>
                    props.onChange((target.value as IConnection) || undefined)
            }}
            fullWidth
            disabled={props.isGoogleMeet}
            helperText={props.isGoogleMeet ? t('new.calendarLocked') : ''}
        >
            {props.calendars.map(calendar => (
                <MenuItem
                    key={`${calendar.provider}${calendar.account}`}
                    value={calendar as any}
                >
                    <div className={styles.calendar}>
                        {calendar.provider === 'google' && (
                            <img
                                src="/assets/google_icon.png"
                                className={styles.providerIcon}
                            />
                        )}
                        {calendar.provider === 'microsoft' && (
                            <svg
                                viewBox="0 0 420 500"
                                className={styles.providerIcon}
                            >
                                <polygon
                                    points="9,399 9,104 270,7 411,53 411,450 270,493 9,399 270,430 270,87 99,126 99,359"
                                />
                            </svg>
                        )}
                        {calendar.provider === 'apple' && (
                            <img
                                src="/assets/icloud_icon.png"
                                className={styles.providerIcon}
                            />
                        )}
                        <span className={styles.account}>
                            {calendar.account}
                        </span>
                    </div>
                </MenuItem>
            ))}
            {/* <MenuItem value="">
                <div className={styles.providerIcon} />
                {t('new.calendarNone')}
            </MenuItem> */}
        </TextField>
    );
}
