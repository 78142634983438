// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".paper__1UFyz {\n    padding: 24px;\n    margin: 16px;\n    text-align: center;\n}\n\n.logo__1fnn9 {\n    width: 132px;\n    margin: 0 auto 8px;\n}\n\n.text__KlJgU {\n    font-size: 18px;\n    margin: 8px 0;\n}\n\n.buttons__2-vIq {\n    margin: 16px -8px 0;\n    display: flex;\n    justify-content: center;\n}\n\n.dialogButton__3fZOA {\n    margin: 0 8px;\n}\n", "",{"version":3,"sources":["RegisterModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB","file":"RegisterModal.css","sourcesContent":[".paper {\n    padding: 24px;\n    margin: 16px;\n    text-align: center;\n}\n\n.logo {\n    width: 132px;\n    margin: 0 auto 8px;\n}\n\n.text {\n    font-size: 18px;\n    margin: 8px 0;\n}\n\n.buttons {\n    margin: 16px -8px 0;\n    display: flex;\n    justify-content: center;\n}\n\n.dialogButton {\n    margin: 0 8px;\n}\n"]}]);
// Exports
exports.locals = {
	"paper": "paper__1UFyz",
	"logo": "logo__1fnn9",
	"text": "text__KlJgU",
	"buttons": "buttons__2-vIq",
	"dialogButton": "dialogButton__3fZOA"
};
module.exports = exports;
