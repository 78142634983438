// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MeetingTitle__3IOku {\n    box-sizing: content-box;\n    cursor: pointer;\n    padding: 10px 10px 10px 0;\n    position: relative;\n}\n\n.MeetingTitle__3IOku > div:nth-child(1) {\n    display: flex;\n    align-items: center;\n}\n\n.MeetingTitle__3IOku > div:nth-child(1) > span {\n    font-size: 20px;\n    line-height: 1.2;\n    word-wrap: break-word;\n}\n\n.ChevronIcon__3X2FS {\n    height: 30px;\n    transition: all 0.3s ease-out;\n    width: 30px;\n    margin-left: 8px;\n    margin-top: 2px;\n    flex-shrink: 0;\n}\n\n.ChevronIcon--Rotate__3Vb01 {\n    transform: rotate(180deg);\n}\n\n.MeetingTitle__3IOku > div:nth-child(2) {\n    font-size: 18px;\n    margin: 5px 0;\n    max-height: 500px;\n    overflow: auto;\n    white-space: pre-line;\n    width: 100%;\n}\n", "",{"version":3,"sources":["MeetingTitle.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,eAAe;IACf,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,qBAAqB;IACrB,WAAW;AACf","file":"MeetingTitle.css","sourcesContent":[".MeetingTitle {\n    box-sizing: content-box;\n    cursor: pointer;\n    padding: 10px 10px 10px 0;\n    position: relative;\n}\n\n.MeetingTitle > div:nth-child(1) {\n    display: flex;\n    align-items: center;\n}\n\n.MeetingTitle > div:nth-child(1) > span {\n    font-size: 20px;\n    line-height: 1.2;\n    word-wrap: break-word;\n}\n\n.ChevronIcon {\n    height: 30px;\n    transition: all 0.3s ease-out;\n    width: 30px;\n    margin-left: 8px;\n    margin-top: 2px;\n    flex-shrink: 0;\n}\n\n.ChevronIcon--Rotate {\n    transform: rotate(180deg);\n}\n\n.MeetingTitle > div:nth-child(2) {\n    font-size: 18px;\n    margin: 5px 0;\n    max-height: 500px;\n    overflow: auto;\n    white-space: pre-line;\n    width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"MeetingTitle": "MeetingTitle__3IOku",
	"ChevronIcon": "ChevronIcon__3X2FS",
	"ChevronIcon--Rotate": "ChevronIcon--Rotate__3Vb01"
};
module.exports = exports;
