import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from 'components/_ReusableComponents/TextField/TextField';
import { Tooltip } from '@material-ui/core';
import Styles from './SbSliderContent.css';
import Button from 'components/_ReusableComponents/Button/Button';
import BackendMethods from 'api/BackendMethods';
import { useHistory } from 'react-router-dom';
import IActions from 'actions/IActions';

interface SliderContentProps {
  actions: IActions;
}
export const SbSliderContent:React.FC<SliderContentProps> = ({actions}) => {
  const [subDomain, getSubDomain] = useState<string>('');
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    localStorage.removeItem('subDomain');
    localStorage.removeItem('mergeRedirectURI');
  }, []);
  const startTrial = async () => {
    if (subDomain.length === 0) {
      actions.showErrorMessage(t('small.enterCompanyName'));
      return;
    }
    try {
      const checkDomain = await BackendMethods.checkSubdomain(subDomain);
      actions.showErrorMessage(t('small.alreadyUsedName'));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        localStorage.setItem('subDomain', subDomain);
        localStorage.setItem('mergeRedirectURI', '/sb/registered');
        history.push('/register');
      }
    }
  };
  return (
    <div className={Styles.content}>
      <div className={Styles.contentBody}>
        <h2 className={Styles.header}>{t('small.tryFree')}</h2>
        <div className={Styles.body}>
          <div className={Styles.inputField}>
            <TextField
              className={Styles.sliderInput}
              value={subDomain}
              type="text"
              onChange={({ target }) => {
                getSubDomain(target.value);
              }}
              placeholder={t('small.yourCompany')}
              data-testid="register-email"
            />
            <label className={Styles.inputDomain}>
              .tanbuu.com
              <Tooltip
                title={t('small.willAddressBookingPage')}
                placement="top"
                classes={{ tooltip: Styles.tooltip }}
              >
                <span className={Styles.info}>i</span>
              </Tooltip>
            </label>
          </div>
          <Button
            variant="text"
            className={Styles.bigSignUp}
            onClick={startTrial}
          >
            {t('small.startFreeTrial')}
          </Button>
        </div>
        <h4 className={Styles.footer}>{t('small.nocardRequire')}</h4>
      </div>
    </div>
  );
};
