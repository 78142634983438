// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".AvailableLicenses__cBRwq {\n    width: 250px;\n    display: flex;\n    flex-direction: column;\n    font-size: 20px;\n    justify-content: flex-end;\n    padding: 16px 12px;\n}\n", "",{"version":3,"sources":["AvailableLicenses.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,yBAAyB;IACzB,kBAAkB;AACtB","file":"AvailableLicenses.css","sourcesContent":[".AvailableLicenses {\n    width: 250px;\n    display: flex;\n    flex-direction: column;\n    font-size: 20px;\n    justify-content: flex-end;\n    padding: 16px 12px;\n}\n"]}]);
// Exports
exports.locals = {
	"AvailableLicenses": "AvailableLicenses__cBRwq"
};
module.exports = exports;
