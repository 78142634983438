import React, { useContext, useEffect, useState } from 'react';
import Styles from './Registered.css';
import BackendMethods from '../../api/BackendMethods';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router';
import { fetchProfile } from 'actions/DataActions';
import { useTranslation } from 'react-i18next';
import containersMapStateToProps from 'utils/containersMapStateToProps';
import containersDispatchToProps from 'utils/containersMapDispatchToProps';
import IActions from 'actions/IActions';
import GlobalContext from 'GlobalContext';
import { OwnerGDPRConsentModal } from 'components/_ReusableComponents/Modals/OwnerGDPRConsentModal/OwnerGDPRConsentModal';
import { useIsDesktop } from 'utils/responsive';
import { redirectToSubdomain } from 'utils/subdomain';

const connect = require('react-redux').connect;
interface IRegisteredProps {
    actions: IActions;
}

function Registered(props: IRegisteredProps) {
    const [subDomain, setSubDomain] = useState<string>('');
    const [isRegistered, setIsRegistered] = useState<boolean>(false);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { profile, updateContext } = useContext(GlobalContext);
    const isDesktop = useIsDesktop();

    useEffect(() => {
        if (!profile.email || profile.email.length === 0) {
            props.actions.showErrorMessage(t('needEmail'));
            return;
        }

        if (profile.appointments.companyId) {
            isCompanyOwner();
        }
        if (localStorage.getItem('subDomain')) {
            createDoamin();
        }
    }, []);
    const isCompanyOwner = async () => {
        const contents = await BackendMethods.getConsents('PRIVACY_POLICY,TAC_EMPLOYEE,GDPR_OWNER');
        if (contents.length > 0) {
            const [newPeople] = await Promise.all([
                BackendMethods.getPeople(),
            ]);
            if (newPeople.length === 1 && profile.email === newPeople[0].email) {
                setIsShowModal(true);
                return;
            }
        }
        props.actions.showErrorMessage(t('small.newCompanyCouldNotBeCreated') + ' ' + profile.email + ' ' + t('small.isAlreadyAssociated'));
    };
    const createDoamin = async () => {
        const domain = localStorage.getItem('subDomain');
        try {
            await BackendMethods.createCompany(domain);
            setIsRegistered(true);
            setIsShowModal(true);
            setSubDomain(domain);
            let profilePromise = fetchProfile(i18n);
            const newProfile = await profilePromise;
            updateContext((x) => ({ ...x, profile: newProfile }));
        } catch (error) {
        }
        localStorage.removeItem('subDomain');
    };
    const startSetting = () => {
        redirectToSubdomain(subDomain, '/sb/settings');
    };

    return (
        <div>
            {isRegistered && (<>
                <h4 className={Styles.title}>
                    Twoja firma została zarejestrowana.
                </h4>
                <div className={Styles.image}>
                    <img src="/assets/small/registered.svg" alt="" />
                </div>
                <div className={Styles.content}>
                    <div className={Styles.subContent}>
                        <p className={Styles.subTitle}>Twoja strona:</p>
                        <h4 className={Styles.subBody}>
                            {subDomain + '.tanbuu.com'}
                        </h4>
                    </div>
                    <IconButton
                        onClick={() => { navigator.clipboard.writeText(subDomain + '.tanbuu.com'); }}
                    >
                        <img className={Styles.copyIcon} src="/assets/copy.svg" alt="" />
                    </IconButton>
                </div>
                <div className={Styles.btnGroup}>
                    <button
                        className={Styles.startButton}
                        onClick={startSetting}
                    >
                        Zacznij przyjmować rezerwacje
                    </button>
                </div>
            </>)}
            {isShowModal && (
                <OwnerGDPRConsentModal
                    isDesktop={isDesktop}
                    setShowGDPRConsentModal={setIsShowModal}
                />
            )}
        </div>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(Registered);

