// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__vZFyf {\n    color: #013253;\n    font-size: 20px;\n    padding: 16px 32px;\n    width: 100%;\n    max-width: 425px;\n    margin: 0 auto;\n}\n", "",{"version":3,"sources":["PlusPlanHeader.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,cAAc;AAClB","file":"PlusPlanHeader.css","sourcesContent":[".root {\n    color: #013253;\n    font-size: 20px;\n    padding: 16px 32px;\n    width: 100%;\n    max-width: 425px;\n    margin: 0 auto;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__vZFyf"
};
module.exports = exports;
