import * as React from 'react';
import IActions from '../../../../../actions/IActions';
import { Checkbox } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';

const Styles = require('./AutoRenew.css');

interface IAutoRenewProps extends WithTranslation {
    actions: IActions;
    plusPlanPayment: any;
}

interface IAutoRenewState {
    autoRenew: boolean;
}

export default withTranslation()(class AutoRenew extends React.PureComponent<IAutoRenewProps, IAutoRenewState> {
    constructor(props) {
        super(props);
        this.state = {
            autoRenew: false
        };
    }

    toggleCheckbox = () => {
        this.setState(
            {
                autoRenew: !this.state.autoRenew
            },
            () => {
                const { actions: { setAutoRenew } } = this.props;
                setAutoRenew(this.state.autoRenew);
            }
        );
    };

    componentDidMount() {
        this.setState({ autoRenew: this.props.plusPlanPayment.get('autoRenew') });
    }

    render() {
        const { t } = this.props;
        return (
            <label htmlFor="autoRenew" className={Styles.Label}>
                <span style={{ marginRight: '20px' }}>{t('plan.autoRenew')}</span>
                <Checkbox
                    color="primary"
                    name="autoRenew"
                    id="autoRenew"
                    checked={this.state.autoRenew}
                    onChange={this.toggleCheckbox}
                    className={Styles.checkbox}
                />
            </label>
        );
    }
});
