import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/_ReusableComponents/Button/Button';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import SearchAdornment from '../../../components/_ReusableComponents/SearchAdornment';
import IProfile from '../../../interfaces/IProfile';
import { Country } from 'country-telephone-data';
import * as R from 'ramda';
import { ISearchCustomer } from '../../../interfaces/interfaces';
import moment, { Moment } from 'moment';
import { ICustomer, INewCustomer } from '../DesktopBooking';
import { formatPhone, getObjCountryAndPhone, getPhoneObj, getTimezoneCountry } from '../../../utils/phone';
import classNames from 'classnames';
import BackendMethods from '../../../api/BackendMethods';
import { DatePicker } from '@material-ui/lab';
import PhoneInput from '../../../components/_ReusableComponents/PhoneInput';
import { Dialog, Tooltip } from '@material-ui/core';
import { IAppointment } from '../Dashboard/Dashboard';
import AffectedAppointments from './AffectedAppointments';
const styles = require('./Customers.css');

interface IProps {
    profile: IProfile;
}

export default function Customers(props: IProps) {
    const { profile: { timezone } } = props;
    const { t, i18n: { language } } = useTranslation();
    const defaultCountry = getTimezoneCountry(timezone);
    const [search, setSearch] = useState('');
    const [customers, setCustomers] = useState<ISearchCustomer[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<number | 'new' | null>(null);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [birthdate, setBirthdate] = useState<Moment | null>(null);
    const [country, setCountry] = useState<Country>(defaultCountry);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [care, setCare] = useState<ICustomer[]>([]);
    const [selectedExtra, setSelectedExtra] = useState<number | 'new' | null>(null);
    const [extraName, setExtraName] = useState('');
    const [extraSurname, setExtraSurname] = useState('');
    const [extraBirthdate, setExtraBirthdate] = useState<Moment | null>(null);
    const [extraCountry, setExtraCountry] = useState<Country>(defaultCountry);
    const [phoneVisible, setPhoneVisible] = useState(false);
    const [extraPhone, setExtraPhone] = useState('');
    const [extraEmail, setExtraEmail] = useState('');
    const [deleteExtraOpen, setDeleteExtraOpen] = useState(false);
    const [saveIndependentOpen, setSaveIndependentOpen] = useState(false);
    const [affectedAppointments, setAffectedAppointments] = useState<IAppointment[]>([]);
    useEffect(() => {
        if (!deleteOpen) {
            setAffectedAppointments([]);
        }
    }, [deleteOpen]);
    useEffect(() => {
        if (!deleteExtraOpen) {
            setAffectedAppointments([]);
        }
    }, [deleteExtraOpen]);
    const loadCare = (extra?: ICustomer) => {
        if (extra) {
            setSelectedExtra(extra.id);
            setExtraName(extra.firstName);
            setExtraSurname(extra.lastName);
            setExtraBirthdate(extra.birthDate ? moment(extra.birthDate) : null);
            if (extra.phone) {
                const [newCountry, newPhone] = getObjCountryAndPhone(extra);
                setExtraCountry(newCountry);
                setExtraPhone(newPhone);
                setPhoneVisible(true);
            } else {
                setExtraCountry(defaultCountry);
                setExtraPhone('');
                setPhoneVisible(false);
            }
            setExtraEmail(extra.email || '');
        } else {
            setSelectedExtra('new');
            setExtraName('');
            setExtraSurname('');
            setExtraBirthdate(null);
            setExtraCountry(defaultCountry);
            setExtraPhone('');
            setExtraEmail('');
            setPhoneVisible(false);
        }
    };
    const loadData = async (searchCustomer?: ISearchCustomer) => {
        if (searchCustomer) {
            const href = searchCustomer.href || searchCustomer.hrefCarer;
            const carer = await BackendMethods.getCustomer(href);
            setSelectedCustomer(Number(href.match(/(\d+)/)[1]));
            setName(carer.firstName);
            setSurname(carer.lastName);
            setBirthdate(carer?.birthDate ? moment(carer.birthDate) : null);
            if (carer.phone) {
                const [newCountry, newPhone] = getObjCountryAndPhone(carer);
                setCountry(newCountry);
                setPhone(newPhone);
            } else {
                setCountry(defaultCountry);
                setPhone('');
            }
            setEmail(carer.email || '');
            setCare(carer.care);
            if (searchCustomer.hrefCarer) {
                loadCare(carer.care.find(R.propEq('id', searchCustomer.id)));
            } else {
                setSelectedExtra(null);
            }
        } else {
            setSelectedCustomer('new');
            setName('');
            setSurname('');
            setBirthdate(null);
            setCountry(defaultCountry);
            setPhone('');
            setEmail('');
            setCare([]);
            setSelectedExtra(null);
        }
    };
    const onSearch = async (value: string) => {
        if (value.length >= 3) {
            const newCustomers = await BackendMethods.searchCustomers(value);
            setCustomers(newCustomers);
        } else {
            setCustomers([]);
        }
    };
    return (
        <div className={styles.root}>
            <div className={styles.left}>
                <div className={styles.addHeader}>
                    {t('small.customers')}
                    <Button
                        variant="contained"
                        className={styles.addButton}
                        onClick={() => loadData()}
                    >
                        <img src="/assets/plus-white.svg" alt="" className={styles.addIcon} />
                        {t('small.addCustomer')}
                    </Button>
                </div>
                <TextField
                    label={t('small.nameEmailPhone')}
                    value={search}
                    onChange={async ({ target: { value } }) => {
                        setSearch(value);
                        onSearch(value);
                    }}
                    InputProps={{ startAdornment: <SearchAdornment /> }}
                    fullWidth
                    className={styles.search}
                />
                <div>
                    {customers.map((x) => (
                        <Button
                            key={`${x.id}${x.hrefCarer}`}
                            onClick={() => {
                                if (selectedCustomer === x.id) {
                                    setSelectedCustomer(null);
                                } else {
                                    loadData(x);
                                }
                            }}
                            className={classNames(styles.customerButton, {
                                [styles.buttonSelected]: selectedCustomer === x.id,
                            })}
                            variant="text"
                            color="secondary"
                            fullWidth
                        >
                            {x.lastName}
                            {', '}
                            {x.firstName}
                            <div className={styles.optionSmall}>
                                {x.hrefCarer
                                    ? `${t('small.bookedBy')} ${x.carerFirstName} ${x.carerLastName}`
                                    : formatPhone(x)}
                            </div>
                        </Button>
                    ))}
                </div>
            </div>
            <div className={styles.right}>
                {selectedCustomer !== null && (
                    <>
                        <div className={styles.columns}>
                            <div className={styles.customerColumn}>
                                <div className={styles.header}>
                                    {t('small.customerData')}
                                </div>
                                <TextField
                                    label={t('small.customerName')}
                                    value={name}
                                    onChange={({ target }) => (
                                        setName(target.value)
                                    )}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    label={t('small.surname')}
                                    value={surname}
                                    onChange={({ target }) => (
                                        setSurname(target.value)
                                    )}
                                    fullWidth
                                    required
                                />
                                <DatePicker
                                    value={birthdate}
                                    onChange={setBirthdate}
                                    label={t('small.birthdate')}
                                    renderInput={(props2) => (
                                        <TextField {...props2} fullWidth helperText="" inputProps={{ ...props2.inputProps, placeholder: language === 'pl' ? 'dd.mm.rrrr' : 'mm/dd/yyyy' }} />
                                    )}
                                />
                                <PhoneInput
                                    country={country}
                                    setCountry={setCountry}
                                    phone={phone}
                                    setPhone={setPhone}
                                    phoneProps={{ required: true }}
                                />
                                <TextField
                                    label={t('small.email')}
                                    value={email}
                                    onChange={({ target }) => (
                                        setEmail(target.value)
                                    )}
                                    fullWidth
                                />
                                <div className={styles.requiredInfo}>
                                    {`* ${t('small.requiredField')}`}
                                </div>
                            </div>
                            <div className={styles.careColumn}>
                                <div className={styles.careHeader}>
                                    {t('small.dependentPeople')}
                                    <Button
                                        variant="text"
                                        className={styles.addCareButton}
                                        onClick={() => loadCare()}
                                    >
                                        <img src="/assets/plus-icon.svg" alt="" className={styles.addIcon} />
                                        {t('small.addPerson')}
                                    </Button>
                                </div>
                                <div className={styles.careList}>
                                    {care.map((extra) => (
                                        <Button
                                            key={extra.id}
                                            variant="contained"
                                            className={styles.careButton}
                                            onClick={() => loadCare(extra)}
                                        >
                                            {`${extra.firstName} ${extra.lastName}`}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={styles.saveRow}>
                            {selectedCustomer !== 'new' && (
                                <Button
                                    variant="text"
                                    onClick={async () => {
                                        setDeleteOpen(true);
                                        setAffectedAppointments(await BackendMethods.getChangePreview({
                                            type: 'CUSTOMER_DELETE',
                                            details: {
                                                customer: selectedCustomer,
                                            },
                                        }));
                                    }}
                                >
                                    {t('small.deleteCustomer')}
                                </Button>
                            )}
                            <Button
                                variant="text"
                                onClick={() => setSelectedCustomer(null)}
                                className={styles.buttonLeftMargin}
                            >
                                {t('small.discardChanges')}
                            </Button>
                            <Button
                                variant="contained"
                                className={styles.buttonLeftMargin}
                                disabled={!name || !surname || !phone}
                                onClick={async () => {
                                    const newCustomer: INewCustomer = {
                                        firstName: name,
                                        lastName: surname,
                                        birthDate: birthdate ? birthdate.format('YYYY-MM-DD') : null,
                                        ...getPhoneObj(country, phone),
                                        email: email || null,
                                    };
                                    if (selectedCustomer === 'new') {
                                        const { id } = await BackendMethods.addCustomer(newCustomer);
                                        await Promise.all(care.map((x) => (
                                            BackendMethods.addCare(id, x)
                                        )));
                                    } else {
                                        await BackendMethods.updateCustomer({
                                            ...newCustomer,
                                            id: selectedCustomer,
                                        });
                                    }
                                    setSelectedCustomer(null);
                                    await onSearch(search);
                                }}
                            >
                                {t('small.save')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <Dialog
                open={selectedExtra !== null}
                onClose={() => setSelectedExtra(null)}
                classes={{ paper: styles.dialog }}
            >
                <TextField
                    label={t('small.customerName')}
                    value={extraName}
                    onChange={({ target }) => (
                        setExtraName(target.value)
                    )}
                    fullWidth
                    required
                />
                <TextField
                    label={t('small.surname')}
                    value={extraSurname}
                    onChange={({ target }) => (
                        setExtraSurname(target.value)
                    )}
                    fullWidth
                    required
                />
                <DatePicker
                    value={extraBirthdate}
                    onChange={setExtraBirthdate}
                    label={t('small.birthdate')}
                    renderInput={(props2) => (
                        <TextField {...props2} fullWidth helperText="" inputProps={{ ...props2.inputProps, placeholder: language === 'pl' ? 'dd.mm.rrrr' : 'mm/dd/yyyy' }} />
                    )}
                />
                <div className={styles.phoneInfo}>
                    {t('small.remindersPhone', {
                        phone: formatPhone({ countryCode: `+${country.dialCode}`, phone }),
                    })}
                </div>
                <Button
                    variant="text"
                    className={styles.phoneAddButton}
                    onClick={() => setPhoneVisible(true)}
                    disabled={phoneVisible}
                >
                    <img src="/assets/plus-icon.svg" alt="" className={styles.addIcon} />
                    {t('small.phoneNumber')}
                </Button>
                {phoneVisible && (
                    <PhoneInput
                        country={extraCountry}
                        setCountry={setExtraCountry}
                        phone={extraPhone}
                        setPhone={setExtraPhone}
                    />
                )}
                <TextField
                    label={t('small.email')}
                    value={extraEmail}
                    onChange={({ target }) => (
                        setExtraEmail(target.value)
                    )}
                    fullWidth
                />
                {selectedExtra !== 'new' && (
                    <div className={styles.saveRow}>
                        <Button
                            variant="text"
                            className={styles.saveIndependent}
                            disabled={!extraName || !extraSurname || !extraPhone || selectedCustomer === 'new'}
                            onClick={() => setSaveIndependentOpen(true)}
                        >
                            {t('small.saveAsIndependent')}
                        </Button>
                        {!extraPhone && (
                            <Tooltip
                                title={t('small.independentPhone')}
                                classes={{ tooltip: styles.tooltip }}
                            >
                                <span className={styles.info}>i</span>
                            </Tooltip>
                        )}
                    </div>
                )}
                <div className={styles.saveRow}>
                    {selectedExtra !== 'new' && (
                        <Button
                            variant="text"
                            onClick={async () => {
                                setDeleteExtraOpen(true);
                                if (selectedCustomer !== 'new') {
                                    setAffectedAppointments(await BackendMethods.getChangePreview({
                                        type: 'CUSTOMER_DELETE',
                                        details: {
                                            care: selectedExtra,
                                        },
                                    }));
                                }
                            }}
                        >
                            {t('small.deletePerson')}
                        </Button>
                    )}
                    <Button
                        variant="text"
                        onClick={() => setSelectedExtra(null)}
                        className={styles.buttonLeftMargin}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        className={styles.buttonLeftMargin}
                        disabled={!extraName || !extraSurname}
                        onClick={async () => {
                            const newExtra: INewCustomer = {
                                firstName: extraName,
                                lastName: extraSurname,
                                birthDate: extraBirthdate ? extraBirthdate.format('YYYY-MM-DD') : null,
                                ...getPhoneObj(extraCountry, extraPhone),
                                email: extraEmail || null,
                            };
                            if (selectedCustomer === 'new') {
                                const id = Math.round(Math.random() * 1e10);
                                setCare([
                                    ...care,
                                    { id, ...newExtra },
                                ]);
                                setSelectedExtra(null);
                                return;
                            }
                            if (selectedExtra === 'new') {
                                const { id } = await BackendMethods.addCare(selectedCustomer, newExtra);
                                setCare([
                                    ...care,
                                    { id, ...newExtra },
                                ]);
                            } else {
                                const updatedCare: ICustomer = {
                                    id: selectedExtra,
                                    ...newExtra,
                                };
                                await BackendMethods.updateCare(selectedCustomer, updatedCare);
                                setCare(care.map((x) => (x.id === selectedExtra ? updatedCare : x)));
                            }
                            setSelectedExtra(null);
                        }}
                    >
                        {selectedExtra === 'new' ? t('small.add') : t('small.save')}
                    </Button>
                </div>
            </Dialog>
            <Dialog
                open={deleteExtraOpen}
                onClose={() => setDeleteExtraOpen(false)}
                classes={{ paper: styles.dialog }}
            >
                <div className={styles.dialogText}>
                    {t('small.deleteCareConfirm')}
                    <AffectedAppointments appointments={affectedAppointments} isDelete className={styles.affectedIcon} />
                </div>
                <div className={styles.saveRow}>
                    <Button
                        onClick={async () => {
                            if (selectedExtra === 'new') {
                                return;
                            }
                            if (selectedCustomer !== 'new') {
                                await BackendMethods.deleteCare(selectedCustomer, selectedExtra);
                                await BackendMethods.flagAffectedAppointments({
                                    type: 'CUSTOMER_DELETE',
                                    details: {
                                        care: selectedExtra,
                                    },
                                });
                            }
                            setCare(care.filter((x) => x.id !== selectedExtra));
                            setDeleteExtraOpen(false);
                            setSelectedExtra(null);
                            await onSearch(search);
                        }}
                    >
                        {t('small.delete')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setDeleteExtraOpen(false)}
                        className={styles.buttonLeftMargin}
                    >
                        {t('common.cancel')}
                    </Button>
                </div>
            </Dialog>
            <Dialog
                open={saveIndependentOpen}
                onClose={() => setSaveIndependentOpen(false)}
                classes={{ paper: styles.dialog }}
            >
                <div className={styles.dialogText}>
                    {t('small.saveAsIndependentConfirm')}
                </div>
                <div className={styles.saveRow}>
                    <Button
                        onClick={() => setSaveIndependentOpen(false)}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        className={styles.buttonLeftMargin}
                        onClick={async () => {
                            if (selectedExtra === 'new' || selectedCustomer === 'new') {
                                return;
                            }
                            await BackendMethods.deleteCare(selectedCustomer, selectedExtra);
                            const newCustomer = care.find((x) => x.id === selectedExtra);
                            const { id } = await BackendMethods.addCustomer(newCustomer);
                            setCare(care.filter((x) => x.id !== selectedExtra));
                            await loadData({
                                ...newCustomer,
                                id,
                                href: `/customer/${id}`,
                            });
                            setSelectedExtra(null);
                            setSaveIndependentOpen(false);
                            await onSearch(search);
                        }}
                    >
                        {t('small.save')}
                    </Button>
                </div>
            </Dialog>
            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                classes={{ paper: styles.dialog }}
            >
                <div className={styles.dialogText}>
                    {care.length > 0 ? t('small.deleteCustomerConfirm') : t('small.deleteCustomerConfirmNoCare')}
                    <AffectedAppointments appointments={affectedAppointments} isDelete className={styles.affectedIcon} />
                </div>
                <div className={styles.saveRow}>
                    <Button
                        onClick={async () => {
                            if (selectedCustomer !== 'new') {
                                await BackendMethods.deleteCustomer(selectedCustomer);
                                await BackendMethods.flagAffectedAppointments({
                                    type: 'CUSTOMER_DELETE',
                                    details: {
                                        customer: selectedCustomer,
                                    },
                                });
                            }
                            setDeleteOpen(false);
                            setSelectedCustomer(null);
                            await onSearch(search);
                        }}
                    >
                        {t('small.delete')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setDeleteOpen(false)}
                        className={styles.buttonLeftMargin}
                    >
                        {t('common.cancel')}
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}
