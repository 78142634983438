import * as React from 'react';
import LeftAnimation from './LeftAnimation/LeftAnimation';
import RightAnimation from './RightAnimation/RightAnimation';

const Styles = require('./Animations.css');

export default class Animations extends React.Component<{}, null> {
  render(): JSX.Element {
    return (
      <div className={Styles.AnimationsContainer}>
        <LeftAnimation />
        <RightAnimation />
      </div>
    );
  }
}
