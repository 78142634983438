// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".paper__kvuqS {\n    padding: 24px;\n    margin: 16px;\n    width: 292px;\n}\n\n.main__2LqSC {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 0 auto;\n    text-align: center;\n}\n\n.icon__1RVsz {\n    width: 56px;\n    height: 56px;\n    border-radius: 25%;\n    margin-bottom: 10px;\n}\n\n.buttons__31FF8 {\n    margin: 16px -8px 0;\n    display: flex;\n}\n\n.button__3oV3T {\n    margin: 0 8px;\n    width: 50%;\n    padding: 2px 15px;\n}\n\n.row__2FFO2 {\n    display: flex;\n    align-items: flex-start;\n    margin-bottom: 6px;\n    margin-top: 20px;\n}\n\n.shareIcon__3og3V {\n    margin-top: -4px;\n    margin-right: 16px;\n}\n\n.addIcon__1hPAt {\n    margin-right: 16px;\n}\n", "",{"version":3,"sources":["InstallModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","file":"InstallModal.css","sourcesContent":[".paper {\n    padding: 24px;\n    margin: 16px;\n    width: 292px;\n}\n\n.main {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 0 auto;\n    text-align: center;\n}\n\n.icon {\n    width: 56px;\n    height: 56px;\n    border-radius: 25%;\n    margin-bottom: 10px;\n}\n\n.buttons {\n    margin: 16px -8px 0;\n    display: flex;\n}\n\n.button {\n    margin: 0 8px;\n    width: 50%;\n    padding: 2px 15px;\n}\n\n.row {\n    display: flex;\n    align-items: flex-start;\n    margin-bottom: 6px;\n    margin-top: 20px;\n}\n\n.shareIcon {\n    margin-top: -4px;\n    margin-right: 16px;\n}\n\n.addIcon {\n    margin-right: 16px;\n}\n"]}]);
// Exports
exports.locals = {
	"paper": "paper__kvuqS",
	"main": "main__2LqSC",
	"icon": "icon__1RVsz",
	"buttons": "buttons__31FF8",
	"button": "button__3oV3T",
	"row": "row__2FFO2",
	"shareIcon": "shareIcon__3og3V",
	"addIcon": "addIcon__1hPAt"
};
module.exports = exports;
