import React from 'react';
import classNames from 'classnames';
import { Rating } from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { IReviews } from 'interfaces/ICompaniesRating';
import BackendMethods from 'api/BackendMethods';
import { Pagination } from 'elementsSelectors/Reviews';
import styles from './Review.css';
import { useTranslation } from 'react-i18next';

interface IProps {
    review: IReviews;
    companyName: string;
    isMyCompany: boolean;
    setNowReview: React.Dispatch<React.SetStateAction<IReviews>>;
    setNowPage: React.Dispatch<React.SetStateAction<number>>;
    getRating: any;
}

export const Review = ({ review, companyName, isMyCompany, setNowReview, setNowPage, getRating }: IProps) => {
    const isMyReview = review.self;
    const isReport = (review.response && isMyReview) || (review && isMyCompany);
    const { t, i18n } = useTranslation();
    const deleteReview = async () => {
        try {
            await BackendMethods.deleteReview(review.id);
            getRating();
        } catch (error) {
        }
    };
    const deleteAnswer = async () => {
        try {
            await BackendMethods.deleteAnswer(review.id);
            getRating();
        } catch (error) {
        }
    };
    const gotoReport = () => {
            setNowReview(review);
            setNowPage(Pagination.REPORT_EDIT);
    };
    return (
        <div className={classNames('p-18', 'p-b-0', 'light-white-background', styles.b_border)}>
            <div>
                <div className={classNames('inline-between', 'item-center')}>
                    <h5 className={classNames('m-0', 'f-14', 'bold')}>{review.author}</h5>
                    <label className={'item-center'}>
                        <h6 className={classNames('m-0', 'f-10')}>
                            {t('small.bookedByTanbuu')}
                        </h6>
                        <CheckBox
                            className={classNames('w-15', 'h-15', 'm-l-6', 'yellow')}
                        />
                    </label>
                </div>
                <div className={classNames(styles.rating, 'inline-block')}>
                    <Rating
                        name="rating"
                        value={review.rating}
                        readOnly
                        icon={<StarIcon className={classNames('w-12', 'h-12')}></StarIcon>}
                        emptyIcon={
                            <StarBorderIcon fontSize="inherit" className={classNames('w-12', 'h-12', 'light-yellow')} />
                        }
                    />
                    <h6 className={classNames('m-0', 'm-l-6', 'f-10')}>
                        {moment(review.submitted).locale(i18n.language).format('DD MMMM Y')}
                    </h6>
                </div>
                <div className={classNames('m-0', 'f-14', 'item-end')}>
                    {`${review.review}`}
                    <h6 className={classNames('light-grey', 'f-10', 'm-0', 'm-l-4')}>
                        {isMyCompany && review.edited ? `(${t('small.edited')})` : null}
                    </h6>
                </div>
            </div>
            <div className={classNames('p-t-4', 'p-b-10', 'text-right')}>
                <h6
                    className={classNames('m-0', 'f-10', 'bold', isReport ? styles.isReportComponent : styles.isNonReportComponent)}
                    onClick={isReport ? () => gotoReport() : null}
                >
                    {t('small.report')}
                </h6>
            </div>
            {review.response && (
                <div className={classNames('p-l-20', 'm-t-12')}>
                    <div className={'inline-block'}>
                        <h6 className={classNames('m-0', 'f-10', 'bold')}>{ t('small.reply') }</h6>
                        <h6 className={classNames('m-0', 'm-l-6', 'f-10')}>{moment(review.response.submitted).locale(i18n.language).format('DD MMMM Y')}</h6>
                    </div>
                    <h5 className={classNames('m-0', 'f-14', 'bold', 'm-b-8')}>{companyName}</h5>
                    <h5 className={classNames('m-0', 'f-14')}>
                        {review.response.content}
                    </h5>
                </div>
            )}
            <div className={classNames('inline-block', 'p-b-16', 'm-t-12')}>
                {isMyReview && (
                    <>
                        <Button
                            className={classNames('no-border', 'bold', 'light-blue', 'p-0', 'no-width')}
                            onClick={() => {
                                setNowReview(review);
                                setNowPage(Pagination.REVIEW_EDIT);
                            }}
                        >
                            {t('small.edit')}
                        </Button>
                        <Button
                            className={classNames('no-border', 'bold', 'light-blue', 'p-0', 'm-l-75', 'no-width')}
                            onClick={deleteReview}
                        >
                            {t('small.delete')}
                        </Button>
                    </>
                )}
                {review && isMyCompany ? (
                    <>
                        {review.response ? (
                            <>
                                <Button
                                    className={classNames('no-border', 'bold', 'light-blue', 'p-0', 'no-width')}
                                    onClick={() => {
                                        setNowReview(review);
                                        setNowPage(Pagination.RESPONSIVE_EDIT);
                                    }}
                                >
                                    {t('small.edit')}
                                </Button>
                                <Button
                                    className={classNames('no-border', 'bold', 'light-blue', 'p-0', 'm-l-75', 'no-width')}
                                    onClick={deleteAnswer}
                                >
                                    {t('small.delete')}
                                </Button>
                            </>
                        ) : (
                            <Button
                                className={classNames('no-border', 'bold', 'light-blue', 'p-0', 'no-width')}
                                onClick={() => {
                                    setNowReview(review);
                                    setNowPage(Pagination.RESPONSIVE_EDIT);
                                }}
                            >
                                {t('small.reply')}
                            </Button>
                        )}
                    </>
                ): null}
            </div>
        </div>
    );
};
