// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".BillingSummary__70S-i {\n    margin-top: 38px;\n}\n\n.Total__3vkKX {\n    display: flex;\n    justify-content: space-between;\n}\n\n.Total__Price__2Hl9R {\n    text-align: right;\n    width: 100%;\n}\n", "",{"version":3,"sources":["BillingSummary.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf","file":"BillingSummary.css","sourcesContent":[".BillingSummary {\n    margin-top: 38px;\n}\n\n.Total {\n    display: flex;\n    justify-content: space-between;\n}\n\n.Total__Price {\n    text-align: right;\n    width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"BillingSummary": "BillingSummary__70S-i",
	"Total": "Total__3vkKX",
	"Total__Price": "Total__Price__2Hl9R"
};
module.exports = exports;
