import React from 'react';
import MainFeatures from './MainFeatures/MainFeatures';
import { OtherFeatures } from './OtherFeatures/OtherFeatures';
import { Diagram } from './Diagram/Diagram';
import Styles from './Feature.css';

export const Features = () => {
    return (
        <div className={Styles.content}>
            <MainFeatures />
            <OtherFeatures />
            {/* <Diagram /> */}
        </div>
    );
};
