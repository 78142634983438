import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TextField from 'components/_ReusableComponents/TextField/TextField';
import Styles from './ReviewContent.css';
import { useTranslation } from 'react-i18next';

interface ReviewProps {
    disabled: boolean;
    ratingValue: number;
    setRatingValue: React.Dispatch<React.SetStateAction<number>>;
    opinion: string;
    setOpinion: React.Dispatch<React.SetStateAction<string>>;
    nickName: string;
    setNickName: React.Dispatch<React.SetStateAction<string>>;
    companyName?: string;
}
export const ReviewContent: React.FC<ReviewProps> = ({ disabled, ratingValue, setRatingValue, opinion, setOpinion, nickName, setNickName, companyName }) => {
    const { t } = useTranslation();

    return (
        <>
            <Box component="fieldset" p={0} m={0} borderColor="transparent" disabled={disabled}>
                <Typography component="legend" className={disabled ? Styles.disabledHeader : null}>{t('small.yourRatingOf')} { companyName }</Typography>
                <Rating
                    name="simple-controlled"
                    value={ratingValue}
                    onChange={(event, newValue) => {
                        setRatingValue(newValue);
                    }}
                    size="large"
                    disabled={disabled}
                    emptyIcon={
                        <StarBorderIcon fontSize="inherit" className={disabled ? null : Styles.opinionStar} />
                    }
                />
            </Box>
            <TextField
                className={Styles.opinionInput}
                value={opinion}
                onChange={({ target }) => {
                    setOpinion(target.value);
                }}
                multiline
                rows={4}
                label={t('small.yourReview')}
                fullWidth
                disabled={disabled}
            />
            <TextField
                value={nickName}
                onChange={({ target }) => {
                    setNickName(target.value);
                }}
                label={t('small.yourNameOrInitials')}
                fullWidth
                disabled={disabled}
            />
        </>
    );
};
