import * as React from 'react';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import IActions from 'actions/IActions';
import AdminPanel from '../../components/PlusPlanAdmin/AdminPanel/AdminPanel';
import PlusPlanHeader from '../../components/_ReusableComponents/Header/PlusPlanHeader/PlusPlanHeader';
import ILocation from '../../interfaces/location/ILocation';
import Header from '../../components/_ReusableComponents/Header/Header/Header';
import AdminLinks from '../../components/_ReusableComponents/AdminLinks/AdminLinks';
import { WithTranslation, withTranslation } from 'react-i18next';

const connect = require('react-redux').connect;

interface IPlusPlanAdminPageProps extends WithTranslation {
    plusPlanAdmin: any;
    plusPlanPayment: any;
    data: any;
    actions: IActions;
    location: ILocation;
    history: any;
}

class PlusPlanAdminPage extends React.Component<IPlusPlanAdminPageProps, null> {
    render() {
        const { t } = this.props;
        const activePanel = this.props.plusPlanAdmin.get('activePanel');
        let headerText;
        if (activePanel === 'placeOrder') {
            headerText = t('plan.placeOrder');
        } else if (activePanel === 'updatePaymentInfo') {
            headerText = t('plan.paymentInformation');
        } else {
            headerText = t('plan.plusPlanSubscription');
        }
        return (
            <div>
                <Header {...this.props} />
                <AdminLinks active="plan" />
                <PlusPlanHeader text={headerText} />
                <AdminPanel {...this.props} />
            </div>
        );
    }
}

export default withTranslation()(connect(
    containersMapStateToProps,
    containersDispatchToProps
)(PlusPlanAdminPage));
