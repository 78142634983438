import * as React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import classNames from 'classnames';
const styles = require('./Button.css');

export default function Button(
    props: ButtonProps & {
        component?: React.ComponentType<any> | string;
        to?: string;
    }
) {
    const { variant = 'outlined', color = 'primary', className, ...rest } = props;
    return (
        <MuiButton
            variant={variant}
            color={color}
            className={classNames(className, styles.root, {
                [styles.outlined]: variant === 'outlined',
                [styles.contained]: variant === 'contained',
                [styles.outlinedPrimary]: variant === 'outlined' && color === 'primary',
                [styles.text]: variant === 'text',
            })}
            {...rest}
        />
    );
}
