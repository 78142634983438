import moment from 'moment-timezone';
import io from 'socket.io-client';
import * as R from 'ramda';
import api, { transformMeeting } from '../api/BackendMethods';
import IMeeting from 'interfaces/IMeeting';
import BackendMethods from '../api/BackendMethods';
import { getAuthToken } from '../scripts/helpers';
import { getTimezone } from 'countries-and-timezones';
import { i18n } from 'i18next';
import IProfile from '../interfaces/IProfile';
import { IGlobalContextData } from '../GlobalContext';
const config = require('CONFIG');

export const UPDATE_DATA = 'UPDATE_DATA';
export const UPDATE_MEETING = 'UPDATE_MEETING';
export const UPDATE_MEETINGS = 'UPDATE_MEETINGS';
export const PREDICTIONS_PREVIEW = 'PREDICTIONS_PREVIEW';
export const REMOVE_MEETING = 'REMOVE_MEETING';
export const REMOVE_PREDICTIONS_PREVIEW = 'REMOVE_PREDICTIONS_PREVIEW';

export async function fetchProfile(i18nInstance: i18n): Promise<IProfile> {
    const user = await BackendMethods.getUserProfile();
    let newUser = R.clone(user);
    if (!user.timezone) {
        const timezone = moment.tz.guess();
        const country = getTimezone(timezone).country.toLowerCase();
        newUser = {
            ...newUser,
            timezone,
            country,
        };
    }
    if (!user.timeFormat) {
        const timeFormat = new Intl.DateTimeFormat(
            navigator.language,
            { hour: 'numeric' },
        ).resolvedOptions().hour12 ? '12h' : '24h';
        newUser = {
            ...newUser,
            timeFormat,
        };
    }
    if (!user.language) {
        newUser = {
            ...newUser,
            language: i18nInstance.language,
        };
    } else {
        i18nInstance.changeLanguage(user.language);
    }
    if (!R.equals(newUser, user)) {
        BackendMethods.updateUserProfile(newUser);
    }
    return newUser;
}

export default class DataActions {
    privateDataFetched: boolean;
    publicDataFetched: boolean;
    constructor() {
        this.privateDataFetched = false;
        this.publicDataFetched = false;
    }
    updateData(payload: any): any {
        return {
            type: UPDATE_DATA,
            payload
        };
    }
    setWorkWeek(payload: any): any {
        return {
            type: 'SET_PREFERENCES_CALENDARS',
            payload
        };
    }
    updateMeeting(payload: IMeeting, email: string): any {
        return {
            type: UPDATE_MEETING,
            payload: transformMeeting(email)(payload),
        };
    }
    updateMeetings(payload: any): any {
        return {
            type: UPDATE_MEETINGS,
            payload
        };
    }
    removeMeeting({ id }): any {
        return {
            type: REMOVE_MEETING,
            payload: id
        };
    }
    refreshMeetings(email: string) {
        return () =>
            api.getMeetings(email).then((items) => {
                this.updateMeetings(items);
            });
    }
    predictionsPreview(payload: any): any {
        return {
            type: PREDICTIONS_PREVIEW,
            payload
        };
    }
    removePredictionsPreview(): any {
        return {
            type: REMOVE_PREDICTIONS_PREVIEW,
            payload: {}
        };
    }
    getSubscription() {
        return async dispatch => {
            const subscription = await api.getSubscription();
            dispatch({
                type: UPDATE_DATA,
                payload: { subscription }
            });
        };
    }
    dbConnect(profilePromise: Promise<IProfile>): any {
        if (!this.privateDataFetched) {
            this.privateDataFetched = true;
            return () => {
                const connectionsPromise = api.getConnections();
                connectionsPromise.then(async (connections) => {
                    this.updateData({ connections });
                });
                const subscriptionPromise = this.getSubscription();
                const meetingsPromise = profilePromise.then((profile) => {
                    return this.refreshMeetings(profile.email);
                });
                return Promise.all([
                    profilePromise,
                    BackendMethods.getWorkWeek(),
                    connectionsPromise,
                    subscriptionPromise,
                    meetingsPromise,
                ]).then(([profile, workWeek]) => {
                    this.updateData({ workWeek });
                    return this.setWorkWeek({ profile, workWeek });
                });
            };
        }
    }

    fetchPublicData(): any {
        if (!this.publicDataFetched) {
            this.publicDataFetched = true;
            return dispatch => {
                fetch('/assets/HostConfig.json')
                    .then(r => r.json())
                    .then(r => {
                        const hostName = window.location.host.split('.')[0];
                        const host = r[hostName];
                        const isProd = ![
                            'localhost:8080',
                            'dev',
                            'stab',
                            'tanbuu-frontend-test',
                        ].includes(hostName);
                        const logoSrc = isProd
                            ? '/assets/' + hostName + '_logo.png'
                            : '/assets/tanbuu_logo.png';
                        dispatch({
                            type: UPDATE_DATA,
                            payload: { logoSrc, host }
                        });
                    });
            };
        }
    }

    pushConnect(email: string, updateContext: (updateFn: (context: IGlobalContextData) => IGlobalContextData) => void) {
        return () => {
            const socket = io(`${process.env.PUSH_URL || config.pushURL}?authorization=${getAuthToken()}`);
            socket.on('MEETING_UPDATE', (data) => this.updateMeeting(data, email));
            socket.on('MEETING_NEW', (data) => this.updateMeeting(data, email));
            socket.on('MEETING_DELETED', this.removeMeeting);
            socket.on('PREDICTIONS_PREVIEW', (data) => this.predictionsPreview(data));
            socket.on('UNSEEN_MEETINGS', (unseenMeetings) => {
                this.updateData({ unseenMeetings });
            });
            socket.on('PLUS_PLAN_CHANGED', ({ event }) => {
                if (event === 'LICENSE_ASSIGNED' || event === 'LICENSE_REVOKED') {
                    updateContext((x) => ({
                        ...x,
                        profile: R.set(
                            R.lensPath(['plusPlan', 'active']),
                            event === 'LICENSE_ASSIGNED',
                            x.profile,
                        ),
                    }));
                }
            });
        };
    }
}
