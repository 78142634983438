// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".menuIcon__1g5ml {\n    width: 24px;\n    height: 24px;\n}\n\n.menuButton__p45EW {\n    padding: 0;\n    padding-bottom: 6px;\n}\n\n.menuItem__2K258 {\n    background-color: white !important;\n    padding: 8px 12px;\n    line-height: 20px;\n    min-height: 0 !important;\n}\n\n.menuItem__2K258:hover {\n    background-color: #eff3f4 !important;\n}\n", "",{"version":3,"sources":["FilterMenu.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,kCAAkC;IAClC,iBAAiB;IACjB,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,oCAAoC;AACxC","file":"FilterMenu.css","sourcesContent":[".menuIcon {\n    width: 24px;\n    height: 24px;\n}\n\n.menuButton {\n    padding: 0;\n    padding-bottom: 6px;\n}\n\n.menuItem {\n    background-color: white !important;\n    padding: 8px 12px;\n    line-height: 20px;\n    min-height: 0 !important;\n}\n\n.menuItem:hover {\n    background-color: #eff3f4 !important;\n}\n"]}]);
// Exports
exports.locals = {
	"menuIcon": "menuIcon__1g5ml",
	"menuButton": "menuButton__p45EW",
	"menuItem": "menuItem__2K258"
};
module.exports = exports;
