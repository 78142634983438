import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILocation } from './Settings/Sites';
import { IService } from './Settings/Services';
import moment, { Moment } from 'moment';
import * as R from 'ramda';
import Autocomplete from '../../components/_ReusableComponents/Autocomplete/Autocomplete';
import TextField from '../../components/_ReusableComponents/TextField/TextField';
import classNames from 'classnames';
import { Dialog, FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import { PickersDay, StaticDatePicker } from '@material-ui/lab';
import Button from 'components/_ReusableComponents/Button/Button';
import WeekCalendar from '../../components/MainPageComponents/WeekCalendar/WeekCalendar';
import MobileDateTimePicker from '@material-ui/lab/MobileDateTimePicker';
import BackendMethods from '../../api/BackendMethods';
import { Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { renderPrice } from './common';
import BookingCustomer from './AssistantBooking/BookingCustomer';
import IProfile from '../../interfaces/IProfile';
import PhoneNumber from 'awesome-phonenumber';
import { DatePickerView } from '@material-ui/lab/DatePicker/shared';
import { getSubdomain, redirectToBaseDomain, redirectToBaseIfNeeded } from '../../utils/subdomain';
import FallingLeaves from '../../components/_ReusableComponents/FallingLeaves/FallingLeaves';
import { cancelRequest } from '../../api/backendRequest';
import AddEmailDialog from '../../components/_ReusableComponents/AddEmailDialog';
import { IAppointment } from './Dashboard/Dashboard';
import { GDPRConsentModal } from 'components/_ReusableComponents/Modals/GDPRConsentModal/GDPRConsentModal';
const styles = require('./DesktopBooking.css');

interface IProps {
    profile: IProfile;
    isCustomer?: boolean;
}

export interface INewCustomer {
    firstName: string;
    lastName: string;
    birthDate?: string;
    countryCode?: string;
    phone?: string;
    email?: string;
}

export interface ICustomer extends INewCustomer {
    id: number;
}

export interface IEmployeeDates {
    id: number;
    dates: string[];
}

export interface ILocationDates {
    location: number;
    employees: IEmployeeDates[];
}

export interface ISlot {
    date: Moment;
    employee: number;
    location: number;
}

export interface IBookedSlot extends ISlot {
    service: number;
}

export interface INewAppointment {
    employee: number;
    location: number;
    service: number;
    date: string;
    notes: string;
    customer: { id: number } | INewCustomer;
    bookingFor?: { id: number } | INewCustomer;
    customDate?: boolean;
}

export interface INewCustomerAppointment extends INewAppointment {
    company: number;
}

export interface IBookingStaff {
    id: number;
    name: string;
    picture: string | null;
    services: number[];
    locations: number[];
}

export default function DesktopBooking({ profile, isCustomer }: IProps) {
    const userTimezone = profile?.timezone || moment.tz.guess();
    const timeFormat = profile?.timeFormat || '24h';
    const { t, i18n } = useTranslation();
    const params = useParams<{ id: string, company: string, location: string }>();
    const history = useHistory();
    const companyId = params.company ? Number(params.company) : profile.appointments.companyId;
    const assistantRebook = useRouteMatch('/sb/assistant-booking/rebook/:id');
    const customerRebook = useRouteMatch('/sb-customer/book/:company/rebook/:id');
    const assistantEdit = useRouteMatch('/sb/assistant-booking/edit/:id');
    const customerEdit = useRouteMatch('/sb-customer/book/:company/edit/:id');
    const isRebook = Boolean(assistantRebook || customerRebook);
    const isEdit = Boolean(assistantEdit || customerEdit);
    const [sites, setSites] = useState<ILocation[] | null>(null);
    const [services, setServices] = useState<IService[] | null>(null);
    const [people, setPeople] = useState<IBookingStaff[] | null>(null);
    const [companyName, setCompanyName] = useState<string | null>(null);
    const [birthDateRequired, setBirthDateRequired] = useState<boolean>(false);
    const [showGDPRConsentModal, setShowGDPRConsentModal] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            const companyData = await BackendMethods.getBookingCompany(companyId);
            setSites(companyData.locations);
            setServices(companyData.services);
            setPeople(companyData.staff);
            setCompanyName(companyData.name);
            setBirthDateRequired(companyData.birthDateRequired);
        })();
    }, [companyId]);
    const [site, setSite] = useState<ILocation | 'ANY'>('ANY');
    useEffect(() => {
        if (sites && params.location) {
            setSite(sites.find(R.propEq('id', Number(params.location))));
        }
    }, [sites, params.location]);
    const [search, setSearch] = useState('');
    const [selectedPerson, setSelectedPerson] = useState<IBookingStaff | 'ANY'>('ANY');
    const [selectedService, setSelectedService] = useState<IService | null>(null);
    const [bookedService, setBookedService] = useState<IService | null>(null);
    const prevService = useRef(null);
    const [locationDates, setLocationDates] = useState<ILocationDates[]>([]);
    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const [startOfWeek, setStartOfWeek] = useState(moment().tz(userTimezone).startOf('week'));
    useEffect(() => {
        if (selectedDate) {
            setStartOfWeek(moment(selectedDate).startOf('week'));
        }
    }, [selectedDate]);
    useEffect(() => {
        if (site !== 'ANY') {
            moment.tz.setDefault(site.timezone);
        }
        return () => {
            moment.tz.setDefault();
        };
    }, [site]);
    const prevWeek = useRef(null);
    const [selectedSlot, setSelectedSlot] = useState<ISlot | null>(null);
    const [bookedSlot, setBookedSlot] = useState<IBookedSlot | null>(null);
    const getSlots = useCallback((currentLocationDates: ILocationDates[]) => (
        R.sortBy<ISlot>((x) => x.date.valueOf(), R.uniqBy(
            (x) => x.date.valueOf(),
            R.concat(
                R.chain(
                    (x) => x.dates.map((y) => ({
                        date: moment(y),
                        employee: x.id,
                        location: x.location,
                    })),
                    R.chain((x) => x.employees.map((y) => ({
                        ...y,
                        location: x.location,
                    })), currentLocationDates.filter((x) => (
                        site === 'ANY' || x.location === site.id
                    ))).filter((x) => (
                        selectedPerson === 'ANY' || x.id === selectedPerson.id
                    )),
                ),
                ((bookedSlot
                    && (site === 'ANY' || bookedSlot.location === site.id)
                    && (selectedPerson === 'ANY' || bookedSlot.employee === selectedPerson.id)
                    && (bookedSlot.service === selectedService.id)) ? [bookedSlot] : []),
            ),
        ))
    ), [site, selectedPerson, selectedService, bookedSlot]);
    const slots = useMemo(() => (
        selectedService ? getSlots(locationDates) : []
    ), [selectedService, locationDates, getSlots]);
    const promiseRef = useRef(null);
    useEffect(() => {
        (async () => {
            let currentWeek = startOfWeek;
            const prevRangeStart = moment(prevWeek.current).startOf('month');
            const prevRangeEnd = moment(prevWeek.current).endOf('week').endOf('month');
            const rangeStart = moment(currentWeek).startOf('month');
            const rangeEnd = moment(currentWeek).endOf('week').endOf('month');
            let dates = [];
            const sameService = prevService.current === selectedService;
            prevService.current = selectedService;
            prevWeek.current = currentWeek;
            if (selectedService && (!sameService
                || !prevRangeStart.isSame(rangeStart) || !prevRangeEnd.isSame(rangeEnd)
            )) {
                let prevCurrentWeek = currentWeek;
                const getDates = async () => {
                    if (promiseRef.current) {
                        promiseRef.current[cancelRequest]();
                    }
                    promiseRef.current = BackendMethods.getAppointmentDates(
                        companyId,
                        selectedService.id,
                        moment(currentWeek).startOf('month').startOf('week').toISOString(),
                        moment(currentWeek).endOf('week').endOf('month').endOf('week').toISOString(),
                        isEdit ? Number(params.id) : null,
                    );
                    dates = (await promiseRef.current).filter((x) => x.employees.some((y) => y.dates.length !== 0));
                    prevCurrentWeek = currentWeek;
                    currentWeek = moment(currentWeek).add(1, 'month');
                };
                if (sameService) {
                    await getDates();
                } else {
                    while (dates.length === 0 && currentWeek.diff(moment(), 'month') <= 12) {
                        await getDates();
                    }
                }
                setLocationDates(dates);
                const newSlots = getSlots(dates);
                if (!sameService && newSlots.length > 0) {
                    const newSite = sites.find((x) => x.id === newSlots[0].location);
                    setStartOfWeek(moment(newSlots[0].date).tz(newSite.timezone).startOf('week'));
                    setSelectedDate(moment(newSlots[0].date).tz(newSite.timezone).startOf('day'));
                } else if (!prevCurrentWeek.isSame(startOfWeek)) {
                    setStartOfWeek(prevCurrentWeek);
                }
            }
        })();
    }, [companyId, selectedService, startOfWeek, isEdit, params.id, sites]);
    const daySlots = slots.filter((slot) => slot.date.isSame(selectedDate, 'day'));
    const morningSlots = daySlots.filter((slot) => (
        slot.date.isBefore(moment(slot.date).hour(12).startOf('hour'))
    ));
    const gotoBooking = async () => {
        let contents: any;
        setCustomerStep(true);
        try {
            const types: string = 'PRIVACY_POLICY,TAC_CONSUMER,GDPR_CONSUMER';
            contents = await BackendMethods.getConsents(types);
            if (contents.length > 0) {
                setShowGDPRConsentModal(true);
            }
        } catch (error) {
            setCustomerStep(false);
        }
    };
    const afternoonSlots = daySlots.filter((slot) => (slot.date.isBetween(
        moment(slot.date).hour(12).startOf('hour'),
        moment(slot.date).hour(18).startOf('hour'),
        'minute',
        '[)',
    )));
    const eveningSlots = daySlots.filter((slot) => (
        slot.date.isSameOrAfter(moment(slot.date).hour(18).startOf('hour'))
    ));
    const [showCustom, setShowCustom] = useState(false);
    const [customOpen, setCustomOpen] = useState(false);
    const [customDate, setCustomDate] = useState<Moment | null>(null);
    const [customError, setCustomError] = useState(false);
    const [customerStep, setCustomerStep] = useState(false);
    useEffect(() => {
        const bookingDataRaw = localStorage.getItem('booking-data');
        if (sites && services && people && bookingDataRaw) {
            const bookingData = JSON.parse(bookingDataRaw);
            const newSite = sites.find(R.propEq('id', bookingData.site));
            setSite(newSite);
            setSelectedService(services.find(R.propEq('id', bookingData.service)));
            setSelectedPerson(people.find(R.propEq('id', bookingData.person)));
            if (bookingData.isCustomDate) {
                setCustomDate(moment(bookingData.date).tz(newSite.timezone));
            } else {
                setSelectedSlot({
                    date: moment(bookingData.date).tz(newSite.timezone),
                    location: bookingData.site,
                    employee: bookingData.person,
                });
            }
            if (bookingData.customer) {
                setInitialCustomer(bookingData.customer);
            }
            if (bookingData.bookingFor) {
                setInitialBookingFor(bookingData.bookingFor);
            }
            setCustomerStep(true);
            gotoBooking();
            localStorage.removeItem('booking-data');
        }
    }, [sites, services, people]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [initialNotes, setInitialNotes] = useState('');
    const [initialCustomer, setInitialCustomer] = useState<ICustomer | null>(null);
    const [initialBookingFor, setInitialBookingFor] = useState<ICustomer | null>(null);
    const [originalAppointment, setOriginalAppointment] = useState<IAppointment | null>(null);
    const loadAppointment = async (id: number) => {
        const from = '2000-01-01T00:00:00.000Z';
        const to = '2100-01-01T00:00:00.000Z';
        const allAppointments = await (isCustomer
            ? BackendMethods.getCustomerAppointments(from, to)
            : BackendMethods.getAppointments(from, to));
        const appointment = allAppointments.find(R.propEq('id', id));
        setSite(sites.find(R.propEq('id', appointment.location.id)));
        setSelectedPerson(people.find(R.propEq('id', appointment.employee.id)));
        setSelectedService(services.find(R.propEq('id', appointment.service.id)));
        setInitialNotes(appointment.notes);
        setInitialCustomer(appointment.customer);
        setInitialBookingFor(appointment.bookingFor);
        setOriginalAppointment(appointment);
        return appointment;
    };
    useEffect(() => {
        (async () => {
            if (sites && services && people && isRebook && !dataLoaded) {
                await loadAppointment(Number(params.id));
                setDataLoaded(true);
            }
        })();
    }, [isRebook, params.id, sites, services, people]);
    useEffect(() => {
        (async () => {
            if (sites && services && people && isEdit && !dataLoaded) {
                const appointment = await loadAppointment(Number(params.id));
                setBookedService(appointment.service);
                setDataLoaded(true);
            }
        })();
    }, [isEdit, params.id, sites, services, people]);
    useEffect(() => {
        if (!isEdit) {
            setShowCustom(false);
            setCustomDate(null);
            setSelectedSlot(null);
            setBookedSlot(null);
            setBookedService(null);
        }
    }, [isEdit]);
    const selectSlot = (slot: ISlot) => {
        setSelectedSlot(slot);
        setSelectedPerson(people.find(R.propEq('id', slot.employee)));
        setSite(sites.find(R.propEq('id', slot.location)));
        setCustomDate(null);
    };
    useEffect(() => {
        if (!customDate && selectedSlot === null && slots.length > 0 && site !== 'ANY' && !isEdit) {
            selectSlot(slots[0]);
            setSelectedDate(moment(slots[0].date).tz(site.timezone).startOf('day'));
        }
    }, [selectedSlot, slots, customDate, site, isEdit]);
    const events = useMemo(() => slots.filter((slot) => (
        !slot.date.isSame(selectedSlot?.date)
    )).map((slot) => {
        const { timezone } = sites.find((x) => x.id === slot.location);
        return ({
            id: slot.date.toISOString(),
            subject: '',
            start: slot.date.toISOString(),
            end: moment(slot.date).add(selectedService.duration, 'minutes').toISOString(),
            dontGroup: true,
            data: slot,
            timezone,
        });
    }), [slots, selectedService, selectedSlot]);
    const selectedSlotDate = selectedSlot?.date || customDate;
    const eventProposition = useMemo(() => (
        selectedSlotDate ? {
            id: selectedSlotDate.toISOString(),
            subject: '',
            start: selectedSlotDate.toISOString(),
            end: moment(selectedSlotDate).add(selectedService.duration, 'minutes').toISOString(),
            dontGroup: true,
        } : null
    ), [selectedSlotDate, selectedService]);
    const workWeek = useMemo(() => [], []);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    const renderSlot = (slot: ISlot) => {
        const selected = slot.date.isSame(selectedSlot?.date);
        const { timezone } = sites.find((x) => x.id === slot.location);
        return (
            <Button
                key={slot.date.toISOString()}
                color={selected ? 'secondary' : 'primary'}
                variant={selected ? 'contained' : 'text'}
                className={styles.slot}
                onClick={() => selectSlot(slot)}
            >
                {slot.date.tz(timezone).format('HH:mm')}
            </Button>
        );
    };

    const renderInput = useCallback(() => null, []);
    const shouldDisableDate = (date: Moment) => slots.findIndex((slot) => {
        const { timezone } = sites.find((x) => x.id === slot.location);
        return slot.date.tz(timezone).isSameOrAfter(date.startOf('day')) && slot.date.tz(timezone).isSameOrBefore(date.endOf('day'));
    }) === -1;
    const pickerViews = useMemo((): DatePickerView[] => ['month', 'day'], []);
    const renderDay = useCallback((
        date,
        selectedDates,
        pickersDayProps,
    ) => {
        return (
            <PickersDay
                {...pickersDayProps}
                className={classNames({
                    [styles.today]: pickersDayProps.today,
                })}
                disabled={pickersDayProps.disabled}
            />
        );
    }, []);
    const onMonthChange = useCallback((month) => {
        setStartOfWeek(month.startOf('month').add(1, 'week').startOf('week'));
    }, []);
    const datePicker = useMemo(() => (
        <StaticDatePicker<Moment>
            value={selectedDate}
            onChange={setSelectedDate}
            renderInput={renderInput}
            className={styles.picker}
            shouldDisableDate={shouldDisableDate}
            views={pickerViews}
            renderDay={renderDay}
            onMonthChange={onMonthChange}
        />
    ), [selectedDate, slots, site]);
    if (!sites || !services || !people || ((isEdit || isRebook) && !dataLoaded)) {
        return <FallingLeaves />;
    }
    const siteSelect = (
        <Autocomplete<ILocation | 'ANY', false, true, false>
            label={t('small.site')}
            options={['ANY', ...sites]}
            getOptionLabel={(x) => x === 'ANY' ? t('small.siteAny') : x.name}
            value={site}
            onChange={(event, newValue) => {
                if (!isRebook && !isEdit && isCustomer) {
                    history.push(`/sb-customer/book/${companyId}${newValue === 'ANY' ? '' : `/${newValue.id}`}`);
                }
                setSite(newValue);
                setSelectedPerson('ANY');
                setSelectedService(null);
                setSelectedDate(null);
                setSelectedSlot(null);
                setCustomDate(null);
            }}
            disableClearable
        />
    );
    const getServicePhone = (service: IService) => {
        const site2 = site === 'ANY'
            ? sites.find(R.propEq(
                'id',
                (
                    selectedPerson === 'ANY'
                        ? people.find((person) => (
                            person.services.includes(service.id)
                            && person.locations.length > 0
                        ))
                        : selectedPerson
                ).locations[0],
            ))
            : site;
        return new PhoneNumber(site2.countryCode + site2.phone).getNumber('international');
    };
    const summary = (
        <>
            {isEdit && selectedService && originalAppointment.service.id !== selectedService.id && (
                <div className={styles.changed}>
                    {originalAppointment.service.name}
                </div>
            )}
            {selectedService && (
                <div>
                    {selectedService.name}
                </div>
            )}
            {isEdit && ((selectedPerson === 'ANY' || originalAppointment.employee.id !== selectedPerson.id)
                || (originalAppointment.service.price && originalAppointment.service.price !== selectedService?.price)) && (
                <div className={styles.spaceBetween}>
                    {(selectedPerson === 'ANY' || originalAppointment.employee.id !== selectedPerson.id) && (
                        <div className={styles.changed}>
                            {originalAppointment.employee.name}
                        </div>
                    )}
                    {originalAppointment.service.price && !R.equals(originalAppointment.service.price, selectedService?.price) && (
                        <div className={styles.changed}>
                            {renderPrice(originalAppointment.service.price)}
                        </div>
                    )}
                </div>
            )}
            <div className={styles.spaceBetween}>
                <div>
                    {selectedPerson !== 'ANY' && selectedPerson.name}
                </div>
                {selectedService?.price && (
                    <div>
                        {renderPrice(selectedService.price)}
                    </div>
                )}
            </div>
            {isEdit && (!selectedSlotDate || !selectedSlotDate.isSame(originalAppointment.begin)) && (
                <div className={styles.spaceBetween}>
                    <div className={styles.changed}>
                        {(!selectedSlotDate || !selectedSlotDate.isSame(originalAppointment.begin, 'day'))
                            && moment(originalAppointment.begin).format(
                                i18n.language === 'pl' ? 'dddd, D MMMM Y' : 'dddd, MMMM D, Y',
                            )}
                    </div>
                    <div className={styles.changed}>
                        {moment(originalAppointment.begin).format(
                            timeFormat === '12h' ? 'h:mm a' : 'H:mm',
                        )}
                        -
                        {moment(originalAppointment.end)
                            .format(timeFormat === '12h' ? 'h:mm a' : 'H:mm')}
                    </div>
                </div>
            )}
            {selectedSlotDate && selectedService && (
                <div className={styles.spaceBetween}>
                    <div>
                        {selectedSlotDate.format(
                            i18n.language === 'pl' ? 'dddd, D MMMM Y' : 'dddd, MMMM D, Y',
                        )}
                    </div>
                    <div>
                        {selectedSlotDate.format(
                            timeFormat === '12h' ? 'h:mm a' : 'H:mm',
                        )}
                        -
                        {moment(selectedSlotDate).add(selectedService.duration, 'minutes')
                            .format(timeFormat === '12h' ? 'h:mm a' : 'H:mm')}
                    </div>
                </div>
            )}
            {isEdit && (site === 'ANY' || originalAppointment.location.id !== site.id) && (
                <>
                    <div className={styles.changed}>
                        {originalAppointment.location.name}
                    </div>
                    <div className={styles.changed}>
                        {originalAppointment.location.address}
                    </div>
                </>
            )}
            <div>
                {site !== 'ANY' && site.name}
            </div>
            <div>
                {site !== 'ANY' && site.address}
            </div>
        </>
    );
    const origin = getSubdomain() === null ? '' : window.location.origin;
    const redirect = encodeURIComponent(`${origin}/sb-customer/book/${companyId}/${site !== 'ANY' ? site.id : ''}`);
    const loginUrl = `/login?redirectURI=${redirect}`;
    const registerMergeUrl = `/register?merge=1&redirectURI=${redirect}`;
    const dashboardUrl = isCustomer ? '/sb-customer/my-appointments' : '/sb/dashboard';
    const filteredPeople = people.filter((person) => (
        person.services.length > 0
        && (site === 'ANY' || person.locations.includes(site.id))
        && person.name.toLowerCase().includes(search.toLowerCase())
        && (selectedService === null || person.services.includes(selectedService.id))
    ));
    const timezoneMismatch = site !== 'ANY' && site.timezone !== userTimezone;
    return (
        <div className={styles.root}>
            {!customerStep && (
                <>
                    {isCustomer && (
                        <div className={styles.companyColumn}>
                            <div className={styles.company}>
                                <div
                                    className={styles.companyImage}
                                    style={(site !== 'ANY' && site.photo)
                                        ? { backgroundImage: `url(${site.photo})` }
                                        : {}}
                                />
                                <div className={styles.companyName}>
                                    {companyName}
                                </div>
                            </div>
                            <div className={styles.companySiteSelect}>
                                {isCustomer && siteSelect}
                            </div>
                        </div>
                    )}
                    <div className={styles.serviceColumn}>
                        <div className={styles.selects}>
                            {!isCustomer && siteSelect}
                            <TextField
                                label={t('small.searchServicePerson')}
                                value={search}
                                onChange={({ target }) => (
                                    setSearch(target.value)
                                )}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                src="/assets/search-gray.svg"
                                                alt=""
                                                className={styles.searchIcon}
                                            />
                                        </InputAdornment>
                                    ),
                                    endAdornment: search ? (
                                        <InputAdornment position="end" className={styles.endAdornment}>
                                            <IconButton onClick={() => setSearch('')} size="large">
                                                <img
                                                    src="/assets/cancel_icon_dark_blue.svg"
                                                    alt=""
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null,
                                }}
                            />
                        </div>
                        <div className={styles.people}>
                            {search === '' && filteredPeople.length > 1 && (
                                <div
                                    className={classNames(styles.person, {
                                        [styles.selectedPerson]: selectedPerson === 'ANY',
                                    })}
                                    onClick={() => setSelectedPerson('ANY')}
                                >
                                    <div className={styles.avatarContainer}>
                                        <img
                                            src="/assets/people.svg"
                                            alt=""
                                            className={styles.avatarAny}
                                        />
                                    </div>
                                    <div className={styles.personName}>
                                        {t('small.everyone')}
                                    </div>
                                </div>
                            )}
                            {filteredPeople.map((person) => (
                                <div
                                    key={person.id}
                                    className={classNames(styles.person, {
                                        [styles.selectedPerson]: selectedPerson === person,
                                    })}
                                    onClick={() => {
                                        setSelectedPerson(person);
                                        setSearch('');
                                        setSelectedDate(null);
                                        setSelectedSlot(null);
                                    }}
                                >
                                    <div className={styles.avatarContainer}>
                                        <img
                                            src={person.picture || '/assets/human.svg'}
                                            alt=""
                                            className={person.picture ? styles.avatar : styles.defaultAvatar}
                                        />
                                    </div>
                                    <Tooltip title={person.name} enterDelay={500}>
                                        <div className={styles.personName}>
                                            {person.name}
                                        </div>
                                    </Tooltip>
                                </div>
                            ))}
                        </div>
                        <RadioGroup
                            value={selectedService ? selectedService.id : null}
                            className={styles.services}
                        >
                            {services.filter((service) => (
                                (site === 'ANY' || R.chain(
                                    R.prop('services'),
                                    people.filter((person) => (
                                        person.locations.includes(site.id)
                                    )),
                                ).includes(service.id))
                                && service.name.toLowerCase().includes(search.toLowerCase())
                                && (selectedPerson === 'ANY' || selectedPerson.services.includes(service.id))
                                && (!isCustomer || !service.custHidden)
                            )).map((service) => (
                                <FormControlLabel
                                    key={service.id}
                                    control={(
                                        <Radio
                                            color="secondary"
                                            onClick={() => {
                                                setSelectedService(
                                                    selectedService?.id === service.id ? null : service,
                                                );
                                                setSearch('');
                                                setSelectedDate(null);
                                                setSelectedSlot(null);
                                                setCustomDate(null);
                                                setLocationDates([]);
                                            }}
                                        />
                                    )}
                                    label={(
                                        <div className={styles.service}>
                                            <div>
                                                {service.name}
                                            </div>
                                            <div className={styles.serviceDetails}>
                                                <div>
                                                    {renderPrice(service.price)}
                                                </div>
                                                <div>
                                                    {service.duration} min
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    value={service.id}
                                    classes={{ label: styles.radioLabel }}
                                    className={styles.formControlLabel}
                                />
                            ))}
                        </RadioGroup>
                    </div>
                    {(isCustomer && selectedService && selectedService.noSelf
                        && selectedService.id !== bookedService?.id) ? (
                        <div className={styles.noSelfColumn}>
                            <div className={styles.noSelf}>
                                <div className={styles.noSelfLine}>
                                    {t('small.onlyPhoneBooking')}
                                </div>
                                <div className={styles.noSelfLine}>
                                    {t('small.callNumber')}
                                    {' '}
                                    {getServicePhone(selectedService)}
                                    .
                                </div>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() => {
                                        setSelectedService(null);
                                        setSelectedDate(null);
                                        setSelectedSlot(null);
                                        setCustomDate(null);
                                    }}
                                    className={styles.noSelfClose}
                                >
                                    {t('small.close')}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.datetimeColumn}>
                            <div className={styles.datetimeLeft}>
                                {datePicker}
                                {timezoneMismatch && (
                                    <div className={styles.timezoneMismatch}>
                                        {t('small.hoursTimezone', { timezone: moment().tz((site as ILocation).timezone).zoneAbbr() })}
                                    </div>
                                )}
                                {morningSlots.length > 0 && (
                                    <>
                                        <div className={styles.timeGroupHeader}>
                                            {t('small.morning')}
                                        </div>
                                        <div className={styles.slots}>
                                            {morningSlots.map(renderSlot)}
                                        </div>
                                    </>
                                )}
                                {afternoonSlots.length > 0 && (
                                    <>
                                        <div className={styles.timeGroupHeader}>
                                            {t('small.afternoon')}
                                        </div>
                                        <div className={styles.slots}>
                                            {afternoonSlots.map(renderSlot)}
                                        </div>
                                    </>
                                )}
                                {eveningSlots.length > 0 && (
                                    <>
                                        <div className={styles.timeGroupHeader}>
                                            {t('small.evening')}
                                        </div>
                                        <div className={styles.slots}>
                                            {eveningSlots.map(renderSlot)}
                                        </div>
                                    </>
                                )}
                                {selectedService && !isCustomer && (
                                    <>
                                        {!showCustom && (
                                            <Button
                                                onClick={() => {
                                                    if (site !== 'ANY' && selectedPerson !== 'ANY') {
                                                        setShowCustom(true);
                                                        setCustomOpen(true);
                                                    } else {
                                                        setCustomError(true);
                                                    }
                                                }}
                                            >
                                                {t('small.anotherDate')}
                                            </Button>
                                        )}
                                        {showCustom && (
                                            <MobileDateTimePicker
                                                label={t('small.anotherDate')}
                                                disablePast
                                                value={customDate}
                                                onChange={(newDate) => {
                                                    setCustomDate(moment(newDate).set({ seconds: 0 }));
                                                    if (newDate) {
                                                        setSelectedDate(moment(newDate).startOf('day'));
                                                        setSelectedSlot(null);
                                                    }
                                                }}
                                                renderInput={(params2) => <TextField {...params2} />}
                                                ampm={timeFormat === '12h'}
                                                open={customOpen}
                                                onOpen={() => setCustomOpen(true)}
                                                onClose={() => setCustomOpen(false)}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                            <div className={styles.weekCalendarWrapper}>
                                <WeekCalendar
                                    rangeStart={moment().startOf('week').toISOString()}
                                    rangeEnd={
                                        moment().startOf('week').add(6, 'months').toISOString()
                                    }
                                    events={events}
                                    proposition={eventProposition}
                                    timezone={site === 'ANY' ? '' : site.timezone}
                                    timeFormat={timeFormat}
                                    workWeekEvents={workWeek}
                                    isSmallBusiness
                                    selectedDate={selectedDate}
                                    setSelectedSlot={(slot) => {
                                        if (slot) {
                                            selectSlot(slot);
                                            setSelectedDate(moment(slot.date).startOf('day'));
                                        }
                                    }}
                                    startOfDay={startOfWeek}
                                    setStartOfDay={setStartOfWeek}
                                    className={classNames({
                                        [styles.weekCalendarCustomer]: isCustomer,
                                    })}
                                />
                                {isCustomer && (
                                    <div className={styles.summary}>
                                        {summary}
                                        <div className={styles.bookRow}>
                                            {isEdit && (
                                                <Button
                                                    variant="outlined"
                                                    component={Link}
                                                    to="/sb-customer/find-service"
                                                    className={styles.discardChanges}
                                                >
                                                    {t('small.discardChanges')}
                                                </Button>
                                            )}
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                disabled={(!selectedService || !selectedSlotDate || selectedPerson === 'ANY')}
                                                className={styles.book}
                                                {...(profile ? {
                                                        onClick: () => gotoBooking()
                                                } : {
                                                    component: Link,
                                                    to: loginUrl,
                                                    onClick: () => {
                                                        if (site === 'ANY' || selectedPerson === 'ANY') {
                                                            return;
                                                        }
                                                        localStorage.setItem('booking-data', JSON.stringify({
                                                            site: site.id,
                                                            service: selectedService.id,
                                                            person: selectedPerson.id,
                                                            date: selectedSlotDate.toISOString(),
                                                            isCustomDate: customDate !== null,
                                                        }));
                                                        redirectToBaseDomain(loginUrl);
                                                    },
                                                })}
                                            >
                                                {isEdit ? t('small.reschedule') : t('small.book')}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
            {customerStep && (
                <div className={styles.summaryColumn}>
                    <div className={styles.summary}>
                        {summary}
                    </div>
                </div>
            )}
            {(!isCustomer || customerStep) && (
                <div
                    className={classNames(styles.customerColumn, {
                        [styles.customerCustomer]: isCustomer,
                    })}
                >
                    <BookingCustomer
                        renderSummary={(data) => {
                            const { customer, bookingFor, isExtra, notes, customerEdited, bookingForEdited } = data;
                            const button = (
                                <Button
                                    variant="contained"
                                    fullWidth={!isCustomer}
                                    disabled={(
                                        !selectedService || !selectedSlotDate || selectedPerson === 'ANY'
                                        || !customer || (isExtra && !bookingFor)
                                    )}
                                    className={styles.book}
                                    onClick={async () => {
                                        if (selectedPerson === 'ANY' || site === 'ANY') {
                                            return;
                                        }
                                        if (customerEdited) {
                                            if (isCustomer) {
                                                await BackendMethods.updateUserProfile(customer);
                                                await BackendMethods.updateMyCustomer(companyId, customer);
                                            } else if ('id' in customer) {
                                                await BackendMethods.updateCustomer(customer);
                                            }
                                        }
                                        if (bookingForEdited && 'id' in bookingFor) {
                                            if (isCustomer) {
                                                await BackendMethods.updateMyCare(companyId, {
                                                    countryCode: null,
                                                    phone: null,
                                                    ...bookingFor,
                                                });
                                            } else if ('id' in customer) {
                                                await BackendMethods.updateCare(customer.id, bookingFor);
                                            }
                                        }
                                        const newAppointment = {
                                            employee: selectedPerson.id,
                                            location: site.id,
                                            service: selectedService.id,
                                            date: selectedSlotDate.toISOString(),
                                            customDate: customDate !== null,
                                            notes,
                                            customer,
                                            bookingFor,
                                            ...(isEdit ? { id: Number(params.id) } : {}),
                                        };
                                        if (isEdit) {
                                            if (isCustomer) {
                                                await BackendMethods.editAppointmentCustomer({
                                                    company: companyId,
                                                    ...newAppointment,
                                                });
                                            } else {
                                                await BackendMethods.editAppointment(newAppointment);
                                            }
                                        } else {
                                            if (isCustomer) {
                                                await BackendMethods.bookAppointmentCustomer({
                                                    company: companyId,
                                                    ...newAppointment,
                                                });
                                            } else {
                                                await BackendMethods.bookAppointment(newAppointment);
                                            }
                                        }
                                        setDialogOpen(true);
                                    }}
                                >
                                    {isCustomer
                                        ? (isEdit ? t('small.confirmReschedule') : t('small.confirmBooking'))
                                        : (isEdit ? t('small.reschedule') : t('small.book'))}
                                </Button>
                            );
                            if (isCustomer) {
                                return (
                                    <div className={styles.bookRow}>
                                        <Button
                                            variant="text"
                                            onClick={() => setCustomerStep(false)}
                                            className={styles.discardChanges}
                                        >
                                            {isEdit ? t('small.discardChanges') : t('small.back')}
                                        </Button>
                                        {button}
                                    </div>
                                );
                            }
                            return (
                                <div className={classNames(styles.summary, styles.assistantSummary)}>
                                    {summary}
                                    <div className={styles.bookRow}>
                                        {isEdit && (
                                            <Button
                                                variant="text"
                                                component={Link}
                                                to="/sb/dashboard"
                                            >
                                                {t('small.discardChanges')}
                                            </Button>
                                        )}
                                        {button}
                                    </div>
                                </div>
                            );
                        }}
                        saveData={({ customer, bookingFor }) => {
                            if (site === 'ANY' || selectedPerson === 'ANY') {
                                return;
                            }
                            localStorage.setItem('booking-data', JSON.stringify({
                                site: site.id,
                                service: selectedService.id,
                                person: selectedPerson.id,
                                date: selectedSlotDate.toISOString(),
                                isCustomDate: customDate !== null,
                                customer,
                                bookingFor,
                            }));
                        }}
                        onAddEmail={() => redirectToBaseIfNeeded(history, registerMergeUrl)}
                        timezone={userTimezone}
                        initialNotes={initialNotes}
                        initialCustomer={initialCustomer}
                        initialBookingFor={initialBookingFor}
                        isEdit={isEdit}
                        isCustomer={isCustomer}
                        profile={profile}
                        companyId={companyId}
                        birthDateRequired={birthDateRequired}
                    />
                </div>
            )}
            <Dialog
                open={dialogOpen}
                PaperProps={{ className: styles.paper }}
            >
                <div className={styles.dialogHeader}>
                    {isEdit ? t('small.appointmentRescheduled') : t('small.bookingConfirmed')}
                </div>
                <img src="/assets/checkmark_icon.svg" alt="" className={styles.checkmark} />
                <div className={styles.dialogText}>
                    {t('small.dayBeforeConfirmation')}
                </div>
                {(profile && !profile.email) && (
                    <>
                        <div className={styles.dontType}>
                            {t('small.dontTypeCodes')}
                        </div>
                        <Button
                            fullWidth
                            onClick={() => setEmailDialogOpen(true)}
                            className={styles.registerEmail}
                        >
                            {t('small.registerYourEmail')}
                        </Button>
                        <AddEmailDialog
                            open={emailDialogOpen}
                            onClose={() => setEmailDialogOpen(false)}
                        />
                    </>
                )}
                <Button
                    fullWidth
                    variant="contained"
                    component={Link}
                    to={dashboardUrl}
                    onClick={() => {
                        if (isCustomer) {
                            redirectToBaseDomain(dashboardUrl);
                        }
                    }}
                >
                    {t('small.done')}
                </Button>
            </Dialog>
            <Dialog
                open={customError}
                PaperProps={{ className: styles.paper }}
                onClose={() => setCustomError(false)}
            >
                <div className={styles.errorDialogText}>
                    {t('small.selectLocationPerson')}
                </div>
                <Button
                    className={styles.dialogButton}
                    variant="contained"
                    onClick={() => setCustomError(false)}
                >
                    {t('small.ok')}
                </Button>
            </Dialog>
            {showGDPRConsentModal && profile && (
                <GDPRConsentModal
                    isDesktop={true}
                    setShowGDPRConsentModal={setShowGDPRConsentModal}
                    onClose={() => {
                        setShowGDPRConsentModal(false);
                        setCustomerStep(false);
                    }}
                />
            )}
        </div>
    );
}
