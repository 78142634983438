import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import AppFooter from '../SmallBusiness/Footer';
import { useIsDesktop } from '../../utils/responsive';
const styles = require('./Footer.css');

interface Props {
    isBackgroundColor?: boolean;
}

export default function Footer(props: Props) {
    const { t } = useTranslation();
    const isDesktop = useIsDesktop();
    return (
        <div className = {props.isBackgroundColor ? styles.root : ''}>
            <div className={styles.footer}>
                <div className={styles.links}>
                    <Link
                        to="/"
                        className={styles.link}
                    >
                        {t('landing.home')}
                    </Link>
                    <Link
                        to={isDesktop ? '/#features' : '/features'}
                        className={styles.link}
                    >
                        {t('landing.features')}
                    </Link>
                    <Link
                        to={isDesktop ? '/#pricing' : '/pricing'}
                        className={styles.link}
                    >
                        {t('landing.pricing')}
                    </Link>
                    <Link
                        to="/faq"
                        className={styles.link}
                    >
                        {t('landing.faq')}
                    </Link>
                    <Link
                        to="/about"
                        className={styles.link}
                    >
                        {t('landing.aboutUs')}
                    </Link>
                    <Link
                        to="/contact"
                        className={styles.link}
                    >
                        {t('landing.contact')}
                    </Link>
                </div>
                <div className={styles.right}>
                    <div className={styles.extension}>
                        <div className={styles.extensionText}>
                            <div className={styles.usingCalendar}>
                                {t('landing.usingCalendar')}
                            </div>
                            <div className={styles.getTanbuuExtension}>
                                {t('landing.getExtension')}
                                <img
                                    src="/assets/landingAnimation/logo_text.png"
                                    alt="Tanbuu"
                                    className={styles.chromeTanbuu}
                                />
                                <span className={styles.chromeExtension}>
                                    {t('landing.chromeExtension')}
                                </span>
                            </div>
                            <div className={styles.forCalendar}>
                                {t('landing.forCalendar')}
                            </div>
                        </div>
                        <a
                            href="https://chrome.google.com/webstore/detail/tanbuu/ehbgpokeijkjihbmaofmickihgpkbike"
                            rel="noopener noreferrer"
                        >
                            <img src="/assets/chrome-web-store.png" alt="" className={styles.storeBadge} />
                        </a>
                    </div>
                    <div className={styles.social}>
                        <div className={styles.socialLinks}>
                            {/*
                            <IconButton
                                color="primary"
                                className={styles.socialLink}
                                component="a"
                                href="https://www.facebook.com/Tanbuu-1683002148403079/"
                                rel="noopener noreferrer"
                            >
                                <img src="/assets/facebook-blue.svg" alt="" />
                            </IconButton>
                            */}
                            <IconButton
                                color="primary"
                                className={styles.socialLink}
                                component="a"
                                href="https://www.linkedin.com/showcase/tanbuu/"
                                rel="noopener noreferrer"
                                size="large">
                                <img src="/assets/linkedin-blue.svg" alt="" />
                            </IconButton>
                            <IconButton
                                color="primary"
                                className={styles.socialLink}
                                component="a"
                                href="https://www.youtube.com/channel/UCGS0xCiVog4AWQpo5viqhWA"
                                rel="noopener noreferrer"
                                size="large">
                                <img src="/assets/youtube-blue.svg" alt="" />
                            </IconButton>
                        </div>
                        <div className={styles.socialShare}>
                            <div className={styles.shareText}>
                                {t('landing.share')}
                            </div>
                            <div className={styles.shareLinks}>
                                <IconButton
                                    color="primary"
                                    className={styles.shareLink}
                                    onClick={() => {
                                        const baseUrl = 'https://www.linkedin.com/shareArticle?mini=true';
                                        const url = 'http://tanbuu.com';
                                        const title = 'Tanbuu';
                                        const summary =
                                            'Simplify your life, orchestrating one meeting at a time with Tanbuu!';
                                        const source = 'Tanbuu';
                                        const link = `${baseUrl}&url=${url}&title=${title}&summary=${summary}&source=${source}`;
                                        window.open(link, 'Share on LinkedIn', 'width=1024, height=700');
                                    }}
                                    size="large">
                                    <img src="/assets/linkedin-share-blue.svg" alt="" />
                                </IconButton>
                                <IconButton
                                    color="primary"
                                    className={styles.shareLink}
                                    onClick={() => {
                                        const baseUrl = 'https://twitter.com/intent/tweet';
                                        const text =
                                            'Simplify your life, orchestrating one meeting at a time with Tanbuu!';
                                        const hashtags =
                                            'productivity,meetings,calendar,time_saved,management,office,schedule,assistant,ai,smart,scheduler,meet,tanbuu';
                                        const link = `${baseUrl}?text=${text}&hashtags=${hashtags}`;
                                        window.open(link, 'Share on Twitter', 'width=1024, height=700');
                                    }}
                                    size="large">
                                    <img src="/assets/twitter-share-blue.svg" alt="" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    );
}
