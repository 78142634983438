import React, { useContext, useEffect, useState } from 'react';
import IActions from '../../../../actions/IActions';
import classNames from 'classnames';
import AdminNavigation from '../AdminNavigation/AdminNavigation';
import moment from 'moment-timezone';
import { RouteComponentProps, withRouter } from 'react-router';
import Modal from '../../../_ReusableComponents/Modals/Modal';
import TermsContent from '../../../_ReusableComponents/TermsContent/TermsContent';
import getStripe from '../../../../utils/getStripe';
import Button from '../../../_ReusableComponents/Button/Button';
import { useTranslation, Trans } from 'react-i18next';
import GlobalContext from '../../../../GlobalContext';
const styles = require('./PlaceOrder.css');

interface Props extends RouteComponentProps<any> {
    plusPlanAdmin: any;
    data: any;
    actions: IActions;
    history: any;
}

const PlaceOrder = (props: Props) => {
    const { t, i18n } = useTranslation();
    const { profile } = useContext(GlobalContext);
    const { plusPlanAdmin } = props;
    const licenses = plusPlanAdmin.get('newAmountOfLicenses');
    const monthlyPriceUSD = plusPlanAdmin.get('monthlyPrice') / 100;
    const yearlyPriceUSD = plusPlanAdmin.get('yearlyPrice') / 100;
    const billingType = plusPlanAdmin.get('newBillingType');
    const billingTypeShort = billingType === 'monthly' ? 'mo' : 'yr';
    const licensesNumber = Number(licenses);
    const licensePrice = billingType === 'monthly' ? monthlyPriceUSD : yearlyPriceUSD;
    const total = (licensesNumber * licensePrice).toFixed(2);
    const creditNumber = plusPlanAdmin.get('credit') || 0;
    const credit = creditNumber.toFixed(2);
    const chargeNow = (licensesNumber * licensePrice - creditNumber).toFixed(2);
    const cardBrand = plusPlanAdmin.getIn(['card', 'brand']);
    const cardEnding = plusPlanAdmin.getIn(['card', 'last4']);
    const expires = props.data.get('subscription').expires;
    const expiresFormatted = moment.tz(expires, profile.timezone)
        .format(i18n.language === 'pl' ? 'D MMMM Y' : 'MMMM D, Y');
    const renew = plusPlanAdmin.get('newAutoRenew');
    const proration = plusPlanAdmin.get('prorationToken');

    const onReturn = () => props.actions.changeActivePanel('edit');
    const onContinue = () => props.actions.updateSubscription(billingType, licensesNumber, renew, proration);

    const token = plusPlanAdmin.get('threeDSecureToken');
    useEffect(() => {
        (async () => {
            if (token) {
                const stripe = await getStripe(i18n.language);
                const { error } = await stripe.handleCardPayment(token);
                props.actions.adminThreeDSecureComplete(!error);
            }
        })();
    }, [token]);

    useEffect(() => {
        if (plusPlanAdmin.get('paymentSuccess')) {
            if (licenses !== props.data.get('subscription').licenses) {
                props.history.push('/payment-success');
            } else {
                props.history.push('/payment-success/no-new-licenses');
            }
            props.actions.resetAdminPanel(props.data.get('subscription'));
        }
    }, [plusPlanAdmin.get('paymentSuccess')]);

    useEffect(() => {
        if (plusPlanAdmin.get('paymentFailed')) {
            props.history.push('/payment-failed');
            props.actions.resetAdminPanel(props.data.get('subscription'));
        }
    }, [plusPlanAdmin.get('paymentFailed')]);

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className={styles.root}>
            <div className={classNames(styles.element, styles.spaceBetween)}>
                <div>
                    {t('plan.totalLicenses')}:
                </div>
                <div>
                    {licenses}
                </div>
                <div className={styles.note}>
                    ${licensePrice} / {t('plan.licensedUser')}
                    */{billingType === 'monthly' ? t('plan.month') : t('plan.year')}
                </div>
            </div>
            <div className={styles.element}>
                {`${t('plan.billed')} ${t(`plan.${billingType}`)}`}
            </div>
            <div className={classNames(styles.element, styles.spaceBetween)}>
                <div className={styles.bold}>
                    {t('plan.newTotal', { type: t(`plan.${billingType}`) })}
                    :
                </div>
                <div className={styles.bold}>
                    ${total} / {t(`plan.${billingTypeShort}`)}
                </div>
                <div className={styles.note}>
                    {t('plan.effective')} {t('common.today')}
                </div>
            </div>
            {creditNumber !== 0 && (
                <div className={classNames(styles.element, styles.spaceBetween)}>
                    <div>
                        {t('plan.credit')}**:
                    </div>
                    <div>
                        ${credit}
                    </div>
                    <div className={styles.note}>
                        {t('plan.asOfNow')}
                    </div>
                </div>
            )}
            <div className={classNames(styles.element, styles.spaceBetween)}>
                <div className={styles.bold}>
                    {t('plan.chargeNow')}:
                </div>
                <div className={styles.bold}>
                    ${chargeNow}
                </div>
            </div>
            <div className={classNames(styles.element, styles.small)}>
                {t('plan.taxesInfo')}
            </div>
            <div className={classNames(styles.element, styles.spaceBetween, styles.margin)}>
                <div>
                    <div>{t('plan.paymentMethod')}:</div>
                    <div>{cardBrand}, {t('plan.endingIn')} {cardEnding}</div>
                </div>
                <Button onClick={() => props.actions.updateCardFromPlaceOrder()}>
                    {t('plan.update')}
                </Button>
            </div>
            <div className={classNames(styles.element, styles.small)}>
                <Trans
                    i18nKey="plan.termsAgree"
                    components={[
                        <span
                            className={styles.terms}
                            onClick={() => setModalOpen(true)}
                        />,
                    ]}
                />
            </div>
            <AdminNavigation
                acceptButton={t('plan.placeOrder')}
                onContinueClick={onContinue}
                onReturnClick={onReturn}
                actions={props.actions}
            />
            <div className={classNames(styles.element, styles.small)}>
                * {t('plan.licensedUserFootnote')}
            </div>
            <div className={classNames(styles.element, styles.small)}>
                ** {t('plan.creditFootnote', { date: expiresFormatted })}
            </div>
            {modalOpen && (
                <Modal position="top">
                    <div className={styles.Terms__Modal}>
                        <div>
                            <div className={styles.ButtonContainer}>
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => setModalOpen(false)}
                                >
                                    {t('plan.back')}
                                </Button>
                            </div>
                            <TermsContent />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default withRouter(PlaceOrder);
