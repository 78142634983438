import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/_ReusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';
const styles = require('./Nav.css');

export default function Nav() {
    const { t } = useTranslation();
    return (
        <div className={styles.header}>
            <Link
                className={styles.logo}
                to="/biz"
            />
            <Button
                variant="text"
                className={styles.menuButton}
                component={Link}
                to="/biz#features"
            >
                {t('small.features')}
            </Button>
            <Button
                variant="text"
                className={styles.menuButton}
                component={Link}
                to="/biz#useCases"
            >
                {t('small.useCasesForFooter')}
            </Button>
            <Button
                variant="text"
                className={styles.menuButton}
                component={Link}
                to="/biz#How_it_works"
            >
                {t('small.howItWorks')}
            </Button>
            <Button
                variant="text"
                className={styles.menuButton}
                component={Link}
                to="/biz#pricing"
            >
                {t('landing.pricing')}
            </Button>
            <Button
                variant="contained"
                className={styles.menuButton}
                component={Link}
                to="/register"
            >
                {t('landing.signUp')}
            </Button>
            <Button
                variant="text"
                className={styles.menuButton}
                component={Link}
                to="/login"
            >
                {t('landing.signIn')}
            </Button>
        </div>
    );
}
