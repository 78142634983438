import * as React from 'react';
import IActions from 'actions/IActions';
import Validators from '../../../utils/Validators/Validators';
import TextField from '../../_ReusableComponents/TextField/TextField';
import Button from '../../_ReusableComponents/Button/Button';
import { WithTranslation, withTranslation } from 'react-i18next';
const Styles = require('./AssignLicense.css');

interface IAssignLicenseProps extends WithTranslation {
    amountOfLicensesUnassigned: number;
    manageLicenses: any;
    data: any;
    actions: IActions;
}

export default withTranslation()(class AssignLicense extends React.Component<IAssignLicenseProps, null> {
    assignLicense = (e) => {
        e.preventDefault();
        const licenseName = this.props.manageLicenses.get('licenseName');
        const licenseEmail = this.props.manageLicenses.get('licenseEmail');

        let error = false;
        if (Validators.isValidEmail(licenseEmail) === false) {
            const {actions: {setLicenseEmailError}} = this.props;
            setLicenseEmailError();
            error = true;
        }
        if (this.props.amountOfLicensesUnassigned <= 0) {
            const {actions: {setInsufficientLicenseAmount}} = this.props;
            setInsufficientLicenseAmount();
            error = true;
        }
        if (!error) {
            this.props.actions.assignLicense(
                {
                    name: licenseName,
                    email: licenseEmail,
                    expiring: false
                }
            );
        }
    };

    changeLicenseName = (e) => {
        this.props.actions.changeLicenseName(e.target.value);
    };

    changeLicenseEmail = (e) => {
        this.props.actions.changeLicenseEmail(e.target.value);
    };

    render() {
        const { t, manageLicenses } = this.props;
        let error;
        if (manageLicenses.get('wrongLicenseEmail')) {
            error = t('plan.invalidEmail');
        }
        if (manageLicenses.get('insufficientLicenseAmount')) {
            error = t('plan.notEnoughLicenses');
        }
        if (manageLicenses.get('assignedByOtherError')) {
            error = t('plan.licenseAssignedByOther');
        }
        if (manageLicenses.get('alreadyAssignedError')) {
            error = t('plan.alreadyAssigned');
        }
        return (
            <section className={Styles.AssignLicence}>
                <div className={Styles.Label}>{t('plan.assignUser')}</div>
                <form className={Styles.Form}>
                    <TextField
                        label={t('plan.firstAndLastName')}
                        value={manageLicenses.get('licenseName')}
                        onChange={this.changeLicenseName}
                        fullWidth
                        margin="dense"
                        data-testid="license-name"
                    />
                    <TextField
                        label={t('plan.emailAddress')}
                        value={manageLicenses.get('licenseEmail')}
                        onChange={this.changeLicenseEmail}
                        fullWidth
                        margin="dense"
                        error={Boolean(error)}
                        helperText={error}
                        data-testid="license-email"
                    />
                    <div className={Styles.buttonContainer}>
                        {manageLicenses.get('assignPending') && (
                            <img src="/assets/spinner.gif" className={Styles.spinner} />
                        )}
                        <Button
                            variant="contained"
                            data-testid="AssignButton"
                            className={Styles.button}
                            onClick={this.assignLicense}
                            disabled={manageLicenses.get('assignPending')}
                        >
                            {t('plan.assign')}
                        </Button>
                    </div>
                </form>
            </section>
        );
    }
});
