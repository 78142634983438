import * as React from 'react';
import cs from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';

const Styles = require('./MeLabel.css');

export default withTranslation()(class YouLabel extends React.PureComponent<WithTranslation, null> {
  render() {
      const { t } = this.props;
      return (
          <div className={cs(Styles.MeLabel, Styles['MeLabel--YouLabel'])}>
              {t('common.you')}
          </div>
      );
  }
});
