// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MeetingTitleWithImage__2k7h8 {\n    display: block;\n    position: relative;\n}\n\n.Title__oYv-T {\n    font-size: 25px;\n    height: 70px;\n    line-height: 1.3;\n    max-width: 280px;\n    overflow-y: hidden;\n    width: 70%;\n}\n\n.HorizontalLine__2A10u {\n    margin-left: -22px;\n    margin-top: -57px;\n    max-width: 400px;\n    width: calc(100% + 22px);\n}\n", "",{"version":3,"sources":["MeetingTitleWithImage.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,wBAAwB;AAC5B","file":"MeetingTitleWithImage.css","sourcesContent":[".MeetingTitleWithImage {\n    display: block;\n    position: relative;\n}\n\n.Title {\n    font-size: 25px;\n    height: 70px;\n    line-height: 1.3;\n    max-width: 280px;\n    overflow-y: hidden;\n    width: 70%;\n}\n\n.HorizontalLine {\n    margin-left: -22px;\n    margin-top: -57px;\n    max-width: 400px;\n    width: calc(100% + 22px);\n}\n"]}]);
// Exports
exports.locals = {
	"MeetingTitleWithImage": "MeetingTitleWithImage__2k7h8",
	"Title": "Title__oYv-T",
	"HorizontalLine": "HorizontalLine__2A10u"
};
module.exports = exports;
