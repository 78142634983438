import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/_ReusableComponents/Button/Button';
import { ILocation } from './Sites';
import moment, { Moment } from 'moment';
import { Checkbox, Dialog, FormControlLabel, IconButton, Tooltip } from '@material-ui/core';
import Select from '../../../components/_ReusableComponents/Select/Select';
import { IPerson } from './Personnel';
import classNames from 'classnames';
import { DatePicker, TimePicker } from '@material-ui/lab';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import BackendMethods from '../../../api/BackendMethods';
import { IAppointment } from '../Dashboard/Dashboard';
import AffectedAppointments from './AffectedAppointments';
const styles = require('./PersonnelHours.css');

export type IWeekDay = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

interface IHourGroup {
    id: number;
    days: IWeekDay[];
    from: string;
    to: string;
}

export interface INewWorkHours {
    location: number;
    weekHours: IHourGroup[];
    start: string;
    end?: string;
}

export interface IWorkHours extends INewWorkHours {
    id: number;
}

interface IProps {
    workHours: IWorkHours[];
    setWorkHours: (workHours: IWorkHours[]) => void;
    sites: ILocation[];
    people: IPerson[];
    timeFormat: string;
    personId: number | 'new';
}

const midnight = moment('00:00', 'HH:mm');

export default function PersonnelHours(props: IProps) {
    const { t, i18n } = useTranslation();
    const { language } = i18n;
    const {
        workHours, setWorkHours, sites, people, timeFormat, personId,
    } = props;
    const [selectedHours, setSelectedHours] = useState<number | 'new' | null>(null);
    const [site, setSite] = useState<number | null>(null);
    const [copyFrom, setCopyFrom] = useState<number | null>(null);
    const [hourGroups, setHourGroups] = useState<IHourGroup[]>([]);
    const [days, setDays] = useState<IWeekDay[]>([]);
    const [hourFrom, setHourFrom] = useState<Moment | null>(midnight);
    const [hourTo, setHourTo] = useState<Moment | null>(midnight);
    const [dateFrom, setDateFrom] = useState<Moment | null>(null);
    const [dateTo, setDateTo] = useState<Moment | null>(null);
    const [noEndDate, setNoEndDate] = useState(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [affectedAppointments, setAffectedAppointments] = useState<IAppointment[]>([]);
    const saveDisabled = (
        site === null || hourGroups.length === 0 || !dateFrom
        || (!noEndDate && !dateTo) || personId === 'new'
    );
    useEffect(() => {
        (async () => {
            if (personId !== 'new') {
                if (deleteOpen && deleteId !== null) {
                    const hours = workHours.find((x) => x.id === deleteId);
                    const newList = workHours.filter((x) => x.location === hours.location && x.id !== deleteId);
                    const appointments = await BackendMethods.getChangePreview({
                        type: 'EMPL_WORK_HOURS',
                        details: {
                            employee: personId,
                            location: hours.location,
                            timezone: sites.find(R.propEq('id', hours.location)).timezone,
                            workingHours: newList,
                        },
                    });
                    setAffectedAppointments(appointments);
                } else if (!saveDisabled && typeof selectedHours === 'number') {
                    const newHours: INewWorkHours = {
                        location: site,
                        weekHours: hourGroups,
                        start: dateFrom.format('YYYY-MM-DD'),
                        end: dateTo ? dateTo.format('YYYY-MM-DD') : null,
                    };
                    const newList = workHours.map((x) => (
                        x.id === selectedHours ? { ...newHours, id: selectedHours } : x
                    )).filter((x) => (
                        x.location === site
                    ));
                    const appointments = await BackendMethods.getChangePreview({
                        type: 'EMPL_WORK_HOURS',
                        details: {
                            employee: personId,
                            location: site,
                            timezone: sites.find(R.propEq('id', site)).timezone,
                            workingHours: newList,
                        },
                    });
                    setAffectedAppointments(appointments);
                } else {
                    setAffectedAppointments([]);
                }
            }
        })();
    }, [personId, workHours, sites, selectedHours, site, hourGroups, dateFrom, dateTo, deleteId, deleteOpen]);
    const save = async () => {
        if (selectedHours === null) {
            return workHours;
        }
        if (saveDisabled || typeof personId !== 'number') {
            return false;
        }
        const newHours: INewWorkHours = {
            location: site,
            weekHours: hourGroups,
            start: dateFrom.format('YYYY-MM-DD'),
            end: dateTo ? dateTo.format('YYYY-MM-DD') : null,
        };
        if (selectedHours === 'new') {
            const { id } = await BackendMethods.addHours(personId, newHours);
            const newList = [
                ...workHours,
                { ...newHours, id },
            ];
            setWorkHours(newList);
            setSelectedHours(null);
            return newList;
        } else {
            await BackendMethods.updateHours(personId, selectedHours, newHours);
            const newList = workHours.map((x) => (
                x.id === selectedHours ? { ...newHours, id: selectedHours } : x
            ));
            await BackendMethods.flagAffectedAppointments({
                type: 'EMPL_WORK_HOURS',
                details: {
                    employee: personId,
                    location: site,
                    timezone: sites.find(R.propEq('id', site)).timezone,
                    workingHours: newList.filter((x) => x.location === site),
                },
            });
            setWorkHours(newList);
            setSelectedHours(null);
            return newList;
        }
    };
    const loadHours = (hours?: IWorkHours, copy?: boolean) => {
        if (hours) {
            if (!copy) {
                setSelectedHours(hours.id);
            }
            setSite(hours.location);
            setHourGroups(hours.weekHours);
            setDateFrom(moment(hours.start));
            setDateTo(hours.end ? moment(hours.end) : null);
            setNoEndDate(!hours.end);
        } else {
            if (!copy) {
                setSelectedHours('new');
            }
            setSite(null);
            setHourGroups([]);
            setDateFrom(null);
            setDateTo(null);
            setNoEndDate(false);
        }
        setCopyFrom(null);
        setDays([]);
        setHourFrom(midnight);
        setHourTo(midnight);
    };
    const formatHour = (hour: string) => moment(hour, 'HH:mm')
        .format(timeFormat === '12h' ? 'hh:mm a' : 'HH:mm');
    const formatDate = (date: string) => moment(date)
        .format(i18n.language === 'pl' ? 'DD.MM.Y' : 'MM/DD/Y');
    const renderGroupDays = (group: IHourGroup) => {
        return R.sortBy(
            (day) => day.weekday(),
            group.days.map((day) => (
                moment(day, 'dddd', 'en').locale(i18n.language)
            )),
        ).map((day) => (
            i18n.language === 'pl' ? day.format('dd').toLowerCase() : day.format('dd')
        )).join(', ');
    };
    const renderGroupHours = (group: IHourGroup) =>
        `${formatHour(group.from)} - ${formatHour(group.to)}`;
    const renderDay = (day: string) => {
        const momentDay = moment(day, 'dd', 'en').locale(i18n.language).format('dd');
        return i18n.language === 'pl' ? momentDay.toLowerCase() : momentDay;
    };
    return (
        <div className={styles.root}>
            <div className={styles.addHeader}>
                {t('small.workHours')}
                <Button
                    variant="contained"
                    className={styles.addButton}
                    onClick={() => loadHours()}
                    disabled={personId === 'new'}
                >
                    <img src="/assets/plus-white.svg" alt="" className={styles.addIcon} />
                    {t('small.addHours')}
                </Button>
            </div>
            <div className={styles.container}>
                <div className={styles.left}>
                    {workHours.map((hoursSet) => (
                        <div
                            key={hoursSet.id}
                            className={styles.hoursSet}
                            onClick={() => loadHours(hoursSet)}
                        >
                            <IconButton
                                onClick={async (event) => {
                                    event.stopPropagation();
                                    setDeleteId(hoursSet.id);
                                    setDeleteOpen(true);
                                }}
                                className={styles.delete}
                                size="large">
                                <img src="/assets/delete-blue.svg" alt="" />
                            </IconButton>
                            <div className={styles.header}>
                                {sites.find(R.propEq('id', hoursSet.location)).name}
                            </div>
                            <div className={styles.chips}>
                                {hoursSet.weekHours.map((group) => (
                                    <div className={styles.chip} key={group.id}>
                                        <span className={styles.groupDays}>
                                            {renderGroupDays(group)}
                                        </span>
                                        <span>
                                            {renderGroupHours(group)}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.header}>
                                {t('small.effective')}
                            </div>
                            <div className={styles.chips}>
                                <div className={styles.chip}>
                                    {formatDate(hoursSet.start)}
                                    {' - '}
                                    {hoursSet.end && formatDate(hoursSet.end)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {selectedHours !== null && (
                    <div className={styles.right}>
                        <Select
                            label={t('small.siteName')}
                            value={site
                                ? { value: site, label: sites.find(R.propEq('id', site)).name }
                                : null
                            }
                            onChange={option => setSite(option.value)}
                            options={sites.map((site2) => ({
                                value: site2.id,
                                label: site2.name,
                            }))}
                            className={styles.select}
                        />
                        <div className={styles.copyRow}>
                            <div className={styles.copyFrom}>
                                {t('small.copyFrom')}
                            </div>
                            <Select
                                label={t('small.copyName')}
                                value={copyFrom ? {
                                    value: copyFrom,
                                    label: people.find(R.propEq('id', copyFrom)).name,
                                } : null}
                                onChange={option => setCopyFrom(option.value)}
                                options={people.filter((person) => (
                                    person.locations.some((x) => x.location === site)
                                )).map((person) => ({
                                    value: person.id,
                                    label: person.name,
                                }))}
                                className={styles.select}
                            />
                            <Button
                                variant="contained"
                                className={styles.copyButton}
                                onClick={() => {
                                    if (copyFrom !== null) {
                                        const person = people.find(R.propEq('id', copyFrom));
                                        const hours = person.locations
                                            .find((x) => x.location === site);
                                        loadHours(hours, true);
                                    }
                                }}
                                disabled={copyFrom === null}
                            >
                                {t('small.copy')}
                            </Button>
                        </div>
                        <div className={styles.header}>
                            {t('small.workHours')}
                        </div>
                        <div className={styles.weekdays}>
                            {['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY',
                                'FRIDAY', 'SATURDAY', 'SUNDAY'].map((x: IWeekDay) => (
                                <Button
                                    key={x}
                                    variant={days.includes(x) ? 'contained' : 'text'}
                                    color={days.includes(x) ? 'secondary' : 'primary'}
                                    className={classNames(styles.day, {
                                        [styles.daySelected]: days.includes(x),
                                    })}
                                    onClick={() => setDays(
                                        days.includes(x)
                                            ? days.filter((y) => y !== x)
                                            : [...days, x],
                                    )}
                                >
                                    {renderDay(x)}
                                </Button>
                            ))}
                        </div>
                        <div className={styles.hoursRow}>
                            <TimePicker
                                value={hourFrom}
                                onChange={setHourFrom}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classNames(params.className, styles.hourField)}
                                        helperText=""
                                    />
                                )}
                            />
                            <div className={styles.hyphen}>-</div>
                            <TimePicker
                                value={hourTo}
                                onChange={setHourTo}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classNames(params.className, styles.hourField)}
                                        helperText=""
                                    />
                                )}
                            />
                            <Button
                                variant="text"
                                className={styles.hoursCancel}
                                onClick={() => {
                                    setDays([]);
                                    setHourFrom(null);
                                    setHourTo(null);
                                }}
                            >
                                {t('common.cancel')}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setHourGroups([
                                        ...hourGroups,
                                        {
                                            id: Math.round(Math.random() * 1e8),
                                            days,
                                            from: hourFrom.format('HH:mm'),
                                            to: hourTo.format('HH:mm'),
                                        },
                                    ]);
                                    setDays([]);
                                    setHourFrom(midnight);
                                    setHourTo(midnight);
                                }}
                                disabled={days.length === 0 || !hourFrom || !hourTo}
                            >
                                {t('small.add')}
                            </Button>
                        </div>
                        <div className={styles.chips}>
                            {hourGroups.map((group) => (
                                <div key={group.id} className={styles.interactiveChip}>
                                    <span className={styles.groupDays}>
                                        {renderGroupDays(group)}
                                    </span>
                                    <span>
                                        {renderGroupHours(group)}
                                    </span>
                                    <IconButton
                                        onClick={() => {
                                            setHourGroups(hourGroups.filter((x) => (
                                                x.id !== group.id
                                            )));
                                        }}
                                        size="small"
                                        className={styles.chipDelete}
                                    >
                                        <img src="/assets/delete-blue.svg" alt="" />
                                    </IconButton>
                                </div>
                            ))}
                        </div>
                        <div className={styles.header}>
                            {t('small.effective')}
                        </div>
                        <div className={styles.effectiveRow}>
                            <DatePicker
                                value={dateFrom}
                                onChange={setDateFrom}
                                label={t('small.from')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classNames(params.className, styles.dateField)}
                                        helperText=""
                                        inputProps={{ ...params.inputProps, placeholder: language === 'pl' ? 'dd.mm.rrrr' : 'mm/dd/yyyy' }}
                                    />
                                )}
                            />
                            <DatePicker
                                value={dateTo}
                                onChange={setDateTo}
                                label={t('small.to')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classNames(params.className, styles.dateField)}
                                        helperText=""
                                        inputProps={{ ...params.inputProps, placeholder: language === 'pl' ? 'dd.mm.rrrr' : 'mm/dd/yyyy' }}
                                    />
                                )}
                                disabled={noEndDate}
                            />
                            <FormControlLabel
                                checked={noEndDate}
                                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                                    setNoEndDate(target.checked);
                                    if (target.checked) {
                                        setDateTo(null);
                                    }
                                }}
                                control={<Checkbox color="primary" />}
                                label={t('small.noEndDate')}
                            />
                        </div>
                        <div className={styles.saveRow}>
                            <Button
                                variant="text"
                                onClick={() => setSelectedHours(null)}
                                className={styles.discardChanges}
                            >
                                {t('small.discardChanges')}
                            </Button>
                            <AffectedAppointments appointments={affectedAppointments} />
                            <Button
                                variant="contained"
                                onClick={save}
                                disabled={saveDisabled}
                            >
                                {t('small.save')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                classes={{ paper: styles.dialog }}
            >
                <div className={styles.dialogText}>
                    {t('small.deleteHoursConfirm')}
                </div>
                <div className={styles.dialogActions}>
                    <AffectedAppointments appointments={affectedAppointments} />
                    <Button
                        className={styles.dialogDeleteButton}
                        onClick={async () => {
                            if (personId !== 'new') {
                                await BackendMethods.deleteHours(personId, deleteId);
                                const hours = workHours.find((x) => x.id === deleteId);
                                const newList = workHours.filter((x) => x.location === hours.location && x.id !== deleteId);
                                await BackendMethods.flagAffectedAppointments({
                                    type: 'EMPL_WORK_HOURS',
                                    details: {
                                        employee: personId,
                                        location: hours.location,
                                        timezone: sites.find(R.propEq('id', hours.location)).timezone,
                                        workingHours: newList,
                                    },
                                });
                                if (selectedHours === deleteId) {
                                    setSelectedHours(null);
                                }
                                setWorkHours(workHours.filter((x) => x.id !== deleteId));
                                setDeleteOpen(false);
                            }
                        }}
                    >
                        {t('small.delete')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setDeleteOpen(false)}
                    >
                        {t('common.cancel')}
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}
