// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__1XDpe {\n    padding: 16px 32px;\n    margin: 44px auto 0;\n    width: 100%;\n    max-width: 425px;\n    display: flex;\n    flex-direction: column;\n}\n\n.header__3LF9- {\n    font-size: 20px;\n    margin-bottom: 32px;\n}\n\n.logo__2NHiv {\n    height: 100px;\n    width: auto;\n    margin: 0 auto 32px;\n}\n", "",{"version":3,"sources":["PlusPlanInfo.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;AACvB","file":"PlusPlanInfo.css","sourcesContent":[".root {\n    padding: 16px 32px;\n    margin: 44px auto 0;\n    width: 100%;\n    max-width: 425px;\n    display: flex;\n    flex-direction: column;\n}\n\n.header {\n    font-size: 20px;\n    margin-bottom: 32px;\n}\n\n.logo {\n    height: 100px;\n    width: auto;\n    margin: 0 auto 32px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__1XDpe",
	"header": "header__3LF9-",
	"logo": "logo__2NHiv"
};
module.exports = exports;
