import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import TextField from '../../TextField/TextField';
import { useEffect, useRef } from 'react';

const styles = require('./HeaderSearch.css');

interface Props {
    search: string;
    setSearch: (search: string) => void;
    hideSearch: () => void;
    hidden: boolean;
    expanded: boolean;
}

export default function HeaderSearch(props: Props) {
    const { t } = useTranslation();
    const { search, setSearch, hidden, expanded, hideSearch } = props;
    const isOutlook = sessionStorage.getItem('outlook') === '1';
    const inputEl = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (!hidden && inputEl.current) {
            inputEl.current.focus();
        }
    }, [hidden]);
    return (
        <div
            className={classNames(styles.root, {
                [styles.hidden]: hidden,
                [styles.outlook]: isOutlook,
                [styles.expanded]: expanded,
            })}
            data-testid="header-search"
        >
            <TextField
                id="SearchInput"
                placeholder={t('home.search')}
                onKeyDown={(event) => {
                    if (event.key === 'Escape') {
                        hideSearch();
                    }
                }}
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                inputRef={inputEl}
                className={styles.input}
                margin="none"
            />
            <i id="CloseSearchIcon" onClick={hideSearch} />
        </div>
    );
}
