// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".IconHolder___6d3L {\n    align-items: center;\n    background: transparent;\n    color: #fff;\n    display: flex;\n    height: 700px;\n    justify-content: center;\n    opacity: 0.8;\n    width: 100%;\n}\n\n.IconHolder___6d3L img {\n    height: 40px;\n}\n\n.IconHolder___6d3L:hover {\n    opacity: 0.6;\n}\n", "",{"version":3,"sources":["Playback.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","file":"Playback.css","sourcesContent":[".IconHolder {\n    align-items: center;\n    background: transparent;\n    color: #fff;\n    display: flex;\n    height: 700px;\n    justify-content: center;\n    opacity: 0.8;\n    width: 100%;\n}\n\n.IconHolder img {\n    height: 40px;\n}\n\n.IconHolder:hover {\n    opacity: 0.6;\n}\n"]}]);
// Exports
exports.locals = {
	"IconHolder": "IconHolder___6d3L"
};
module.exports = exports;
