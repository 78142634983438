import * as React from 'react';
import classNames from 'classnames';
const $ = require('jquery');
import IActions from 'actions/IActions';

const Styles = require('./CustomMessage.css');

interface Props {
    app: any;
    actions: IActions;
    type: 'Success' | 'Error';
}

export default class CustomMessage extends React.Component<Props, null> {
    HIDE_MESSAGE_AFTER_TIME_IN_MILLIS: number = 9000;
    timeOfLastMessageInMillis: number;
    currentKey: number;
    checkTextHeightInterval;

    componentDidMount() {
        this.setCurrentKey();
        this.checkTextHeightInterval = setInterval(() => {
            const errorMessageText: any = this.refs['errorMessageText'];

            if (!errorMessageText) {
                return;
            }

            if (errorMessageText.clientHeight < 30) {
                $(errorMessageText).css({
                    'margin-top': '12px'
                });
            } else {
                $(errorMessageText).css({
                    'margin-top': ''
                });
            }
        }, 25);
    }

    componentWillUnmount() {
        clearInterval(this.checkTextHeightInterval);
    }

    setCurrentKey(callback?: Function) {
        let newCurrentKey;

        const randomizeKey = () => {
            newCurrentKey = Math.random() * new Date().getTime();
        };

        randomizeKey();

        if (this.currentKey === newCurrentKey) {
            this.setCurrentKey(callback);
        } else {
            this.currentKey = newCurrentKey;
            if (callback) {
                callback();
            }
        }
    }

    hideCustomSuccessMessage = () => {
        this.setCurrentKey(() => {
            this.timeOfLastMessageInMillis = 1;
            this.props.actions.hideErrorMessage();
            this.forceUpdate();
        });
    };

    hideCustomErrorMessage = () => {
        this.setCurrentKey(() => {
            this.timeOfLastMessageInMillis = 1;
            this.props.actions.hideErrorMessage();
            this.forceUpdate();
        });
    };

    updateSuccessMessage() {
        if (this.props.app.get('displaySuccessMessage') === true) {
            this.setCurrentKey(() => {
                this.timeOfLastMessageInMillis = new Date().getTime();
                const {
                    actions: { hideSuccessMessage }
                } = this.props;
                hideSuccessMessage();
                setTimeout(() => {
                    this.forceUpdate();
                }, this.HIDE_MESSAGE_AFTER_TIME_IN_MILLIS);
            });
        }
    }

    updateErrorMessage() {
        if (this.props.app.get('displayErrorMessage') === true) {
            this.setCurrentKey(() => {
                this.timeOfLastMessageInMillis = new Date().getTime();
                const {
                    actions: { hideErrorMessage }
                } = this.props;
                hideErrorMessage();
                setTimeout(() => {
                    this.forceUpdate();
                }, this.HIDE_MESSAGE_AFTER_TIME_IN_MILLIS);
            });
        }
    }

    componentDidUpdate() {
        if (this.props.type === 'Success') {
            this.updateSuccessMessage();
        } else {
            this.updateErrorMessage();
        }
    }

    render() {
        const currentTimeInMillis = new Date().getTime();
        const showMessage: boolean =
            this.timeOfLastMessageInMillis &&
            currentTimeInMillis - this.timeOfLastMessageInMillis <
                this.HIDE_MESSAGE_AFTER_TIME_IN_MILLIS;

        if (showMessage === true && this.props.type === 'Success') {
            return (
                <div
                    key={this.currentKey}
                    id="tanbuu-success-message"
                    className={classNames(
                        Styles.CustomMessage,
                        Styles.CustomSuccessMessage
                    )}
                >
                    <span
                        ref="errorMessageText"
                        className={classNames(Styles.Text)}
                    >
                        {this.props.app.get('successMessageText')}
                    </span>
                    <img
                        src="/assets/message-close.svg"
                        className={Styles.CloseIcon}
                        onClick={this.hideCustomSuccessMessage}
                    />
                </div>
            );
        } else if (showMessage === true && this.props.type === 'Error') {
            return (
                <div
                    key={this.currentKey}
                    id="tanbuu-error-message"
                    className={classNames(
                        Styles.CustomMessage,
                        Styles.CustomErrorMessage
                    )}
                >
                    <span
                        ref="errorMessageText"
                        className={classNames(Styles.Text)}
                    >
                        {this.props.app.get('errorMessageText')}
                    </span>
                    <img
                        src="/assets/message-close.svg"
                        className={Styles.CloseIcon}
                        onClick={this.hideCustomErrorMessage}
                    />
                </div>
            );
        } else {
            return null;
        }
    }
}
