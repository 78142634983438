import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import TextField from 'components/_ReusableComponents/TextField/TextField';
import Button from '@material-ui/core/Button';
import { IReviews } from 'interfaces/ICompaniesRating';
import BackendMethods from 'api/BackendMethods';
import { useIsDesktop } from 'utils/responsive';
import { useTranslation } from 'react-i18next';

interface IResponseProps {
    nowReview: IReviews;
    getRating: any;
}
export const Response = ({ nowReview, getRating }: IResponseProps) => {
    const isDesktop = useIsDesktop();
    const [answer, setAnswer] = useState<string>('');
    const {t} = useTranslation();

    useEffect(() => {
        setAnswer(nowReview.response ? nowReview.response.content : '');
    }, [nowReview]);

    const submmitAnswer = async () => {
        try {
            await BackendMethods.submitAnswer({
                id: nowReview.id,
                answer: answer
            });
            getRating();
        } catch (error) {
        }
    };

    return (
        <div className={ isDesktop? 'p-t-36': 'p-18'}>
            <TextField
                value={answer}
                onChange={({ target }) => {
                    target.value.length > 5000 ? setAnswer(target.value.slice(0, 5000)) : setAnswer(target.value);
                }}
                multiline
                rows={4}
                label={'Twoja odpowiedź'}
                fullWidth
            />
            <div className={classNames('text-right', 'm-t-20')}>
                <Button
                    className={classNames('blue-btn')}
                    onClick={submmitAnswer}
                    disabled={answer === ''}
                >
                    {t('small.reply')}
                </Button>
            </div>
        </div>
    );
};
