import React, { useState } from 'react';
import * as R from 'ramda';
import YouLabel from 'components/_ReusableComponents/Label/YouLabel';
import OrganizerLabel from 'components/_ReusableComponents/Label/OrganizerLabel';
import moment from 'moment';
import { Collapse, IconButton, Tooltip } from '@material-ui/core';
import TimeZoneSelect from '../../../_ReusableComponents/TimeZoneSelect/TimeZoneSelect';
import BackendMethods from '../../../../api/BackendMethods';
import IActions from '../../../../actions/IActions';
import getRanges from '../../../../utils/dateRange';
import formatDuration from '../../../../utils/duration';
import IMeeting from '../../../../interfaces/IMeeting';
import { useTranslation } from 'react-i18next';
const styles = require('./MeetingParticipants.css');

interface Props {
    email: string;
    canEditTimezone: boolean;
    actions: IActions;
    defaultVisible?: boolean;
    meeting: IMeeting;
}

function YouAndOrganizerLabels(props: {
    email: string;
    organizerEmail: string;
    userEmail: string;
}) {
    return (
        <span>
            {props.userEmail === props.email && <YouLabel {...props} />}
            {props.email === props.organizerEmail && <OrganizerLabel {...props} />}
        </span>
    );
}

export default function MeetingParticipants(props: Props) {
    const { t, i18n } = useTranslation();
    const { meeting } = props;
    const [visible, setVisible] = useState(props.defaultVisible !== undefined ? props.defaultVisible : false);
    const [timezoneSelectOpen, setTimezoneSelectOpen] = useState(false);
    const onTimezoneChange = async (newTimezone) => {
        setTimezoneSelectOpen(false);
        const newMeeting = await BackendMethods.setMeetingTimezone(meeting.id, newTimezone);
        props.actions.updateMeeting(newMeeting, props.email);
    };
    const { timezone } = meeting.participants.find(R.whereEq({ email: props.email }));
    const { renderRange } = getRanges(timezone, t, i18n.language);
    const otherParticipant = meeting.participants.find((x) => x.email !== props.email);
    return (
        <div className={styles.MeetingParticipants}>
            <div
                className={styles.MeetingParticipants_Header}
                onClick={() => setVisible(R.not)}
            >
                {!(meeting.isOrganizer && i18n.language === 'pl') && (
                    <span className={styles.label}>
                        {meeting.isOrganizer
                            ? t('home.you')
                            : (meeting.organizer.name || meeting.organizer.email)}
                    </span>
                )}
                {' '}
                {meeting.isOrganizer ? t('home.wantToMeet') : t('home.wantsToMeet')}
                {' '}
                <b>
                    {meeting.isOrganizer
                        ? (otherParticipant.name || otherParticipant.email)
                        : t('home.withYou')
                    }
                    {meeting.participants.length > 2 && ` +${meeting.participants.length - 2}`}
                </b>
            </div>
            <Collapse in={visible}>
                <div className={styles.ParticipantsList}>
                    {meeting.participants.map((participant, index) => (
                        <div
                            className={styles.ParticipantsList__Participant}
                            key={index}
                        >
                              <span className={styles.ParticipantsList__Participant__Text}>
                                  {participant.name || participant.email}
                              </span>
                            <YouAndOrganizerLabels
                                email={participant.email}
                                organizerEmail={meeting.organizer.email}
                                userEmail={props.email}
                            />
                            {participant.optional && (
                                <span className={styles.optionalLabel}>
                                    {t('new.optional')}
                                </span>
                            )}
                            {participant.declined && (
                                <span className={styles.declinedLabel}>
                                    {t('home.declined')}
                                </span>
                            )}
                            <div className={styles.participantTimezone}>
                                {participant.timezone}
                                {participant.email === props.email && props.canEditTimezone && (
                                    <>
                                        <IconButton
                                            onClick={() => setTimezoneSelectOpen(true)}
                                            className={styles.timezoneEditButton}
                                            size="large">
                                            <img src="/assets/pen.svg" />
                                        </IconButton>
                                        {participant.timezone !== moment.tz.guess() && (
                                            <Tooltip
                                                title={t('home.timezoneMismatch')}
                                                placement="right"
                                                enterTouchDelay={0}
                                                classes={{ tooltip: styles.tooltip }}
                                            >
                                                <div className={styles.warningIcon} />
                                            </Tooltip>
                                        )}
                                        {timezoneSelectOpen && (
                                            <div className={styles.timezoneSelect}>
                                                <TimeZoneSelect
                                                    onChange={onTimezoneChange}
                                                    onBlur={() => setTimezoneSelectOpen(false)}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </Collapse>
            {meeting.state !== 'SCHEDULED' && (
                <div className={styles.duration}>
                    {t('home.forDuration')}
                    {' '}
                    <b>
                        {formatDuration(moment.duration(meeting.duration, 'minutes'), t)}
                    </b>
                    {', '}
                    {renderRange({
                        dateFrom: meeting.after,
                        dateTo: meeting.before,
                    })}
                </div>
            )}
        </div>
    );
}
