import * as React from 'react';

const Styles = require('./Playback.css');

interface IPlaybackProps {
    onClick: () => void;
    iconType: string;
}

export default class Playback extends React.Component<IPlaybackProps, null> {

    render(): JSX.Element {
        return(
            <div className={Styles.IconHolder} onClick={this.props.onClick}>
                {this.props.iconType === 'play' &&
                    <img src="/assets/landingAnimation/play_icon.svg" />
                }
                {this.props.iconType === 'restart' &&
                    <img src="/assets/landingAnimation/play_again_icon.svg" />
                }
            </div>
        );
    }
}
