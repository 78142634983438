import './wdyr.ts';
import '@babel/polyfill';
import 'ts-helpers';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import routes from './routes';
import configureStore from './store/configure-store';
import App from './containers/App';
import './styles/index.css';
import './favicon.ico';
import { BrowserRouter as Router } from 'react-router-dom';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import * as Sentry from '@sentry/browser';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import smoothscroll from 'smoothscroll-polyfill';
import i18next from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/pl';
import CookieDisclaimer from './components/_ReusableComponents/CookieDisclaimer/CookieDisclaimer';
import { Provider } from 'react-redux';
const pl = require('./translations/pl.yaml');
const en = require('./translations/en.yaml');
const config = require('CONFIG');

Sentry.init({ dsn: config.sentryDsn });

smoothscroll.polyfill();

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js');
}

i18next
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            pl: {
                translation: pl,
            },
            en: {
                translation: en,
            },
        },
        detection: {
            order: ['localStorage', 'navigator', 'path'],
        },
        whitelist: ['pl', 'en'],
        fallbackLng: 'en',
    }, () => {});
i18next.on('languageChanged', (lng) => {
    moment.locale(lng);
    document.documentElement.setAttribute('lang', lng);
});

const store = configureStore({});

ReactDOM.render(
    <Provider store={store}>
        <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Router>
                    <App>
                        {routes}
                        <CookieDisclaimer />
                    </App>
                </Router>
            </LocalizationProvider>
        </StyledEngineProvider>
    </Provider>,
    document.getElementById('root')
);
