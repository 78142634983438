import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import containersDispatchToProps from '../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../utils/containersMapStateToProps';
import CustomMessage from 'components/_ReusableComponents/CustomMessage/CustomMessage';
import IActions from 'actions/IActions';
import { getAuthToken, getSearchParam, isAdHoc, isLoggedIn, parseJwt } from '../scripts/helpers';
import auth from '../auth/Auth';
import FallingLeaves from '../components/_ReusableComponents/FallingLeaves/FallingLeaves';
import { isMobileBrowser } from '../utils/BrowserDetect';
import InstallModal from '../components/_ReusableComponents/Modals/InstallModal/InstallModal';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { createTheme, Slide, ThemeProvider } from '@material-ui/core';
import { enUS, plPL } from '@material-ui/core/locale';
import MobileMenu from '../components/_ReusableComponents/MobileMenu/MobileMenu';
import { useIsDesktop } from '../utils/responsive';
import { Route, useHistory, useRouteMatch } from 'react-router';
import inIframe from 'utils/inIframe';
import { pickersDayClasses } from '@material-ui/lab';
import SupportModal from '../components/_ReusableComponents/Modals/SupportModal/SupportModal';
import GlobalContext, { IGlobalContextData } from '../GlobalContext';
import { fetchProfile } from '../actions/DataActions';
import useAsyncEffect from '../utils/useAsyncEffect';
import { getSubdomain } from '../utils/subdomain';
import BackendMethods from '../api/BackendMethods';
import { Redirect } from 'react-router-dom';
import NoSubdomain from './NoSubdomain';
const config = require('CONFIG');

const styles = require('./App.css');

interface Props {
    session: any;
    app: any;
    meeting: any;
    preferences: any;
    actions: IActions;
    login: () => void;
    logout: () => void;
    children: any;
    data: any;
}

function App(props: Props) {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        moment.locale(i18n.language);
        document.documentElement.setAttribute('lang', i18n.language);
    }, [i18n.language]);
    const [ready, setReady] = useState(false);
    useEffect(() => {
        if (inIframe()) {
            window.parent.postMessage(JSON.stringify({
                loggedIn: Boolean(isAdHoc() || isLoggedIn()),
            }), config.outlookOrigin);
        }
    }, []);
    const history = useHistory();
    useEffect(() => {
        const listener = (event: MessageEvent) => {
            if (event.origin === config.outlookOrigin) {
                try {
                    const data = JSON.parse(event.data);
                    if ('navigate' in data) {
                        history.push(data.navigate);
                    }
                } catch {}
            }
        };
        window.addEventListener('message', listener);
        return () => window.removeEventListener('message', listener);
    }, [history]);
    const isSbCustomer = useRouteMatch('/sb-customer/*');
    const [globalContext, setGlobalContext] = useState<IGlobalContextData>({ profile: null });
    useAsyncEffect(async () => {
        props.actions.fetchPublicData();
        if (isAdHoc()) {
            const { email } = parseJwt(getAuthToken());
            props.actions.updateData({ workWeek: [] });
            if (isSbCustomer) {
                const profile = await fetchProfile(i18n);
                setGlobalContext((x) => ({ ...x, profile }));
            } else {
                setGlobalContext((x) => ({
                    ...x,
                    profile: {
                        email,
                        firstName: '',
                        lastName: '',
                        company: '',
                        plusPlan: { active: false },
                        appointments: {},
                        timeFormat: new Intl.DateTimeFormat(
                            navigator.language,
                            { hour: 'numeric' },
                        ).resolvedOptions().hour12 ? '12h' : '24h',
                    },
                }));
                await props.actions.refreshMeetings(email);
                props.actions.pushConnect(email, setGlobalContext);
            }
        } else if (isLoggedIn()) {
            auth.renewSession();
            let profilePromise = fetchProfile(i18n);
            await props.actions.dbConnect(profilePromise);
            const profile = await profilePromise;
            setGlobalContext((x) => ({ ...x, profile }));
            props.actions.pushConnect(profile.email, setGlobalContext);
        }
        setReady(true);
    }, []);
    const [noSubdomain, setNoSubdomain] = useState(false);
    useAsyncEffect(async () => {
        if (getSubdomain() !== null) {
            try {
                await BackendMethods.checkSubdomain(getSubdomain());
            } catch (error) {
                if (error?.response?.status === 404) {
                    setNoSubdomain(true);
                }
            }
        }
    }, []);
    const loggedIn = Boolean(globalContext.profile);
    const [installPrompt, setInstallPrompt] = useState(null);
    const [installPopup, setInstallPopup] = useState(false);
    useEffect(() => {
        const handler = (event) => {
            event.preventDefault();
            setInstallPrompt(event);
        };
        window.addEventListener('beforeinstallprompt', handler);
        return () => window.removeEventListener('beforeinstallprompt', handler);
    }, []);
    useEffect(() => {
        // @ts-ignore
        const { userAgent, standalone } = window.navigator;
        const isMobileSafari = /iP(hone|ad|od)/.test(userAgent)
            && !/(FxiOS|CriOS|OPT|EdgiOS|DuckDuckGo)/.test(userAgent);
        if (localStorage.getItem('install-popup-shown') === null && isMobileBrowser()
            && loggedIn && (installPrompt || isMobileSafari) && !standalone) {
            setInstallPopup(true);
        }
    }, [loggedIn, installPrompt]);
    useEffect(() => {
        const outlook = getSearchParam(location.search, 'outlook');
        if (outlook) {
            sessionStorage.setItem('outlook', '1');
        }
    }, []);

    const isDesktop = useIsDesktop();

    const { language } = i18n;
    const theme = createTheme({
        palette: {
            primary: {
                main: '#08a'
            },
            secondary: {
                main: '#013253'
            }
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: 16,
                        textTransform: 'none',
                    },
                    contained: {
                        boxShadow: 'none',
                        '&:active, &$focusVisible': {
                            boxShadow: 'none'
                        },
                        '&$disabled': {
                            backgroundColor: '#e0e0e0',
                            color: '#939393'
                        }
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderRadius: 15
                    },
                    paperFullScreen: {
                        height: 'calc(100% - 44px)',
                        marginTop: 44
                    },
                },
            },
            MuiDialogContentText: {
                styleOverrides: {
                    root: {
                        fontSize: 18,
                        color: 'inherit'
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: '0 24px 20px'
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        color: '#013253'
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        '&$selected': {
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            '&$focusVisible': {
                                backgroundColor: 'rgba(0, 0, 0, 0.14)',
                            },
                        },
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    iconOpen: {
                        transform: 'rotate(90deg)',
                    },
                    iconOutlined: {
                        right: 5,
                    },
                    icon: {
                        top: 'calc(50% - 17px)',
                    },
                },
            },
            // @ts-ignore
            MuiPickerView: {
                styleOverrides: {
                    root: {
                        position: 'relative',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: 'white !important',
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        backgroundColor: 'white',
                    },
                    flexContainer: {
                        backgroundColor: '#08a',
                    },
                },
            },
            MuiMobileDateTimePicker: {
                defaultProps: {
                    cancelText: t('common.cancel'),
                    inputFormat: `${language === 'pl' ? 'D MMM.' : 'MMM. D,'} Y h:mm a`,
                    showDaysOutsideCurrentMonth: true,
                },
            },
            MuiCalendarPicker: {
                styleOverrides: {
                    root: {
                        overflowY: 'hidden',
                        minHeight: 242,
                        maxHeight: 360,
                        '& > div:nth-of-type(1) > div:nth-of-type(1)': {
                            fontSize: 20,
                        },
                    },
                    viewTransitionContainer: {
                        '& > div > div:nth-of-type(2)': {
                            minHeight: 262,
                        },
                        '& > div:nth-of-type(1) > div:nth-of-type(1) > span': {
                            fontSize: 16,
                            fontWeight: '400 !important',
                            color: '#013253',
                            width: 42,
                            height: 42,
                            '&::after': {
                                display: 'inline',
                            },
                            '&:nth-of-type(1)::after': {
                                content: `"${language === 'pl' ? 'n' : 'u'}"`,
                            },
                            '&:nth-of-type(2)::after': {
                                content: `"${language === 'pl' ? 't' : 'o'}"`,
                            },
                            '&:nth-of-type(3)::after': {
                                content: `"${language === 'pl' ? 'r' : 'u'}"`,
                            },
                            '&:nth-of-type(4)::after': {
                                content: `"${language === 'pl' ? 'z' : 'e'}"`,
                            },
                            '&:nth-of-type(5)::after': {
                                content: `"${language === 'pl' ? 't' : 'h'}"`,
                            },
                            '&:nth-of-type(6)::after': {
                                content: `"${language === 'pl' ? 'o' : 'r'}"`,
                            },
                            '&:nth-of-type(7)::after': {
                                content: `"${language === 'pl' ? 'd' : 'a'}"`,
                            },
                        },
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                        color: '#08a',
                        fontSize: 20,
                        fontWeight: '400 !important',
                        width: 40,
                        height: 36,
                        margin: '1px 3px',
                        borderRadius: 3,
                        [`&.${pickersDayClasses.disabled}`]: {
                            color: '#9E9E9E',
                        },
                        [`&.${pickersDayClasses.selected}`]: {
                            backgroundColor: '#013253',
                            '&:focus': {
                                backgroundColor: '#013253',
                            },
                        }
                    },
                },
            },
            PrivatePicker: {
                styleOverrides: {
                    root: {
                        '& > div': {
                            maxHeight: 360,
                        },
                    },
                },
            },
            MuiDatePicker: {
                defaultProps: {
                    showDaysOutsideCurrentMonth: true,
                    views: ['year', 'month', 'day'],
                    mask: language === 'pl' ? '__.__.____' : '__/__/____',
                    openPickerIcon: <img src="/assets/calendar.svg" alt="" />,
                    components: {
                        LeftArrowIcon: () => (
                            <img src="/assets/chevron-blue.svg" alt="" className={styles.chevronLeft} />
                        ),
                        RightArrowIcon: () => (
                            <img src="/assets/chevron-blue.svg" alt="" className={styles.chevronRight} />
                        ),
                    },
                },
            },
            MuiStaticDatePicker: {
                defaultProps: {
                    showDaysOutsideCurrentMonth: true,
                    displayStaticWrapperAs: 'desktop',
                    components: {
                        LeftArrowIcon: () => (
                            <img src="/assets/chevron-blue.svg" alt="" className={styles.chevronLeft} />
                        ),
                        RightArrowIcon: () => (
                            <img src="/assets/chevron-blue.svg" alt="" className={styles.chevronRight} />
                        ),
                    },
                },
            },
        },
    }, language === 'pl' ? plPL : enUS);
    if (!ready) {
        return <FallingLeaves />;
    }
    const isMenuVisible = props.app.get('isMenuVisible');
    const menu = (
        <MobileMenu
            actions={props.actions}
            profile={globalContext.profile}
            data={props.data}
            collapsed={!isMenuVisible}
        />
    );
    return (
        <GlobalContext.Provider
            value={{
                ...globalContext,
                updateContext: setGlobalContext,
            }}
        >
            <ThemeProvider theme={theme}>
                <div>
                    {!isAdHoc() && !isDesktop && (
                        <Route
                            path={[
                                '/main/:id?',
                                '/meeting/:id',
                                '/history',
                                '/new',
                                '/edit/:id',
                                '/preferences',
                                '/upgradePlan',
                                '/payment-failed',
                                '/payment-success',
                                '/licenses',
                                '/team',
                                '/billing',
                                '/manage',
                                '/plan-info',
                                '/sb/*',
                                '/sb-customer/*',
                                '/suggestion/*',
                            ]}
                            render={() => (
                                <div>
                                    <Slide
                                        in={isMenuVisible}
                                        direction="right"
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        {menu}
                                    </Slide>
                                </div>
                            )}
                        />
                    )}
                    {noSubdomain ? <NoSubdomain /> : props.children}
                    <CustomMessage {...props} type="Error" />
                    <CustomMessage {...props} type="Success" />
                    {props.app.get('clickEventsDisable') === true && (
                        <div className={styles.DisableClickEvents} />
                    )}
                    <InstallModal
                        open={installPopup}
                        prompt={installPrompt}
                        onClose={() => {
                            localStorage.setItem('install-popup-shown', 'true');
                            setInstallPopup(false);
                        }}
                    />
                    {globalContext.profile && (
                        <SupportModal
                            app={props.app}
                            actions={props.actions}
                            open={props.app.get('isSupportModalVisible')}
                        />
                    )}
                </div>
            </ThemeProvider>
        </GlobalContext.Provider>
    );
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(App) as any;
