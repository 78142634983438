// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__10At2 {\n    max-width: 600px;\n    margin: 44px auto 300px;\n    padding: 8px 16px;\n}\n\n.note__lVZ_k {\n    margin-top: 25px;\n}\n\n.title__2nevi {\n    font-size: 20px;\n    text-align: center;\n    color: #013253;\n    margin-top: 41px;\n    margin-bottom: 32px;\n    font-family: Source Sans Pro;\n    font-stretch: normal;\n}\n\n.sendButton__2Xm6g {\n    margin-top: 38px;\n    border-radius: 5px;\n    height: 36px;\n    min-width: 162px;\n    padding: 8px 24px 6px 25px;\n    float: right;\n}\n", "",{"version":3,"sources":["SuggestionPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,4BAA4B;IAC5B,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,0BAA0B;IAC1B,YAAY;AAChB","file":"SuggestionPage.css","sourcesContent":[".root {\n    max-width: 600px;\n    margin: 44px auto 300px;\n    padding: 8px 16px;\n}\n\n.note {\n    margin-top: 25px;\n}\n\n.title {\n    font-size: 20px;\n    text-align: center;\n    color: #013253;\n    margin-top: 41px;\n    margin-bottom: 32px;\n    font-family: Source Sans Pro;\n    font-stretch: normal;\n}\n\n.sendButton {\n    margin-top: 38px;\n    border-radius: 5px;\n    height: 36px;\n    min-width: 162px;\n    padding: 8px 24px 6px 25px;\n    float: right;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__10At2",
	"note": "note__lVZ_k",
	"title": "title__2nevi",
	"sendButton": "sendButton__2Xm6g"
};
module.exports = exports;
