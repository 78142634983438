import React from 'react';
import { Dialog } from '@material-ui/core';
import Button from '../../Button/Button';
import { Trans, useTranslation } from 'react-i18next';
const styles = require('./InstallModal.css');

interface Props {
    open: boolean;
    prompt: any;
    onClose: () => void;
}

function InstallModal(props: Props) {
    const { t } = useTranslation();
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{ className: styles.paper }}
        >
            <div className={styles.main}>
                <img
                    src="/assets/app-icon.png"
                    alt="App icon"
                    className={styles.icon}
                />
                <div className={styles.text}>
                    <Trans
                        i18nKey={props.prompt ? 'home.addTanbuuAndroid' : 'home.addTanbuuSafari'}
                        components={[<br />]}
                    />
                </div>
            </div>
            {props.prompt ? (
                <div className={styles.buttons}>
                    <Button
                        onClick={props.onClose}
                        className={styles.button}
                    >
                        {t('home.doNotAdd')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            props.prompt.prompt();
                            props.onClose();
                        }}
                        className={styles.button}
                    >
                        {t('home.add')}
                    </Button>
                </div>
            ) : (
                <>
                    <div className={styles.row}>
                        <img src="/assets/safari-share.svg" alt="" className={styles.shareIcon} />
                        {t('home.tapShare')}
                    </div>
                    <div className={styles.row}>
                        <img src="/assets/safari-add.svg" alt="" className={styles.addIcon} />
                        {t('home.chooseAdd')}
                    </div>
                </>
            )}
        </Dialog>
    );
}

export default InstallModal;
