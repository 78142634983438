import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import BackendMethods from '../../../api/BackendMethods';
import { IAppointment, ICustomer } from './Dashboard';
import { IHeaderProps } from '../../../components/_ReusableComponents/Header/Header/Header';
import Button from '../../../components/_ReusableComponents/Button/Button';
import classNames from 'classnames';
import TextField from '../../../components/_ReusableComponents/TextField/TextField';
import { IconButton, InputAdornment, Fab, Dialog, RadioGroup, FormControlLabel, Radio, Checkbox, Tooltip } from '@material-ui/core';
import * as R from 'ramda';
import PhoneNumber from 'awesome-phonenumber';
import { Link, Redirect, useParams } from 'react-router-dom';
import CustomerData from './CustomerData';
import IProfile from '../../../interfaces/IProfile';
import MobileCalendar from '../MobileCalendar';
import WeekCalendar from '../../../components/MainPageComponents/WeekCalendar/WeekCalendar';
import { renderPrice } from '../common';
import { redirectToBaseDomain } from '../../../utils/subdomain';
import { IPerson } from '../Settings/Personnel';
import scrollIntoView from 'scroll-into-view';
import { isAdHoc, isLoggedIn } from '../../../scripts/helpers';
import useDebouncedEffect from 'use-debounced-effect';
import pickColorForBackground from '../../../utils/pickColorForBackground';
import { Review } from './Review/Review';
import IActions from 'actions/IActions';
const styles = require('./Mobile.css');

interface IProps {
    profile: IProfile;
    setHeaderProps: (props: Partial<IHeaderProps>) => void;
    isCustomer?: boolean;
    actions?: IActions;
}

type Tab = 'BOOKED' | 'CANCELED' | 'CALENDAR';

export default function MobileDashboard({ profile, setHeaderProps, isCustomer, actions }: IProps) {
    useEffect(() => {
        if (isCustomer) {
            redirectToBaseDomain();
        }
    }, [isCustomer]);
    const params: any = useParams();
    const loggedIn = isLoggedIn();
    const adHoc = isAdHoc();
    const [isConfirm, setIsConfirm] = useState<boolean>(params.serviceId? true: false);
    if (!loggedIn && !adHoc) {
        const redirect = params.serviceId
            ? encodeURIComponent(`/sb-customer/my-appointments/${params.serviceId}/confirm`)
            : encodeURIComponent('/sb-customer/my-appointments');
        return <Redirect to={`/login?redirectURI=${redirect}`} />;
    }
    const { timezone, timeFormat } = profile;
    const { t } = useTranslation();
    const [appointments, setAppointments] = useState<IAppointment[]>(null);
    const [employees, setEmployees] = useState<IPerson[] | null>(null);
    const [selectedWeek, setSelectedWeek] = useState(moment().tz(timezone).startOf('week'));
    const [tab, setTab] = useState<Tab>('BOOKED');
    const [isFilters, setIsFilters] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedAppointment, setSelectedAppointment] = useState<number | null>(params.serviceId? Number(params.serviceId): null);
    const [editing, setEditing] = useState(false);
    const [cancelOpen, setCancelOpen] = useState(false);
    const [isWeekView, setIsWeekView] = useState(true);
    const [sendConfirm, setSendConfirm] = useState<boolean>(false);
    const getAppointments = useCallback(() => {
        const from = (isCustomer || adHoc)
            ? '2020-01-01T00:00:00Z'
            : moment(selectedWeek).startOf('week').toISOString();
        const to = (isCustomer || adHoc)
            ? '2120-01-01T00:00:00Z'
            : moment(selectedWeek).endOf('week').toISOString();
        return (isCustomer
            ? BackendMethods.getCustomerAppointments(from, to)
            : BackendMethods.getAppointments(from, to));
    }, [selectedWeek, isCustomer]);
    useEffect(() => {
        if (isConfirm) {
            (async () => {
                try {
                    await BackendMethods.confirmAppointment(Number(params.serviceId));
                } catch (error) {
                    setIsConfirm(false);
                    actions.showErrorMessage(t('small.confirmationErrorMsg'));
                }
            })();
        }
    }, [isConfirm]);
    useEffect(() => {
        (async () => {
            if (!isCustomer) {
                const newEmployees = await BackendMethods.getPeople();
                setEmployees(newEmployees);
            }

            const newAppointments = await getAppointments();
            if (selectedAppointment) {
                const nowAppointment: IAppointment = newAppointments.find(appointment => appointment.id === selectedAppointment);
                if (!nowAppointment || moment(nowAppointment.begin).isBefore(moment())) {
                    setIsConfirm(false);
                }
            }
            setAppointments(R.sortBy(R.prop('begin'), newAppointments));
            if (adHoc && newAppointments.length === 1) {
                setSelectedAppointment(newAppointments[0].id);
            }
        })();
    }, [isCustomer, getAppointments]);
    useDebouncedEffect(() => {
        (async () => {
            if (!isCustomer) {
                const newAppointments = await (search ? BackendMethods.searchAppointments(search) : getAppointments());
                setAppointments(R.sortBy(R.prop('begin'), newAppointments));
            }
        })();
    }, 300, [getAppointments, isCustomer, search]);
    useEffect(() => {
        setHeaderProps({
            onlyBack: !adHoc && (selectedAppointment !== null || isFilters),
            onBack: () => {
                setSelectedAppointment(null);
                setIsFilters(false);
            },
            children: (isCustomer || tab !== 'CALENDAR' || isFilters) ? null : (
                <IconButton
                    onClick={() => setIsFilters(true)}
                    className={styles.filtersButton}
                    size="large">
                    <img src="/assets/filters.svg" alt="" />
                </IconButton>
            ),
        });
        return () => setHeaderProps({});
    }, [isCustomer, selectedAppointment, tab, isFilters]);
    const [selectedDate, setSelectedDate] = useState<Moment>(moment().tz(timezone).startOf('day'));
    const [isWeekly, setIsWeekly] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState<number>(profile.id);
    const firstTodayRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (firstTodayRef.current && !isCustomer) {
            scrollIntoView(firstTodayRef.current, {
                time: 0,
                align: {
                    top: 0,
                },
            });
        }
    }, [appointments, isCustomer]);
    if (!appointments) {
        return null;
    }
    const filteredAppointments = appointments.filter((appointment) => (
        appointment.canceled === (tab === 'CANCELED')
        && (!isCustomer || [
            appointment.service.name, appointment.employee.name,
            `${appointment.customer.firstName} ${appointment.customer.lastName}`,
        ].some((x) => x.toLowerCase().includes(search.toLowerCase())))
    ));
    const [upcoming, pastUnsorted] = R.partition(({ begin }) => moment(begin).isAfter(moment()), filteredAppointments);
    const past = R.reverse(pastUnsorted);
    const attentionCount = appointments.filter((x) => (
        x.needsAttention && moment(x.begin).isAfter(moment())
    )).length;
    const firstTodayId = filteredAppointments
        .find((x) => moment(x.begin).isSameOrAfter(moment(), 'day'))?.id;
    const changeConfirm = async (id: number, confirmed: boolean) => {
        if (!sendConfirm) {
            setSendConfirm(true);
            try {
                await BackendMethods.changeAppointmentConfirm(id, confirmed);
                setAppointments(appointments.map((x) => (
                    x.id === id ? {
                        ...x,
                        confirmed: confirmed
                    } : x
                )));
            } catch (error) {
            } finally {
                setSendConfirm(false);
            }
        }
    };
    const renderAppointment = (appointment: IAppointment) => {
        const { service, location } = appointment;
        const person = appointment.bookingFor || appointment.customer;
        const isPast = moment(appointment.begin).isBefore(moment());
        const timezoneMismatch = location.timezone !== timezone;
        return (
            <div
                key={appointment.id}
                className={styles.appointment}
                onClick={() => (
                    setSelectedAppointment(selectedAppointment === appointment.id
                        ? null
                        : appointment.id,
                    )
                )}
                ref={appointment.id === firstTodayId ? firstTodayRef : null}
            >
                <div className={styles.appointmentData}>
                    {(!isCustomer || appointment.bookingFor) && (
                        <div className={styles.line}>
                            {!isCustomer && !appointment.canceled && !isPast && (
                                <>
                                    <IconButton
                                        size="small"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            BackendMethods.changeNeedsAttention(
                                                appointment.id,
                                                !appointment.needsAttention,
                                            );
                                            setAppointments(appointments.map((x) => (
                                                x.id === appointment.id ? {
                                                    ...x,
                                                    needsAttention: !x.needsAttention,
                                                } : x
                                            )));
                                        }}
                                        className={styles.flagButton}
                                    >
                                        <img
                                            src={appointment.needsAttention
                                                ? '/assets/flag-red.svg'
                                                : '/assets/flag-white.svg'}
                                            alt=""
                                        />
                                    </IconButton>
                                    {appointment.confirmed !== undefined && (
                                        <div
                                            className={styles.confirmContent}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                changeConfirm(appointment.id, !appointment.confirmed);
                                            }}
                                        >
                                            {appointment.confirmed ? (
                                                <Tooltip
                                                    title={t('small.confirmSchedule')}
                                                    placement="top"
                                                    classes={{ tooltip: styles.tooltip }}
                                                >
                                                    <div className={styles.nonConfirm}>
                                                        <img
                                                            src="/assets/check-icon.svg"
                                                            width={'100%'}
                                                            height={'100%'}
                                                            alt=""
                                                        />
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip
                                                    title={t('small.nonConfirmScheduleYet')}
                                                    placement="top"
                                                    classes={{ tooltip: styles.tooltip }}
                                                >
                                                    <div className={styles.nonConfirm}>
                                                        ?
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                            <span className={styles.bold}>
                                {person.firstName}
                                {' '}
                                {person.lastName}
                            </span>
                        </div>
                    )}
                    <div className={styles.line}>
                        {isCustomer ? service.name : (
                            <span
                                className={styles.serviceName}
                                style={{
                                    backgroundColor: service.color,
                                    color: pickColorForBackground(service.color),
                                }}
                            >
                                    {service.name}
                                </span>
                        )}
                    </div>
                    <div className={styles.line}>
                        {location.name}
                    </div>
                    <div className={classNames(styles.line, styles.bold)}>
                        {moment(appointment.begin).tz(location.timezone)
                            .format('dddd, DD MMMM Y, HH:mm')}
                        -
                        {moment(appointment.end).tz(location.timezone)
                            .format('HH:mm')}
                        {timezoneMismatch && (
                            <span className={styles.timezoneMismatch}>
                                {moment(appointment.end).tz(location.timezone).format('z')}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div>
            {selectedAppointment === null && !isFilters && (
                <>
                    <div className={styles.tabs}>
                        <Button
                            variant="contained"
                            className={classNames(styles.tab, {
                                [styles.selectedTab]: tab === 'BOOKED',
                            })}
                            onClick={() => setTab('BOOKED')}
                        >
                            <span className={styles.bookedText}>
                                {t('small.booked')}
                                {attentionCount > 0 && (
                                    <span className={styles.attentionCount}>
                                        {attentionCount}
                                    </span>
                                )}
                            </span>
                        </Button>
                        <Button
                            variant="contained"
                            className={classNames(styles.tab, {
                                [styles.selectedTab]: tab === 'CANCELED',
                            })}
                            onClick={() => setTab('CANCELED')}
                        >
                            {t('small.canceled')}
                        </Button>
                        <Button
                            variant="contained"
                            className={classNames(styles.tab, {
                                [styles.selectedTab]: tab === 'CALENDAR',
                            })}
                            onClick={() => setTab('CALENDAR')}
                        >
                            {t('small.calendar')}
                        </Button>
                    </div>
                    <div className={styles.tabContent}>
                        {tab !== 'CALENDAR' && (
                            <>
                                {!isCustomer && (
                                    <div className={styles.weekNavigation}>
                                        <IconButton
                                            onClick={() => setSelectedWeek(moment(selectedWeek).subtract(1, 'week'))}
                                        >
                                            <img
                                                src="/assets/chevron-blue.svg"
                                                alt=""
                                                className={styles.chevronLeft}
                                            />
                                        </IconButton>
                                        <div className={styles.currentWeek}>
                                            {moment(selectedWeek).startOf('week').format('D MMMM')}
                                            {' – '}
                                            {moment(selectedWeek).endOf('week').format('D MMMM')}
                                        </div>
                                        <IconButton
                                            onClick={() => setSelectedWeek(moment(selectedWeek).add(1, 'week'))}
                                        >
                                            <img
                                                src="/assets/chevron-blue.svg"
                                                alt=""
                                                className={styles.chevronRight}
                                            />
                                        </IconButton>
                                    </div>
                                )}
                                <TextField
                                    value={search}
                                    onChange={({ target }) => (
                                        setSearch(target.value)
                                    )}
                                    label={t('small.dashboardSearch')}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img
                                                    src="/assets/search-gray.svg"
                                                    alt=""
                                                    className={styles.searchIcon}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div className={styles.appointments}>
                                    {(isCustomer && !adHoc && tab !== 'CANCELED') ? (
                                        <>
                                            <div className={styles.listHeader}>
                                                {t('small.upcomingAppointments')}
                                            </div>
                                            {upcoming.map(renderAppointment)}
                                            {upcoming.length === 0 && (
                                                <div className={styles.noAppointments}>
                                                    {t('small.noAppointments')}
                                                </div>
                                            )}
                                            <div className={styles.listHeader}>
                                                {t('small.pastAppointments')}
                                            </div>
                                            {past.map(renderAppointment)}
                                            {past.length === 0 && (
                                                <div className={styles.noAppointments}>
                                                    {t('small.noAppointments')}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {filteredAppointments.map(renderAppointment)}
                                            {filteredAppointments.length === 0 && (
                                                <div className={styles.noAppointments}>
                                                    {t('small.noAppointments')}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        {tab === 'CALENDAR' && !isFilters && (
                            <div className={styles.calendarTab}>
                                <MobileCalendar
                                    selectedWeek={selectedWeek}
                                    setSelectedWeek={setSelectedWeek}
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                    switchIcon={(
                                        <IconButton
                                            size="small"
                                            onClick={() => setIsWeekly(!isWeekly)}
                                            className={styles.switchWeekly}
                                        >
                                            <img
                                                src={isWeekly ? '/assets/calendar-day.svg' : '/assets/calendar-week.svg'}
                                                alt=""
                                                className={styles.calendarIcon}
                                            />
                                        </IconButton>
                                    )}
                                    isWeekly={isWeekly}
                                    isWeekView={isWeekView}
                                    setIsWeekView={setIsWeekView}
                                />
                                <div
                                    className={classNames(styles.weekCalendarWrapper, {
                                        [styles.calendarWrapperWeekly]: isWeekly,
                                    })}
                                >
                                    <WeekCalendar
                                        rangeStart="2000-01-01T00:00:00.000Z"
                                        rangeEnd="2100-01-01T00:00:00.000Z"
                                        events={appointments.filter((appointment) => (
                                            (isCustomer || (appointment.employee.id === selectedPerson)) && !appointment.canceled
                                        )).map((appointment) => {
                                            const person = appointment.bookingFor || appointment.customer;
                                            const name = `${person.firstName} ${person.lastName}`;
                                            const phone = person.phone ? person.countryCode + person.phone : null;
                                            const phoneString = phone
                                                ? `, ${new PhoneNumber(phone).getNumber('international')}`
                                                : '';
                                            const email = person.email ? `, ${person.email}` : '';
                                            const { notes } = appointment;
                                            const subjectShort = (
                                                <>
                                                    <span className={styles.bold}>
                                                        {name}
                                                    </span>
                                                    {notes && ` ${notes}`}
                                                </>
                                            );
                                            return {
                                                id: String(appointment.id),
                                                start: appointment.begin,
                                                end: appointment.end,
                                                ...(isCustomer ? {
                                                    subject: appointment.service.name,
                                                } : {
                                                    subjectShort,
                                                    subject: (
                                                        <>
                                                            {subjectShort}
                                                            {`${phoneString}${email}`}
                                                        </>
                                                    ),
                                                    subject2: appointment.service.name,
                                                    color: appointment.service.color,
                                                }),
                                            };
                                        })}
                                        proposition={null}
                                        timezone={timezone}
                                        timeFormat={timeFormat}
                                        workWeekEvents={[]}
                                        startOfDay={selectedDate}
                                        setStartOfDay={setSelectedDate}
                                        isDaily={!isWeekly}
                                        isMobile
                                        showHolidays
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <Fab
                        className={styles.fab}
                        component={Link}
                        to={isCustomer ? '/sb-customer/find-service' : '/sb/assistant-booking'}
                    >
                        <img src="/assets/plus-white.svg" alt="" className={styles.fabIcon} />
                    </Fab>
                </>
            )}
            {selectedAppointment !== null && (() => {
                const appointment = appointments.find(R.propEq('id', selectedAppointment));
                const { customer, bookingFor, service, employee, location } = appointment;
                const isPast = moment(appointment.begin).isBefore(moment());
                const timezoneMismatch = location.timezone !== timezone;
                const renderCustomer = (customer2: ICustomer) => {
                    return (
                        <div className={styles.customer}>
                            <div className={styles.name}>
                                {customer2.firstName}
                                {' '}
                                {customer2.lastName}
                                {!isCustomer && (
                                    <IconButton
                                        className={styles.customerEdit}
                                        onClick={() => setEditing(true)}
                                        size="large">
                                        <img src="/assets/pen.svg" alt="" />
                                    </IconButton>
                                )}
                            </div>
                            {!isCustomer && customer2.birthDate && (
                                <div className={styles.detailsLine}>
                                    {t('small.birthdateDetails')}
                                    {' '}
                                    {moment(customer2.birthDate).format('DD.MM.YYYY')}
                                </div>
                            )}
                            {!isCustomer && customer2.phone && (
                                <div className={styles.detailsLine}>
                                    <a
                                        href={`tel:${customer2.countryCode}${customer2.phone}`}
                                        className={styles.phoneLink}
                                    >
                                        {new PhoneNumber(customer2.countryCode + customer2.phone)
                                            .getNumber('international')}
                                    </a>
                                </div>
                            )}
                            {!isCustomer && customer2.email && (
                                <div className={styles.detailsLine}>
                                    {customer2.email}
                                </div>
                            )}
                        </div>
                    );
                };
                const bookBase = isCustomer
                    ? `/sb-customer/book/${appointment.company.id}`
                    : '/sb/assistant-booking';
                return (
                    <div className={styles.details}>
                        <div className={styles.state}>
                            {appointment.canceled
                                ? t('small.canceled')
                                : t('small.booked')}
                        </div>
                        {bookingFor && renderCustomer(bookingFor)}
                        <div className={styles.detailsGroup}>
                            <div className={styles.detailsLine}>
                                {service.name}
                            </div>
                            <div className={styles.detailsLine}>
                                {employee.name}
                            </div>
                            {(service.price.min || service.price.max) && (
                                <div className={styles.detailsLine}>
                                    {t('small.priceLower')}
                                    {' '}
                                    {renderPrice(service.price)}
                                </div>
                            )}
                        </div>
                        <div className={styles.detailsGroup}>
                            <div className={styles.detailsLine}>
                                {location.name}
                            </div>
                            <div className={styles.detailsLine}>
                                {location.address}
                            </div>
                            <div className={classNames(styles.detailsLine, styles.bold)}>
                                {moment(appointment.begin).tz(location.timezone)
                                    .format('dddd, DD MMMM Y, HH:mm')}
                                -
                                {moment(appointment.end).tz(location.timezone)
                                    .format('HH:mm')}
                                {timezoneMismatch && (
                                    <span className={styles.timezoneMismatch}>
                                        {moment(appointment.end).tz(location.timezone).format('z')}
                                    </span>
                                )}
                            </div>
                        </div>
                        {appointment.notes && (
                            <div className={styles.detailsGroup}>
                                <div className={classNames(styles.detailsLine, styles.bold)}>
                                    {appointment.notes}
                                </div>
                            </div>
                        )}
                        {!isCustomer && (
                            <>
                                <div className={styles.detailsLine}>
                                    {t('small.bookedBy')}
                                </div>
                                {renderCustomer(customer)}
                            </>
                        )}
                        <div className={styles.buttonsRow}>
                            {appointment.canceled ? (
                                <Button
                                    variant="outlined"
                                    component={Link}
                                    to={adHoc
                                        ? '/login'
                                        : `${bookBase}/rebook/${appointment.id}`}
                                >
                                    {t('small.rebook')}
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        variant="outlined"
                                        onClick={() => setCancelOpen(true)}
                                        disabled={isPast}
                                    >
                                        {t('small.cancel')}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        component={Link}
                                        to={`${bookBase}/edit/${appointment.id}`}
                                        disabled={isPast}
                                    >
                                        {t('small.reschedule')}
                                    </Button>
                                </>
                            )}
                        </div>
                        {isCustomer && (
                            <Review
                                company={appointment.company}
                                disabled={appointment.canceled || !isPast}
                            />
                        )}
                    </div>
                );
            })()}
            {isFilters && (
                <div className={styles.filters}>
                    <div className={styles.calendars}>
                        {t('small.calendars')}
                        :
                    </div>
                    <RadioGroup
                        className={styles.people}
                        value={selectedPerson}
                        onChange={async ({ target }) => {
                            setSelectedPerson(Number(target.value));
                        }}
                    >
                        {employees.filter((x) => x.services.length > 0).map((person) => (
                            <FormControlLabel
                                key={person.id}
                                value={person.id}
                                control={(
                                    <Radio color="secondary" />
                                )}
                                label={person.name}
                                className={styles.person}
                            />
                        ))}
                    </RadioGroup>
                </div>
            )}
            <Dialog
                open={cancelOpen}
                onClose={() => setCancelOpen(false)}
                classes={{ paper: styles.cancelDialog }}
            >
                <div className={styles.dialogText}>
                    {t('small.cancelConfirm')}
                </div>
                <div className={styles.dialogActions}>
                    <Button
                        className={styles.dialogButton}
                        onClick={async () => {
                            if (isCustomer) {
                                await BackendMethods.customerCancelAppointment(
                                    selectedAppointment,
                                    appointments.find(R.propEq('id', selectedAppointment)).company.id,
                                );
                            } else {
                                await BackendMethods.cancelAppointment(selectedAppointment);
                            }
                            setSelectedAppointment(null);
                            setAppointments(appointments.map((x) => (
                                x.id === selectedAppointment ? {
                                    ...x,
                                    canceled: true,
                                } : x
                            )));
                            setCancelOpen(false);
                        }}
                    >
                        {t('small.yes')}
                    </Button>
                    <Button
                        variant="contained"
                        className={styles.dialogButton}
                        onClick={() => setCancelOpen(false)}
                    >
                        {t('small.no')}
                    </Button>
                </div>
            </Dialog>
            <Dialog
                open={isConfirm}
                onClose={() => setIsConfirm(false)}
                classes={{ paper: styles.confirmModal }}
            >
                <div className={styles.confirmModalText}>
                    {t('small.attendanceConfirmed')}
                </div>
                <div className={styles.confirmModalIcon}>
                    <img src="/assets/checkmark_icon.svg" alt="" width={45} height={42} />
                </div>
                <Button
                    className={styles.confirmModalButton}
                    onClick={() => setIsConfirm(false)}
                >
                    {t('small.viewDetails')}
                </Button>
                <h4 className={styles.confirmModalContectText}>{t('small.cantAttend')}</h4>
                <div className={styles.confirmModalButtonGroup}>
                    <Button
                        className={styles.confirmModalButton}
                        onClick={() => {
                            setCancelOpen(true);
                            setIsConfirm(false);
                        }}
                    >
                        {t('small.cancel')}
                    </Button>
                    <Button
                        className={styles.confirmModalButton}
                        component={Link}
                        to={isCustomer
                            ? `/sb-customer/book/${appointments.find(appointment => appointment.id === selectedAppointment)?.company.id}/edit/${selectedAppointment}`
                            : `/sb/assistant-booking/edit/${selectedAppointment}`}
                    >
                        {t('small.reschedule')}
                    </Button>
                </div>
            </Dialog>
            <Dialog
                open={editing}
                onClose={() => setEditing(false)}
                fullScreen
                classes={{ container: styles.dialog, paper: styles.dialogPaper }}
            >
                <CustomerData
                    appointment={appointments.find(R.propEq('id', selectedAppointment))}
                    onSave={(appointment) => {
                        setAppointments(appointments.map((x) => (
                            x.id === selectedAppointment ? appointment : x
                        )));
                        setEditing(false);
                    }}
                />
            </Dialog>
        </div>
    );
}
