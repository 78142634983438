import React from 'react';
import Button from 'components/_ReusableComponents/Button/Button';
import TextField from 'components/_ReusableComponents/TextField/TextField';
import Styles from './ICloudCalendarLogin.css';
import { useTranslation } from 'react-i18next';
const config = require('CONFIG');

interface ICloudCalendarLoginProps {
    setIsICloudCalendar: React.Dispatch<React.SetStateAction<boolean>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    isError: boolean;
    setIsError: React.Dispatch<React.SetStateAction<boolean>>;
    addICloudCalendar: Function;
    setIsAppleCalendarEvents: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ICloudCalendarLogin = (props: ICloudCalendarLoginProps) => {
    const { setIsICloudCalendar, email, setEmail, password, setPassword, isError, addICloudCalendar, setIsError, setIsAppleCalendarEvents } = props;
    const { t } = useTranslation();

    return (
        <div>
            <h4 className={Styles.normalText}>{t('settings.enterYour')} <span className={Styles.boldText}>iCloud</span> {t('settings.emailAddress')}</h4>
            <TextField
                type="email"
                className={Styles.inputField}
                placeholder="Email"
                value={email}
                onChange={
                    ({ target }) => {
                        setEmail(target.value);
                        setIsError(false);
                    }
                }
                fullWidth
            />
            <h4 className={Styles.normalText}>{t('settings.enterYour')} <span className={Styles.boldText}>{t('settings.appSpecificPassword')}</span> </h4>
            <TextField
                type="password"
                placeholder="Password"
                value={password}
                onChange={
                    ({ target }) => {
                        setPassword(target.value);
                        setIsError(false);
                    }
                }
                fullWidth
            />
            <p className={Styles.helperText}>{t('settings.NotSureHowToRetrieveAppSpecificPassword')}</p>
            <a className={Styles.linkText} href={`https://${config.baseDomain}/icloud-docs`} target="_balnk">{t('settings.checkOutThisHelpSite')}</a>
            <div className={Styles.errorMessageContent}>
                {isError && (
                    <h4 className={Styles.errorFont}>{t('settings.yourICloudEmailAddressOrPasswordWasIncorrect')}</h4>
                )}
            </div>
            <div className={Styles.btnGroup}>
                <Button
                    className={Styles.cancelButton}
                    onClick={() => {
                        setIsICloudCalendar(false);
                        setIsAppleCalendarEvents(false);
                        setEmail('');
                    }}
                >
                    {t('settings.cancel')}
                </Button>
                <Button
                    className={Styles.continueButton}
                    onClick={() => addICloudCalendar()}
                >
                    {t('settings.continue')}
                </Button>
            </div>
        </div>
    );
};
