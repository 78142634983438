export default function mapStateToProps(state: any): {
    data: any;
    app: any;
    plusPlanAdmin: any;
    plusPlanPayment: any;
    manageLicenses: any;
    meeting: any;
    preferences: any;
} {
    return {
        ...state
    };
}
