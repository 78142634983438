// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__2gKcl {\n    padding: 40px 32px 40px 66px;\n}\n\n.header__3jaNa {\n    font-size: 35px;\n    font-weight: 700 !important;\n    margin-bottom: 20px;\n}\n\n.form__5l-f2 {\n    width: 100%;\n    max-width: 414px;\n}\n\n@media (max-width: 1250px) {\n    .root__2gKcl {\n        padding-left: 32px;\n    }\n}\n", "",{"version":3,"sources":["Contact.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","file":"Contact.css","sourcesContent":[".root {\n    padding: 40px 32px 40px 66px;\n}\n\n.header {\n    font-size: 35px;\n    font-weight: 700 !important;\n    margin-bottom: 20px;\n}\n\n.form {\n    width: 100%;\n    max-width: 414px;\n}\n\n@media (max-width: 1250px) {\n    .root {\n        padding-left: 32px;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__2gKcl",
	"header": "header__3jaNa",
	"form": "form__5l-f2"
};
module.exports = exports;
