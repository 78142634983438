// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Register__3ADkL {\n    display: block;\n    font-size: 16px;\n}\n\n.Register__3ADkL div > span,\n.Register__3ADkL a {\n    font-size: 14px;\n}\n\n.Register__3ADkL p > a {\n    color: #e63e25;\n    margin-left: 5px;\n    text-decoration: none;\n}\n\n.Register__3ADkL > span {\n    float: left;\n    margin-bottom: 10px;\n    margin-left: 10px;\n    margin-top: 10px;\n}\n\n.Register__3ADkL > span > span {\n    color: #013253;\n    font-weight: 600;\n    margin-left: 4px;\n}\n\n.AdditionalInfo__3qmwY {\n    margin: 10px 0;\n}\n\n.AdditionalInfo__Link__3ZUfD,\n.AdditionalInfo__Link__3ZUfD:visited {\n    color: #08a;\n    text-decoration: none;\n}\n\n.AdditionalInfo__Link__3ZUfD:hover,\n.AdditionalInfo__Link__3ZUfD:active {\n    color: #0badd6;\n}\n", "",{"version":3,"sources":["Register.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;;IAEI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI,WAAW;IACX,qBAAqB;AACzB;;AAEA;;IAEI,cAAc;AAClB","file":"Register.css","sourcesContent":[".Register {\n    display: block;\n    font-size: 16px;\n}\n\n.Register div > span,\n.Register a {\n    font-size: 14px;\n}\n\n.Register p > a {\n    color: #e63e25;\n    margin-left: 5px;\n    text-decoration: none;\n}\n\n.Register > span {\n    float: left;\n    margin-bottom: 10px;\n    margin-left: 10px;\n    margin-top: 10px;\n}\n\n.Register > span > span {\n    color: #013253;\n    font-weight: 600;\n    margin-left: 4px;\n}\n\n.AdditionalInfo {\n    margin: 10px 0;\n}\n\n.AdditionalInfo__Link,\n.AdditionalInfo__Link:visited {\n    color: #08a;\n    text-decoration: none;\n}\n\n.AdditionalInfo__Link:hover,\n.AdditionalInfo__Link:active {\n    color: #0badd6;\n}\n"]}]);
// Exports
exports.locals = {
	"Register": "Register__3ADkL",
	"AdditionalInfo": "AdditionalInfo__3qmwY",
	"AdditionalInfo__Link": "AdditionalInfo__Link__3ZUfD"
};
module.exports = exports;
