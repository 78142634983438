// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ForgotPage__3_exT {\n    margin: 30px auto;\n    width: 320px;\n}\n\n.ForgotPage__3_exT > div:first-child {\n    margin-bottom: 30px;\n    text-align: center;\n}\n\n.ForgotPage__3_exT > div:first-child > img {\n    max-width: 100%;\n}\n\n@media only screen and (max-width: 500px) {\n    .ForgotPage__3_exT {\n        margin: 0;\n        min-height: 600px;\n        padding: 30px;\n        width: 100%;\n    }\n}\n", "",{"version":3,"sources":["ForgotPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,SAAS;QACT,iBAAiB;QACjB,aAAa;QACb,WAAW;IACf;AACJ","file":"ForgotPage.css","sourcesContent":[".ForgotPage {\n    margin: 30px auto;\n    width: 320px;\n}\n\n.ForgotPage > div:first-child {\n    margin-bottom: 30px;\n    text-align: center;\n}\n\n.ForgotPage > div:first-child > img {\n    max-width: 100%;\n}\n\n@media only screen and (max-width: 500px) {\n    .ForgotPage {\n        margin: 0;\n        min-height: 600px;\n        padding: 30px;\n        width: 100%;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"ForgotPage": "ForgotPage__3_exT"
};
module.exports = exports;
