// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__RtQ8b {\n    text-align: center;\n    padding: 0 32px;\n}\n\n.notOnTanbuu__1G4HZ {\n    margin: 200px 0;\n    font-size: 48px;\n}\n\n.text__1XACg {\n    font-size: 36px;\n    margin: 27px 0;\n}\n\n.name__Yb8KQ {\n    text-transform: uppercase;\n    font-weight: 400 !important;\n}\n\n.link__2cnYf {\n    color: #1488a8;\n    text-decoration: none;\n}\n", "",{"version":3,"sources":["NoSubdomain.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","file":"NoSubdomain.css","sourcesContent":[".root {\n    text-align: center;\n    padding: 0 32px;\n}\n\n.notOnTanbuu {\n    margin: 200px 0;\n    font-size: 48px;\n}\n\n.text {\n    font-size: 36px;\n    margin: 27px 0;\n}\n\n.name {\n    text-transform: uppercase;\n    font-weight: 400 !important;\n}\n\n.link {\n    color: #1488a8;\n    text-decoration: none;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__RtQ8b",
	"notOnTanbuu": "notOnTanbuu__1G4HZ",
	"text": "text__1XACg",
	"name": "name__Yb8KQ",
	"link": "link__2cnYf"
};
module.exports = exports;
