import React, { useState } from 'react';
import moment from 'moment-timezone';
import * as R from 'ramda';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import Button from '../../Button/Button';
import BackendMethods from '../../../../api/BackendMethods';
import getRanges from '../../../../utils/dateRange';
import IMeeting from '../../../../interfaces/IMeeting';
import TimeZoneSelect from '../../TimeZoneSelect/TimeZoneSelect';
import { useTranslation } from 'react-i18next';
const styles = require('./AdhocTimezoneModal.css');

interface Props {
    open: boolean;
    meeting?: IMeeting;
    email: string;
    onClose: () => void;
}

export default function AdhocTimezoneModal(props: Props) {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectOpen, setSelectOpen] = useState(false);
    if (!props.meeting) {
        return null;
    }
    const meetingTimezone = props.meeting.participants.find(R.propEq('email', props.email))?.timezone;
    if (!meetingTimezone) {
        return null;
    }
    const browserTimezone = moment.tz.guess();
    const { renderRange } = getRanges(meetingTimezone, t, i18n.language);
    const updateTimezone = async (newTimezone: string) => {
        setLoading(true);
        await BackendMethods.setMeetingTimezone(props.meeting.id, newTimezone);
        setLoading(false);
        props.onClose();
    };
    return (
        <Dialog
            open={props.open}
            className={styles.root}
            PaperProps={{ className: styles.paper }}
        >
            <DialogContent>
                <DialogContentText>
                    {props.meeting.organizer.name || props.meeting.organizer.email}
                    {` ${t('home.hasSetTimezone')} `}
                    <strong>{meetingTimezone}</strong>
                    {` ${t('home.forAMeeting')} `}
                    {renderRange({ dateFrom: props.meeting.after, dateTo: props.meeting.before })}
                    .
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.actions}>
                <div className={styles.mainButtons}>
                    <Button
                        onClick={props.onClose}
                        disabled={loading}
                    >
                        {t('home.keep')}&nbsp;
                        {meetingTimezone}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => updateTimezone(browserTimezone)}
                        disabled={loading}
                    >
                        {t('home.changeTo')}&nbsp;
                        {browserTimezone}
                    </Button>
                </div>
                <Button
                    variant="text"
                    className={styles.setAnother}
                    onClick={() => setSelectOpen(true)}
                    disabled={loading}
                >
                    {t('home.setAnotherTimezone')}
                </Button>
                {selectOpen && (
                    <div className={styles.timeZoneSelect}>
                        <TimeZoneSelect
                            onChange={updateTimezone}
                            onBlur={() => {}}
                        />
                    </div>
                )}
            </DialogActions>
        </Dialog>
    );
}
