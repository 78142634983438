import * as React from 'react';
import cs from 'classnames';
import * as userInitials from 'utils/userInitials/userInitials';

const Styles = require('./UserInitials.css');

interface Props {
    changeColorOnHover?: boolean;
    name: string;
    onClick?: () => void;
}

export default function UserInitials(props: Props) {
    const userInit = userInitials.getInitialsFromName(props.name);
    return (
        <span
            className={cs(
                Styles['UserInitials--Initials'],
                props.changeColorOnHover === true
                    ? Styles['changeColorOnHover--ChangeColorOnHover']
                    : ''
            )}
            onClick={props.onClick}
        >
            {userInit ? (
                <span style={{ verticalAlign: 'sub' }}>{userInit}</span>
            ) : (
                <img
                    src="/assets/spinner.gif"
                    className={Styles['UserInitials--Spinner']}
                />
            )}
        </span>
    );
}
