import TextField from '../TextField/TextField';
import { TimePicker } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const Styles = require('./Styles.css');

const TimeInputField = ({
    value,
    timeFormat = '24h',
    onChange,
}) => {
    const { t } = useTranslation();

    return (
        <TimePicker
            value={value}
            onChange={onChange}
            ampm={timeFormat === '12h'}
            mask={timeFormat === '12h' ? '__:__ _M' : '__:__'}
            disableMaskedInput={false}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className={classNames(Styles.timeField)}
                    inputProps={{
                        ...params.inputProps,
                        placeholder: timeFormat === '12h' ? t('settings.timeFormat12') : t('settings.timeFormat24')
                    }}
                />
            )}
        />
    );
};

TimeInputField.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default TimeInputField;
