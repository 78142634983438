import React, { useEffect } from 'react';
import IActions from 'actions/IActions';
import AvailableLicenses from '../AvailableLicenses/AvailableLicenses';
import AssignLicense from '../AssignLicense/AssignLicense';
import CurrentlyAssignedList from '../CurrentlyAssignedList/CurrentlyAssignedList';
import FallingLeaves from '../../_ReusableComponents/FallingLeaves/FallingLeaves';
import ILocation from '../../../interfaces/location/ILocation';
import Button from '../../_ReusableComponents/Button/Button';
import AdminLinks from '../../_ReusableComponents/AdminLinks/AdminLinks';
import { useTranslation } from 'react-i18next';
const styles = require('./ManageLicenses.css');

interface IManageLicensesProps {
    manageLicenses: any;
    data: any;
    actions: IActions;
    history: any;
    location: ILocation;
}

const ManageLicenses = (props: IManageLicensesProps) => {
    const { t } = useTranslation();
    useEffect(() => {
        props.actions.getAssignedLicenses();
    }, []);
    if (!props.manageLicenses.get('assignedLicenses')) {
        return <FallingLeaves />;
    }
    const subscription = props.data.get('subscription');
    const amountOfLicensesOwned = subscription.licenses;
    const assignedLicenses = props.manageLicenses.get('assignedLicenses');
    const amountOfLicensesUnassigned =
        amountOfLicensesOwned - assignedLicenses.size;
    const expiringLicenses = amountOfLicensesOwned - subscription.renewing;
    const expiringAssignedLicenses = assignedLicenses.size - subscription.renewing;

    return (
        <main className={styles.ManageLicenses} data-testid="licenses">
            {!props.location.state?.wizard && (
                <AdminLinks active="users" />
            )}
            <div className={styles.content}>
                <AvailableLicenses
                    {...props}
                    amountOfLicensesOwned={amountOfLicensesOwned}
                    amountOfLicensesUnassigned={amountOfLicensesUnassigned}
                    expiringLicenses={expiringLicenses}
                />
                <AssignLicense
                    {...props}
                    amountOfLicensesUnassigned={amountOfLicensesUnassigned}
                />
                <CurrentlyAssignedList
                    {...props}
                    assignedLicenses={assignedLicenses}
                    expiringLicenses={expiringAssignedLicenses}
                    subscriptionExpires={subscription.expires}
                />
                {(props.location.state && props.location.state.wizard) && (
                    <div className={styles.Button_Continue__Container}>
                        <Button
                            data-testid="continue"
                            onClick={() => props.history.push('/team', { wizard: true })}
                            className={styles.Button_Continue}
                        >
                            {t('plan.continue')}
                        </Button>
                    </div>
                )}
            </div>
        </main>
    );
};

export default ManageLicenses;
