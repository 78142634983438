import React, { Ref } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import * as R from 'ramda';
import PropositionResponses from './PropositionResponses/PropositionResponses';
import ScheduledDate from './ScheduledDate/ScheduledDate';
import IPropositionAnswer from 'interfaces/IPropositionAnswer';
import PropositionButtons from 'components/MeetingPageComponents/PropositionButtons/PropositionButtons';
import IMeeting from 'interfaces/IMeeting';
import Slider from './Slider/Slider';
import IProposition from '../../../interfaces/IProposition';
import BackendMethods from '../../../api/BackendMethods';
import { Box } from '@material-ui/system';
import { Rating } from 'react-simple-star-rating';
import styles from './ConfirmScheduledDateBox.css';
interface Props {
    proposition: IProposition;
    propositionAnswer: IPropositionAnswer;
    activeCandidateId: number;
    yesNoButtonsIndex: number;
    isUserOrganizer: boolean;
    newLabel: boolean;
    meeting: IMeeting;
    timezone: string;
    timeFormat: string;
    email: string;
    setAnswer: (answer: IPropositionAnswer) => void;
    setNextCandidateActive: () => void;
    setActiveCandidateId: (id: number) => void;
    mainPage?: boolean;
    isSetDate: boolean;
    setDate: (date: string) => void;
    updateMeeting: (meeting: IMeeting, email: string) => void;
}

export default React.forwardRef(function ConfirmScheduledDateBox(props: Props, ref: Ref<HTMLDivElement>) {
    const { meeting, proposition, propositionAnswer } = props;
    const setAnswer = async (x: IPropositionAnswer) => {
        props.setAnswer(x);
        await BackendMethods.saveAnswer({
            meeting: meeting.id,
            data: {
                id: x.id,
                answers: [
                    ...(moment(proposition.earliest).isSame(x.earliest) ? [] : [{
                        earliest: proposition.earliest,
                        latest: moment(x.earliest)
                            .subtract(15, 'minutes')
                            .toISOString(),
                        accepted: !x.accepted,
                    }]),
                    {
                        earliest: x.earliest,
                        latest: x.latest,
                        accepted: x.accepted,
                    },
                    ...(moment(proposition.latest).isSame(x.latest) ? [] : [{
                        earliest: moment(x.latest)
                            .add(15, 'minutes')
                            .toISOString(),
                        latest: proposition.latest,
                        accepted: !x.accepted,
                    }]),
                ],
            },
        });
    };
    const setWatchingMeeting = () => props.setActiveCandidateId(props.propositionAnswer.id);
    const onSliderChange = (x) => {
        if (x.earliest !== propositionAnswer.earliest || x.latest !== propositionAnswer.latest) {
            props.setAnswer({
                ...propositionAnswer,
                earliest: x.earliest || propositionAnswer.earliest,
                latest: x.latest || propositionAnswer.latest,
                accepted: false,
            });
            setWatchingMeeting();
        }
    };
    const rangeNarrowed = !moment(propositionAnswer.earliest).isSame(proposition.earliest) ||
        !moment(propositionAnswer.latest).isSame(proposition.latest);
    const active = props.activeCandidateId === props.propositionAnswer.id;

    return (
        <div
            onClick={setWatchingMeeting}
            className={classNames(styles.root, {
                [styles.active]: active,
                [styles.accepted]: props.proposition.acceptedBy.includes(props.email),
            })}
            data-meeting-schedule={props.newLabel ? 'newSchedule' : ''}
            ref={ref}
        >
            <Box
                className={styles.rating}
                component={Rating}
                ratingValue={proposition.score*20}
                emptyColor={'#FFFFFF'}
                fillColor={'#FAAF01'}
                size={12}
                readonly
                sx={{
                    '& svg': {
                        '& > path': {
                            strokeWidth: '1px',
                            stroke: '#FAAF01',
                        }
                    }
                }}
            />

            <ScheduledDate
                meeting={props.meeting}
                id={props.yesNoButtonsIndex}
                proposition={props.proposition}
                timezone={props.timezone}
                timeFormat={props.timeFormat}
                meetingDate={propositionAnswer.earliest}
                mainPage={props.mainPage}
            />
            <Slider
                meetingDuration={props.meeting.duration}
                earliest={proposition.earliest}
                latest={proposition.latest}
                selectedEarliest={propositionAnswer.earliest}
                selectedLatest={propositionAnswer.latest}
                onChange={onSliderChange}
                timezone={props.timezone}
                timeFormat={props.timeFormat}
                isSetDate={props.isSetDate}
            />
            <PropositionButtons
                propositionAnswer={props.propositionAnswer}
                acceptedByUser={props.proposition.acceptedBy.includes(props.email)}
                setPropositionAnswer={setAnswer}
                setNextPropositionActive={props.setNextCandidateActive}
                rangeNarrowed={rangeNarrowed}
                isSetDate={props.isSetDate}
                setDate={() => props.setDate(propositionAnswer.earliest)}
            />
            <PropositionResponses
                participants={meeting.participants.filter(x => !x.declined)}
                proposition={props.proposition}
                userEmail={props.email}
                isSetDate={props.isSetDate}
            />
            {props.proposition.acceptedBy.includes(props.email) && (
                <div className={styles.checkmark}>
                    <img src="/assets/checkmark-white.svg" alt="" />
                </div>
            )}
        </div>
    );
});
