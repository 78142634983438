// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Label__2G_ZD {\n    margin: 0 0 10px 12px;\n}\n\n.Form__U2OWK {\n    position: relative;\n}\n\n.buttonContainer__3rnin {\n    margin: 8px 0 24px;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n.spinner__1g8bK {\n    width: 32px;\n    margin-right: 8px;\n}\n\n.button__Xgl2_ {\n    width: 100px;\n}\n", "",{"version":3,"sources":["AssignLicense.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB","file":"AssignLicense.css","sourcesContent":[".Label {\n    margin: 0 0 10px 12px;\n}\n\n.Form {\n    position: relative;\n}\n\n.buttonContainer {\n    margin: 8px 0 24px;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n.spinner {\n    width: 32px;\n    margin-right: 8px;\n}\n\n.button {\n    width: 100px;\n}\n"]}]);
// Exports
exports.locals = {
	"Label": "Label__2G_ZD",
	"Form": "Form__U2OWK",
	"buttonContainer": "buttonContainer__3rnin",
	"spinner": "spinner__1g8bK",
	"button": "button__Xgl2_"
};
module.exports = exports;
