import PhoneNumber from 'awesome-phonenumber';
import { allCountries, Country } from 'country-telephone-data';
import * as R from 'ramda';
import { getTimezone } from 'countries-and-timezones';

interface IPhoneObject {
    countryCode?: string;
    phone?: string;
}

export function getCountryAndPhone(phone: string, countryCode?: string): [Country, string] {
    const pn = new PhoneNumber(phone, countryCode);
    const country = allCountries.find((x) => x.dialCode === String(pn.getCountryCode()));
    const phoneSignificant = pn.getNumber('significant');
    return [country, phoneSignificant];
}

export function getObjCountryAndPhone({ countryCode, phone }: IPhoneObject) {
    return getCountryAndPhone(`${countryCode}${phone}`);
}

export function formatPhone({ countryCode, phone }: IPhoneObject) {
    if (!countryCode || !phone) {
        return '';
    }
    return new PhoneNumber(countryCode + phone).getNumber('international');
}

export function formatFullPhone(phone: string) {
    return new PhoneNumber(phone).getNumber('international');
}

export function getCodeLabel(country: Country) {
    return `${country.iso2.toUpperCase()} +${country.dialCode}`;
}

export function getTimezoneCountry(timezone: string) {
    return allCountries.find(R.propEq(
        'iso2',
        getTimezone(timezone).country.toLowerCase(),
    ));
}

export function getPhoneObj(country: Country, phone: string): IPhoneObject {
    if (!phone) {
        return {
            countryCode: null,
            phone: null,
        };
    }
    return {
        countryCode: `+${country.dialCode}`,
        phone: new PhoneNumber(phone, country.iso2).getNumber('significant'),
    };
}
