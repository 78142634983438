// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".AdminPanel__3-zvR {\n    margin: 0 auto;\n    padding-bottom: 60px;\n    width: 100%;\n    max-width: 425px;\n}\n", "",{"version":3,"sources":["AdminPanel.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,oBAAoB;IACpB,WAAW;IACX,gBAAgB;AACpB","file":"AdminPanel.css","sourcesContent":[".AdminPanel {\n    margin: 0 auto;\n    padding-bottom: 60px;\n    width: 100%;\n    max-width: 425px;\n}\n"]}]);
// Exports
exports.locals = {
	"AdminPanel": "AdminPanel__3-zvR"
};
module.exports = exports;
