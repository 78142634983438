// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__1MLuS {\n    margin: 0 auto;\n}\n\n.header__2wP5i {\n    font-size: 16px;\n    text-align: center;\n    margin-top: 0;\n    font-weight: 400 !important;\n}\n\n.headerIndex__3c62Y {\n    font-size: 16px;\n    text-align: center;\n    margin-bottom: 0;\n    font-weight: 400 !important;\n}\n\n.description__3nZZ2 {\n    text-align: justify;\n    margin: 10px 0;\n}\n\n.description__3nZZ2 b {\n    font-weight: 400;\n}\n\n.mainHeader__3jK17 {\n    font-weight: 500 !important;\n    text-align: center;\n}\n\nul {\n    list-style-type: ol;\n    padding-left: 24px;\n}\n\nul li {\n    margin: 10px 0;\n}\n\nul ul {\n    list-style-type: lower-alpha;\n}\n\n.footer__2J8D8 {\n    margin-top: 36px;\n}\n", "",{"version":3,"sources":["Privacy.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;AACpB","file":"Privacy.css","sourcesContent":[".root {\n    margin: 0 auto;\n}\n\n.header {\n    font-size: 16px;\n    text-align: center;\n    margin-top: 0;\n    font-weight: 400 !important;\n}\n\n.headerIndex {\n    font-size: 16px;\n    text-align: center;\n    margin-bottom: 0;\n    font-weight: 400 !important;\n}\n\n.description {\n    text-align: justify;\n    margin: 10px 0;\n}\n\n.description b {\n    font-weight: 400;\n}\n\n.mainHeader {\n    font-weight: 500 !important;\n    text-align: center;\n}\n\nul {\n    list-style-type: ol;\n    padding-left: 24px;\n}\n\nul li {\n    margin: 10px 0;\n}\n\nul ul {\n    list-style-type: lower-alpha;\n}\n\n.footer {\n    margin-top: 36px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__1MLuS",
	"header": "header__2wP5i",
	"headerIndex": "headerIndex__3c62Y",
	"description": "description__3nZZ2",
	"mainHeader": "mainHeader__3jK17",
	"footer": "footer__2J8D8"
};
module.exports = exports;
