import React, { useContext, useState } from 'react';
import containersMapStateToProps from 'utils/containersMapStateToProps';
import containersDispatchToProps from 'utils/containersMapDispatchToProps';
import TextField from '../../components/_ReusableComponents/TextField/TextField';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from 'components/_ReusableComponents/Header/Header/Header';
import DateRangePicker from 'components/NewMeetingPageComponents/DateRangePicker/DateRangePicker';
import { IParticipant } from 'interfaces/interfaces';
import GlobalContext from '../../GlobalContext';
import IDateRange from 'interfaces/IDateRange';
import moment from 'moment';
import Button from 'components/_ReusableComponents/Button/Button';
import BackendMethods from 'api/BackendMethods';
import ISuggestionMeeting from 'interfaces/ISuggestionMeeting';
import getRanges from 'utils/dateRange';
import { useHistory } from 'react-router-dom';
const styles = require('./SuggestionPage.css');

export interface State {
    note: string;
    participants: Record<string, IParticipant>;
    isDisableBtn: boolean;
}

const SuggestionPage = props => {
    const { t, i18n } = useTranslation();
    const nowMeetingId = Number(props.match.params.id);
    const meetings = props.data.get('meetings');
    const nowMeeting: any = meetings.find((meeting) => meeting.id === nowMeetingId);
    const { profile } = useContext(GlobalContext);
    const { meetingToEdit, actions } = props;
    const [sending, setSending] = useState(false);
    const [note, setNote] = useState('');
    const [isDisableBtn, setIsDisableBtn] = useState(false);
    const history = useHistory();
    const [noteError, setNoteError] = useState<string | null>(null);
    const [participants, setParticipants] = useState<Record<string, IParticipant>>({
        [profile.email]: {
            id: profile.id,
            email: profile.email,
            name: profile.firstName ? `${profile.firstName} ${profile.lastName}` : null,
            adhoc: false,
            timezone: profile.timezone,
            country: profile.country,
            editingTimeZone: false,
            reviewRequired: false,
        }
    });
    const [dateRange, setDateRange] = useState<IDateRange>({
        dateFrom: moment().tz(profile.timezone).add(1, 'day').startOf('day').toISOString(),
        dateTo: moment().tz(profile.timezone).add(1, 'day').endOf('week').toISOString(),
    });
    const validate = () => {
        if (note.length > 500) {
            actions.showErrorMessage(t('new.failedToCreate'));
            return false;
        }
        return true;
    };
    const sendMeeting = async () => {
        if (sending || !validate()) {
            return;
        }
        setSending(true);
        const { getRangeName } = getRanges(participants[profile.email].timezone, t, i18n.language);
        const hintName = getRangeName(dateRange);
        const meetingId: number = nowMeetingId;
        const { dateFrom, dateTo } = dateRange;
        const before: string = dateTo;
        const after: string = dateFrom;
        let hint: any;
        switch (hintName) {
            case 'today':
                hint = 'TODAY';
                break;
            case 'tomorrow':
                hint = 'TOMORROW';
                break;
            case 'thisWeek':
                hint = 'THIS_WEEK';
                break;
            case 'nextWeek':
                hint = 'NEXT_WEEK';
                break;
            default:
                hint = null;
        }
        const suggestionMeeting: ISuggestionMeeting = {
            before,
            after,
            hint,
            note
        };
        try {
            setIsDisableBtn(true);
            await (BackendMethods.suggestionMeeting(meetingId, suggestionMeeting));
            history.push(`/meeting/${meetingId}/suggestion`);
            actions.showSuccessMessage(t('suggestion.successMessage'));
        } catch {
            actions.showErrorMessage(t('suggestion.failureMessage'));
        } finally {
            setSending(false);
        }
        setIsDisableBtn(false);
    };
    const sendButton = (
        <Button
            variant="contained"
            color="primary"
            id="NewMeetingSendButton"
            onClick={sendMeeting}
            className={styles.sendButton}
            disabled={isDisableBtn}
        >
            {t('suggestion.sendToOrganizer')}
        </Button>
    );
    return (
        <>
            <Header>
            </Header>
            <div className={styles.root}>
                <h3 className={styles.title}>{ nowMeeting.title }</h3>
                <DateRangePicker
                    title={t('suggestion.dateRange')}
                    timezone={participants[profile.email].timezone}
                    country={participants[profile.email].country}
                    profile={profile}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    actions={actions}
                    dayEvents={meetingToEdit?.organizerDayEvents}
                    meetingHolidays={meetingToEdit?.organizerBusyHolidays}
                    isDispalyPreviewMeetingDate={false}
                    previewMeetingDate={false}
                />
                <TextField
                    id="NewMeetingTitleInput"
                    className={styles.note}
                    placeholder={t('suggestion.notetoOrganizer')}
                    onChange={({ target }) => {
                        setNote(target.value);
                        setNoteError(null);
                    }}
                    fullWidth
                    margin="dense"
                    error={Boolean(noteError)}
                    helperText={noteError}
                    multiline
                />
                {sendButton}
            </div>
        </>
    );
};

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(SuggestionPage);
