import {ISingleTypeAction, IMultipleTypesAction} from 'interfaces/IAction';
import BackendMethods from '../api/BackendMethods';
import {PLAN_INTERVAL} from '../interfaces/backendResponses/ISubscriptionPlan';
import { UPDATE_DATA } from './DataActions';
import ICardDetails from '../interfaces/backendResponses/ICardDetails';

export const CHANGE_AMOUNT_OF_LICENSES = 'CHANGE_AMOUNT_OF_LICENSES';
export const SET_BILLING_TYPE = 'SET_BILLING_TYPE';
export const RECALCULATE_TOTAL_PRICE = 'RECALCULATE_TOTAL_PRICE';
export const SET_AUTO_RENEW = 'SET_AUTO_RENEW';

export const GET_PLANS_PENDING = 'GET_PLANS_PENDING';
export const GET_PLANS_FULFILLED = 'GET_PLANS_FULFILLED';
export const GET_PLANS_REJECTED = 'GET_PLANS_REJECTED';

export const GO_TO_PAYMENT = 'GO_TO_PAYMENT';

export const STORE_CARD_FULFILLED = 'STORE_CARD_FULFILLED';

export const PREVIEW_ORDER_PENDING = 'PREVIEW_ORDER_PENDING';
export const PREVIEW_ORDER_FULFILLED = 'PREVIEW_ORDER_FULFILLED';
export const PREVIEW_ORDER_REJECTED = 'PREVIEW_ORDER_REJECTED';

export const CREATE_SUBSCRIPTION_FULFILLED = 'CREATE_SUBSCRIPTION_FULFILLED';
export const THREE_D_SECURE_COMPLETE = 'THREE_D_SECURE_COMPLETE';
export const RESET_PAYMENT = 'RESET_PAYMENT';
export const GO_BACK = 'GO_BACK';
export const SET_TOO_MANY_LICENSES_ERROR = 'SET_TOO_MANY_LICENSES_ERROR';
export const SET_INVALID_NUMBER_ERROR = 'SET_INVALID_NUMBER_ERROR';

export default class PlusPlanPaymentActions {
    changeAmountOfLicenses(amountOfLicenses: string): ISingleTypeAction {
        return {
            type: CHANGE_AMOUNT_OF_LICENSES,
            payload: {
                amountOfLicenses: amountOfLicenses
            }
        };
    }

    paymentSetTooManyLicensesError(error: boolean) {
        return {
            type: SET_TOO_MANY_LICENSES_ERROR,
            payload: error
        };
    }

    paymentSetInvalidNumberError(error: boolean) {
        return {
            type: SET_INVALID_NUMBER_ERROR,
            payload: error
        };
    }

    setBillingType(billingType: string): ISingleTypeAction {
        return {
            type: SET_BILLING_TYPE,
            payload: {
                billingType: billingType
            }
        };
    }

    setAutoRenew(autoRenew: boolean): ISingleTypeAction {
        return {
            type: SET_AUTO_RENEW,
            payload: {
                autoRenew: autoRenew,
            }
        };
    }

    getSubscriptionPlans(): IMultipleTypesAction {
        return {
          types: [GET_PLANS_PENDING, GET_PLANS_FULFILLED, GET_PLANS_REJECTED],
          payload: {
              promise: BackendMethods.getSubscriptionPlans()
          }
        };
    }

    goToPayment(): ISingleTypeAction {
      return {
          type: GO_TO_PAYMENT,
          payload: {}
      };
    }

    cardDetailsReceived(cardDetails: ICardDetails) {
        return {
            type: STORE_CARD_FULFILLED,
            payload: cardDetails
        };
    }

    previewOrder(
        interval: PLAN_INTERVAL,
        quantity: number,
        renew: boolean,
        prorationToken?: string
    ): IMultipleTypesAction {
        return {
            types: [PREVIEW_ORDER_PENDING, PREVIEW_ORDER_FULFILLED, PREVIEW_ORDER_REJECTED],
            payload: {
                promise: BackendMethods.previewOrder(interval, quantity, renew, prorationToken)
            }
        };
    }

    createSubscription(
        interval: string,
        quantity: number,
        renew: boolean
    ) {
        return async dispatch => {
            const subscription = await BackendMethods.createSubscription(interval, quantity, renew);
            dispatch({
                type: CREATE_SUBSCRIPTION_FULFILLED,
                payload: subscription.payment
            });
            dispatch({
                type: UPDATE_DATA,
                payload: { subscription }
            });
        };
    }

    threeDSecureComplete(success: boolean): ISingleTypeAction {
        return {
            type: THREE_D_SECURE_COMPLETE,
            payload: {success}
        };
    }

    resetPayment(): ISingleTypeAction {
        return { type: RESET_PAYMENT };
    }

    goBack(): ISingleTypeAction {
        return { type: GO_BACK };
    }
}
