import { color } from '@material-ui/system';
import React from 'react';
import Styles from './RadioButton.css';

interface RadioStyle {
    color: string;
    size: number;
    padding: number;
    titlePosTop: number;
    titlePosLeft: number;
    fontColor: string;
    fontSize: number;
}

interface RadioProps {
    checked: Boolean;
    label: string;
    value: string;
    onChange: React.Dispatch<React.SetStateAction<string>>;
    style: RadioStyle;
}

export const RadioButton = (props: RadioProps) => {
    return (
        <div
            className={Styles.radioContent}
            onClick={() => props.onChange(props.value)}
        >
            <div
                className={Styles.outsideCircle}
                style={{
                    width: props.style.size,
                    height: props.style.size,
                    padding: props.style.padding,
                    borderColor: props.style.color
                }}
            >
                {props.checked && (
                    <div
                        className={Styles.insideCircle}
                        style={{
                            backgroundColor: props.style.color
                        }}
                    >
                    </div>
                )}
            </div>
            <h4
                className={Styles.radioTitle}
                style={{
                    color: props.style.fontColor,
                    fontSize: props.style.fontSize,
                    marginLeft: props.style.size + props.style.titlePosLeft,
                    marginTop: props.style.titlePosTop
                }}
            >
                {props.label}
            </h4>
        </div>
    );
};
