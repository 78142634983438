// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".root__4w8-e {\n    display: inline-flex;\n    margin-left: 2px;\n    margin-bottom: 2px;\n}\n\n@-webkit-keyframes blink__3M5Mh {\n    0%,\n    100% {\n        transform: scale(0.5);\n    }\n    50% {\n        transform: scale(1);\n    }\n}\n\n@keyframes blink__3M5Mh {\n    0%,\n    100% {\n        transform: scale(0.5);\n    }\n    50% {\n        transform: scale(1);\n    }\n}\n\n.dot__d_OZL {\n    width: 6px;\n    height: 6px;\n    border-radius: 100%;\n    background-color: #e9523e;\n    margin: 0 2px;\n    -webkit-animation: blink__3M5Mh 1s ease-in-out infinite;\n            animation: blink__3M5Mh 1s ease-in-out infinite;\n}\n\n.dot__d_OZL:nth-child(2) {\n        -webkit-animation-delay: 0.33s;\n                animation-delay: 0.33s;\n    }\n\n.dot__d_OZL:nth-child(3) {\n        -webkit-animation-delay: 0.66s;\n                animation-delay: 0.66s;\n    }\n", "",{"version":3,"sources":["BlinkingDots.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI;;QAEI,qBAAqB;IACzB;IACA;QACI,mBAAmB;IACvB;AACJ;;AARA;IACI;;QAEI,qBAAqB;IACzB;IACA;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,uDAAwC;YAAxC,+CAAwC;AAS5C;;AAPI;QACI,8BAAsB;gBAAtB,sBAAsB;IAC1B;;AAEA;QACI,8BAAsB;gBAAtB,sBAAsB;IAC1B","file":"BlinkingDots.css","sourcesContent":[".root {\n    display: inline-flex;\n    margin-left: 2px;\n    margin-bottom: 2px;\n}\n\n@keyframes blink {\n    0%,\n    100% {\n        transform: scale(0.5);\n    }\n    50% {\n        transform: scale(1);\n    }\n}\n\n.dot {\n    width: 6px;\n    height: 6px;\n    border-radius: 100%;\n    background-color: #e9523e;\n    margin: 0 2px;\n    animation: blink 1s ease-in-out infinite;\n\n    &:nth-child(2) {\n        animation-delay: 0.33s;\n    }\n\n    &:nth-child(3) {\n        animation-delay: 0.66s;\n    }\n}\n"]}]);
// Exports
exports.locals = {
	"root": "root__4w8-e",
	"dot": "dot__d_OZL",
	"blink": "blink__3M5Mh"
};
module.exports = exports;
