import * as React from 'react';
const Styles = require('./SocialIcon.css');

interface ISocialIconProps {
    classes: {
        list: string,
        image: string;
    };
    iconSrc: string;
    iconAlt: string;
    link?: string;
    onClick?: () => void;
}

export default class SocialIcon extends React.PureComponent<ISocialIconProps, null> {

    preventRedirect = (e) => {
        e.preventDefault();
    };

    render(): JSX.Element {
        const {classes, iconSrc, iconAlt, onClick, link} = this.props;
        return(
            <li className={classes.list} onClick={onClick}>
                <a href={link} onClick={this.preventRedirect} className={Styles.Link}>
                    <img
                        className={classes.image}
                        src={iconSrc}
                        alt={iconAlt}
                    />
                </a>
            </li>
        );
    }
}
