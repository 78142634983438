import * as app from '../actions/AppActions';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import * as AppActionsConsts from '../actions/AppActions';

const INITIAL_STATE = fromJS({
    isMenuVisible: false,
    clickEventsDisable: false,
    displayErrorMessage: false,
    displaySuccessMessage: false,
    errorMessageText: '',
    successMessageText: '',
    isMeetingNotesPopoverVisible: false,
    isAddAccountButtonLoading: false,
    isSupportModalVisible: false,
    supportEmail: '',
    supportTitle: '',
    supportMessage: '',
    newNotesLength: 0,
    isNotificationsListLoading: false,
    isNotificationWindowVisible: false,
    isUseQuotaExceededModalVisible: false
});

export default function appReducer(
    state: any = INITIAL_STATE,
    action: any = { type: '', payload: {} }
) {
    switch (action.type) {
        case LOCATION_CHANGE: {
            state = INITIAL_STATE;
            return state;
        }

        case app.SEND_SUPPORT_MESSAGE_PENDING: {
            return state
                .set('isSupportSendButtonLoading', true)
                .set('supportMessageSent', false);
        }
        case app.SEND_SUPPORT_MESSAGE_REJECTED: {
            return state.set('isSupportSendButtonLoading', false);
        }
        case app.SEND_SUPPORT_MESSAGE_FULFILLED: {
            return state
                .set('displaySuccessMessage', true)
                .set(
                    'successMessageText',
                    'Your message has been sent to Tanbuu support.'
                )
                .set('isSupportModalVisible', false)
                .set('isSupportSendButtonLoading', false)
                .set('supportMessageSent', true);
        }

        case app.TOGGLE_SUPPORT_MODAL: {
            const isSupportModalVisible = state.get('isSupportModalVisible');
            return state.set('isSupportModalVisible', !isSupportModalVisible);
        }

        case app.TOGGLE_MEETING_NOTES: {
            const isMeetingNotesPopoverVisible: boolean = state.get(
                'isMeetingNotesPopoverVisible'
            );
            return state
                .set(
                    'isMeetingNotesPopoverVisible',
                    !isMeetingNotesPopoverVisible
                );
        }

        case app.HIDE_MOBILE_MENU: {
            return state.set('isMenuVisible', false);
        }

        case app.SHOW_MOBILE_MENU: {
            return state.set('isMenuVisible', true);
        }

        case app.DISABLE_CLICK_EVENTS: {
            return state.set('clickEventsDisable', true);
        }

        case app.ENABLE_CLICK_EVENTS: {
            return state.set('clickEventsDisable', false);
        }

        case app.SHOW_ERROR_MESSAGE: {
            return state
                .set('displayErrorMessage', true)
                .set('errorMessageText', action.payload);
        }

        case app.HIDE_ERROR_MESSAGE: {
            return state.set('displayErrorMessage', false);
        }

        case app.SHOW_SUCCESS_MESSAGE: {
            return state
                .set('displaySuccessMessage', true)
                .set('successMessageText', action.payload);
        }

        case app.HIDE_SUCCESS_MESSAGE: {
            return state.set('displaySuccessMessage', false);
        }

        default: {
            return state;
        }
    }
}
