// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ManageGroup__Z6L8X {\n    margin: 44px auto 32px;\n    width: 100%;\n    max-width: 425px;\n    color: #013253;\n}\n\n.content__1flHH {\n    padding: 0 16px;\n}\n", "",{"version":3,"sources":["ManageGroup.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB","file":"ManageGroup.css","sourcesContent":[".ManageGroup {\n    margin: 44px auto 32px;\n    width: 100%;\n    max-width: 425px;\n    color: #013253;\n}\n\n.content {\n    padding: 0 16px;\n}\n"]}]);
// Exports
exports.locals = {
	"ManageGroup": "ManageGroup__Z6L8X",
	"content": "content__1flHH"
};
module.exports = exports;
