import React from 'react';
import { Dialog } from '@material-ui/core';
import Button from '../../Button/Button';
import BackendMethods from '../../../../api/BackendMethods';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
const styles = require('./AskForCalendarModal.css');

interface Props {
    open: boolean;
    onClose: () => void;
}

function AskForCalendarModal(props: Props) {
    const history = useHistory();
    const { t } = useTranslation();
    const hideModal = () => {
        BackendMethods.saveShouldPrompt('calendar', 'NEVER');
        props.onClose();
    };
    return (
        <Dialog
            open={props.open}
            onClose={hideModal}
            PaperProps={{ className: styles.paper }}
            id="AskForCalendarModal"
        >
            <div className={styles.title}>
                {t('home.addCalendarQuestion')}
            </div>
            <div>
                {t('home.addCalendarInfo')}
            </div>
            <div className={styles.buttons}>
                <Button
                    variant="contained"
                    id="AskForCalendarModalYesButton"
                    onClick={() => {
                        hideModal();
                        history.push('/preferences?openTab=integrations');
                    }}
                    className={styles.yesButton}
                >
                    {t('home.yes')}
                </Button>
                <Button
                    id="AskForCalendarModalNoButton"
                    onClick={hideModal}
                >
                    {t('home.no')}
                </Button>
            </div>
        </Dialog>
    );
}

export default AskForCalendarModal;
