import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
const styles = require('./CookieDisclaimer.css');

export default function CookieDisclaimer() {
    const { t } = useTranslation();
    const [cookiesAccepted, setCookiesAccepted] = useState(
        localStorage.getItem('cookies-accepted') !== null,
    );
    if (cookiesAccepted) {
        return null;
    }
    return (
        <div className={styles.root}>
            <div className={styles.text}>
                <Trans
                    i18nKey="home.cookies"
                    components={[
                        <Link to="/policy" className={styles.link} />,
                    ]}
                />
            </div>
            <Button
                variant="contained"
                color="primary"
                className={styles.button}
                onClick={() => {
                    localStorage.setItem('cookies-accepted', 'true');
                    setCookiesAccepted(true);
                }}
            >
                {t('home.accept')}
            </Button>
        </div>
    );
}
