// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PlusPlanPayment__dKUw5 {\n    margin: 44px auto 0 auto;\n    padding: 0 20px;\n    max-width: 425px;\n    width: 100%;\n}\n", "",{"version":3,"sources":["PlusPlanPayment.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf","file":"PlusPlanPayment.css","sourcesContent":[".PlusPlanPayment {\n    margin: 44px auto 0 auto;\n    padding: 0 20px;\n    max-width: 425px;\n    width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"PlusPlanPayment": "PlusPlanPayment__dKUw5"
};
module.exports = exports;
