import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/_ReusableComponents/Button/Button';
import classNames from 'classnames';
import { WANTS_TO_BUY_TANBUU } from '../../../../constants/appConsts';
import { Link } from 'react-router-dom';
const styles = require('./Plans.css');

export default function Plans() {
    const { t } = useTranslation();
    return (
        <div className={styles.content}>
            <div className={styles.sectionHeader} id="pricing">
                {t('landing.pricing')}
            </div>
            <div className={styles.root}>
                <div className={styles.plan}>
                    <div className={styles.planName}>
                        {'Pro'}
                    </div>
                    <div className={styles.planPrice}>
                        {`${t('landing.159PLN')}`}
                        <span className={styles.mo}>
                            {t('landing.perMonthPerEmployee')}
                        </span>
                    </div>
                    <div className={styles.planDescription}>
                        {t('small.forProfessionalsFreelancersSmallerBusinesses')}
                    </div>
                    <Button
                        variant="text"
                        className={classNames(styles.planButton, styles.planBuyNow)}
                        onClick={() => {
                            localStorage.setItem(WANTS_TO_BUY_TANBUU, 'PlusPlan');
                        }}
                        component={Link}
                        to="/contact"
                    >
                        {t('landing.buyNow')}
                    </Button>
                    <span className={styles.planFotter}>
                        {'*' + t('small.employeeWithWhomAppointmentsWereBooked')}
                    </span>
                </div>
                <div className={styles.plan}>
                    <div className={styles.planName}>
                        {'Scala'}
                    </div>
                    <div className={styles.planPrice}>
                        {t('small.contactUs')}
                    </div>
                    <div className={styles.planDescription}>
                        {t('small.forLargerBusinessesClinicsUsers')}
                    </div>
                    <Button
                        variant="text"
                        className={classNames(styles.planButton, styles.planBuyNow)}
                        onClick={() => {
                            localStorage.setItem(WANTS_TO_BUY_TANBUU, 'PlusPlan');
                        }}
                        component={Link}
                        to="/contact"
                    >
                        {t('small.contactUsButton')}
                    </Button>
                </div>
            </div>
            <table className={styles.featureTable}>
                <thead>
                    <tr className={styles.headerRow}>
                        <th className={styles.tableTitle}>
                            {t('small.features')}
                        </th>
                        <th className={styles.tablePlan}>
                            {'Pro'}
                        </th>
                        <th className={styles.tablePlan}>
                            {'Scala'}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('small.selfServiceBookingNoApp')}
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('small.automatedSMSAndEmailConfirmationsAndReminders')}
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('small.frontDeskAppForOverThePhoneAndOnSiteBooking')}
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('small.selfManagementOfTheServiceOfferAndStaffSchedules')}
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('small.integrationWithBusinessWebsiteAvailable')}
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('small.dailyWeeklyCalendarViewForTheCustomersAndStaff')}
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('small.polishAndEnglishLanguageVersionsForTheCustomersAndStaff')}
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('small.phoneBaedTechnicalSupport')}
                        </td>
                        <td>
                            {t('small.workTime')}
                        </td>
                        <td>
                            {t('small.custom')}
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('small.emailBasedTechnicalSupport')}
                        </td>
                        <td>
                            {t('small.4h')}
                        </td>
                        <td>
                            {t('small.1h')}
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('small.dedicatedSucessManager')}
                        </td>
                        <td>
                            <img src="/assets/dash.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                    <tr className={styles.tableRow}>
                        <td>
                            {t('small.sLAContractAvailable')}
                        </td>
                        <td>
                            <img src="/assets/dash.svg" alt="" />
                        </td>
                        <td>
                            <img src="/assets/checkmark.svg" alt="" />
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr className={styles.footerRow}>
                        <td />
                        <td>
                            <Button
                                variant="text"
                                className={classNames(styles.planButton, styles.tableButton, styles.planBuyNow)}
                                onClick={() => {
                                    localStorage.setItem(WANTS_TO_BUY_TANBUU, 'PlusPlan');
                                }}
                                component={Link}
                                to="/contact"
                            >
                                {t('landing.buyNow')}
                            </Button>
                        </td>
                        <td>
                            <Button
                                variant="text"
                                className={classNames(styles.planButton, styles.tableButton, styles.planSignUp)}
                                component={Link}
                                to="/contact"
                            >
                                {t('small.contactUsButton')}
                            </Button>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <div className={styles.otherService}>
                <h3 className={styles.otherTitle}>
                    {t('small.additionalServices')}
                </h3>
                <p className={styles.otherText}>
                    {t('small.configuringAndTransferringCustomerDatabaseFromOther')}
                </p>
                <p className={styles.otherText}>
                    {t('small.configuringAndTransferringCustomerDatabase')}
                </p>
                <p className={styles.otherText}>
                    {t('small.integratingWithGoogleMaps')}
                </p>
            </div>
        </div>
    );
}
