import * as React from 'react';
import classNames from 'classnames';
import TogglePreferencesElement from 'components/_ReusableComponents/Toggle/TogglePreferencesElement/TogglePreferencesElement';
import MyWorkWeekContainer from 'components/PreferencesPageComponents/MyWorkWeek/MyWorkWeekContainer/MyWorkWeekContainer';
import IActions from 'actions/IActions';
import { WithTranslation, withTranslation } from 'react-i18next';

const Styles = require('./MyWorkWeekTab.css');

interface Props extends WithTranslation {
    actions: IActions;
    preferences: any;
    data: any;
}

export default withTranslation()(class MyWorkWeekTab extends React.PureComponent<Props, null> {
    toggleMyWorkWeek = () => {
        const {
            actions: { toggleMyWorkWeek }
        } = this.props;
        toggleMyWorkWeek();
    };

    render() {
        const { t } = this.props;
        const classDef = classNames(Styles.MyWorkWeekTab);

        return (
            <div className={classDef}>
                <TogglePreferencesElement
                    callback={this.toggleMyWorkWeek}
                    text={t('settings.workWeek')}
                    toggleState={this.props.preferences.get(
                        'isMyWorkWeekVisible'
                    )}
                >
                    <MyWorkWeekContainer {...this.props} />
                </TogglePreferencesElement>
            </div>
        );
    }
});
