import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { PickersDay, StaticDatePicker } from '@material-ui/lab';
import TextField from '../../components/_ReusableComponents/TextField/TextField';
import classNames from 'classnames';
import moment from 'moment';
import { FormControlLabel, MenuItem, Radio, RadioGroup } from '@material-ui/core';
import WeekCalendar from '../../components/MainPageComponents/WeekCalendar/WeekCalendar';
import IProfile from '../../interfaces/IProfile';
import { IPerson } from './Settings/Personnel';
import BackendMethods from '../../api/BackendMethods';
import * as R from 'ramda';
import { IAppointment } from './Dashboard/Dashboard';
import PhoneNumber from 'awesome-phonenumber';
const styles = require('./Calendar.css');

interface IProps {
    profile: IProfile;
}

type View = 'daily' | 'weekly';

export default function Calendar({ profile }: IProps) {
    const { t } = useTranslation();
    const { timezone, timeFormat } = profile;
    const [selectedDate, setSelectedDate] = useState<Moment>(moment().tz(timezone).startOf('day'));
    const [view, setView] = useState<View>('daily');
    const [employees, setEmployees] = useState<IPerson[] | null>(null);
    const [appointments, setAppointments] = useState<IAppointment[]>([]);
    useEffect(() => {
        (async () => {
            const newEmployees = await BackendMethods.getPeople();
            setEmployees(newEmployees);
        })();
    }, []);
    const [selectedPerson, setSelectedPerson] = useState<number | null>(null);
    if (!employees) {
        return null;
    }
    const appointmentDates = R.uniq(appointments
        .filter((x) => !x.canceled)
        .map((x) => moment(x.begin).format('YYYY-MM-DD'))
    );
    return (
        <div className={styles.root}>
            <div className={styles.left}>
                <div className={styles.pickerContainer}>
                    <StaticDatePicker<Moment>
                        value={selectedDate}
                        onChange={(newDate) => {
                            setSelectedDate(newDate);
                        }}
                        renderInput={(props2) => <TextField {...props2} />}
                        className={styles.picker}
                        shouldDisableDate={(date) => (
                            !appointmentDates.includes(moment(date).format('YYYY-MM-DD'))
                        )}
                        views={['month', 'day']}
                        renderDay={(
                            date,
                            selectedDates,
                            pickersDayProps,
                        ) => {
                            return (
                                <PickersDay
                                    {...pickersDayProps}
                                    className={classNames(styles.day, {
                                        [styles.today]: pickersDayProps.today,
                                    })}
                                    disabled={pickersDayProps.disabled}
                                />
                            );
                        }}
                    />
                </div>
                <TextField
                    label={t('small.view')}
                    value={view}
                    onChange={({ target }) => (
                        setView(target.value as View)
                    )}
                    select
                    className={styles.view}
                >
                    <MenuItem value="daily">
                        {t('small.daily')}
                    </MenuItem>
                    <MenuItem value="weekly">
                        {t('small.weekly')}
                    </MenuItem>
                </TextField>
                <RadioGroup
                    className={styles.people}
                    value={selectedPerson}
                    onChange={async ({ target }) => {
                        setSelectedPerson(Number(target.value));
                        const newAppointments = await BackendMethods.getAppointments(
                            '2000-01-01T00:00:00.000Z',
                            '2100-01-01T00:00:00.000Z',
                            Number(target.value),
                        );
                        setAppointments(newAppointments);
                    }}
                >
                    {employees.filter((x) => x.services.length > 0).map((person) => (
                        <FormControlLabel
                            key={person.id}
                            value={person.id}
                            control={(
                                <Radio color="secondary" />
                            )}
                            label={person.name}
                            className={styles.person}
                        />
                    ))}
                </RadioGroup>
            </div>
            <div className={styles.right}>
                <WeekCalendar
                    rangeStart="2000-01-01T00:00:00.000Z"
                    rangeEnd="2100-01-01T00:00:00.000Z"
                    events={appointments.filter((x) => !x.canceled && (x.bookingFor || x.customer)).map((appointment) => {
                        const person = appointment.bookingFor || appointment.customer;
                        const name = `${person.firstName} ${person.lastName}`;
                        const phone = person.phone ? person.countryCode + person.phone : null;
                        const phoneString = phone
                            ? `, ${new PhoneNumber(phone).getNumber('international')}`
                            : '';
                        const email = person.email ? `, ${person.email}` : '';
                        const { notes } = appointment;
                        const subjectShort = (
                            <>
                                <span className={styles.bold}>
                                    {name}
                                </span>
                                {notes && ` ${notes}`}
                            </>
                        );
                        return {
                            id: String(appointment.id),
                            start: appointment.begin,
                            end: appointment.end,
                            subject: (
                                <>
                                    {subjectShort}
                                    {`${phoneString}${email}`}
                                </>
                            ),
                            subjectShort: subjectShort,
                            subject2: appointment.service.name,
                            color: appointment.service.color,
                            timezone: appointment.location.timezone,
                        };
                    })}
                    proposition={null}
                    timezone={timezone}
                    timeFormat={timeFormat}
                    workWeekEvents={[]}
                    startOfDay={selectedDate}
                    setStartOfDay={setSelectedDate}
                    isDaily={view === 'daily'}
                    showHolidays
                />
            </div>
        </div>
    );
}
