import * as React from 'react';
import Forgot from '../../components/ForgotPageComponents/Forgot/Forgot';
import containersDispatchToProps from '../../utils/containersMapDispatchToProps';
import containersMapStateToProps from '../../utils/containersMapStateToProps';
import EnterpriseLogo from '../../components/_ReusableComponents/EnterpriseLogo/EnterpriseLogo';

const Styles = require('./ForgotPage.css');
const connect = require('react-redux').connect;

interface IForgotPageProps {
    data: any;
    actions: any;
}
class ForgotPage extends React.PureComponent<IForgotPageProps, void> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const logoSrc = this.props.data.get('logoSrc');
        return (
            <div className={Styles.ForgotPage}>
                <EnterpriseLogo logoSrc={logoSrc} />
                <Forgot />
            </div>
        );
    }
}

export default connect(
    containersMapStateToProps,
    containersDispatchToProps
)(ForgotPage);
