import React, { CSSProperties, HTMLAttributes, Ref, useState } from 'react';
import { MenuItem, Paper, Typography, useTheme } from '@material-ui/core';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuProps, NoticeProps } from 'react-select/src/components/Menu';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueType } from 'react-select/src/types';
import classNames from 'classnames';
import { FixedSizeList } from 'react-window';
import TextField from '../TextField/TextField';
const styles = require('./Select.css');

interface OptionType {
    label: string;
    subLabel?: string;
    value: string | number;
}

interface Props {
    label: string;
    options?: OptionType[];
    value: OptionType | null;
    onChange: (x, y) => void;
    creatable?: boolean;
    virtualized?: boolean;
    optionHeight?: number;
    autoFocus?: boolean;
    onBlur?: (x) => void;
    menuIsOpen?: boolean;
    isValidNewOption?: (inputValue: string, selectValue: any[], selectOptions: any[]) => boolean;
    error?: boolean;
    helperText?: string;
    async?: boolean;
    loadOptions?: (query: string) => Promise<any>;
    getNewOptionData?: (inputValue: string) => void;
    defaultOptions?: OptionType[];
    id?: string;
    isDisabled?: boolean;
    isSearchable?: boolean;
    inputId?: string;
    className?: string;
    isClearable?: boolean;
    dontClearOnBlur?: boolean;
    noOptionsMessage?: (inputValue: string) => string;
}

function NoOptionsMessage(props: NoticeProps<OptionType>) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

const inputComponent = React.forwardRef(function (props: InputComponentProps, ref: Ref<HTMLDivElement>) {
    return <div {...props} ref={ref} />;
});

function Control(props: ControlProps<OptionType>) {
    const {
        children,
        innerProps,
        innerRef,
        isFocused,
        selectProps: { classes, TextFieldProps, value, inputValue, error, helperText, isDisabled },
    } = props;

    return (
        <TextField
            margin="dense"
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            InputLabelProps={{ shrink: Boolean(value || inputValue || isFocused) }}
            error={error}
            helperText={helperText}
            disabled={isDisabled}
            className={classes.root}
            {...TextFieldProps}
        />
    );
}

function Option(props: OptionProps<OptionType>) {
    const { onMouseMove, onMouseOver, ...otherInnerProps } = props.innerProps;
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isSelected}
            component="div"
            style={props.isFocused ? {
                backgroundColor: 'rgba(0, 0, 0, 0.14)',
            } : {}}
            className={props.selectProps.classes.option}
            data-testid="option"
            {...otherInnerProps}
        >
            <div className={styles.optionChildren}>
                {props.children}
            </div>
            {props.data.subLabel && (
                <div className={props.selectProps.classes.subLabel}>
                    {props.data.subLabel}
                </div>
            )}
        </MenuItem>
    );
}

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType>, 'innerProps'> &
    Partial<Pick<PlaceholderProps<OptionType>, 'innerProps'>>;
function Placeholder(props: MuiPlaceholderProps) {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

function SingleValue(props: SingleValueProps<OptionType>) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props: MenuProps<OptionType>) {
    return (
        <Paper
            square
            className={props.selectProps.classes.paper}
            {...props.innerProps}
            ref={props.innerRef}
        >
            {props.children}
        </Paper>
    );
}

function MenuList(props) {
    const commonProps = {
        style: {
            ...props.getStyles('menuList', props),
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 4,
            marginBottom: 4
        },
        className: classNames(
            {
                'menu-list': true,
                'menu-list--is-multi': props.isMulti,
            },
            props.className
        )
    };
    if (Array.isArray(props.children)) {
        const { maxMenuHeight, optionHeight } = props.selectProps;
        const itemCount = props.children.length || 0;
        return (
            <FixedSizeList
                {...commonProps}
                height={Math.min(maxMenuHeight, itemCount * optionHeight)}
                itemCount={itemCount}
                itemSize={optionHeight}
            >
                {({ index, style }) => (
                    <div style={style}>
                        {props.children[index]}
                    </div>
                )}
            </FixedSizeList>
        );
    } else {
        return (
            <div {...commonProps}>
                {props.children}
            </div>
        );
    }
}

function DropdownIndicator(props) {
    return (
        <div className={props.selectProps.classes.indicator} />
    );
}

function ClearIndicator() {
    return null;
}

export default function Select(props: Props) {
    const [inputValue, setInputValue] = useState('');
    const classes = {
        root: classNames(styles.root, props.className),
        input: styles.select_input,
        valueContainer: styles.select_valueContainer,
        noOptionsMessage: styles.select_noOptionsMessage,
        singleValue: styles.select_singleValue,
        placeholder: styles.select_placeholder,
        paper: styles.select_paper,
        subLabel: styles.select_subLabel,
        option: styles.select_option,
        indicator: styles.indicator,
    };
    const theme = useTheme();

    const selectStyles = {
        input: (base: CSSProperties) => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    let SelectComponent = ReactSelect;
    if (props.creatable && props.async) {
        SelectComponent = AsyncCreatable;
    } else if (props.creatable) {
        SelectComponent = Creatable;
    } else if (props.async) {
        SelectComponent = Async;
    }

    return (
        <SelectComponent
            classes={classes}
            styles={selectStyles}
            TextFieldProps={{
                label: props.label
            }}
            placeholder=""
            options={props.options}
            components={{
                Control,
                Menu,
                NoOptionsMessage,
                Option,
                Placeholder,
                SingleValue,
                ValueContainer,
                DropdownIndicator,
                ClearIndicator,
                ...(props.virtualized ? { MenuList } : {})
            }}
            value={props.value}
            onChange={props.onChange}
            formatCreateLabel={value => (
                <div className={styles.select_createOption}>
                    {value}
                </div>
            )}
            optionHeight={props.optionHeight}
            autoFocus={props.autoFocus}
            onBlur={props.onBlur}
            menuIsOpen={props.menuIsOpen}
            isValidNewOption={props.isValidNewOption}
            error={props.error}
            helperText={props.helperText}
            loadOptions={props.loadOptions}
            getNewOptionData={props.getNewOptionData}
            defaultOptions={props.defaultOptions}
            id={props.id}
            isDisabled={props.isDisabled}
            isSearchable={props.isSearchable}
            inputId={props.inputId}
            isClearable={props.isClearable}
            {...(props.dontClearOnBlur ? {
                inputValue,
                onInputChange: (newInputValue, { action }) => {
                    if (action !== 'input-blur' && action !== 'menu-close') {
                        setInputValue(newInputValue);
                    }
                },
            } : {})}
            noOptionsMessage={props.noOptionsMessage}
        />
    );
}
